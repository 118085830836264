import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import ReactPaginate from 'react-paginate';

@inject('reports', 'global')
@observer
class ReportPaginator extends React.Component {

    constructor(props) {
        super(props);
        this.goToPage = this.goToPage.bind(this);
        this.showByNumberRows = this.showByNumberRows.bind(this);
    }

    goToPage(data){
        let selected = data.selected;
        this.props.reports.setFrom(selected);
        
        this.props.global.setLoading(true);
        if (!this.props.mode) {            
            if (!this.props.option) {
                this.props.reports.getReportFromApi(this.props.report).then(res => {
                    this.props.global.setLoading(false);
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                });
            } else if(this.props.option === 'xchange') {
                this.props.reports.getReportxChangeFromApi(this.props.report).then(res => {
                    this.props.global.setLoading(false);
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                });
            }
        }
        else {
            if (!this.props.option) {
                this.props.reports.getReportFromApiOrgID(this.props.report, this.props.mode).then(res => {
                    this.props.global.setLoading(false);
                })
                    .catch(error => {
                        this.props.global.setLoading(false);
                    });
            }
            else {
                this.props.reports.getReportOrgFromApi(this.props.report, this.props.mode).then(res => {
                    this.props.global.setLoading(false);
                })
                    .catch(error => {
                        this.props.global.setLoading(false);
                    });
            }
        }
    }

    showByNumberRows(e, number){
        if (e) {
            e.preventDefault();            
        }
        
        let selected = Number(number)
        this.props.reports.setCount(selected);
        
        this.props.global.setLoading(true);
        if (!this.props.mode) {
            if (!this.props.option) {
                this.props.reports.getReportFromApi(this.props.report).then(res => {
                    this.props.global.setLoading(false);
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                });
            } else if(this.props.option === 'xchange') {
                this.props.reports.getReportxChangeFromApi(this.props.report).then(res => {
                    this.props.global.setLoading(false);
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                });
            }
        }
        else {
            this.props.reports.getReportOrgFromApi(this.props.report).then(res => {
                this.props.global.setLoading(false);
            })
                .catch(error => {
                    this.props.global.setLoading(false);
                });
        }
    }

    render() {
        return (
            <div className='report-paginator row'>
                <div className='col pagination-section-1'>
                    {( this.props.reports.totalRecords > 20 ) ?
                        (
                            <ul className="pagination justify-content-left pagination-rows-page">
                                <p className='mt-1 mr-2'>Rows per page</p>
                                <li className={this.props.reports.count === 20 ? 'page-item active' : 'page-item'}><a href='/' role="button" className="page-link" onClick={(e) => this.showByNumberRows(e, 20)}>20</a></li>
                                <li className={this.props.reports.count === 50 ? 'page-item active' : 'page-item'}><a href='/' role="button" className="page-link" onClick={(e) => this.showByNumberRows(e, 50)} data-qaid="rowsPerPage50">50</a></li>
                                <li className={this.props.reports.count === 100 ? 'page-item active' : 'page-item'}><a href='/' role="button" className="page-link" onClick={(e) => this.showByNumberRows(e, 100)} data-qaid="rowsPerPage100">100</a></li>
                            </ul>
                        ) :
                        (
                            <ul className="pagination justify-content-left pagination-rows-page" style={{opacity: '0.4'}}>
                                <p className='mt-1 mr-2'>Rows per page</p>
                                <li className={'page-item'}><a href='/' role="button" className="btn page-link disabled" onClick={(e) => {e && e.preventDefault()}} aria-disabled="true">20</a></li>
                                <li className={'page-item'}><a href='/' role="button" className="btn page-link disabled" onClick={(e) => {e && e.preventDefault()}} aria-disabled="true">50</a></li>
                                <li className={'page-item'}><a href='/' role="button" className="btn page-link disabled" onClick={(e) => {e && e.preventDefault()}} aria-disabled="true">100</a></li>
                            </ul>
                        ) 
                    }
                </div>
                <div className='col pagination-section-2'>
                    <nav aria-label="Page navigation">
                        <ReactPaginate
                            previousLabel={<BiChevronLeft/>}
                            nextLabel={<BiChevronRight/>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.props.reports.totalPages}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={8}
                            onPageChange={this.goToPage}
                            containerClassName={'pagination justify-content-center pagination-rows-page'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            nextClassName={'page-item'}
                            previousClassName={'page-item'}
                            previousLinkClassName= {'page-link'}
                            nextLinkClassName= {'page-link'}
                            pageLinkClassName={'page-link'}
                        />
                    </nav>
                </div>
                <div className='col pagination-section-3'>
                    {( this.props.reports.records.length > 0 ) &&
                        (
                            <p className='float-end mt-1'>Viewing <strong>{this.props.reports.from + 1}-{(this.props.reports.count + this.props.reports.from) > this.props.reports.totalRecords ? this.props.reports.totalRecords : (this.props.reports.count + this.props.reports.from)}</strong> of <strong>{this.props.reports.totalRecords}</strong> results</p>
                        )
                    }
                </div>
            </div>            
        );
    }
}

export { ReportPaginator };