import React from 'react';
import { inject, observer } from 'mobx-react';
import {IMaskInput} from 'react-imask';

@inject('vTerminal', 'global')
@observer
class PaymentMethodCCForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ccType: 'unknown'
        };
        this.goToReview = this.goToReview.bind(this);
        this.handleTextChangeACH = this.handleTextChangeACH.bind(this);
    }

    goToReview(event){
        event.preventDefault();
        this.props.history.push('/review-confirm');
    }

    handleTextChangeACH(field, value) {
        this.props.vTerminal.handleTextChangeACH(field, value);
        var ccType =  this.props.global.creditCardType(this.props.vTerminal.paymentPage.paymentMethods.cardNumber);
        this.setState({'ccType': ccType});
    }

    render() {
        return (
            <div>
                <div className={this.props.vTerminal.hasCCError ? "row cc-form mb-3 input-error" : "row cc-form mb-3" }>
                    <div className="col-sm-7 cc-field">
                        <div className={this.state.ccType}>
                            <div className="form-floating input-cc-cont">
                                <IMaskInput
                                    mask={this.state.ccType === "american-express" ? this.props.global.maskValidator('creditCardAmex') : this.props.global.maskValidator('creditCard') }
                                    data-qaid="cardNumber"
                                    name="cardNumber"
                                    defaultValue={this.props.vTerminal.paymentPage.paymentMethods.cardNumber ? this.props.vTerminal.paymentPage.paymentMethods.cardNumber: ''}
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChangeACH('cardNumber', value)
                                    }
                                    placeholder="Card Number"
                                    className="form-control input-cc"
                                    id="cardNumber"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                />
                                <label htmlFor="cardNumber">Card Number</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm cc-field">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('cardDate')}
                                data-qaid="cardExpirationDate"
                                name="cardExpirationDate"
                                defaultValue={this.props.vTerminal.paymentPage.paymentMethods.cardExpirationDate ? this.props.vTerminal.paymentPage.paymentMethods.cardExpirationDate: ''}
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeACH('cardExpirationDate', value)
                                }
                                className="form-control" 
                                placeholder="MM/YY"
                                id="cardExpirationDate"
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                            <label htmlFor="cardExpirationDate">MM/YY</label>
                        </div>
                    </div>
                    <div className="col-sm ml-2 cc-field">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.state.ccType === "american-express" ? this.props.global.maskValidator('cvvamex') : this.props.global.maskValidator('cvv') }
                                data-qaid="cardCvv"
                                name="cardCvv"
                                id="cardCvv"
                                defaultValue={this.props.vTerminal.paymentPage.paymentMethods.cardCvv ? this.props.vTerminal.paymentPage.paymentMethods.cardCvv: ''}
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeACH('cardCvv', value)
                                }
                                className="form-control" 
                                placeholder="CVV"
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                            <label htmlFor="cardCvv">CVV</label>
                        </div>
                    </div>
                    <div className="col-sm-2 cc-field">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('zipcode')}
                                data-qaid="cardZip"
                                name="cardZipcode"
                                id="cardZipcode"
                                defaultValue={this.props.vTerminal.paymentPage.paymentMethods.cardZipcode ? this.props.vTerminal.paymentPage.paymentMethods.cardZipcode: ''}
                                unmask={true}
                                onAccept={
                                    (value, mask) => this.handleTextChangeACH('cardZipcode', value?.toUpperCase())
                                }
                                className="form-control" 
                                placeholder="Zipcode"
                            />
                            <label htmlFor="cardZipcode">Zipcode</label>
                        </div>
                    </div>
                </div>
                <div className="form-floating">
                    <IMaskInput
                        mask={this.props.global.maskValidator('alphanumericspaces')}
                        data-qaid="cardHolderName"
                        name="cardHolderName"
                        defaultValue={this.props.vTerminal.paymentPage.paymentMethods.cardHolderName ? this.props.vTerminal.paymentPage.paymentMethods.cardHolderName: ''}
                        unmask={true}
                        onAccept={
                            (value, mask) => this.handleTextChangeACH('cardHolderName', value)
                        }
                        placeholder="Cardholder Name"
                        className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsCardHolderNameError ? "form-control input-error" : "form-control" }
                        id="cardHolderName"
                    />
                    <label htmlFor="cardHolderName">Cardholder Name</label>
                </div>
            </div>
        );
    }
}

export { PaymentMethodCCForm };