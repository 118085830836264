import React from 'react';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import { BiChevronDown } from 'react-icons/bi';
import PaymentMethod from './PaymentMethod';
import visaImg from '../../../../../assets/images/visa.svg';
import mastercardImg from '../../../../../assets/images/mastercard.svg';
import amexImg from '../../../../../assets/images/amex.svg';
import discoverImg from '../../../../../assets/images/discover.svg';
import payabliLogo from "../../../../../assets/images/payabli.png";
import { HeaderNameSubtitle } from '../headerNameSubtitle';

@inject('store', 'global', 'invoice')
@observer
class PreviewPaymentLink extends React.Component {
  getPriceFormatted(value) {
    return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(2))}`;
  }

  getPriceFormattedDynamic(value) {
    return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(this.props.global.formatDecimalDynamics(value)))}`;
  }

  getPriceFormattedDynamicAmount(value){
    if (value.toFixed(2) > 0) {
      return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(2))}`;
    } else {
      return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(this.props.global.formatDecimalDynamics(value)))}`;
    }
  }

  render() {
    const { paypointData, defaultSettings, pay, invoiceDetails, advancedOptions, items } = this.props.invoice;

    const { brandColor, contactUsEmail, contactUsPhone, contactUsText, footerNote } = defaultSettings;
    const dueDate = dayjs(invoiceDetails.dueDate).format('MMM D, YYYY');

    const { paylinkHeader, paylinkDescription, paymentMethods, memoNote } = advancedOptions;
    const { visa, mastercard, discover, amex } = paymentMethods;

    const logo = paypointData.logo || this.props.invoice.getInvoiceLogo;

    return (
      <div id="preview-paylink" className="preview-card">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="bar">
          <div className="bar-left" style={{ backgroundColor: brandColor }}></div>
          <div className="bar-right"></div>
        </div>

        <HeaderNameSubtitle
            paylinkHeader={paylinkHeader ? paylinkHeader : defaultSettings?.paylinkHeader}
            paylinkDescription={paylinkDescription ? paylinkDescription : defaultSettings?.paylinkDescription}
        />

        <h2 className="price">{this.getPriceFormatted(pay.subtotal)}</h2>

        <div className="invoice-data">
          <div className="group-data middle">
            <span className="group-header">Invoice #</span>
            <span className="group-value">{invoiceDetails.invoiceNumber}</span>
          </div>

          <div className="group-data middle">
            <span className="group-header">Due Date</span>
            <span className="group-value">{dueDate}</span>
          </div>
        </div>

        <span className="action-invoice-details">
          View invoice details <BiChevronDown />
        </span>

        {items.length > 0 && (
          <div className="invoice-details full-w">
            <div className="header">
              <div className="col product">Product/service</div>
              <div className="col unit">Unit Price</div>
              <div className="col qty">Qty</div>
              <div className="col amount">Amount</div>
            </div>
            <div className="items-body">
              {items.length > 0 &&
                items.map((item, idx) => (
                  <div key={idx} className="row-item">
                    <div className="details">
                      <div className="col product">{item.label ? item.label : '-'}</div>
                      <div className="col unit">{this.getPriceFormattedDynamic(item.value)}</div>
                      <div className="col qty">{item.quantity}</div>
                      <div className="col amount">{this.getPriceFormattedDynamicAmount(item.value * item.quantity)}</div>
                    </div>
                    {item.description && <div className="description">{item.description}</div>}
                  </div>
                ))}
            </div>
          </div>
        )}

        {/* ADD PAYMENT METHOD */}
        <PaymentMethod />

        <div className="review-total full-w">
          <h6 className="sub-header-line mb-3">Review &amp; Confirm Payment</h6>
          <div className="row">
            <div className="col">Subtotal:</div>
            <div className="col">{this.getPriceFormatted(pay.subtotal)}</div>
          </div>
          <div className="row">
            <div className="col">Service Fee:</div>
            <div className="col">{this.getPriceFormatted(pay.fee)}</div>
          </div>
          <div className="row total-amount">
            <div className="col">Total Amount:</div>
            <div className="col">{this.getPriceFormatted(pay.total)}</div>
          </div>
        </div>
        <span className="memo-note full-w">{memoNote}</span>
        <div className="btn-pay btn-group full-w btn-pay">
          <button type="button" className="btn btn-success btn-lg" style={{ backgroundColor: brandColor }}>
            Pay {this.getPriceFormatted(pay.subtotal)}
          </button>
        </div>
        <span className="memo-note full-w">{advancedOptions.footerNote || footerNote}</span>

        <p className="contact-us small-grey-m0 text-center full-w">
          <b>{contactUsText}</b>
          <br />
          {contactUsEmail} | {contactUsPhone}
        </p>

        <div className="card-brands text-center bottom-grey mt-3">
          <div>
            {visa && <img alt="" src={visaImg}/>}
            {mastercard && <img alt="" className="mastercard" src={mastercardImg}/>}
            {discover && <img alt="" className="discover" src={discoverImg} />}
            {amex && <img alt="" className="amex" src={amexImg} />}
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewPaymentLink;
