import React from "react";
import { inject, observer } from "mobx-react";

@inject("store", "applepay")
@observer
class ApplePayButtonLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.handleTextChangeSelect = this.handleTextChangeSelect.bind(this);
  }

  handleTextChangeSelect(e) {
    const selectedValue = e.target.value;
    this.props.store.setApplePayButton({language: selectedValue});
  }

  render() {
    return (
      <div>
        <div className="col-sm mb-3">
          <div className="form-floating">
            <select
              className="form-select form-control"
              id="appleButtonLanguage"
              name="appleButtonLanguage"
              onChange={this.handleTextChangeSelect}
              value={this.props.store.applePayButton.language}
              data-qaid="appleButtonLanguageInput"
            >
              {this.props.applepay.applePayButtonLanguages.map((language) => (
                <option key={language.value} value={language.value} title={language.description}>
                  {language.text}
                </option>
              ))}
            </select>
            <label htmlFor="appleButtonLanguage">Language</label>
          </div>
        </div>
      </div>
    );
  }
}

export { ApplePayButtonLanguage };
