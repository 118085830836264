import React from 'react';
import { inject, observer } from 'mobx-react';

import { EditVendor } from '../../../../../components/EditVendor';

@inject('store', 'global', 'vTerminal', 'vendor', 'bill')
@observer
class SectionVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchingVendor: false,
      searchVendorPanelIsOpen: false,
      vendorToSearch: '',
    };

    this.clearTextVendor = this.clearTextVendor.bind(this);
    this.closeSearchVendorPanelDelay = this.closeSearchVendorPanelDelay.bind(this);
    this.searchVendor = this.searchVendor.bind(this);
    this.selectVendor = this.selectVendor.bind(this);
    this.updateVendorToSearch = this.updateVendorToSearch.bind(this);
  }

  clearTextVendor() {
    this.setState({ vendorToSearch: '' });
    this.props.vTerminal.clearVendor();
    this.props.vTerminal.clearVendorSelected();
    this.props.bill.setVendorSelected({});
  }

  closeSearchVendorPanelDelay() {
    let _this = this;
    setTimeout(function () {
      _this.setState({ searchVendorPanelIsOpen: false });
      if (Object.keys(_this.props.vTerminal.getVendorSelected).length === 0) {
        _this.clearTextVendor();
      }
    }, 600);
  }

  searchVendor(e) {
    const { value } = e.target;

    this.setState({
      vendorToSearch: value,
      searchVendorPanelIsOpen: true,
    });

    
    if (value.length > 1) {
      this.setState({ searchingVendor: true });
      this.props.vTerminal
        .searchingVendor(value)
        .then((res) => {
          this.setState({ searchingVendor: false });
        })
        .catch((error) => {
          this.setState({ searchingVendor: false });
        });
    } else {
      this.props.vTerminal.clearVendor();
      this.props.bill.setVendorSelected({});
    }
  }

  updateVendorToSearch(vendor, result) {
    let thisObj = this;
    if(result.data.responseData){
      vendor = this.props.bill.parseLoadVendor(vendor);
      vendor.VendorId = result.data.responseData;
      this.props.bill.setVendorSelected(vendor);
      this.props.vTerminal.selectVendorObject(vendor);
      thisObj.setState({
        vendorToSearch: thisObj.props.vTerminal.getSelectedVendorString,
      });
    }
  }

  selectVendor(position, e) {
    e.preventDefault();
    this.props.bill.setVendorSelected(this.props.vTerminal.vendors[position]);
    this.props.vTerminal.selectVendor(position);
    this.setState(
      {
        vendorToSearch: this.props.vTerminal.getSelectedVendorString,
        searchVendorPanelIsOpen: false,
      },
      function () {
        this.props.vTerminal.clearVendor();
      }
    );
  }

  componentDidMount() {
    
    let thisObj = this;
    setTimeout(function(){
      if(thisObj.props.bill && thisObj.props.bill.getVendorSelected){
        thisObj.setState({ vendorToSearch: thisObj.props.vTerminal.getSelectedVendorString !== "  - Email" ? thisObj.props.vTerminal.getSelectedVendorString : "" });
      }
    }, 1000)
  }

  componentDidUpdate() {
    if (this.props.vTerminal.getVendorSelected.VendorId && this.state.vendorToSearch !== this.props.vTerminal.getSelectedVendorString) {
      this.setState({ vendorToSearch: this.props.vTerminal.getSelectedVendorString });
    }
  }

  render() {

    let vendorToSearch = (this.state.vendorToSearch === "  - Email" ? this.props.vTerminal.getSelectedVendorString : this.state.vendorToSearch);

    return (
      <>
        <h6 className="mb-3">Vendor</h6>
        <div className="form-floating">
          <input
            className={
              !this.state.searchingCustomer ? 'form-control search-input' : 'form-control search-input loading'
            }
            autoComplete="off"
            onFocus={(e) => this.clearTextVendor(e)}
            onBlur={(e) => this.closeSearchVendorPanelDelay(e)}
            onChange={(e) => this.searchVendor(e)}
            value={vendorToSearch}
            id="vendor"
            placeholder="Search for a customer"
            disabled={this.props.isView}
          />
          <label htmlFor="vendor">Find or add a vendor...</label>

          <div
            className={this.state.searchVendorPanelIsOpen ? 'search-dropdown-menu' : 'd-none search-dropdown-menu'}
          >
            <EditVendor
              rewriteSelectedWithResponse={true}
              functionCallbackNew={this.updateVendorToSearch}
              defaultName={this.state.vendorToSearch}
              title={'Add vendor'}
              label={'+ Add vendor'}
              style={{ color: '#10A0E3' }}
              action={'new'}
              viewAction={"new"}
              isEmbedded={this.props.isEmbedded}
            />

            {this.props.vTerminal.vendors.map((record, i) => (
              <a key={i} href="/" onClick={(e) => this.selectVendor(i, e)} className="dropdown-item">
                {record.Name1 + ' ' + (record.Name2 ? record.Name2 : '')} - {record.Email}<br/>
                <div className='separatorBetween'>
                  {record.VendorNumber ? <span className='small-small grey'>Vendor # {record.VendorNumber}</span> : ""}
                  {record.PaypointLegalname ? <span className='small-small grey'>{record.PaypointLegalname.length > 20 ? record.PaypointLegalname.substring(0,20)+ " ..." : record.PaypointLegalname}</span> : ""}
                  {record.Address1 ? <span className='small-small grey'>{record.Address1.substring(0,20)+ " ..."}</span> : ""}
                  {record.Phone ? <span className='small-small grey'>{record.Phone}</span> : ""}
                </div>
              </a>
            ))}

            {this.props.vTerminal.vendors.length === 0 && this.state.vendorToSearch && this.state.vendorToSearch.length > 2 && (
              <div className="small-grey ml-2">No results found</div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export { SectionVendor };
