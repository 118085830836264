import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportsLinks } from '../../components/ReportsLinks';
import { ReportPaginator } from '../../components/ReportPaginator';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { DropdownButton, Dropdown, Modal, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { Layout } from '../../components/Layout'
import { PayabliStorageManager } from '../../api/localStorageManager';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {Link} from "react-router-dom";
import { RightPanel } from '../../components/RightPanel';
import {IMaskInput} from 'react-imask';
import { MainBar } from '../../components/MainBar';
import { BiSortAlt2, BiDotsVerticalRounded, BiNotepad, BiMailSend } from 'react-icons/bi';
import { MdOutlineCallSplit } from 'react-icons/md'
import { QuickViewTransaction } from '../../components/QuickViewTransaction';

@inject('reports', 'global')
@observer
class BatchesTransactionsReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customFields: [],
            sortDirection: "desc",
            sendReceiptModalIsOpen: true,
            sendReceiptAddress: "",
            sendReceiptError: true,
            transIdToSendReceipt: null,
            flagItemAllColumn: true,
            batchNumber: null,
            idBatch: null,
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.filter = this.filter.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.sendTransactionReceipt = this.sendTransactionReceipt.bind(this);
        this.openSendReceiptModal = this.openSendReceiptModal.bind(this);
        this.closeSendReceiptModal = this.closeSendReceiptModal.bind(this);
        this.sendReceiptTransactionAction = this.sendReceiptTransactionAction.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.quickViewTransaction = React.createRef();
    }
    clearFilters(){
        this.setState({ head: "" }, function(){
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }
    componentDidMount() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        
        this.setState({ 
            idBatch: this.props.match.params.idBatch ? this.props.match.params.idBatch : null,
            orgId: idOrg
        });

        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                BatchNumber     : { label:'Batch #', class: '', display: true },
                BatchDate       : { label:'Batch Date', class: '', display: true},
                PaymentId       : { label:'Payment ID', class: '', display: false },
                GatewayTransId  : { label:'Transaction ID', class: '', display: true }, 
                OrderId         : { label:'Order ID', class: '', display: false}, 
                ExternalPaypointID  : { label:'External Paypoint ID', class: '', display: false },
                Customer        : { label:'Customer', class: '', display: true }, 
                Company         : { label:'Company', class: '', display: true},
                CustomerNumber  : { label:'Customer #', class: '', display: false },
                BillingEmail    : { label:'Email', class: '', display: false }, 
                PayorPhone      : { label:'Phone #', class: '', display: false },
                BillingAddress  : { label:'Billing Address', class: '', display: false},
                ShippingAddress : { label:'Shipping Address', class: '', display: false},
                InvoiceNumber   : { label:'Invoice #', class: '', display: true},
                Date            : { label:'Date', class: '', display: true, filter: 'date'},
                Time            : { label:'Time (' + this.props.global.getTimeZone('v1') + ')', class: '', display: true}, 
                Brand           : { label:'Pay Method', class: 'text-center', display: true }, 
                Last4           : { label:'Last 4', class: '', display: true }, 
                Type            : { label:'Type', class: '', display: true },
		        Cycle           : { label:'Cycle', class: '', display: false},
                Status          : { label:'Batch Status', class: '', display: true}, 
                NetAmount       : { label:'Amount', class: 'text-right', display: true },
                FeeAmount       : { label:'Fee', class: 'text-right', display: false},
                SettledAmount   : { label:'Total Amount', class: 'text-right', display: false},
                currency        : { label:'Currency', class: 'text-center', display: false }, 
                AuthCode        : { label:'Auth. Code', class: '', display: false}, 
                Source          : { label:'Source', class: '', display: false},
                Notes           : { label:'Notes', class: 'text-center', display: true} 
            }
        )        
        this.props.reports.setRecords([]);
        this.getReportFromApi();
    }

    componentWillUnmount(){
        this.props.reports.clearFilters();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    openSendReceiptModal(){
        this.setState({ sendReceiptModalIsOpen: false });
    }
    
    closeSendReceiptModal(){
        this.setState({ sendReceiptModalIsOpen: true });
    }

    sendTransactionReceipt(transaction, email){
        this.setState({ sendReceiptAddress: email, transIdToSendReceipt: transaction , sendReceiptError: email ? false : true });
        this.openSendReceiptModal();
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }

    exportFile(format) {
        this.props.reports.exportFile('settlements', format, this.state.orgId);
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    getMappingFields(){
        return {
            'batchNumber': 'batchnumber',
            'batchDate': 'createat',
            'paymentId': 'transid',
            'gatewayTransId': 'gatewaytransid',
            'orderId': 'orderid',
            'parentOrgName': 'orgname',
            'paypointLegalname': 'paypointlegal',
            'ExternalPaypointID': 'extarnalpaypointid',
            'customer': 'customerfullname',
            'company': 'customercompanyname',
            'customerNumber': 'customernumber',
            'billingEmail': 'customeremail',
            'payorPhone': 'customerphone',
            'billingAddress': 'customeraddress',
            'shippingAddress': 'customershippingaddress',
            'invoiceNumber': 'invoicenumber',
            'date': 'date',
            'brand': 'method',
            'last4': 'last4',
            'type': 'operation',
            'cycle': 'scheduleid',
            'status': 'status',
            'netAmount': 'netamount',
            'settledAmount': 'settledamount',
            // 'authCode': 'authcode',
            'source': 'source',
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }
    getReportFromApi(){
        let tempFilterValue = {};
        if(this.state.sortBy){
            tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
        }
        if(this.props.match.params.idBatch){
            tempFilterValue['batchId(eq)'] = this.props.match.params.idBatch;
        }
        this.filter(tempFilterValue);
    }

    handleSendReceiptAddress(value){
        this.setState({ sendReceiptAddress: value });
        if(!this.props.global.validators.isEmpty(value) && !this.props.global.validators.stringValidator('email',value)){
            this.setState({ sendReceiptError: false });
        }
        else{
            this.setState({ sendReceiptError: true });
        }
    }

    sendReceiptTransactionAction(){
        if(this.state.sendReceiptError){
            return;
        }

        let transIdToSendReceipt = this.state.transIdToSendReceipt;
        let sendReceiptAddress = this.state.sendReceiptAddress;
        
        if(transIdToSendReceipt && sendReceiptAddress){
            this.props.global.setLoading(true);
            this.props.reports.sendReceiptTransaction(transIdToSendReceipt, sendReceiptAddress)
            .then(result => {
                this.setState({ sendReceiptModalIsOpen: true });
                this.props.global.setLoading(false);
                toast.success("Transaction Receipt Emailed Successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'settlements').then(res => {
            if(this.props.match.params.idBatch){
                let tempBatchNumber = (this.props.reports.records.length > 0 ? this.props.reports.records[0].BatchNumber : null);
                this.setState({batchNumber: tempBatchNumber});
            }
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    viewRecordDetails(index, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            let thisObj = this;
            if(thisObj.props.global.rightPanelOpen && thisObj.props.reports.recordDetails !== index){
                thisObj.props.global.setRightPanelOpen(false);
                thisObj.props.reports.setRecordDetails(index);
                setTimeout(function(){
                    thisObj.props.global.setRightPanelOpen(true);
                }, 500)                
            }else{
                thisObj.props.reports.setRecordDetails(index);
                thisObj.props.global.setRightPanelOpen(true);
            }
        }
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];

        return (
            <Layout {...this.props}>
            <div>
                <RightPanel>
                    <h5 className="header mb-3" data-qaid="batchTransactionsDetailsMoneyIn">Transaction Details</h5>
                    <QuickViewTransaction ref={this.quickViewTransaction} />
                </RightPanel>
                <Modal style={{textAlign: "center"}} show={!this.state.sendReceiptModalIsOpen} onHide={this.closeSendReceiptModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiMailSend className="icon-modal"/>
                    <h5 data-qaid="sendReceiptModalBatchTransactionsMoneyIn">Send Receipt</h5>
                    <p className="small">Transaction Id:</p>
                    <p className="small-grey">{this.state.transIdToSendReceipt}</p>

                    <IMaskInput
                        mask={this.props.global.maskValidator('email')}
                        value={this.state.sendReceiptAddress}
                        unmask={true}
                        placeholder= "Email"
                        className={this.state.sendReceiptError ? "form-control mb-3 input-error" : "form-control mb-3" }
                        onAccept={
                            (value, mask) => this.handleSendReceiptAddress(value)
                        }
                    />
                    
                    <div className="row">
                        <div className="col">
                            <Button className="btn full-w  cancel-btn" variant="default" onClick={this.closeSendReceiptModal}>
                                Cancel
                            </Button>
                        </div>
                        <div className="col">
                            <Button className="btn full-w" variant="primary" onClick={this.sendReceiptTransactionAction} data-qaid="sendButtonBatchTransactionsMoneyIn">
                                Send
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                </Modal>
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <ReportsLinks selected="batches" goback={this.state.idBatch ? true : false} history={this.props.history}/>
                </div>
                </TopBar>
                
                <ParentFilterPanel report={'settlements'} customFields={this.state.customFields} batchId={this.props.match.params.idBatch} />

                <div className="mt-body4">
                    <MainBar
                        reportTitle={'Batches Payments'}
                        reportName={"batchesTransactions"}
                        dataQAName="BatchTransactions"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        withTotal={true}
                        reportTransaction={true}
                        totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    />
                <div className="report-container">  
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            ( 
                                (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                                record[1].display && 
                                (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                    <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                                </th> ))
                                ||
                                (record[1].display && 
                                (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                                </th> ))
                            )
                        ))
                    }
                    <th className='text-center sticky-row' scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i}  className="cursorPointer" onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} onClick={(e) => this.viewRecordDetails(i,e)} data-qaid={"batchTransactionsMoneyInRow-"+(i)}>
                            { (this.props.reports.headers.BatchNumber && this.props.reports.headers.BatchNumber.display) &&
                                <td>{record.BatchNumber}</td>
                            }
                            { (this.props.reports.headers.BatchDate && this.props.reports.headers.BatchDate.display) &&
                                <td>{this.props.global.stringDateFormatV3(record.CreatedAt)}</td>
                            }
                            { (this.props.reports.headers.PaymentId && this.props.reports.headers.PaymentId.display) &&
                                <td>
                                    {record.PaymentTransId ? record.PaymentTransId.substring(0, 20) : ''}...
                                </td>
                            }
                            { (this.props.reports.headers.ParentOrgName && this.props.reports.headers.ParentOrgName.display) &&
                                <td>{record && record.ParentOrgName ? record.ParentOrgName: '-'}</td>
                            }
                            { (this.props.reports.headers.GatewayTransId && this.props.reports.headers.GatewayTransId.display) &&
                                <td>{record && record.gatewayTransId ? record.gatewayTransId: '-'}</td>
                            }
                            { (this.props.reports.headers.OrderId && this.props.reports.headers.OrderId.display) &&
                                <td>{record && record.OrderId ? record.OrderId: '-'}</td>
                            }
                            { (this.props.reports.headers.ExternalPaypointID && this.props.reports.headers.ExternalPaypointID.display) &&
                                <td>{record && record.externalPaypointID ? record.externalPaypointID : '-'}</td>
                            }
                            { (this.props.reports.headers.Customer && this.props.reports.headers.Customer.display) &&
                                <td>
                                    <div className='d-flex align-items-center flex-nowrap gap-1'>
                                        <span>{record.Customer && record.Customer.FirstName ? this.props.global.capitalizeFirstLetterOfEachWord(record.Customer.FirstName) : '-'} { record.Customer && record.Customer.LastName ? this.props.global.capitalizeFirstLetterOfEachWord(record.Customer.LastName) : ''}</span>
                                        {Array.isArray(record.PaymentData?.paymentDetails?.splitFunding) && 
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Split Funded</Tooltip>}>
                                                <MdOutlineCallSplit style={{color:'#7B61FF', transform: 'rotate(90deg)', width:'16px', height:'16px'}}/>
                                            </OverlayTrigger>
                                        }
                                    </div>
                                </td>
                            }
                            { (this.props.reports.headers.Company && this.props.reports.headers.Company.display) &&
                                <td>{record.Customer && record.Customer.CompanyName ? this.props.global.capitalizeFirstLetterOfEachWord(record.Customer.CompanyName) : '-'}</td>
                            }                            
                            { (this.props.reports.headers.CustomerNumber && this.props.reports.headers.CustomerNumber.display) &&
                                <td>{record.Customer && record.Customer.CustomerNumber ? record.Customer.CustomerNumber.length > 15 ? record.Customer.CustomerNumber.substring(0, 15) + "..." : record.Customer.CustomerNumber : '-'} </td>
                            }
                            { (this.props.reports.headers.BillingEmail && this.props.reports.headers.BillingEmail.display) &&
                                <td>{record.Customer && record.Customer.BillingEmail ? record.Customer.BillingEmail : '-'} </td>
                            }                            
                            { (this.props.reports.headers.PayorPhone && this.props.reports.headers.PayorPhone.display) &&
                                <td>{record.Customer && record.Customer.BillingPhone ? this.props.global.phoneNumberFormat(record.Customer.BillingPhone): '-'} </td>
                            }                           
                            { (this.props.reports.headers.BillingAddress && this.props.reports.headers.BillingAddress.display) &&
                                <td>{record.Customer && record.Customer.BillingAddress1 ? record.Customer.BillingAddress1 +" "+ record.Customer.BillingAddress2+" "+ record.Customer.BillingCity+" "+ record.Customer.BillingState+" "+ record.Customer.BillingZip+" "+ record.Customer.BillingCountry: '-'} </td>
                            }                            
                            { (this.props.reports.headers.ShippingAddress && this.props.reports.headers.ShippingAddress.display) &&
                                <td>{record.Customer && record.Customer.ShippingAddress1 ? record.Customer.ShippingAddress1 +" "+ record.Customer.ShippingAddress2+" "+ record.Customer.ShippingCity+" "+ record.Customer.ShippingState+" "+ record.Customer.ShippingZip+" "+ record.Customer.ShippingCountry: '-'} </td>
                            }
                            { (this.props.reports.headers.InvoiceNumber && this.props.reports.headers.InvoiceNumber.display) &&
                                <td>{record.invoiceData && record.invoiceData.invoiceNumber ? record.invoiceData.invoiceNumber: '-'}</td>
                            }
                            { (this.props.reports.headers.Date && this.props.reports.headers.Date.display) &&
                                <td>{this.props.global.stringDateFormatV3(record.TransactionTime)}</td>
                            }
                            { (this.props.reports.headers.Time && this.props.reports.headers.Time.display) && 
                                <td>{this.props.global.stringTimeFormat(record.TransactionTime)}</td>
                            }
                            { (this.props.reports.headers.Brand && this.props.reports.headers.Brand.display) && 
                                <td className='text-center'>
                                    {this.props.global.getPaymethodImgPaymentData(record)}
                                </td>
                            }
                            { (this.props.reports.headers.Last4 && this.props.reports.headers.Last4.display) && 
                                <td>{ (record && record.PaymentData && record.PaymentData.MaskedAccount )? this.props.global.maskedCardNumber(record.PaymentData.MaskedAccount, "v5") : '-'}</td>
                            }
                            { (this.props.reports.headers.Type && this.props.reports.headers.Type.display) && 
                                <td>{record.Operation}</td>
                            }
                            { (this.props.reports.headers.Cycle && this.props.reports.headers.Cycle.display) && 
                                <td>{record.ScheduleReference > 0 ? 'Scheduled' : 'One-Time'}</td>
                            }
                            { (this.props.reports.headers.Status && this.props.reports.headers.Status.display) && 
                                <td>{this.props.global.getSettlementTransStatus(record.Status)}</td>
                            }
                            { (this.props.reports.headers.NetAmount && this.props.reports.headers.NetAmount.display) && 
                                <td className='text-right'>{this.props.global.numberWithCommasNegative(record.NetAmount ? record.NetAmount.toFixed(2) : '0.00')}</td>
                            }
                            { (this.props.reports.headers.FeeAmount && this.props.reports.headers.FeeAmount.display) && 
                                <td className='text-right'>{this.props.global.numberWithCommasNegative(record?.PaymentData?.paymentDetails?.serviceFee ? record.PaymentData.paymentDetails.serviceFee.toFixed(2) : '0.00')}</td>
                            }
                            { (this.props.reports.headers.SettledAmount && this.props.reports.headers.SettledAmount.display) && 
                                <td className='text-right'>{this.props.global.numberWithCommasNegative(record.SettledAmount ? record.SettledAmount.toFixed(2) : '0.00')}</td>
                            }
                            { (this.props.reports.headers.currency && this.props.reports.headers.currency.display) && 
                                <td className='text-center'>{record.PaymentData ? record.PaymentData.paymentDetails.currency ? record.PaymentData.paymentDetails.currency : '-' : '-'}</td>
                            }
                            { (this.props.reports.headers.AuthCode && this.props.reports.headers.AuthCode.display) && 
                                <td>{record?.ResponseData?.authcode ? record.ResponseData.authcode : '-'}</td>
                            }                            
                            { (this.props.reports.headers.Source && this.props.reports.headers.Source.display) && 
                                <td>{record.Source ? this.props.global.capitalizeFirstLetter(record.Source) : '-'}</td>
                            }
                            { (this.props.reports.headers.Notes && this.props.reports.headers.Notes.display) && 
                                <td className='text-center'>
                                    {record.PaymentData && record.PaymentData.orderDescription ? 
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{record.PaymentData.orderDescription}</Tooltip>}
                                        >
                                        <BiNotepad className="label-info-icon in-input"/>
                                        </OverlayTrigger>
                                    : '-'}
                                </td>
                            }
                            {
                                 this.state.customFields.map((field, i) => (
                                    (this.props.reports.headers[field.key] && this.props.reports.headers[field.key].display) && 
                                     <td key={i}>{record.Customer && record.Customer.AdditionalData && record.Customer.AdditionalData[field.key] ? record.Customer.AdditionalData[field.key] : '-'}</td>
                                 ))
                            }                            
                            <td className='text-center sticky-row'>
                                <DropdownButton
                                    menuAlign="right"
                                    title={<BiDotsVerticalRounded/>}
                                    data-qaid="actionBatchTransactionsMoneyIn"
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                <Dropdown.Item data-qaid="quickViewBatchTransactionsMoneyIn" onClick={(e) => this.viewRecordDetails(i)}><div>Quick View</div></Dropdown.Item>
                                <Dropdown.Item data-qaid="sendReceiptBatchTransactionsMoneyIn" onClick={() => this.sendTransactionReceipt(record.PaymentTransId, record.Customer?record.Customer.BillingEmail:'') }>Send Receipt</Dropdown.Item>
                                {(userPermissions.indexOf("Customers") !== -1 && record.PayorId>0)  &&
                                <Dropdown.Item as={Link} to={ record.Customer ? "/customer/"+ record.Customer.customerId : ''}>View Customer</Dropdown.Item>
                                }
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No transactions yet" description={<span>When funding events occur,<br/>you can track them here to help reconcile your bank deposits.</span>}/>
                }
                </div>
                <ReportPaginator report="settlements"/>

                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { BatchesTransactionsReport };
