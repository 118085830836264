import React from "react";
import { inject, observer } from "mobx-react";
import { Brand } from "./Brand";

@inject("store")
@observer
class PaymentPageLogo extends React.Component {
  render() {
    const { furl } = this.props.store.getPaymentPageLogo;
    let imageBrand = "";
    if (typeof furl === "string") {
      imageBrand = furl;
    }
    return <Brand classBrand={"header-brand"} imageBrand={imageBrand} />;
  }
}

export { PaymentPageLogo };
