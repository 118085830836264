import React from 'react';
import { inject, observer } from 'mobx-react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

@inject('store', 'global', 'reports', 'bill')
@observer
class BillSchedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedDefault: false,
      startDate: null,
      endDate: null,
      frequency: 'onetime',
      formErrors: {},
      // options
      optionsFrequency: [
        { value: 'onetime', label: 'One Time' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'every2weeks', label: 'Every 2 weeks' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'every3Months', label: 'Every 3 Months' },
        { value: 'every6Months', label: 'Every 6 Months' },
      ],
      colVisibleSchedule: 2,
    };

    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleFrequency = this.handleFrequency.bind(this);
    this.endDateBillCalendar = React.createRef();
  }

  handleStartDate(date) {
    this.setState({
      startDate: date,
      endDate: null,
    });
    this.props.bill.setBillDetails({
      startDate: date,
      endDate: null,
    });

    const { billDetails } = this.props.bill;

   if(billDetails.startDate > billDetails.dueDate){
      this.props.bill.setBillDetails({
        dueDate: billDetails.startDate
      });
    }

    if(billDetails.term){
      this.props.bill.setBillDetails({
        dueDate: this.props.bill.getCalculateDueDateFromStartDateTerms(),
      });
    }
  }

  handleEndDate(date) {
    this.setState({ endDate: date });
    this.props.bill.setBillDetails({
      endDate: date,
    });
    this.endDateBillCalendar.current.setOpen(false)
  }

  handleFrequency(e) {
    const { value } = e.target;
    let { endDate, colVisibleSchedule } = this.state;

    if (value === 'onetime') {
      endDate = null;
      colVisibleSchedule = 2;
    } else {
      colVisibleSchedule = 3;
    }

    this.setState({ frequency: value, endDate, colVisibleSchedule });
    this.props.bill.setBillDetails({
      frequency: value,
      endDate,
    });
  }

  componentDidMount() {
    const billStatus = this.props.bill.status;
    const billDataLoaded = this.props.billDataLoaded;

    if (billStatus !== 'new' && !this.state.loadedDefault && billDataLoaded) {
      let startDate  = billDataLoaded.BillDate;
      let billFrequency = billDataLoaded.Frequency;
      startDate = startDate ? new Date(startDate) : null;

      let colVisibleSchedule = 2;
      if (billFrequency && billFrequency !== 'onetime') {
        colVisibleSchedule = 3;
      }

      let endDate = billDataLoaded.endDate ? new Date(billDataLoaded.endDate) : null;
      endDate = endDate === null ? "untilcancelled" : endDate;
      this.setState({
        loadedDefault: true,
        startDate: startDate,
        endDate: endDate,
        frequency: billFrequency,
        colVisibleSchedule,
      });

      this.props.bill.setBillDetails({
        startDate: startDate,
        frequency: billFrequency,
        endDate: endDate,
      });
    }
  }

  render() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const { billDetails } = this.props.bill;

    let minEndDate = tomorrow;
    if (this.state.startDate) {
      minEndDate = new Date(this.state.startDate);
      minEndDate.setDate(minEndDate.getDate() + 1);
    }

    return (
      <div className="row">
        <div className={`col-${12 / this.state.colVisibleSchedule}`}>
          <DatePicker
            customInput={
              <div className="form-floating">
                <input
                  readOnly
                  autoComplete="off"
                  onChange={function () {}}
                  value={billDetails.startDate ? this.props.reports.dateStringFormat(billDetails.startDate) : ''}
                  name="startDate"
                  id="startDate"
                  className={
                    this.state.formErrors.startDate
                      ? 'form-control input-calendar input-error input-lg'
                      : 'form-control input-calendar input-lg'
                  }
                  placeholder="Select Date"
                  disabled={this.props.isView}
                />
                <label htmlFor="startDate">{this.state.frequency === "onetime" ? "Bill Date":"Start Date"}</label>
              </div>
            }
            selected={billDetails.startDate}
            onChange={(date) => this.handleStartDate(date)}
            minDate={tomorrow}
            dayClassName={(date) => 'calendar-day'}
            popperPlacement="bottom-start"
            disabled={this.props.isView}
          />
        </div>

        <div className={`col-${12 / this.state.colVisibleSchedule}`}>
          <div className="form-floating">
            <select
              className="form-control form-select"
              name="frequency"
              id="frequency"
              value={this.state.frequency}
              onChange={this.handleFrequency}
              disabled={this.props.isView}
            >
              {this.state.optionsFrequency.map((record, i) => (
                <option value={record.value} key={i}>
                  {record.label}
                </option>
              ))}
            </select>
            <label htmlFor="frequency" className="font-weight-bold">
              Frequency
            </label>
          </div>
        </div>

        {this.state.frequency !== 'onetime' && (
          <div className={`col-${12 / this.state.colVisibleSchedule}`}>
            <DatePicker
              customInput={
                <div className="form-floating">
                  <input
                    readOnly
                    autoComplete="off"
                    onChange={function () {}}
                    value={this.state.endDate && this.state.endDate instanceof Date ? this.props.reports.dateStringFormat(this.state.endDate) : this.state.endDate === 'untilcancelled' ? "Until Cancelled" : '' }
                    name="dueDateCustom"
                    id="dueDateCustom"
                    className={
                      this.state.formErrors.endDate
                        ? 'form-control input-calendar input-error input-lg'
                        : 'form-control input-calendar input-lg'
                    }
                    placeholder="Select Date"
                    disabled={this.props.isView}
                  />
                  <label htmlFor="endDate">End Date</label>
                </div>
              }
              selected={this.state.endDate && this.state.endDate instanceof Date ? this.state.endDate : ''}
              onChange={(date) => this.handleEndDate(date)}
              minDate={minEndDate}
              dayClassName={(date) => 'calendar-day'}
              popperPlacement="bottom-start"
              ref={this.endDateBillCalendar}
              disabled={this.props.isView}
              >
                 <div className="linkCalendar" onClick={(e)=>this.handleEndDate('untilcancelled')}>Until Cancelled</div>
              </DatePicker>
          
          </div>
        )}
      </div>
    );
  }
}

export default BillSchedule;
