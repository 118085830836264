import React from 'react';
import { inject, observer } from 'mobx-react';
import { PaymentMethodsAccepted } from '../../components/PaymentMethodsAccepted';
import { BiCheckCircle, BiChevronLeft} from "react-icons/bi";
import visa from '../../assets/images/visa.svg';
import calendar from '../../assets/images/calendar.svg';
import {Link} from "react-router-dom";
import { TopFixedMessage } from '../../components/TopFixedMessage';
import { PaymentPageLogo } from '../../components/PaymentPageLogo';

import {Helmet} from "react-helmet";
import { PayabliStorageManager } from '../../api/localStorageManager';

@inject('store','global')
@observer
class PreviewReceipt extends React.Component {

    prevent(e) {
        e.preventDefault();
    }    

    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.props.store.setCurrentPage('payment-page-receipt-preview');
        var subdomain = this.props.match.params.subdomain;
        
        if(subdomain){
            this.props.global.setLoading(true);
            this.props.store.getPaymentPageFromApi(subdomain).then(result => {
                this.props.global.setLoading(false);
            })
            .catch(error => {
                this.props.history.push('/404');
            });
        }
        else{
            this.props.history.push('/404');
        }
       
    }

    sortBlocks(inputArr){
        let len = inputArr.length;
        for (let i = 0; i < len ; i++) {
            for (let j = 0; j < len - 1 ; j++) {
                if (inputArr[j].order > inputArr[j + 1].order) {
                    let tmp = inputArr[j];
                    inputArr[j] = inputArr[j + 1];
                    inputArr[j + 1] = tmp;
                }
            }
        }
        return inputArr;
    }

    renderSortBlocks(){
        // eslint-disable-next-line no-unused-vars
        const btnTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };

        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };
        
        var blocksUI = [
                {
                    elements: <>{ this.props.store.paymentReceipt.logo.enabled &&
                        <li className="ui-state-default dragable-panel" id="blocklogo" style={{paddingBottom: 0}}> 
                        <div className="text-center">
                            <PaymentPageLogo/>
                        </div>
                        </li>
                        }</> ,
                    order: this.props.store.paymentReceipt.logo.order ? this.props.store.paymentReceipt.logo.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.page.enabled &&
                        <li className="ui-state-default dragable-panel" id="blockpage" style={{paddingTop: 0}}>
                            <div className={ !this.props.store.paymentReceipt.page.enabled ? 'opacity-panel' : ''}>
                                <div className="row">
                                    <div className="col-md-8 offset-md-2 progress-steps mb-3">
                                        <div className="row">
                                            <div style={bgTheme} className="col active">
                                                &nbsp;
                                            </div>
                                            <div style={bgTheme} className="col active">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="card-title text-center">{this.props.store.paymentReceipt.page.header ? this.props.store.paymentReceipt.page.header : '[Page Header]'}</h3>
                                        <p className="small small-grey text-center m0" dangerouslySetInnerHTML={{__html: this.props.store.paymentReceipt.page.description ? this.props.store.paymentReceipt.page.description : '[Page Description]' }}></p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        }</> ,
                    order: this.props.store.paymentReceipt.page.order ? this.props.store.paymentReceipt.page.order : 0
                },

                {
                    elements: <li className="ui-state-default dragable-panel text-center" id="blockamount">
                                    <div className="big-amount">$0.00</div>
                                    <p><BiCheckCircle style={{fontSize: '28px', color: "rgb(136,201,82)"}}/> Approved Payment</p>
                                </li>
                    ,
                    order: this.props.store.paymentReceipt.amount.order ? this.props.store.paymentReceipt.amount.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.settings.enabled &&
                                <li className="ui-state-default dragable-panel" id="blocksettings"> 
                                {this.props.store.getPaymentReceiptSettingsFields && 
                                    <div className="review-total">
                                        <div className="row">
                                            {this.props.store.getPaymentReceiptSettingsFields.map((block, i) => (                                           
                                                (block.display &&
                                                    <div className={`col-sm-12 mb-1 text-center text-muted`} key={i}>
                                                        {block?.label || block.name}: {block.name} 
                                                    </div>
                                                )
                                            ))}
                                    </div>
                                    </div>      
                                }
                            </li>
                            }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.settings.order ? this.props.store.paymentReceipt.settings.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.details.enabled &&
                            <li className="ui-state-default dragable-panel" id="blockdetails">
                            <div className="review-total">
                                <div className="row">
                                <div className="col">Payment Details:</div>
                                <div className="col"></div>
                                </div>
                                <div className="row mt-2">
                                <div className="col">Payment:</div>
                                <div className="col">$0.00</div>
                                </div>

                                <div className="row mt-2">
                                <div className="col">Fee:</div>
                                <div className="col">$0.00</div>
                                </div>

                                <div className="top-border-grey mt-2">
                                <div className="row total-amount">
                                    <div className="col">Total Amount:</div>
                                    <div className="col">$0.00</div>
                                </div>
                                </div>
                            </div>
                        </li>
                            }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.details.order ? this.props.store.paymentReceipt.details.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.paymentInformation.enabled &&
                            <li className="ui-state-default dragable-panel small" id="blockpaymentInformation">
                                
                            <div className="mb-2"><b>Payment Information</b></div>
                            <div className="row mb-1">
                                <div className="col-2 text-center">
                                    <img className="payment-icon" alt="" src={visa}/>
                                </div>
                                <div className="col-10">My Credit Card •••• 7734</div>
                            </div>
                            <div className="bottom-border-grey">
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img style={{width: '18px'}} alt="" src={calendar}/>
                                </div>
                                <div className="col-10">Paid on March 7th, 2021</div>
                            </div>
                            </div>
                            </li>
                            }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.paymentInformation.order ? this.props.store.paymentReceipt.paymentInformation.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.messageBeforeButton.enabled &&
                        <li className="ui-state-default dragable-panel" id="blockmessageBeforeButton">
                        <p className="small small-grey" style={{margin:0}} dangerouslySetInnerHTML={{__html: this.props.store.paymentReceipt.messageBeforeButton.label ? this.props.store.paymentReceipt.messageBeforeButton.label : '[Message Before Button]' }}>
                        </p>
                        </li>
                        }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.messageBeforeButton.order ? this.props.store.paymentReceipt.messageBeforeButton.order : 0
                },

                /*{
                    elements: <li className="ui-state-default dragable-panel" id="blockpaymentButton">
                                <div className="btn-group full-w">
                                    <button style={btnTheme} type="button" onClick={this.testDataJson} className="btn btn-success btn-lg">{this.props.store.paymentReceipt.paymentButton.label}</button>
                                </div>
                            </li>

                    ,
                    order: this.props.store.paymentReceipt.paymentButton.order ? this.props.store.paymentReceipt.paymentButton.order : 0
                },*/

                {
                    elements: <>{ this.props.store.paymentReceipt.contactUs.enabled &&
                        <li className="ui-state-default dragable-panel" id="blockcontactUs">   
                            <p className="small-grey-m0 text-center ">
                                <b>{this.props.store.paymentPage.contactUs.header ? this.props.store.paymentPage.contactUs.header : '[Contact Us Header]'}</b>
                                <br/>
                                {this.props.store.paymentPage.contactUs.emailLabel ? this.props.store.paymentPage.contactUs.emailLabel : '[email]'} | {this.props.store.paymentPage.contactUs.phoneLabel ? this.props.store.paymentPage.contactUs.phoneLabel : '[Phone Number]'}
                            </p>
                        </li>
                        }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.contactUs.order ? this.props.store.paymentReceipt.contactUs.order : 0
                },
            ];

        return this.sortBlocks(blocksUI);
    }


    render() {
        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
            height: "50%"
        };

        const linkTheme = {
            color: this.props.store.paymentPageSettings.color,
        };
       
        return (
            <div>
            {this.props.global.isLoading &&
            <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status">
                </div>
            </div>
            }
            <div className="bg-decorator" style={bgTheme}>&nbsp;</div>
            <div className="mt-body3">
            <div className="d-flex flex-column sjustify-content-center align-items-center">
                <TopFixedMessage message="This page is a Page Preview"/>
                <div className="card" style={{width: '50em'}}>
                    <div className="card-body  main-cont">
                        <Link className="btn btn-light text-transform-normal btn-back" to={"/"+PayabliStorageManager.getEntryName()+"/preview/"+ this.props.store.getPaymentPageSubdomain}><BiChevronLeft/> Go back</Link>
                        <ul className="sorted" ref={this.sortablePayer}>
                            { this.renderSortBlocks().map((block, i) => (
                                (<div key={i}>
                                {block.elements}
                                </div>)
                            ))}
                       </ul>
                    </div>

                    { this.props.store.paymentPage.contactUs.paymentIcons && 
                    <div className="card-brands text-center bottom-grey mt-3">
                        <PaymentMethodsAccepted/>
                    </div>
                    }
                </div>
                <p className="small-small mt-5">Powered by  <a rel="noreferrer" style={linkTheme} target="_blank" href="https://www.payabli.com/" className="small no-underline">Payabli</a></p>
            </div>
            </div>
            {(this.props.store && this.props.store.paymentPageSettings.customCssUrl) &&
            <Helmet>
            <link rel="stylesheet" href={this.props.store.paymentPageSettings.customCssUrl}/>
            </Helmet>
            }
            </div>
        );
    }
}

export { PreviewReceipt };