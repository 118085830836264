import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";
import { PayabliStorageManager } from '../api/localStorageManager'
import { BiChevronLeft, BiReceipt, BiSitemap, BiKey, BiPaint } from 'react-icons/bi';

@inject('reports', 'global')
@observer
class DevelopersLinks extends React.Component {

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <div className="row">
                {this.props.goback &&
                    <div className="col-2">
                        <div className="hide-sm">
                            <a href={this.props.gobackLink} className="btn btn-light text-transform-normal"><BiChevronLeft /> {this.props.gobackText ? this.props.gobackText : "Go back"}</a>
                        </div>
                    </div>
                }
                {this.props.gobackIframeCreator &&
                    <div className="col-2">
                        <div className="hide-sm">
                            <a href="#" onClick={(e)=>this.props.handleBackFunction(e)} className="btn btn-light text-transform-normal"><BiChevronLeft /> {this.props.gobackText ? this.props.gobackText : "Go back"}</a>
                        </div>
                    </div>
                }
                <div className={(this.props.goback || this.props.gobackIframeCreator) ? "col-10 text-center" : "col-12 text-center"}>
                <div className="hide-sm">
                    {userPermissions.indexOf("Xchange") !== -1 &&
                        <>
                            <Link className={this.props.selected === 'sftpaccounts'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/sftpaccounts"}><BiSitemap/> SFTP Accounts</Link>
                            {/*<Link className={this.props.selected === 'logs'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/logs"}><BiCollection/> Logs</Link>*/}
                            <Link className={this.props.selected === 'reports'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/reports"}><BiReceipt/> Reports</Link>
                        </>
                    }
                    {userPermissions.indexOf("Developers") !== -1 &&
                        <>
                            <Link className={this.props.selected === 'apikeys'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/apitokens"}><BiKey/> API Tokens</Link>
                            {process.env.REACT_APP_LINK_COMPONENT_CREATOR === 'true' &&
                            <Link className={this.props.selected === 'componentCreator'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/creator"}><BiPaint/> Creator <span style={{fontSize: '10px', marginLeft: '5px'}} className="badge bg-primary">Beta</span></Link>
                            }
                        </>
                    }
                </div>
                <div className="hide show-sm sm-screen-topbar-menu">
                    {userPermissions.indexOf("Xchange") !== -1 &&
                        <>
                            <Link className={this.props.selected === 'sftpaccounts'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/sftpaccounts"}><BiSitemap/></Link>
                            {/*<Link className={this.props.selected === 'logs'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/logs"}><BiCollection/></Link>*/}
                            <Link className={this.props.selected === 'reports'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/reports"}><BiReceipt/></Link>
                        </>
                    }
                    {userPermissions.indexOf("Developers") !== -1 &&
                        <>
                            <Link className={this.props.selected === 'apikeys'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/apitokens"}><BiKey/></Link>
                            {process.env.REACT_APP_LINK_COMPONENT_CREATOR === 'true' &&
                            <Link className={this.props.selected === 'componentCreator'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/developers/creator"}><BiPaint/></Link>
                            }
                        </>
                    }
                </div>
            </div>            
        </div>
        )
    }
}

export { DevelopersLinks };