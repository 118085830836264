import React from 'react';
import { inject, observer } from 'mobx-react';
import {BiLockAlt} from "@react-icons/all-files/bi/BiLockAlt";


@inject('global')
@observer
class AccessDenied extends React.Component {

    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
     }
     
     goBack(e){
        if(e){
        e.preventDefault();
        }
        this.props.history.go(-2);
     }

    render() {
        return (
        <>
            <div className="root-page">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{height: "100%", position: "absolute", width: "100%"}}>
                <div className="text-center" style={{width: '40em'}}>
                    <BiLockAlt className="icon-modal" />
                    <p className="small">
                        <h5>Access Denied</h5>
                        You don't have access to this section <br/>
                    </p>
                    <a href="/" onClick={(e)=>this.goBack(e)} className="underline">Go back</a>
                </div>
            </div>
            </div>
        </>
        )
    }
}

export { AccessDenied };