import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('reports', 'filter')
@observer
class RenderCustomFields extends React.Component {

    render() {
        return (<>
                {this.props.filter.customFields && this.props.filter.customFields.length > 0 && (<h5 className='title'>Custom Fields</h5>)}
                <div className='row'>
                    {
                        this.props.filter.customFields && this.props.filter.customFields.length > 0 && this.props.filter.customFields.map((field, i) => (
                            <div key={`additional${(field.key)}${i}`} className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating">
                                    <input name={`additional${(field.key).replaceAll(/\s/g, '')}`} id={`additional${(field.key).replaceAll(/\s/g, '')}`} className={"form-control"} placeholder={field.key} onChange={(e) => this.props.filter.handleCaptureTextFilter(`additional-${field.key}(ct)`, e)} onKeyDown={(e) => this.props.filter.handleKeyDown(`additional-${field.key}(ct)`, e)} value={this.props.filter.filterText[`additional${(field.key).replaceAll(/\s/g, '')}`] ? this.props.filter.filterText[`additional${(field.key).replaceAll(/\s/g, '')}`] : ''} />
                                    <label htmlFor={`additional${(field.key).replaceAll(/\s/g, '')}`}>{field.key}</label>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </>);
    }
}

export { RenderCustomFields };