import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiChevronDown, BiEdit} from 'react-icons/bi';
import { Accordion, Card } from 'react-bootstrap';
import { EditVendor } from '../components/EditVendor';
import { BarChartVendor } from './BarChartVendor';

@inject('reports', 'global', 'vendor')
@observer
class VendorDataChartColumn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            statistics: null,
            vendorId: null,
            paymentYTD: 0
        };
        this.getVendorStatistics = this.getVendorStatistics.bind(this);
        this.callBackFunction = this.callBackFunction.bind(this);
    }

    componentDidMount() {
        if(this.props.routeParams.id){
            let id =  this.props.routeParams.id;
            this.setState({ vendorId: id }, function(){
                this.getVendorStatistics();
            });
        }
       
    }

    getVendorStatistics(){
        this.props.vendor.getStatistics(this.state.vendorId).then(res => {
            
        })
        .catch(error => {
            
        });
    }

    callBackFunction(){
        if(this.props.callBackFunction){
            this.props.callBackFunction();
        }
    }

    render() {
        let vendorDataChart = this.props.vendor.vendorDataChart;
        let customFields = [];

        if(this.props.vendorObj){
            if (this.props.vendorObj.customField1 && this.props.vendorObj.customField1 !== '') {                
                customFields.push(
                    <div key={'customField1'} className="row small mb-2">
                        <div className="col-4 grey">{'Custom Field 1'}</div>
                        <div className="col-8">{this.props.vendorObj.customField1}</div>
                    </div>
                );
            }
            if (this.props.vendorObj.customField2 && this.props.vendorObj.customField2 !== '') {                
                customFields.push(
                    <div key={'customField2'} className="row small mb-2">
                        <div className="col-4 grey">{'Custom Field 2'}</div>
                        <div className="col-8">{this.props.vendorObj.customField2}</div>
                    </div>
                );
            }
        }

        return (
            <>
                {this.props.header &&
                <h5 className="header mb-3">{this.props.header}</h5>
                }
                <div className="mb-5">
                    <small className="grey">Total Payments this month</small>
                    <h2 style={{fontWeight: 400}}>${ vendorDataChart.lastMonthTotal ? this.props.global.numberWithCommas(vendorDataChart.lastMonthTotal.toFixed(2)) : "0.00"}</h2>
                    <small>Payment History</small>
                    <BarChartVendor/>
                </div>

                <h6 className="sub-header-line mb-1 dark-grey mb-4" data-qaid="accountInformationVendor">
                    Account Information
                    <EditVendor isEmbedded={this.props.isEmbedded} viewAction={"edit"} functionCallback={this.callBackFunction} className={" "} style={{fontSize: "18px", float: "right", cursor: "pointer"}} vendorToUpdate={this.props.vendorObj} label={<BiEdit/>} action={"edit"}/>
                </h6>
                
                <div className="mb-2">Contact</div>
                <div className="row small mb-2">
                    <div className="col-4 grey">Paypoint</div>
                    <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.paypointDbaname ? this.props.global.capitalizeFirstLetterOfEachWord(this.props.vendorObj.paypointDbaname) : this.props.vendorObj.PaypointDbaname ? this.props.global.capitalizeFirstLetterOfEachWord(this.props.vendorObj.PaypointDbaname) : "-") : '-'}</div>
                </div>
                <div className="row small mb-2">
                    <div className="col-4 grey">Business Name</div>
                    <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.name1 ? this.props.global.capitalizeFirstLetterOfEachWord(this.props.vendorObj.name1) : this.props.vendorObj.Name1 ? this.props.global.capitalizeFirstLetterOfEachWord(this.props.vendorObj.Name1) : "-") : '-'}</div>
                </div>
                <div className="row small mb-2">
                    <div className="col-4 grey">Contact Name</div>
                    <div className="col-8">{this.props.vendorObj ? (Array.isArray(this.props.vendorObj.Contacts) && this.props.vendorObj.Contacts[0] && this.props.vendorObj.Contacts[0].ContactName ? this.props.vendorObj.Contacts[0].ContactName : Array.isArray(this.props.vendorObj.contacts) && this.props.vendorObj.contacts[0] && this.props.vendorObj.contacts[0].ContactName ? this.props.vendorObj.contacts[0].ContactName : '-') : '-'}</div>
                </div>

                <div className="row small mb-2">
                    <div className="col-4 grey">Account #</div>
                    <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.customerVendorAccount ? this.props.vendorObj.customerVendorAccount : this.props.vendorObj.CustomerVendorAccount ? this.props.vendorObj.CustomerVendorAccount : '-') : '-'}</div>
                </div>

                <div className="row small mb-2">
                    <div className="col-4 grey">Vendor #</div>
                    <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.VendorNumber ? this.props.vendorObj.VendorNumber.length > 15 ? this.props.vendorObj.VendorNumber.substring(0, 15) + "..." :  this.props.vendorObj.VendorNumber  : this.props.vendorObj.VendorNumber ? this.props.vendorObj.vendorNumber.length > 15 ? this.props.vendorObj.vendorNumber.substring(0, 15) + "..." :  this.props.vendorObj.vendorNumber : '-')  : '-'}</div>
                </div>
                <div className="row small mb-2">
                    <div className="col-4 grey">Tax ID / EIN</div>
                    <div className="col-8">{this.props.vendorObj && this.props.vendorObj.EIN ? this.props.vendorObj.EIN : '-'}</div>
                </div>
                <div className="row small mb-2">
                    <div className="col-4 grey">Phone</div>
                    <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.phone ? this.props.global.phoneNumberFormat(this.props.vendorObj.phone) : this.props.vendorObj.Phone ? this.props.global.phoneNumberFormat(this.props.vendorObj.Phone) : "-") : '-'}</div>
                </div>
                <div className="row small mb-3">
                    <div className="col-4 grey">Email</div>
                    <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.email ? this.props.vendorObj.email : this.props.vendorObj.Email ? this.props.vendorObj.Email : "-"): '-'}</div>
                </div>

                

                <Accordion defaultActiveKey="0">
                <Card className="card-no-border mb-3">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                        <BiChevronDown/> Billing Details 
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Address 1</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.address1 ? this.props.vendorObj.address1 : this.props.vendorObj.Address1 ? this.props.vendorObj.Address1 : "-") : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Address 2</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.address2 ? this.props.vendorObj.address2 : this.props.vendorObj.Address2 ? this.props.vendorObj.Address2 : "-") : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">City</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.city ? this.props.vendorObj.city : this.props.vendorObj.City ? this.props.vendorObj.City : "-") : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">State</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.state ? this.props.vendorObj.state : this.props.vendorObj.State ? this.props.vendorObj.State : "-") : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Zip</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.zip ? this.props.vendorObj.zip : this.props.vendorObj.Zip ? this.props.vendorObj.Zip : "-") : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Country</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.country ? this.props.vendorObj.country : this.props.vendorObj.Country ? this.props.vendorObj.Country : "-") : '-'}</div>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                <Accordion defaultActiveKey="0">
                <Card className="card-no-border mb-3">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                        <BiChevronDown/> Remit Details 
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Address 1</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.remitAddress1 ? this.props.vendorObj.remitAddress1 : this.props.vendorObj.RemitAddress1 ? this.props.vendorObj.RemitAddress1 : "-") : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Address 2</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.remitAddress2 ? this.props.vendorObj.remitAddress2 : this.props.vendorObj.RemitAddress2 ? this.props.vendorObj.RemitAddress2 : "-") : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">City</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.remitCity ? this.props.vendorObj.remitCity : this.props.vendorObj.RemitCity ? this.props.vendorObj.RemitCity : "-") : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">State</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.remitState ? this.props.vendorObj.remitState : this.props.vendorObj.RemitState ? this.props.vendorObj.RemitState : "-") : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Zip</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.remitZip ? this.props.vendorObj.remitZip : this.props.vendorObj.RemitZip ? this.props.vendorObj.RemitZip : "-"): '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Country</div>
                            <div className="col-8">{this.props.vendorObj ? (this.props.vendorObj.remitCountry ? this.props.vendorObj.remitCountry : this.props.vendorObj.RemitCountry ? this.props.vendorObj.RemitCountry : "-"): '-'}</div>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
                {
                    (customFields.length > 0) &&
                    (
                        <>
                            <Accordion defaultActiveKey="0">
                            <Card className="card-no-border mb-3">
                                <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                                <BiChevronDown/> Custom Fields 
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {customFields}
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            </Accordion>
                        </>
                    )
                }
            </>
        )
    }
}

export { VendorDataChartColumn };