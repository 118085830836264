import { makeObservable , action } from "mobx";
import axios from 'axios';
import { PayabliStorageManager } from "../api/localStorageManager";

class DeviceStore {

    constructor() {
        makeObservable(this)
    }

    @action
    getDevicesFromApi(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entryPoint = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) &&
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ?
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : null;

        if(entryPoint){
            return axios.get(process.env.REACT_APP_URL_API+ 'Cloud/list/' + entryPoint ,{
                headers: {
                    'requestToken': encryptStorage.getItem('pToken'),
                }})
                .then(res => {
                    return res.data;
                })
                .catch(error => {
                    throw error;
                });
        }
    }

    @action
    getCredentialsPaypointFromApi(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage(), entryPoint = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : "";
        if (entryPoint) {
            return axios.get(process.env.REACT_APP_URL_API+ 'Paypoint/'+ entryPoint + "?entrypages=false",{
                headers: {
                    'requestToken': encryptStorage.getItem('pToken'),
                }
                })
                .then(res => {
					return res?.data?.responseData?.Paypoint?.Credentials
                })
                .catch(error => {throw error}
                );
        }
    }

	@action
	async haveDevicePermission(){
		const credentials = await this.getCredentialsPaypointFromApi()
		if (Array.isArray(credentials) && credentials.length > 0) {
			return credentials.some(objeto => objeto.Service === "cloud")
		}
		return false
	}

    @action
    newDevice(device){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entryPoint = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry;
        return axios.post(process.env.REACT_APP_URL_API+ 'Cloud/register/'+entryPoint,{
            "registrationCode": device.id,
            "description": device.description
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            if(res && res.data && res.data.responseData){
                return res;
            }
            else{
                throw 'error';
            }
            
        })
        .catch(error => {
            throw error;
        });
    }

    @action
    deleteDevice(deviceId){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entryPoint = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry;
        return axios.delete(process.env.REACT_APP_URL_API+ 'Cloud/register/'+entryPoint+'/'+ deviceId,
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

}

const deviceStore = new DeviceStore();
export default deviceStore;