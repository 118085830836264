import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import Switch from 'react-switch';
import $ from 'jquery';
import 'jquery-ui-bundle';
import ReactDOM from 'react-dom';
import { BiCheck, BiInfoCircle, BiX, BiCustomize } from 'react-icons/bi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

@inject('store')
@observer
class PayorFields extends React.Component {
	constructor(props) {
		super(props);

		this.sortablePayerConfig = React.createRef();

		this.displayField = this.displayField.bind(this);
		this.handlePayorFieldsChecked = this.handlePayorFieldsChecked.bind(this);
		this.handlePayorfieldTextChange =
			this.handlePayorfieldTextChange.bind(this);
		this.removePayorfield = this.removePayorfield.bind(this);
		this.renderFields = this.renderFields.bind(this);
		this.handlePayorFieldsWidth = this.handlePayorFieldsWidth.bind(this);
		this.handlePayorFieldsIdentifier =
			this.handlePayorFieldsIdentifier.bind(this);
	}

	handlePayorfieldTextChange(event) {
		this.props.store.handlePayorfieldTextChange(event);
	}

	removePayorfield(event) {
		this.props.store.removePayorfield(event);
	}

	displayField(checked, event, field) {
		this.props.store.displayField(checked, event, field);
	}

	handlePayorFieldsChecked(event) {
		this.props.store.handlePayorFieldsChecked(event);
	}

	handlePayorFieldsIdentifier(event) {
		this.props.store.handlePayorFieldsIdentifier(event);
	}

	handlePayorFieldsValidation(event) {
		this.props.store.handlePayorFieldsValidation(event);
	}

	handlePayorFieldsWidth(event) {
		this.props.store.handlePayorFieldsWidth(event);
	}

	sortFields(inputArr) {
		let len = inputArr.length;
		for (let i = 0; i < len; i++) {
			for (let j = 0; j < len - 1; j++) {
				if (inputArr[j][1].order > inputArr[j + 1][1].order) {
					let tmp = inputArr[j];
					inputArr[j] = inputArr[j + 1];
					inputArr[j + 1] = tmp;
				}
			}
		}

		return inputArr;
	}

	getOptionsValidationFromField(field) {
		let fullOptions = (
			<>
				<option value="alpha">Alphabet only</option>
				<option value="numbers">Numbers only</option>
				<option value="text">Text</option>
				<option value="email">Email</option>
				<option value="phone">Phone</option>
			</>
		);
		if (field.name === 'billingEmail') {
			return <option value="email">Email</option>;
		} else if (field.name === 'billingPhone') {
			return <option value="phone">Phone</option>;
		} else if (['company', 'firstName', 'lastName'].includes(field.name)) {
			return <option value="text">Text</option>;
		} else if (['customerNumber', 'invoiceNumber'].includes(field.name)) {
			return (
				<>
					<option value="alpha">Alphabet only</option>
					<option value="numbers">Numbers only</option>
					<option value="text">Text</option>
				</>
			);
		} else if (['billingAddress1', 'billingAddress', 'shippingAddress1','shippingAddress'].includes(field.name)) {
			return (
				<>
					<option value="text">Text</option>
					<option value="address">Address</option>
				</>
			);
		} else {
			return fullOptions;
		}
	}

	renderFields() {
		var loopDataConfig = [];
		var payerFieldsConfigSort = this.sortFields(
			Object.entries(toJS(this.props.store.payerFields))
		);
		var reactObject = this;

		this.props.store.setRequiredCustomFields(payerFieldsConfigSort.some(
			(item) => item[1].identifier === true
		))
		payerFieldsConfigSort.forEach(function (item, index) {
			item[1].onlyData !== true &&
				loopDataConfig.push(
					<li
						key={'keyPayerConfig' + item[0]}
						className="ui-state-default dragable-panel"
						id={'payerConfig' + item[0]}
					>
						<div className="row">
							{item[1].display}
							<div className="col-3 mb-2">
								<input
									type="text"
									placeholder="Label"
									className="form-control"
									value={item[1].label}
									onChange={(e) => reactObject.handlePayorfieldTextChange(e)}
									id={item[0]}
								/>
							</div>
							<div className="col-2 mb-2 text-center">
								<select
									value={item[1].validation}
									className="form-select form-control"
									onChange={(e) => reactObject.handlePayorFieldsValidation(e)}
									id={'payerFieldValidation' + item[0]}
								>
									{reactObject.getOptionsValidationFromField(item[1])}
								</select>
							</div>
							<div className="col-2 mb-2 text-center">
								<select
									value={item[1].width}
									className="form-select form-control"
									onChange={(e) => reactObject.handlePayorFieldsWidth(e)}
									id={'payerFieldWidth' + item[0]}
								>
									<option value="6">1/2</option>
									<option value="4">1/3</option>
									<option value="8">2/3</option>
									<option value="12">3/3</option>
								</select>
							</div>
							<div className="col-2 mb-2 text-center">
								<Switch
									disabled={
										item[1].identifier && item[1].identifier === true
											? true
											: false
									}
									onChange={reactObject.displayField}
									checked={item[1].identifier && item[1].identifier === true
										? true
										: item[1].display}
									onColor="#10A0E3"
									onHandleColor="#ffffff"
									handleDiameter={20}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={20}
									width={38}
									className="react-switch"
									id={item[0]}
								/>
							</div>
							<div className="col mb-2 text-center">
								<div className="icheck-primary">
									<input
										type="checkbox"
										checked={item[1].required}
										onChange={(e) => reactObject.handlePayorFieldsChecked(e)}
										id={'payerFieldRequired' + item[0]}
										disabled={
											item[1].identifier && item[1].identifier === true
												? true
												: false
										}
									/>
									<label htmlFor={'payerFieldRequired' + item[0]}></label>
								</div>
							</div>

							<div className="col mb-2 text-center">
								{item[1].identifier && item[1].identifier === true ? (
									<BiCheck
										style={{ fontSize: '24px' }}
										className="green-text"
									/>
								) : (
									<BiX className="light-text" />
								)}
							</div>

							<div className="col mb-2 text-center p0">
								{item[1].name.startsWith('additional_') === true && (
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip>This is a custom field.</Tooltip>}
									>
										<BiCustomize style={{ color: '#10A0E3' }} />
									</OverlayTrigger>
								)}
							</div>
						</div>
					</li>
				);
		});

		return loopDataConfig;
	}

	componentDidMount() {
		var MainStoreObj = this.props.store;
		var sortableObjConfig = $(
			ReactDOM.findDOMNode(this.sortablePayerConfig.current)
		);

		sortableObjConfig.sortable({
			stop: function (event, ui) {
				var idsInOrder = sortableObjConfig.sortable('toArray');
				MainStoreObj.updatePayerFieldsOrder(idsInOrder);
			},
		});
	}

	render() {
		return (
			<div className="payorFieldsConfig">
				<div className="row">
					<div className="col-3 mb-2">
						<small>
							Input Field
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip>
										These are the input fields you will have your customers fill
										out. You can edit the label of these fields as you wish.{' '}
									</Tooltip>
								}
							>
								<BiInfoCircle className="label-info-icon in-input" />
							</OverlayTrigger>
						</small>
					</div>
					<div className="col-2 mb-2 text-center">
						<small>
							Validation
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip>
										Decide the data format you want to be entered:
										<br />
										Alphabet only (ex: Aa)
										<br />
										Numbers only (ex: 123)
										<br />
										Text (ex: Aa123!)
										<br />
										Email (ex: xyz@.com)
										<br />
										Phone- (ex: xxx-xxx-xxxx)
									</Tooltip>
								}
							>
								<BiInfoCircle className="label-info-icon in-input" />
							</OverlayTrigger>
						</small>
					</div>
					<div className="col-2 mb-2 text-center">
						<small>
							Field Width
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip>
										Decide the field width on the payment page
										<br />
										1/2 half width page width
										<br />
										1/3 one third page width
										<br />
										2/3 two third page width
										<br />
										3/3 full page width
									</Tooltip>
								}
							>
								<BiInfoCircle className="label-info-icon in-input" />
							</OverlayTrigger>
						</small>
					</div>
					<div className="col-2 mb-2 text-center">
						<small>
							Display?
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip>
										Select the fields you wish to make visible to your
										customers; customer will have the option to input their data
										in these fields.
									</Tooltip>
								}
							>
								<BiInfoCircle className="label-info-icon in-input" />
							</OverlayTrigger>
						</small>
					</div>
					<div className="col mb-2 text-center">
						<small>
							Req.
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip>
										Select fields you wish to require. Field must be visible to
										be required.
									</Tooltip>
								}
							>
								<BiInfoCircle className="label-info-icon in-input" />
							</OverlayTrigger>
						</small>
					</div>
					<div className="col mb-2 text-center">
						<small>
							ID
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip>
										Identifiers are customer profile fields that uniquely
										identify and associate customer records and payments. You
										can Manage IDs in Settings.
									</Tooltip>
								}
							>
								<BiInfoCircle className="label-info-icon in-input" />
							</OverlayTrigger>
						</small>
					</div>
					<div className="col mb-2 text-center">
						<small>&nbsp;</small>
					</div>
				</div>

				<ul className="sortable sortable-config" ref={this.sortablePayerConfig}>
					{this.renderFields()}
				</ul>
			</div>
		);
	}
}

export { PayorFields };
