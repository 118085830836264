import { makeObservable , action, observable } from "mobx";
import globalStore from './GlobalStore';

class ApplePayStore {
    globalStore = null;
    constructor(globalStore) {
        this.globalStore = globalStore;
        makeObservable(this)
    }

    @observable 
    applePayButtonTypes = [
        { text: "Pay", value: "pay", displayText: "Pay with", description: "Use this button for general purchases" },
        { text: "Check Out", value: "check-out", displayText: "Check Out with", description: "Use this button for purchase experiences that include other payment buttons that start with “Check out”" },
        { text: "Continue", value: "continue", displayText: "Continue with", description: "Use this button for general purchases" },
        { text: "Donate", value: "donate", displayText: "Donate with", description: "Used by approved nonprofits. Lets people make donations" },
        { text: "Contribute", value: "contribute", displayText: "Contribute with", description: "Use this button to help people give money to projects, causes, organizations, and other entities" },
        { text: "Support", value: "support", displayText: "Support with", description: "Use this button for helping people give money to projects, causes, organizations, and other entities" },
        { text: "Buy", value: "buy", displayText: "Buy with", description: "Use this button for product purchases" },
        { text: "Order", value: "order", displayText: "Order with", description: "Use this button for placing orders for items" },
        { text: "Rent", value: "rent", displayText: "Rent with", description: "Use this button for renting items such as equipment or cars" },
        { text: "Book", value: "book", displayText: "Book with", description: "Use this button for booking appointments, flights, or other experiences" },
        { text: "Tip", value: "tip", displayText: "Tip with", description: "Use this button to let people tip for goods or services" },
        { text: "Top Up", value: "top-up", displayText: "Top Up with", description: "Use this button for adding money to an account, or payment system" },
        { text: "Plain", value: "plain", displayText: "", description: "An Apple Pay button with the Apple Pay logo only, useful when an additional call to action isn't needed" },
    ];

    @observable 
    applePayButtonStyles = [
        { text: "Black (Default)", value: "black"},
        { text: "White with outline", value: "white-outline"},
        { text: "White", value: "white"},
    ];

    @observable 
    applePayButtonLanguages = [
        { text: "English (US)", value: "en-US" },
        { text: "Arabic", value: "ar-AB" },
        { text: "Catalan", value: "ca-ES" },
        { text: "Chinese (China)", value: "zh-CN" },
        { text: "Chinese (Hong Kong)", value: "zh-HK" },
        { text: "Chinese (Taiwan)", value: "zh-TW" },
        { text: "Croatian", value: "hr-HR" },
        { text: "Czech", value: "cs-CZ" },
        { text: "Danish", value: "da-DK" },
        { text: "German", value: "de-DE" },
        { text: "Dutch", value: "nl-NL" },
        { text: "English (Australia)", value: "en-AU" },
        { text: "English (UK)", value: "en-GB" },
        { text: "Finnish", value: "fi-FI" },
        { text: "French (Canada)", value: "fr-CA" },
        { text: "French (France)", value: "fr-FR" },
        { text: "German", value: "de-DE" },
        { text: "Greek", value: "el-GR" },
        { text: "Hebrew", value: "he-IL" },
        { text: "Hindi", value: "hi-IN" },
        { text: "Hungarian", value: "hu-HU" },
        { text: "Indonesian", value: "id-ID" },
        { text: "Italian", value: "it-IT" },
        { text: "Japanese", value: "ja-JP" },
        { text: "Korean", value: "ko-KR" },
        { text: "Malay", value: "ms-MY" },
        { text: "Norwegian", value: "nb-NO" },
        { text: "Polish", value: "pl-PL" },
        { text: "Portuguese (Brazil)", value: "pt-BR" },
        { text: "Portuguese (Portugal)", value: "pt-PT" },
        { text: "Romanian", value: "ro-RO" },
        { text: "Russian", value: "ru-RU" },
        { text: "Slovak", value: "sk-SK" },
        { text: "Spanish (Mexico)", value: "es-MX" },
        { text: "Spanish (Spain)", value: "es-ES" },
        { text: "Swedish", value: "sv-SE" },
        { text: "Thai", value: "th-TH" },
        { text: "Turkish", value: "tr-TR" },
        { text: "Ukrainian", value: "uk-UA" },
        { text: "Vietnamese", value: "vi-VN" }
    ];

    @action
    getApplePayButtonType(value) {
        return this.applePayButtonTypes.find(button => button.value === value);
    }

    @action
    getApplePayButtonStyle(value) {
        return this.applePayButtonStyles.find(button => button.value === value);
    }

    @action
    getApplePayButtonLanguages(value) {
        return this.applePayButtonLanguages.find(button => button.value === value);
    }
}

const applePayStore = new ApplePayStore(globalStore);
export default applePayStore;