import React from 'react';
import { inject, observer } from 'mobx-react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { BiPaperPlane } from 'react-icons/bi';
import { GiReceiveMoney } from 'react-icons/gi';
import { Message } from '../components/Message';

@inject('reports','vTerminal')
@observer
class PayBillPaymentDeliveryMethods extends React.Component {
  constructor(props) {
    super(props);

    this.selectPaymentDeliveryMethod = this.selectPaymentDeliveryMethod.bind(this);
  }

  selectPaymentDeliveryMethod(method){
    if(this.props.callbackFunction){
      this.props.callbackFunction(method)
    }
  }


  render() {
    return (
      <div>
        {
        this.props.vTerminal.hasMoneyOutCredentials === true ?
        <>
        <h6 className="mb-3">Payment Delivery Method</h6>
        <div className='row delivery-methods'>
          <div className='col-6'>
            <div onClick={(e) => this.selectPaymentDeliveryMethod(1)} className={"card card-in mb-3 card-select text-center " + (this.props.optionSelected === 1 ? "selected" : "")}>
              <div className="card-body card-header">
                  <BiPaperPlane style={{fontSize: "32px", margin: "10px 0"}} className="grey-icon-v2"/>
                  <br/>
                  <small className='small-small'>Send vendor payment link</small>
                  <br/>
                  <small className='small-small small-grey'>Vendor decides how to get paid</small>
              </div>
              {this.props.optionSelected === 1 ? 
               <MdRadioButtonChecked className='radio-in-square'/>
               :
               <MdRadioButtonUnchecked className='radio-in-square disabled'/>
              }
             
            </div>
          </div>
          <div className='col-6'>
          <div onClick={(e) => this.selectPaymentDeliveryMethod(2)} className={"card card-in mb-3 card-select text-center " + (this.props.optionSelected === 2 ? "selected" : "")}>
              <div className="card-body card-header">
                  <GiReceiveMoney style={{fontSize: '32px', marginBottom: "7px",marginTop: "7px"}} className="grey-icon-v2"/>
                  <br/>
                  <small className='small-small'>Select how vendor gets paid</small>
                  <br/>
                  <small className='small-small small-grey'>You decide how vendor gets paid</small>
              </div>
              {this.props.optionSelected === 2 ? 
               <MdRadioButtonChecked className='radio-in-square'/>
               :
               <MdRadioButtonUnchecked className='radio-in-square disabled'/>
              }
            </div>
          </div>
        </div>
        </>
        :
        <Message message="Sorry. You dont't have credentials."  />
        }
      </div>
    );
  }
}

export { PayBillPaymentDeliveryMethods };
