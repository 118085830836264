import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiTrash, BiCheckCircle, BiMinusCircle } from 'react-icons/bi';
import DatePicker from "react-datepicker";
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'filter')
@observer
class ViewPartialVendors extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-12 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="vendorNumber" id="vendorNumber" className={"form-control"} placeholder="Vendor #" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorNumber(ct)', e)} value={this.props.filter.filterText.vendorNumber ? this.props.filter.filterText.vendorNumber : ''} data-qaid="vendorNumberMoneyOutInput"/>
                            <label htmlFor="vendorNumber">Vendor #</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating input-number">
                            <input name="vendorEIN" id="vendorEIN" type='number' className={"form-control"} placeholder="Tax ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('ein(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('ein(ct)', e)} value={this.props.filter.filterText.vendorEIN ? this.props.filter.filterText.vendorEIN : ''} />
                            <label htmlFor="vendorEIN">Tax ID</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerVendorAccount" id="customerVendorAccount" className={"form-control"} placeholder="Account #" onChange={(e) => this.props.filter.handleCaptureTextFilter('customerVendorAccount(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customerVendorAccount(ct)', e)} value={this.props.filter.filterText.customerVendorAccount ? this.props.filter.filterText.customerVendorAccount : ''} />
                            <label htmlFor="customerVendorAccount">Account #</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="vendorName" id="vendorName" className={"form-control"} placeholder="Vendor or Recipient Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('name(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('name(ct)', e)} value={this.props.filter.filterText.vendorName ? this.props.filter.filterText.vendorName : ''} />
                            <label htmlFor="vendorName">Vendor or Recipient Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="externalPaypointID" id="externalPaypointID" className={"form-control"} placeholder="External Paypoint ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('externalPaypointID(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('externalPaypointID(ct)', e)} value={this.props.filter.filterText.externalPaypointID ? this.props.filter.filterText.externalPaypointID : ''} />
                            <label htmlFor="externalPaypointID">External Paypoint ID</label>
                        </div>
                    </div>
                </div>
                <div>
                    <p><small>Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}><BiCheckCircle/> Active</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-light" : "badge text-light"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiMinusCircle /> Inactive</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-99]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', -99, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-99]))}><BiTrash /> Deleted</span>
                    </div>
                </div>
                <div>
                    <ContainerShowMoreLess>
                        <hr className='mb-4' style={{width: '95%'}}></hr>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <p><small>Created Date</small></p>
                            <DatePicker
                                customInput={
                                    <div className="form-floating">
                                        <input 
                                            id="createdDateVendorCalendar" 
                                            name="createdDateVendorCalendar" 
                                            readOnly 
                                            autoComplete="off" 
                                            className={"form-control input-calendar"} 
                                            value={(this.props.filter.stateDate.startDateDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateDate) + ' → ' : '') + (this.props.filter.stateDate.endDateDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateDate) : '')} 
                                            placeholder="Start Date to End Date" 
                                        />
                                        <label htmlFor="createdDateVendorCalendar">Start Date to End Date</label>
                                    </div>
                                }
                                selected={ this.props.filter.stateDate.startDateDate ? this.props.filter.stateDate.startDateDate : new Date() }
                                onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateDate', 'endDateDate', 'createdAt')}
                                startDate={this.props.filter.stateDate.startDateDate}
                                endDate={this.props.filter.stateDate.endDateDate}
                                selectsRange={true}
                                monthsShown={2}
                                shouldCloseOnSelect={this.props.filter.stateDate.endDateDate !== null ? false : true}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4" style={{marginTop: '20px'}}>
                            <div className="form-floating">
                                <input name="vendorEmail" id="vendorEmail" className={"form-control"} placeholder="Email" onChange={(e) => this.props.filter.handleCaptureTextFilter('email(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('email(ct)', e)} value={this.props.filter.filterText.vendorEmail ? this.props.filter.filterText.vendorEmail : ''} />
                                <label htmlFor="vendorEmail">Email</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating input-number">
                                <input name="vendorPhone" id="vendorPhone" type='number' className={"form-control"} placeholder="Phone" onChange={(e) => this.props.filter.handleCaptureTextFilter('phone(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('phone(ct)', e)} value={this.props.filter.filterText.vendorPhone ? this.props.filter.filterText.vendorPhone : ''} />
                                <label htmlFor="vendorPhone">Phone</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="vendorAddress" id="vendorAddress" className={"form-control"} placeholder="Billing Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('address(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('address(ct)', e)} value={this.props.filter.filterText.vendorAddress ? this.props.filter.filterText.vendorAddress : ''} />
                                <label htmlFor="vendorAddress">Billing Address</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="remitAddress" id="remitAddress" className={"form-control"} placeholder="Shipping Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('remitAddress(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('remitAddress(ct)', e)} value={this.props.filter.filterText.remitAddress ? this.props.filter.filterText.remitAddress : ''} />
                                <label htmlFor="remitAddress">Remit Address</label>
                            </div>
                        </div>
                    </ContainerShowMoreLess>
                </div>
                <div>
                    <h5 className='title mb-2'>Bill Details</h5>
                    <form className="form-inline">
                        <div className='row'>
                            <div className='col-6'>
                                <div className="row mb-4">
                                    <p><small>Active Bills</small></p>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='minActiveBills' id='minActiveBills' type='number' className="form-control" placeholder="Qty min" onChange={(e) => this.props.filter.handleCaptureTextFilter('pendingBills(ge)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('pendingBills(ge)', e)} value={this.props.filter.filterText.minActiveBills ? this.props.filter.filterText.minActiveBills : ''} />
                                            <label htmlFor="minActiveBills">Qty min</label>
                                        </div>
                                    </div>
                                    <div className="form-group col-2 p-to-bill-details">
                                        <p><small>TO</small></p>
                                    </div>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='maxActiveBills' id='maxActiveBills' type='number' className="form-control" placeholder="Qty max" onChange={(e) => this.props.filter.handleCaptureTextFilter('pendingBills(le)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('pendingBills(le)', e)} value={this.props.filter.filterText.maxActiveBills ? this.props.filter.filterText.maxActiveBills : ''} />
                                            <label htmlFor="maxActiveBills">Qty max</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="row mb-4">
                                    <p><small>Payment in Transit</small></p>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='minInTransitBills' id='minInTransitBills' type='number' className="form-control" placeholder="Qty min" onChange={(e) => this.props.filter.handleCaptureTextFilter('inTransitBills(ge)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('inTransitBills(ge)', e)} value={this.props.filter.filterText.minInTransitBills ? this.props.filter.filterText.minInTransitBills : ''} />
                                            <label htmlFor="minInTransitBills">Qty min</label>
                                        </div>
                                    </div>
                                    <div className="form-group col-2 p-to-bill-details">
                                        <p><small>TO</small></p>
                                    </div>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='maxInTransitBills' id='maxInTransitBills' type='number' className="form-control" placeholder="Qty max" onChange={(e) => this.props.filter.handleCaptureTextFilter('inTransitBills(le)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('inTransitBills(le)', e)} value={this.props.filter.filterText.maxInTransitBills ? this.props.filter.filterText.maxInTransitBills : ''} />
                                            <label htmlFor="maxInTransitBills">Qty max</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="row mb-4">
                                    <p><small>Bills Paid</small></p>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='minPaidBills' id='minPaidBills' type='number' className="form-control" placeholder="Qty min" onChange={(e) => this.props.filter.handleCaptureTextFilter('paidBills(ge)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('paidBills(ge)', e)} value={this.props.filter.filterText.minPaidBills ? this.props.filter.filterText.minPaidBills : ''} />
                                            <label htmlFor="minPaidBills">Qty min</label>
                                        </div>
                                    </div>
                                    <div className="form-group col-2 p-to-bill-details">
                                        <p><small>TO</small></p>
                                    </div>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='maxPaidBills' id='maxPaidBills' type='number' className="form-control" placeholder="Qty max" onChange={(e) => this.props.filter.handleCaptureTextFilter('paidBills(le)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('paidBills(le)', e)} value={this.props.filter.filterText.maxPaidBills ? this.props.filter.filterText.maxPaidBills : ''} />
                                            <label htmlFor="maxPaidBills">Qty max</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="row mb-4">
                                    <p><small>Total Bills</small></p>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='minTotalBills' id='minTotalBills' type='number' className="form-control" placeholder="Qty min" onChange={(e) => this.props.filter.handleCaptureTextFilter('totalBills(ge)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('totalBills(ge)', e)} value={this.props.filter.filterText.minTotalBills ? this.props.filter.filterText.minTotalBills : ''} />
                                            <label htmlFor="minTotalBills">Qty min</label>
                                        </div>
                                    </div>
                                    <div className="form-group col-2 p-to-bill-details">
                                        <p><small>TO</small></p>
                                    </div>
                                    <div className="form-group col-6">
                                        <div className="form-floating input-number">
                                            <input name='maxTotalBills' id='maxTotalBills' type='number' className="form-control" placeholder="Qty max" onChange={(e) => this.props.filter.handleCaptureTextFilter('totalBills(le)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('totalBills(le)', e)} value={this.props.filter.filterText.maxTotalBills ? this.props.filter.filterText.maxTotalBills : ''} />
                                            <label htmlFor="maxTotalBills">Qty max</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div>
                    <h5 className='title'>Custom Fields</h5>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="customField1" id="customField1" className={"form-control"} placeholder="Test Customer Field 1" onChange={(e) => this.props.filter.handleCaptureTextFilter('customField1(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customField1(ct)', e)} value={this.props.filter.filterText.customField1 ? this.props.filter.filterText.customField1 : ''} />
                                <label htmlFor="customField1">Test Customer Field 1</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="customField2" id="customField2" className={"form-control"} placeholder="Test Customer Field 2" onChange={(e) => this.props.filter.handleCaptureTextFilter('customField2(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customField2(ct)', e)} value={this.props.filter.filterText.customField2 ? this.props.filter.filterText.customField2 : ''} />
                                <label htmlFor="customField2">Test Customer Field 2</label>
                            </div>
                        </div>
                    </div>
                </div>                
            </>);
    }
}

export { ViewPartialVendors };