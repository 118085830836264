import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportPaginator } from '../../components/ReportPaginator';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { Modal, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { Layout } from '../../components/Layout'
import {BiX} from '@react-icons/all-files/bi/BiX';
import {BiNotepad} from '@react-icons/all-files/bi/BiNotepad';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { Message } from '../../components/Message';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiDollarCircle, BiTrash } from 'react-icons/bi';
import { NameAvatar } from '../../components/NameAvatar';
import { VendorsLinks } from '../../components/VendorsLinks';
import SelectSearch from 'react-select-search';
import '../../assets/css/react-select-search.css';
import Fuse from 'fuse.js';
import { MoneyOutLinks } from '../../components/MoneyOutLinks';
import { AiOutlineCheckCircle, AiOutlineCloseCircle} from 'react-icons/ai';
import { PayBill } from '../../components/PayBill';
import { ModalImport } from '../Common/ModalImport';
import { MainBar } from '../../components/MainBar';
import { PayabliStorageManager } from '../../api/localStorageManager';
import { BiSortAlt2, BiDotsVerticalRounded } from 'react-icons/bi';
import { RightPanel } from '../../components/RightPanel';
import { QuickViewBill } from '../../components/QuickViewBill';

export default function fuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name', 'value'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }

        return fuse.search(value);
    };
}

@inject('reports', 'global', 'vendor','user', 'bill', 'vTerminal', 'entry')
@observer
class BillsReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            sortDirection: "desc",
            billIdToDelete: null ,
            billIdToApprove: null ,
            deleteModalIsOpen: false,
            payBillModalIsOpen: false,
            sendForApprovalModalIsOpen: false,
            usersForApproval: [],
            approvalEmails: [],
            approveModalIsOpen: false,
            // 1 to approve, 0 to disaprove
            approveAction: null,            
            payBillRightPanelOpen: false,
            billToPay: 0,
            modalImportIsOpen: false,
            flagItemAllColumn: true,
            localReportSummary: null
        };
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.deleteBill = this.deleteBill.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.deleteBillAction = this.deleteBillAction.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.exportFile = this.exportFile.bind(this);        
        this.filter = this.filter.bind(this);
        this.handleQuickFilter = this.handleQuickFilter.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.viewVendor = this.viewVendor.bind(this);        
        this.payBill = this.payBill.bind(this);
        this.openPayBillModal = this.openPayBillModal.bind(this);
        this.closePayBillModal = this.closePayBillModal.bind(this);
        this.payBillAction = this.payBillAction.bind(this);        
        this.sendForApproval = this.sendForApproval.bind(this);
        this.openSendForApprovalModal = this.openSendForApprovalModal.bind(this);
        this.closeSendForApprovalModal = this.closeSendForApprovalModal.bind(this);
        this.sendForApprovalAction = this.sendForApprovalAction.bind(this);
        this.handleSelectSearchForApproval = this.handleSelectSearchForApproval.bind(this);
        this.handleDeleteForApproval = this.handleDeleteForApproval.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);        
        this.approveBill = this.approveBill.bind(this);
        this.disapproveBill = this.disapproveBill.bind(this);
        this.approveBillAction = this.approveBillAction.bind(this);        
        this.closeApproveModal = this.closeApproveModal.bind(this);
        this.openApproveModal = this.openApproveModal.bind(this);        
        this.closePaybillPanel = this.closePaybillPanel.bind(this);
        this.openPaybillPanel = this.openPaybillPanel.bind(this);
        this.editBill = this.editBill.bind(this);
        this.viewBill = this.viewBill.bind(this);
        this.payBillChild = React.createRef();
        this.toggleModalImport = this.toggleModalImport.bind(this);
        this.handleImportAction = this.handleImportAction.bind(this);   
        this.handlerImportClick = this.handlerImportClick.bind(this);   
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.getNameReport = this.getNameReport.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.getMainSummary = this.getMainSummary.bind(this);
    }
    toggleModalImport(){
        this.setState({modalImportIsOpen: false})
    }
    handleImportAction(){

    }
    toggleModalMarkPaid(recordIdSelected) {
    this.setState({
        recordIdSelected: recordIdSelected,
        markPaidModalIsOpen: !this.state.markPaidModalIsOpen,
    });
    }
    handlerImportClick(ev){
    this.setState({modalImportIsOpen: true})
    }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                Vendor                : { label:'Vendor Name', class: '', display: true },
                VendorId              : { label:'Vendor ID', class: '', display: false },
                ExternalPaypointID    : { label:'External Paypoint ID', class: '', display: false },
                CustomerVendorAccount : { label:'Account #', class: '', display: true },
                VendorNumber          : { label:'Vendor #', class: '', display: false },
                Ein                   : { label:'Tax ID', class: '', display: false },
                Email                 : { label:'Email', class: '', display: false},
                Phone                 : { label:'Phone', class: '', display: false},
                BillingAddress        : { label:'Billing Address', class: '', display: false},
                RemitAddress          : { label:'Remit Address', class: '', display: false},
                BillNumber            : { label:'Bill #', class: '', display: true },
                LotNumber             : { label:'Lot #', class: '', display: true },
                PaymentGroup          : { label:'Payment Group', class: '', display: true},
                BillDate              : { label:'Bill Date', class: '', display: false }, 
                Type                  : { label:'Type', class: 'text-center', display: true }, 
                Status                : { label:'Bill Status', class: '', display: true },
                PaymentStatus         : { label:'Payment Status', class: '', display: true},
                TotalAmount           : { label:'Total Amount', class: 'text-right', display: true },
                TransId               : { label:'Trans. ID', class: '', display: false },
                Source                : { label:'Source', class: '', display: false},
                Notes                 : { label:'Notes', class: 'text-center', display: true},
                CreatedOn             : { label:'Created On', class: '', display: false},
                LastUpdatedOn         : { label:'Last Updated On', class: '', display: false},
                DueOn                 : { label:'Due On', class: '', display: true },
                CustomField1          : { label:'Accounting Field 1', class: '', display: false},
                CustomField2          : { label:'Accounting Field 2', class: '', display: false},
                //QuickActions          : { label:'Quick Actions', class: 'text-center', display: true},
            }
        )
        this.props.reports.setRecords([]);

        if(this.props.match.params.id){
            this.props.vendor.getVendorFromApi(this.props.match.params.id).then(res => {
                this.setState({currentVendor:res.data});
                this.clearFilters();
            })
            .catch(error => {
                toast.error("Something is Wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }else{
            this.setState({currentVendor:null});
            this.clearFilters();
        }  

        this.getMainSummary();
    }

    getMainSummary(){
        this.props.reports.getReportSummaryFromApi('bills', PayabliStorageManager.getEntryName()).then(res => {
            this.setState({localReportSummary : res});
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is wrong when trying to load summary!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    handleSelectSearchForApproval(e){
        let emailsArray = this.state.approvalEmails;
        let add = true;
        emailsArray.forEach(function (item) {
            if(item === e){
                add = false;
            }
        });
        if(add === true){
            emailsArray.push(e);
        }
        this.setState({approvalEmails: emailsArray});
    }

    handleDeleteForApproval(e){
        let emailsArray = this.state.approvalEmails;
        emailsArray.forEach(function (item, index) {
            if(item === e){
                emailsArray.splice(index,1);
            }
        });
        this.setState({approvalEmails: emailsArray});
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'bills').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    viewVendor(id, object){
        object.preventDefault();
        this.props.history.push("/"+PayabliStorageManager.getEntryName()+"/vendor/"+id);
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    deleteBill(billIdToDelete) {
        this.setState({ billIdToDelete: billIdToDelete });
        this.openDeleteModal();
    }

    openDeleteModal() {
        this.setState({ deleteModalIsOpen: true });
    }
    
    closeDeleteModal() {
        this.setState({ deleteModalIsOpen: false });
    }

    closeApproveModal() {
        this.setState({ approveModalIsOpen: false });
    }
    
    openApproveModal() {
        this.setState({ approveModalIsOpen: true });
    }

    deleteBillAction() {
        let billIdToDelete = this.state.billIdToDelete;
        if (billIdToDelete) {
            this.props.global.setLoading(true);
            this.props.reports.deleteBill(billIdToDelete)
            .then((result) => {
                this.closeDeleteModal();
                this.props.global.setLoading(false);
                toast.success('Data deleted successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container',
                });
            })
            .catch((error) => {
                this.props.global.setLoading(false);
                let errorMessage =
                error.response && error.response.data.responseText
                    ? error.response.data.responseText
                    : 'Something is Wrong!';
                toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
                });
            });
        }
    }

    async payBill(bill){
        this.setState({billToPay:bill});

        //Bill Details
        this.props.bill.updateStatusBill('edit');
        this.props.bill.setBillDetails({
            billNumber: bill.BillNumber,
            billDate: new Date(bill.BillDate),
            dueDate: new Date(bill.DueDate),
            term: bill.Terms,
            //scheduleCheck: isSchedule,
            frequency: bill.Frequency,
            endDate: bill.EndDate ? new Date(bill.EndDate) : null,
        });
        this.props.bill.updateAdvancedOptions({ memoNote: bill.Comments ? bill.Comments : null });
        this.setState({billToPay: bill});

        this.props.vTerminal.clearVendor();
        this.props.vTerminal.clearVendorSelected();
        this.props.vTerminal.selectVendorObject(bill.Vendor);
    
        this.payBillChild.current.goto(0);
        this.payBillChild.current.selectPaymentDeliveryMethod(1);

        this.props.global.setLoading(true);
        const res = await this.props.entry.getEntryFromApi(bill.PaypointEntryname);
        if (res.responseData && res.responseData.Paypoint) {
            this.props.global.setLoading(false);
            this.props.vTerminal.setCredentials(res.responseData.Paypoint.Credentials);
            this.openPaybillPanel();
        }
        else{
            this.props.global.setLoading(false);
        }
    }

    payBillAction(){
        this.props.global.setLoading(true);
        this.props.reports.payBillMoneyOut(this.state.billToPay).then(res => {
            this.getReportFromApi();
            toast.success("Bill paid successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container'
            });
            this.closePayBillModal();
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
            this.props.global.setLoading(false);
        });
    }

    openPayBillModal() {
        this.setState({ payBillModalIsOpen: true });
    }
    
    closePayBillModal() {
        this.setState({ payBillModalIsOpen: false });
    }

    sendForApproval(billId){
        this.props.global.setLoading(true);
        this.props.user.getUsers("?status(eq)=1&role.roleLabel(eq)=Bills&role.roleValue(eq)=true")
        .then((result) => {
            let usersArray = [];
            result.forEach(function (item) {
                if(item.Email){
                    usersArray.push({name: (item.Name ? item.Name: '')+' ('+item.Email+')', value: item.Email})
                }
            });

            this.setState({usersForApproval: usersArray, billIdToApprove: billId}, function(){
                this.openSendForApprovalModal();
                this.props.global.setLoading(false);
            })
        })
        .catch((error) => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!';
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    sendForApprovalAction(){
       if(this.state.approvalEmails.length > 0){
            this.props.global.setLoading(true);
            this.props.reports.sendForApproval(this.state.billIdToApprove,this.state.approvalEmails)
            .then((result) => {
                this.closeSendForApprovalModal();
                this.props.global.setLoading(false);
                this.getReportFromApi();
                toast.success('Request for Approval sent successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container',
                });
            })
            .catch((error) => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!';
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
       }
    }

    openSendForApprovalModal() {
        this.setState({ approvalEmails: [], sendForApprovalModalIsOpen: true });
    }
    
    closeSendForApprovalModal() {
        this.setState({ sendForApprovalModalIsOpen: false });
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
   
    clearFilters(){
        this.setState({ head: ""}, function(){
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    clearFilter(e, filter, filterType){
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter,"");
    }

    exportFile(format){
        this.props.reports.exportFile('bills',format,this.state.orgId);
    }

    editBill(url,e){
        window.location.href = url;
    }

    viewBill(url,e){
        window.location.href = url;
    }

    viewRecordDetails(index, object){
        if(this.props.global.isDrag()){
            return;
        }

        if(object && object.target.rel === undefined){
            object.preventDefault();
        }
        if(object && object.target.id !== "actionsMenuButton" 
            && object.target.nodeName.toLowerCase() !=='svg'
            && object.target.nodeName.toLowerCase() !=='path'
            && object.target.className !== 'papercheckblue'
            && object.target.nodeName.toLowerCase() !== 'a'){
                let thisObj = this;
                if(thisObj.props.global.rightPanelOpen && thisObj.props.reports.recordDetails !== index){
                    thisObj.props.global.setRightPanelOpen(false);
                    thisObj.props.reports.setRecordDetails(index);
                    setTimeout(function(){
                        thisObj.props.global.setRightPanelOpen(true);
                    }, 500)                
                }else{
                    thisObj.props.reports.setRecordDetails(index);
                    thisObj.props.global.setRightPanelOpen(true);
                }
        }
    }

    approveBill(e, IdBill){
        e.preventDefault();
        this.setState({
            billIdToApprove: IdBill,
            approveAction: 1,
            approveModalIsOpen: true
        })
    }

    disapproveBill(e, IdBill){
        e.preventDefault();
        this.setState({
            billIdToApprove: IdBill,
            approveAction: 0,
            approveModalIsOpen: true
        })
    }

    approveBillAction(){
        let billIdToApprove = this.state.billIdToApprove;
        let approveAction = this.state.approveAction;

        if (billIdToApprove && approveAction !== null) {
            this.props.global.setLoading(true);
            this.props.bill.approveBill(billIdToApprove, approveAction)
            .then((result) => {
                this.closeApproveModal();
                this.props.global.setLoading(false);
                this.getReportFromApi();
                toast.success('Data '+ (approveAction ? "approved":"disapproved") + ' successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container',
                });
            })
            .catch((error) => {
                this.props.global.setLoading(false);
                let errorMessage =
                error.response && error.response.data.responseText
                    ? error.response.data.responseText
                    : 'Something is Wrong!';
                toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
                });
            });
        }
    }

    getMappingFields(){
        return {
            'Vendor': 'vendor',
            'VendorId': 'vendorid',
            'ParentOrgName': 'orgname',
            'PaypointLegalname': 'paypointlegal',
            'ExternalPaypointID': 'externalpaypointid',
            'CustomerVendorAccount': 'vendoraccount',
            'VendorNumber': 'vendornumber',
            'Ein': 'ein',
            'Email': 'email',
            'Phone': 'phone',
            'BillingAddress': 'billaddress',
            'RemitAddress': 'remitaddress',
            'BillNumber': 'billnumber',
            'LotNumber': 'lotnumber',
            'PaymentGroup': 'paymentgroup',
            'Time': 'billdate',
            'Type': 'paymentmethod',
            'Status': 'status',
            // 'PaymentStatus': 'paymentstatus',
            'TotalAmount': 'netamount',
            'TransId': 'paymentid',
            // 'Source': 'source',
            'CreatedOn': 'createat',
            'LastUpdatedOn': 'lastupdated',
            'DueOn': 'billduedate',
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.props.global.setLoading(true);
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }
    getReportFromApi(){
        let tempFilterValue = {};
        if(this.state.sortBy){
            tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
        }
        if(this.state.currentVendor){
            tempFilterValue['vendorNumber(eq)'] = this.state.currentVendor.VendorNumber;
        }
        if(this.props.match.params.vendorNumber){
            tempFilterValue['vendorNumber(eq)'] = this.props.match.params.vendorNumber;
            tempFilterValue['status(eq)'] = 1;
        }       
        if(this.props.match.params.billNumber){
            tempFilterValue['billNumber(eq)'] = decodeURIComponent(this.props.match.params.billNumber);
        }
        this.filter(tempFilterValue);
    }

    handleQuickFilter(value){
        this.filter('status(eq)',value)
        this.setState({ head: value });
    }

    closePaybillPanel(){
        this.setState({ payBillRightPanelOpen: false});
    }
    
    openPaybillPanel(){
        this.setState({ payBillRightPanelOpen: true});
    }
    getNameReport(){
        return (
            <>
                { (this.props.match.params.id && this.state.currentVendor) &&
                    <h5>Vendor - {this.state.currentVendor.Name1} {this.state.currentVendor.Name2}</h5>
                }
                
                { (this.props.match.params.vendorNumber) &&
                    <h5>Vendor - {this.props.match.params.vendorNumber}</h5>
                }
                
                { (this.props.match.params.billNumber) &&
                    <h5>Bill # {decodeURIComponent(this.props.match.params.billNumber)}</h5>
                }
                <div className="mr-3 inline">
                    <h6 data-qaid="allBillsMoneyOutReportsPage">{this.state.head ? this.props.reports.getBillStatus(this.state.head,true) : "All Bills"}</h6>
                </div>
            </>  
        )
    }
    render() {
        const summary = this.state.localReportSummary;
        return (
            <Layout {...this.props}>

            <RightPanel>
            <h5 className="header mb-3" data-qaid="billDetailsMoneyOut">Bill Details</h5>
            <QuickViewBill ref={this.quickViewBill} payBill={this.payBill} sendForApproval={this.sendForApproval}/>
            </RightPanel> 

            <div id="paybill-rigth-panel" className={ this.state.payBillRightPanelOpen ? 'open' : ''}>
                <div className="popover-body popover-body-2">
                    <BiX id="right-panel-close" onClick={(e) => this.closePaybillPanel()}/>
                    <h5 className="header mb-3">Pay Bill</h5>
                    <PayBill isEmbedded={true} ref={this.payBillChild} billToPay={this.state.billToPay} functionCallBack={this.closePaybillPanel} secondFunctionCallBack={this.getReportFromApi}/>
                </div>
            </div>
            {this.state.payBillRightPanelOpen &&
            <div onClick={(e) => this.closePaybillPanel()} id="right-panel-layer"></div>
            }


            <div>

            <Modal
                style={{ textAlign: 'left' }}
                show={this.state.sendForApprovalModalIsOpen}
                onHide={this.closeSendForApprovalModal}
                size="md"
                centered
              >
            <Modal.Body>
                <h6 className="mb-3">Send for Approval</h6>
                <p className="small">
                Please find and select users to send an email for bill approval.
                </p>

                <div className="col-12 mb-3 selectSearchPT0">
                        <SelectSearch
                            id="searchUser"
                            options={this.state.usersForApproval}
                            autoComplete={"selectSearch"}
                            search
                            filterOptions={fuzzySearch}
                            emptyMessage="Not found"
                            placeholder="Find and select users"
                            value={this.state.mcc}
                            onChange={(e) =>
                                this.handleSelectSearchForApproval(e)
                            }
                            disabled={false}
                        />
                 
                </div>
                {
                    this.state.approvalEmails.length > 0 ?
                    <div className="mb-4 filter-badges">                    
                    {
                        this.state.approvalEmails.map((record, i) => (
                            <span key={i} className="badge widthAuto mb-1" onClick={()=>this.handleDeleteForApproval(record)}>{record} <BiX/></span>
                        ))
                    }
                    </div>
                    :
                    <div className="mb-4">
                        <Message message="Select at least one user."/>
                    </div>
                }
                
                
                
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <button
                        className="btn full-w btn-light" type="button"
                        onClick={(e) => this.closeSendForApprovalModal()}
                        >
                        Cancel
                        </button>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <button
                        className="btn full-w btn-primary"
                        onClick={this.sendForApprovalAction}
                        >
                        Send
                        </button>
                    </div>
                </div>
            </Modal.Body>
            </Modal>
            <ModalImport
                isOpen={this.state.modalImportIsOpen}
                closeModal={this.toggleModalImport}
                action={this.handleImportAction}
                modalType='Bill'
                textHeader="Import Bill Data"
                initialState={{uploadedFiles: [],correctFiles: []}}
            />
            <Modal
                style={{ textAlign: 'center' }}
                show={this.state.deleteModalIsOpen}
                onHide={this.closeDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <BiTrash className="icon-modal" />
                  <h5>Delete</h5>
                  <p className="small">
                    Are you sure you want to delete this Bill?
                  </p>
                  <Button
                    className="btn cancel-btn"
                    variant="default"
                    onClick={(e) => this.closeDeleteModal()}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn"
                    variant="danger"
                    onClick={this.deleteBillAction}
                  >
                    Delete
                  </Button>
                </Modal.Body>
              </Modal>

              <Modal
                style={{ textAlign: 'center' }}
                show={this.state.payBillModalIsOpen}
                onHide={this.closePayBillModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <BiDollarCircle className="icon-modal" />
                  <h5>Pay Bill</h5>
                  <p className="small">
                    Are you sure you want to pay this Bill?
                  </p>
                  <Button
                    className="btn cancel-btn"
                    variant="default"
                    onClick={(e) => this.closePayBillModal()}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn"
                    variant="primary"
                    onClick={this.payBillAction}
                  >
                    &nbsp;&nbsp;&nbsp;Pay&nbsp;&nbsp;&nbsp;
                  </Button>
                </Modal.Body>
              </Modal>

              <Modal style={{textAlign: "center"}} show={this.state.approveModalIsOpen} onHide={this.closeApproveModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        {this.state.approveAction === 1 ? 
                            <AiOutlineCheckCircle className="icon-modal"/>
                        : 
                            <AiOutlineCloseCircle className="icon-modal"/>
                        }
                       
                        <h5>{this.state.approveAction === 1 ? "Approve" : "Disapprove"}</h5>
                        <p className="small">Are you sure you want to {this.state.approveAction === 1 ? "approve" : "disapprove"} this bill?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeApproveModal()}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        {this.state.approveAction === 1 ? 
                        <Button className="btn" variant="primary" onClick={(e) => this.approveBillAction()}>
                            Approve
                        </Button>
                        : 
                        <Button className="btn" variant="danger" onClick={(e) => this.approveBillAction()}>
                            Disapprove
                        </Button>
                        }
                        
                    </Modal.Body>
                </Modal>
                <ParentFilterPanel report={'bills'} vendorNumber={this.props.match.params.vendorNumber} billNumber={this.props.match.params.billNumber ? decodeURIComponent(this.props.match.params.billNumber) : null} />
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    {
                    this.state.currentVendor ?
                        <VendorsLinks gobackLink={"/" + this.props.global.getURLEntry() + '/vendor/'+this.state.currentVendor.VendorId} gobackText="Vendor Overview" history={this.props.history} goback={true} selected="bills" id={this.state.currentVendor.VendorId} />
                    :(this.props.match.params.billNumber || this.props.match.params.vendorNumber) ?
                        (<MoneyOutLinks gobackText="Go back" history={this.props.history} goback={true} selected="bills" />)
                    :
                      (
                        <>
                            {(!this.props.match.params.id && !this.props.match.params.billNumber && !this.props.match.params.vendorNumber) &&
                                <MoneyOutLinks gobackText="Go back" history={this.props.history} goback={false} selected="bills" />
                            }
                        </>
                      )
                    }
                </div>
                </TopBar>
                

                <div className="mt-body4">
                    <MainBar
                        btnNewName="Add Bill" 
                        newLink={"/"+PayabliStorageManager.getEntryName()+"/bills/new"} 
                        reportNameComponent={this.getNameReport}
                        dataQAName="BillsMoneyOut"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        buttonImport={true}
                        importFile={this.handlerImportClick}
                        withTotal={true}
                        reportOut={true}
                        totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    />

                <div className="statistic-reports">
                    <div className='item' onClick={(e) => this.handleQuickFilter('1')}>
                        <div className='row'>
                            <div className='col-8 text-right column'>
                                <p>Active</p>
                                ${ summary && summary.totalamountactive ? this.props.global.numberWithCommas(summary.totalamountactive.toFixed(2)) : "0.00" }
                            </div>
                            <div className='col-4 column'>
                            <span className="bg-secondary">{ summary && summary.totalactive ? summary.totalactive : "0" }</span>
                            </div>
                        </div>
                    </div>
                    <div className='item' onClick={(e) => this.handleQuickFilter('2')}>
                        <div className='row'>
                            <div className='col-8 text-right column'>
                                <p>Sent to Approval</p>
                                ${ summary && summary.totalamountsent2approval ? this.props.global.numberWithCommas(summary.totalamountsent2approval.toFixed(2)) : "0.00" }
                            </div>
                            <div className='col-4 column'>
                            <span className="bg-secondary">{ summary && summary.totalsent2approval ? summary.totalsent2approval : "0" }</span>
                            </div>
                        </div>
                    </div>
                    <div className='item' onClick={(e) => this.handleQuickFilter('20')}>
                        <div className='row'>
                            <div className='col-8 text-right column'>
                                <p>Approved</p>
                                ${ summary && summary.totalamountapproved ? this.props.global.numberWithCommas(summary.totalamountapproved.toFixed(2)) : "0.00" }
                            </div>
                            <div className='col-4 column'>
                            <span className="bg-warning">{ summary && summary.totalapproved ? summary.totalapproved : "0" }</span>
                            </div>
                        </div>
                    </div>
                    <div className='item' onClick={(e) => this.handleQuickFilter('50')}>
                        <div className='row'>
                            <div className='col-8 text-right column'>
                                <p>In Transit</p>
                                ${ summary && summary.totalamountintransit ? this.props.global.numberWithCommas(summary.totalamountintransit.toFixed(2)) : "0.00" }
                            </div>
                            <div className='col-4 column'>
                            <span className="bg-warning">{ summary && summary.totalintransit ? summary.totalintransit : "0" }</span>
                            </div>
                        </div>
                    </div>
                    <div className='item' onClick={(e) => this.handleQuickFilter('100')}>
                        <div className='row'>
                            <div className='col-8 text-right column'>
                                <p>Paid</p>
                                ${ summary && summary.totalamountpaid ? this.props.global.numberWithCommas(summary.totalamountpaid.toFixed(2)) : "0.00" }
                            </div>
                            <div className='col-4 column'>
                            <span>{ summary && summary.totalpaid ? summary.totalpaid : "0" }</span>
                            </div>
                        </div>
                    </div>
                    <div className='item' onClick={(e) => this.handleQuickFilter('11')}>
                        <div className='row'>
                            <div className='col-8 text-right column'>
                                <p>Not Approved</p>
                                ${ summary && summary.totalamountdisapproved ? this.props.global.numberWithCommas(summary.totalamountdisapproved.toFixed(2)) : "0.00" }
                            </div>
                            <div className='col-4 column'>
                            <span className="bg-danger">{ summary && summary.totaldisapproved ? summary.totaldisapproved : "0" }</span>
                            </div>
                        </div>
                    </div>
                    <div className='item' onClick={(e) => this.handleQuickFilter('-99')}>
                        <div className='row'>
                            <div className='col-8 text-right column'>
                                <p>Deleted/Cancelled</p>
                                ${ summary && summary.totalamountcancel ? this.props.global.numberWithCommas(summary.totalamountcancel.toFixed(2)) : "0.00" }
                            </div>
                            <div className='col-4 column'>
                            <span className="bg-danger">{ summary && summary.totalcancel ? summary.totalcancel : "0" }</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container">  
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                            record[1].display && 
                            (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                            </th> ))
                            ||
                            (record[1].display && 
                            (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                            </th> ))
                        ))
                    }
                    <th className="text-center sticky-row" scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                
                { this.props.reports.records.map((record, i) => (
                        <tr onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} key={i} className="cursorPointer" onClick={(e) => this.viewRecordDetails(i,e)} data-qaid={"billMoneyOutRow-"+(i)}>
                            {/*<td>
                            <div className="icheck-primary">
                                <input
                                    type="checkbox"
                                    id={"checkRow"+i}
                                />
                                <label htmlFor={"checkRow"+i}></label>
                            </div>
                            </td>*/}
                            { (this.props.reports.headers.Vendor && this.props.reports.headers.Vendor.display) && 
                                <td className='avatarTd'> <NameAvatar text={record.Vendor && record.Vendor.Name1 ? record.Vendor.Name1 : '-'}/> {record.Vendor && record.Vendor.Name1 ? this.props.global.capitalizeFirstLetterOfEachWord(record.Vendor.Name1) : '-'} {record.Vendor && record.Vendor.Name2 ? this.props.global.capitalizeFirstLetterOfEachWord(record.Vendor.Name2) : ''}</td>
                            }                            
                            { (this.props.reports.headers.VendorId && this.props.reports.headers.VendorId.display) && 
                                <td>{record.PaymentId && record.Vendor.VendorId ? record.Vendor.VendorId : '-'}</td>
                            }
                            { (this.props.reports.headers.ExternalPaypointID && this.props.reports.headers.ExternalPaypointID.display) &&
                                <td>{record && record.externalPaypointID ? record.externalPaypointID : '-'}</td>
                            }
                            { (this.props.reports.headers.CustomerVendorAccount && this.props.reports.headers.CustomerVendorAccount.display) && 
                                <td>{record.Vendor && record.Vendor.customerVendorAccount ? record.Vendor.customerVendorAccount : '-'}</td>
                            }
                            { (this.props.reports.headers.VendorNumber && this.props.reports.headers.VendorNumber.display) && 
                                <td>{record.Vendor && record.Vendor.VendorNumber ? record.Vendor.VendorNumber : '-'}</td>
                            }
                            { (this.props.reports.headers.Ein && this.props.reports.headers.Ein.display) && 
                                <td>{record.Vendor && record.Vendor.EIN ? record.Vendor.EIN : '-'}</td>
                            }                            
                            { (this.props.reports.headers.Email && this.props.reports.headers.Email.display) && 
                                <td>{record.Vendor && record.Vendor.Email ? record.Vendor.Email : '-'}</td>
                            }                            
                            { (this.props.reports.headers.Phone && this.props.reports.headers.Phone.display) && 
                                <td>{record.Vendor && record.Vendor.Phone ? record.Vendor.Phone : '-'}</td>
                            }                            
                            {(this.props.reports.headers.BillingAddress && this.props.reports.headers.BillingAddress.display) &&
                                <td>{record.Vendor && record.Vendor.Address1 ? ((record.Vendor.Address1?record.Vendor.Address1+" ":"")+ (record.Vendor.Address2?record.Vendor.Address2+" ":"")+ (record.Vendor.City?record.Vendor.City+" ":"")+ (record.Vendor.State?record.Vendor.State  +" ":"")+ (record.Vendor.Zip?record.Vendor.Zip+" ":'')+ (record.Vendor.Country?record.Vendor.Country:'')) : '-'} </td>
                            }
                            {(this.props.reports.headers.RemitAddress && this.props.reports.headers.RemitAddress.display) &&
                                <td>{record.Vendor && record.Vendor.remitAddress1 ? ((record.Vendor.remitAddress1?record.Vendor.remitAddress1+" ":"")+ (record.Vendor.remitAddress2?record.Vendor.remitAddress2+" ":"")+ (record.Vendor.remitCity?record.Vendor.remitCity+" ":"")+ (record.Vendor.remitState?record.Vendor.remitState  +" ":"")+ (record.Vendor.remitZip?record.Vendor.remitZip+" ":'')+ (record.Vendor.remitCountry?record.Vendor.remitCountry:'')) : '-'} </td>
                            }
                            { (this.props.reports.headers.BillNumber && this.props.reports.headers.BillNumber.display) && 
                                <td>{record && record.BillNumber ? record.BillNumber : '-'}</td>
                            }
                            { (this.props.reports.headers.LotNumber && this.props.reports.headers.LotNumber.display) && 
                                <td>{record && record.LotNumber ? record.LotNumber : '-'}</td>
                            }                            
                            { (this.props.reports.headers.PaymentGroup && this.props.reports.headers.PaymentGroup.display) && 
                                <td>{record && record.PaymentGroup ? record.PaymentGroup : '-'}</td>
                            }                            
                            { (this.props.reports.headers.BillDate && this.props.reports.headers.BillDate.display) && 
                                <td>{record && record.BillDate ? (this.props.global.stringDateFormatV3(record.BillDate)) : '-'}</td>
                            }         
                            { (this.props.reports.headers.Type && this.props.reports.headers.Type.display) && 
                                <td className='text-center'>{record && record.PaymentMethod ? this.props.global.getPaymethodImg(record.PaymentMethod) : this.props.global.getPaymethodImg("timer")}</td>
                            }                                                   
                            { (this.props.reports.headers.Status && this.props.reports.headers.Status.display) && 
                                <td>{this.props.reports.getBillStatus(record.Status)}</td>
                            }
                            { (this.props.reports.headers.PaymentStatus && this.props.reports.headers.PaymentStatus.display) && 
                                <td>{this.props.global.getMoneyOutTransStatus(record?.Transaction?.Status)}</td>
                            }                            
                            { (this.props.reports.headers.TotalAmount && this.props.reports.headers.TotalAmount.display) && 
                                <td className='text-right'>${record && record.NetAmount ? this.props.global.numberWithCommas(record.NetAmount.toFixed(2)) : '0.00'}</td>
                            }                            
                            { (this.props.reports.headers.TransId && this.props.reports.headers.TransId.display) && 
                                <td>{record.PaymentId ?? '-'}</td>
                            }
                            { (this.props.reports.headers.Source && this.props.reports.headers.Source.display) && 
                                <td className='text-uppercase'>{record.Source ?? '-'}</td>
                            }                           
                            { (this.props.reports.headers.Notes && this.props.reports.headers.Notes.display) && 
                                <td className="text-center">
                                    {record && record.Comments ? 
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{record.Comments}</Tooltip>}
                                        >
                                        <BiNotepad className="label-info-icon in-input"/>
                                        </OverlayTrigger>
                                    : '-'}
                                </td>
                            }
                            { (this.props.reports.headers.CreatedOn && this.props.reports.headers.CreatedOn.display) && 
                                <td>{record && record.CreatedAt ? (this.props.global.stringDateFormatV3(record.CreatedAt)) : '-'}</td>
                            }                            
                            { (this.props.reports.headers.LastUpdatedOn && this.props.reports.headers.LastUpdatedOn.display) && 
                                <td>{record && record.LastUpdated ? (this.props.global.stringDateFormatV3(record.LastUpdated)) : '-'}</td>
                            }                            
                            { (this.props.reports.headers.DueOn && this.props.reports.headers.DueOn.display) && 
                                <td>{record && record.DueDate ? (this.props.global.stringDateFormatV3(record.DueDate)) : '-'}</td>
                            }
                            { (this.props.reports.headers.CustomField1 && this.props.reports.headers.CustomField1.display) && 
                                <td>{record && record.AccountingField1 ? record.AccountingField1 : '-'}</td>
                            }                            
                            { (this.props.reports.headers.CustomField2 && this.props.reports.headers.CustomField2.display) && 
                                <td>{record && record.AccountingField2 ? record.AccountingField2 : '-'}</td>
                            }                            
                            {/* (this.props.reports.headers.QuickActions && this.props.reports.headers.QuickActions.display) && 
                            <td style={{width: "104px"}} className="text-center approvalActions">
                                {(((record && record.Status === 2) || (record && record.Status === 5)) && this.props.bill.isUserAllowedToApprove(record.billApprovals)) ?
                                    <>

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip><div className='text-center'>Click to approve bill <br/>for payment</div></Tooltip>}
                                        >
                                       <a onClick={(e) => this.approveBill(e, record.IdBill)}><AiFillCheckCircle className='approve' /></a>
                                    </OverlayTrigger>
                                    
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip><div className='text-center'>Click to disapprove bill <br/>for payment</div></Tooltip>}
                                        >
                                      <a onClick={(e) => this.disapproveBill(e, record.IdBill)}><AiFillCloseCircle className='disapprove'/></a>
                                    </OverlayTrigger>

                                    
                                    </>
                                :
                                    ((record && record.Status === 20) && this.props.bill.isUserAllowedToApprove(record.billApprovals)) ?
                                        <>
                                       
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip><div className='text-center'>This bill has already <br/>been approved</div></Tooltip>}
                                            >
                                        <a><AiFillCheckCircle className='approve disabled' /></a>
                                        </OverlayTrigger>
                                        
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip><div className='text-center'>Click to disapprove bill <br/>for payment</div></Tooltip>}
                                            >
                                        <a onClick={(e) => this.disapproveBill(e, record.IdBill)}><AiFillCloseCircle className='disapprove'/></a>
                                        </OverlayTrigger>
                                  
                                        </>
                                        :
                                        "-"
                                    }
                            </td>
                                */}
                            <td className='text-center sticky-row'>
                                <DropdownButton
                                    menuAlign="right"
                                    title={<BiDotsVerticalRounded/>}
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                <Dropdown.Item onClick={(e)=>this.viewBill("/" + this.props.global.getURLEntry() +"/bills/view/"+record.IdBill, e)}>View Bill</Dropdown.Item>
                                {(record.Status !== -99 && record.Status !== 2 && record.Status !== 5 && record.Status !== 50 && record.Status !== 100) &&
                                    <Dropdown.Item onClick={(e)=>this.editBill("/" + this.props.global.getURLEntry() +"/bills/edit/"+record.IdBill, e)}>Edit Bill</Dropdown.Item>
                                }
                                {(record.Status === 1 || record.Status === 20) &&
                                <Dropdown.Item onClick={()=>this.payBill(record)}>Pay Bill</Dropdown.Item>
                                }
                                 {(record.Status === 1) &&
                                <Dropdown.Item onClick={()=>this.sendForApproval(record.IdBill)}>Send for Approval</Dropdown.Item>
                                 }
                                <Dropdown.Item onClick={(e)=>this.viewVendor(record.Vendor.VendorId,e)}>View Vendor</Dropdown.Item>
                                {record.BillNumber &&
                                    <Dropdown.Item as={Link} to={"/" + this.props.global.getURLEntry() + '/report/transaction/out/'+ encodeURIComponent(record.BillNumber)}>Tracking Status</Dropdown.Item>
                                }

                                 {(record.Status >= 0 && record.Status < 50 ) &&
                                 <>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  style={{ color: '#c00000' }}
                                  onClick={(e) =>
                                    this.deleteBill(record.IdBill)
                                  }
                                >
                                  Delete Bill
                                </Dropdown.Item>
                                </>
                                }
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No bills yet" description={<span>You can track bills details here.</span>}/>
                }
                </div>
                <ReportPaginator report="bills"/>

                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
       
    }
}

export { BillsReport };