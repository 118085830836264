import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'filter')
@observer
class RenderVendorInformation extends React.Component {

    render() {
        return (<>
                <h5 className='title'>Vendor Information</h5>
                <div className='row'>
                    <div className="col-sm-12 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="vendorNumber" id="vendorNumber" className={"form-control"} placeholder="Vendor #" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorNumber(ct)', e)} value={this.props.filter.filterText.vendorNumber ? this.props.filter.filterText.vendorNumber : ''} />
                            <label htmlFor="vendorNumber">Vendor #</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating input-number">
                            <input name="vendorEIN" id="vendorEIN" type='number' className={"form-control"} placeholder="Tax ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorEIN(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorEIN(ct)', e)} value={this.props.filter.filterText.vendorEIN ? this.props.filter.filterText.vendorEIN : ''} />
                            <label htmlFor="vendorEIN">Tax ID</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerVendorAccount" id="customerVendorAccount" className={"form-control"} placeholder="Account #" onChange={(e) => this.props.filter.handleCaptureTextFilter('customerVendorAccount(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customerVendorAccount(ct)', e)} value={this.props.filter.filterText.customerVendorAccount ? this.props.filter.filterText.customerVendorAccount : ''} />
                            <label htmlFor="customerVendorAccount">Account #</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="vendorName" id="vendorName" className={"form-control"} placeholder="Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorName(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorName(ct)', e)} value={this.props.filter.filterText.vendorName ? this.props.filter.filterText.vendorName : ''} />
                            <label htmlFor="vendorName">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="externalPaypointID" id="externalPaypointID" className={"form-control"} placeholder="External Paypoint ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('externalPaypointID(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('externalPaypointID(ct)', e)} value={this.props.filter.filterText.externalPaypointID ? this.props.filter.filterText.externalPaypointID : ''} />
                            <label htmlFor="externalPaypointID">External Paypoint ID</label>
                        </div>
                    </div>
                    <div>
                        <ContainerShowMoreLess>
                            <hr className='mb-4' style={{width: '95%'}}></hr>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating">
                                    <input name="vendorId" id="vendorId" className={"form-control"} placeholder="Vendor ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorId(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorId(eq)', e)} value={this.props.filter.filterText.vendorId ? this.props.filter.filterText.vendorId : ''} />
                                    <label htmlFor="vendorId">Vendor ID</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating">
                                    <input name="vendorEmail" id="vendorEmail" className={"form-control"} placeholder="Email" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorEmail(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorEmail(ct)', e)} value={this.props.filter.filterText.vendorEmail ? this.props.filter.filterText.vendorEmail : ''} />
                                    <label htmlFor="vendorEmail">Email</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating input-number">
                                    <input name="vendorPhone" id="vendorPhone" type='number' className={"form-control"} placeholder="Phone" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorPhone(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorPhone(ct)', e)} value={this.props.filter.filterText.vendorPhone ? this.props.filter.filterText.vendorPhone : ''} />
                                    <label htmlFor="vendorPhone">Phone</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating">
                                    <input name="vendorAddress" id="vendorAddress" className={"form-control"} placeholder="Billing Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorAddress(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorAddress(ct)', e)} value={this.props.filter.filterText.vendorAddress ? this.props.filter.filterText.vendorAddress : ''} />
                                    <label htmlFor="vendorAddress">Billing Address</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating">
                                    <input name="remitAddress" id="remitAddress" className={"form-control"} placeholder="Shipping Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('vendorRemitAddress(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('vendorRemitAddress(ct)', e)} value={this.props.filter.filterText.remitAddress ? this.props.filter.filterText.remitAddress : ''} />
                                    <label htmlFor="remitAddress">Remit Address</label>
                                </div>
                            </div>
                        </ContainerShowMoreLess>
                    </div>
                </div>
            </>);
    }
}

export { RenderVendorInformation };