import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { SettingsLinks } from '../../components/SettingsLinks';
import { TopBar } from '../../components/TopBar';
import { Modal, Button,  OverlayTrigger, Tooltip  } from "react-bootstrap";
import { BiTrash } from 'react-icons/bi';
import { FaUsers } from 'react-icons/fa';
import { PayabliStorageManager } from '../../api/localStorageManager';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IMaskInput } from 'react-imask';

@inject('global','entry','customer')
@observer
class CustomFields extends React.Component {
    

    constructor(props) {
        super(props);
        this.state = {
            customFields: [],
            identifiers: [],
            formErrors: {},
            confirmResetModalIsOpen: false,
            confirmApplyAllModalIsOpen: false,
        };
        this.addCustomField = this.addCustomField.bind(this);
        this.removeCustomField = this.removeCustomField.bind(this);
        this.saveCustomFields = this.saveCustomFields.bind(this);
        this.resetCustomFields = this.resetCustomFields.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.customFieldsKeyHandleTextChange = this.customFieldsKeyHandleTextChange.bind(this);
        this.customFieldsValueHandleTextChange = this.customFieldsValueHandleTextChange.bind(this);
        this.closeConfirmResetModal = this.closeConfirmResetModal.bind(this);
        this.openConfirmResetModal = this.openConfirmResetModal.bind(this);
        this.showError = this.showError.bind(this);
        this.handleSelectIdentifier = this.handleSelectIdentifier.bind(this);
        this.closeConfirmApplyAllModal = this.closeConfirmApplyAllModal.bind(this);
        this.openConfirmApplyAllModal = this.openConfirmApplyAllModal.bind(this);
    }

    componentDidMount() {
        this.props.global.setLoading(true);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ? 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0;

        this.props.entry.getEntryFromApi(entry).then(res => {
            this.props.entry.getOrganizationCustomFileds(res.responseData.Paypoint.ParentOrg.IdOrg).then(res => {
                let parentCustomFields = res.customFields
                let parentIdentifiers = res.identifiers

                this.props.entry.getPaypointSettings(entry).then(res => {
                    let customField = res?.customFields?.map(customField => {
                        const find = parentCustomFields?.find(data => data.key ===customField.key)
                         if (find !== undefined){
                             return {...customField, parent: true}
                         }
                         return customField
                     })

                    let identifiers =  res.customFields ? Array.from(res.customFields) : [];
                    this.props.customer.getIdentifiers().then(resIds => {
                        if(resIds.data){
                            resIds.data.forEach(function (identifier, o) {
                                identifiers.push({"key":identifier, "value":"", "readOnly":null});
                            });
                        }

                        this.setState({customFields: res.customFields ? customField : [], identifiers: identifiers}, function(){
                            if(res.identifiers){
                                let stateIdentifiers = this.state.identifiers;
                                res.identifiers.forEach(function (apiIdentifier, k) {
                                    stateIdentifiers.forEach(function (stateIdentifier, m) {
                                        if(apiIdentifier.key === stateIdentifier.key){
                                            stateIdentifier.checked = true;
                                            if(apiIdentifier.readOnly && apiIdentifier.readOnly === true){
                                                stateIdentifier.disabled = true;
                                            }
                                        }
                                    });
                                });

                                stateIdentifiers = stateIdentifiers.map(identifier => {
                                    const find = parentIdentifiers?.find(data => data.key ===identifier.key)
                                     if (find !== undefined){
                                         return {...identifier, parent: true}
                                     }
                                     return identifier
                                 })

                                this.setState({identifiers: stateIdentifiers});
                            }
                        });
                        this.props.global.setLoading(false);
                    })
                    .catch(error => {
                        this.showError(error);
                        this.props.global.setLoading(false);
                    });
                    
                })
                .catch(error => {
                        this.showError(error);
                        this.props.global.setLoading(false);
                });
            })
            .catch(error => {
                this.showError(error);
                this.props.global.setLoading(false);
            });
        })
        .catch(error => {
            this.showError(error);
            this.props.global.setLoading(false);
        });
    }

    getIdentifiersToSave(){
        let newIdentifiersSelected = [];
        this.state.identifiers.forEach(function (identifier, i) {
            if(identifier.checked &&  identifier.checked === true ){
                newIdentifiersSelected.push(identifier);
            }
        });
        return newIdentifiersSelected;
    }

    showError(error){
        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
        toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
        });
    }

    customFieldsKeyHandleTextChange(value, i){
        let customFields = this.state.customFields;
        let identifiers = this.state.identifiers;
        if(customFields[i].ref){
            identifiers[customFields[i].ref].key = value;
        }
        else{
            identifiers[i].key = value;
        }
        customFields[i].key = value;
        this.setState({ customFields: customFields, identifiers: identifiers });
    }

    customFieldsValueHandleTextChange(e, i){
        let customFields = this.state.customFields;
        let identifiers = this.state.identifiers;
        if(customFields[i].ref){
            identifiers[customFields[i].ref].value = e.target.value;
        }
        else{
            identifiers[i].value = e.target.value;
        }
        customFields[i].value = e.target.value;
        this.setState({ customFields: customFields,  identifiers: identifiers });
    }

    addCustomField(e){
        if(e){
            e.preventDefault();
        }
       
        let customFields = this.state.customFields ? this.state.customFields : [];
        let identifiers = this.state.identifiers ? this.state.identifiers : [];
        customFields.push({key:"", value: "", ref: identifiers.length});
        identifiers.push({key:"", value: ""});
        this.setState({ customFields: customFields , identifiers: identifiers });
    }

    removeCustomField(i){
        let customFields = this.state.customFields;
        let identifiers = this.state.identifiers;


        for (let l = 0; l < identifiers.length; l++){
            if(customFields[i].key === identifiers[l].key){
                identifiers.splice(l, 1);
                break;
            }
        }

        customFields.splice(i, 1);
        this.setState({ customFields: customFields, identifiers:identifiers });
    }

    handleSelectIdentifier(e, record){
        let identifiers = this.state.identifiers;
        identifiers.forEach(function (identifier, o) {
            if(record.key === identifier.key){
                identifier.checked = e.target.checked;
            }
        });
        this.setState({identifiers:identifiers});
    }

    saveCustomFields(){
        let errors = this.validateFields();
        this.setState({ formErrors: errors }, function(){
            if(Object.keys(errors).length === 0){
                let keys = this.state.customFields.map(field => String(field.key.trim()).toLowerCase());
                let hasDuplicates = keys.some((value, index) => keys.indexOf(value) !== index);  
                if(hasDuplicates) {
                    toast.error('There should be no duplicate fields', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                } else {
                    this.openConfirmApplyAllModal();
                }
            }
        });
    }

    saveCustomFieldsAction(){
        this.props.global.setLoading(true);   
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').pEntry;

        this.props.entry.updatePaypointSettings(entry, {
            customFields: this.state.customFields,
            identifiers: this.getIdentifiersToSave(),
        }).then(res => {
            toast.success("Custom Fields updated successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container'
            });
            this.props.global.setLoading(false);
            this.closeConfirmApplyAllModal();
            window.location.reload();
        })
        .catch(error => {
            this.showError(error);
            this.props.global.setLoading(false);
        });
    }
    
    resetCustomFields(){
        let customFields = this.state.customFields;
        let newCustomFields = [];
        customFields.forEach(function (item, index) {
            if(item.readOnly === true){
                newCustomFields.push(item);
            }
        });

        this.setState({customFields: newCustomFields, confirmResetModalIsOpen: false});
    }

    validateFields(){
        let validators = this.props.global.validators;
        let errors = {};
        this.state.customFields.forEach(function (item, index) {
            if(validators.isEmpty(item.key) || validators.isMaxLength(250, item.key)){
                errors['customFieldsKey'+index]= true;
            }

            if(validators.isMaxLength(250, item.value)){
                errors['customFieldsValue'+index]= true;
            }
        });

        return errors;
    }

    
    closeConfirmResetModal(){
        this.setState({confirmResetModalIsOpen:false});
    }
   
    closeConfirmApplyAllModal(){
        this.setState({confirmApplyAllModalIsOpen:false});
    }

    openConfirmResetModal(){
        this.setState({confirmResetModalIsOpen:true});
    }
    
    openConfirmApplyAllModal(){
        this.setState({confirmApplyAllModalIsOpen:true});
    }

    render() {
        return (
            <Layout {...this.props}>
            <div>

                <Modal style={{ textAlign: "center" }} show={this.state.confirmResetModalIsOpen} onHide={this.closeConfirmResetModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <BiTrash className="icon-modal" />
                        <h5>Reset</h5>
                        <p className="small">Are you sure you want to reset custom fields?</p>
                        <Button className="btn" variant="danger" onClick={(e) => this.resetCustomFields()}>
                            Reset
                        </Button>
                    </Modal.Body>
                </Modal>
                
                <Modal style={{ textAlign: "center" }} show={this.state.confirmApplyAllModalIsOpen} onHide={this.closeConfirmApplyAllModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <FaUsers className="icon-modal" />
                        <h5>Apply to All</h5>
                        <p className="small">Are you sure you want to apply to all customers?</p>
                        <Button className="btn" variant="light" onClick={(e) => this.closeConfirmApplyAllModal()}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="primary" onClick={(e) => this.saveCustomFieldsAction()}>
                            Apply
                        </Button>
                       
                    </Modal.Body>
                </Modal>

                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <SettingsLinks selected="fields"/>
                </div>
                </TopBar>
                
                <div className="mt-body4">
                    <div className="mb-4">
                        <h5 className="mb-3">Custom Fields</h5>
                        <p className="small-grey">View, edit, and add custom fields to gather additional information from customers when payments are made
</p>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{maxWidth: "720px"}}>
                        
                            { this.state.customFields.map((record, i) => (
                            <div key={i} className="row">
                                <div className="col-10 mb-3">
                                    <div className="form-floating">
                                        <IMaskInput
                                            mask={/^[a-zA-Z0-9_ ]+$/}
                                            id={"customFieldsKey"+i}
                                            className={this.state.formErrors['customFieldsKey'+i] ? "form-control input-error" : "form-control"}
                                            placeholder={"Field Name"}
                                            value={record.key} 
                                            unmask={true}                                            
                                            onAccept={
                                                (value, mask) => this.customFieldsKeyHandleTextChange(value,i)
                                            }
                                            readOnly={ record.readOnly && record.readOnly === true ? true : false }
                                        />
                                        <label htmlFor={"customFieldsKey"+i}>{"Field Name"}</label>
                                    </div>
                                </div>
                                <div className="col-5 mb-3 hide">
                                    <div className="form-floating">
                                        <input readOnly={ record.readOnly && record.readOnly === true ? true : false } className={this.state.formErrors['customFieldsValue'+i] ? "form-control input-error" : "form-control"} onChange={(e) => this.customFieldsValueHandleTextChange(e, i)} id={"customFieldsValue"+i} placeholder={"Value"} value={record.value} />
                                        <label htmlFor={"customFieldsValue"+i}>{"Value"}</label>
                                    </div>
                                </div>
                                <div className="col-2 text-center mb-3">
                                    { record.readOnly === true || record.readOnly === null ?
                                    <div className="category-icon mt-2">
                                         <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>This field can not be deleted</Tooltip>}
                                        >
                                         <button className="info-icon-btn" type="button">&nbsp;</button>
                                        </OverlayTrigger>
                                    </div>
                                    :
                                    record.parent === true ?
                                    <div className="category-icon mt-2">
                                         <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>This field cannot be deleted because it inherits the organization's custom fields.</Tooltip>}
                                        >
                                         <button className="info-icon-btn" type="button">&nbsp;</button>
                                        </OverlayTrigger>
                                    </div>
                                    :
                                    <div className="category-icon mt-2">
                                    <button className="remove-icon-btn" type="button" onClick={(e) => this.removeCustomField(i)}>&nbsp;</button>
                                    </div>
                                    }
                                </div>
                            </div>
                            ))
                            }

                            <div className="row mb-4">
                                <div className="col">
                                    <a onClick={(e) => this.addCustomField(e)} href="/">Add custom field</a>
                                </div>
                            </div>

                            <h6 className="sub-header mb-2">
                                Identifiers
                            </h6>
                            <p className="small-grey">
                                Identifiers let you decide what customer profile fields you wish to use to unique identify and associate customer records and payments 
                            </p>
                            <div className="mb-5">
                            {this.state.identifiers &&
                            <div className="row">
                                <div className="col-10">
                                <div className="row">
                                { this.state.identifiers.map((record, i) => (
                                    <div key={i} className="col-md-6 col-lg-4">
                                        <div className="icheck-primary">
                                        <input disabled={record?.parent ? record.parent : record?.disabled}  checked={record?.checked ? record.checked : false} type="checkbox" id={"customFieldIdentifier"+i} onChange={(e) => this.handleSelectIdentifier(e, record)} />
                                        <label className="small-small" htmlFor={"customFieldIdentifier"+i}>{record.key}</label>
                                        </div>
                                    </div>
                                ))}
                                </div>
                                </div>
                            </div>
                            }
                            </div>
                            <div className="row">
                            <div className="col-sm-12 col-md-5 mb-3">
                                <button className="btn full-w btn-light" type="button" onClick={() => this.openConfirmResetModal() }>Reset</button>
                            </div>
                            <div className="col-sm-12 col-md-5">
                                <button className="btn full-w btn-primary" type="button" onClick={() => this.saveCustomFields()}>Save</button>   
                            </div>
                            </div>
                        </div>

                        <div className="col-md-4" style={{maxWidth: "520px"}}>
                            

                        </div>

                       
                    </div>
                   
                </div>
            </div>
            <ToastContainer transition={Bounce} />
            </Layout>
        )
    }
}

export { CustomFields };