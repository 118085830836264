import React from "react";
import { inject, observer } from "mobx-react";
import { Accordion, Card, Tooltip } from "react-bootstrap";

import { BiChevronUp, BiChevronDown, BiPencil } from "react-icons/bi";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { ApplePayButtonType } from "../../../../../../src/components/ApplePayButtonType";
import { ApplePayButtonStyle } from "../../../../../../src/components/ApplePayButtonStyle";
import { ApplePayButtonLanguage } from "../../../../../components/ApplePayButtonLanguage";

@inject("store", "global", "vTerminal", "invoice")
@observer
class SectionAdvancedOptions extends React.Component {
  constructor(props) {
    super(props);

    this.refHeader = React.createRef();
    this.refDescription = React.createRef();

    this.state = {
      optionsOpen: false,
      default: {
        loaded: false,
        header: "",
        description: "",
        footer: "",
      },
      hasCards: false,
      hasEChecks: false,
      hasWallets: false,
      paylinkHeader: "",
      paylinkDescription: "",
      footerNote: "",
    };

    this.handleAccordionState = this.handleAccordionState.bind(this);
    this.handleCancelPopoverPaylink =
      this.handleCancelPopoverPaylink.bind(this);
    this.handleApplyPopoverPaylink = this.handleApplyPopoverPaylink.bind(this);
    this.handleCancelPopoverFooter = this.handleCancelPopoverFooter.bind(this);
    this.handleApplyPopoverFooter = this.handleApplyPopoverFooter.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.resetPopoverPayLink = this.resetPopoverPayLink.bind(this);
    this.resetPopoverFooter = this.resetPopoverFooter.bind(this);
    this.handlePaymentMethods = this.handlePaymentMethods.bind(this);
    this.handleCredrencialEntry = this.handleCredrencialEntry.bind(this);

    this.props.invoice.updateAdvancedOptions({
      paymentMethods: this.props.store.paymentPage.paymentMethods.methods,
      applePayButton: this.props.store.applePayButton,
    });
  }

  handleAccordionState() {
    this.setState({ optionsOpen: !this.state.optionsOpen });
  }

  handleChangeInput(e) {
    const { value, id } = e.target;
    this.setState({ [id]: value });
  }

  resetPopoverPayLink(show) {
    // onClose popover
    if (!show) {
      this.setState({
        paylinkHeader: this.state.default.header,
        paylinkDescription: this.state.default.description,
      });
    }
  }

  resetPopoverFooter(show) {
    // onClose popover
    if (!show) {
      this.setState({
        footerNote: this.state.default.footer,
      });
    }
  }

  handleCancelPopoverPaylink() {
    this.resetPopoverPayLink();
    document.body.click();
  }

  handleCancelPopoverFooter() {
    this.resetPopoverFooter();
    document.body.click();
  }

  handleApplyPopoverPaylink() {
    this.setState({
      default: {
        ...this.state.default,
        header: this.state.paylinkHeader,
        description: this.state.paylinkDescription,
      },
    });

    this.props.invoice.updateAdvancedOptions({
      paylinkHeader: this.state.paylinkHeader,
      paylinkDescription: this.state.paylinkDescription,
    });

    document.body.click();
  }

  handleApplyPopoverFooter() {
    this.setState({
      default: {
        ...this.state.default,
        footer: this.state.footerNote,
      },
    });

    this.props.invoice.updateAdvancedOptions({
      footerNote: this.state.footerNote,
    });

    document.body.click();
  }

  handlePaymentMethods(event) {
    const { id, checked } = event.target;
    this.props.store.handlePaymentMethods(event);
    this.props.invoice.updateAdvancedOptions({
      paymentMethods: {
        ...this.props.invoice.advancedOptions.paymentMethods,
        [id]: checked,
      },
    });
  }

  handleCredrencialEntry() {
    let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions,
      tempHasCards = this.state.hasCards,
      tempHasEChecks = this.state.hasEChecks,
      tempHasWallets = this.state.hasWallets;
    if (
      credentialsPermissions.card.onetime ||
      credentialsPermissions.card.recurring
    ) {
      tempHasCards = true;
    }
    if (credentialsPermissions.ach.recurring) {
      tempHasEChecks = true;
    }
    if (credentialsPermissions.wallet.onetime) {
      tempHasWallets = true;
    }
    this.setState({
      hasCards: tempHasCards,
      hasEChecks: tempHasEChecks,
      hasWallets: tempHasWallets,
    });
  }

  componentDidMount() {
    this.props.store.disableAutopay();
    this.props.vTerminal
      .getPaymentPageFromApi()
      .then((res) => {
        this.handleCredrencialEntry();
      })
      .catch((error) => {});
  }

  componentDidUpdate() {
    if (!this.state.default.loaded) {
      const { paylinkHeader, paylinkDescription, footerNote } =
        this.props.settings;

      this.setState({
        paylinkHeader,
        paylinkDescription,
        footerNote,
        default: {
          loaded: true,
          header: paylinkHeader,
          description: paylinkDescription,
          footer: footerNote,
        },
      });

      this.props.invoice.updateAdvancedOptions({
        paylinkHeader: paylinkHeader,
        paylinkDescription: paylinkDescription,
        footerNote,
      });
    }
  }

  render() {
    const PopoverEditPaymentMethods = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col">
              <h6>Payment Methods</h6>
            </div>
          </div>

          <div className="mb-2">
            <label className="header">Debit/Credit Cards</label>
            {!this.state.hasCards ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip>
                    {
                      "This service is not activated for this Paypoint. Please contact your administrator to enable."
                    }
                  </Tooltip>
                }
              >
                <div className="row">
                  <div className="col">
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasCards}
                        checked={this.state.hasCards}
                        onChange={this.handlePaymentMethods}
                        type="checkbox"
                        id="visa"
                      />
                      <label htmlFor="visa">Visa</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasCards}
                        checked={this.state.hasCards}
                        onChange={this.handlePaymentMethods}
                        type="checkbox"
                        id="mastercard"
                      />
                      <label htmlFor="mastercard">Mastercard</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasCards}
                        checked={this.state.hasCards}
                        onChange={this.handlePaymentMethods}
                        type="checkbox"
                        id="discover"
                      />
                      <label htmlFor="discover">Discover</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasCards}
                        checked={this.state.hasCards}
                        onChange={this.handlePaymentMethods}
                        type="checkbox"
                        id="amex"
                      />
                      <label htmlFor="amex">Amex</label>
                    </div>
                  </div>
                </div>
              </OverlayTrigger>
            ) : (
              <div className="row">
                <div className="col">
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods.visa
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="visa"
                    />
                    <label htmlFor="visa">Visa</label>
                  </div>
                </div>
                <div className="col">
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods
                          .mastercard
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="mastercard"
                    />
                    <label htmlFor="mastercard">Mastercard</label>
                  </div>
                </div>
                <div className="col">
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods
                          .discover
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="discover"
                    />
                    <label htmlFor="discover">Discover</label>
                  </div>
                </div>
                <div className="col">
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods.amex
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="amex"
                    />
                    <label htmlFor="amex">Amex</label>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mb-2">
            <label className="header">Bank</label>
            <div className="row">
              <div className="col">
                {!this.state.hasEChecks ? (
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip>
                        {
                          "This service is not activated for this Paypoint. Please contact your administrator to enable."
                        }
                      </Tooltip>
                    }
                  >
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasEChecks}
                        checked={this.state.hasEChecks}
                        onChange={this.handlePaymentMethods}
                        type="checkbox"
                        id="eCheck"
                      />
                      <label htmlFor="eCheck">E-Check / ACH</label>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods
                          .eCheck
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="eCheck"
                    />
                    <label htmlFor="eCheck">E-Check / ACH</label>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mb-2">
            <label className="header">Wallet</label>
            <div className="row">
              <div className="col">
                {!this.state.hasWallets ? (
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip>
                        {
                          "This service is not activated for this Paypoint. Please contact your administrator to enable."
                        }
                      </Tooltip>
                    }
                  >
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasWallets}
                        checked={false}
                        type="checkbox"
                        id="applePay"
                      />
                      <label htmlFor="applePay">Apple Pay</label>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div className="icheck-primary">
                    <input
                      disabled={
                        this.props.vTerminal.paymentPage.autopay.enabled
                      }
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods
                          .applePay &&
                        !this.props.vTerminal.paymentPage.autopay.enabled
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="applePay"
                    />
                    <label htmlFor="applePay">Apple Pay</label>
                  </div>
                )}
              </div>
              {/* <div className="col">
                <ApplePayButtonType />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ApplePayButtonStyle />
              </div>
              <div className="col">
                <ApplePayButtonLanguage />
              </div> */}
            </div>
          </div>

          <div className="mb-2">
            <label className="header">Coming Soon!</label>
            <div className="row">
              <div className="col">
                <div className="icheck-primary">
                  <input
                    disabled={true}
                    checked={
                      this.props.store.paymentPage.paymentMethods.methods
                        .googlePay
                    }
                    onChange={this.handlePaymentMethods}
                    type="checkbox"
                    id="googlePay"
                  />
                  <label htmlFor="googlePay">Google Pay</label>
                </div>
              </div>
              <div className="col">
                <div className="icheck-primary">
                  <input
                    disabled={true}
                    checked={
                      this.props.store.paymentPage.paymentMethods.methods.payPal
                    }
                    onChange={this.handlePaymentMethods}
                    type="checkbox"
                    id="payPal"
                  />
                  <label htmlFor="payPal">PayPal</label>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2">
            <label className="header">Crypto</label>
            <div className="row">
              <div className="col">
                <div className="icheck-primary">
                  <input
                    disabled={true}
                    checked={
                      this.props.store.paymentPage.paymentMethods.methods.bitPay
                    }
                    onChange={this.handlePaymentMethods}
                    type="checkbox"
                    id="bitPay"
                  />
                  <label htmlFor="bitPay">BitPay</label>
                </div>
              </div>
            </div>
          </div>

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const popoverPaymentLinkHeader = (
      <Popover className="popover-advanced-options">
        <Popover.Content>
          <div className="row mb-2">
            <h6>Payment link header</h6>
          </div>

          <div className="form-floating mb-3">
            <input
              className="form-control"
              onChange={this.handleChangeInput}
              placeholder="Header"
              id="paylinkHeader"
              value={this.state.paylinkHeader}
            />
            <label htmlFor="paylinkHeader">Header</label>
          </div>

          <div className="mb-3">
            <div className="form-floating">
              <input
                className="form-control"
                onChange={this.handleChangeInput}
                placeholder="Description"
                id="paylinkDescription"
                value={this.state.paylinkDescription}
              />
              <label htmlFor="paylinkDescription">Description</label>
            </div>
          </div>

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={this.handleCancelPopoverPaylink}
            >
              Cancel
            </button>
            <button
              className="btn btn-success"
              type="button"
              onClick={this.handleApplyPopoverPaylink}
            >
              Apply
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const popoverFooter = (
      <Popover className="popover-advanced-options">
        <Popover.Content>
          <div className="row mb-2">
            <h6>Footer</h6>
          </div>

          <div className="mb-3">
            <input
              className="form-control"
              onChange={this.handleChangeInput}
              placeholder="Footer"
              id="footerNote"
              value={this.state.footerNote}
            />
          </div>

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={this.handleCancelPopoverFooter}
            >
              Cancel
            </button>
            <button
              className="btn btn-success"
              type="button"
              onClick={this.handleApplyPopoverFooter}
            >
              Apply
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    return (
      <div className="section-form" id="advanced-options">
        <Accordion>
          <Card className="card card-in">
            <Accordion.Toggle
              as={Card.Header}
              className="card-body"
              eventKey="0"
              onClick={this.handleAccordionState}
            >
              <h6>
                Advanced options <small>(optional)</small>
                {this.state.optionsOpen && <BiChevronUp />}
                {!this.state.optionsOpen && <BiChevronDown />}
              </h6>
            </Accordion.Toggle>
          </Card>

          <Accordion.Collapse eventKey="0">
            <div className="options">
              <div className="option">
                <span className="label">Payment link header</span>
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="auto"
                  overlay={popoverPaymentLinkHeader}
                  onToggle={this.resetPopoverPayLink}
                >
                  <BiPencil />
                </OverlayTrigger>
              </div>
              <div className="option">
                <span className="label">
                  Select pay methods you want to offer on Payment Link
                </span>
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="auto"
                  overlay={PopoverEditPaymentMethods}
                  // onToggle={this.resetPopoverPayLink}
                >
                  <BiPencil />
                </OverlayTrigger>
              </div>
              <div className="option">
                <span className="label">Footer</span>
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="auto"
                  overlay={popoverFooter}
                  onToggle={this.resetPopoverFooter}
                >
                  <BiPencil />
                </OverlayTrigger>
              </div>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    );
  }
}

export default SectionAdvancedOptions;
