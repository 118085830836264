import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import 'rc-slider/assets/index.css';
import { RenderCustomFields } from '../../components/filters/RenderCustomFields';
import {BiArchive, BiBox, BiPauseCircle, BiDollarCircle, BiDollar} from 'react-icons/bi'
import {ComponentRangeAmount} from './ComponentRangeAmount.js'
import {ViewPartialTransfer} from './ViewPartialTransfer'

@inject('reports', 'filter', 'global')
@observer
class ViewPartialBatches extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="externalPaypointID" id="externalPaypointID" className={"form-control"} placeholder="External Paypoint ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('externalPaypointID(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('externalPaypointID(ct)', e)} value={this.props.filter.filterText.externalPaypointID ? this.props.filter.filterText.externalPaypointID : ''} />
                            <label htmlFor="externalPaypointID">External Paypoint ID</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="batchNumber" id="batchNumber" className={"form-control"} placeholder="Batch Number" onChange={(e) => this.props.filter.handleCaptureTextFilter('batchNumber(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('batchNumber(eq)', e)} value={this.props.filter.filterText.batchNumber ? this.props.filter.filterText.batchNumber : ''} data-qaid="inputBatchNumberFilter" />
                            <label htmlFor="batchNumber">Batch Number</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="batchClosedDateCalendar" 
                                        name="batchClosedDateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startBatchClosedDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startBatchClosedDate) + ' → ' : '') + (this.props.filter.stateDate.endBatchClosedDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endBatchClosedDate) : '')} 
                                        placeholder="Batch Closed Date" 
                                    />
                                    <label htmlFor="batchClosedDateCalendar">Batch Closed Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startBatchClosedDate ? this.props.filter.stateDate.startBatchClosedDate : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startBatchClosedDate', 'endBatchClosedDate', 'batchDate')}
                            startDate={this.props.filter.stateDate.startBatchClosedDate}
                            endDate={this.props.filter.stateDate.endBatchClosedDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endBatchClosedDate !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="expectedDepositDateCalendar" 
                                        name="expectedDepositDateCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startExpectedDepositDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startExpectedDepositDate) + ' → ' : '') + (this.props.filter.stateDate.endExpectedDepositDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endExpectedDepositDate) : '')} 
                                        placeholder="Expected Deposit Date" 
                                    />
                                    <label htmlFor="expectedDepositDateCalendar">Expected Deposit Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startExpectedDepositDate ? this.props.filter.stateDate.startExpectedDepositDate : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startExpectedDepositDate', 'endExpectedDepositDate', 'expectedDepositDate')}
                            startDate={this.props.filter.stateDate.startExpectedDepositDate}
                            endDate={this.props.filter.stateDate.endExpectedDepositDate}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endExpectedDepositDate !== null ? false : true}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="batchRecords" id="batchRecords" className={"form-control"} placeholder="# of Payments" onChange={(e) => this.props.filter.handleCaptureTextFilter('batchRecords(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('batchRecords(eq)', e)} value={this.props.filter.filterText.batchRecords ? this.props.filter.filterText.batchRecords : ''} />
                            <label htmlFor="batchRecords"># of Payments</label>
                        </div>
                    </div>
                </div>
                <div className='mb-2'>
                    <ComponentRangeAmount
                        id={'batchAmount'}
                        title={'Batch Total'}
                        minRange={0}
                        maxRange={1000}
                        minParameter={'minBatchTotal'}
                        maxParameter={'maxBatchTotal'}
                        filterParameter={'batchAmount'}
                    />
                    <div>
                        <p><small>Batch Status</small></p>
                        <div className='section-status mb-4'>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))} data-qaid="openStatusBatchMoneyInFilter"><BiArchive /> Open</span>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))} data-qaid="closedStatusBatchMoneyInFilter"><BiBox /> Closed</span>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 2, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]))} data-qaid="transferredStatusBatchMoneyInFilter"><BiDollar /> Transferred</span>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 3, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]))} data-qaid="fundedStatusBatchMoneyInFilter"><BiDollarCircle /> Funded</span>
                            <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-5]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', -5, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-5]))} data-qaid="heldStatusBatchMoneyInFilter"><BiPauseCircle /> Held</span>
                        </div>
                    </div>
                    <div>
                        <h5 className='title'>Payment Information</h5>
                        <p className='mb-1'><small>Payment Method</small></p>
                        <div className='row section-payment-information'>
                            <div className="col-2 mb-4" data-qaid="achPaymentMethodBatchMoneyInFilter">
                                <span className={this.props.filter.filterMethods.ach && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethodMo('ach', 'method(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.ach))}>{this.props.global.getPaymethodImg('ach')}</span>
                                <p>ACH</p>
                            </div>                            
                            <div className="col-2 mb-4" data-qaid="cardPaymentMethodBatchMoneyInFilter">
                                <span className={this.props.filter.filterMethods.card && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethodMo('card', 'method(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.card))}>{this.props.global.getPaymethodImg('card')}</span>
                                <p>CARD</p>
                            </div>
                            <div className="col-2 mb-4">
                                <span className={this.props.filter.filterMethods.combined && 'section-payment-information-active'} onClick={() => this.props.filter.handlePayMethodMo('combined', 'method(in)', !(Object.keys(this.props.filter.filterMethods).length > 0 && this.props.filter.filterMethods.combined))}>{this.props.global.getPaymethodImg('combined')}</span>
                                <p>MULTIPLE</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-2'>
                    <ViewPartialTransfer />
                </div>
                { Array.isArray(this.props.filter.customFields) && <RenderCustomFields /> }
            </>);
    }
}

export { ViewPartialBatches };