import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiChevronDown, BiEdit} from 'react-icons/bi';
import { Accordion, Card } from 'react-bootstrap';
import { BarChart } from '../components/BarChart';
import { EditCustomer } from '../components/EditCustomer';

@inject('reports', 'global', 'customer')
@observer
class CustomerDataChartColumn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            statistics: null,
            customerId: null,
            paymentYTD: 0
        };
        this.getCustomerStatistics = this.getCustomerStatistics.bind(this);
    }

    componentDidMount() {
        if(this.props.routeParams.id){
            let id =  this.props.routeParams.id;
            this.setState({ customerId: id }, function(){
                this.getCustomerStatistics();
            });
        }
       
    }

    getCustomerStatistics(){
        this.props.customer.getStatistics(this.state.customerId).then(res => {
            
        })
        .catch(error => {
            
        });
    }

    render() {
        let customerDataChart = this.props.customer.customerDataChart;
        let customFields = [];

        if(this.props.customerObj && this.props.customerObj.AdditionalFields){
            for (var [key, value] of Object.entries(this.props.customerObj.AdditionalFields)) {
                customFields.push(
                    <div key={key} className="row small mb-2">
                        <div className="col-4 grey">{key}</div>
                        <div className="col-8">{value}</div>
                    </div>
                );
            }
        }    

        return (
            <>
                {this.props.header &&
                <h5 className="header mb-3">{this.props.header}</h5>
                }
                <div className="mb-5">
                    <small className="grey">Total Payments this month</small>
                    <h2 style={{fontWeight: 400}}>${this.props.global.numberWithCommas(customerDataChart.lastMonthTotal.toFixed(2))}</h2>
                    <small>Payment History</small>
                    <BarChart/>
                </div>

                <h6 className="sub-header-line mb-1 dark-grey mb-4" data-qaid="accountInformationViewCustomer">
                    Account Information   
                    <EditCustomer isEmbedded={this.props.isEmbedded} className={" "} style={{fontSize: "18px", float: "right"}} customerToUpdate={this.props.customerObj} hasIconLabel={true} label={<BiEdit/>} action={"edit"}/>
                </h6>
                
                <div className="mb-2">Contact</div>
                <div className="row small mb-2">
                    <div className="col-4 grey">Company</div>
                    <div className="col-8">{this.props.customerObj ? this.props.customerObj.Company: '-'}</div>
                </div>
                <div className="row small mb-2">
                    <div className="col-4 grey">First Name</div>
                    <div className="col-8">{this.props.customerObj ? this.props.customerObj.Firstname: '-'}</div>
                </div>
                <div className="row small mb-2">
                    <div className="col-4 grey">Last Name</div>
                    <div className="col-8">{this.props.customerObj ? this.props.customerObj.Lastname: '-'}</div>
                </div>

                <div className="row small mb-2">
                    <div className="col-4 grey">Customer #</div>
                    <div className="col-8">{this.props.customerObj && this.props.customerObj.customerNumber ? this.props.customerObj.customerNumber.length > 15 ? this.props.customerObj.customerNumber.substring(0, 15) + "..." :  this.props.customerObj.customerNumber: '-'}</div>
                </div>

                <div className="row small mb-2">
                    <div className="col-4 grey">Phone</div>
                    <div className="col-8">{this.props.customerObj ? this.props.global.phoneNumberFormat(this.props.customerObj.Phone): '-'}</div>
                </div>
                <div className="row small mb-2">
                    <div className="col-4 grey">Email</div>
                    <div className="col-8">{this.props.customerObj ? this.props.customerObj.Email: '-'}</div>
                </div>
                <div className="row small mb-2">
                    <div className="col-4 grey">Created</div>
                    <div className="col-8">{this.props.customerObj && this.props.customerObj.Created ? this.props.global.stringDateFormat(this.props.customerObj.Created): "-"}</div>
                </div>
                <div className="row small mb-4">
                    <div className="col-4 grey">Status</div>
                    <div className="col-8">{this.props.customerObj ? this.props.reports.getCustomerStatus(this.props.customerObj.customerStatus): "-"}</div>
                </div>

               

                <Accordion defaultActiveKey="0">
                <Card className="card-no-border mb-3">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                        <BiChevronDown/> Billing Details 
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Address</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.Address  ? this.props.customerObj.Address : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Address 1</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.Address1  ? this.props.customerObj.Address1 : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">City</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.City ? this.props.customerObj.City: '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">State</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.State  ? this.props.customerObj.State: '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Zip</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.Zip ? this.props.customerObj.Zip: '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Country</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.Country ? this.props.customerObj.Country: '-'}</div>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                <Accordion defaultActiveKey="0">
                <Card className="card-no-border mb-3">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                        <BiChevronDown/> Shipping
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Address</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.ShippingAddress  ? this.props.customerObj.ShippingAddress : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Address 1</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.ShippingAddress1  ? this.props.customerObj.ShippingAddress1 : '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">City</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.ShippingCity ? this.props.customerObj.ShippingCity: '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">State</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.ShippingState  ? this.props.customerObj.ShippingState: '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Zip</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.ShippingZip ? this.props.customerObj.ShippingZip: '-'}</div>
                        </div>
                        <div className="row small mb-2">
                            <div className="col-4 grey">Country</div>
                            <div className="col-8">{this.props.customerObj && this.props.customerObj.ShippingCountry ? this.props.customerObj.ShippingCountry: '-'}</div>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                <Accordion defaultActiveKey="0">
                <Card className="card-no-border mb-3">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                    <BiChevronDown/> Additional Fields 
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        {customFields}
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                <Accordion defaultActiveKey="0">
                <Card className="card-no-border mb-3">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2">
                    <BiChevronDown/> Identifiers 
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        { (this.props.customerObj && this.props.customerObj.IdentifierFields) &&
                        this.props.customerObj.IdentifierFields.map((record, i) => (
                        <div key={i} className="row small mb-2">
                            <div className="col-4 grey">Identifier {i+1}</div>
                            <div className="col-8">{record}</div>
                        </div>
                        
                        ))
                        }
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

            </>
        )
    }
}

export { CustomerDataChartColumn };