import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiEdit, BiCheckCircle, BiLinkExternal, BiChevronLeft, BiInfoCircle } from "react-icons/bi";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import $ from 'jquery';
import 'jquery-ui-bundle';

import visa from '../../assets/images/visa.svg';
import calendar from '../../assets/images/calendar.svg';
import { PaymentMethodsAccepted } from '../../components/PaymentMethodsAccepted';
import { TopBar } from '../../components/TopBar';
import { PaymenPagePublishActions } from '../../components/PaymenPagePublishActions';
import { PaymenPageSections } from '../../components/PaymenPageSections';
import { PaymentPageName } from '../../components/PaymentPageName';
import { Layout } from '../../components/Layout'

import ReactDOM from 'react-dom';
import Switch from "react-switch";
import {Link} from "react-router-dom";
import { PaymentPageLogo } from '../../components/PaymentPageLogo';

import {Helmet} from "react-helmet";
import { toJS } from "mobx";

@inject('store','global')
@observer
class Receipt extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.sortableReceipt = React.createRef();
        this.handleTextChange = this.handleTextChange.bind(this);
        this.disableLogo = this.disableLogo.bind(this);
        this.disableMessageBeforeButton = this.disableMessageBeforeButton.bind(this);
        this.disableContactUs = this.disableContactUs.bind(this);
        this.handleReceiptSettings = this.handleReceiptSettings.bind(this);
        this.handleReceiptCustomFields = this.handleReceiptCustomFields.bind(this);
        this.handleReceiptSend = this.handleReceiptSend.bind(this);
        this.disableSettings = this.disableSettings.bind(this);
        this.disableTitle = this.disableTitle.bind(this);
    }

    prevent(e) {
        e.preventDefault();
    }    

    componentDidMount() {
        this.props.global.protect(this.props.history);
        
        var MainStoreObj = this.props.store;
        var sortableObj = $(ReactDOM.findDOMNode(this.sortableReceipt.current));
        sortableObj.sortable({
            stop:function(event,ui){
                var idsInOrder = sortableObj.sortable("toArray");
                MainStoreObj.updateReceiptBlocksOrder(idsInOrder);
            }
        });

        var subdomain = this.props.match.params.subdomain;
        if(subdomain){
            if(this.props.store.paymentPage.name === ''){
                this.props.history.push(`/${this.props.global.getURLEntry()}/paymentpages`);
            }
        }
       
    }
    sortFields(inputArr){
        let len = inputArr.length;
        for (let i = 0; i < len ; i++) {
            for (let j = 0; j < len - 1 ; j++) {
                if (inputArr[j][1].order > inputArr[j + 1][1].order) {
                    let tmp = inputArr[j];
                    inputArr[j] = inputArr[j + 1];
                    inputArr[j + 1] = tmp;
                }
            }
        }
        return inputArr;
    }
    handleTextChange(block, key ,event) {
        this.props.store.handleReceiptTextChange(block, key ,event);
    }

    disableLogo(checked){
        this.props.store.disableReceiptLogo(checked);
    }

    disableTitle(checked){
        this.props.store.disableReceiptTitle(checked);
    }

    disableMessageBeforeButton(checked){
        this.props.store.disableMessageBeforeButton(checked);
    }

    disableContactUs(checked){
        this.props.store.disableReceiptContactUs(checked);
    }
    
    disableSettings(checked){
        this.props.store.disableReceiptSettings(checked);
    }

    handleReceiptSettings(event) {
        this.props.store.handleReceiptSettings(event);
    }

    handleReceiptCustomFields(event) {
        this.props.store.handleReceiptCustomFields(event);
    }
    
    handleReceiptSend(event) {
        this.props.store.handleReceiptSend(event);
    }
    fieldsInPaymentPage(){
        let payorFields = this.props.store.paymentPage.payor.fields;
        let receiptFields = this.props.store.paymentReceipt.settings.fields || [];
        receiptFields.forEach(function (element, index) {
            element.index = index;
        });
        let resultFields = receiptFields.filter(a => payorFields.some(b => a.name === b.name && b.display === true));
        return resultFields;
    }

    render() {
        const btnTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };

        const linkTheme = {
            color: this.props.store.paymentPageSettings.color,
        };

        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };

        const PopoverEditTitleDescription = (
            <Popover>
              <Popover.Content>
                    <div className="row mb-2">
                        <div className="col"><h6>Page Header</h6></div>
                        <div className="col text-right">
                        <Switch
                            onChange={this.disableTitle}
                            checked={this.props.store.paymentReceipt.page.enabled}
                            onColor="#10A0E3"
                            onHandleColor="#ffffff"
                            handleDiameter={20}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={38}
                            className="react-switch"
                        />
                        </div>
                    </div>
                    <input name="header" value={this.props.store.paymentReceipt.page.header} onChange={(e) => this.handleTextChange("page","header", e)} type="text" className="form-control mb-3" placeholder="Header" />
                    <textarea rows="6"  name="description" value={this.props.store.paymentReceipt.page.description} onChange={(e) => this.handleTextChange("page","description", e)} className="form-control" placeholder="Description"></textarea>
              
                    <div className="popover-footer text-right">
                        <button className="btn btn-light btn-sm" type="button" onClick={() => document.body.click()}>Done</button>
                    </div>
              </Popover.Content>
            </Popover>
          );

          const PopoverEditSettings = (
            <Popover>
              <Popover.Content>
                    <div className="row mb-2">
                        <div className="col"><h6>Receipt Settings</h6></div>
                        <div className="col text-right">
                        <Switch
                            onChange={this.disableSettings}
                            checked={this.props.store.paymentReceipt.settings.enabled}
                            onColor="#10A0E3"
                            onHandleColor="#ffffff"
                            handleDiameter={20}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={38}
                            className="react-switch"
                        />
                        </div>
                    </div>

                    <div className="icheck-primary">
                    <input name="receiptSend" onChange={this.handleReceiptSend} defaultChecked={this.props.store.paymentReceipt.settings.sendAuto} type="radio"  id="sendAuto" />
                    <label htmlFor="sendAuto">Send Automated Receipts</label>
                    <label className="small small-grey">Receipt is sent immediately after payment.</label>
                    </div>


                    {/* Allows developers to control manual receipts */}
                    {/*<div className="icheck-primary">
                    <input name="receiptSend" onChange={this.handleReceiptSend} defaultChecked={this.props.store.paymentReceipt.settings.sendManual} type="radio"  id="sendManual" />
                    <label htmlFor="sendManual">Send Manual Receipts</label>
                    <label className="small small-grey">Manager your own receipt after payment.</label>
                    </div>*/}

                    

                    <label className="header">Input Fields to Display</label>
                    <div className="row mb-3">
                    
                    {this.fieldsInPaymentPage() && 
                           
                           this.fieldsInPaymentPage().map((block, i) => (
                            <div className="col-6" key={i}>
                                <div className="icheck-primary">
                                <input type="checkbox" defaultChecked={block.display} onChange={this.handleReceiptSettings} id={`settingField${block?.index}`} disabled={(block.Fixed && block.Fixed ===true) ? true : false } />
                                <label htmlFor={`settingField${block?.index}`}>{block.label || block.name}</label>
                                </div>
                            </div>
                        ))
                              
                    }
                    {/* {this.props.store.customFields && 
                           
                           this.props.store.customFields.map((block, i) => (
                               <div className="col-6" key={i}>
                                   <div className="icheck-primary">
                                   <input type="checkbox" defaultChecked={block.display} onChange={this.handleReceiptCustomFields} id={'customFields'+i} disabled={(block.Fixed && block.Fixed ===true) ? true : false } />
                                   <label htmlFor={'customFields'+i}>{block.name}</label>
                                   </div>
                               </div>
                           ))
                                 
                       } */}
                    </div>

                    { this.props.store.getSaveAction !== 'new' && this.props.store.getSaveAction !== 'fieldsChange' ?
                    <p className="small"><a target='_blank' rel='noreferrer' href={ "/" + this.props.global.getURLEntry() + "/previewreceipt/" + this.props.match.params.subdomain }>View Sample Receipt <BiLinkExternal/></a></p>
                    :
                    <p className="small m0"><BiInfoCircle style={{fontSize: "15px"}}/> Must save before publish or preview a Payment Page</p>     
                    }
                    <div className="popover-footer text-right">
                        <button className="btn btn-light btn-sm" type="button" onClick={() => document.body.click()}>Done</button>
                    </div>
              </Popover.Content>
            </Popover>
          );

          const PopoverEditLogo = (
            <Popover>
              <Popover.Content>
                    <div className="row mb-2">
                        <div className="col"><h6>Branding</h6></div>
                        <div className="col text-right">
                        <Switch
                            onChange={this.disableLogo}
                            checked={this.props.store.paymentReceipt.logo.enabled}
                            onColor="#10A0E3"
                            onHandleColor="#ffffff"
                            handleDiameter={20}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={38}
                            className="react-switch"
                        />
                        </div>
                    </div>

                    <p className="small">Add your logo through the <Link to={"/" + this.props.global.getURLEntry() + "/settings"}>Page Settings</Link> page to brand your organization. </p>

                    <div className="popover-footer text-right">
                        <button className="btn btn-light btn-sm" type="button" onClick={() => document.body.click()}>Done</button>
                    </div>
              </Popover.Content>
            </Popover>
          );

          const PopoverEditMessageBeforeButton = (
            <Popover>
              <Popover.Content>
                    <div className="row mb-2">
                        <div className="col"><h6>Message Before Action Button</h6></div>
                        <div className="col text-right">
                        <Switch
                            onChange={this.disableMessageBeforeButton}
                            checked={this.props.store.paymentReceipt.messageBeforeButton.enabled}
                            onColor="#10A0E3"
                            onHandleColor="#ffffff"
                            handleDiameter={20}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={38}
                            className="react-switch"
                        />
                        </div>
                    </div>

                    <label className="header">Add message before paying</label>
                    <p className="small small-grey">Ideas: Term & Conditions, settlement disclaimers, thank you note, promotional message. </p>

                    <textarea rows="5"  name="messageBeforeButton" value={this.props.store.paymentReceipt.messageBeforeButton.label} onChange={(e) => this.handleTextChange("messageBeforeButton","label", e)} className="form-control" placeholder="Enter messaging..."></textarea>
              
                    <div className="popover-footer text-right">
                        <button className="btn btn-light btn-sm" type="button" onClick={() => document.body.click()}>Done</button>
                    </div>
              </Popover.Content>
            </Popover>
          );

          const PopoverEditActionButton = (
            <Popover>
              <Popover.Content>
                    <div className="row mb-2">
                        <div className="col"><h6>Action Button</h6></div>
                        <div className="col text-right">
                        </div>
                    </div>
                    <label className="header">Edit Action Button Label</label>
                    <input name="payButton" value={this.props.store.paymentReceipt.paymentButton.label} onChange={(e) => this.handleTextChange("paymentButton","label", e)} type="text" className="form-control mb-3" placeholder="Pay" />
              
                    <div className="popover-footer text-right">
                        <button className="btn btn-light btn-sm" type="button" onClick={() => document.body.click()}>Done</button>
                    </div>
              </Popover.Content>
            </Popover>
          );

          const PopoverEditContact = (
            <Popover>
              <Popover.Content>
                    <div className="row mb-2">
                        <div className="col"><h6>Contact Us info</h6></div>
                        <div className="col text-right">
                        <Switch
                            onChange={this.disableContactUs}
                            checked={this.props.store.paymentReceipt.contactUs.enabled}
                            onColor="#10A0E3"
                            onHandleColor="#ffffff"
                            handleDiameter={20}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={38}
                            className="react-switch"
                        />
                        </div>
                    </div>
                    <p className="small">You can change the Contact Informtion on Payment Page Config. </p>

                    <div className="popover-footer text-right">
                        <button className="btn btn-light btn-sm" type="button" onClick={() => document.body.click()}>Done</button>
                    </div>
              </Popover.Content>
            </Popover>
          );

        return (
            <Layout {...this.props}>
            
            <div>
            <TopBar>
                <PaymenPagePublishActions history={this.props.history} />
                <div className="top-bar-sub">
                    <div className="row">
                        <div className="col-5">
                            <a href={"/" + this.props.global.getURLEntry() + "/paymentpages"} className="btn btn-light text-transform-normal"><BiChevronLeft/> Payment Pages</a>
                        </div>
                       
                        <div className="col-6 text-center hide">
                            {/*
                            <a href="/"><BiDollarCircle/> Charge Amounts</a>
                            <a href="/"><BiIdCard/> Payer Details</a>
                            <a href="/"><BiWallet/> Pay Methods</a>
                            <a href="/"><BiListCheck/> Confirm</a>
                            */}
                        </div>
                        <div className="col-7 text-right">
                            <PaymenPageSections selected="receipt" />
                        </div>
                    </div>
                </div>
            </TopBar>
            <div className="mt-body2">

            <div className="d-flex flex-column justify-content-center align-items-center">
                 <div className="card" style={{width: '50em', padding: "1rem 2rem", marginBottom: "40px"}}>
                 <PaymentPageName/>
                </div>
            </div>

            <div className="d-flex flex-column sjustify-content-center align-items-center">
                <div className="card" style={{width: '50em'}}>
                    <div className="card-body  main-cont">
                        <ul className="sortable" ref={this.sortableReceipt}>
                            <li className="ui-state-default dragable-panel" id="blocklogo" style={{paddingBottom: 0}}>
                                <OverlayTrigger rootClose key={0} trigger="click" placement="right" overlay={PopoverEditLogo}>
                                    <BiEdit className="icon-edit right" />
                                </OverlayTrigger>   
                                <div className={ !this.props.store.paymentReceipt.logo.enabled ? 'opacity-panel' : ''}>
                                    <div className="text-center">
                                    <PaymentPageLogo/>
                                    </div>
                                </div>
                            </li>
                            <li className="ui-state-default dragable-panel" id="blockpage" style={{paddingTop: 0}}>
                                <OverlayTrigger rootClose key={1} trigger="click" placement="right" overlay={PopoverEditTitleDescription}>
                                    <BiEdit className="icon-edit right" />
                                </OverlayTrigger>
                                <div className={ !this.props.store.paymentReceipt.page.enabled ? 'opacity-panel' : ''}>
                                    <div className="row">
                                        <div className="col-md-8 offset-md-2 progress-steps mb-3">
                                            <div className="row">
                                                <div style={bgTheme} className="col active">
                                                    &nbsp;
                                                </div>
                                                <div style={bgTheme} className="col active">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="card-title text-center">{this.props.store.paymentReceipt.page.header ? this.props.store.paymentReceipt.page.header : '[Page Header]'}</h3>
                                            <p className="small small-grey text-center m0" dangerouslySetInnerHTML={{__html: this.props.store.paymentReceipt.page.description ? this.props.store.paymentReceipt.page.description : '[Page Description]' }}></p>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="ui-state-default dragable-panel text-center" id="blockamount">
                                <div className="big-amount">$0.00</div>
                                <p><BiCheckCircle style={{fontSize: '28px', color: "rgb(136,201,82)"}}/> Approved Payment</p>
                            </li>

                            <li className="ui-state-default dragable-panel" id="blocksettings">
                                <OverlayTrigger rootClose key={4} trigger="click" placement="right" overlay={PopoverEditSettings}>
                                    <BiEdit className="icon-edit right"/>
                                </OverlayTrigger>
                                <div className={ !this.props.store.paymentReceipt.settings.enabled ? 'opacity-panel' : ''}>
                                {this.props.store.paymentPage.payor.fields && 
                                    <div className="review-total">
                                        <div className="row">
                                            {this.sortFields(Object.entries(toJS(this.fieldsInPaymentPage()))).map((block, i) => (                                           
                                                (block[1].display &&
                                                <div className={`col-sm-${block[1].width} mb-3 text-center text-muted`} key={i}>
                                                    {block[1].label || block[1].name}: {block[1].name} 
                                                </div>
                                                )
                                            ))}
                                        </div>
                                        {/* {this.props.store.customFields && 
                                                <div className="row">
                                                    {this.props.store.customFields.map((block, i) => (                                           
                                                        (block.display &&
                                                        <div className="col-4 mb-3" key={i}><b>{block.name}</b> <br/> {block.name} </div>
                                                        )
                                                    ))}
                                                </div>   
                                        } */}
                                    </div>      
                                }
                                
                                </div>
                            </li>

                            <li className="ui-state-default dragable-panel" id="blockdetails">
                                <div className={ !this.props.store.paymentReceipt.details.enabled ? 'opacity-panel' : ''}>
                                <div className="review-total">
                                    <div className="row">
                                    <div className="col">Payment Details:</div>
                                    <div className="col"></div>
                                    </div>
                                    <div className="row mt-2">
                                    <div className="col">Payment:</div>
                                    <div className="col">$0.00</div>
                                    </div>

                                    <div className="row mt-2">
                                    <div className="col">Fee:</div>
                                    <div className="col">$0.00</div>
                                    </div>

                                    <div className="top-border-grey mt-2">
                                    <div className="row total-amount">
                                        <div className="col">Total Amount:</div>
                                        <div className="col">$0.00</div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </li>

                            <li className="ui-state-default dragable-panel small" id="blockpaymentInformation">
                                <div className={ !this.props.store.paymentReceipt.paymentInformation.enabled ? 'opacity-panel' : ''}>
                                   <div className="mb-2"><b>Payment Information</b></div>
                                   <div className="row mb-1">
                                       <div className="col-2 text-center">
                                           <img className="payment-icon" alt="" src={visa}/>
                                       </div>
                                       <div className="col-10">My Credit Card •••• 7734</div>
                                   </div>
                                   <div className="bottom-border-grey">
                                   <div className="row">
                                       <div className="col-2 text-center">
                                            <img style={{width: '18px'}} alt="" src={calendar}/>
                                       </div>
                                       <div className="col-10">Paid on March 7th, 2021</div>
                                   </div>
                                   </div>

                                </div>
                            </li>

                            <li className="ui-state-default dragable-panel" id="blockmessageBeforeButton">
                                <OverlayTrigger rootClose key={4} trigger="click" placement="right" overlay={PopoverEditMessageBeforeButton}>
                                    <BiEdit className="icon-edit right"/>
                                </OverlayTrigger>
                                <div className={ !this.props.store.paymentReceipt.messageBeforeButton.enabled ? 'opacity-panel' : ''}>
                                    <p className="small small-grey" style={{margin:0}} dangerouslySetInnerHTML={{__html: this.props.store.paymentReceipt.messageBeforeButton.label ? this.props.store.paymentReceipt.messageBeforeButton.label : '[Message Before Button]' }}>
                                    </p>
                                </div>
                            </li>

                            <li className="ui-state-default dragable-panel" id="blockpaymentButton">
                                <OverlayTrigger rootClose key={5} trigger="click" placement="right" overlay={PopoverEditActionButton}>
                                    <BiEdit className="icon-edit right"/>
                                </OverlayTrigger>
                                <div className="btn-group full-w">
                                    <button type="button" style={btnTheme} onClick={this.testDataJson} className="btn btn-success btn-lg">{this.props.store.paymentReceipt.paymentButton.label}</button>
                                </div>
                            </li>

                            <li className="ui-state-default dragable-panel" id="blockcontactUs">
                                <OverlayTrigger rootClose key={7} trigger="click" placement="right" overlay={PopoverEditContact}>
                                    <BiEdit className="icon-edit right"/>
                                </OverlayTrigger>
                                <div className={ !this.props.store.paymentReceipt.contactUs.enabled ? 'opacity-panel' : ''}>
                                <p className="small-grey-m0 text-center ">
                                    <b>{this.props.store.paymentPage.contactUs.header ? this.props.store.paymentPage.contactUs.header : '[Contact Us Header]'}</b>
                                    <br/>
                                    {this.props.store.paymentPage.contactUs.emailLabel ? this.props.store.paymentPage.contactUs.emailLabel : '[email]'} | {this.props.store.paymentPage.contactUs.phoneLabel ? this.props.store.paymentPage.contactUs.phoneLabel : '[Phone Number]'}
                                </p>
                                </div>
                            </li>
                        
                        </ul>
                    </div>

                    { this.props.store.paymentPage.contactUs.paymentIcons && 
                    <div className="card-brands text-center bottom-grey mt-3">
                        <PaymentMethodsAccepted/>
                    </div>
                    }
                </div>

                <p className="small-small mt-5">Powered by  <a rel="noreferrer" style={linkTheme} target="_blank" href="https://www.payabli.com/" className="small no-underline">Payabli</a></p>
            </div>
            </div>
            </div>
            {(this.props.store && this.props.store.paymentPageSettings.customCssUrl) &&
            <Helmet>
            <link rel="stylesheet" href={this.props.store.paymentPageSettings.customCssUrl}/>
            </Helmet>
            }
            </Layout>
        );
    }
}

export { Receipt };