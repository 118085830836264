import React from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import { BiCheckCircle, BiMinusCircle, BiLockAlt } from 'react-icons/bi';

@inject('reports', 'filter')
@observer
class ViewPartialManageUsers extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Created On</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="createdOnCalendar" 
                                        name="createdOnCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateCreatedOn ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateCreatedOn) + ' → ' : '') + (this.props.filter.stateDate.endDateCreatedOn ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateCreatedOn) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="createdOnCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateCreatedOn ? this.props.filter.stateDate.startDateCreatedOn : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateCreatedOn', 'endDateCreatedOn', 'createdDate')}
                            startDate={this.props.filter.stateDate.startDateCreatedOn}
                            endDate={this.props.filter.stateDate.endDateCreatedOn}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateCreatedOn !== null ? false : true}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Last Login</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="lastLoginCalendar" 
                                        name="lastLoginCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateLastLogin ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateLastLogin) + ' → ' : '') + (this.props.filter.stateDate.endDateLastLogin ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateLastLogin) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="lastLoginCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateLastLogin ? this.props.filter.stateDate.startDateLastLogin : new Date() }
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateLastLogin', 'endDateLastLogin', 'lastAccessOn')}
                            startDate={this.props.filter.stateDate.startDateLastLogin}
                            endDate={this.props.filter.stateDate.endDateLastLogin}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateLastLogin !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>                    
                </div>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="userName" id="userName" className={"form-control"} placeholder="Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('name(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('name(ct)', e)} value={this.props.filter.filterText.userName ? this.props.filter.filterText.userName : ''} />
                            <label htmlFor="userName">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="userEmail" id="userEmail" className={"form-control"} placeholder="Email" onChange={(e) => this.props.filter.handleCaptureTextFilter('email(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('email(ct)', e)} value={this.props.filter.filterText.userEmail ? this.props.filter.filterText.userEmail : ''} />
                            <label htmlFor="userEmail">Email</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating input-number">
                            <input name="userPhone" id="userPhone" type='number' className={"form-control"} placeholder="Phone Number" onChange={(e) => this.props.filter.handleCaptureTextFilter('phone(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('phone(ct)', e)} value={this.props.filter.filterText.userPhone ? this.props.filter.filterText.userPhone : ''} />
                            <label htmlFor="userPhone">Phone Number</label>
                        </div>
                    </div>                    
                </div>                
                <div>
                    <p><small>Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}><BiCheckCircle /> Active</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-light" : "badge text-light"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiMinusCircle /> Inactive</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[85]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 85, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[85]))}><BiLockAlt /> Locked</span>
                    </div>
                </div>
            </>);
    }
}

export { ViewPartialManageUsers };