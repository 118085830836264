import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { RenderCurrency } from '../../components/filters/RenderCurrency';
import { RenderCustomerInformation } from '../../components/filters/RenderCustomerInformation';
import { RenderCustomFields } from '../../components/filters/RenderCustomFields';
import { RenderInvoiceNumber } from '../../components/filters/RenderInvoiceNumber';
import { RenderNetAmount } from '../../components/filters/RenderNetAmount';
import { RenderSource } from '../../components/filters/RenderSource';
import { RenderTransactionDate } from '../../components/filters/RenderTransactionDate';
import {BiArchive, BiLoader, BiBox, BiShare} from 'react-icons/bi';

@inject('reports', 'filter')
@observer
class ViewPartialChargebacks extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Chargeback Date</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="chargebackOnCalendar" 
                                        name="chargebackOnCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateChargeback ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateChargeback) + ' → ' : '') + (this.props.filter.stateDate.endDateChargeback ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateChargeback) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="chargebackOnCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateChargeback ? this.props.filter.stateDate.startDateChargeback : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateChargeback', 'endDateChargeback', 'chargebackDate')}
                            startDate={this.props.filter.stateDate.startDateChargeback}
                            endDate={this.props.filter.stateDate.endDateChargeback}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateChargeback !== null ? false : true}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <p><small>Date Logged</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="loggedOnCalendar" 
                                        name="loggedOnCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateLogged ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateLogged) + ' → ' : '') + (this.props.filter.stateDate.endDateNextLogged ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateNextLogged) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="loggedOnCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateLogged ? this.props.filter.stateDate.startDateLogged : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateLogged', 'endDateNextLogged', 'createdAt')}
                            startDate={this.props.filter.stateDate.startDateLogged}
                            endDate={this.props.filter.stateDate.endDateNextLogged}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateNextLogged !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>                                        
                </div>
                <div className='row'>
                    <p><small>Reply By</small></p>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="nextDraftOnCalendar" 
                                        name="nextDraftOnCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateReplyBy ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateReplyBy) + ' → ' : '') + (this.props.filter.stateDate.endDateReplyBy ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateReplyBy) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="nextDraftOnCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateReplyBy ? this.props.filter.stateDate.startDateReplyBy : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarStateReplyBy(date, 'startDateReplyBy', 'endDateReplyBy', 'createdAt')}
                            startDate={this.props.filter.stateDate.startDateReplyBy}
                            endDate={this.props.filter.stateDate.endDateReplyBy}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateReplyBy !== null ? false : true}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="caseNumber" id="caseNumber" className={"form-control"} placeholder="Case Number" onChange={(e) => this.props.filter.handleCaptureTextFilter('caseNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('caseNumber(ct)', e)} value={this.props.filter.filterText.caseNumber ? this.props.filter.filterText.caseNumber : ''} />
                            <label htmlFor="caseNumber">Case Number</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="reasonCode" id="reasonCode" className={"form-control"} placeholder="Reason Code" onChange={(e) => this.props.filter.handleCaptureTextFilter('reasonCode(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('reasonCode(eq)', e)} value={this.props.filter.filterText.reasonCode ? this.props.filter.filterText.reasonCode : ''} />
                            <label htmlFor="reasonCode">Reason Code</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="reason" id="reason" className={"form-control"} placeholder="Reason" onChange={(e) => this.props.filter.handleCaptureTextFilter('reason(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('reason(ct)', e)} value={this.props.filter.filterText.reason ? this.props.filter.filterText.reason : ''} data-qaid="inputReasonFilter"/>
                            <label htmlFor="reason">Reason</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="referenceNumber" id="referenceNumber" className={"form-control"} placeholder="Reference Number" onChange={(e) => this.props.filter.handleCaptureTextFilter('referenceNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('referenceNumber(ct)', e)} value={this.props.filter.filterText.referenceNumber ? this.props.filter.filterText.referenceNumber : ''} />
                            <label htmlFor="referenceNumber">Reference Number</label>
                        </div>
                    </div>
                </div>
                <div>
                    <p><small>Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiArchive /> Open</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}><BiLoader /> Pending</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 2, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]))}><BiBox /> Closed-Won</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', 3, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]))}><BiBox /> Closed-Lost</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('status(in)', 4, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[4]))}><BiShare /> ACH Returned</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleStatus('status(in)', 5, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]))}><BiShare /> ACH Chargeback</span>
                    </div>
                </div>
                <div className='mb-2'>
                    <hr className='mt-4 mb-4'></hr>
                    <h5 className='title'>Transaction Details</h5>
                    <RenderTransactionDate />
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <RenderInvoiceNumber />
                        </div>
                    </div>
                </div>
                <div>
                    <RenderNetAmount />
                </div>
                <div>
                    <h5 className='title'>Payment Information</h5>
                    <p className='mb-1'><small>Payment Method</small></p>
                    <div className='row mt-2'>
                        <div className='col-sm-6 mb-4'>
                            <div className="form-group mt-1" style={{fontSize: '20px', display: 'inline-flex'}}>
                                <div className="icheck-primary mr-3">
                                    <input type="radio" name="inlineRadioAch" id="inlineRadioAch" value="ach" checked={this.props.filter.filterRadioOption.inlineRadioAch ? true : false} onChange={(e) => this.props.filter.handleRadioOption(e, 'method(eq)')} />
                                    <label htmlFor="inlineRadioAch">ACH</label>
                                </div>
                                <div className="icheck-primary">
                                    <input type="radio" name="inlineRadioCreditCard" id="inlineRadioCreditCard" value="card" checked={this.props.filter.filterRadioOption.inlineRadioCreditCard ? true : false} onChange={(e) => this.props.filter.handleRadioOption(e, 'method(eq)')} />
                                    <label htmlFor="inlineRadioCreditCard">Credit Card</label>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 mb-4'>
                            <div className="form-floating">
                                <NumberFormat
                                    format={'####'}
                                    thousandsGroupStyle="thousand"
                                    decimalSeparator="."
                                    displayType="input"
                                    type="text"
                                    allowNegative={false}
                                    value={this.props.filter.filterText.lastFour ? this.props.filter.filterText.lastFour : ''}
                                    placeholder={"Last 4"}
                                    className="form-control"
                                    onValueChange={(values) => this.props.filter.handleCaptureTextFilterObject('payaccountLastfour(ct)', values, 'lastFour')}
                                    onKeyDown={(e) => this.props.filter.handleKeyDownObject('payaccountLastfour(ct)', e, 'lastFour')}
                                />
                                <label>"Last 4"</label>
                            </div>
                        </div>
                    </div>                     
                </div>
                <RenderCustomerInformation />
                <div>
                    <h5 className='title'>Processing Information</h5>                    
                    <RenderSource />
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <RenderCurrency />
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4 mt-4">
                            <div className="form-floating">
                                <input name="paymentId" id="paymentId" className={"form-control"} placeholder="Paypoint DBA" onChange={(e) => this.props.filter.handleCaptureTextFilter('transId(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('transId(ct)', e)} value={this.props.filter.filterText.paymentId ? this.props.filter.filterText.paymentId : ''} />
                                <label htmlFor="paymentId">Payment ID</label>
                            </div>
                        </div>
                    </div>                    
                </div>
                { Array.isArray(this.props.filter.customFields) && <RenderCustomFields /> }    
            </>);
    }
}

export { ViewPartialChargebacks };