import React from 'react';
import { inject, observer } from 'mobx-react';
import 'rc-slider/assets/index.css';
import { RenderCurrency } from '../../components/filters/RenderCurrency';
import { RenderCustomFields } from '../../components/filters/RenderCustomFields';
import { RenderPaymentInformation } from '../../components/filters/RenderPaymentInformation';
import { RenderSource } from '../../components/filters/RenderSource';
import {ComponentRangeAmount} from './ComponentRangeAmount.js'
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'filter')
@observer
class ViewPartialSettlements extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="batchNumber" id="batchNumber" className={"form-control"} placeholder="Batch Number" onChange={(e) => this.props.filter.handleCaptureTextFilter('batchNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('batchNumber(ct)', e)} value={this.props.filter.filterText.batchNumber ? this.props.filter.filterText.batchNumber : ''} />
                            <label htmlFor="batchNumber">Batch Number</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="externalPaypointID" id="externalPaypointID" className={"form-control"} placeholder="External Paypoint ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('externalPaypointID(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('externalPaypointID(ct)', e)} value={this.props.filter.filterText.externalPaypointID ? this.props.filter.filterText.externalPaypointID : ''} />
                            <label htmlFor="externalPaypointID">External Paypoint ID</label>
                        </div>
                    </div>
                </div>            
                <div className='mb-2'>
                    <ContainerShowMoreLess>
                        <hr className='mt-1 mb-4'></hr>
                        <ComponentRangeAmount
                            id={'settledAmount'}
                            title={'Total Amount'}
                            minRange={0}
                            maxRange={1000}
                            minParameter={'minSettledAmount'}
                            maxParameter={'maxSettledAmount'}
                            filterParameter={'settledAmount'}
                        />
                    </ContainerShowMoreLess>
                </div>                   
                <div>
                    <RenderPaymentInformation />
                </div>
                <div>
                    <h5 className='title'>Processing Information</h5>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="transactionId" id="transactionId" className={"form-control"} placeholder="Transaction ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('gatewayTransId(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('gatewayTransId(ct)', e)} value={this.props.filter.filterText.transactionId ? this.props.filter.filterText.transactionId : ''} />
                                <label htmlFor="transactionId">Transaction ID</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="paymentId" id="paymentId" className={"form-control"} placeholder="Payment ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('transId(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('transId(ct)', e)} value={this.props.filter.filterText.paymentId ? this.props.filter.filterText.paymentId : ''} data-qaid="inputPaymentIdFilter"/>
                                <label htmlFor="paymentId">Payment ID</label>
                            </div>
                        </div>
                    </div>
                </div>                            
                <RenderSource />
                <div className='row'>
                    <div className="col-sm-6 col-xs-12">
                        <RenderCurrency />
                    </div>
                </div>
                <div>
                    <ContainerShowMoreLess>
                        <hr className='mb-3 mt-2' style={{width: '95%'}}></hr>
                        <div className="col-sm-6 col-xs-12">
                            <p className='mb-1'><small>Cycle</small></p>
                            <div className="form-group mt-1" style={{fontSize: '20px', display: 'inline-flex'}}>
                                <div className="icheck-primary mr-3">
                                    <input type="radio" name="inlineRadioScheduled" id="inlineRadioScheduled" value="0" checked={this.props.filter.filterRadioOption.inlineRadioScheduled ? true : false} onChange={(e) => this.props.filter.handleRadioOptionCycle(e, 'scheduleId(ne)')} />
                                    <label htmlFor="inlineRadioScheduled">Scheduled</label>
                                </div>
                                <div className="icheck-primary">
                                    <input type="radio" name="inlineRadioOneTime" id="inlineRadioOneTime" value="0" checked={this.props.filter.filterRadioOption.inlineRadioOneTime ? true : false} onChange={(e) => this.props.filter.handleRadioOptionCycle(e, 'scheduleId(eq)')} />
                                    <label htmlFor="inlineRadioOneTime">One-Time</label>
                                </div>
                            </div>
                        </div>
                    </ContainerShowMoreLess>
                </div>
                { Array.isArray(this.props.filter.customFields) && <RenderCustomFields /> }
            </>);
    }
}

export { ViewPartialSettlements };