import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";
import { BiChevronLeft, BiDollarCircle, BiGrid, BiIdCard, BiFile, BiCheckSquare } from 'react-icons/bi';
import { PayabliStorageManager } from '../api/localStorageManager';

@inject('global')
@observer
class MoneyOutLinks extends React.Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack(e){
        if(e){
            e.preventDefault();
        }
        this.props.history.goBack();
    }
     
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];;

        return (
            <div className="row">
            <div className="col-2">
                <div className="hide-sm">
                {this.props.goback && 
                    <a href="/" onClick={(e)=>this.goBack(e)} className="btn btn-light text-transform-normal"><BiChevronLeft/> { this.props.gobackText ? this.props.gobackText: "Go back" }</a>
                }
                </div>
            </div>
            <div className="col-8 text-center">
                <div className="hide-sm">
                    {userPermissions.indexOf("MoneyOut Actions") !== -1  &&
                        <>
                            <Link className={this.props.selected === 'transactions'? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/transactions/out"} data-qaid="topBarMenuTransactionsOutLink"><BiDollarCircle/> Transactions</Link>
                            <Link className={this.props.selected === 'batches'? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/batches/out"} data-qaid="topBarMenuBatchesFundingOutLink"><BiGrid/> Batches & Funding</Link>
                            {userPermissions.indexOf("Vendors") !== -1  &&
                                <Link className={this.props.selected === 'vendors' ? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/vendors"} data-qaid="topBarMenuVendorLink"><BiIdCard/> Vendors</Link>
                            }
                            {userPermissions.indexOf("Bills") !== -1  &&
                                <Link className={this.props.selected === 'bills'? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/bills/"} data-qaid="topBarMenuBillsLink"><BiFile/> Bills</Link>
                            }  
                            {userPermissions.indexOf("Approvals") !== -1  &&
                                <Link className={this.props.selected === 'approvals'? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/approvals"} data-qaid="topBarMenuApprovalsLink"><BiCheckSquare/> Approvals</Link>
                            }
                        </>
                    }                     
                </div>
                <div className="hide show-sm sm-screen-topbar-menu">
                    {userPermissions.indexOf("MoneyOut Actions") !== -1  &&
                        <>
                            <Link className={this.props.selected === 'transactions'? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/transactions/out"}><BiDollarCircle/></Link>
                            <Link className={this.props.selected === 'batches'? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/batches/out"}><BiGrid/></Link>
                            {userPermissions.indexOf("Vendors") !== -1  &&
                                <Link className={this.props.selected === 'vendors' ? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/vendors"}><BiIdCard/></Link>
                            }
                            {userPermissions.indexOf("Bills") !== -1  &&
                                <Link className={this.props.selected === 'bills'? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/bills/"}><BiFile/></Link>
                            }  
                            {userPermissions.indexOf("Approvals") !== -1  &&
                                <Link className={this.props.selected === 'approvals'? 'selected' : ''} to={"/"+PayabliStorageManager.getEntryName()+"/report/approvals"}><BiCheckSquare/></Link>
                            }
                        </>
                    }                     
                </div>
            </div>
            <div className="col-2 text-right"></div>
        </div>
        )
    }
}

export { MoneyOutLinks };