import React from 'react';
import { Table, OverlayTrigger, Tooltip, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { BiInfoCircle, BiDotsVerticalRounded } from 'react-icons/bi';
import RSC from 'react-scrollbars-custom';
import { CustomTableReportPaginator } from './CustomTableReportPaginator';
import { inject } from 'mobx-react';
import { toJS } from 'mobx';
import { RecordsNotFound } from './RecordsNotFound';

export const renderDropdownActions = ({ ctable, actions, row, rowIndex }) => {
	return actions ? (
		<DropdownButton
			enualign='right'
			title={<BiDotsVerticalRounded />}
			id='actionsMenuButton'
			data-qaid={`${ctable?.typeReport}Actions`}
			size='sm'
			variant='default'>
			{actions.map((action, actionIndex) => (
				<Dropdown.Item
					key={actionIndex}
					data-qaid={`${rowIndex}-${action?.action}`}
					onClick={() => action?.action && ctable[action.action](rowIndex)}>
					{action.label}
				</Dropdown.Item>
			))}
		</DropdownButton>
	) : null;
};

const CustomTable = ({
	global,
	ctable,
	columns,
	visibleColumns,
	data,
	actions,
	rowAction,
	messageNotData,
	descriptionNotData
}) => {
	const executeRowAction = (e, rowIndex) => {
		if (
			e &&
			(global.isDrag() ||
				e.target.rel === 'noreferrer' ||
				e.target.tagName.toLowerCase() === 'svg' ||
				e.target.tagName.toLowerCase() === 'a' ||
				e.target.tagName.toLowerCase() === 'path')
		) {
			return;
		}

		if (e?.target?.id !== 'actionsMenuButton' && rowAction) {
			rowAction(rowIndex);
		}
	};

	return data ? (
		data?.length > 0 ? (
			<>
				<RSC className='table-responsive mb-3'>
					<Table
						striped
						hover>
						<thead>
							<tr>
								{columns?.map(
									(col, index) =>
										visibleColumns?.includes(col.key) && (
											<th
												key={index}
												className={`text-${col.alignment || 'left'} ${
													ctable.isEnabledBillingFees(col.key, 'transferDetails')
														? ' bg-primary'
														: ''
												}`}>
												{col.label}
												{col.tooltip && (
													<OverlayTrigger
														key={index}
														placement='top'
														overlay={<Tooltip>{col.tooltip}</Tooltip>}>
														<BiInfoCircle className='icon-info-text' />
													</OverlayTrigger>
												)}
											</th>
										)
								)}
								<th className='text-center sticky-row'>Actions</th>
							</tr>
						</thead>
						<tbody style={{ overflowY: 'auto' }}>
							{data?.map((row, rowIndex) => (
								<tr
									key={rowIndex}
									onMouseUp={(e) => global.setCoordClickUp(e)}
									onMouseDown={(e) => global.setCoordClickDown(e)}
									onClick={(e) => executeRowAction(e, rowIndex)}
									data-qaid={`batchTransferMoneyInRow-${rowIndex}`}>
									{columns?.map(
										(col, colIndex) =>
											visibleColumns?.includes(col.key) && (
												<td
													key={colIndex}
													className={`text-${col.alignment || 'left'}`}>
													{toJS(row[col.key])}
												</td>
											)
									)}
									<td
										className='text-center sticky-row'
										style={{ overflow: 'inherit' }}>
										{renderDropdownActions({ ctable, actions, row, rowIndex })}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</RSC>
				<CustomTableReportPaginator />
			</>
		) : (
			<RecordsNotFound
				message={messageNotData}
				description={descriptionNotData}
			/>
		)
	) : (
		<div className='records-not-found'>
			<Spinner
				animation='border'
				variant='light'
				role='status'
			/>
			<span
				className='mt-4 text-light'
				style={{ fontWeight: 'bold' }}>
				Loading...
			</span>
		</div>
	);
};

export default inject('global', 'ctable')(CustomTable);
