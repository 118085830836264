import { makeObservable , action } from "mobx";
import axios from 'axios';
import { PayabliStorageManager } from '../api/localStorageManager';
import globalStore from './GlobalStore';

class ChargebackStore {
    globalStore = null;
    constructor(globalStore) {
        this.globalStore = globalStore;
        makeObservable(this)
    }

    @action
    getChargebackFromApi(id){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'ChargeBacks/read/' + id,{
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }})
            .then(res => {
                return res.data;
            })
            .catch(error => {
                throw error;
            });
    }

    newResponse(chargeback){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.post(process.env.REACT_APP_URL_API+ 'ChargeBacks/response/'+chargeback.id,{
            "contactName": chargeback.contactName,
            "contactEmail": chargeback.contactEmail,
            "notes": chargeback.contactNotes,
            "attachments": chargeback.attachments
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

    acceptLiability(id){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.put(process.env.REACT_APP_URL_API+ 'ChargeBacks/response/' + id + "/3",{
           
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            
        })
        .catch(error => {
            throw error;
        });
    }

    setStatus(id, status){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.put(process.env.REACT_APP_URL_API+ 'ChargeBacks/response/' + id + "/" +status,{
           
        },
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            
        })
        .catch(error => {
            throw error;
        });
    }

    getChargebackLast4(record){
        
        if (record) {
            if ((record.PaymentData && record.PaymentData.MaskedAccount && record.PaymentData.MaskedAccount.toLowerCase() === "poi") ||
                (record.paymentData && record.paymentData.maskedAccount && record.paymentData.maskedAccount.toLowerCase() === "poi")
            ) {
                return (" - ");
            } else if ((record.Method && record.Method.toLowerCase() === "check") ||
                       (record.method && record.method.toLowerCase() === "check")
            ) {
                return (record.PaymentData.paymentDetails.checkNumber && this.globalStore.maskedCardNumber(record.PaymentData.paymentDetails.checkNumber, "v7")) || 
                       (record.paymentData.paymentDetails.checkNumber && this.globalStore.maskedCardNumber(record.paymentData.paymentDetails.checkNumber, "v7"));
            }else if (record.Method && record.Method.toLowerCase() === "cash") {
                return (" - ");
            }else{
                if(record.LastFour || record.lastFour){
                    return (this.globalStore.maskedCardNumber(record.LastFour, "v5")) ||
                           (this.globalStore.maskedCardNumber(record.lastFour, "v5"));
                }
                else{
                    return (this.globalStore.maskedCardNumber(record.PaymentData && record.PaymentData.MaskedAccount ? record.PaymentData.MaskedAccount : "", "v5")) ||
                           (this.globalStore.maskedCardNumber(record.paymentData && record.paymentData.maskedAccount ? record.paymentData.maskedAccount : "", "v5"));
                }
            }            
        }
        return (" - ");
    }


}

const chargebackStore = new ChargebackStore(globalStore);
export default chargebackStore;