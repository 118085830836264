import React from 'react';
import { BiX } from 'react-icons/bi';
import { inject, observer } from 'mobx-react';


@inject('global')
@observer
class RightPanel extends React.Component {

    constructor(props) {
        super(props);
        this.closePanel = this.closePanel.bind(this);
    }

    closePanel(){
        this.props.global.setRightPanelOpen(false);
    }

    render() {
        return (
            <>
            <div id="right-panel" className={ this.props.global.rightPanelOpen ? 'open' : ''}>
                <BiX id="right-panel-close" onClick={(e) => this.closePanel()}/>
                {this.props.children}
            </div>
            {this.props.global.rightPanelOpen &&
            <div onClick={(e) => this.closePanel()} id="right-panel-layer"></div>
            }
            </>
        );
    }
}

export { RightPanel };