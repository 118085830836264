import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiChevronLeft, BiDollarCircle, BiGrid, BiRepost, BiShare  } from "react-icons/bi";
import {Link} from "react-router-dom";
import { PayabliStorageManager } from '../api/localStorageManager'


@inject('reports', 'global')
@observer
class ReportsLinks extends React.Component {

    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
     }
     
     goBack(e){
        e.preventDefault();
        this.props.history.goBack();
     }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];;
        return (
            <div className="row">
            <div className="col-md-2">
                {this.props.goback && 
                    <a href="/" onClick={(e)=>this.goBack(e)} className="btn btn-light text-transform-normal"><BiChevronLeft/>{ this.props.gobackText ? this.props.gobackText: "Go back" }</a>
                }
                {this.props.gobackLink && 
                    <a href={this.props.gobackLink} className="btn btn-light text-transform-normal"><BiChevronLeft/> { this.props.gobackText ? this.props.gobackText: "Go back" }</a>
                }
            </div>
            <div className="col-md-8 text-center">
                <div className="hide-sm">
                    {userPermissions.indexOf("Transaction") !== -1 &&
                        <Link className={this.props.selected === 'transactions'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/report/transactions"} data-qaid="topBarMenuTransactionsLink"><BiDollarCircle/> Transactions</Link>
                    }
                    {userPermissions.indexOf("Subscriptions") !== -1 &&
                        <Link className={this.props.selected === 'autopays'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/report/autopays"} data-qaid="topBarMenuAutopaysLink"><BiRepost/> Autopays</Link>
                    }
                    {userPermissions.indexOf("Batch") !== -1 &&
                        <Link className={this.props.selected === 'batches'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/report/batches"} data-qaid="topBarMenuBatches&FundingLink"><BiGrid/> Batches & Funding</Link>
                    }
                    {userPermissions.indexOf("Chargeback") !== -1 &&
                        <Link className={this.props.selected === 'chargebacks'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/report/chargebacks"} data-qaid="topBarMenuACHReturns&ChargebacksLink"><BiShare/> ACH Returns & Chargebacks</Link>
                    }
                </div>
                <div className="hide show-sm sm-screen-topbar-menu">
                    {userPermissions.indexOf("Transaction") !== -1 &&
                        <Link className={this.props.selected === 'transactions'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/report/transactions"}><BiDollarCircle/></Link>
                    }
                    {userPermissions.indexOf("Subscriptions") !== -1 &&
                        <Link className={this.props.selected === 'autopays'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/report/autopays"}><BiRepost/></Link>
                    }
                    {userPermissions.indexOf("Batch") !== -1 &&
                        <Link className={this.props.selected === 'batches'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/report/batches"}><BiGrid/></Link>
                    }
                    {userPermissions.indexOf("Chargeback") !== -1 &&
                        <Link className={this.props.selected === 'chargebacks'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/report/chargebacks"}><BiShare/></Link>
                    }
                </div>  
            </div>
            <div className="col-md-2 text-right"></div>
        </div>
        )
    }
}

export { ReportsLinks };