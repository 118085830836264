import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../../components/Layout';
import { TopBar } from '../../../components/TopBar';
import { MainTopBarMenu } from '../../../components/MainTopBarMenu';
import { Link } from 'react-router-dom';
import { BiChevronDown, BiChevronLeft, BiCodeAlt, BiEdit, BiFile, BiInfoCircle, BiListUl, BiMessageSquareError } from 'react-icons/bi';
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../assets/css/codeJson.css';
import { VendorForm } from './form/VendorForm';
import ModalConfirmation from './ModalConfirmation';
import { withRouter } from 'react-router';
import { Dropdown, DropdownButton, Modal, Button } from 'react-bootstrap';
import { PayabliStorageManager } from '../../../api/localStorageManager';
import { SendPaymentLinkModal } from '../../../components/SendPaymentLinkModal';


@inject('store', 'global', 'entry', 'bill', 'vTerminal')
@observer
class BillBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {},
      billData: null, // for data loaded when a bill is Edit
      paylinkData: null, // for data loaded when a bill is Edit
      modalConfirmationOpen: false,
      previewShown: true,
      ocrCodeShown: false,
      infoModalIsOpen: false,
      isView: false,
      fileError: false,
      base64string: '',
      fileExtension: '',
      dataOCR: null,
      overwriteDataUsingOCRModalOpen: false,
      dataOCRRawData: '',
      showSendPaymentLinkModal: false,
      emails: '',
      emailsError: false,
    };

    this.handleToggleModal = this.handleToggleModal.bind(this);
    this.handleSaveBill = this.handleSaveBill.bind(this);
    this.handleTogglePreview = this.handleTogglePreview.bind(this);
    this.openInfoModal = this.openInfoModal.bind(this);
    this.closeInfoModal = this.closeInfoModal.bind(this);
    this.cancel = this.cancel.bind(this);
    this.redirectToBillsReport = this.redirectToBillsReport.bind(this);
    this.readDocument = this.readDocument.bind(this);
    this.handleFileRead = this.handleFileRead.bind(this);
    this.showHideOcrCode = this.showHideOcrCode.bind(this);
    this.closeAcceptOCRData = this.closeAcceptOCRData.bind(this);
    this.openAcceptOCRData = this.openAcceptOCRData.bind(this);
    this.overwriteFormDataOCR = this.overwriteFormDataOCR.bind(this);
    this.closeSendPaymentLinkModal = this.closeSendPaymentLinkModal.bind(this);
    this.openSendPaymentLinkModal = this.openSendPaymentLinkModal.bind(this);
    this.getMainActionButtons = this.getMainActionButtons.bind(this);
    this.getSendPaymentActionButton = this.getSendPaymentActionButton.bind(this);
    this.inputFileInvoice = React.createRef();
  }

  componentDidMount() {
    this.setState({isView: this.props.match.path === "/:entryUrl/bills/view/:idBill"});

    this.props.global.setLoading(true);
    this.loadBillData().finally(() => {
      this.props.global.setLoading(false);
    });

    if(this.props.match.params.vendorNumber){
        this.loadVendorData(this.props.match.params.vendorNumber);
    }
  }

  closeSendPaymentLinkModal(){
    this.setState({showSendPaymentLinkModal:false});
  }

  openSendPaymentLinkModal(){
    this.setState({showSendPaymentLinkModal:true});
  }

  closeAcceptOCRData(){
    this.setState({overwriteDataUsingOCRModalOpen: false});
  }
  
  openAcceptOCRData(){
    this.setState({overwriteDataUsingOCRModalOpen: true});
  }

  overwriteFormDataOCR(){
    this.closeAcceptOCRData();
    let dataOCR = this.state.dataOCR;

    if(dataOCR.rawData){
      this.setState({
        dataOCRRawData: dataOCR.rawData ? JSON.stringify(dataOCR.rawData,null, 4) : ''
      });
      this.props.bill.setBillDetails({JSON: dataOCR.rawData ? JSON.stringify(dataOCR.rawData,null, 4) : null });
    }

    if(dataOCR.resultData){
      this.props.bill.setBillDetails({
        billNumber: dataOCR.resultData.billNumber ? dataOCR.resultData.billNumber :  this.props.bill.billDetails.billNumber,
        dueDate: dataOCR.resultData.dueDate ? new Date(dataOCR.resultData.dueDate) :  this.props.bill.billDetails.dueDate,
        term: dataOCR.resultData.terms ? dataOCR.resultData.terms :  this.props.bill.billDetails.term,
        scheduleCheck: (dataOCR.resultData.frequency && dataOCR.resultData.frequency.toLowerCase() !== "onetime")? true :  false,
        startDate: dataOCR.resultData.billDate ? new Date(dataOCR.resultData.billDate) :  this.props.bill.billDetails.startDate,
        frequency: dataOCR.resultData.frequency ? dataOCR.resultData.frequency :  this.props.bill.billDetails.frequency,
        endDate: dataOCR.resultData.endDate ? new Date(dataOCR.resultData.endDate) :  this.props.bill.billDetails.endDate
      });
      
      this.props.bill.updateAdvancedOptions({ memoNote: dataOCR.resultData.comments ? dataOCR.resultData.comments :  this.props.bill.advancedOptions.memoNote });
      
      let items = [];
      if(dataOCR.resultData.billItems){
        dataOCR.resultData.billItems.forEach(function (item, index) {
          items.push({
             label: item.itemProductName,
             description: item.itemDescription,
             value: item.itemCost,
             quantity: item.itemQty
          })
        });

        this.props.bill.setItems(items);
        this.props.bill.calculatePay();
      }

      if(dataOCR.resultData.attachments){
        this.props.bill.setAttachments(dataOCR.resultData.attachments);
      }
      
      if(dataOCR.resultData.netAmount){
        this.props.bill.setBillDetails({totalAmount: dataOCR.resultData.netAmount});
      }

      if(dataOCR.resultData.vendor && !this.props.bill.hasVendorSelected ){
        this.props.bill.setVendorOCR(dataOCR.resultData.vendor);
      }
    }
  }

  getMainActionButtons(){
    const btnActionDisabled = !this.props.bill.isBtnActionEnabled;
    return (<>{ this.props.bill.billActionSelected.action === "askVendor" &&
                      <span className='bottom-btn'>
                        <button 
                          className="btn btn-success btn-action-bill-builder"
                          type="button" 
                          onClick={this.openSendPaymentLinkModal}
                          disabled={btnActionDisabled}
                          >
                            Send Payment Link
                        </button>

                        {!this.state.isView &&
                          <button 
                          className="btn btn-success btn-action-bill-builder"
                          type="button"
                          onClick={this.handleSaveBill}
                          disabled={btnActionDisabled}
                          >
                            Save Bill
                          </button>
                        }
                      </span>
                      
              }</>)
  }

  getSendPaymentActionButton(){
    const btnActionDisabled = !this.props.bill.isBtnActionEnabled;
    return (!this.state.isView &&
              <>
              {
              this.props.bill.billActionSelected.action === "howVendorGetsPaid" &&
              <span className='bottom-btn'>
                <button 
                className="btn btn-success btn-action-bill-builder singleButton"
                type="button" 
                onClick={this.handleSaveBill}
                disabled={btnActionDisabled}
                >
                  Send Payment
                </button>
              </span>
              }
              </> 
            )
  }

  handleFileRead(e){
    let file = e.target.files[0];
    if(file){
      
        let fileExtension = this.props.global.getFileExtension(file.type);
        if( (fileExtension === "pdf" || fileExtension === "png" || fileExtension === "jpeg") && file.size <= 10000000){
            this.props.global.setLoading(true);
            this.props.global.readFileBase64(file).then(base64 => {
                let base64string = base64.split(',');
                if(base64string[0] &&  base64string[1]){
                  this.props.bill.processDocument(fileExtension, "ocrDocument."+fileExtension,  base64string[1]).then(res => {
                      this.setState({dataOCR: res}, function(){
                        this.openAcceptOCRData();
                      })

                      if(res.rawData){
                        this.setState({
                          dataOCRRawData: res.rawData ? JSON.stringify(res.rawData,null, 4) : ''
                        });
                      }

                      this.props.global.setLoading(false);
                  })
                  .catch(error => {
                      this.props.global.setLoading(false);
                      let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                      toast.error(errorMessage, {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          className: 'toast-error-container',
                      });
                  });
                }
                
                this.setState({fileError: false, base64string : base64string[1], fileExtension: fileExtension });
            })
            .catch(error => {
              this.props.global.setLoading(false);
              toast.error(error.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'toast-error-container',
              });
            });
        }
        else{
            this.setState({fileError: true, ocrCodeShown: false, base64string : '', fileExtension: '', dataOCR: null});
        }
        
    }
    
}

  async loadBillData() {
    this.props.bill.reset();
    this.props.global.setLoading(true);

    const billId = this.props.match.params.idBill;
    // load bill data
    if (billId) {
      this.props.bill.updateStatusBill('edit');
      try{

        const data = await this.props.bill.loadBill(billId);
        this.setState({ billData: data });

        if(data.DocumentsRef && data.DocumentsRef.filelist && data.DocumentsRef.filelist[0] && data.DocumentsRef.filelist[0].zipName){
          let zipName = data.DocumentsRef && data.DocumentsRef.filelist && data.DocumentsRef.filelist[0] && data.DocumentsRef.filelist[0].zipName;
          this.props.bill.getBillAttachments(billId,zipName).then(res => {
              if(res.ftype && res.fContent){
                this.setState({fileExtension: (res.ftype.replace(".", "")), base64string: res.fContent}, function(){

                })
              }
          })
          .catch(error => {
              let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
              toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
          });
        }

      }catch(e){
        toast.error(e.message ? "Problem loading the Bill, "+e.message : "Problem loading the Bill, something is wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      }

    } else {
      this.setState({ billData: null });
    }
  }

  async loadVendorData(value) {
    this.props.global.setLoading(true);
    this.props.vTerminal
    .searchingVendorNumber(value)
    .then((res) => {
      if(this.props.vTerminal.vendors[0]){
        this.props.bill.setVendorSelected(this.props.vTerminal.vendors[0]);
        this.props.vTerminal.selectVendor(0);
        this.props.vTerminal.clearVendor();
        this.props.global.setLoading(false);
      }
    })
    .catch((error) => {
        this.props.global.setLoading(false);
        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
        toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
        });
    });
  }

  openInfoModal(info){
    this.setState({ infoModalIsOpen: true, infoMessageInModal: info });
  }

  closeInfoModal(){
    this.setState({ infoModalIsOpen: false });
  }
  
  showHideOcrCode(){
    this.setState({ ocrCodeShown: !this.state.ocrCodeShown });
  }

  handleToggleModal() {
    if(!this.props.store.hasCards && !this.props.store.hasECheck){
      this.openInfoModal("You must select at least one payment method");
    }else{
      this.setState({ modalConfirmationOpen: !this.state.modalConfirmationOpen });
    }
  }

  handleTogglePreview() {
    this.setState({ previewShown: !this.state.previewShown });
  }

  cancel(){
    this.props.history.push("/"+PayabliStorageManager.getEntryName()+"/report/bills");
  }

  redirectToBillsReport() {
    // clean stores
    this.props.vTerminal.cleanCategories();
    this.props.bill.reset();

    this.props.history.push({
      pathname: "/"+PayabliStorageManager.getEntryName()+"/report/bills"
    });
  }

  async handleSaveBill(noRedirect) {
    try {
      this.props.global.setLoading(true);
      let saveBillResponse = null;

      if (this.props.bill.status === 'new') {
        saveBillResponse = await this.props.bill.saveBill();
        
        if(saveBillResponse && this.props.bill.billActionSelected.action === "howVendorGetsPaid"){
          let billToPay = {};
          billToPay.IdBill = saveBillResponse;
          billToPay.Vendor = {};
          billToPay.Vendor.VendorId = this.props.bill.getVendorSelected.VendorId;
          await this.props.bill.schedulePayment(billToPay,this.props.vTerminal.getPaymentMethod).then(res => {})
          .catch(error => {
              throw error;
          });
        } 
      } else {
        await this.props.bill.updateBill();
      }

      if(noRedirect !== true){
        this.redirectToBillsReport();
        this.props.global.setLoading(false);
        toast.success('Data saved successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success-container',
        });
      }else{
        return saveBillResponse;
      }
     
      
    } catch (e) {
      if (e.response) {
        this.props.global.setLoading(false);
        let responseText = e.response.data.responseText;

        if(e.response.data.responseData && e.response.data.responseData.todoAction && e.response.data.responseData.explanation){
          responseText = e.response.data.responseData.explanation + ". "+ e.response.data.responseData.todoAction;
        }

        return toast.error(responseText, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      }

      this.props.global.setLoading(false);
      toast.error(e.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
      });
    }
  }

  readDocument(){
    this.inputFileInvoice.current.click();
  }


  render() {
    const btnActionDisabled = !this.props.bill.isBtnActionEnabled;
    const pay = this.props.bill.pay;

    let classesForm = this.state.previewShown ? 'col-md-5 full-1260' : 'col-md-6';
    let classesPreview = 'col-md-7 full-1260';
    if (!this.state.previewShown) classesPreview += ' hide';
    const btnPreviewText = this.state.previewShown ? 'Hide Preview' : 'Show Preview';

    const queryParameters = new URLSearchParams(window.location.search);
    const backTo = queryParameters.get("backTo");

    return (
      <Layout {...this.props}>
        
        <Modal style={{textAlign: "center"}} show={this.state.overwriteDataUsingOCRModalOpen} onHide={this.closeAcceptOCRData}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <BiEdit className="icon-modal"/>
                <h5>Fill Form</h5>
                <p className="small">Would you like us to populate the form with data captured from the imaged bill?</p>
                <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeAcceptOCRData()}>
                    Cancel
                </Button>
                &nbsp;&nbsp;
                <Button className="btn btn-primary" onClick={(e) => this.overwriteFormDataOCR()}>
                    Accept
                </Button>
            </Modal.Body>
        </Modal>

        <SendPaymentLinkModal
          vendorName={
            ((this.props.vTerminal.getVendorSelected && this.props.vTerminal.getVendorSelected.Name1) ? 
              this.props.vTerminal.getVendorSelected.Name1 : 
            (this.props.vTerminal.getVendorSelected && this.props.vTerminal.getVendorSelected.name1) ?
              this.props.vTerminal.getVendorSelected.name1 : ''
            )
            +
            ((this.props.vTerminal.getVendorSelected && this.props.vTerminal.getVendorSelected.Name2) ? 
              this.props.vTerminal.getVendorSelected.Name2 : 
            (this.props.vTerminal.getVendorSelected && this.props.vTerminal.getVendorSelected.name2) ?
              this.props.vTerminal.getVendorSelected.name2 : ''
            )
            
          }
          amountOf={this.props.bill.items.length > 0 ? pay.subtotal : this.props.bill.billDetails.totalAmount}
          emailOnFile={this.props.vTerminal.getVendorSelected?.email || this.props.vTerminal.getVendorSelected?.Email}
          show={this.state.showSendPaymentLinkModal} 
          onHide={this.closeSendPaymentLinkModal}
          billToPay = {this.state.billData?this.state.billData:null}
          closeSendPaymentLinkModal={this.closeSendPaymentLinkModal}
          functionCallBack={this.redirectToBillsReport}
          secondFunctionCallBack={this.props.secondFunctionCallBack}
          handleSaveBill={this.handleSaveBill}
        />

        <div id="invoicesBuilder">
          <TopBar>
            <MainTopBarMenu />
            <div className="top-bar-sub">
              <div className="row datatable-actions">
                <div className="col-5" style={{ alignSelf: 'center' }}>
                  {backTo ?

                      <>

                      {backTo === "approvals" && 
                        <Link to={"/"+PayabliStorageManager.getEntryName()+"/report/approvals"} className="btn btn-light text-transform-normal">
                        <BiChevronLeft /> Go back to approvals list
                        </Link>
                      }
                      
                      </>
                  :
                    <>
                    {this.props.match.params.vendorNumber ?
                    <Link to={"/"+PayabliStorageManager.getEntryName()+"/report/vendors"} className="btn btn-light text-transform-normal">
                      <BiChevronLeft /> Go back to vendor list
                    </Link>
                    :
                    <Link to={"/"+PayabliStorageManager.getEntryName()+"/report/bills"} className="btn btn-light text-transform-normal">
                      <BiChevronLeft /> Go back to bill list
                    </Link>
                    }
                    </>
                  }

                  
                </div>
                <div className="col-7 text-right">
                      <div className="d-none show-md">
                            {this.getMainActionButtons()}
                            {this.getSendPaymentActionButton()}
                            <DropdownButton
                                menuAlign="right"
                                title={<div><BiListUl/> Actions</div>}
                                size="sm"
                                variant="default"
                                className='DropdownButtonV2'
                            >
                                {(this.state.isView && this.state.billData && this.state.billData.Status === 1) && 
                                <Dropdown.Item>
                                  <a href={"/"+this.props.global.getURLEntry()+"/bills/edit/"+this.props.match.params.idBill}>
                                    Edit Bill
                                  </a> 
                                  </Dropdown.Item>
                                }
                                <Dropdown.Item onClick={this.handleTogglePreview}>{btnPreviewText}</Dropdown.Item>
                 
                                {((!this.state.isView && this.state.billData && this.state.billData.Status < 50 ) || !this.state.billData) &&
                                  <Dropdown.Item onClick={()=>this.readDocument()}>Upload File</Dropdown.Item>
                                }
                                <Dropdown.Item onClick={this.cancel}>Cancel</Dropdown.Item>
                                {(!this.state.isView && this.state.billData && this.state.billData.Status < 50) && 
                                  <Dropdown.Item onClick={this.handleSaveBill}>Save Bill</Dropdown.Item>
                                }
                
                          
                            </DropdownButton>
                            
                        </div>

                        <div className="hide-md">
                            {(this.state.isView && this.state.billData && this.state.billData.Status === 1) && 
                            <a href={"/"+this.props.global.getURLEntry()+"/bills/edit/"+this.props.match.params.idBill}>
                                <button className="btn btn-light" type="button">
                                  Edit Bill
                                </button>
                            </a> 
                            }

                            
                            <button className="btn btn-light" type="button" onClick={this.handleTogglePreview}>
                              {btnPreviewText}
                            </button>
                            
                            {((!this.state.isView && this.state.billData && this.state.billData.Status < 50 ) || !this.state.billData) && 
                            <button 
                            className="btn btn-light" 
                            type="button" 
                            onClick={()=>this.readDocument()}
                            >
                              Upload File
                            </button>
                            }
                   

                            <button 
                            className="btn btn-light" 
                            type="button" 
                            onClick={this.cancel}
                            >
                              Cancel
                            </button>

                            {this.getMainActionButtons()}
                            {this.getSendPaymentActionButton()}

                        </div>


                </div>
              </div>
            </div>
          </TopBar>

          <div className = { this.state.isView ? "body-builder disabled-form" : "body-builder"}>
            <div className="row justify-content-center">
              <div className={classesForm}>
                <div id="wrapper-invoice-form">
                  <VendorForm params={this.props.match.params} vendorOCR={(this.state.dataOCR && this.state.dataOCR.vendor) ? this.state.dataOCR.vendor : null} isView={this.state.isView} billDataLoaded={this.state.billData} settings={this.state.settings} />
                </div>
              </div>

              <div className={classesPreview}>
                <div id="wrapper-bill-preview" className={ this.state.ocrCodeShown ? "codeBg": ""}>
                  <input className="hide" ref={this.inputFileInvoice} type="file" onChange={(e) => this.handleFileRead(e)} />
                  
                  {!this.state.ocrCodeShown ? 
                  <>
                      { (this.state.fileExtension === 'pdf' && this.state.base64string !== '') &&
                      <iframe style={{width: "100%", height: "100%"}} src={"data:application/pdf;base64,"+this.state.base64string}></iframe>
                      }

                      { (this.state.fileExtension === 'png' && this.state.base64string !== '') &&
                      <img alt="" style={{width: "100%"}} src={"data:application/png;base64,"+this.state.base64string} />
                      }

                      { (this.state.fileExtension === 'jpeg' && this.state.base64string !== '') &&
                      <img alt="" style={{width: "100%"}} src={"data:application/jpeg;base64,"+this.state.base64string} />
                      }
                      </>
                  :
                  <div>
                    <pre>
                    <code className="language-json">
                      {
                        this.state.dataOCRRawData ?
                          this.state.dataOCRRawData
                        :
                        this.state.billData && this.state.billData.AdditionalData && this.state.billData.AdditionalData.JSON ?
                          this.state.billData.AdditionalData.JSON : "JSON code is not available."
                      }
                    </code>
                  </pre>
                      
                  </div>
                  }

                  {this.state.base64string === '' &&
                  <div className="addInvoice" onClick={()=>this.readDocument()} >
                    <h5>Bill Image</h5>
                    { this.state.fileError &&
                    <span className="error small text-danger"><BiInfoCircle style={{fontSize: "18px"}}/> Error uploading a file</span>
                    }
                    <div></div>
                    Click to upload a file<br/>
                    pdf, png, jpeg, Max 10MB
                   
                  </div>
                  }
                  
                  
                  {this.state.base64string !== '' &&
                  <div className="buttonOnPreview" onClick={()=>this.showHideOcrCode()}>{this.state.ocrCodeShown ? <BiFile/>:<BiCodeAlt/>}</div>
                  }
                </div>
              </div>
            </div>
          </div>

          <ModalConfirmation open={this.state.modalConfirmationOpen} close={this.handleToggleModal} />
          
          <Modal style={{textAlign: "center"}} show={this.state.infoModalIsOpen} onHide={this.closeInfoModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>
              <BiMessageSquareError className="icon-modal"/>
              <h5>Info</h5>
              <p className="small">{this.state.infoMessageInModal}</p>
              <Button className="btn" onClick={this.closeInfoModal}>
              Close
              </Button>
          </Modal.Body>
          </Modal>

          <ToastContainer transition={Bounce} />
        </div>
      </Layout>
    );
  }
}

export default withRouter(BillBuilder);
