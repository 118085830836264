import React from 'react';
import {ComponentRangeAmount} from './ComponentRangeAmount.js'

class RenderTotalAmount extends React.Component {

    render() {
        return (
                <ComponentRangeAmount
                    id={'totalAmount'}
                    title={'Total Amount'}
                    minRange={0}
                    maxRange={1000}
                    minParameter={'minTotalAmount'}
                    maxParameter={'maxTotalAmount'}
                    filterParameter={'totalAmount'}
                />
            );
    }
}

export { RenderTotalAmount };