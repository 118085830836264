import React from 'react';
import { inject, observer } from 'mobx-react';
import { PayabliLogger } from '../../api/logger';
import { PayabliStorageManager } from '../../api/localStorageManager';
import { Brand } from '../../components/Brand';
import { BiUnlink } from 'react-icons/bi';

@inject('global')
@observer
class PageNotFound extends React.Component {

    async componentDidMount() {
        const errorMessage = `Error accessing to path ${this.props.location?.pathname}.`;
        await PayabliLogger.process({
            type: 'Route Not Found',
            message: errorMessage,
            exception: {
                message: errorMessage
            },
            origin: 'PageNotFound'
        });
    }

    goBack(e){
        if(e){
            e.preventDefault();
        }
        this.props.history.goBack();
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let urlEntry = this.props.global.getURLEntry();
        return (
        <>
            <div className="root-page">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{height: "100%", position: "absolute", width: "100%"}}>
                <div className="text-center pageNotFoundloginBrand" style={{width: '20em', backgroundColor: "#ffffff", padding: "30px 15px"}}>
                    {!encryptStorage.getItem(urlEntry+"_pImg") || encryptStorage.getItem(urlEntry+"_pImg")==="" ?
                        <BiUnlink className='icon-modal'/>
                        :
                        <Brand/>
                    }
                    <p className="small">
                        <b>404</b> Page not found.
                        <br/>
                        <br/>
                        <span className='text-underline blue-text cursor-pointer' onClick={()=>this.goBack()}>Go back</span>
                    </p>
                </div>
            </div>
            </div>
        </>
        )
    }
}

export { PageNotFound };