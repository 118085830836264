import React from 'react';
import { inject, observer } from 'mobx-react';
import { OverlayTrigger, Popover, Form, Col, Row } from "react-bootstrap";

@inject('reports', 'filter')
@observer
class RenderPopoverTimeBeetween extends React.Component {

    render() {
        const listHours = Array(24).fill(0).map((e, i) => <option key={i} value={(i).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}> {(i).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} </option> );
            
        const listMinutes = Array(60).fill(0).map((e, i) => <option key={i} value={((i)).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}> {((i)).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} </option>);
        
        const popoverTime = (
            <Popover id="popover-time" style={{boxShadow: '0 0 0 0px', minWidth: '0'}}>
                <Popover.Content style={{minWidth: '0'}}>
                    <Form>
                        <Row>
                            <Col>
                                <span>Start Time</span>
                                <Row className='mt-1'>
                                    <Col>
                                        <Form.Group>
                                            <div className='popover-time-select'>
                                                <select className="form-control select-time" size={10} onChange={(e) => this.props.filter.handleChangeTimeBeetween(e, 'dtreceived', 0)}>
                                                    {listHours}
                                                </select>
                                            </div>
                                            <div className='popover-time-select'>
                                                <select className="form-control select-time" size={10} onChange={(e) => this.props.filter.handleChangeTimeBeetween(e, 'dtreceived', 1)}>
                                                    {listMinutes}
                                                </select>
                                            </div>                                        
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <span>End Time</span>
                                <Row className='mt-1'>
                                    <Col>
                                        <Form.Group>
                                            <div className='popover-time-select'>
                                                <select className="form-control select-time" size={10} onChange={(e) => this.props.filter.handleChangeTimeBeetween(e, 'dtreceived', 2)}>
                                                    {listHours}
                                                </select>
                                            </div>
                                            <div className='popover-time-select'>
                                                <select className="form-control select-time" size={10} onChange={(e) => {this.props.filter.handleChangeTimeBeetween(e, 'dtreceived', 3)}}>
                                                    {listMinutes}
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Popover.Content>
            </Popover>
        );
        return (<>
                {
                    this.props.filter.stateDate.endDateDate ? 
                    <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={ popoverTime } onExited={() => this.props.filter.handlePanelFilter()}>
                        <div className="form-floating">
                            <input 
                                id="timeCalendar" 
                                name="timeCalendar" 
                                readOnly 
                                autoComplete="off" 
                                className={"form-control input-calendar-time"}
                                value={(`${this.props.filter.timeDate[0]}:${this.props.filter.timeDate[1]} → ${this.props.filter.timeDate[2]}:${this.props.filter.timeDate[3]}`)} 
                                placeholder="Start Time to End Time"
                                />
                            <label htmlFor="timeCalendar">Start Time to End Time</label>
                        </div>
                    </OverlayTrigger> :
                    <div className="form-floating">
                        <input 
                            id="timeCalendar" 
                            name="timeCalendar" 
                            readOnly 
                            autoComplete="off" 
                            className={"form-control input-calendar-time disabled"}
                            value={(`${this.props.filter.timeDate[0]}:${this.props.filter.timeDate[1]} → ${this.props.filter.timeDate[2]}:${this.props.filter.timeDate[3]}`)} 
                            placeholder="Start Time to End Time"
                            />
                        <label htmlFor="timeCalendar">Start Time to End Time</label>
                    </div>
                }
        </>);
    }
}

export { RenderPopoverTimeBeetween };