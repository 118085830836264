import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../../components/Layout';
import { TopBar } from '../../../components/TopBar';
import { MainTopBarMenu } from '../../../components/MainTopBarMenu';
import { SettingsLinks } from '../../../components/SettingsLinks';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import InputColor from 'react-input-color';
import { PayabliStorageManager } from '../../../api/localStorageManager';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link } from 'react-router-dom';
import { IMaskInput } from 'react-imask';

import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiInfoCircle } from 'react-icons/bi';

@inject('store', 'global', 'entry', 'reports', 'invoice')
@observer
class InvoiceSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paypointId: null,
      legalName: null,
      pEntry: null,
      settings: {
        brandColor: '#FFFFFF',
        requiredInvoiceNumber: false,
        invoicePrefix: '',
        invoiceNumber: 1,
        dueDate: 'DOR', // defaultSelected
        dueDateCustom: null,
        memoNote: 'Thank you for your business!',
        footerNote: '',
        includePaymentLink: false,
        // paylink Headers
        paylinkHeader: '',
        paylinkDescription: 'Is requesting a payment of',
        // contactUs
        contactUsText: '',
        contactUsEmail: '',
        contactUsPhone: '',
      },
      formErrors: {},
    };

    this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
    this.handleDueDateCustom = this.handleDueDateCustom.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChecks = this.handleChecks.bind(this);
    this.handleInvoiceNumbering = this.handleInvoiceNumbering.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.handleChangeInputMask = this.handleChangeInputMask.bind(this);
  }

  componentDidMount() {
    const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    const { paypointId, legalName, pEntry } = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`);
    this.setState({ paypointId, legalName, pEntry });

    this.loadSettingsData(pEntry, legalName);
  }

  setColor(e) {
    this.setState({ settings: { ...this.state.settings, brandColor: e.hex } });
  }

  handleChangeDueDate(e) {
    const { value } = e.target;
    this.setState({
      settings: { ...this.state.settings, dueDate: value, dueDateCustom: null },
    });
  }

  handleDueDateCustom(date) {
    this.setState({
      settings: { ...this.state.settings, dueDateCustom: date },
    });
  }

  handleChangeInput(e) {
    const { value, id } = e.target;
    this.setState({ settings: { ...this.state.settings, [id]: value } });
  }

  handleChangeInputMask(value, id) {
    this.setState({ settings: { ...this.state.settings, [id]: value } });
  }

  handleChecks(e) {
    const { checked, id } = e.target;
    this.setState({ settings: { ...this.state.settings, [id]: checked } });
  }

  handleInvoiceNumbering(value) {
    this.setState({
      settings: { ...this.state.settings, requiredInvoiceNumber: value },
    });
  }

  async loadSettingsData(entry, legalName) {
    this.props.global.setLoading(true);
    const paypointSettings = await this.props.entry.getPaypointSettings(entry);

    const { forInvoices } = paypointSettings;
    const valueLoaded = {};

    if (forInvoices !== null) {
      Object.values(forInvoices).forEach(({ key, value }) => {
        let valueParsed = value;
        if (value === 'true') valueParsed = true;
        if (value === 'false') valueParsed = false;
        if (value === 'null') valueParsed = null;

        if (key === 'dueDateCustom') valueParsed = new Date(value);

        valueLoaded[key] = valueParsed;
      });
    }

    const settings = Object.assign(
      {},
      this.state.settings,
      {
        paylinkHeader: legalName,
        brandColor: '#268ACE',
        requiredInvoiceNumber: false,
        dueDate: 'DOR',
        contactUsText: 'Contact Us',
      },
      valueLoaded
    );

    this.setState({ settings });
    this.props.global.setLoading(false);
  }

  saveSettings() {
    const { requiredInvoiceNumber, invoiceNumber } = this.state.settings;
    const { formErrors } = this.state;

    if (requiredInvoiceNumber && !this.props.global.isPositiveInteger(invoiceNumber)) {
      formErrors.invoiceNumber = true;
      toast.error(
        'if you selected auto-apply invoice numbers to new invoices you need specify a valid Next Invoice Number',
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        }
      );
      this.setState({ formErrors });
      return;
    }

    const { settings } = this.state;
    const settingsToSave = [];

    Object.entries(settings).forEach(([key, value]) => {
      settingsToSave.push({
        key,
        value: String(value),
        readOnly: null,
      });
    });

    this.props.global.setLoading(true);
    this.setState({ formErrors: {} });
    this.props.entry
      .updatePaypointSettings(this.state.pEntry, { invoices: settingsToSave })
      .then((res) => {
        toast.success('Invoices settings updated successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success-container',
        });
        this.props.global.setLoading(false);
      })
      .catch((error) => {
        this.showError(error);
        this.props.global.setLoading(false);
      });
  }

  showError(error) {
    let errorMessage =
      error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!';
    toast.error(errorMessage, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-error-container',
    });
  }

  render() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const popoverHelpPrefix = (
      <Popover id="popover-help-prefix">
        <Popover.Content>
          <span className="small">
            All future invoices will begin with "{this.state.settings.invoicePrefix}" prefix. E.g "{this.state.settings.invoicePrefix}{this.state.settings.invoiceNumber}".
          </span>
        </Popover.Content>
      </Popover>
    );

    const popoverHelpPrefixNumber = (
      <Popover id="popover-help-prefix-number">
        <Popover.Content>
          <span className="small">You next invoice will be "{this.state.settings.invoicePrefix}{this.state.settings.invoiceNumber}" that includes a prefix.</span>
        </Popover.Content>
      </Popover>
    );

    return (
      <Layout {...this.props}>
        <div>
          {/* TOPBAR */}
          <TopBar>
            <MainTopBarMenu />
            <div className="top-bar-sub">
              <SettingsLinks selected="invoicing" />
            </div>
          </TopBar>

          {/* BODY */}
          <div className="mt-body4" id="invoice-settings">
            <div className="mb-5">
              <h5>Invoice Settings</h5>
              <p className="small-grey">
                Configure defaults settings for new invoices. Changes will only be applied to newly created invoices
              </p>
            </div>

            <div className="row mb-5" style={{ maxWidth: '550px' }}>
              <div className="col-sm-12">
                <h6 className="sub-header mb-1">Business info & branding</h6>
                <p className="small-grey">
                  Change business contact information, and logo on invoices, payment links, and emails in{' '}
                  <Link to={"/"+PayabliStorageManager.getEntryName()+'/paypoint'}>Paypoint details</Link>
                </p>
              </div>

              <div className="mb-5">
                <div className="form">
                  <p className="small">Select the color that best highlights your company branding</p>
                  <InputColor
                    initialValue={this.state.settings.brandColor}
                    onChange={(e) => this.setColor(e)}
                    placement="top"
                    className="color-picker"
                    style={{ padding: '5px !important' }}
                  />

                  <input
                    type="text"
                    className="form-control color-picker-input"
                    placeholder="#FFFFFF"
                    style={{ maxWidth: '300px' }}
                    value={this.state.settings.brandColor}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <h6 className="sub-header mb-1">Invoice numbering</h6>
              </div>

              <div className="mb-5">
                <div className="icheck-primary">
                  <input
                    name="invoiceNumbering"
                    type="radio"
                    id="requiredInvoiceNumberingEmpty"
                    value="false"
                    onChange={(e) => this.handleInvoiceNumbering(false)}
                    checked={!this.state.settings.requiredInvoiceNumber}
                  />
                  <label htmlFor="requiredInvoiceNumberingEmpty">
                    Empty invoice # field must be completed by user (default)
                  </label>
                </div>

                <div className="icheck-primary">
                  <input
                    name="invoiceNumbering"
                    type="radio"
                    id="requiredInvoiceNumbering"
                    value="true"
                    onChange={(e) => this.handleInvoiceNumbering(true)}
                    checked={this.state.settings.requiredInvoiceNumber}
                  />
                  <label htmlFor="requiredInvoiceNumbering">
                    You decide how system should auto-apply invoice #'s to new invoices
                  </label>
                </div>

                <div
                  className={`input-by-radio mt-3 form-group ${
                    !this.state.settings.requiredInvoiceNumber ? 'disabled' : ''
                  }`}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-floating mb-3">
                        <input
                          disabled={!this.state.settings.requiredInvoiceNumber}
                          className={this.state.formErrors.invoicePrefix ? 'form-control input-error' : 'form-control'}
                          onChange={this.handleChangeInput}
                          placeholder="Apply invoice prefix"
                          id="invoicePrefix"
                          value={this.state.settings.invoicePrefix}
                        />
                        <label htmlFor="invoicePrefix">Apply invoice prefix</label>
                        <OverlayTrigger rootClose key={1} placement="auto" overlay={popoverHelpPrefix}>
                          <div className="icon">
                            <BiInfoCircle/>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">              
                        <IMaskInput
                              mask={this.props.global.maskValidator('numbers')}
                              disabled={!this.state.settings.requiredInvoiceNumber}
                              name="invoiceNumber"
                              value={String(this.state.settings.invoiceNumber)}
                              unmask={true}                              
                              onAccept={
                                  (value, mask) => this.handleChangeInputMask(value, 'invoiceNumber')
                              }
                              placeholder="Next Invoice Number"
                              className={this.state.formErrors.invoiceNumber ? 'form-control input-error' : 'form-control'}
                              id="invoiceNumber"
                          />
                          <label htmlFor="invoicePrefix">Next Invoice Number</label>
                        
                        <OverlayTrigger
                          rootClose
                          key={1}
                          placement="auto"
                          overlay={popoverHelpPrefixNumber}
                        >
                          <div className="icon">
                          <BiInfoCircle/>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Default payment due Date */}
              <div className="col-sm-12">
                <h6 className="sub-header mb-1">Default payment due date & terms</h6>
              </div>

              <div className="mb-5">
                <div className="form-group mt-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-floating">
                        <select
                          className="form-control form-select"
                          name="dueDate"
                          id="dueDate"
                          value={this.state.settings.dueDate}
                          onChange={this.handleChangeDueDate}
                        >
                          {this.props.invoice.getOptionsDueDate() &&
                            this.props.invoice.getOptionsDueDate().map((record, i) => (
                              <option value={record.value} key={i}>
                                {record.label}
                              </option>
                            ))}
                        </select>
                        <label htmlFor="dueDate" className="font-weight-bold">
                          Terms
                        </label>
                      </div>
                    </div>

                    {this.state.settings.dueDate === 'custom' && (
                      <div className="col-sm-4 mt-2">
                        <DatePicker
                          customInput={
                            <div className="form-floating">
                              <input
                                readOnly
                                autoComplete="off"
                                onChange={function () {}}
                                value={
                                  this.state.settings.dueDateCustom
                                    ? this.props.reports.dateStringFormat(this.state.settings.dueDateCustom)
                                    : ''
                                }
                                name="dueDateCustom"
                                id="dueDateCustom"
                                className={
                                  this.state.formErrors.dueDateCustom
                                    ? 'form-control input-calendar input-error input-lg'
                                    : 'form-control input-calendar input-lg'
                                }
                                placeholder="Select Date"
                              />
                              <label htmlFor="dueDateCustom">Custom Date</label>
                            </div>
                          }
                          selected={this.state.settings.dueDateCustom}
                          onChange={(date) => this.handleDueDateCustom(date)}
                          minDate={tomorrow}
                          dayClassName={(date) => 'calendar-day'}
                          popperPlacement="bottom-start"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Default Memo Note */}
              <div className="col-sm-12">
                <h6 className="sub-header mb-1">Default memo note</h6>
              </div>

              <div className="mt-2 mb-5">
                <div className="form">
                  <input
                    id="memoNote"
                    type="text"
                    className="form-control"
                    maxLength={500}
                    value={this.state.settings.memoNote}
                    onChange={this.handleChangeInput}
                  />
                </div>
              </div>

              {/* Default Footer */}
              <div className="col-sm-12">
                <h6 className="sub-header mb-1">Default footer</h6>
              </div>

              <div className="mt-2 mb-5">
                <div className="form">
                  <input
                    id="footerNote"
                    type="text"
                    maxLength={500}
                    className="form-control"
                    value={this.state.settings.footerNote}
                    onChange={this.handleChangeInput}
                  />
                </div>
              </div>

              {/* Payment Links */}
              <div className="col-sm-12">
                <h6 className="sub-header mb-1">Payment Link</h6>
              </div>

              <div className="mt-2 mb-5">
                <div className="icheck-primary">
                  <input
                    checked={this.state.settings.includePaymentLink}
                    type="checkbox"
                    name="includePaymentLink"
                    id="includePaymentLink"
                    onChange={this.handleChecks}
                  />
                  <label htmlFor="includePaymentLink">
                    Include payment link where customers can view, download, and pay their invoice
                  </label>
                </div>

                <p className="small mt-5">Set default pay link header</p>

                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      maxLength={500}
                      onChange={this.handleChangeInput}
                      placeholder="Header"
                      id="paylinkHeader"
                      value={this.state.settings.paylinkHeader}
                    />
                    <label htmlFor="paylinkHeader">Header</label>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      maxLength={500}
                      onChange={this.handleChangeInput}
                      placeholder="Description"
                      id="paylinkDescription"
                      value={this.state.settings.paylinkDescription}
                    />
                    <label htmlFor="paylinkDescription">Description</label>
                  </div>
                </div>
              </div>

              {/* Payment Links */}
              <div className="col-sm-12">
                <h6 className="sub-header mb-1">Default contact us info</h6>
              </div>

              <div className="mt-2 mb-5">
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      maxLength={500}
                      onChange={this.handleChangeInput}
                      placeholder="Text"
                      id="contactUsText"
                      value={this.state.settings.contactUsText}
                    />
                    <label htmlFor="contactUsText">Text</label>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="form-floating">
                    <IMaskInput
                      mask={this.props.global.maskValidator('email')}
                      name="email"
                      value={this.state.settings.contactUsEmail}
                      unmask={true}                      
                      onAccept={(value, mask) => this.handleChangeInputMask(value, 'contactUsEmail')}
                      placeholder="Support Email"
                      className={this.state.formErrors.email ? 'form-control input-error' : 'form-control'}
                      id="contactUsEmail"
                    />
                    <label htmlFor="contactUsEmail">Support email</label>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="form-floating">
                    <IMaskInput
                      mask={this.props.global.maskValidator('phone')}
                      name="email"
                      value={this.state.settings.contactUsPhone}
                      unmask={true}                      
                      onAccept={(value, mask) => this.handleChangeInputMask(value, 'contactUsPhone')}
                      placeholder="Support phone"
                      className={this.state.formErrors.email ? 'form-control input-error' : 'form-control'}
                      id="contactUsPhone"
                    />
                    <label htmlFor="contactUsPhone">Support phone</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <button className="btn full-w btn-primary" type="button" onClick={() => this.saveSettings()}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer transition={Bounce} />
      </Layout>
    );
  }
}

export { InvoiceSettings };
