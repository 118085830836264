import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('store', 'global', 'reports')
@observer
class QuickFilters extends React.Component {
  componentDidMount() {}

  handleQuickFilter(value, overDueComparator, e) {
    const filters = {
      'status(in)': value,
      'dueDate(lt)': null,
      'dueDate(gt)': null,
    };

    if (overDueComparator) {
      const today =
        this.props.global.stringDateFormatFilters(new Date()) + 'T00:00:00';
      const dueDateFilter = `dueDate(${overDueComparator})`;
      filters[dueDateFilter] = today;
    }

    this.props.handleFilter(filters);
    this.props.setHead(e.target.id);
  }

  render() {    
    return (
      <div className="col-5 head-filter">
        <div id="dropdown-invoices" className="mr-3">
          <h6 data-qaid="allInvoicesMoneyInReportsPage">{this.props.head ? this.props.global.capitalizeFirstLetter(this.props.head) : 'All Invoices'}</h6>
        </div>
        <div className="small-small inline-block">
          Detailed overview of all your customer invoices
        </div>
      </div>
    );
  }
}

export { QuickFilters };
