import React from 'react';
import { inject, observer } from 'mobx-react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PayabliStorageManager } from '../../../api/localStorageManager';
import { BiDotsVerticalRounded } from 'react-icons/bi';

@inject('store', 'global', 'reports', 'invoice')
@observer
class RowActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewInvoice: true,
      markPaid: true,
      sendInvoice: true,
      viewCustomer: true,
      delete: true,
    };

    this.exportInvoiceFile = this.exportInvoiceFile.bind(this);
  }

  componentDidMount() {
    const status = this.props.record.invoiceStatus;
    switch (status) {
      case 0:
        this.setState({ sendInvoice: false, markPaid: false});
        break;
      case 4:
        this.setState({ sendInvoice: false, markPaid: false, delete: false });
        break;
      default:
        break;
    }
  }

  exportInvoiceFile(){
    let invoiceId = this.props.record.invoiceId, invoiceNumber = (this.props.record.invoiceNumber ? this.props.record.invoiceNumber : '');
    this.props.invoice.exportInvoiceFile(invoiceId, invoiceNumber);
  }

  render() {
    let status = this.props.status;
    let frequency = this.props.record.frequency ? this.props.record.frequency : "";
    return (
      <DropdownButton menuAlign="right" title={<BiDotsVerticalRounded/>} id="actionsMenuButton" size="sm" variant="default" data-qaid="invoicesMoneyInActions">
        {this.state.viewCustomer && (
            <Dropdown.Item as={Link} to={"/"+PayabliStorageManager.getEntryName()+"/invoices/"+this.props.record.invoiceId} data-qaid="viewEditInvoiceLink">
              {(status !== 4 && status !== 2) ?
              <> View/Edit Invoice</>
              :
              <> View Invoice</>
              }
            </Dropdown.Item>
        )}

        {(status !== 4 && status !== 0) && (
          <Dropdown.Item onClick={(e) => this.props.toggleModalMarkPaid(this.props.record.invoiceId)} data-qaid="markAsPaidInvoiceLink">
            Mark as Paid
          </Dropdown.Item>
        )}

        {(status !== 4 && status !== 0 && frequency.toLowerCase() === "onetime") && (
          <Dropdown.Item onClick={(e) => this.props.handleSendInvoice(this.props.record)} data-qaid="sendInvoiceLink">Send Invoice</Dropdown.Item>
        )}

        {(status !== 0) && (
          <Dropdown.Item onClick={this.exportInvoiceFile} data-qaid="downloadInvoiceLink">Download Invoice</Dropdown.Item>
        )}

        {(this.state.viewCustomer && this.props.record.Customer) && (
            <Dropdown.Item as={Link} to={`/${this.props.global.getURLEntry()}/customer/${this.props.record.Customer.customerId}`} data-qaid="viewCustomerInvoiceLink">View Customer</Dropdown.Item>
        )}

        {(status !== 4) && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item
              style={{ color: 'rgb(192, 0, 0)' }}
              onClick={(e) => this.props.toggleModalDelete(this.props.record.invoiceId, e)}
              data-qaid="deleteInvoiceLink"
            >
              Delete
            </Dropdown.Item>
          </>
        )}
      </DropdownButton>
    );
  }
}

export { RowActions };
