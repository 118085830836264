import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiX } from 'react-icons/bi';
import { ViewPartialBatches } from '../../components/filters/ViewPartialBatches';
import { ViewPartialChargebacks } from '../../components/filters/ViewPartialChargebacks';
import { ViewPartialCustomers } from '../../components/filters/ViewPartialCustomers';
import { ViewPartialInvoices } from '../../components/filters/ViewPartialInvoices';
import { ViewPartialLineitems } from '../../components/filters/ViewPartialLineitems';
import { ViewPartialSettlements } from '../../components/filters/ViewPartialSettlements';
import { ViewPartialSubscriptions } from '../../components/filters/ViewPartialSubscriptions';
import { ViewPartialTransactions } from '../../components/filters/ViewPartialTransactions';
import { ViewPartialTransactionsMo } from '../../components/filters/ViewPartialTransactionsMo';
import { ViewPartialBatchesMo } from '../../components/filters/ViewPartialBatchesMo';
import { ViewPartialVendors } from '../../components/filters/ViewPartialVendors';
import { ViewPartialBills } from '../../components/filters/ViewPartialBills';
import { ViewPartialApprovals } from '../../components/filters/ViewPartialApprovals';
import { ViewPartialManageUsers } from '../../components/filters/ViewPartialManageUsers';
import { ViewPartialSftpAccounts } from '../../components/filters/ViewPartialSftpAccounts';
import { ViewPartialXChangeReport } from '../../components/filters/ViewPartialXChangeReport';

@inject('global', 'filter')
@observer
class ParentFilterPanel extends React.Component {

    componentDidMount(){
        if (this.props.report) {
            this.props.filter.setPropsReport(this.props.report);            
        }        
        if (this.props.batchId) {
            this.props.filter.setBatchId(this.props.batchId);            
        }
        if (this.props.paypointId) {
            this.props.filter.setPaypointId(this.props.paypointId);            
        }
        if (this.props.customFields) {
            this.props.filter.setCustomFields(this.props.customFields);
        }
        if (this.props.approvals && this.props.approvalUserId) {
            this.props.filter.setApprovals(this.props.approvals, this.props.approvalUserId);            
        }
        if (this.props.customerId) {
            this.props.filter.setCustomerId(this.props.customerId);            
        }
        if (this.props.transId) {
            this.props.filter.setTransId(this.props.transId);            
        }
        if (this.props.billNumber) {
            this.props.filter.setBillNumber(this.props.billNumber);            
        }
        if (this.props.vendorNumber) {
            this.props.filter.setVendorNumber(this.props.vendorNumber);            
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (Array.isArray(this.props.customFields) && this.props.customFields.length > 0 && Array.isArray(this.props.filter.customFields) && this.props.filter.customFields?.length === 0) {
            this.props.filter.setCustomFields(this.props.customFields);
        }
    }

    componentWillUnmount(){
        this.props.filter.clearStateFilters();
    }

    renderViewSwitch = () => {
        switch (this.props.report) {
            case 'transactions':
                return <ViewPartialTransactions />
            case 'subscriptions':
                return <ViewPartialSubscriptions />
            case 'chargebacks':
                return <ViewPartialChargebacks />
            case 'batches':
                return <ViewPartialBatches />
            case 'settlements':
                return <ViewPartialSettlements />
            case 'invoices':
                return <ViewPartialInvoices />
            case 'customers':
                return <ViewPartialCustomers />
            case 'lineitems':
                return <ViewPartialLineitems />
            case 'payouts':
                return <ViewPartialTransactionsMo />
            case 'batchesOut':
                return <ViewPartialBatchesMo />
            case 'vendors':
                return <ViewPartialVendors />
            case 'bills':
                return this.props.approvals ? <ViewPartialApprovals /> : <ViewPartialBills />
            case 'users/point':
                return <ViewPartialManageUsers />
            case 'xChangeAccount':
                return <ViewPartialSftpAccounts />
            case 'xChangeFilesLogs':
                return <ViewPartialXChangeReport />
            default:
                return '';
        }
    }

    render() {
        return (<>
            <div id="filter-right-panel" className={ this.props.global.filterRightPanelOpen ? 'open popover-body popover-body-2' : 'popover-body popover-body-2'} style={{ padding: '34px 30px 60px' }}>
                <BiX id="filter-right-panel-close" data-qaid="closePanelSymbol" onClick={() => this.props.filter.closePanel()}/>
                <h5 className="header mb-3">Filters</h5>
                <h5 className='title'data-qaid={"filters-"+(this.props.filter.getTitlePanel())}>{this.props.filter.getTitlePanel()}</h5>
                { this.renderViewSwitch() }
                <div className={'btnFilter'}>
                    <button type="button" className="btn-filter-reset" onClick={() => this.props.filter.clearFilters()}>Reset All</button>
                    <button type="button" className="btn-filter-see" onClick={() => this.props.filter.handlePanelFilter()} data-qaid="seeResultsFilterButton">See Results</button>
                </div>
            </div>
            { this.props.global.filterRightPanelOpen && <div onClick={() => this.props.filter.closePanel()} id="filter-right-panel-layer"></div> } 
        </>);
    }
}

export { ParentFilterPanel };