import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";
import { PayabliStorageManager } from '../api/localStorageManager'
import { BiChevronLeft } from 'react-icons/bi';

@inject('reports', 'global')
@observer
class CustomerLinks extends React.Component {
     
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        return (
            <div className="row">
            <div className="col-2">
                <div className="hide-sm">
                {this.props.goback && 
                    <a href={this.props.gobackLink} className="btn btn-light text-transform-normal"><BiChevronLeft/> { this.props.gobackText ? this.props.gobackText: "Go back" }</a>
                }
                </div>
            </div>
            <div className="col-8 text-center">
            {userPermissions.indexOf("Customers") !== -1  &&
                <Link className={this.props.selected === 'overview'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/customer/"+this.props.id}> Overview</Link>
            }
            {userPermissions.indexOf("Transaction") !== -1  &&
                <Link className={this.props.selected === 'payhistory' || this.props.selected ==='transactions' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/report/transactions/"+this.props.id}>Pay History</Link>
            }
            {userPermissions.indexOf("Subscriptions") !== -1  &&
                <Link className={this.props.selected === 'autopays'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/report/autopays/"+this.props.id}>Autopays</Link>
            }
            {(userPermissions.indexOf("Autopay") !== -1 || userPermissions.indexOf("Accept Payments") !== -1) &&
                <Link className={this.props.selected === 'paymethods'? 'selected' : ''} to={"/" + this.props.global.getURLEntry() + "/customer/paymethods/"+this.props.id}>Pay Methods</Link>
            }  
            </div>
            <div className="col-2 text-right">
            </div>
        </div>
        )
    }
}

export { CustomerLinks };