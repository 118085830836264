import React from 'react';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import { withRouter } from 'react-router';
import { BiChevronDown } from 'react-icons/bi';
import '../../assets/css/paylink.css';
import visaImg from '../../assets/images/visa.svg';
import mastercardImg from '../../assets/images/mastercard.svg';
import amexImg from '../../assets/images/amex.svg';
import discoverImg from '../../assets/images/discover.svg';
import { HeaderNameSubtitle } from '../Invoice/builder/preview/headerNameSubtitle';

@inject('store', 'global', 'invoice')
@observer
class PaymentLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      paylinkData: null,
    };
  }

  async loadData() {
    const paylinkId = this.props.match.params.paylinkId;
    const result = await this.props.invoice.getPaymentLinkData(paylinkId);
    this.setState({ paylinkData: result.responseData });
  }

  componentDidMount() {
    this.props.global.setLoading(true);
    // load invoiceData associated to PaymentLink
    this.loadData().finally(() => {
      this.props.global.setLoading(false);
      this.setState({ loading: false });
    });
  }

  getPriceFormatted(value) {
    return `$${this.props.global.numberWithCommas(parseFloat(value).toFixed(2))}`;
  }

  calculateSubTotal(items) {
    let subTotal = 0;
    items.forEach((item) => {
      subTotal += item.itemQty * item.itemCost;
    });
    return subTotal;
  }

  render() {
    if (this.state.loading) return null;

    const { PageSettings, PageContent } = this.state.paylinkData;
    const brandColor = PageSettings.color;
    const { header: paylinkHeader, description: paylinkDescription } = PageContent.page;
    const invoiceData = PageContent.invoices.invoiceList[0];
    const { invoiceNumber, invoiceDueDate, items, notes: memoNote } = invoiceData;
    const dueDate = dayjs(invoiceDueDate).format('MMM D, YYYY');

    const { emailLabel: contactUsEmail, header: contactUsText, phoneLabel: contactUsPhone } = PageContent.contactUs;
    const {defaultSettings} = this.props.invoice;

    const subTotal = this.calculateSubTotal(items);
    const total = subTotal + 0;

    const { visa, amex, mastercard, discover } = PageContent.paymentMethods.methods;

    return (
      <div id="paylinkPage" className="preview-card">
        <div className="logo">{/* <img src={logo} alt="logo" /> */}</div>
        <div className="bar">
          <div className="bar-left" style={{ backgroundColor: brandColor }}></div>
          <div className="bar-right"></div>
        </div>

        <HeaderNameSubtitle
            paylinkHeader={paylinkHeader ? paylinkHeader : defaultSettings?.paylinkHeader}
            paylinkDescription={paylinkDescription ? paylinkDescription : defaultSettings?.paylinkDescription}
        />

        <h2 className="price">{this.getPriceFormatted(subTotal)}</h2>

        <div className="invoice-data">
          <div className="group-data middle">
            <span className="group-header">Invoice #</span>
            <span className="group-value">{invoiceNumber}</span>
          </div>

          <div className="group-data middle">
            <span className="group-header">Due Date</span>
            <span className="group-value">{dueDate}</span>
          </div>
        </div>

        <span className="action-invoice-details">
          View invoice details <BiChevronDown />
        </span>

        {items.length > 0 && (
          <div className="invoice-details full-w">
            <div className="header">
              <div className="col product">Product/service</div>
              <div className="col unit">Unit Price</div>
              <div className="col qty">Qty</div>
              <div className="col amount">Amount</div>
            </div>
            <div className="items-body">
              {items.length > 0 &&
                items.map((item, idx) => (
                  <div key={idx} className="row-item">
                    <div className="details">
                      <div className="col product">{item.itemProductName}</div>
                      <div className="col unit">{this.getPriceFormatted(item.itemCost)}</div>
                      <div className="col qty">{item.itemQty}</div>
                      <div className="col amount">{this.getPriceFormatted(item.itemCost * item.itemQty)}</div>
                    </div>
                    {item.itemDescription && <div className="description">{item.itemDescription}</div>}
                  </div>
                ))}
            </div>
          </div>
        )}

        {/* ADD PAYMENT METHOD */}
        {/* <PaymentMethod /> */}

        <div className="review-total full-w">
          <h6 className="sub-header-line mb-3">Review &amp; Confirm Payment</h6>
          <div className="row">
            <div className="col">Subtotal:</div>
            <div className="col">{this.getPriceFormatted(subTotal)}</div>
          </div>
          <div className="row">
            <div className="col">Service Fee:</div>
            <div className="col">{this.getPriceFormatted(0)}</div>
          </div>
          <div className="row total-amount">
            <div className="col">Total Amount:</div>
            <div className="col">{this.getPriceFormatted(subTotal + 0)}</div>
          </div>
        </div>

        <div className="btn-pay btn-group full-w btn-pay">
          <button type="button" className="btn btn-success btn-lg" style={{ backgroundColor: brandColor }}>
            Pay {this.getPriceFormatted(total)}
          </button>
        </div>

        <span className="memo-note full-w">{memoNote}</span>

        <p className="contact-us small-grey-m0 text-center full-w">
          <b>{contactUsText}</b>
          <br />
          {contactUsEmail} | {contactUsPhone}
        </p>

        <div className="card-brands text-center bottom-grey mt-3">
          <div>
            {visa && <img alt="" src={visaImg}/>}
            {mastercard && <img alt="" className="mastercard" src={mastercardImg}/>}
            {discover && <img alt="" className="discover" src={discoverImg} />}
            {amex && <img alt="" className="amex" src={amexImg} />}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PaymentLink);
