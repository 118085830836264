import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportsLinks } from '../../components/ReportsLinks';
import { ReportPaginator } from '../../components/ReportPaginator';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { BiFilterAlt } from 'react-icons/bi';
import { ReportFilters } from '../../components/ReportFilters';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { Layout } from '../../components/Layout'
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MainBar } from '../../components/MainBar';
import { BiSortAlt2 } from 'react-icons/bi';


@inject('reports', 'global')
@observer
class SettlementReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterStatus: {},
            sortDirection: "desc",
            filtersEnabled: false
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getNameComponent = this.getNameComponent.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.sortBy = this.sortBy.bind(this);
    }

    getNameComponent(){
        return (
            <>
                <div className="mr-3 inline">
                    <h6 style={{display: "inline-block"}}>Settlements { this.state.batchNumber ? ", Batch "+this.state.batchNumber : '' }</h6>
                </div>
                <div className="small-small inline-block">
                    Total Count:  {this.props.reports.totalRecords}
                </div>
            </>
        )
    }
    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }
    
    componentDidMount() {
        this.setState({ batchNumber: this.props.match.params.batchNumber ? this.props.match.params.batchNumber : null });

        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                BatchNumber     : { label:'Batch #', class: '', display: true, filter: "BatchNumber"}, 
                SettlementDate  : { label:'Settlement Date', class: '', display: true, filter: "SettlementDate"}, 
                NetAmount       : { label:'Net amount', class: '', display: true}, 
                SettledAmount   : { label:'Settled amount', class: '', display: true}, 
            }
        )
        
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    exportFile(format){
        this.props.reports.exportFile('settlements',format);
    }

    showHideFilters(){
        this.setState({ filtersEnabled: !this.state.filtersEnabled }, function(){
            if(this.state.filtersEnabled === false){
                this.clearFilters();
            }
        });
    }

    clearFilters(){
        this.setState({ head: "",filterStatus: {}, dateStartDate: null,dateEndtDate: null}, function(){
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    getMappingFields(){
        return {
            'BatchNumber': 'batchnumber',
            'ParentOrgName': 'orgname',
            'PaypointLegalname': 'paypointlegal',
            'SettlementDate': 'settlementdate',
            'NetAmount': 'netamount',
            'SettledAmount': 'settledamount',
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }
    getReportFromApi(){
        if(this.state.sortBy){
            this.filter('sortBy', `${this.state.sortDirection}(${this.state.sortBy})`);
        }
        else{
            this.filter('sortBy', '');
        }
        if(this.state.batchNumber){
            this.filter('batchNumber(eq)',this.state.batchNumber)
        }

        this.props.global.setLoading(true);
        this.props.reports.getReportFromApi('settlements').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    handleChangeCalendar(dates) {
        const [start, end] = dates;
        this.setState({dateStartDate: start,dateEndtDate: end}, function(){
            const type = [];
            type['settlementDate(ge)'] = this.props.global.stringDateFormatFilters(start)+ "T00:00:00";
            type['settlementDate(le)'] = this.props.global.stringDateFormatFilters(end)+ "T23:59:59";
            if(end){
                this.filter(type,null);
            }
        });
        
    };


    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'settlements').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });
    }

    handleKeyDown(type, e){
        if (e.key === 'Enter') {
            this.filter(type,e.target.value);
        }
    }

    render() {
        return (
            <Layout {...this.props}>
            <div>

                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <ReportsLinks selected="settlements" goback={this.state.batchNumber ? true : false} history={this.props.history}/>
                </div>
                </TopBar>
                

                <div className="mt-body4">
                    <MainBar
                        reportNameComponent={this.getNameComponent}
                        dataQAName="SettlementsReports"
                        reportTitle={`Settlements ${ this.state.batchNumber ? ", Batch "+this.state.batchNumber : '' }`}
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        skipTotal={true}
                        exportFile={this.exportFile}
                    />    
                {this.state.filtersEnabled &&
                <ReportFilters report="batches" clearFilters = {this.clearFilters}/>
                }
                <div className="report-container">  
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            
         
                            ( record[1].display &&
                            (this.state.filtersEnabled ?
                            <th key={i} scope="col" className={record[1].class}>
                                
                                { (!record[1].filter) ? record[1].label: ''}

                                { record[1].filter &&
                                    <>
                                    
                                    
                                
                                    {record[1].filter === 'BatchNumber' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form">
                                            <label className="header">Filter by Batch #</label>
                                            <input placeholder="Batch #" onKeyDown={(e) => this.handleKeyDown('batchNumber(ct)',e)} type="text" className="form-control search-enter"/>
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    {record[1].filter === 'SettlementDate' &&
                                    <DropdownButton
                                        menuAlign="left"
                                        title={<div>{record[1].label} <BiFilterAlt/></div>}
                                        size="sm"
                                        variant=""
                                        className="search"
                                    >
                                        <Dropdown.ItemText className="filter-form-datepicker">
                                        <DatePicker
                                            selected={ this.state.dateStartDate ? this.state.dateStartDate : new Date()}
                                            onChange={date => this.handleChangeCalendar(date)}
                                            startDate={this.state.dateStartDate}
                                            endDate={this.state.dateEndtDate}
                                            selectsRange
                                            inline
                                        />
                                        </Dropdown.ItemText>
                                    </DropdownButton>
                                    }

                                    </>
                                    
                                }


                                

                            </th>
                                : 
                                (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                                record[1].display && 
                                (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                    <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                                </th> ))
                                ||
                                (record[1].display && 
                                (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                                </th> ))
                            )
                            )
    
                 
                           
                        ))
                    }
                    <th scope="col" className="text-right sticky-row">Actions</th>
                    </tr>
                </thead>
                <tbody>
                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i}>
                             { (this.props.reports.headers.BatchNumber && this.props.reports.headers.BatchNumber.display) &&
                            <td>{record.BatchNumber}</td>
                             }

                            { (this.props.reports.headers.CreatedAt && this.props.reports.headers.CreatedAt.display) &&
                            <td>{this.props.global.stringDateFormat(record.CreatedAt)}</td>
                            }

                            { (this.props.reports.headers.SettlementDate && this.props.reports.headers.SettlementDate.display) &&
                            <td>{this.props.global.stringDateFormat(record.SettlementDate)}</td>
                            }           

                            { (this.props.reports.headers.NetAmount && this.props.reports.headers.NetAmount.display && record.NetAmount) && 
                            <td>${this.props.global.numberWithCommas(record.NetAmount.toFixed(2))}</td>
                            }

                            { (this.props.reports.headers.SettledAmount && this.props.reports.headers.SettledAmount.display && record.SettledAmount) && 
                            <td>${this.props.global.numberWithCommas(record.SettledAmount.toFixed(2))}</td>
                            }

                            <td className="text-center sticky-row">
                                <DropdownButton
                                    menuAlign="center"
                                    title="..."
                                    id="dropdown-menu-align-right"
                                    size="sm"
                                    variant="default"
                                >
                                <Dropdown.Item onClick={(e) => this.viewRecordDetails(i)}>View Details</Dropdown.Item>
                                <Dropdown.Item>View Transactions</Dropdown.Item>
                                <Dropdown.Item>Download</Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }
                
                </tbody>
                </table>
                
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No settlements yet" description={<span>When funding events occur,<br/>you can track them here to help reconcile your bank deposits.</span>}/>
                }
                </div>
                <ReportPaginator report="settlements"/>

                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { SettlementReport };