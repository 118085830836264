import React from 'react';
import { inject, observer } from 'mobx-react';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import { BiListUl } from '@react-icons/all-files/bi/BiListUl';
import { BiColumns } from '@react-icons/all-files/bi/BiColumns';
import { BiSlider, BiArrowToBottom, BiPlus } from 'react-icons/bi';
import {BiRefresh} from '@react-icons/all-files/bi/BiRefresh';

import { Link } from "react-router-dom";

@inject('store', 'global', 'reports')
@observer
class TableTools extends React.Component {

  state = {
    flagItemAllColumn: true,
  };

  handleShowColumn(event) {
    this.props.reports.handleShowColumn(event);
    if (!this.state.flagItemAllColumn) {
      this.setState({flagItemAllColumn: true});
    }
  }

  selectAllColumns(e, containerId){
    let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
    let checkBoxes = menuContainer.getElementsByTagName('input');
    for(var i = 0; i < checkBoxes.length; i++) {
        if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
            checkBoxes[i].click();
        }
    }
    this.setState({flagItemAllColumn: false});
}

  exportFile(format) {
    this.props.reports.exportFile('invoices', format);
  }

  render() {
    return (
      <div className="col-7 text-right">
        <div className="d-none show-md">
          <DropdownButton
            menuAlign="right"
            title={
              <div>
                <BiListUl /> Actions
              </div>
            }
            size="sm"
            variant="default"
          >
             <Dropdown.Item as={Link} to={"/"+this.props.global.getURLEntry()+"/invoices"}>
              Add Invoice
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => this.props.showHideFilters()}>
              Show Filters
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => this.exportFile('xlsx')}>
              Download xlsx
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => this.exportFile('csv')}>
              Download csv
            </Dropdown.Item>
          </DropdownButton>
        </div>

        <div className="hide-md">
          <div className="main-bar-right-side">
              <div className='zone-refresh' onClick={(e) => this.props.refreshView(e)}>
                  <BiRefresh className='btn-refresh' size={20}/>
                  <span className='span-refresh'>Update Data</span>
              </div>
              <button
                className="btn btn-default bordered"
                type="button"
                onClick={(e) => this.props.showHideFilters()}
                data-qaid="showFiltersInvoicesMoneyInButton"
              >
                <BiSlider /> <span className="main-span-button">Show Filters</span>
              </button>
              <DropdownButton
                menuAlign="right"
                data-qaid="columnsInvoicesMoneyInButton"
                title={
                  <div>
                    <BiColumns /> <span className="main-span-button">Columns</span>
                  </div>
                }
                size="sm"
                variant="default"
                id='columnReport'
              >
                <Dropdown.ItemText>
                    <label><input type="checkbox" ref={input => {if(input){ input.indeterminate = this.state.flagItemAllColumn }}} id="itemSelectColumn" onChange={(e) => this.selectAllColumns(e, "columnReport") } /> Select All</label>
                </Dropdown.ItemText>
                <Dropdown.Divider />
                {this.props.reports.getHeaders.map((record, i) => (
                  <Dropdown.ItemText key={'key' + record[0]}>
                    <label data-qaid={'columnsInvoices-' + record[0]}>
                      <input
                        type="checkbox"
                        id={record[0]}
                        defaultChecked={
                          this.props.reports.headers[record[0]] &&
                          this.props.reports.headers[record[0]].display
                            ? true
                            : false
                        }
                        onChange={(e) => this.handleShowColumn(e)}
                      />{' '}
                      {record[1].label}
                    </label>
                  </Dropdown.ItemText>
                ))}
              </DropdownButton>
              <DropdownButton
                menuAlign="right"
                title={
                  <div>
                    <BiArrowToBottom data-qaid="exportInvoicesMoneyInButton"/> <span className="main-span-button">Export</span>
                  </div>
                }
                size="sm"
                variant="default"
              >
                <Dropdown.Item
                  onClick={(e) => this.exportFile('xlsx')}
                  eventKey="1"
                  data-qaid="downloadxlsxExportInvoicesMoneyInButton"
                >
                  Download xlsx
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => this.exportFile('csv')} eventKey="2" data-qaid="downloadcsvExportInvoicesMoneyInButton">
                  Download csv
                </Dropdown.Item>
              </DropdownButton>
              <a href={"/"+this.props.global.getURLEntry()+"/invoices"} className="btn btn-default btn-bordered-success ml-2" data-qaid="addInvoiceMoneyInButton">
                <BiPlus/> <span className="main-span-button">Add Invoice</span>
              </a>
            </div>
        </div>
      </div>
    );
  }
}

export { TableTools };
