import React from 'react';
import {ComponentRangeAmount} from './ComponentRangeAmount.js'

class RenderFeeAmount extends React.Component {

    render() {
        return (
                <ComponentRangeAmount
                    id={'feeAmount'}
                    title={'Fee'}
                    minRange={0}
                    maxRange={10}
                    minParameter={'minFee'}
                    maxParameter={'maxFee'}
                    filterParameter={'feeAmount'}
                />
            );
    }
}

export { RenderFeeAmount };