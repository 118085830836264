import React from 'react';
import { inject, observer } from 'mobx-react';
import {
	BiRefresh,
	BiColumns,
	BiDownload,
	BiInfoCircle,
	BiPieChartAlt2,
	BiDollarCircle,
	BiListUl
} from 'react-icons/bi';
import { RiHandCoinLine, RiBankFill } from 'react-icons/ri';
import { Col, OverlayTrigger, Tooltip, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import PartialBtnBadgeReport from './PartialBtnBadgeReport';
import { BalanceLine } from '../BalanceLine';

function PartialViewHeader({ global, ctable, reports }) {
	const { objectBatchRecord } = reports;
	const {
		BatchNumber = '-',
		BatchDate,
		BatchStatus,
		BatchAmount = 0,
		BatchFeesAmount = 0,
		BatchAuthAmount = 0,
		Transfer
	} = objectBatchRecord || {};

	return (
		<>
			<h6 data-qaid='batchTransferDetail'>
				<span style={{ marginRight: '30px' }}>Batch & Transfer Details</span>
				<BalanceLine moneIn={true} />
			</h6>
			<Row className='slide-view-header mb-2'>
				<Col className='d-flex slide-view-header-left mb-2'>
					<div>
						<span className='badge bg-light mb-1'>Batch #: {BatchNumber}</span>
						<div
							className='d-flex align-items-center'
							style={{ gap: '8px', whiteSpace: 'nowrap' }}>
							{global.getBatchStatus(BatchStatus)}
							<div className='text text-uppercase'>
								<span>{BatchDate ? global.stringDateFormatV3(BatchDate) : '-'}</span>
							</div>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip>Batch Close Date is {global.stringDateFormatV3(BatchDate)}</Tooltip>
								}>
								<BiInfoCircle style={{ fontSize: '10px' }} />
							</OverlayTrigger>
						</div>
					</div>
					<div className='content-summary-header align-items-center'>
						<PartialBtnBadgeReport
							classCircle='bg-primary'
							icon={<BiPieChartAlt2 />}
							textTitle={'Gross Batch'}
							textSubTitle={global.numberWithCommasNegative(BatchAmount.toFixed(2))}
							isInfoIcon={true}
							infoTextTooltip={
								'Gross batch is the total amount of the payments grouped in the batch. This amount includes service fees.'
							}
						/>
						<span className='m-2'>-</span>
						<PartialBtnBadgeReport
							classCircle='bg-secondary'
							icon={<RiHandCoinLine />}
							textTitle={'Service Fees'}
							textSubTitle={global.numberWithCommasNegative(BatchFeesAmount.toFixed(2))}
							isInfoIcon={true}
							infoTextTooltip={
								'Service fees are any pass-through fees charged to the customer at the time of payment. These are not transferred to the merchant when the batch is transferred and funded.'
							}
						/>
						<span className='m-2'>=</span>
						<PartialBtnBadgeReport
							classCircle='bg-success'
							icon={<BiDollarCircle />}
							textTitle={'Net Batch'}
							textSubTitle={global.numberWithCommasNegative(BatchAuthAmount.toFixed(2))}
							isInfoIcon={true}
							infoTextTooltip={'Net batch is the gross batch amount minus service fees.'}
						/>
						<span className='m-2 header-vertical-line'></span>
						<PartialBtnBadgeReport
							classCircle='bg-info'
							icon={<RiBankFill />}
							textTitle={'Transfer Amount'}
							textSubTitle={
								Transfer?.NetFundedAmount
									? global.numberWithCommasNegative(Transfer.NetFundedAmount.toFixed(2))
									: '$0.00'
							}
							isInfoIcon={true}
							infoTextTooltip={
								'The transfer amount is the net batch amount plus or minus any returns, refunds, billing and fees items, chargebacks, adjustments, and third party payments. This is the amount from the batch that is transferred to the merchant bank account.'
							}
						/>
					</div>
				</Col>
				<Col className='slide-view-header-right text-right mb-2'>
					<div className='menu-btn-action-show d-flex align-items-center justify-content-end'>
						<div
							className='element-refresh-data'
							onClick={ctable.getDataFromAPI}>
							<BiRefresh
								className='btn-refresh'
								size={20}
							/>
							<span className='ml-1 text'>Refresh Data</span>
						</div>
						<DropdownButton
							menuAlign='left'
							title={
								<>
									<BiColumns />
									<span
										className='btc-actions-header'
										data-qaid='columnsBatchTransferButton'>
										Columns
									</span>
								</>
							}
							size='sm'
							variant='default'>
							<Dropdown.ItemText>
								<label>
									<input
										type='checkbox'
										ref={(input) => {
											if (input) {
												input.indeterminate = ctable.flagItemAllColumn;
											}
										}}
										id='itemSelectColumn'
										onChange={(e) => ctable.selectAllColumns(e)}
									/>{' '}
									Select All
								</label>
							</Dropdown.ItemText>
							<Dropdown.Divider />
							<span className='label-item-menu small'>transactions</span>
							{ctable.columns?.map((col, index) => (
								<>
									{col.key === 'serviceGroup' && (
										<>
											<Dropdown.Divider />
											<span className='label-item-menu small'>billing & fees</span>
										</>
									)}
									{col.key === 'splitFundingAmount' && (
										<>
											<Dropdown.Divider />
											<span className='label-item-menu small'>transfer</span>
										</>
									)}
									<Dropdown.ItemText
										key={index}
										eventKey={index}
										className='small'
										style={{ fontSize: '10px' }}>
										<label data-qaid={`columnsBatchTransfer-${col.key}`}>
											<input
												type='checkbox'
												checked={ctable.visibleColumns?.includes(col.key)}
												onChange={() => ctable.setVisibleColumns(col.key)}
												disabled={ctable.isEnabledBillingFees(col.key, 'settlements')}
											/>{' '}
											{col.label}
										</label>
									</Dropdown.ItemText>
								</>
							))}
						</DropdownButton>
						<DropdownButton
							menuAlign='left'
							title={
								<>
									<BiDownload />
									<span
										className='btc-actions-header'
										data-qaid='exportBatchTransferButton'>
										Export
									</span>
								</>
							}
							size='sm'
							variant='default'>
							<Dropdown.Item
								onClick={() => {
									ctable.exportReportByFormat('xlsx');
								}}
								data-qaid='downloadxlsxExportBatchTransferMoneyInButton'>
								Download xlsx
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									ctable.exportReportByFormat('csv');
								}}
								data-qaid='downloadcsvExportBatchTransferMoneyInButton'>
								Download csv
							</Dropdown.Item>
						</DropdownButton>
					</div>
					<div className='menu-btn-action-hide'>
						<DropdownButton
							className='menu-btn-dropdown'
							menuAlign='right'
							title={<BiListUl />}
							size='sm'
							variant='default'>
							<Dropdown.Item onClick={ctable.getDataFromAPI}>Refresh Data</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									ctable.exportReportByFormat('xlsx');
								}}>
								Download xlsx
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									ctable.exportReportByFormat('csv');
								}}>
								Download csv
							</Dropdown.Item>
						</DropdownButton>
					</div>
				</Col>
			</Row>
		</>
	);
}

export default inject('global', 'ctable', 'reports')(observer(PartialViewHeader));
