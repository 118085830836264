import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiCopy, BiTrash, BiCheckCircle, BiDotsVerticalRounded } from "react-icons/bi";
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ActionsLinks } from '../../components/ActionsLinks';
import { Layout } from '../../components/Layout'
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { PayabliStorageManager } from '../../api/localStorageManager'

@inject('store', 'global')
@observer
class PaymentPages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true,
            subdomainToDelete: null,
            replicateModalIsOpen: false
        };
    }

    componentDidMount() {
        this.props.global.protect(this.props.history);

        this.editPaymentPage = this.editPaymentPage.bind(this);
        this.previewPaymentPage = this.previewPaymentPage.bind(this);
        this.newPaymentPage = this.newPaymentPage.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.deletePaymentPage = this.deletePaymentPage.bind(this);
        this.deletePaymentPageAction = this.deletePaymentPageAction.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.replicatePaymentPage = this.replicatePaymentPage.bind(this);
        this.replicatePaymentPageAction = this.replicatePaymentPageAction.bind(this);
        this.openReplicateModal = this.openReplicateModal.bind(this);
        this.closeReplicateModal = this.closeReplicateModal.bind(this);
        this.loadDataFromAPI = this.loadDataFromAPI.bind(this);

        this.props.global.setLoading(true);
        this.loadDataFromAPI();

    }

    loadDataFromAPI() {
        this.props.store.getPaymentPagesFromApi().then(res => {
            this.props.global.setLoading(false);
        })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
    }

    previewPaymentPage(subdomain) {
        window.open("/" + PayabliStorageManager.getEntryName() + "/preview/" + subdomain);
    }

    viewRecordDetails(link, object) {
        if (object && object.target.rel === undefined) {
            object.preventDefault();
        }

        if (object && object.target.id !== "actionsMenuButton" && object.target.nodeName.toLowerCase() !== 'a') {
            window.open(link, '_blank');
        }
    }

    editPaymentPage(subdomain, object) {
        if ((object && object.target.id !== "actionsMenuButton" && object.target.nodeName.toLowerCase() !== 'a' && object.target.tagName.toLowerCase() !== "svg") || object.target.id === "editPPLink") {
            this.props.global.setLoading(true);
            this.props.store.getPaymentPageFromApi(subdomain).then(res => {
                this.props.store.setSaveAction('edit');
                this.props.global.setLoading(false);
                this.props.history.push("/" + this.props.global.getURLEntry() + "/builder/" + subdomain);
            })
                .catch(error => {
                    this.props.global.setLoading(false);
                });
        }
    }

    replicatePaymentPage(position) {
        this.setState({ ppToReplicate: position });
        this.openReplicateModal();
    }

    replicatePaymentPageAction() {
        let ppPosition = this.state.ppToReplicate;
        this.props.global.setLoading(true);
        if (Number.isInteger(ppPosition) && ppPosition > -1) {
            this.props.store.replicatePaymentPage(this.props.store.getPaymentPages[ppPosition]).then(res => {
                this.loadDataFromAPI();
                this.closeReplicateModal();
                this.props.global.setLoading(false);

                toast.success("Data saved successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
        } else {
            let errorMessage = "Something is Wrong, cannot identify the payment page.";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
    }

    newPaymentPage() {
        this.props.store.newPaymentPageData();
        this.props.history.push("/" + this.props.global.getURLEntry() + "/builder");
    }

    closeDeleteModal() {
        this.setState({ modalIsOpen: true });
    }

    openDeleteModal() {
        this.setState({ modalIsOpen: false });
    }

    openReplicateModal() {
        this.setState({ replicateModalIsOpen: true });
    }

    closeReplicateModal() {
        this.setState({ replicateModalIsOpen: false });
    }

    deletePaymentPage(subdomain) {
        this.setState({ subdomainToDelete: subdomain });
        this.openDeleteModal();
    }

    deletePaymentPageAction() {
        var subdomainToDelete = this.state.subdomainToDelete;
        if (subdomainToDelete) {
            this.props.global.setLoading(true);
            this.props.store.deletePaymentPage(subdomainToDelete)
                .then(result => {
                    this.setState({ modalIsOpen: true });
                    this.props.global.setLoading(false);
                    toast.success("Data saved successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.loadDataFromAPI();
                })
                .catch(error => {
                    this.setState({ modalIsOpen: true });
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
        }
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) &&
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ?
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0;
        return (
            <Layout {...this.props}>
                <div>

                    <Modal style={{ textAlign: "center" }} show={!this.state.modalIsOpen} onHide={this.closeDeleteModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            <BiTrash className="icon-modal" />
                            <h5>Confirm</h5>
                            <p className="small">Confirm that you want to delete the item ?</p>
                            <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeDeleteModal()}>
                                Cancel
                            </Button>
                            &nbsp;&nbsp;
                            <Button className="btn" variant="danger" onClick={this.deletePaymentPageAction}>
                                Delete
                            </Button>
                        </Modal.Body>
                    </Modal>

                    <Modal style={{ textAlign: "center" }} show={this.state.replicateModalIsOpen} onHide={this.closeReplicateModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            <BiCopy className="icon-modal" />
                            <h5>Confirm</h5>
                            <p className="small">Please confirm you would like to create a new Payment Page by duplicating the attributes of this one?</p>
                            <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeReplicateModal()}>
                                Cancel
                            </Button>
                            &nbsp;&nbsp;
                            <Button className="btn" variant="primary" onClick={this.replicatePaymentPageAction}>
                                Confirm
                            </Button>
                        </Modal.Body>
                    </Modal>

                    <TopBar>
                        <MainTopBarMenu />
                        <div className="top-bar-sub">
                            <ActionsLinks selected="paymentPages" />
                        </div>
                    </TopBar>


                    <div className="mt-body4">

                        <div className="row mb-2">
                            <div className="col-7">
                                <h5 data-qaid="paymentPages">Payment Pages</h5>
                                <p className="small">Below is a list of your payment pages. Get stats and pull reports on your payment links. </p>
                            </div>
                            <div className="col text-right">
                                <button type="button" className="btn bordered float-end text-normal" onClick={() => this.newPaymentPage()}>+ New Payment Page</button>
                            </div>
                        </div>

                        <div className="report-container">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Subdomain</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" className="text-right">Total Volume</th>
                                        <th scope="col" className="text-center sticky-row">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.store.getPaymentPages.map((ppage, i) => (
                                        <tr key={i} className="cursorPointer" onClick={(e) => this.editPaymentPage(ppage.Subdomain, e)}>
                                            <td>{ppage.PageContent.name ? ppage.PageContent.name : '-'}</td>
                                            <td><a href={process.env.REACT_APP_URL_PAYMENT_PAGE + entry + "/" + ppage.Subdomain} target="_blank" rel="noreferrer">{ppage.Subdomain ? ppage.Subdomain : '-'}</a></td>
                                            <td>{ppage.LastAccess ? this.props.global.stringDateFormatV3(ppage.LastAccess) : ''}<br />{ppage.LastAccess ? this.props.global.stringTimeFormat(ppage.LastAccess) : ''}</td>
                                            <td><span className="badge bg-primary"><BiCheckCircle /> Active</span></td>
                                            <td className="text-right">${this.props.global.numberWithCommas(parseFloat(ppage.totalAmount).toFixed(2))}</td>
                                            <td className="text-center sticky-row">
                                                <DropdownButton
                                                    menuAlign="right"
                                                    title={<BiDotsVerticalRounded />}
                                                    id="actionsMenuButton"
                                                    size="sm"
                                                    variant="default"
                                                >
                                                    <Dropdown.Item onClick={() => this.previewPaymentPage(ppage.Subdomain)}>Preview</Dropdown.Item>
                                                    <Dropdown.Item id="editPPLink" onClick={(e) => this.editPaymentPage(ppage.Subdomain, e)}>Edit</Dropdown.Item>
                                                    <Dropdown.Item id="replicatePPLink" onClick={(e) => this.replicatePaymentPage(i, e)}>Duplicate</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item style={{ color: '#c00000' }} onClick={() => this.deletePaymentPage(ppage.Subdomain)}>Delete</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                        </tr>
                                    ))
                                    }


                                </tbody>
                            </table>

                            {this.props.store.getPaymentPages.length < 1 &&
                                <RecordsNotFound btnFunction={() => this.newPaymentPage()} btnText="+ Create a payment page" message="Create a payment page" description={<span>Create a beautiful payment page that lets your customers pay you quickly and securely.<br /> You can build one or as many as you wish.</span>} />
                            }
                        </div>

                    </div>
                    <ToastContainer transition={Bounce} />
                </div>
            </Layout>
        )
    }
}

export { PaymentPages };