import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiDollar, BiGroup, BiImageAdd, BiInfoCircle, BiMoney, BiReceipt, BiRepost} from 'react-icons/bi';
import { SettingsLinks } from '../../components/SettingsLinks';
import { PayabliCookieManager, PayabliStorageManager } from '../../api/localStorageManager';
import { HiCheckCircle } from 'react-icons/hi';
import { Brand } from '../../components/Brand';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

@inject('entry', 'global')
@observer
class PaypointDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            entryPoint: null,
            disableAutoReceipt: false,
            fileError: false,
            autoCapture: false,
            general: [],
            forPayOuts: []
        };
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.showError = this.showError.bind(this);
        this.getEntry = this.getEntry.bind(this);
        this.handleFileRead = this.handleFileRead.bind(this);
        this.selectFileLogo = this.selectFileLogo.bind(this);
        this.updateLogo = this.updateLogo.bind(this);
        this.getServices = this.getServices.bind(this);
        this.inputFileLogo = React.createRef();
    }
    handleCheckChange(event) {
        let thisObj = this;
        let values = {forPayOuts:null, general: null};
        const { id, checked } = event.target;
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ? 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0;

        this.props.global.setLoading(true);
        this.setState({ [id]: checked }, () => {
            if(id === 'disableAutoReceipt'){
                let general = thisObj.state.general;
                general.forEach(function (item) {
                    if(item.key === "disableAutoReceipt"){
                        item.readOnly = false;
                        item.value = checked.toString();
                    }
                });
                values.general = general;
            }
            if(id === 'autoCapture'){
                let forPayOuts = thisObj.state.forPayOuts;
                if(!forPayOuts.length > 0 ){
                    forPayOuts = [{readOnly: false, key: "autoCapture", value: checked.toString()}];
                }
                else{
                    forPayOuts.forEach(function (item) {
                        if(item.key === "autoCapture"){
                            item.readOnly = false;
                            item.value = checked.toString();
                        }
                    });
                }
                values.forPayOuts = forPayOuts;
            }
            thisObj.props.entry.updatePaypointSettings(entry, {...values}).then(res => {
                toast.success("Settings Fields updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
                thisObj.props.global.setLoading(false);
            })
            .catch(error => {
                thisObj.showError(error);
                thisObj.props.global.setLoading(false);
            });
        });
    }
    handleTextChange(e){
        this.setState({ [e.target.id]: e.target.value });
    }

    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }

    async loadSettingsData(entry) {
        this.props.global.setLoading(true);
        const paypointSettings = await this.props.entry.getPaypointSettings(entry);
    
        const { general, forPayOuts } = paypointSettings;
        let thisObj = this;
        if (general !== null) {
            general.forEach(function (item) {
                if(item.key === "disableAutoReceipt"){
                    thisObj.setState({
                        disableAutoReceipt: item.value === "true",
                        general: general,
                    }, () => {
                        thisObj.props.global.setLoading(false);
                    })
                }
            });
        }

        if (forPayOuts !== null) {
            forPayOuts.forEach(function (item) {
                if(item.key === "autoCapture"){
                    thisObj.setState({
                        autoCapture: item.value === "true",
                        forPayOuts: forPayOuts,
                    }, () => {
                        thisObj.props.global.setLoading(false);
                    })
                }
            });
        }
      }
    
    componentDidMount() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        this.props.global.setLoading(true);
        let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ? 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0;
        this.getEntry(entry);
        this.loadSettingsData(entry);
    }

    getEntry(entry){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        this.props.entry.getEntryFromApi(entry).then(res => {

            if(res.responseData && res.responseData.EntryLogo !== ''){
                encryptStorage.setItem('pImg', res.responseData.EntryLogo);
                PayabliCookieManager.createCookie(`${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`, res.responseData.EntryLogo, 1);
            }

            if(res.responseData && res.responseData.Paypoint){
                this.setState({entryPoint:res.responseData});
            }
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.showError(error);
            this.props.global.setLoading(false);
        });
    }


    showError(error){
        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
        toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
        });
    }

    handleFileRead(e){
        let file = e.target.files[0];
        if(file){
            let fileExtension = this.props.global.getFileExtension(file.type);

            if( (fileExtension === "jpeg" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "gif") && file.size <= 2000000){
                this.props.global.readFileBase64(file).then(base64 => {
                    let base64string = base64.split(',');
                    if(base64string[0] &&  base64string[1]){
                        this.props.entry.setLogo(base64string[1],fileExtension);
                    }
                    this.props.entry.setPreviewLogo(base64);
                    this.updateLogo();
                    this.setState({fileError: false});
                })
                .catch(error => {
                    console.log(error);
                });
            }
            else{
                this.setState({fileError: true});
            }
            
        }
        
    }

    updateLogo(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').pEntry;

        this.props.entry.updatePaypointLogo(entry)
        .then(result => {
            this.getEntry(entry);
            toast.success("Profile updated successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container'
            });

            let entryImg = `data:image/${this.props.entry.logo.ftype};base64,${this.props.entry.logo.fContent}`;
            this.props.global.setBrandingImg(entryImg);
            let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            let storageEntryImg = `${PayabliStorageManager.getEntryName()}_pImg`;
            encryptStorage.setItem(storageEntryImg, entryImg);
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    selectFileLogo(){
        this.inputFileLogo.current.click();
    }

    getServices(){
        if(this.state.entryPoint && this.state.entryPoint.Paypoint.Credentials){
            let services = [];
            this.state.entryPoint.Paypoint.Credentials.forEach(function (item) {
                services[item.Service] = true;
            });
            
            let servicesReturn = [];
            for (const key in services) {
                servicesReturn.push(<span className="badge bg-success mb-2">{key}</span>);
            }
            return servicesReturn;
        }
        return [];
    }

   
    render() {
        
        return (
            <Layout {...this.props}>
            <div>
                <div className="mt-body4">
                <div>
                <TopBar>
                    <MainTopBarMenu />
                    <div className="top-bar-sub">
                        <SettingsLinks selected="orgdetails"/>
                    </div>
                </TopBar>
                    <div className="row mb-4">
                        <div className="col-12">
                            <h5 className="fl-capitalize" data-qaid="settingsPage">{this.state.entryPoint ? this.state.entryPoint.Paypoint.DbaName:'-'}</h5>
                            <p className="small-grey">{this.state.entryPoint ? this.state.entryPoint.Paypoint.LegalName:'-'}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 mb-5 full-1260 order-1260-2">
                            <h6 className="sub-header-line mb-1  mb-4">
                                Details   
                            </h6>
                            
                            <div className="row small mb-3 dark-grey">
                                <div className="col-5"><b>Profile</b></div>
                                <div className="col-7 fl-capitalize">
                                    <div className="profile-image mb-2" onClick={(e)=>this.selectFileLogo()}>
                                        {this.props.entry?.previewLogo ?
                                            <Brand classBrand={'full-w'} imageBrand={this.props.entry.previewLogo} />
                                        :
                                            <>
                                            {this.state.entryPoint?.EntryLogo ?
                                               <Brand classBrand={'full-w'} imageBrand={this.state.entryPoint.EntryLogo} />
                                            :
                                                <BiImageAdd/>
                                            }                                            
                                            </>
                                        }
                                        <input className="hide" ref={this.inputFileLogo} type="file" onChange={(e) => this.handleFileRead(e)} />
                                    </div>
                                    {this.state.fileError && 
                                        <div className="small-small text-danger">File size or extension not allowed</div>
                                    }
                                </div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Organization</b></div>
                                <div className="col-7 fl-capitalize">{this.state.entryPoint ? this.state.entryPoint.Paypoint.ParentOrg.OrgName:'-'}</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Website</b></div>
                                <div className="col-7"><a rel="noreferrer" target="_blank" href={ this.state.entryPoint && this.state.entryPoint.Paypoint.WebsiteAddress? this.state.entryPoint.Paypoint.WebsiteAddress:'-'}>{this.state.entryPoint ? this.state.entryPoint.Paypoint.WebsiteAddress:'-'}</a></div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Address</b></div>
                                <div className="col-7">{this.state.entryPoint ? this.state.entryPoint.Paypoint.Address1:'-'}</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Address 2</b></div>
                                <div className="col-7">{this.state.entryPoint ? this.state.entryPoint.Paypoint.Address2:'-'}</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>City</b></div>
                                <div className="col-7">{this.state.entryPoint ? this.state.entryPoint.Paypoint.City:'-'}</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>State</b></div>
                                <div className="col-7">{this.state.entryPoint ? this.state.entryPoint.Paypoint.State:'-'}</div>
                            </div>
                            <div className="row small mb-2 dark-grey">
                                <div className="col-5"><b>Zip</b></div>
                                <div className="col-7">{this.state.entryPoint ? this.state.entryPoint.Paypoint.Zip:'-'}</div>
                            </div>
                            <div className="row small mb-5 dark-grey">
                                <div className="col-5"><b>Country</b></div>
                                <div className="col-7">{this.state.entryPoint ? this.state.entryPoint.Paypoint.Country:'-'}</div>
                            </div>

                            <h6 className="sub-header-line mb-1  mb-4">
                                Billing Information   
                            </h6>
                            <div className="mb-5">
                            { (this.state.entryPoint && this.state.entryPoint.Paypoint.BankData && this.state.entryPoint.Paypoint.BankData.length) ?
                                this.state.entryPoint.Paypoint.BankData.map((record, i) => (
                                    <div key={i} className="mb-4">
                                        <div className="row small mb-2 dark-grey">
                                            <div className="col-5"><b>Bank Name</b></div>
                                            <div className="col-7">{record.bankName ? record.bankName  : '-'}</div>
                                        </div>
                                        <div className="row small mb-2 dark-grey">
                                            <div className="col-5"><b>Account Number</b></div>
                                            <div className="col-7">{this.props.global.maskedCardNumber(record.accountNumber, "v5")}</div>
                                        </div>
                                        <div className="row small mb-2 dark-grey">
                                            <div className="col-5"><b>Routing Account</b></div>
                                            <div className="col-7">{this.props.global.maskedCardNumber(record.routingAccount, "v5")}</div>
                                        </div>
                                        <div className="row small mb-2 dark-grey">
                                            <div className="col-5"><b>Account Type</b></div>
                                            <div className="col-7">{record.typeAccount}</div>
                                        </div>
                                    </div>
                                ))
                                :''
                            }
                            </div>
                            <h6 className="sub-header-line mb-1  mb-4">
                                General Settings  
                            </h6>                           
                            <div className="mb-3 d-flex flex-column ">
                                <div className="icheck-primary">
                                    <input type="checkbox" name="autoCapture" id="autoCapture" value={this.state.autoCapture} checked={this.state.autoCapture} onChange={(e) => this.handleCheckChange(e)}/>
                                    <label htmlFor="autoCapture">PayOut Auto-capture &nbsp;
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Checking this setting will automatically capture all authorized status payout requests every day.</Tooltip>}
                                    >
                                        <BiInfoCircle style={{fontSize: '15px'}}/>
                                    </OverlayTrigger>
                                    </label>
                                </div>
                                <div className="icheck-primary">
                                    <input type="checkbox" name="disableAutoReceipt" id="disableAutoReceipt" value={this.state.disableAutoReceipt} checked={this.state.disableAutoReceipt} onChange={(e) => this.handleCheckChange(e)}/>
                                    <label htmlFor="disableAutoReceipt">Receipts from Autopay</label>
                                </div>
                            </div>
                            <h6 className="sub-header-line mb-1  mb-4">
                                Services  
                            </h6>
                           
                            <div className="mb-3 dark-grey">
                               {
                                    this.getServices().map((record, i) => (
                                        <span key={i}>{record} &nbsp;</span>
                                    ))
                                }
                            </div>


                        </div>
                        <div className="col-lg-8 offset-lg-1 dashboard-items full-1260 order-1260-1 offset-1260-0">
                            <div className="row mb-3">
                            <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle orange">
                                        <BiMoney/>
                                    </div>
                                    <label>Total # of Payments</label><br/>
                                    <b>{ this.state.entryPoint &&  this.state.entryPoint.Paypoint && this.state.entryPoint.Paypoint.summary.countTx ? this.state.entryPoint.Paypoint.summary.countTx : 0 }</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle green">
                                        <BiDollar/>
                                    </div>
                                    <label>Total $ Volume</label><br/>
                                    <b>${this.props.global.numberWithCommas((this.state.entryPoint &&  this.state.entryPoint.Paypoint && this.state.entryPoint.Paypoint.summary.amountTx ? this.state.entryPoint.Paypoint.summary.amountTx : 0 ).toFixed(2))}</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle red">
                                        <BiReceipt/>
                                    </div>
                                    <label>Total Invoices</label><br/>
                                    <b>0</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    { (this.state.entryPoint &&  this.state.entryPoint.Paypoint && this.state.entryPoint.Paypoint.PaypointStatus) === 1 ?
                                    <>
                                        <div className="dashboard-color-circle greenoutline">
                                            <HiCheckCircle/>
                                        </div>
                                        <label>Paypoint Status</label><br/>
                                        <b>Active</b>
                                    </>
                                    :
                                    <>
                                        <div className="dashboard-color-circle yellowoutline">
                                            <BiInfoCircle/>
                                        </div>
                                        <label>Status</label><br/>
                                        <b>Inactive</b>
                                    </>
                                    }
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle blue">
                                        <BiRepost/>
                                    </div>
                                    <label>Active Autopays</label><br/>
                                    <b>{this.state.entryPoint &&  this.state.entryPoint.Paypoint && this.state.entryPoint.Paypoint.summary.countSubs ? this.state.entryPoint.Paypoint.summary.countSubs : 0 }
                                    &nbsp;| 
                                    ${this.props.global.numberWithCommas((this.state.entryPoint &&  this.state.entryPoint.Paypoint && this.state.entryPoint.Paypoint.summary.amountSubs ? this.state.entryPoint.Paypoint.summary.amountSubs : 0 ).toFixed(2))}</b>
                                </div>

                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="dashboard-color-circle purple">
                                        <BiGroup/>
                                    </div>
                                    <label>Total Customers</label><br/>
                                    <b>{this.state.entryPoint &&  this.state.entryPoint.Paypoint && this.state.entryPoint.Paypoint.summary.customers ? this.state.entryPoint.Paypoint.summary.customers : 0 }</b>
                                </div>
                               
                            </div>

                            <h6 className="sub-header-line mb-1">
                                Contacts
                            </h6>

                            {(this.state.entryPoint && this.state.entryPoint.Paypoint.Contacts  && this.state.entryPoint.Paypoint.Contacts.length ) ?
                            <div className="report-container height-1260-auto">
                            <table className="table table-hover table-striped mb-5">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-center">Email</th>
                                        <th className="text-center">Phone</th>
                                        <th className="text-right">Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.state.entryPoint.Paypoint.Contacts.map((record, i) => (
                                    <tr key={i}>
                                        <td>{record.ContactName}</td>
                                        <td className="text-center">{record.ContactEmail}</td>
                                        <td className="text-center">{record.ContactPhone}</td>
                                        <td className="text-right">{record.ContactTitle}</td>
                                    </tr>
                                      ))
                                    }
                                </tbody>
                            </table>
                            </div>
                            :  <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div>
                            }

                            

                        </div>
                    </div>

                </div>
                </div>

                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { PaypointDetails };