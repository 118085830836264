import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { ReportPaginator } from '../../components/ReportPaginator';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { Layout } from '../../components/Layout';
import {Link} from "react-router-dom";
import { PayabliStorageManager } from '../../api/localStorageManager'
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MoneyOutLinks } from '../../components/MoneyOutLinks';
import { MainBar } from '../../components/MainBar';
import { BiSortAlt2, BiDotsVerticalRounded } from 'react-icons/bi';

@inject('reports', 'global')
@observer
class BatchesReportMoneyOut extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            flagItemAllColumn: true,
            sortDirection: "desc",
        };
        
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.exportTransactions = this.exportTransactions.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.sortBy = this.sortBy.bind(this);
    }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId ?
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId : null;
            
        this.setState({ orgId: idOrg });
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                BatchNumber             : { label:'Batch #', class: '', display: true },   
                ExternalPaypointID      : { label:'External Paypoint ID', class: '', display: false },       
                BatchDate               : { label:'Batch Date', class: '', display: true }, 
                BatchRecords            : { label:'# of Payments', class: 'text-center', display: true }, 
                PaymentGroupStatus      : { label:'Payment Group Status', class: '', display: true},  
                BatchStatus             : { label:'Batch Status', class: '', display: true }, 
                BatchAmount             : { label:'Batch Total', class: 'text-right', display: true }, 
                BatchProcessingAmount   : { label:'Processing Total', class: 'text-right', display: false },
                BatchProcessedAmount    : { label:'Processed Total', class: 'text-right', display: false },
                BatchCancelledAmount    : { label:'Cancelled Total', class: 'text-right', display: true },
                BatchPaidAmount         : { label:'Paid Total', class: 'text-right', display: true },
            }
        )        
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    viewRecordDetails(url, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            this.props.history.push(url);
        }
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    exportFile(format){
        this.props.reports.exportFile('batchesOut',format,this.state.orgId);
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    exportTransactions(format, batchNumber){
        this.props.global.setLoading(true);
        this.props.reports.exportTransactionsBatch(format, batchNumber)
        .then(result => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    clearFilters(){
        this.setState({ head: "" }, () => {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    clearFilter(e, filter, filterType){
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter,"");
    }

    getMappingFields(){
        return {
            'BatchNumber': 'batchnumber',
            'ParentOrgName': 'orgname',
            'PaypointLegalname': 'paypointlegal',
            'ExternalPaypointID': 'externalpaypointid',
            'BatchDate': 'batchdate',
            // 'BatchRecords': 'batchrecords',
            'PaymentGroupStatus': 'batchstatus',
            'BatchStatus': 'status',
            'BatchAmount': 'batchamount'
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.props.global.setLoading(true);
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }
    getReportFromApi(){
        let tempFilterValue = {};
        if(this.state.sortBy){
            tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
        }
        this.filter(tempFilterValue);
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'batchesOut').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    handleKeyDown(type, e){
        if (e.key === 'Enter') {
            this.filter(type,e.target.value);
        }
    }

    render() {
        return (
            <Layout {...this.props}>
            <div>
                <ParentFilterPanel report={'batchesOut'} />
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <MoneyOutLinks history={this.props.history} goback={false} selected="batches" />
                </div>
                </TopBar>
                

                <div className="mt-body4">
                    <MainBar
                        reportTitle={"Batches & Funding"}
                        reportName={"batchesOut"}
                        dataQAName="BatchesMoneyOut"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        withTotal={true}
                        totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    />
                <div className="report-container">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                            record[1].display && 
                            (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                            </th> ))
                            ||
                            (record[1].display && 
                            (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                            </th> ))
                        ))
                    }
                    <th scope="col" className="text-center sticky-row">Actions</th>
                    </tr>
                </thead>
                <tbody>
                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} className="cursorPointer" onClick={(e) => this.viewRecordDetails("/" + this.props.global.getURLEntry() + "/report/batches/transactions/out/"+ record.IdBatch,e)} data-qaid={"batchesOutMoneyInRow-"+(i)}>
                            { (this.props.reports.headers.BatchNumber && this.props.reports.headers.BatchNumber.display) &&
                                <td>{record.BatchNumber}</td>
                            }
                            { (this.props.reports.headers.ExternalPaypointID && this.props.reports.headers.ExternalPaypointID.display) &&
                                <td>{record && record.externalPaypointID ? record.externalPaypointID : '-'}</td>
                            }
                            { (this.props.reports.headers.BatchDate && this.props.reports.headers.BatchDate.display) &&
                                <td>{this.props.global.stringDateFormatV3(record.BatchDate)}</td>
                            }                                                       
                            { (this.props.reports.headers.BatchRecords && this.props.reports.headers.BatchRecords.display) &&
                                <td className='text-center'>{record.BatchRecords}</td>
                            }
                            { (this.props.reports.headers.PaymentGroupStatus && this.props.reports.headers.PaymentGroupStatus.display) && 
                                <td>{record.BatchStatusText ? record.BatchStatusText : "-"}</td>
                            }                                 
                            { (this.props.reports.headers.BatchStatus && this.props.reports.headers.BatchStatus.display) && 
                                <td>{this.props.global.getBatchMoStatus(record.BatchStatus)}</td>
                            }
                            { (this.props.reports.headers.BatchAmount && this.props.reports.headers.BatchAmount.display) && 
                                <td className='text-right'>{record.BatchAmount ? this.props.global.numberWithCommasNegative(record.BatchAmount.toFixed(2)) : '$0.00'}</td>
                            }
                            { (this.props.reports.headers.BatchProcessingAmount && this.props.reports.headers.BatchProcessingAmount.display) && 
                                <td className='text-right'>{record.BatchProcessingAmount ? this.props.global.numberWithCommasNegative(record.BatchProcessingAmount.toFixed(2)) : '$0.00'}</td>
                            }
                            { (this.props.reports.headers.BatchProcessedAmount && this.props.reports.headers.BatchProcessedAmount.display) && 
                                <td className='text-right'>{record.BatchProcessedAmount ? this.props.global.numberWithCommasNegative(record.BatchProcessedAmount.toFixed(2)) : '$0.00'}</td>
                            }
                            { (this.props.reports.headers.BatchCancelledAmount && this.props.reports.headers.BatchCancelledAmount.display) && 
                                <td className='text-right'>{record.BatchCancelledAmount ? this.props.global.numberWithCommasNegative(record.BatchCancelledAmount.toFixed(2)) : '$0.00'}</td>
                            }
                            { (this.props.reports.headers.BatchPaidAmount && this.props.reports.headers.BatchPaidAmount.display) && 
                                <td className='text-right'>{record.BatchPaidAmount ? this.props.global.numberWithCommasNegative(record.BatchPaidAmount.toFixed(2)) : '$0.00'}</td>
                            }
                            <td className="text-center sticky-row">
                                <DropdownButton
                                    menuAlign="right"
                                    title={<BiDotsVerticalRounded/>}
                                    data-qaid="actionBatchesMoneyOut"
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                <Dropdown.Item as={Link} to={"/" + this.props.global.getURLEntry() + "/report/batches/transactions/out/"+ record.IdBatch} data-qaid="batchesOutViewTransactionsLinks">View Transactions</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => this.exportTransactions('csv', record.IdBatch)} data-qaid="batchesOutDownloadLinks">Download</Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No batches yet" description={<span>When your payments are grouped together for funding,<br/> they will show up here.</span>}/>
                }
                </div>
                
                <ReportPaginator report="batchesOut"/>

                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { BatchesReportMoneyOut };