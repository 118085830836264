import React from 'react';
import { inject, observer } from 'mobx-react';

import { SectionVendor } from './vendor/SectionVendor';
import SectionBillDetails from './billDetails/SectionBillDetails';
import SectionItemsDetails from './itemsDetails/SectionItemsDetails';
import SectionPayment from './payment/SectionPayment';

@inject('store', 'global', 'bill')
@observer
class VendorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedDefault: false
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    const billStatus = this.props.bill.status;
    const billDataLoaded = this.props.billDataLoaded;

    if (billStatus !== 'new' && !this.state.loadedDefault && billDataLoaded) {
      const note = billDataLoaded.Comments;
      this.props.bill.updateAdvancedOptions({ memoNote: note });
      this.setState({ loadedDefault: true });
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  handleChangeInput(e) {
    const { value } = e.target;
    this.props.bill.updateAdvancedOptions({ memoNote: value });
  }

  render() {
    return (
      <>
        <h5 data-qaid={this.props.isView ? "viewBillMoneyOutButton" : "add/editModalBillMoneyOut"}>{ this.props.isView ? "View Bill" : (this.props.bill.status.toLowerCase() === 'edit' ? "Edit" : "Add") + " Bill"}</h5>

        <div className="wrapper-form">
          <SectionVendor params={this.props.params} isView={this.props.isView}/>

          <SectionBillDetails isView={this.props.isView} billDataLoaded={this.props.billDataLoaded} />

          <SectionItemsDetails isView={this.props.isView} billDataLoaded={this.props.billDataLoaded} />

          {/* Notes */}
          <div className="section-form">
            <h6 className="mb-3">Notes</h6>
            <div className="form-floating">
              <input
                className="form-control"
                onChange={this.handleChangeInput}
                placeholder="Memo note"
                id="memoNote"
                value={this.props.bill.advancedOptions.memoNote ? this.props.bill.advancedOptions.memoNote : ''}
                disabled={this.props.isView}
              />
               <label htmlFor={"memoNote"}>Memo note</label>
            </div>
          </div>

  

          <SectionPayment isView={this.props.isView} />
        </div>
      </>
    );
  }
}

export { VendorForm };
