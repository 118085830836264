import { observable, makeObservable, action } from "mobx";
import globalStore from './GlobalStore';
import reportsStore from './ReportsStore';

class FilterStore {
    constructor(globalStore, reportsStore) {
        this.globalStore = globalStore;
        this.reportsStore = reportsStore;
        makeObservable(this)
    }

    rangeTrackStyle = {background: 'linear-gradient(90deg, #7022FF -1.67%, #22D2FD 100%)'};
    
    rangeHandleStyle = {background: '#FFFFFF', border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', opacity: '1'};

    rangeHandleStyleBlue = {background: '#FFFFFF', border: '1px solid #03A9F4', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', opacity: '1'};
    
    rangeRailStyle = {background: 'linear-gradient(90deg, #7022FF -1.67%, #22D2FD 100%)', opacity: '0.2'};

    globalStore;
    
    reportsStore;

    @observable
    propsReport = '';

    @observable
    objectFilter = {};
    
    @observable
    stateDate = {};

    @observable
    timeDate = ['00', '00', '23', '59'];

    @observable
    rangeNumber = {};

    @observable
    filterStatus = {};
    
    @observable
    filterBillsStatus = {};

    @observable
    filterReturned = {};

    @observable
    filterRefunded = {};

    @observable
    filterMethods = {};

    @observable
    filterBrand = {};

    @observable
    filterText = {};

    @observable
    filterTypes = {};

    @observable
    filterRadioOption = {};

    @observable
    filterCheck = {};

    @observable
    filterSource = {};

    @observable
    filterSettlementStatus = {};

    @observable
    filterPaymentTerms = {};

    @observable
    batchId;

    @observable
    paypointId;

    @observable
    customFields;

    @observable
    approvals;

    @observable
    transId;

    @observable
    customerId;

    @observable
    vendorNumber;

    @observable
    billNumber;

    @observable
    approvalUserId;

    /* ACTIONS */

    @action
    setPropsReport(value){
        this.propsReport = value;
    }

    @action
    setBatchId(value){
        this.batchId = value;
    }

    @action
    setPaypointId(value){
        this.paypointId = value;
    }

    @action
    setCustomFields(value){
        this.customFields = value;
    }

    @action
    setApprovals(value, valueUser){
        this.approvals = value;
        this.approvalUserId = valueUser;
    }

    @action
    setCustomerId(value){
        this.customerId = value;
    }

    @action
    setTransId(value){
        this.transId = value;
    }

    @action
    setVendorNumber(value){
        this.vendorNumber = value;
    }

    @action
    setBillNumber(value){
        this.billNumber = value;
    }

    getTitlePanel(){
        switch (this.propsReport) {
            case 'transactions':
                return 'Transaction Details';
            case 'subscriptions':
                return 'Autopay Details';
            case 'chargebacks':
                return 'Returns & Chargeback Details';
            case 'batches':
                return 'Batch Details';
            case 'settlements':
                return 'Batch Transactions Details';
            case 'invoices':
                return 'Invoice Details';
            case 'customers':
                return 'Customer Information';
            case 'lineitems':
                return 'Item Details';
            case 'payouts':
                return 'Transaction Details';
            case 'batchesOut':
                return 'Batch Details';
            case 'vendors':
                return 'Vendor Information'
            case 'bills':
                return 'Bill Details'
            case 'users/point':
                return 'Customer Information'
            case 'xChangeAccount':
                return 'SFTP Accounts Details'
            case 'xChangeFilesLogs':
                return 'Exchange Files Details'
            default:
                return 'Report Details';
        }
    }

    closePanel(){
        this.globalStore.setFilterRightPanelOpen(false);
    }

    filter(value){
        const type = this.setParametersInFilters(value);
        if(this.propsReport === 'xChangeFilesLogs' || this.propsReport === 'xChangeAccount'){
            this.globalStore.setLoading(true);
            this.reportsStore.filterXchange(type, null, this.propsReport).then(res => {
                this.globalStore.setLoading(false);
            })
            .catch(error => {
                this.globalStore.setLoading(false);
            });
        } else {
            this.globalStore.setLoading(true);
            this.reportsStore.filter(type, null, this.propsReport).then(res => {
                this.globalStore.setLoading(false);
            })
            .catch(error => {
                this.globalStore.setLoading(false);
            });
        }
    }

    handlePanelFilter(){
        if (Object.keys(this.objectFilter).length > 0) {
            this.filter(this.objectFilter);
        }
    }

    @action
    clearFilters(){  
        this.objectFilter = {};
        this.stateDate = {};
        this.timeDate = ['00', '00', '23', '59'];
        this.rangeNumber = {};
        this.filterStatus = {};
        this.filterReturned = {};
        this.filterRefunded = {};
        this.filterMethods = {};
        this.filterBrand = {};
        this.filterText = {};
        this.filterTypes = {};
        this.filterRadioOption = {};
        this.filterCheck = {};
        this.filterSource = {};
        this.filterSettlementStatus = {};
        this.filterPaymentTerms = {};
        this.filterBillsStatus = {};

        this.reportsStore.clearFilters();
        const tempFilterValue = this.setParametersInFilters({});
        this.filter(tempFilterValue);
    }

    setParametersInFilters(object) {
        let tempFilterValue = object;
        if (this.propsReport === 'payouts' && this.batchId) {
            tempFilterValue['batchId(eq)'] = this.batchId;
        }
        if (this.propsReport === 'payouts' && this.billNumber) {
            tempFilterValue['billNumber(eq)'] = this.billNumber;
        }
        if (this.propsReport === 'payouts' && this.vendorNumber) {
            tempFilterValue['vendorNumber(eq)'] = this.vendorNumber;
        }
        if (this.propsReport === 'settlements' && this.batchId) {
            tempFilterValue['batchId(eq)'] = this.batchId;
        }
        if (this.propsReport === 'subscriptions' && this.paypointId) {
            tempFilterValue['paypointId(eq)'] = this.paypointId;
        }
        if (this.propsReport === 'subscriptions' && this.customerId) {
            tempFilterValue['customerId(eq)'] = this.customerId;
        }
        if (this.propsReport === 'transactions' && this.customerId) {
            tempFilterValue['customerId(eq)'] = this.customerId;
        }
        if (this.propsReport === 'transactions' && this.paypointId) {
            tempFilterValue['paypointId(eq)'] = this.paypointId;
        }
        if (this.propsReport === 'transactions' && this.transId) {
            tempFilterValue['transId(eq)'] = this.transId;
        }
        if (this.propsReport === 'bills' && this.billNumber) {
            tempFilterValue['billNumber(eq)'] = this.billNumber;
        }
        if (this.approvals) {
            tempFilterValue['approvalUserId(eq)'] = this.approvalUserId;
            tempFilterValue['status(in)'] = '2|5';
            let filterBillsStatus = this.filterBillsStatus;
            filterBillsStatus[2] = true;
            filterBillsStatus[5] = true;
            this.filterBillsStatus = filterBillsStatus;
        }
        return tempFilterValue;
    }

    @action
    clearStateFilters(){  
        this.objectFilter = {};
        this.stateDate = {};
        this.timeDate = ['00', '00', '23', '59'];
        this.rangeNumber = {};
        this.filterStatus = {};
        this.filterBillsStatus = {};
        this.filterReturned = {};
        this.filterRefunded = {};
        this.filterMethods = {};
        this.filterBrand = {};
        this.filterText = {};
        this.filterTypes = {};
        this.filterRadioOption = {};
        this.filterRadioOptionTwo = {};
        this.filterCheck = {};
        this.filterSource = {};
        this.filterSettlementStatus = {};
        this.filterPaymentTerms = {};
        this.filterOrgState = {};
    }

    @action
    clearFilterAmount(value, type){
        let object = this.objectFilter
        for (let item in object) {
            if (item.toString().includes(value)) {
                let keyNew = `${value}(${type})`
                let valueNew = object[item]
                delete object[item]
                if (valueNew.length > 0) {
                    object[keyNew] = valueNew
                }
                this.objectFilter = object
                if (Object.keys(this.objectFilter).length > 0) {
                    this.filter(this.objectFilter)
                }
                continue
            }
        }
    }
    
    /* FUNCTIONS */

    @action
    handleKeyDown(filter, event){
        if (event.key === 'Enter' && event.target.value !== '') {
            let object = this.objectFilter;
            object[filter] = event.target.value;
            this.objectFilter = object;
            this.filter(this.objectFilter);
        }
    }

    @action
    handleCaptureTextFilter(filter, event){
        let object = this.objectFilter, tempState = this.filterText;
        object[filter] = event.target.value;
        tempState[event.target.id] = event.target.value;
        this.objectFilter = object;
        this.filterText = tempState;
    }

    @action
    handleKeyDownObject(filter, event, state){
        if (event.key === 'Enter' && event.target.value !== '') {
            let object = this.objectFilter, tempState = this.filterText;
            const value = (event.target.value).replaceAll(/\s/g, '').replaceAll('$', '');
            object[filter] = value;
            tempState[state] = value;            
            this.objectFilter = object;
            this.filterText = tempState;
            this.filter(this.objectFilter);
        }
    }

    @action
    handleCaptureTextFilterObject(filter, event, state){
        let object = this.objectFilter, tempState = this.filterText;
        object[filter] = event.value;
        tempState[state] = event.value
        this.objectFilter = object;
        this.filterText = tempState;
    }

    @action
    handleChangeCalendarState(dates, startState, endState, filter) {
        const [start, end] = dates;
        let tempStateDate = this.stateDate;
        tempStateDate[startState] = start;
        tempStateDate[endState] = end;
        this.stateDate = tempStateDate
        if (end) {
            let object = this.objectFilter;
            object[`${filter}(ge)`] = this.globalStore.stringDateFormatFilters(start) + `T${this.timeDate[0]}:${this.timeDate[1]}:00`;
            object[`${filter}(le)`] = this.globalStore.stringDateFormatFilters(end) + `T${this.timeDate[2]}:${this.timeDate[3]}:59`;
            this.objectFilter = object;
            this.filter(this.objectFilter);
        }
    };

    @action
    handleChangeCalendarStateReplyBy(dates, startState, endState, filter) {        
        const [start, end] = dates;
        let tempStateDate = this.stateDate;
        tempStateDate[startState] = start;
        tempStateDate[endState] = end;
        this.stateDate = tempStateDate
        if (end) {
            let object = this.objectFilter, tempDates = [];
            dates.forEach((item) => {
                tempDates.push(new Date(item));
            });
            const [tempStart, tempEnd] = tempDates;                                
            object[`${filter}(ge)`] = this.globalStore.stringDateFormatFilters(tempStart.setDate(tempStart.getDate() - 10)) + `T${this.timeDate[0]}:${this.timeDate[1]}:00`;
            object[`${filter}(le)`] = this.globalStore.stringDateFormatFilters(tempEnd.setDate(tempEnd.getDate() - 10)) + `T${this.timeDate[2]}:${this.timeDate[3]}:59`;
            this.objectFilter = object;
            this.filter(this.objectFilter);
        }
    };

    @action
    handleChangeCalendarStateBeetween(dates, startState, endState, filter) {
        const [start, end] = dates;
        let tempStateDate = this.stateDate;
        tempStateDate[startState] = start;
        tempStateDate[endState] = end;
        this.stateDate = tempStateDate
        if (end) {
            let object = this.objectFilter;
            object[`${filter}(bt)`] = `${this.globalStore.stringDateFormatFilters(start)}T${this.timeDate[0]}:${this.timeDate[1]}:00||${this.globalStore.stringDateFormatFilters(end)}T${this.timeDate[2]}:${this.timeDate[3]}:59`;
            this.objectFilter = object;
            this.filter(this.objectFilter);
        }
    };

    @action
    handleChangeTimeBeetween(event, filter, pos) {
        let val = this.timeDate, object = this.objectFilter
        val[pos] = event.target.value;
        this.timeDate = val;
        object[`${filter}(bt)`] = `${this.globalStore.stringDateFormatFilters(this.stateDate.startDateDate)}T${this.timeDate[0]}:${this.timeDate[1]}:00||${this.globalStore.stringDateFormatFilters(this.stateDate.endDateDate)}T${this.timeDate[2]}:${this.timeDate[3]}:59`;
        this.objectFilter = object;
        if (pos === 3) {
            document.getElementById("filter-right-panel").click();
        }
    };

    @action
    handleChangeTime(event, filter, pos) {
        let val = this.timeDate, object = this.objectFilter
        val[pos] = event.target.value;
        this.timeDate = val;
        object[`${filter}(ge)`] = this.globalStore.stringDateFormatFilters(this.stateDate.startDateDate) + `T${this.timeDate[0]}:${this.timeDate[1]}:00`;
        object[`${filter}(le)`] = this.globalStore.stringDateFormatFilters(this.stateDate.endDateDate) + `T${this.timeDate[2]}:${this.timeDate[3]}:59`;
        this.objectFilter = object;
        if (pos === 3) {
            document.getElementById("filter-right-panel").click();
        }
    };

    @action
    handleChangeTimeSftpAccount(event, filter, pos) {
        let val = this.timeDate, object = this.objectFilter
        val[pos] = event.target.value;
        this.timeDate = val;
        object[`${filter}(bt)`] = `${this.timeDate[0]}:${this.timeDate[1]}||${this.timeDate[2]}:${this.timeDate[3]}`;
        this.objectFilter = object;
        if (pos === 3) {
            document.getElementById("filter-right-panel").click();
        }
    };

    @action
    handleStatus(filter, value, click){
        let object = this.objectFilter, status = this.filterStatus;
        if (typeof value === 'string' && value.indexOf(';') > -1) {
            let arr = value.split(';')
            for (let index = 0; index < arr.length; index++) {
                status[arr[index]] = click;
            }
        }else{
            status[value] = click;
        }
        var arrayValues = [];
        for (let key in status) {
            if(status[key]){
                arrayValues.push(key);
            }
        }
        object[filter] = arrayValues.join("|");
        this.filterStatus = status;
        this.objectFilter = object;
        this.filter(this.objectFilter);
    }

    @action
    handleSettlementStatus(filter, value, click){
        let object = this.objectFilter, status = this.filterSettlementStatus;        
        status[value] = click;
        var arrayValues = [];
        for (let key in status) {
            if(status[key]){
                arrayValues.push(key);
            }
        }        
        object[filter] = arrayValues.join("|");
        this.filterSettlementStatus = status;
        this.objectFilter = object;
        this.filter(this.objectFilter);        
    }

    @action
    handleFilterBillsStatus(filter, value, click){
        let object = this.objectFilter, status = this.filterBillsStatus;        
        status[value] = click;
        var arrayValues = [];
        for (let key in status) {
            if(status[key]){
                arrayValues.push(key);
            }
        }        
        object[filter] = arrayValues.join("|");
        this.filterBillsStatus = status;
        this.objectFilter = object;
        this.filter(this.objectFilter);
    }

    @action
    handleStatusPastDue(click){
        let object = this.objectFilter, status = this.filterStatus;
        status['overdue'] = click;
        status[1] = click;
        status[2] = click;
        var arrayValues = [];
        for (let key in status) {
            if(key !== 'overdue' && status[key]){
                arrayValues.push(key);
            }
        }        
        object['status(in)'] = arrayValues.join("|");
        if (status['overdue'] === true) {
            object['dueDate(le)'] = this.globalStore.stringDateFormatFilters(new Date());
        } else {
            delete status['overdue'];
            delete object['dueDate(le)'];
            this.reportsStore.clearFilters();
        }
        this.filterStatus = status; 
        this.objectFilter = object;
        this.filter(this.objectFilter);
    }

    @action
    handleRefunded(filter, value, click){        
        let object = this.objectFilter, status = this.filterRefunded;        
        status[value] = click;
        var arrayValues = [];
        for (let key in status) {
            if(status[key]){
                arrayValues.push(key);
            }
        }        
        object[filter] = arrayValues.join("|");
        this.filterRefunded = status;
        this.objectFilter = object;
        if (Object.keys(this.objectFilter).length > 0) {
            this.filter(this.objectFilter);
        }
    }

    @action
    handleReturned(filter, value, click){        
        let object = this.objectFilter, status = this.filterReturned;        
        status[value] = click;
        var arrayValues = [];
        for (let key in status) {
            if(status[key]){
                arrayValues.push(key);
            }
        }        
        object[filter] = arrayValues.join("|");
        this.filterReturned = status;
        this.objectFilter = object;
        if (Object.keys(this.objectFilter).length > 0) {
            this.filter(this.objectFilter);
        }
    }

    @action
    handleKeyDownByRange(state, filter, event){
        if (event.key === 'Enter' && event.target.value !== '') {
            let object = this.objectFilter, tempRangeNumber = this.rangeNumber, val = event.target.value.replace('$', '');
            object[filter] = val;
            tempRangeNumber[state] = val;
            this.rangeNumber = tempRangeNumber;
            this.objectFilter = object;
            this.filter(this.objectFilter);
        }
    }

    @action
    filterByRangeNumber(state, filter, values){
        let object = this.objectFilter, tempRangeNumber = this.rangeNumber;
        object[filter] = values.value;
        tempRangeNumber[state] = values.value;
        this.rangeNumber = tempRangeNumber;
        this.objectFilter = object;
    }

    @action
    filterByRangeNumberWithSlider(state, filter, value){
        let object = this.objectFilter, tempRangeNumber = this.rangeNumber;
        object[filter] = value;
        tempRangeNumber[state] = value;
        this.rangeNumber = tempRangeNumber;
        this.objectFilter = object;
    }

    @action
    filterByGreaterValue(states, filter, value){
        let object = this.objectFilter, tempRangeNumber = this.rangeNumber, arrayStates = states.split('|');
        if (object[`${filter}(ge)`] !== undefined) {
            delete object[`${filter}(ge)`];
        }
        if (object[`${filter}(le)`] !== undefined) {
            delete object[`${filter}(le)`];
        }
        object[`${filter}(ge)`] = value;
        tempRangeNumber[arrayStates[1]] = value;
        this.rangeNumber = tempRangeNumber;
        this.objectFilter = object;
        this.reportsStore.clearFilters();
    }
    
    @action
    handleBrand(state, filter, click){        
        let object = this.objectFilter, brand = this.filterBrand, methods = this.filterMethods;
        brand[state] = click;
        if(click && (methods['cash'] !== undefined || methods['check'] !== undefined)){
            delete methods['cash'];
            delete methods['check']
            this.filterMethods = methods;
            let arrayValuesMethods = [];
            for (let key in methods) {
                if(methods[key]){
                    arrayValuesMethods.push(key);
                }
            }
            object['method(in)'] = arrayValuesMethods.join("|");
        }
        let arrayValues = [];
        for (let key in brand) {
            if(brand[key]){
                arrayValues.push(key);
            }
        }
        object[filter] = arrayValues.join("|");
        this.filterBrand = brand;
        this.objectFilter = object;
        if (Object.keys(this.objectFilter).length > 0) {
            this.filter(this.objectFilter);
        }
    }

    @action
    handlePayMethod(state, filter, click){        
        let object = this.objectFilter, methods = this.filterMethods, brand = this.filterBrand;
        methods[state] = click;
        if(state === 'card'){
            if (click) {
                brand['visa'] = click;
                brand['mastercard'] = click;
                brand['discover'] = click;
                brand['amex'] = click;
                brand['jcb'] = click;                
            } else {
                delete brand['visa'];
                delete brand['mastercard'];
                delete brand['discover'];
                delete brand['amex'];
                delete brand['jcb'];
            }
        }
        if(state === 'ach'){
            if (click) {
                //brand['savings'] = click;
                //brand['checking'] = click;
                object['payaccountType(in)'] = 'savings|checking';
            } else {
                delete brand['savings'];
                delete brand['checking'];
            }
        }
        if(state === 'cash' || state === 'check'){
            brand = {};
            delete object['payaccountType(in)'];
        }
        //payaccountType
        let arrayValuesBrand = [];
        for (let key in brand) {
            if(brand[key]){
                arrayValuesBrand.push(key);
            }
        }
        object['payaccountType(in)'] = arrayValuesBrand.join("|");
        this.filterBrand = brand;
        //method
        let arrayValuesMethods = [];
        for (let key in methods) {
            if(methods[key]){
                arrayValuesMethods.push(key);
            }
        }
        object[filter] = arrayValuesMethods.join("|");
        this.filterMethods = methods;

        this.objectFilter = object;
        if (Object.keys(this.objectFilter).length > 0) {
            this.filter(this.objectFilter);
        }
    }

    @action
    handlePayMethodMo(state, filter, click){        
        let object = this.objectFilter, methods = this.filterMethods;
        methods[state] = click;
        var arrayValues = [];
        for (let key in methods) {
            if(methods[key]){
                arrayValues.push(key);
            }
        }
        object[filter] = arrayValues.join("|");
        this.filterMethods = methods;
        this.objectFilter = object;
        this.filter(this.objectFilter);
    }

    @action
    handleRadioOption(e, filter){
        let radioOption = {}, object = this.objectFilter;
        radioOption[e.target.id] = e.target.checked;
        object[filter] = e.target.value;
        this.filterRadioOption = radioOption;
        this.objectFilter = object;
        if (Object.keys(this.objectFilter).length > 0) {
            this.filter(this.objectFilter);
        }
    }

    @action
    handleRadioOptionCycle(e, filter){
        let radioOption = {}, object = this.objectFilter;
        radioOption[e.target.id] = e.target.checked;
        if (filter === 'scheduleId(eq)') {
            delete object['scheduleId(ne)'];
        } else {
            delete object['scheduleId(eq)'];
        }
        object[filter] = e.target.value;
        this.filterRadioOption = radioOption;
        this.objectFilter = object;
        if (Object.keys(this.objectFilter).length > 0) {
            this.reportsStore.clearFilters();
            this.filter(this.objectFilter);
        }
    }

    @action
    handleCheck(event, filter){
        let object = this.objectFilter, check = this.filterCheck;
        check[event.target.id] = event.target.checked;
        var arrayValues = [];
        for (let key in check) {
            if(check[key]){
                arrayValues.push(document.getElementById(key).value);
            }
        }        
        object[filter] = arrayValues.join("|");
        this.filterSource = check;
        this.objectFilter = object;
        if (Object.keys(this.objectFilter).length > 0) {
            this.filter(this.objectFilter);
        }
    }

    @action
    handleCheckUntilCancelled(event, filter){
        let object = this.objectFilter, check = this.filterCheck, tempFilterText = this.filterText;
        if (event.target.checked === true) {
            check[event.target.id] = event.target.checked;
            object[filter] = event.target.checked;
            delete tempFilterText['cycles']
            delete object['cycles(eq)'];
            this.filterText = tempFilterText;
        } else {
            delete check[event.target.id];
            delete object[filter];
        }
        this.reportsStore.clearFilters();
        this.filterSource = check;
        this.objectFilter = object;
        this.filter(this.objectFilter);
    }

    @action
    handleSource(event){
        let object = this.objectFilter, source = this.filterSource;
        if(event.target.value !== 'other'){  
            delete source['sourceCheckOther'];
            object['source(ct)'] = '';
            source[event.target.id] = event.target.checked;
            var arrayValues = [];
            for (let key in source) {
                if(source[key]){
                    arrayValues.push(document.getElementById(key).value);
                }
            }            
            object['source(in)'] = arrayValues.join("|");
            this.filterSource = source;
            this.objectFilter = object;
            if (Object.keys(this.objectFilter).length > 0) {
                this.filter(this.objectFilter);
            }
        }else{
            object['source(in)'] = '';
            source = {};
            source[event.target.id] = event.target.checked;
            this.filterSource = source;
            this.objectFilter = object;
        }
    }

    @action
    handlePaymentTerms(event){
        let object = this.objectFilter, paymentTerms = this.filterPaymentTerms;
        if(event.target.value !== 'custom'){    
            delete paymentTerms['paymentTermsCheckCustom'];
            object['payTerms(in)'] = '';
            paymentTerms[event.target.id] = event.target.checked;
            var arrayValues = [];
            for (let key in paymentTerms) {
                if(paymentTerms[key]){
                    arrayValues.push(document.getElementById(key).value);
                }
            }            
            object['payTerms(in)'] = arrayValues.join("|");
            this.filterPaymentTerms = paymentTerms;
            this.objectFilter = object;
        }else{
            object['payTerms(in)'] = '';
            paymentTerms = {};
            paymentTerms[event.target.id] = event.target.checked;
            this.filterPaymentTerms = paymentTerms;
            this.objectFilter = object;
        }
        this.filter(this.objectFilter);
    }

    getTextPaymentTerms(){
        let result = '', tempText = this.objectFilter['payTerms(in)'];        
        if (tempText) {
            result = tempText.replaceAll('|', ', ')
        }        
        return result;
    }

}

const filterStore = new FilterStore(globalStore, reportsStore);
export default filterStore;