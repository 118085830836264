import { observable, makeObservable, action, computed, toJS } from "mobx";
import axios from "axios";
import { PayabliStorageManager } from "../api/localStorageManager";
import payabliLogo from "../assets/images/payabli.png";

class PaymentPageStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  previewLogo = null;

  @observable
  saveAction = "new";

  @observable
  currentPage = "";

  @observable
  showPaymentPageNameError = false;

  @observable
  showPaymentPageSubdomainError = false;

  @observable
  paymentPageErrors = {
    paymentMethodsAchAccountHolderNameError: false,
  };
  @observable
  customFields = [];

  @observable
  paymentPages = [];

  maskValidator(validation) {
    switch (validation) {
      case "alpha":
        return /^[a-zA-Z\s]*$/;
      case "numbers":
        return Number;
      case "alphanumeric":
        return /^[a-z0-9]+$/i;
      case "text":
        return String;
      case "email":
        return /^([a-z0-9-\._]*)@?([a-z0-9-]*)\.?([a-z]*)$/;
      case "phone":
        return "(000) 000-0000";
      case "routing":
        return "000000000";
      case "cardDate":
        return "00/00";
      case "cvv":
        return "000";
      case "cvvamex":
        return "0000";
      case "zipcode":
        return "00000";
      case "creditCard":
        return "0000 0000 0000 0000";
      case "creditCardAmex":
        return "0000 0000 0000 0000";
      default:
        return String;
    }
  }

  getPaymentPageFields() {
    return [
      {
        name: "company",
        required: true,
        label: "Company Name",
        validation: "text",
        display: true,
        Fixed: false,
        order: 0,
        width: 12,
      },
      {
        name: "firstName",
        required: true,
        label: "First Name",
        validation: "text",
        display: true,
        Fixed: false,
        order: 1,
        width: 6,
      },
      {
        name: "lastName",
        required: true,
        label: "Last Name",
        validation: "text",
        display: true,
        Fixed: false,
        order: 2,
        width: 6,
      },
      {
        name: "billingEmail",
        required: true,
        label: "Email",
        validation: "email",
        display: true,
        order: 3,
        Fixed: false,
        width: 6,
      },
      {
        name: "billingPhone",
        required: true,
        label: "Primary Phone",
        validation: "phone",
        display: true,
        order: 4,
        Fixed: false,
        width: 6,
      },
      {
        name: "customerNumber",
        required: true,
        label: "Customer #",
        validation: "numbers",
        display: true,
        order: 5,
        Fixed: false,
        width: 6,
      },
      {
        name: "invoiceNumber",
        required: true,
        label: "Invoice #",
        validation: "numbers",
        display: true,
        order: 5,
        Fixed: false,
        width: 6,
      },
      {
        name: "billingAddress1",
        required: true,
        label: "Billing Address",
        validation: "address",
        display: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
        fixedValidation: true,
      },
      {
        name: "billingAddress2",
        required: true,
        label: "Billing Address 2",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },
      {
        name: "billingCity",
        required: true,
        label: "Billing City",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },

      {
        name: "billingState",
        required: true,
        label: "Billing State",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },
      {
        name: "billingZip",
        required: true,
        label: "Billing Zip",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },
      {
        name: "billingCountry",
        required: true,
        label: "Billing Country",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },

      {
        name: "shippingAddress1",
        required: true,
        label: "Shipping Address",
        validation: "address",
        display: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
        fixedValidation: true,
      },
      {
        name: "shippingAddress2",
        required: true,
        label: "Shipping Address 2",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },
      {
        name: "shippingCity",
        required: true,
        label: "Shipping City",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },
      {
        name: "shippingState",
        required: true,
        label: "Shipping State",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },
      {
        name: "shippingZip",
        required: true,
        label: "Shipping Zip",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },
      {
        name: "shippingCountry",
        required: true,
        label: "Shipping Country",
        validation: "text",
        display: "onlyData",
        onlyData: true,
        order: 6,
        Fixed: false,
        width: 6,
        identifier: false,
        value: "",
      },
    ];
  }

  @observable
  paymentPage = {
    subdomain: "",
    name: "",
    logo: {
      enabled: true,
      order: 0,
    },
    customCssUrl: "",
    page: {
      header: "Make a Payment",
      description: "Please enter your information below",
      enabled: true,
      order: 1,
    },
    amount: {
      categories: [
        {
          description: "",
          label: "Item Name",
          name: new Date().getTime().toString(),
          optionalPay: false,
          optionalPayChecked: false,
          order: 1,
          quantity: 1,
          showDescription: false,
          type: "customer",
          value: "0.00",
        },
      ],
      enabled: true,
      order: 2,
    },
    autopay: {
      header: "Payment Schedule",
      finish: {
        untilCancelled: false,
        calendar: true,
      },
      frequency: {
        onetime: true,
        weekly: true,
        every2Weeks: true,
        every6Months: true,
        monthly: true,
        every3Months: true,
        annually: true,
      },
      enabled: true,
      order: 3,
    },
    payor: {
      header: "Customer Information",
      enabled: true,
      order: 4,
      fields: this.getPaymentPageFields(),
    },
    paymentMethods: {
      header: "Payment Method",
      methods: {
        visa: true,
        mastercard: true,
        discover: true,
        amex: true,
        eCheck: true,
        applePay: true,
        googlePay: false,
        payPal: false,
        bitPay: false,
      },
      enabled: true,
      allMethodsChecked: true,
      order: 5,
    },
    notes: {
      header: "Notes",
      placeholder: "Please enter additional information...",
      enabled: true,
      value: "",
      order: 6,
    },
    review: {
      header: "Review & Confirm Payment",
      enabled: true,
      order: 7,
    },
    messageBeforePaying: {
      label:
        "Disclaimer - Please note your payment may take 1 - 3 days to post.",
      enabled: true,
      order: 8,
    },
    paymentButton: {
      label: "Pay",
      order: 9,
    },
    contactUs: {
      header: "Contact Us",
      emailLabel: "",
      phoneLabel: "",
      paymentIcons: true,
      enabled: true,
      order: 10,
    },
  };

  @observable
  applePayButton = {
    buttonType: "pay",
    buttonStyle: "black",
    language: "en-US",
  };

  @observable
  requiredCustomFields = false

  paymentPageTemplate = {
    subdomain: "",
    name: "",
    logo: {
      enabled: true,
      order: 0,
    },
    customCssUrl: "",
    page: {
      header: "Make a Payment",
      description: "Please enter your information below",
      enabled: true,
      order: 1,
    },
    amount: {
      categories: [
        {
          description: "",
          label: "Item Name",
          name: new Date().getTime().toString(),
          optionalPay: false,
          optionalPayChecked: false,
          order: 1,
          quantity: 1,
          showDescription: false,
          type: "customer",
          value: "0.00",
        },
      ],
      enabled: true,
      order: 2,
    },
    autopay: {
      header: "Payment Schedule",
      finish: {
        untilCancelled: false,
        calendar: true,
      },
      frequency: {
        onetime: true,
        weekly: true,
        every2Weeks: true,
        every6Months: true,
        monthly: true,
        every3Months: true,
        annually: true,
      },
      enabled: true,
      order: 3,
    },
    paymentMethods: {
      header: "Payment Method",
      methods: {
        visa: true,
        mastercard: true,
        discover: true,
        amex: true,
        eCheck: true,
        applePay: true,
        googlePay: false,
        payPal: false,
        bitPay: false,
      },
      enabled: true,
      allMethodsChecked: true,
      order: 5,
    },
    notes: {
      header: "Notes",
      placeholder: "Please enter additional information...",
      enabled: true,
      value: "",
      order: 6,
    },
    messageBeforePaying: {
      label:
        "Disclaimer - Please note your payment may take 1 - 3 days to post.",
      enabled: true,
      order: 8,
    },
    paymentButton: {
      label: "Pay",
      order: 9,
    },
    contactUs: {
      header: "Contact Us",
      emailLabel: "",
      phoneLabel: "",
      paymentIcons: true,
      enabled: true,
      order: 10,
    },
    payor: {
      header: "Customer Information",
      enabled: true,
      order: 4,
      fields: this.getPaymentPageFields(),
    },
    review: {
      header: "Review & Confirm Payment",
      enabled: true,
      order: 7,
    },
  };

  paymentReceiptTemplate = {
    logo: {
      enabled: true,
      order: 0,
    },
    page: {
      header: "Payment Receipt",
      description: "Thank you for your payment",
      enabled: true,
      order: 1,
    },
    amount: {
      enabled: true,
      order: 2,
    },
    settings: {
      sendAuto: true,
      sendManual: true,
      enabled: true,
      order: 3,
      fields: this.getPaymentPageFields(),
    },
    details: {
      enabled: true,
      order: 4,
    },
    paymentInformation: {
      enabled: true,
      order: 5,
    },
    messageBeforeButton: {
      label: "",
      enabled: true,
      order: 6,
    },
    paymentButton: {
      enabled: true,
      order: 7,
      label: "Go back to Payment Page",
    },
    contactUs: {
      enabled: true,
      order: 8,
    },
  };

  paymentPageSettingsTemplate = {
    color: "#10A0E3",
    paymentButton: {
      label: "Pay Now",
      size: "sm",
    },
    customCssUrl: "",
    language: "en",
    customUrl: "",
    pageLogo: {
      ftype: "",
      filename: null,
      furl: null,
      fContent: "",
    },
    redirectAfterApprove: false,
    redirectAfterApproveUrl: "",
    notificationEmail: "",
    allApprovedPaymentNotification: false,
    dailyBatchReportNotification: false,
  };

  @observable
  paymentReceipt = {
    logo: {
      enabled: true,
      order: 0,
    },
    page: {
      header: "Payment Receipt",
      description: "Thank you for your payment",
      enabled: true,
      order: 1,
    },
    amount: {
      enabled: true,
      order: 2,
    },
    settings: {
      sendAuto: true,
      sendManual: true,
      enabled: true,
      order: 3,
      fields: this.getPaymentPageFields(),
    },
    details: {
      enabled: true,
      order: 4,
    },
    paymentInformation: {
      enabled: true,
      order: 5,
    },
    messageBeforeButton: {
      label: "Thank you for your payment.",
      enabled: true,
      order: 6,
    },
    paymentButton: {
      enabled: true,
      order: 7,
      label: "Go back to Payment Page",
    },
    contactUs: {
      enabled: true,
      order: 8,
    },
  };

  @observable
  paymentPageSettings = {
    color: "#10A0E3",
    paymentButton: {
      label: "Pay Now",
      size: "sm",
    },
    customCssUrl: "",
    language: "en",
    customUrl: "",
    pageLogo: {
      ftype: "",
      filename: null,
      furl: null,
      fContent: "",
    },
    redirectAfterApprove: false,
    redirectAfterApproveUrl: "",
    notificationEmail: "",
    allApprovedPaymentNotification: false,
    dailyBatchReportNotification: false,
  };

  @computed
  get embeddedButtonLabel() {
    return this.paymentPageSettings.paymentButton.label
      ? this.paymentPageSettings.paymentButton.label
      : "Pay Now";
  }

  @computed
  get hasCards() {
    return (
      this.paymentPage.paymentMethods.methods.visa ||
      this.paymentPage.paymentMethods.methods.mastercard ||
      this.paymentPage.paymentMethods.methods.discover ||
      this.paymentPage.paymentMethods.methods.amex
    );
  }

  @computed
  get payerFields() {
    return this.paymentPage.payor.fields;
  }

  @computed
  get getPaymentPageErrors() {
    return this.paymentPageErrors;
  }

  @computed
  get paymentPageColor() {
    return this.paymentPageSettings.color;
  }

  @computed
  get amountCategories() {
    return this.paymentPage.amount.categories;
  }

  @computed
  get hasECheck() {
    return this.paymentPage.paymentMethods.methods.eCheck;
  }

  @computed
  get hasWallets() {
    return this.paymentPage.paymentMethods.methods.applePay;
  }

  @action
  handleTextChange(block, key, event) {
    this.paymentPage[block][key] = event.target.value;
  }

  @action
  handleReceiptTextChange(block, key, event) {
    this.paymentReceipt[block][key] = event.target.value;
  }

  @action
  setPPSettingsColor(color) {
    this.paymentPageSettings.color = color;
  }

  @action
  setCurrentPage(page) {
    this.currentPage = page;
  }

  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  @action
  handlePPSettingsPaymentButtonTextChange(event) {
    this.paymentPageSettings.paymentButton.label = event.target.value;
  }

  @action
  handlePPSettingsTextChange(key, value) {
    this.paymentPageSettings[key] = value;
  }

  @action
  handlePageNameChange(event) {
    var value = event.target.value;
    this.paymentPage.name = value;
  }

  @action
  setPageName(name) {
    this.paymentPage.name = name;
  }

  @action
  setPageSubdomain(subdomain) {
    this.paymentPage.subdomain = subdomain;
  }

  @action
  setPreviewLogo(base64) {
    this.previewLogo = base64;
  }

  @action
  setLogo(base64, ext) {
    if (base64 && ext) {
      this.paymentPageSettings.pageLogo = {
        ftype: ext,
        filename: null,
        furl: null,
        fContent: base64,
      };
    }
  }

  @action
  setDefaultLogo(pageLogo) {
    if (pageLogo) {
      this.paymentPageSettings.pageLogo = pageLogo;
    }
  }

  @computed
  get getPageName() {
    return this.paymentPage.name;
  }

  @action
  handleCategoryTextChange(field, key, event) {
    this.paymentPage.amount.categories[field][key] = event.target.value;
  }

  @action
  setSaveAction(action) {
    this.saveAction = action;
  }

  @action
  handleCategoryTextChangeMask(field, key, value) {
    this.paymentPage.amount.categories[field][key] = value;
  }

  @action
  testDataJson() {
    return toJS(this.paymentPage);
  }

  @action
  handlePayorfieldTextChange(event) {
    this.paymentPage.payor.fields[event.target.id].label = event.target.value;
  }

  @action
  setPayorIdentifier(name, value) {
    let fields = this.paymentPage.payor.fields;
    fields.forEach(function (item, index) {
      if (item.name === "additional_" + name || item.name === name) {
        item.identifier = value ? value : false;
      }
    });
  }

  @action
  disableTitle(checked) {
    this.paymentPage.page.enabled = checked;
  }

  @action
  disableContactUs(checked) {
    this.paymentPage.contactUs.enabled = checked;
  }

  @action
  disableReceiptSettings(checked) {
    this.paymentReceipt.settings.enabled = checked;
  }

  @action
  disableReceiptContactUs(checked) {
    this.paymentReceipt.contactUs.enabled = checked;
  }

  @action
  disableReview(checked) {
    this.paymentPage.review.enabled = checked;
  }

  @action
  disableLogo(checked) {
    this.paymentPage.logo.enabled = checked;
  }

  @action
  disableReceiptLogo(checked) {
    this.paymentReceipt.logo.enabled = checked;
  }

  @action
  disableNotes(checked) {
    this.paymentPage.notes.enabled = checked;
  }

  @action
  disableMessageBeforePaying(checked) {
    this.paymentPage.messageBeforePaying.enabled = checked;
  }

  @action
  disableMessageBeforeButton(checked) {
    this.paymentReceipt.messageBeforeButton.enabled = checked;
  }

  @action
  disableReceiptTitle(checked) {
    this.paymentReceipt.page.enabled = checked;
  }

  @action
  disableAutopay(checked) {
    this.paymentPage.autopay.enabled = checked;
  }

  @action
  disablePayer(checked) {
    this.paymentPage.payor.enabled = checked;
  }

  @action
  handleAutopayFrequency(event) {
    this.paymentPage.autopay.frequency[event.target.id] =
      !this.paymentPage.autopay.frequency[event.target.id];
  }

  @action
  handleContactUsPaymentIcons() {
    this.paymentPage.contactUs.paymentIcons =
      !this.paymentPage.contactUs.paymentIcons;
  }

  @action
  handlePaymentMethods(event) {
    this.paymentPage.paymentMethods.methods[event.target.id] =
      !this.paymentPage.paymentMethods.methods[event.target.id];
  }

  @action
  setPaymentMethods(pMethods) {
    //temporary
    this.paymentPage.paymentMethods.methods.visa = pMethods.visa;
    this.paymentPage.paymentMethods.methods.mastercard = pMethods.mastercard;
    this.paymentPage.paymentMethods.methods.discover = pMethods.discover;
    this.paymentPage.paymentMethods.methods.amex = pMethods.amex;
    this.paymentPage.paymentMethods.methods.eCheck = pMethods.eCheck;
    this.paymentPage.paymentMethods.methods.applePay = pMethods.applePay;
    this.paymentPage.paymentMethods.methods.googlePay = true;
    this.paymentPage.paymentMethods.methods.payPal = true;
    this.paymentPage.paymentMethods.methods.bitPay = true;
  }

  @action
  setApplePayButton(updates) {
    this.applePayButton = {
        ...this.applePayButton,
        ...updates
    };
  }

  @action
  setRequiredCustomFields(required) {
    this.requiredCustomFields = required;
  }

  @action
  handleReceiptSettings(event) {
    var field = event.target.id.replace("settingField", "");
    this.paymentReceipt.settings.fields[field].display =
      !this.paymentReceipt.settings.fields[field].display;
    if (this.getSaveAction !== "new") this.setSaveAction("fieldsChange");
  }

  @action
  handleReceiptCustomFields(event) {
    var field = event.target.id.replace("customFields", "");
    this.customFields[field].display = !this.customFields[field].display;
  }

  @action
  handlePPSettingsPaymentButtonSize(event) {
    this.paymentPageSettings.paymentButton.size = event.target.value;
  }

  @action
  handlePayorFieldsChecked(event) {
    var field = event.target.id.replace("payerFieldRequired", "");
    this.paymentPage.payor.fields[field].required =
      !this.paymentPage.payor.fields[field].required;

    if (this.paymentPage.payor.fields[field].required) {
      this.paymentPage.payor.fields[field].display = true;
    }
  }

  @action
  handlePayorFieldsIdentifier(event) {
    var field = event.target.id.replace("payerFieldIdentifier", "");
    this.paymentPage.payor.fields[field].identifier =
      !this.paymentPage.payor.fields[field].identifier;

    if (this.paymentPage.payor.fields[field].identifier) {
      this.paymentPage.payor.fields[field].required = true;
      this.paymentPage.payor.fields[field].display = true;
    }
  }

  @action
  handleCategoryShowDescription(event) {
    var category = event.target.id.replace(
      "amountCategoryCheckDescription",
      ""
    );
    this.paymentPage.amount.categories[category].showDescription =
      !this.paymentPage.amount.categories[category].showDescription;
  }

  @action
  handleRedirectAfterApprove(event) {
    this.paymentPageSettings.redirectAfterApprove = event.target.checked;
  }

  @action
  handleCategoryOptionalPay(event) {
    var category = event.target.id.replace("amountCategoryCheckOptional", "");
    this.paymentPage.amount.categories[category].optionalPay =
      !this.paymentPage.amount.categories[category].optionalPay;
  }

  @action
  handlePayorFieldsWidth(event) {
    var field = event.target.id.replace("payerFieldWidth", "");
    this.paymentPage.payor.fields[field].width = parseInt(event.target.value);
  }

  @action
  handlePayorFieldsValidation(event) {
    var field = event.target.id.replace("payerFieldValidation", "");
    this.paymentPage.payor.fields[field].validation = event.target.value;
  }

  @action
  removePayorfield(event) {
    var idRemove = event.target.id.replace("payerFieldRemove", "");
    this.paymentPage.payor.fields.splice(idRemove, 1);
  }

  @action
  removeAmountCategory(event) {
    var idRemove = event.target.id.replace("categoryRemove", "");
    this.paymentPage.amount.categories.splice(idRemove, 1);
  }

  @action
  handleAutopayFinish(event) {
    this.paymentPage.autopay.finish.calendar = false;
    this.paymentPage.autopay.finish.untilCancelled = false;
    this.paymentPage.autopay.finish[event.target.id] = true;
  }

  @action
  handleReceiptSend(event) {
    this.paymentReceipt.settings.sendAuto = false;
    this.paymentReceipt.settings.sendManual = false;
    this.paymentReceipt.settings[event.target.id] = true;
  }

  @computed
  get getPaymentReceiptSettingsFields() {
    return this.paymentReceipt.settings.fields;
  }

  @action
  handleAmountCategoryType(event) {
    var category = event.target.id.replace("amountCategoryTypeQuantity", "");
    category = category.replace("amountCategoryTypeFixed", "");
    category = category.replace("amountCategoryTypeCustomer", "");
    this.paymentPage.amount.categories[category].type = event.target.value;
  }

  @action
  handleCategoryQtySubstract(event) {
    var category = event.target.id.replace("amountCategoryQtySubstract", "");
    if (this.paymentPage.amount.categories[category].quantity > 1)
      this.paymentPage.amount.categories[category].quantity--;
  }

  @action
  handleCategoryQtyAdd(event) {
    var category = event.target.id.replace("amountCategoryQtyAdd", "");
    this.paymentPage.amount.categories[category].quantity++;
  }

  @action
  handleCategoryQtyInput(event) {
    var category = event.target.id.replace("amountCategoryQtyInput", "");
    event.target.value > 1
      ? (this.paymentPage.amount.categories[category].quantity =
          event.target.value)
      : (this.paymentPage.amount.categories[category].quantity = 1);
  }

  @action
  handleAllPaymentMethods() {
    var checked = !this.paymentPage.paymentMethods.allMethodsChecked;
    this.paymentPage.paymentMethods.allMethodsChecked = checked;

    this.paymentPage.paymentMethods.methods.visa = checked;
    this.paymentPage.paymentMethods.methods.mastercard = checked;
    this.paymentPage.paymentMethods.methods.discover = checked;
    this.paymentPage.paymentMethods.methods.amex = checked;
    this.paymentPage.paymentMethods.methods.eCheck = checked;
    this.paymentPage.paymentMethods.methods.applePay = checked;
    this.paymentPage.paymentMethods.methods.googlePay = checked;
    this.paymentPage.paymentMethods.methods.payPal = checked;
    this.paymentPage.paymentMethods.methods.bitPay = checked;
  }

  @action
  displayField(checked, event, field) {
    if (checked === false) {
      this.paymentPage.payor.fields[field].required = false;
    }
    this.paymentPage.payor.fields[field].display = checked;
    this.paymentReceipt.settings.fields[field].display = checked;
    if (this.getSaveAction !== "new") this.setSaveAction("fieldsChange");
  }

  @action
  updateBlocksOrder(updateBlocksOrder) {
    var paymentPage = this.paymentPage;
    updateBlocksOrder.forEach(function (item, index) {
      var block = item.replace("block", "");
      if (paymentPage[block]) {
        paymentPage[block].order = index;
      }
    });
  }

  @action
  updateReceiptBlocksOrder(updateBlocksOrder) {
    var paymentReceipt = this.paymentReceipt;
    updateBlocksOrder.forEach(function (item, index) {
      var block = item.replace("block", "");
      if (paymentReceipt[block]) {
        paymentReceipt[block].order = index;
      }
    });
  }

  @action
  updateCategoryBlocksOrder(updateCategoryBlocksOrder) {
    var paymentPage = this.paymentPage;

    updateCategoryBlocksOrder.forEach(function (item, index) {
      var block = item.replace("blockcategory", "");
      paymentPage.amount.categories[block].order = index;
    });
  }

  @action
  updatePayerFieldsOrder(idsInOrder) {
    var paymentPage = this.paymentPage;
    idsInOrder.forEach(function (item, index) {
      var field = item.replace("payerConfig", "");
      paymentPage.payor.fields[field].order = index;
    });
  }
  @action
  updateCustomFieldsOrder(idsInOrder) {
    var thisStore = this;
    idsInOrder.forEach(function (item, index) {
      var field = item.replace("customConfig", "");
      thisStore.customFields[field].order = index;
    });
  }

  @action
  addNewPayorField() {
    var fieldId = Date.now();
    var newField = {
      name: "misc_" + String(fieldId),
      required: false,
      label: "Item name",
      validation: "",
      display: true,
      Fixed: false,
      order: this.getFieldOrder(this.paymentPage.payor.fields),
      width: 12,
      identifier: false,
      value: "",
    };
    this.paymentPage.payor.fields.push(newField);
  }

  @action
  addAdditionalField(field, identifier, display = true) {
    let name = field.key.split(" ").join("_");
    var newField = {
      name: `additional_${name}`,
      required: true,
      label: field.key,
      validation: "text",
      display: display,
      Fixed: field.readOnly ? field.readOnly : false,
      order: this.getFieldOrder(this.paymentPage.payor.fields),
      width: 12,
      identifier: identifier ? identifier : false,
      value: "",
    };
    this.paymentPage.payor.fields.push(newField);
    this.paymentReceipt.settings.fields.push(newField);
    this.customFields.push(newField);
  }

  getFieldOrder(items) {
    var max = 0;
    items.forEach(function (item, index) {
      if (parseInt(item.order) > parseInt(max)) {
        max = parseInt(item.order);
      }
    });
    return max + 1;
  }

  @action
  newPaymentPageData() {
    this.setPageName("");
    this.paymentPage = this.paymentPageTemplate;
    this.paymentReceipt = this.paymentReceiptTemplate;
    this.paymentPageSettings = this.paymentPageSettingsTemplate;
  }

  @action
  addNewAmountCategoryField() {
    var fieldId = Date.now();
    var newField = {
      name: String(fieldId),
      type: "customer",
      label: "Item Name",
      value: "0.00",
      description: "",
      order: this.getFieldOrder(this.paymentPage.amount.categories),
      quantity: 1,
      showDescription: false,
      optionalPay: false,
      optionalPayChecked: false,
    };

    this.paymentPage.amount.categories.push(newField);
  }

  @action
  getPaymentPagesFromApi() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    ).pEntry
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .get(process.env.REACT_APP_URL_API + "Paypoint/" + entryPoint, {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      })
      .then((res) => {
        this.setPaymentPages(res.data.responseData.EntryPages);
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  normalizePayorFields() {
    let fields = this.paymentPage.payor.fields;

    fields.forEach(function (item, index) {
      delete item.identifier;
      //normalizing field name
      switch (item.name) {
        case "companyName":
          item.name = "company";
          break;
        case "email":
          item.name = "billingEmail";
          break;
        case "primaryPhone":
          item.name = "billingPhone";
          break;
        default:
          break;
      }
    });
  }
  @action
  normalizePayorFieldsSave() {
    let fields = this.paymentPage.payor.fields;

    fields.forEach(function (item, index) {
      delete item.identifier;
      delete item.fixedValidation;
      delete item.value;
      if (item.display === "onlyData") {
        item.display = false;
      }
    });
  }

  normalizeBase64(string) {
    let stringArray = string.split(",");
    if (stringArray[1]) {
      return stringArray[1];
    } else {
      return string;
    }
  }

  normalizeSaveSettingsFields() {
    let settings = this.paymentReceipt.settings;
    let fields = [];
    (settings?.fields || []).forEach(function (item) {
      const { display, Fixed, label, name } = item;
      fields.push({ name: name, label: label, display, fixed: Fixed });
    });
    fields.forEach(function (item) {
      if (item.display === "onlyData") {
        item.display = false;
      }
    });
    this.paymentReceipt.settings.fields = fields;
  }

  replicatePaymentPage(pPage) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .post(
        process.env.REACT_APP_URL_API + "Paypoint/" + entryPoint,
        {
          subdomain:
            pPage.Subdomain +
            "_" +
            (Math.random() + 1).toString(36).substring(2),
          PageContent: toJS(pPage.PageContent),
          ReceiptContent: toJS(pPage.ReceiptContent),
          PageSettings: toJS(pPage.PageSettings),
          AdditionalData: toJS(pPage.AdditionalData),
        },
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {})
      .catch((error) => {
        throw error;
      });
  }

  @action
  savePaymentPage() {
    if (this.paymentPageSettings.pageLogo?.ftype === "") {
      this.setDefaultLogo({
        ftype: this.getPaymentPageLogo.ftype,
        filename: null,
        furl: this.getPaymentPageLogo.furl,
        fContent: "",
      });
    }
    this.normalizePayorFieldsSave();
    this.normalizeSaveSettingsFields();
    let additionalData = {
      applepay_buttonType : this.applePayButton.buttonType,
      applepay_buttonStyle : this.applePayButton.buttonStyle,
      applepay_language : this.applePayButton.language,
    };
    if (this.getSaveAction === "new") {
      let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
      let entryPoint = encryptStorage.getItem(
        `${PayabliStorageManager.getEntryName()}_pEntry`
      )
        ? encryptStorage.getItem(
            `${PayabliStorageManager.getEntryName()}_pEntry`
          ).pEntry
        : "";

      return axios
        .post(
          process.env.REACT_APP_URL_API + "Paypoint/" + entryPoint,
          {
            subdomain: this.paymentPage.subdomain,
            PageContent: toJS(this.paymentPage),
            ReceiptContent: toJS(this.paymentReceipt),
            PageSettings: toJS(this.paymentPageSettings),
            AdditionalData: toJS(additionalData),
          },
          {
            headers: { requestToken: encryptStorage.getItem("pToken") },
          }
        )
        .then((res) => {})
        .catch((error) => {
          throw error;
        });
    } else if (
      this.getSaveAction === "edit" ||
      this.getSaveAction === "fieldsChange"
    ) {
      let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
      let entryPoint = encryptStorage.getItem(
        `${PayabliStorageManager.getEntryName()}_pEntry`
      )
        ? encryptStorage.getItem(
            `${PayabliStorageManager.getEntryName()}_pEntry`
          ).pEntry
        : "";

      return axios
        .put(
          process.env.REACT_APP_URL_API +
            "Paypoint/" +
            entryPoint +
            "/" +
            this.paymentPage.subdomain,
          {
            subdomain: this.paymentPage.subdomain,
            PageContent: toJS(this.paymentPage),
            ReceiptContent: toJS(this.paymentReceipt),
            PageSettings: toJS(this.paymentPageSettings),
            AdditionalData: toJS(additionalData),
          },
          {
            headers: { requestToken: encryptStorage.getItem("pToken") },
          }
        )
        .then((res) => {})
        .catch((error) => {
          throw error;
        });
    }
  }

  @action
  deletePaymentPage(sudomain) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .delete(
        process.env.REACT_APP_URL_API +
          "Paypoint/" +
          entryPoint +
          "/" +
          sudomain,
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        var paymentPages = this.paymentPages;
        paymentPages.forEach(function (item, index) {
          if (item.Subdomain === sudomain) {
            paymentPages.splice(index, 1);
            return;
          }
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  setPaymentPages(EntryPages) {
    this.paymentPages = EntryPages;
  }

  @action
  setPaymentPage(paymentPage) {
    this.paymentPage = paymentPage;
  }

  @action
  setPaymentReceipt(paymentReceipt) {
    this.paymentReceipt = paymentReceipt
  }

  @action
  setPaymentPageSubdomain(subdomain) {
    this.paymentPage.subdomain = subdomain;
  }

  @computed
  get getPaymentPageSubdomain() {
    return this.paymentPage.subdomain;
  }

  @action
  setPaymentSettings(pageSettings) {
    this.paymentPageSettings = pageSettings;
  }

  @action
  getPaymentPageFromApi(subdomain) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .get(
        process.env.REACT_APP_URL_API +
          "Paypoint/" +
          entryPoint +
          "/" +
          subdomain,
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        this.setPaymentPage(res.data.responseData.PageContent);
        this.setPaymentSettings(res.data.responseData.PageSettings);
        this.setPaymentReceipt(res.data.responseData.ReceiptContent);
        this.setPaymentPageSubdomain(res.data.responseData.Subdomain);
        let applePayButton = {
          buttonType: res.data.responseData.AdditionalData.applepay_buttonType || 'pay',
          buttonStyle: res.data.responseData.AdditionalData.applepay_buttonStyle || 'black',
          language: res.data.responseData.AdditionalData.applepay_language || 'en-US',
        };
        this.setApplePayButton({
          ...this.applePayButton,
          ...applePayButton 
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  @computed
  get getPaymentPages() {
    return this.paymentPages;
  }

  @computed
  get getShowPaymentPageNameError() {
    return this.showPaymentPageNameError;
  }

  @computed
  get getShowPaymentPageSubdomainError() {
    return this.showPaymentPageSubdomainError;
  }

  @action
  setShowPaymentPageNameError(status) {
    this.showPaymentPageNameError = status;
  }

  @action
  setShowPaymentPageSubdomainError(status) {
    this.showPaymentPageSubdomainError = status;
  }

  @computed
  get isAutopay() {
    return this.paymentPage.autopay.enabled;
  }

  @computed
  get getSaveAction() {
    return this.saveAction;
  }

  @computed
  get getPaymentPageLogo() {
    let urlLogo = "";
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    const urlEntry = encryptStorage?.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";

    if (this.previewLogo) {
      urlLogo = this.previewLogo;
    } else {
      urlLogo = this.paymentPageSettings.pageLogo?.furl
        ? this.paymentPageSettings.pageLogo.furl
        : encryptStorage?.getItem(`${urlEntry}_pImg`)
        ? encryptStorage?.getItem(`${urlEntry}_pImg`)
        : payabliLogo;
      if (
        this.paymentPageSettings.pageLogo?.furl &&
        (this.paymentPageSettings.pageLogo.furl.startsWith("http://") ||
          this.paymentPageSettings.pageLogo.furl.startsWith("https://"))
      ) {
        urlLogo = this.paymentPageSettings.pageLogo.furl;
      }
    }

    if (typeof urlLogo === "object" && Object.keys(urlLogo).length === 0) {
      urlLogo = null;
    }

    let type = "png";
    if (typeof urlLogo === "string") {
      let urlLogoArray = urlLogo.split(".");
      if (Array.isArray(urlLogoArray) && urlLogoArray.length > 1) {
        type = urlLogoArray.pop();
      }
    }
    return { furl: urlLogo, ftype: type };
  }
}
const paymentPageStore = new PaymentPageStore();
export default paymentPageStore;
