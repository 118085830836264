import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BiUserX, BiUserMinus, BiUserCheck } from 'react-icons/bi';
import { inject, observer } from 'mobx-react';
import {toast} from 'react-toastify';

@inject('store', 'global', 'reports', 'customer')
@observer
class ModalChangeStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStatus: props.reports.getCustomerTextStatus(this.props.activeRecord?.customerStatus)
    }
    this.handlerCloseModal = this.handlerCloseModal.bind(this);
    this.handlerStatusChange = this.handlerStatusChange.bind(this);
    this.saveCustomerStatus = this.saveCustomerStatus.bind(this);
  }
  saveCustomerStatus(e) {
    const customer = {
      id: this.props.activeRecord.customerId,
      customerStatus: this.state.currentStatus === 'Inactive' ? 0 : this.state.currentStatus === 'Active' ? 1 : this.state.currentStatus === 'Locked' ? 58 : -1
    }
    this.props.customer.updateCustomerStatus(customer)
        .then(result => {

            // calling parent functionCallback after saving
            this.handlerCloseModal();
            if(this.props.functionCallback){
                this.props.functionCallback();
            }

            // this.props.global.setLoading(false);
            toast.success("Customer Status was updated successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container'
            });
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
  }
  componentDidMount() {
    const currentStatus = this.props.reports.getCustomerTextStatus(this.props.activeRecord?.customerStatus);
    this.setState({
      currentStatus: currentStatus === 'Unknown' ? '' : currentStatus
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const currentStatus = this.props.reports.getCustomerTextStatus(this.props.activeRecord?.customerStatus);
    let allStates = [prevState.currentStatus, currentStatus, this.state.currentStatus];
    const setStates = new Set(allStates);
    if(prevState.currentStatus !== currentStatus && (setStates.size !== allStates.length)) {
      this.setState({
        currentStatus: currentStatus
      });
    }
  }
  handlerCloseModal(e){
    let self = this;
    this.setState({currentStatus: ''}, () => {
        self.props.closeModal();
      });
    ;
  }
  handlerStatusChange(e, status){
    this.setState({
      currentStatus: status,
    })
  }
  render() {
    const name = `${this.props.activeRecord?.Firstname}  ${this.props.activeRecord?.Lastname}`;
    const currentStatus = this.props.reports.getCustomerTextStatus(this.props.activeRecord?.customerStatus);
    const currentStatusState = this.state.currentStatus !== 'Unknow' ? (this.state.currentStatus || currentStatus) : currentStatus;
    return (
      <>
        <Modal
          style={{ textAlign: 'center' }}
          show={this.props.isOpen}
          onHide={this.props.closeModal}
          dialogClassName="modal-status-width"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><h6 style={{paddingLeft: '20px'}}>Change Status</h6></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{padding: '20px 36px 0 36px'}}>
            <div className='data-customer-status'>
              <div className="subheader-status">
                <span className="body-header blue-text">Select the new status</span>
                <span className='body-header-right'>Customer: {name}</span>
              </div>
              <div className="customer-status">
                <div onClick={(e) => this.handlerStatusChange(e, 'Active')}>
                  <div className={currentStatusState === "Active" ? "check-active"  : "check"}>
                    <BiUserCheck color='#9CCC65' size={70}/>     
                    <div className="solicitada">{"Active"}</div>
                  </div>
                </div>
                <div onClick={(e) => this.handlerStatusChange(e, 'Inactive')}>
                  <div className={currentStatusState === "Inactive" ? "check-active"  : "check"}>
                    <BiUserMinus color='#FFA726' size={70}/>
                    <div className="solicitada">{"Inactive"}</div>
                  </div>
                </div>
                <div onClick={(e) => this.handlerStatusChange(e, 'Locked')}>
                  <div className={currentStatusState === "Locked" ? "check-active"  : "check"}>
                    <BiUserX color='#FF867C' size={70}/>
                    <div className="solicitada">{"Locked"}</div>
                  </div>
                </div>
              </div>
              <div className="customer-current-status mt-2">
              <div className="current-status-main">
                <div className="current-status-main-inner">
                  <div className="current-status poppins-12">Current Status:</div>
                  <div className="setup">
                    {currentStatus === 'Active' &&
                      <BiUserCheck color='#9CCC65' size={20}/>
                    }
                    {currentStatus === 'Inactive' &&
                      <BiUserMinus color='#FFA726' size={20}/>
                    }
                    {currentStatus === 'Locked' &&
                      <BiUserX color='#FF867C' size={20}/>
                    }   
                    <div className="poppins-12">{currentStatus}</div>
                  </div>
                </div>
                <p className="poppins-10 dark-grey">LAST CHANGED ON {new Date(this.props.activeRecord?.LastUpdated).toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric' }) || ""}</p>
              </div>
              </div>
            </div>     
            <div className='btns-footer'>
              <Button
                className="btn full-w btn-light"
                variant="light"
                onClick={(e) => this.handlerCloseModal(e)}
              >
                Cancel
              </Button>
              <Button
                className="btn full-w btn-light"
                variant="primary"
                onClick={(e) => this.saveCustomerStatus(e)}
              >
                SAVE
              </Button>
            </div>       
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export { ModalChangeStatus };
