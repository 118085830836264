import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiHomeAlt , BiPlus, BiGroup , BiHelpCircle, 
        BiCheckSquare, BiBullseye, BiIdCard, BiCopy, BiCodeBlock, BiMoney, BiCog, BiFile, BiShoppingBag, BiChevronDown} 
        from "react-icons/bi";
import payabliIcon from '../assets/images/payabli-icon.png';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PayabliStorageManager } from '../api/localStorageManager'
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { HiMenuAlt2 } from 'react-icons/hi';
import { IdleTimeOutModal } from '../components/IdleTimeOutModal';
import RSC from "react-scrollbars-custom";
import {BiReceipt} from "@react-icons/all-files/bi/BiReceipt";
import moneyin from '../assets/images/moneyin.svg';
import moneyout from '../assets/images/moneyout.svg';
import { SecurityManager } from '../api/securityManager';
import { IddleManager } from '../api/iddleManager';
import { Brand } from './Brand';
import md5 from 'crypto-js/md5';
import Lottie from 'react-lottie';
import LoadingApplication from '../assets/lotties/LoadingSquaredApplication.json';
import * as Sentry from "@sentry/react";

@inject('store', 'global', 'user')
@observer
class Layout extends React.Component {

    constructor(props) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        super(props);
        this.state = {
            openMenu: encryptStorage && encryptStorage.getItem("pMenu")==="1" ? false : true,
            fullBody: encryptStorage && encryptStorage.getItem("pMenu")==="1" ? true : false,
            selected:'',
            menuPortfolioActiveKey: "closed",
            menuReportsActiveKey: "closed",
            menuReportsMoneyOutActiveKey: "closed",
            menuReportsDevelopersActiveKey: "closed",
            menuRiskControlCenterActiveKey: "closed",
            menuRiskControlCenterReportActiveKey: "closed",
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            intervalId:null
        };
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleMainMenu = this.handleMainMenu.bind(this);
        this.prevent = this.prevent.bind(this);
        this.handlePortfolioAccordion = this.handlePortfolioAccordion.bind(this);
        this.handleReportsAccordion = this.handleReportsAccordion.bind(this);
        this.handleReportsMoneyOutAccordion = this.handleReportsMoneyOutAccordion.bind(this);
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.handleTimeOutKey = this.handleTimeOutKey.bind(this)
        this.displayIddleModal = this.displayIddleModal.bind(this)
        this.handleReportsDevelopersAccordion = this.handleReportsDevelopersAccordion.bind(this);
        this.handleRiskControlCenterAccordion = this.handleRiskControlCenterAccordion.bind(this);
        this.handleRiskControlCenterReportAccordion = this.handleRiskControlCenterReportAccordion.bind(this);
        this.getHashUserEntry = this.getHashUserEntry.bind(this)
    }

    displayIddleModal(status, countDown){
        this.setState({ showModal: status , countDown: countDown})
    }

    handleMainMenu(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        if(window.innerWidth < 960){
            this.setState({
                openMenu: !this.state.openMenu,
            });
        }
        else{
            encryptStorage.setItem('pMenu', this.state.openMenu ? 1 : 0)
            this.setState({
                openMenu: !this.state.openMenu,
                fullBody: !this.state.fullBody
            });
        }
    }

    handleTimeOutKey() {
        try{
            let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            let remaining = encryptStorage.getItem('pUser').remaining;
            
            if (new Date().getTime() < remaining) return;
            return SecurityManager.refreshToken(encryptStorage.getItem('pToken')).then(this.setState({ showModal: false }))
                .catch(error => Sentry.captureException(error));
        }
        catch(e){}
    }

    handleClose() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        SecurityManager.refreshToken(encryptStorage.getItem('pToken')).then(() =>{
            this.setState({ showModal: false })
        }).catch(error => Sentry.captureException(error));
    }

    handleLogout() {
        this.setState({ showModal: false })
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        if(encryptStorage){
            this.props.history.push("/" + encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry + "/logout");
        }
    }

    updateDimensions(){
        if(window.innerWidth < 960){
            this.setState({
                openMenu: false,
                fullBody: true
            });
        }
        else{
            this.setState({
                openMenu: true,
                fullBody: false
            });
        }
    }

    getHashUserEntry(){
        //hashIdUser+pEntry
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        if(encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').id){
            let userIdHash = md5(encryptStorage.getItem('pUser').id).toString();
            return userIdHash+PayabliStorageManager.getEntryName();
        }
        else{
            return null;
        }
        
    }

    componentDidMount() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let orgId = encryptStorage && encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry') ? 
            encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').orgId : null;

        if(!orgId){
            window.location.href = "/" + PayabliStorageManager.getEntryName() + "/logout";
        }

    
        IddleManager.startTimer(this.displayIddleModal); 

        const lr = new BroadcastChannel(`${PayabliStorageManager.getEntryName()}_payabliLocationReload`);
        lr.onmessage = (eventMessage) => {
            window.location.reload();
        }

        window.addEventListener('resize', this.updateDimensions);
        if(encryptStorage.getItem("pMenu")!=="1"){
            this.updateDimensions();
        }
        var intervalId = setInterval(this.handleTimeOutKey, 60000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ 
            intervalId: intervalId
        });
        if(this.props.match){
            let path = this.props.match.path;
            //let url = this.props.match.url;
            this.setState({selected:path});
            if( path === "/:entryUrl/vterminal/:paymentType?" 
                || path === "/:entryUrl/builder/:subdomain?"
                || path === "/:entryUrl/settings/:subdomain?"
                || path === "/:entryUrl/receipt/:subdomain?"
            ){
                this.setState({menuPortfolioActiveKey:"0"});
            }
    
            if( path === "/:entryUrl/report/transactions" 
                || path === "/:entryUrl/report/autopays"
                || path === "/:entryUrl/report/batches"
                || path === "/:entryUrl/report/chargebacks/:paymentTransId?"
                || path === "/:entryUrl/report/batches/:idBatch"
                || path === "/:entryUrl/report/transactions/idtransrefund/:idtransRefund"
            ){
                this.setState({menuReportsActiveKey:"0"});
            }
            
            if( path === "/:entryUrl/report/transactions/out/:id?"
            || path === "/:entryUrl/report/batches/out"
            || path === "/:entryUrl/report/batches/transactions/out/:idBatch"
            ){
                this.setState({menuReportsMoneyOutActiveKey:"0"});
            }

            if( path === "/:entryUrl/developers/apitokens" 
            || path === "/:entryUrl/developers/reports"
            || path === "/:entryUrl/developers/sftpaccounts"
            || path === "/:entryUrl/developers/creator"
            ){
                this.setState({menuReportsDevelopersActiveKey:"0"});
            }

            if( path === "/:entryUrl/riskcontrol/reports"){
                this.setState({menuRiskControlCenterActiveKey:"0", menuRiskControlCenterReportActiveKey:"0"});
            }
        }

        this.checkPermissions();
            
        
        
        window.addEventListener("message", (event) => {
            if (event.data === "componentCreatorShowLoading") {
                this.props.global.setLoading(true);
            }
            if (event.data === "componentCreatorHideLoading") {
                this.props.global.setLoading(false);
            }
        });
       
    }

    checkPermissions(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
    
        if(!this.props.user.hasAccess(this.props.match.path, userPermissions)){
            this.props.history.push("/accessdenied");
        }
    }

    prevent(e){
        if(e.target.getAttribute('data') === "no-prevent"){
            e.preventDefault();
            window.location.href =e.target.getAttribute('href');
        }else{
            e.preventDefault();
        }
    }

    handlePortfolioAccordion(e){
        this.prevent(e);
        this.setState({menuPortfolioActiveKey: this.state.menuPortfolioActiveKey === "closed" ? "0" : "closed" })
    }
    
    handleReportsAccordion(e){
        this.prevent(e);
        this.setState({menuReportsActiveKey: this.state.menuReportsActiveKey === "closed" ? "0" : "closed" })
    }
    
    handleReportsMoneyOutAccordion(e){
        this.prevent(e);
        this.setState({menuReportsMoneyOutActiveKey: this.state.menuReportsMoneyOutActiveKey === "closed" ? "0" : "closed" })
    }

    handleReportsDevelopersAccordion(e){
        this.prevent(e);
        this.setState({menuReportsDevelopersActiveKey: this.state.menuReportsDevelopersActiveKey === "closed" ? "0" : "closed" })
    }

    handleRiskControlCenterAccordion(e){
        this.prevent(e);
        this.setState({
                menuRiskControlCenterActiveKey: this.state.menuRiskControlCenterActiveKey === "closed" ? "0" : "closed",
                menuRiskControlCenterReportActiveKey: "closed"
            })
    }

    handleRiskControlCenterReportAccordion(e){
        this.prevent(e);
        this.setState({menuRiskControlCenterReportActiveKey: this.state.menuRiskControlCenterReportActiveKey === "closed" ? "0" : "closed" })
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let settingsUrls = [
            "/:entryUrl/paypoint",
            "/:entryUrl/profile/:id",
            "/:entryUrl/fields",
            "/:entryUrl/notifications",
            "/:entryUrl/report/users",
            "/:entryUrl/invoicing",
            "/:entryUrl/report/devices"
        ]
        if(encryptStorage && !encryptStorage.getItem('pUser') ){
            if(!window.location.href.endsWith('/login') && !window.location.href.endsWith('/logout') && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)){
                window.location.replace("/" + encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry + "/login");
            }else{
                window.location.replace("/");
            }
        }

        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        let settingsLinkTo = null;
        
        if(!userPermissions){
            window.location.href = "/" + encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry + "/logout";
        }

        if(userPermissions.indexOf("Developers") !== -1){
            settingsLinkTo = "/"+this.props.global.getURLEntry()+"/developers";
        }
        if(userPermissions.indexOf("Manage Users") !== -1){
            settingsLinkTo = "/"+this.props.global.getURLEntry()+"/report/users";
        }
        if(userPermissions.indexOf("Notifications") !== -1){
            settingsLinkTo = "/"+this.props.global.getURLEntry()+"/notifications";
        }
        if(userPermissions.indexOf("Custom Fields") !== -1){
            settingsLinkTo = "/"+this.props.global.getURLEntry()+"/fields";
        }
        if(userPermissions.indexOf("Profile") !== -1){
            settingsLinkTo = "/"+this.props.global.getURLEntry()+"/profile/"+((encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').id) ? encryptStorage.getItem('pUser').id : null);
        }
        if(userPermissions.indexOf("Paypoints") !== -1){
            settingsLinkTo = "/"+this.props.global.getURLEntry()+"/paypoint";
        }

        const ActionsMenu = (
            <Popover style={{width: "180px", marginLeft: "30px", boxShadow: "none"}}>
              <Popover.Content style={{minWidth: "180px"}}>
                <div className="menu-accordion-body-popover">
                    {userPermissions.indexOf("Accept Payments") !== -1 &&
                        <a href={"/"+PayabliStorageManager.getEntryName()+"/vterminal"} data="no-prevent">Accept Payment</a>
                    }
                    {(userPermissions.includes("Accept Payments") && userPermissions.includes("Autopay"))&&
                        <a href={"/"+PayabliStorageManager.getEntryName()+"/vterminal/autopay"} data="no-prevent">Schedule Payment</a>
                    }
                    {userPermissions.indexOf("Customers") !== -1 &&
                        <a href={"/"+PayabliStorageManager.getEntryName()+"/report/customers/add"} data="no-prevent">Add Customer</a>
                    }
                    {userPermissions.indexOf("Payment Pages") !== -1 &&
                        <a href={"/"+PayabliStorageManager.getEntryName()+"/builder"} data="no-prevent">Create Payment Page</a>
                    }
                </div>
              </Popover.Content>
            </Popover>
          );

          const ReportsMenu = (
            <Popover style={{width: "180px", marginLeft: "30px", boxShadow: "none"}}>
              <Popover.Content style={{minWidth: "180px"}}>
                <div className="menu-accordion-body-popover">
                    {userPermissions.indexOf("Transaction") !== -1 &&
                        <a href={"/"+PayabliStorageManager.getEntryName()+"/report/transactions"} data="no-prevent">Transactions</a>
                    }
                    {userPermissions.indexOf("Subscriptions") !== -1 &&
                        <a href={"/"+PayabliStorageManager.getEntryName()+"/report/autopays"} data="no-prevent">Autopays</a>
                    }
                    {userPermissions.indexOf("Batch") !== -1 &&
                        <a href={"/"+PayabliStorageManager.getEntryName()+"/report/batches"} data="no-prevent">Batches & Funding</a>
                    }
                    {userPermissions.indexOf("Chargeback") !== -1 &&
                        <a href={"/"+PayabliStorageManager.getEntryName()+"/report/chargebacks"} data="no-prevent">ACH Returns & Chargebacks</a>
                    }
                </div>
              </Popover.Content>
            </Popover>
          );
          
          const ReportsMoneyOutMenu = (
            <Popover style={{width: "180px", marginLeft: "30px", boxShadow: "none"}}>
              <Popover.Content style={{minWidth: "180px"}}>
                <div className="menu-accordion-body-popover">
                    {userPermissions.includes("Transaction") && (
                    <a href={"/"+PayabliStorageManager.getEntryName()+"/report/transactions/out"} data="no-prevent">Transactions</a>
                    )}
                    {userPermissions.includes("Batch") && (
                    <a href={"/"+PayabliStorageManager.getEntryName()+"/report/batches/out"} data="no-prevent">Batches & Funding</a>
                    )}
                </div>
              </Popover.Content>
            </Popover>
          );
          const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: LoadingApplication,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          };
        return (
            <div>
                <IdleTimeOutModal
                    countDown={this.state.countDown}
                    showModal={this.state.showModal}
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />

                {this.props.global.isLoading &&
                <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">
                   <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div>
                }

                {this.props.fullScreen === true ?
                <>
                   
                    {this.props.children}
                    
                </>
                :
                <>
                <div className={ this.state.openMenu ? "main-left-bar": "main-left-bar open"}>

                    <HiMenuAlt2 className="hamburgerButton" onClick={()=>this.handleMainMenu()}/>
                    <div className="main-menu">
                       
                        <div id="main-branding">
                            <a href={"/"+this.props.global.getURLEntry()+"/dashboard"}><Brand/></a>
                        </div>
                        
                        <div id="main-branding-icon">
                            <a href={"/"+this.props.global.getURLEntry()+"/dashboard"}><img alt="" src={payabliIcon } /></a>
                        </div>

                        <RSC id="RSC-Example" style={{height: "71vh" }}>

                        <ul id="main-menu-ul">
                            
                            <li>
                                <div className="header">Menu</div>
                            </li>
                            {userPermissions.includes("Dashboard") &&
                            <li className={this.state.selected === "/:entryUrl/dashboard" ? 'selected' : ''}>
                                <a title="Dashboard" href={"/"+this.props.global.getURLEntry()+"/dashboard"}><div className="icon"><BiHomeAlt/></div>
                                    Dashboard
                                </a>
                            </li>
                            }
                            {(userPermissions.includes("Accept Payments") || userPermissions.includes("Customers") || userPermissions.includes("Payment Pages"))&&
                            <li>
                                <Accordion activeKey={this.state.menuPortfolioActiveKey}>
                                <Accordion.Toggle as={Link} title="Actions" to="#" onClick={(e) => this.handlePortfolioAccordion(e)} eventKey={this.state.openMenu ? "0" : "99"}>
                                    <div className="icon">
                                        { this.state.openMenu ?
                                        <BiPlus/>
                                        :               
                                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={ActionsMenu}>
                                            <BiPlus/>
                                        </OverlayTrigger>
                                        }
                                    </div>Actions <BiChevronDown className="float-right" style={{fontSize: "15px"}}/>
                                </Accordion.Toggle>
                                { this.state.openMenu ?
                                <Accordion.Collapse eventKey="0">
                                <div className="menu-accordion-body">
                                    {userPermissions.indexOf("Accept Payments") !== -1 &&
                                        <a className={this.state.selected === "/:entryUrl/vterminal/:paymentType?" ? 'selected' : ''} href={"/"+this.props.global.getURLEntry()+"/vterminal"}>Accept Payment</a>
                                    }
                                    {(userPermissions.includes("Accept Payments") && userPermissions.includes("Autopay")) &&
                                        <a className={this.state.selected === "" ? 'selected' : ''} href={"/"+this.props.global.getURLEntry()+"/vterminal/autopay"}>Schedule Payment</a>
                                    }
                                    {userPermissions.indexOf("Customers") !== -1 &&
                                        <a href={"/"+this.props.global.getURLEntry()+"/report/customers/add"}>Add Customer</a>
                                    }
                                    {userPermissions.indexOf("Payment Pages") !== -1 &&
                                        <a className={(this.state.selected === "/:entryUrl/builder/:subdomain?" || this.state.selected === "/:entryUrl/settings/:subdomain?" || this.state.selected === "/:entryUrl/receipt/:subdomain?") ? 'selected' : ''} href={"/"+this.props.global.getURLEntry()+"/builder"}>Create Payment Page</a>
                                    }
                                </div>
                                </Accordion.Collapse>
                                : ''
                                }
                                </Accordion>
                            </li>
                            }

                            <li className="mt-4">
                                <div className="header">Money In</div>
                            </li>
                            {userPermissions.includes("Reports") && (
                            <li>
                                <Accordion activeKey={this.state.menuReportsActiveKey}>
                                <Accordion.Toggle as={Link} title="Reports" to="#" onClick={(e) => this.handleReportsAccordion(e)} eventKey={this.state.openMenu ? "0" : "99"}>
                                    <div className="icon">
                                        { this.state.openMenu ?
                                        <img alt="" src={moneyin}/>
                                        :               
                                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={ReportsMenu}>
                                            <img alt="" src={moneyin}/>
                                        </OverlayTrigger>
                                        }
                                    </div>Reports <BiChevronDown className="float-right" style={{fontSize: "15px"}} data-qaid="leftMenuReportsMoneyIn"/>
                                </Accordion.Toggle>
                                { this.state.openMenu ?
                                <Accordion.Collapse eventKey="0">
                                <div className="menu-accordion-body">
                                    {userPermissions.indexOf("Transaction") !== -1 &&
                                        <a className={this.state.selected === "/:entryUrl/report/transactions" || this.state.selected ===  "/:entryUrl/report/transactions/idtransrefund/:idtransRefund" ? 'selected' : ''} href={"/"+this.props.global.getURLEntry()+"/report/transactions"} data-qaid="leftMenuTransactionsReportsLink">Transactions</a>
                                    }
                                    {userPermissions.indexOf("Subscriptions") !== -1 &&
                                        <a className={this.state.selected === "/:entryUrl/report/autopays" ? 'selected' : ''} href={"/"+this.props.global.getURLEntry()+"/report/autopays"} data-qaid="leftMenuAutopaysReportsLink">Autopays</a>
                                    }
                                    {userPermissions.indexOf("Batch") !== -1 &&
                                        <a className={this.state.selected === "/:entryUrl/report/batches" || this.state.selected === "/:entryUrl/report/batches/:idBatch" ? 'selected' : ''} href={"/"+this.props.global.getURLEntry()+"/report/batches"} data-qaid="leftMenuBatchesReportsLink">Batches & Funding</a>
                                    }
                                    {userPermissions.indexOf("Chargeback") !== -1 &&
                                        <a className={this.state.selected === "/:entryUrl/report/chargebacks/:paymentTransId?" ? 'selected' : ''} href={"/"+this.props.global.getURLEntry()+"/report/chargebacks"} data-qaid="leftMenuReturnsReportsLink">ACH Returns & Chargebacks</a>
                                    }
                                    
                                </div>
                                </Accordion.Collapse>
                                : ''
                                }
                                </Accordion>
                            </li>
                            )}

                            {userPermissions.indexOf("Customers") !== -1 &&
                            <li className={this.state.selected === "/:entryUrl/report/customers/:command?" || this.state.selected === "/:entryUrl/customer/:id" || this.state.selected === "/:entryUrl/report/transactions/:id/:gobackData?" || this.state.selected === "/:entryUrl/customer/paymethods/:id/:command?" || this.state.selected === "/:entryUrl/report/autopays/:id/:command?"  ? 'selected' : ''}>
                                <a title="Customers" href={"/"+this.props.global.getURLEntry()+"/report/customers"} data-qaid="leftMenuCustomersReportsLink"><div className="icon"><BiGroup/></div>
                                Customers
                                </a>
                            </li>
                            }
                            
                            {userPermissions.indexOf("Invoices") !== -1 &&
                                <li className={this.state.selected === "/:entryUrl/report/invoices" || this.state.selected === "/:entryUrl/invoices/:id?" ? 'selected' : ''}>
                                    <a title="Invoices" href={"/"+this.props.global.getURLEntry()+"/report/invoices"} data-qaid="leftMenuInvoicesReportsLink"><div className="icon"><BiReceipt /></div>
                                        Invoices
                                    </a>
                                </li>
                            }

                            {userPermissions.indexOf("Virtual Terminal") !== -1 &&
                                <li className={this.state.selected === "/:entryUrl/report/catalog" ? 'selected' : ''}>
                                    <a title="Catalog" href={"/"+this.props.global.getURLEntry()+"/report/catalog"} data-qaid="leftMenuCatalogReportsLink"><div className="icon"><BiShoppingBag /></div>
                                        Catalog
                                    </a>
                                </li>
                            }

                            {(userPermissions.includes("MoneyOut Actions")) && (
                                <div>
                                    <li className="mt-4">
                                        <div className="header">Money Out</div>
                                    </li>
                                    {(userPermissions.includes("Reports") && userPermissions.includes("MoneyOut Actions"))
                                    && (userPermissions.includes("Transaction") || userPermissions.includes("Batch")) && (
                                    <li>
                                        <Accordion activeKey={this.state.menuReportsMoneyOutActiveKey}>
                                        <Accordion.Toggle as={Link} title="Reports" to="" onClick={(e) => this.handleReportsMoneyOutAccordion(e)} eventKey={this.state.openMenu ? "0" : "99"}>
                                            <div className="icon">
                                                { this.state.openMenu ?
                                                    <img alt="" src={moneyout}/>
                                                :               
                                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={ReportsMoneyOutMenu}>
                                                        <img alt="" src={moneyout}/>
                                                    </OverlayTrigger>
                                                }
                                            </div>Reports <BiChevronDown className="float-right" style={{fontSize: "15px"}} data-qaid="leftMenuReportsMoneyOut"/>
                                        </Accordion.Toggle>
                                        { this.state.openMenu ?
                                            <Accordion.Collapse eventKey="0">
                                            <div className="menu-accordion-body">
                                                {userPermissions.includes("Transaction") && (
                                                <a className={this.state.selected === "/:entryUrl/report/transactions/out/:id?" ? 'selected' : ''} href={"/"+this.props.global.getURLEntry()+"/report/transactions/out"} data-qaid="leftMenuTransactionsOutReportsLink">Transactions</a>
                                                )}
                                                 {userPermissions.includes("Batch") && (
                                                <a className={this.state.selected === "/:entryUrl/report/batches/out" || this.state.selected === "/:entryUrl/report/batches/transactions/out/:idBatch" ? 'selected' : ''} href={"/"+this.props.global.getURLEntry()+"/report/batches/out"} data-qaid="leftMenuBatchesOutReportsLink">Batches & Funding</a>
                                                 )}
                                            </div>
                                            </Accordion.Collapse>
                                            : ''
                                        }
                                        </Accordion>
                                    </li>
                                    )}
                                    {userPermissions.includes("Vendors") &&
                                        <li className={this.state.selected === "/:entryUrl/report/vendors" ? 'selected' : ''}>
                                            <a title="Vendors" href={"/"+this.props.global.getURLEntry()+"/report/vendors"} data-qaid="leftMenuVendorsReportsLink"><div className="icon"><BiIdCard/></div>
                                            Vendors
                                            </a>
                                        </li>
                                    }
                                    {userPermissions.includes("Bills") &&
                                        <li className={(this.state.selected === "/:entryUrl/report/bills/:id?" || this.state.selected === "/:entryUrl/report/bills/vendor/:vendorNumber?" || this.state.selected === "/:entryUrl/report/bills/bill/:billNumber?" || this.state.selected === "/:entryUrl/bills/new/:vendorNumber"  || this.state.selected === "/:entryUrl/bills/new" || this.state.selected === "/:entryUrl/bills/edit/:idBill" || this.state.selected === "/:entryUrl/bills/view/:idBill" ) ? 'selected' : ''}>
                                            <a title="Bills" href={"/"+this.props.global.getURLEntry()+"/report/bills"} data-qaid="leftMenuBillsReportsLink"><div className="icon"><BiFile/></div>
                                            Bills
                                            </a>
                                        </li>
                                    }
                                    {userPermissions.includes("Approvals") &&
                                        <li className={this.state.selected === "/:entryUrl/report/approvals" ? 'selected' : ''}>
                                            <a title="Approvals" href={"/"+this.props.global.getURLEntry()+"/report/approvals"} data-qaid="leftMenuApprovalsReportsLink"><div className="icon"><BiCheckSquare/></div>
                                            Approvals
                                            </a>
                                        </li>
                                    }
                                </div>
                            )}
                            
                            {(
                                userPermissions.indexOf("Virtual Terminal") !== -1 || 
                                userPermissions.indexOf("Payment Pages") !== -1 || 
                                userPermissions.indexOf("Developers") !== -1 || 
                                (userPermissions.indexOf("Management") !== -1  && userPermissions.indexOf("Fraud"))
                            ) &&                           
                            <li className="mt-4">
                               <div className="header">More</div>
                            </li>
                            }
                            {userPermissions.indexOf("Virtual Terminal") !== -1 &&
                            <li className={this.state.selected === "/:entryUrl/vterminal/:paymentType?" ? 'selected' : ''}>
                                <a title="Virtual Terminal" href={"/"+this.props.global.getURLEntry()+"/vterminal"} data-qaid="leftMenuVirtualTerminalLink"><div className="icon"><BiMoney/></div>
                                Virtual Terminal
                                </a>
                            </li>
                            }
                            {userPermissions.indexOf("Payment Pages") !== -1 &&
                            <li className={this.state.selected === "/:entryUrl/paymentpages" || this.state.selected === "/:entryUrl/builder/:subdomain?" || this.state.selected === "/:entryUrl/settings/:subdomain?" || this.state.selected === "/:entryUrl/receipt/:subdomain?" ? 'selected' : ''}>
                                <a title="Payment Pages" href={"/"+this.props.global.getURLEntry()+"/paymentpages"} data-qaid="leftMenuPaymentPagesLink"><div className="icon"><BiCopy/></div>
                                Payment Pages
                                </a>
                            </li>
                            }
                            {userPermissions.indexOf("Developers") !== -1 &&
                            <li>
                                <Accordion activeKey={this.state.menuReportsDevelopersActiveKey}>
                                    <Accordion.Toggle as={Link} title="Reports" to="" data-qaid="leftMenuDevelopersMenu" onClick={(e) => this.handleReportsDevelopersAccordion(e)} eventKey={this.state.openMenu ? "0" : "99"}>
                                        <div className="icon"><BiCodeBlock /></div> Developers <BiChevronDown className="float-right" style={{fontSize: "15px"}}/>
                                    </Accordion.Toggle>
                                    { this.state.openMenu ?
                                        <Accordion.Collapse eventKey="0">
                                        <div className="menu-accordion-body">
                                            {userPermissions.indexOf("Xchange") !== -1 &&
                                                <>
                                                    <a className={this.state.selected === "/:entryUrl/developers/sftpaccounts" ? 'selected' : ''} href={"/" + this.props.global.getURLEntry() + "/developers/sftpaccounts"} data-qaid="leftMenuSFTPAccountsLink">SFTP Accounts</a>
                                                    {/*<a className={this.state.selected === "/:entryUrl/developers/logs" ? 'selected' : ''} href={"/" + this.props.global.getURLEntry() + "/developers/logs">Logs</a>*/}
                                                    <a className={this.state.selected === "/:entryUrl/developers/reports" ? 'selected' : ''} href={"/" + this.props.global.getURLEntry() + "/developers/reports"} data-qaid="leftMenuReportsLink">Reports</a>
                                                </>
                                            }
                                            <a className={this.state.selected === "/:entryUrl/developers/apitokens" ? 'selected' : ''} href={"/" + this.props.global.getURLEntry() + "/developers/apitokens"} data-qaid="leftMenuAPIKeysLink">API Tokens</a>
                                            {process.env.REACT_APP_LINK_COMPONENT_CREATOR === 'true' &&
                                            <a className={this.state.selected === "/:entryUrl/developers/creator" ? 'selected' : ''} href={"/" + this.props.global.getURLEntry() + "/developers/creator"} data-qaid="leftMenuComponentCreatorLink">Creator <span style={{fontSize: '10px', marginLeft: '5px'}} className={"badge bg-primary"}>Beta</span></a>
                                            }
                                        </div>
                                        </Accordion.Collapse>
                                        : ''
                                    }
                                </Accordion>
                            </li>
                            }
                            {(userPermissions.indexOf("Management") !== -1  && userPermissions.indexOf("Fraud") !== -1) &&
                                <div>                                    
                                    <li>
                                        <Accordion activeKey={this.state.menuRiskControlCenterActiveKey}>
                                            <Accordion.Toggle as={Link} title="Reports" to="" data-qaid="leftMenuRiskControlCenterActiveLink" onClick={(e) => this.handleRiskControlCenterAccordion(e)} eventKey={this.state.openMenu ? "0" : "99"}>
                                                <div className="icon"><BiBullseye /></div> Risk Control Center <BiChevronDown className="float-right" style={{fontSize: "15px"}}/>
                                            </Accordion.Toggle>
                                            { this.state.openMenu ?
                                                <Accordion.Collapse eventKey="0">
                                                    <div className="menu-accordion-body">
                                                        <Accordion activeKey={this.state.menuRiskControlCenterReportActiveKey}>
                                                            <Accordion.Toggle as={Link} title="Reports" to="" data-qaid="leftMenuRiskControlCenterReportActiveKeyLink" onClick={(e) => this.handleRiskControlCenterReportAccordion(e)} eventKey={this.state.openMenu ? "0" : "99"}>
                                                                <span>Reports <BiChevronDown className="float-right" style={{fontSize: "15px"}}/></span>
                                                            </Accordion.Toggle>
                                                            { this.state.openMenu ?
                                                            <Accordion.Collapse eventKey="0">
                                                                <div className="menu-accordion-body">
                                                                    <a className={this.state.selected === "/:entryUrl/riskcontrol/reports" ? 'selected sub-menu' : 'sub-menu'} href={"/" + this.props.global.getURLEntry() + "/riskcontrol/reports"}>Fraud Alerts</a>
                                                                </div>
                                                            </Accordion.Collapse>
                                                            : ''
                                                            }
                                                        </Accordion>
                                                    </div>
                                                </Accordion.Collapse>
                                                : ''
                                            }
                                        </Accordion>
                                    </li>
                                </div>
                            }
                        </ul>
                       
                        </RSC>

                    </div>

                    <div className="main-menu-bottom">
                        <ul>
                            {settingsLinkTo && 
                            <li className={settingsUrls.includes(this.state.selected) ? 'selected' : ''}>
                                <a title="Settings" href={settingsLinkTo} data-qaid="leftMenuSettingsLink"><div className="icon"><BiCog/></div>
                                Settings
                                </a>
                            </li>
                            }
                            <li>
                                <a title="Support" href="mailto:support@payabli.com" data-qaid="leftMenuSupportLink"><div className="icon"><BiHelpCircle/></div>
                                Support
                                </a>
                            </li>
                            <li className='versionLabel'>
                                {process.env.REACT_APP_VERSION}
                            </li>
                        </ul>
                       
                    </div>
                </div>

  
                <div className={ !this.state.fullBody ? "main-body": "main-body open-menu"} style={{backgroundColor: this.props.bgcolor ? this.props.bgcolor: '#ffffff'}}>
                    {this.props.children}
                </div>
                </>
                }




            </div>
        )
    }
}

export { Layout };