import React from 'react';
import { inject, observer } from 'mobx-react';
import {Link} from "react-router-dom";
import { Accordion, Dropdown, DropdownButton, Card, Alert, OverlayTrigger, Tooltip, Modal, Button } from 'react-bootstrap';
import { BiDotsVerticalRounded, BiChevronDown, BiNotepad, BiChevronUp, BiCopy, BiCheckCircle, BiUser, BiCreditCard, BiGlobe, BiStore } from 'react-icons/bi';
import { BsExclamationTriangle } from 'react-icons/bs'
import { toast } from 'react-toastify';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { RightPanel } from '../../components/RightPanel';
import { MainBar } from '../../components/MainBar';
import { ReportPaginator } from '../../components/ReportPaginator';
import { QuickViewTransaction } from '../../components/QuickViewTransaction';

@inject('reports', 'global', 'management')
@observer
class ViewPartialFraudAlerts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            flagItemAllColumn: true,
            keyEventAccordion: null,
            copy: {},
            showModalConfirmation: false,
        }
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.handleKeyEventAccordion = this.handleKeyEventAccordion.bind(this);
        this.renderRightPapel = this.renderRightPapel.bind(this);
        this.actionCopy = this.actionCopy.bind(this);
        this.handleActionTimerStateCopy = this.handleActionTimerStateCopy.bind(this);
        this.actionFraudControl = this.actionFraudControl.bind(this);
        this.actionRemoveFraudControl = this.actionRemoveFraudControl.bind(this);
        this.actionBlockPaypoint = this.actionBlockPaypoint.bind(this);
        this.handleShowModalConfirmation = this.handleShowModalConfirmation.bind(this);
        this.handleCloseModalConfirmation = this.handleCloseModalConfirmation.bind(this);
        this.renderLast4Report = this.renderLast4Report.bind(this);
        this.renderModalConfirmation = this.renderModalConfirmation.bind(this);
        this.renderCardPaymentInformation = this.renderCardPaymentInformation.bind(this);
    }

    componentDidMount(){
        this.props.global.setRightPanelOpen(false);
        this.props.reports.setHeaders({
			transactionTime     : { label:'Transaction Date', class: '', display: true },
			createdAt           : { label:'Alert Date', class: '', display: true },
			parentOrgName       : { label:'Organization', class: '', display: false },
			paypointLegalName   : { label:'Paypoint', class: '', display: true },
			event               : { label:'Trigger Event', class: '', display: false },
			typeFraud           : { label:'Alert Results', class: 'text-center', display: true },
			responseCode        : { label:'Response Code', class: '', display: true },
			responseCodeText    : { label:'Response Details', class: '', display: true },
            netAmount           : { label:'Amount', class: 'text-right', display: false}, 
            feeAmount           : { label:'Fee', class: 'text-right', display: false}, 
            totalAmount         : { label:'Total Amount', class: 'text-right', display: true}, 
            currency            : { label:'Currency', class: 'text-center', display: false}, 
			averageTicket       : { label:'Average Ticket Amount', class: 'text-right', display: false },
			highTicket          : { label:'High Ticket Amount', class: 'text-right', display: false },
			avsResponseText     : { label:'AVS Text Response', class: '', display: true },
			cvvResponseText     : { label:'CVV Text Response', class: '', display: true },
			ip                  : { label:'IP Address', class: '', display: true },
            /* Transaction Column */
            gatewayTransId      : { label:'Transaction ID', class: '', display: false },
            paymentTransId      : { label:'Internal Transaction ID', class: '', display: false },
            orderId             : { label:'Order ID', class: '', display: false },
            batchNumber         : { label:'Batch Number', class: '', display: false },
            externalPaypointId  : { label:'External Paypoint ID', class: '', display: false },
            customer            : { label:'Customer', class: '', display: true },
            company             : { label:'Company', class: '', display: false },
            billingEmail        : { label:'Email', class: '', display: false },
            payorPhone          : { label:'Phone #', class: '', display: false },
            billingAddress      : { label:'Billing Address', class: '', display: false },
            shippingAddress     : { label:'Shipping Address', class: '', display: false },
            method              : { label:'Pay Method', class: 'text-center', display: true },
            last4               : { label:'Last 4', class: '', display: true  },
            typeTrans           : { label:'Type', class: '', display: false },
            status              : { label:'Status', class: '', display: true },
            settlementStatus    : { label:'Batch Status', class: '', display: false },
            authCode            : { label:'Auth. Code', class: '', display: false },
            source              : { label:'Source', class: '', display: false },
            notes               : { label:'Notes', class: 'text-center', display: false }
		})
        this.getReportFromApi();
    }

    getReportFromApi(){
        this.props.global.setLoading(true);
        this.props.reports.getReportFromApi('fraudhistory').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = this.props.global.getTextParseMsgError(error);
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    clearFilters(){
        this.props.reports.clearFilters();
        this.getReportFromApi();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }

    viewRecordDetails(index, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            let thisObj = this;
            if(thisObj.props.global.rightPanelOpen && thisObj.props.reports.recordDetails !== index){
                thisObj.props.global.setRightPanelOpen(false);
                thisObj.props.reports.setRecordDetails(index);
                setTimeout(function(){
                    thisObj.props.global.setRightPanelOpen(true);
                }, 500)                
            }else{
                thisObj.props.reports.setRecordDetails(index);
                thisObj.props.global.setRightPanelOpen(true);
            }
        }
    }

    handleKeyEventAccordion(key){
        this.setState({keyEventAccordion: key});
    }

    actionCopy(data, pos){
        if(data){
            navigator.clipboard.writeText(data);
            let copyTemp = this.state.copy;
            copyTemp[pos] = true;
            this.setState({copy: copyTemp}, () => {
                this.handleActionTimerStateCopy();
            });
        }
    }

    handleActionTimerStateCopy(){
        setTimeout(() => {
            this.setState({copy: {}});
          }, 3000);
    }

    actionFraudControl(type, fraudHistoryId){
        this.props.global.setLoading(true);
        this.props.management.actionFraudControl(type, fraudHistoryId).then(res => {
            this.getReportFromApi();
            this.handleCloseModalConfirmation();
            toast.success("Blocked successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
            });
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = this.props.global.getTextParseMsgError(error);
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    actionRemoveFraudControl(fraudHistoryId){
        this.props.global.setLoading(true);
        this.props.management.actionRemoveFraudControl(fraudHistoryId).then(res => {
            this.getReportFromApi();
            this.handleCloseModalConfirmation();
            toast.success("Unblocked successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
            });
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = this.props.global.getTextParseMsgError(error);
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    actionBlockPaypoint(idPaypoint){
        this.props.global.setLoading(true);
        this.props.management.actionBlockPaypointFraudControl(idPaypoint).then(res => {
            this.getReportFromApi();
            this.handleCloseModalConfirmation();
            toast.success("Blocked successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
            });
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = this.props.global.getTextParseMsgError(error);
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    renderCardPaymentInformation(recordDetail){
        if (recordDetail?.Method?.toLowerCase() === "card") {
            return (<>
                <div className="row mb-2">
                    <div className="col-7">
                        <label className="grey">Card Number</label>
                        <h5 style={{fontWeight: 500}}>
                        •••• •••• {recordDetail.PaymentData && recordDetail.PaymentData.MaskedAccount ? this.props.global.maskedCardNumber(recordDetail.PaymentData.MaskedAccount, 'v5') : '-'}
                        </h5>
                    </div> 
                    <div className="col-5 text-center">
                        <label className="grey">Expires on</label>
                        <h5 style={{fontWeight: 500}}>
                            {recordDetail.PaymentData && recordDetail.PaymentData.AccountExp ? this.props.global.expDateFormat(recordDetail.PaymentData.AccountExp) : '-'}
                        </h5>
                    </div>   
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="grey">Cardholder Name</label>
                        <h6 style={{fontWeight: 500}}>
                            {recordDetail.PaymentData && recordDetail.PaymentData.HolderName ? recordDetail.PaymentData.HolderName : '-'}
                        </h6>
                    </div>
                </div>
            </>);
        } else if (recordDetail?.Method?.toLowerCase() === "ach") {
            return (<>
                <div className="row mb-2">
                    <div className="col-7">
                        <label className="grey">Account Number</label>
                        <h5 style={{fontWeight: 500}}>
                        •••• •••• {recordDetail?.PaymentData?.MaskedAccount ? this.props.global.maskedCardNumber(recordDetail.PaymentData.MaskedAccount, 'v5') : '-'}
                        </h5>
                    </div>           
                    <div className="col-5 text-center">
                        <label className="grey">Account Type</label>
                        <h5 style={{fontWeight: 500}}>
                            {recordDetail?.PaymentData?.AccountType ? recordDetail.PaymentData.AccountType  : '-'}
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="grey">Account Holder Name</label>
                        <h6 style={{fontWeight: 500}}>
                            {recordDetail?.PaymentData?.HolderName ? recordDetail.PaymentData.HolderName : '-'}
                        </h6>
                    </div>
                </div>
            </>);
        } else if (recordDetail?.Method?.toLowerCase() === "check") {
            return (<>
                <div className="row mb-2">
                    <div className="col-7">
                    <label className="grey">Account Holder Name</label>
                        <h6 style={{fontWeight: 500, fontSize: '1rem'}}>
                            {recordDetail?.PaymentData?.HolderName ? recordDetail.PaymentData.HolderName : '-'}
                        </h6>    
                    </div>     
                    <div className="col-5 text-center">
                        <label className="grey">Payment Method</label>
                        <h5 style={{fontWeight: 500, fontSize: '1rem'}}>Physical Check</h5>
                    </div>   
                </div>
                <div className="row">
                    <div className="col-12">
                    <label className="grey">Check #</label>
                        <h5 style={{fontWeight: 500, fontSize: '1rem'}}>
                            {recordDetail?.PaymentData?.paymentDetails?.checkNumber ? recordDetail.PaymentData.paymentDetails.checkNumber : '-'}
                        </h5>
                    </div>
                </div>     
            </>);
        } else if (recordDetail?.Method?.toLowerCase() === "cash") {
            return (<>
                <div className="row mb-2">
                    <div className="col-7">
                        <label className="grey">Payor Name</label>
                        <h5 style={{fontWeight: 500, fontSize: '1rem'}}>
                            {recordDetail?.Customer ? (recordDetail.Customer.FirstName ? recordDetail.Customer.FirstName : "") + ' ' + (recordDetail.Customer.LastName ? recordDetail.Customer.LastName : "") : '-'}
                        </h5>
                    </div>
                    <div className="col-5">
                    <label className="grey">Payment Method</label>
                        <h5 style={{fontWeight: 500, fontSize: '1rem'}}>Cash</h5>
                    </div>    
                </div>
            </>);
        }
    }

    renderRightPapel(){
        const record = this.props.reports?.getRecordDetails;
        const recordDetail = this.props.reports?.getRecordDetails?.TransactionDetails;
        let apiRequestData = null;
        if (recordDetail) {
            apiRequestData = {...recordDetail.PaymentData, ...{payorData: recordDetail.Customer}};
        }
        return (
            <RightPanel>
                <h5 className="header mb-3">Summary</h5>
                <div className='row mb-3'>
                    <div className='col text-center'>
                        {this.props.global.getGlobalImg(true ? 'holdtrx' : 'holdtrxcolor', '35px')}<br/>
                        <span className='span-detail-name'>transaction held</span><br/>
                        <span className='span-detail-date'>{false ? 'Jan 23, 2022' : 'n/a'}</span>
                    </div>
                    <div className='col text-center'>
                        {this.props.global.getGlobalImg(true ? 'holdbatch' : 'holdbatchcolor', '35px')}<br/>
                        <span className='span-detail-name'>batch held</span><br/>
                        <span className='span-detail-date'>{false ? 'Jan 23, 2022' : 'n/a'}</span>
                    </div>
                    <div className='col text-center'>
                        {this.props.global.getGlobalImg(record?.Type !== 'payor' ? 'blockuser' : 'blockusercolor', '35px')}<br/>
                        <span className='span-detail-name'>customer blocked</span><br/>
                        <span className='span-detail-date'>{record?.Type === 'payor' ? this.props.global.stringDateFormatV3(record?.CreatedAt) : 'n/a'}</span>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col text-center'>
                        {this.props.global.getGlobalImg(record?.Type !== 'card' ? 'blockcc' : 'blockcccolor', '35px')}<br/>
                        <span className='span-detail-name'>CARDS & DDAs BLOCKED</span><br/>
                        <span className='span-detail-date'>{record?.Type === 'card' ? this.props.global.stringDateFormatV3(record?.CreatedAt) : 'n/a'}</span>
                    </div>
                    <div className='col text-center'>
                        {this.props.global.getGlobalImg(record?.Type !== 'ip' ? 'blockip' : 'blockipcolor', '35px')}<br/>
                        <span className='span-detail-name'>ip Addresses blocked</span><br/>
                        <span className='span-detail-date'>{record?.Type === 'ip' ? this.props.global.stringDateFormatV3(record?.CreatedAt) : 'n/a'}</span>
                    </div>
                    <div className='col text-center'>
                        {this.props.global.getGlobalImg(true ? 'blockpp' : 'blockppcolor', '35px')}<br/>
                        <span className='span-detail-name'>paypoints blocked</span><br/>
                        <span className='span-detail-date'>{false ? 'Jan 23, 2022' : 'n/a'}</span>
                    </div>
                </div>
                <Accordion className='mt-4' defaultActiveKey={'0'} onSelect={(e) => this.handleKeyEventAccordion(e)}>
                    <Accordion.Toggle as={Card.Text} eventKey="0">
                        <span>Transaction Details {this.state.keyEventAccordion === '0' ? <BiChevronUp/> : <BiChevronDown/>}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        {recordDetail ?
                            (
                                <QuickViewTransaction ref={this.quickViewTransaction} recordDetail={recordDetail} />
                            ) :
                            (
                                <Alert variant={'warning'}>There is no information to display</Alert>
                            )
                        }
                    </Accordion.Collapse>
                </Accordion>
                <Accordion className='mt-4' onSelect={(e) => this.handleKeyEventAccordion(e)}>
                    <Accordion.Toggle as={Card.Text} eventKey="1">
                        <span>API Request Data {this.state.keyEventAccordion === '1' ? <BiChevronUp/> : <BiChevronDown/>}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        {apiRequestData ?
                            (
                                <Alert variant={'info'}>
                                    <pre>{JSON.stringify(apiRequestData, null, 2)}</pre>
                                    <br/>
                                    <span>
                                        {!this.state.copy?.apiRequestData ?
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Copy</Tooltip>}
                                                >
                                                <BiCopy onClick={()=> this.actionCopy(JSON.stringify(apiRequestData), 'apiRequestData')}/>
                                            </OverlayTrigger> :
                                            <BiCheckCircle className='text-success' />
                                        }
                                    </span>
                                </Alert>
                            ) :
                            (
                                <Alert variant={'warning'}>There is no information to display</Alert>
                            )
                        }
                    </Accordion.Collapse>
                </Accordion>
                <Accordion className='mt-4' onSelect={(e) => this.handleKeyEventAccordion(e)}>
                    <Accordion.Toggle as={Card.Text} eventKey="2">
                        <span>API Response Data {this.state.keyEventAccordion === '2' ? <BiChevronUp/> : <BiChevronDown/>}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        {recordDetail?.ResponseData ?
                            (
                                <Alert variant={'info'}>
                                    <pre>{JSON.stringify(recordDetail.ResponseData, null, 2)}</pre>
                                    <br/>
                                    <span>
                                        {!this.state.copy?.ResponseData ?
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Copy</Tooltip>}
                                                >
                                                <BiCopy onClick={()=> this.actionCopy(JSON.stringify(recordDetail.ResponseData), 'ResponseData')}/>
                                            </OverlayTrigger> :
                                            <BiCheckCircle className='text-success' />
                                        }
                                    </span>
                                </Alert>
                            ) :
                            (
                                <Alert variant={'warning'}>There is no information to display</Alert>
                            )
                        }
                    </Accordion.Collapse>
                </Accordion>
            </RightPanel>
        );
    }

    handleShowModalConfirmation(action, object){
        this.setState({showModalConfirmation: true, actionModal: action, objectModal: object})
    }

    handleCloseModalConfirmation(){
        this.setState({showModalConfirmation: false})
    }

    renderLast4Report(record){
        if (record) {
            if (record.PaymentData.MaskedAccount && record.PaymentData.MaskedAccount.toLowerCase() === "poi") {
                return ("-");
            } else if (record.Method && record.Method.toLowerCase() === "check") {
                return (record.PaymentData.paymentDetails.checkNumber && this.props.global.maskedCardNumber(record.PaymentData.paymentDetails.checkNumber, "v7"));
            }else if (record.Method && record.Method.toLowerCase() === "cash") {
                return ("-");
            }else{
                return (this.props.global.maskedCardNumber(record.PaymentData.MaskedAccount, "v5"));
            }            
        }
        return ("-");
    }

    renderModalConfirmation(){
        let arrAction = this.state.actionModal?.split('::');
        let action, type = '';
        if (Array.isArray(arrAction)) {
            action = arrAction[0];
            type = arrAction[1];
        }
        const fraudHistoryId = this.state.objectModal?.idFHistory ? this.state.objectModal.idFHistory : '';
        const response_code = this.state.objectModal?.TransactionDetails?.ResponseData?.response_code ? this.state.objectModal.TransactionDetails.ResponseData.response_code : '';
        const response_text = this.state.objectModal?.TransactionDetails?.ResponseData?.response_code_text ? this.state.objectModal.TransactionDetails.ResponseData.response_code_text : '';
        let icon_text = '', save_btn;
        switch(type?.toLowerCase()){
            case 'card':
                const masked_account = this.state.objectModal?.TransactionDetails?.PaymentData?.MaskedAccount ? this.state.objectModal.TransactionDetails.PaymentData.MaskedAccount : '';
                icon_text = <p><BiCreditCard/> Credit Card: {masked_account}</p>
                save_btn = <Button className='btn-modal-risk' variant="danger" onClick={() => {action !== 'block' ? this.actionRemoveFraudControl(fraudHistoryId) : this.actionFraudControl('card', fraudHistoryId)}}>{action} {type}</Button>
                break;
            case 'customer':
                const customer_name = this.state.objectModal?.TransactionDetails?.Customer?.FirstName ? this.state.objectModal.TransactionDetails.Customer.FirstName : '' + this.state.objectModal?.TransactionDetails?.Customer?.LastName ? ' ' + this.state.objectModal.TransactionDetails.Customer.LastName : '';
                icon_text = <p><BiUser/> Customer: {customer_name}</p>
                save_btn = <Button className='btn-modal-risk' variant="danger" onClick={() => {action !== 'block' ? this.actionRemoveFraudControl(fraudHistoryId) : this.actionFraudControl('payor', fraudHistoryId)}}>{action} {type}</Button>
                break;
            case 'ip':
                const ip_address = this.state.objectModal?.IP ? this.state.objectModal?.IP : '';
                icon_text = <p><BiGlobe/> IP Address: {ip_address}</p>
                save_btn = <Button className='btn-modal-risk' variant="danger" onClick={() => {action !== 'block' ? this.actionRemoveFraudControl(fraudHistoryId) : this.actionFraudControl('ip', fraudHistoryId)}}>{action} {type}</Button>
                break;
            case 'paypoint':
                const id_paypoint = this.state.objectModal?.TransactionDetails.PaypointId ? this.state.objectModal.TransactionDetails.PaypointId : '',
                paypoint = this.state.objectModal?.TransactionDetails?.PaypointLegalname ? this.state.objectModal.TransactionDetails.PaypointLegalname : '';
                icon_text = <p><BiStore/> Paypoint: {paypoint}</p>
                save_btn = <Button className='btn-modal-risk' variant="danger" onClick={() => {this.actionBlockPaypoint(id_paypoint)}}>{action} {type}</Button>
                break;
            default:
                break;
        }

        return (
            <Modal show={this.state.showModalConfirmation} onHide={this.handleCloseModalConfirmation} centered>
                <div className='text-center p-4'>
                    <div>
                        <BsExclamationTriangle style={{ fontSize: '50px', color: '#ffa726' }} />
                        <Modal.Title className='font-weight-bold m-3'>Notice!</Modal.Title>
                    </div>
                    <div className='mb-3'>
                        <p>This action will {action} the selected <strong><span className='text-capitalize'>{type}</span></strong>, Do you want to continue?</p>
                        {icon_text}
                        <small className='text-uppercase small' style={{color: '#FF867C'}}>response code: {response_code}</small><br/>
                        <small className='text-uppercase small' style={{color: '#FF867C'}}>response text: {response_text}</small>
                    </div>
                    <div>
                        <Button className='btn-modal-risk' variant="light" onClick={this.handleCloseModalConfirmation}>Close</Button>
                        {save_btn}
                    </div>
                </div>
            </Modal>
        );
    }
    
    render() {
        const { flagItemAllColumn } = this.state;
        return (
             <div>
                <MainBar
                    masterName={'Fraud Alerts'}
                    dataQAName={'FraudAlertReport'}
                    showHideFilters={() => {alert('Function under construction!')}}
                    selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                    handleShowColumn={(e) => this.handleShowColumn(e)}
                    skipTotal={true}
                    getHeaders={this.props.reports.getHeaders}
                    headers={this.props.reports.headers}
                    flagItemAllColumn={flagItemAllColumn}
                    refreshView={this.clearFilters}
                /> 
                <div className="report-container">
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                {this.props.reports.getHeaders.map((record, i) => ( 
                                    (record[1].display && 
                                        (<th key={i} scope="col" className={record[1].class}>{record[1].label}</th>)
                                    )
                                ))}
                                <th className='text-center sticky-row' scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>                
                        { this.props.reports.records.map((record, i) => (
                            <tr key={i} className="cursorPointer" onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} onClick={(e) => this.viewRecordDetails(i, e)}>
                                { (this.props.reports.headers.transactionTime && this.props.reports.headers.transactionTime.display) &&
                                    <td>{record.TransactionDetails?.TransactionTime ? this.props.global.stringDateFormatV3(record.TransactionDetails.TransactionTime) : '-'}</td>
                                }
                                { (this.props.reports.headers.createdAt && this.props.reports.headers.createdAt.display) &&
                                    <td>{record.CreatedAt ? this.props.global.stringDateFormatV3(record.CreatedAt) : '-'}</td>
                                }
                                { (this.props.reports.headers.parentOrgName && this.props.reports.headers.parentOrgName.display) &&
                                    <td>{record.TransactionDetails?.ParentOrgName ? record.TransactionDetails.ParentOrgName : '-'}</td>
                                }
                                { (this.props.reports.headers.paypointLegalName && this.props.reports.headers.paypointLegalName.display) &&
                                    <td>{record?.TransactionDetails?.PaypointLegalname ? record.TransactionDetails.PaypointLegalname : '-'}</td>
                                }
                                { (this.props.reports.headers.event && this.props.reports.headers.event.display) &&
                                    <td>{record.Event ? record.Event : '-'}</td>
                                }
                                { (this.props.reports.headers.typeFraud && this.props.reports.headers.typeFraud.display) &&
                                    <td className='text-center'>
                                        {
                                            <div className='form-inline alert-status'>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Transaction Held</Tooltip>}>
                                                    {this.props.global.getGlobalImg(true ? 'holdtrx' : 'holdtrxcolor')}
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Batch Held</Tooltip>}>
                                                    {this.props.global.getGlobalImg(true ? 'holdbatch' : 'holdbatchcolor')}
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Customers Blocked</Tooltip>}>
                                                    {this.props.global.getGlobalImg(record.Type !== 'payor' ? 'blockuser' : 'blockusercolor')}
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Cards & DDAs Blocked</Tooltip>}>
                                                    {this.props.global.getGlobalImg(record.Type !== 'card' ? 'blockcc' : 'blockcccolor')}
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>IP Addresses Blocked</Tooltip>}>
                                                    {this.props.global.getGlobalImg(record.Type !== 'ip' ? 'blockip' : 'blockipcolor')}
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Paypoints Blocked</Tooltip>}>
                                                    {this.props.global.getGlobalImg(true ? 'blockpp' : 'blockppcolor')}
                                                </OverlayTrigger>
                                            </div>
                                        }
                                    </td>
                                }
                                { (this.props.reports.headers.responseCode && this.props.reports.headers.responseCode.display) &&
                                    <td>{record.TransactionDetails?.ResponseData?.response_code ? record.TransactionDetails.ResponseData.response_code : '-'}</td>
                                }
                                { (this.props.reports.headers.responseCodeText && this.props.reports.headers.responseCodeText.display) &&
                                    <td>{record.TransactionDetails?.ResponseData?.response_code_text ? record.TransactionDetails.ResponseData.response_code_text : '-'}</td>
                                }
                                { (this.props.reports.headers.netAmount && this.props.reports.headers.netAmount.display) &&
                                    <td className='text-right'>{record.TransactionDetails?.NetAmount ? this.props.global.numberWithCommasNegative(record.TransactionDetails.NetAmount.toFixed(2)) : '$0.00'}</td>
                                }
                                { (this.props.reports.headers.feeAmount && this.props.reports.headers.feeAmount.display) &&
                                    <td className='text-right'>{record.TransactionDetails?.FeeAmount ? this.props.global.numberWithCommasNegative(record.TransactionDetails.FeeAmount.toFixed(2)) : '$0.00'}</td>
                                }
                                { (this.props.reports.headers.totalAmount && this.props.reports.headers.totalAmount.display) &&
                                    <td className='text-right'>{record.TransactionDetails?.TotalAmount ? this.props.global.numberWithCommasNegative(record.TransactionDetails.TotalAmount.toFixed(2)) : '$0.00'}</td>
                                }
                                { (this.props.reports.headers.currency && this.props.reports.headers.currency.display) &&
                                    <td className='text-right'>{record?.TransactionDetails?.PaymentData?.paymentDetails?.currency ? record.TransactionDetails.PaymentData.paymentDetails.currency : '-'}</td>
                                }
                                { (this.props.reports.headers.averageTicket && this.props.reports.headers.averageTicket.display) &&
                                    <td className='text-right'>{record.AverageTicket ? this.props.global.numberWithCommasNegative(record.AverageTicket.toFixed(2)) : '$0.00'}</td>
                                }
                                { (this.props.reports.headers.highTicket && this.props.reports.headers.highTicket.display) &&
                                    <td className='text-right'>{record.HighTicket ? this.props.global.numberWithCommasNegative(record.HighTicket.toFixed(2)) : '$0.00'}</td>
                                }
                                { (this.props.reports.headers.avsResponseText && this.props.reports.headers.avsResponseText.display) &&
                                    <td>{record?.TransactionDetails?.ResponseData?.avsresponse_text ? record.TransactionDetails.ResponseData.avsresponse_text : '-'}</td>
                                }
                                { (this.props.reports.headers.cvvResponseText && this.props.reports.headers.cvvResponseText.display) &&
                                    <td>{record?.TransactionDetails?.ResponseData?.cvvresponse_text ? record.TransactionDetails.ResponseData.cvvresponse_text : '-'}</td>
                                }
                                { (this.props.reports.headers.ip && this.props.reports.headers.ip.display) &&
                                    <td>{record.IP ? record.IP : '-'}</td>
                                }
                                { (this.props.reports.headers.gatewayTransId && this.props.reports.headers.gatewayTransId.display) &&
                                    <td>{record.TransactionDetails?.GatewayTransId ? record.TransactionDetails.GatewayTransId : '-'}</td>
                                }
                                { (this.props.reports.headers.paymentTransId && this.props.reports.headers.paymentTransId.display) &&
                                    <td>{record.TransactionDetails?.PaymentTransId ? record.TransactionDetails.PaymentTransId : '-'}</td>
                                }
                                { (this.props.reports.headers.orderId && this.props.reports.headers.orderId.display) &&
                                    <td>{record?.TransactionDetails?.OrderId  ? record.TransactionDetails.OrderId : '-'}</td>
                                }
                                { (this.props.reports.headers.batchNumber && this.props.reports.headers.batchNumber.display) &&
                                    <td>{record?.TransactionDetails?.BatchNumber ? record.TransactionDetails.BatchNumber : '-'}</td>
                                }
                                { (this.props.reports.headers.externalPaypointId && this.props.reports.headers.externalPaypointId.display) &&
                                    <td>{record.TransactionDetails?.externalPaypointID ? record.TransactionDetails.externalPaypointID : '-'}</td>
                                }
                                { (this.props.reports.headers.customer && this.props.reports.headers.customer.display) &&
                                    <td>{(record.TransactionDetails?.Customer?.FirstName || record.TransactionDetails?.Customer?.LastName) ? (record.TransactionDetails?.Customer?.FirstName ? record.TransactionDetails.Customer.FirstName + ' ' : '') + (record.TransactionDetails?.Customer?.LastName ? record.TransactionDetails.Customer.LastName : '') : '-'}</td>
                                }
                                { (this.props.reports.headers.company && this.props.reports.headers.company.display) &&
                                    <td>{record?.TransactionDetails?.Customer?.CompanyName ? record.TransactionDetails.Customer.CompanyName : '-'}</td>
                                }
                                { (this.props.reports.headers.billingEmail && this.props.reports.headers.billingEmail.display) &&
                                    <td>{record?.TransactionDetails?.Customer?.BillingEmail ? record.TransactionDetails.Customer.BillingEmail : '-'}</td>
                                }
                                { (this.props.reports.headers.payorPhone && this.props.reports.headers.payorPhone.display) &&
                                    <td>{record?.TransactionDetails?.Customer?.BillingPhone ? record.TransactionDetails.Customer.BillingPhone : '-'}</td>
                                }
                                { (this.props.reports.headers.billingAddress && this.props.reports.headers.billingAddress.display) &&
                                    <td>{(record.TransactionDetails?.Customer?.BillingAddress1 || record.TransactionDetails?.Customer?.BillingAddress2) ? (record.TransactionDetails?.Customer?.BillingAddress1 ? record.TransactionDetails.Customer.BillingAddress1 : '') + (record.TransactionDetails?.Customer?.BillingAddress2 ? record.TransactionDetails.Customer.BillingAddress2 : '') : '-'}</td>
                                }
                                { (this.props.reports.headers.shippingAddress && this.props.reports.headers.shippingAddress.display) &&
                                    <td>{(record.TransactionDetails?.Customer?.ShippingAddress1 || record.TransactionDetails?.Customer?.ShippingAddress2) ? (record.TransactionDetails?.Customer?.ShippingAddress1 ? record.TransactionDetails.Customer.ShippingAddress1 : '') + (record.TransactionDetails?.Customer?.ShippingAddress2 ? record.TransactionDetails.Customer.ShippingAddress2 : '') : '-'}</td>
                                }
                                { (this.props.reports.headers.method && this.props.reports.headers.method.display) &&
                                    <td className='text-center'>{this.props.global.getPaymethodImgPaymentData(record?.TransactionDetails)}</td>
                                }
                                { (this.props.reports.headers.last4 && this.props.reports.headers.last4.display) && 
                                    <td>{ this.renderLast4Report(record?.TransactionDetails) }</td>
                                }
                                { (this.props.reports.headers.typeTrans && this.props.reports.headers.typeTrans.display) && 
                                    <td>{record?.TransactionDetails?.Operation ? record.TransactionDetails.Operation : '-'}</td>
                                }
                                { (this.props.reports.headers.status && this.props.reports.headers.status.display) && 
                                    <td>{this.props.global.getTransStatus(record?.TransactionDetails?.TransStatus)}</td>
                                }
                                { (this.props.reports.headers.settlementStatus && this.props.reports.headers.settlementStatus.display) && 
                                    <td>{this.props.global.getSettlementTransStatus(record?.TransactionDetails?.SettlementStatus, record?.TransactionDetails?.Method)}</td>
                                }
                                { (this.props.reports.headers.authCode && this.props.reports.headers.authCode.display) && 
                                    <td>{record?.TransactionDetails?.ResponseData?.authcode ? record.TransactionDetails.ResponseData.authcode : '-'}</td>
                                }                            
                                { (this.props.reports.headers.source && this.props.reports.headers.source.display) && 
                                    <td>{record?.TransactionDetails?.Source ? this.props.global.capitalizeFirstLetter(record.TransactionDetails.Source) : '-'}</td>
                                }
                                { (this.props.reports.headers.notes && this.props.reports.headers.notes.display) && 
                                    <td className='text-center'>
                                        {record?.TransactionDetails?.PaymentData?.orderDescription ? 
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>{record.TransactionDetails.PaymentData.orderDescription}</Tooltip>}
                                            >
                                                <BiNotepad className="label-info-icon in-input"/>
                                            </OverlayTrigger>
                                        : '-'}
                                    </td>
                                }
                                <td className='text-center sticky-row'>
                                    <DropdownButton
                                        menuAlign="right"
                                        title={<BiDotsVerticalRounded/>}
                                        data-qaid={''}
                                        id="actionsMenuButton"
                                        size="sm"
                                        variant="default"
                                    >
                                        <Dropdown.Item onClick={() => this.viewRecordDetails(i)}>View Details</Dropdown.Item>
                                        {record?.TransactionDetails?.Customer?.customerId &&
                                            <Dropdown.Item as={Link} to={ '/'+this.props.global.getURLEntry() +"/customer/"+ record?.TransactionDetails?.Customer?.customerId } data-qaid="">View Customer</Dropdown.Item>
                                        }
                                        {(record?.TransactionDetails?.PaypointEntryname && record.TransactionDetails.PaypointId) &&
                                            <Dropdown.Item as={Link} to={"/"+this.props.global.getURLEntry()+"/paypoints/overview/"+record.TransactionDetails.PaypointEntryname+"/"+record.TransactionDetails.PaypointId} data-qaid="">View Paypoint</Dropdown.Item>
                                        }
                                        <Dropdown.Divider />
                                        {(record.TransactionDetails?.TransStatus === 11 && record.TransactionDetails?.TransStatus === 1 && true) && (<>
                                            <Dropdown.Item onClick={() => {}}>Hold Transaction</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {}}>Hold Batch</Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>)
                                        }
                                        {record.Type !== 'payor' ? <Dropdown.Item onClick={() => this.handleShowModalConfirmation('block::customer', record)}>Block Customer</Dropdown.Item> : <Dropdown.Item onClick={() => this.handleShowModalConfirmation('unblock::customer', record)}>Unblock Customer</Dropdown.Item>}
                                        {record.Type !== 'card' ? <Dropdown.Item onClick={() => this.handleShowModalConfirmation('block::card', record)}>Block DDA</Dropdown.Item> : <Dropdown.Item onClick={() => this.handleShowModalConfirmation('unblock::card', record)}>Unblock DDA</Dropdown.Item>}
                                        {record.Type !== 'ip' ? <Dropdown.Item onClick={() => this.handleShowModalConfirmation('block::ip', record)}>Block IP Address</Dropdown.Item> : <Dropdown.Item onClick={() => this.handleShowModalConfirmation('unblock::ip', record)}>Unblock IP Address</Dropdown.Item>}
                                        <Dropdown.Item onClick={() => this.handleShowModalConfirmation('block::paypoint', record)}>Block Paypoint</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))
                    }
                        </tbody>
                    </table>
                    {this.props.reports?.records.length < 1 &&
                        <RecordsNotFound message="No transactions yet" description={<span>When your customers make payments in real time,<br/> you can track transaction details here.</span>}/>
                    }
                </div>
                <ReportPaginator report="fraudhistory"/>
                { this.renderRightPapel() }
                { this.renderModalConfirmation() }
            </div>
        )
    }
}

export { ViewPartialFraudAlerts };