import React from 'react';
import { inject, observer } from 'mobx-react';
import {IMaskInput} from 'react-imask';
import Table from 'react-bootstrap/Table'
import NumberFormat from 'react-number-format';
import { BiTrash } from 'react-icons/bi';

@inject('store', 'global', 'vTerminal', 'invoice')
@observer
class ItemTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputQtyError: false,
      actionInvoiceRemoveItem: false,
    };

    this.editItem = this.editItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.updateQty = this.updateQty.bind(this);
    this.handleItemQtyInput = this.handleItemQtyInput.bind(this);
    this.handleItemQtyInputWithoutZero = this.handleItemQtyInputWithoutZero.bind(this);
    this.handleItemUpValueInput = this.handleItemUpValueInput.bind(this);
    this.handleInvoiceLineMouseOver = this.handleInvoiceLineMouseOver.bind(this);
    this.handleInoviceLineMouseLeave = this.handleInoviceLineMouseLeave.bind(this);
    this.handleInvoiceLineMouseLeaveAmount = this.handleInvoiceLineMouseLeaveAmount.bind(this);
    this.handleInvoiceLineMouseOverAmount = this.handleInvoiceLineMouseOverAmount.bind(this);
  }

  filterDefaultElements(categories) {
    return categories.filter((item) => {
      const [, data] = item;
      if (data.type === 'quantity') return true;
      return false;
    });
  }

  calculateSubtotal(items) {
    return items.reduce((prev, [, data]) => {
      const { quantity, value } = data;
      return prev + value * quantity;
    }, 0);
  }

  editItem(itemData, position) {
    itemData.position = position;
    this.props.openModalEdit(itemData);
  }

  removeItem(position) {
    this.props.vTerminal.removeItemCategory(position);
    this.props.invoice.removeItem(position);
  }

  updateQty(action, position) {
    if (action === 'add') {
      this.props.vTerminal.handleItemQtyAdd(position);
    }

    if (action === 'subtract') {
      this.props.vTerminal.handleItemQtySubstract(position);
    }

    this.props.invoice.updateQuantity(action, position);
  }

  handleItemQtyInput(event){
    if(!this.state.inputQtyError){
        this.props.vTerminal.handleItemQtyInput(event);
        this.props.invoice.updateQuantityInput(event);
    }
  }

  handleItemUpValueInput(event, pos){
    if (event.value > 0) {
      this.props.vTerminal.handleUpValueInput(event, pos);
      this.props.invoice.updateUpValueInput(event, pos);      
    }
  }

  handleItemQtyInputWithoutZero(e) {
      if (e.target.value.length === 0) {
        this.setState({ inputQtyError: true });
      }else{
        this.setState({ inputQtyError: false });        
      }
  }

  handleInvoiceLineMouseOver(index){
    this.setState({
      actionInvoiceRemoveItem: {
          [index]: true
      }
    });
  }

  handleInoviceLineMouseLeave(index){
    this.setState({
      actionInvoiceRemoveItem: {
          [index]: false
      }
    });
  }

  handleInvoiceLineMouseOverAmount(e){
    e.target.focus();
  }

  handleInvoiceLineMouseLeaveAmount(e){
    e.target.blur();
  }

  render() {
    const items = this.props.invoice.items;
    const pay = this.props.invoice.pay;

    return (
      <>
        {items.length > 0 && (
        <div>
          <Table responsive className='mt-3'>
          <thead>
            <tr>
              <th className='text-left'>Product/service</th>
              <th className='text-right'>Unit Price</th>
              <th className='text-center'>Qty</th>
              <th className='text-right'>Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {items.map((data, idx) => (
            <tr 
              key={`item-${idx}`}
              onMouseOver={()=>this.handleInvoiceLineMouseOver(`item-${idx}`)} 
              onMouseLeave={()=>this.handleInoviceLineMouseLeave(`item-${idx}`)}
            >
              <td>
                <strong>{data.label ? data.label : "-"}</strong><br></br>
                {data.description && <span>{data.description}</span>}          
              </td>
              <td className='text-right'>
                <div
                  onMouseOver={(e)=>this.handleInvoiceLineMouseOverAmount(e)}
                  onMouseLeave={(e)=>this.handleInvoiceLineMouseLeaveAmount(e)}
                >
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    prefix="$"
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={4}
                    fixedDecimalScale={false}
                    value={parseFloat(data.value).toFixed(this.props.global.formatDecimalDynamics(data.value))}
                    className="form-control input-amount-upvalue"
                    style={{width: (data.value.toString().length + 2) + 'em'}}
                    placeholder="$0.00"
                    onValueChange={(e) => this.handleItemUpValueInput(e, idx)}
                    readOnly={false}
                  />
                </div>                
              </td>
              <td>
                <div className="input-group input-group-qty-inv" style={{width: data.quantity.toString().length + 3 + "em", margin: 'auto'}}>
                  <button className="btn btn-outline-light btn-sm" type="button" onClick={(e) => this.updateQty('subtract', idx)} style={{borderRadius: '8px 0px 0px 8px', border: '0'}}>-</button>
                  <IMaskInput
                      id={"amountItemQtyInput"+idx}
                      mask = {"00000000"}
                      className={ "form-control" }
                      value={String(data.quantity)}  
                      style={{ border: '0', fontSize: '12px' }}
                      onChange={                                    
                        (e) => this.handleItemQtyInput(e)
                      } 
                      onBlur={
                          (e) => this.handleItemQtyInputWithoutZero(e)
                      }     
                  />
                  <button className="btn btn-outline-light btn-sm" type="button" onClick={(e) => this.updateQty('add', idx)} style={{borderRadius: '0px 8px 8px 0px', border: '0'}}>+</button>
                </div>
              </td>
              <td className='text-right'>
                ${this.props.global.numberWithCommas(
                  parseFloat(data.value * data.quantity).toFixed(2) > 0 ? parseFloat(data.value * data.quantity).toFixed(2) : parseFloat(data.value * data.quantity).toFixed(this.props.global.formatDecimalDynamics(data.value * data.quantity))
                )}
              </td> 
              <td>
                {
                  this.state.actionInvoiceRemoveItem && this.state.actionInvoiceRemoveItem[`item-${idx}`] &&
                  <div>
                      <span onClick={() => this.removeItem(idx)}><BiTrash className="icon-trash-invoice" /></span>
                  </div>
                }  
              </td>
            </tr>
          ))}
          </tbody>
          </Table>
          <div className='text-right mr-2' style={{fontSize: '12px'}}>
              <strong className='mr-3'>Subtotal</strong> ${this.props.global.numberWithCommas(parseFloat(pay.subtotal).toFixed(2))}
              <br></br>
              <strong className='mr-3'>Total</strong> ${this.props.global.numberWithCommas(parseFloat(pay.subtotal).toFixed(2))}
          </div>
        </div>             
       )}
      </>
    );
  }
}

export default ItemTable;
