import React from "react";
import { inject, observer } from "mobx-react";
import { EditCustomer } from "../../../../../components/EditCustomer";
import { autorun } from "mobx";

@inject("store", "global", "vTerminal", "invoice")
@observer
class SectionCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchingCustomer: false,
      searchCustomerPanelIsOpen: false,
      customerToSearch: "",
    };

    this.clearTextCustomer = this.clearTextCustomer.bind(this);
    this.closeSearchCustomerPanelDelay =
      this.closeSearchCustomerPanelDelay.bind(this);
    this.searchCustomer = this.searchCustomer.bind(this);
    this.selectCustomer = this.selectCustomer.bind(this);
    this.updateCustomerToSearch = this.updateCustomerToSearch.bind(this);
  }

  clearTextCustomer() {
    this.setState({ customerToSearch: "" }, () => {
      this.props.vTerminal.clearCustomer();
      this.props.vTerminal.clearCustomerSelected();
      this.props.invoice.setCustomer({});
    });
  }

  closeSearchCustomerPanelDelay() {
    let _this = this;
    setTimeout(function () {
      _this.setState({ searchCustomerPanelIsOpen: false });
      if (Object.keys(_this.props.vTerminal.getCustomerSelected).length === 0) {
        _this.clearTextCustomer();
      }
    }, 600);
  }

  searchCustomer(e) {
    const { value } = e.target;

    this.setState({
      customerToSearch: value,
      searchCustomerPanelIsOpen: true,
    });

    if (value.length > 2) {
      this.setState({ searchingCustomer: true });
      this.props.vTerminal
        .searchingCustomer(value)
        .then((res) => {
          this.setState({ searchingCustomer: false });
        })
        .catch((error) => {
          this.setState({ searchingCustomer: false });
        });
    } else {
      this.props.vTerminal.clearCustomer();
      this.props.invoice.setCustomer({});
    }
  }

  updateCustomerToSearch() {
    this.props.invoice.setCustomer(this.props.vTerminal.getCustomerSelected);
    this.setState({
      customerToSearch: this.props.vTerminal.getSelectedCustomerString,
    });
  }

  selectCustomer(position, e) {
    e.preventDefault();
    this.props.invoice.setCustomer(this.props.vTerminal.customers[position]);
    this.props.vTerminal.selectCustomer(position);
    this.setState(
      {
        customerToSearch: this.props.vTerminal.getSelectedCustomerString,
        searchCustomerPanelIsOpen: false,
      },
      function () {
        this.props.vTerminal.clearCustomer();
      }
    );
  }

  componentDidMount() {
    this.disposeAutorun = autorun(() => {
      const { vTerminal } = this.props;
      const customerToSearchState = this.state.customerToSearch;
      if (vTerminal?.getCustomerSelected) {
        const selectedCustomerString = vTerminal.getSelectedCustomerString;
        const customerToSearch =
          selectedCustomerString !== "Firstname Lastname - Email"
            ? selectedCustomerString
            : customerToSearchState;
        this.setState({ customerToSearch: customerToSearch });
      }
    });
  }

  componentWillUnmount() {
    this.disposeAutorun();
  }

  render() {
    return (
      <>
        <h6 className="mb-3">Customer</h6>

        <div className="form-floating">
          <input
            className={
              !this.state.searchingCustomer
                ? "form-control search-input"
                : "form-control search-input loading"
            }
            autoComplete="off"
            onFocus={(e) => this.clearTextCustomer(e)}
            onBlur={(e) => this.closeSearchCustomerPanelDelay(e)}
            onChange={(e) => this.searchCustomer(e)}
            value={this.state.customerToSearch}
            id="customer"
            placeholder="Search for a customer"
          />
          <label htmlFor="customer">Find or add a customer...</label>

          <div
            className={
              this.state.searchCustomerPanelIsOpen
                ? "search-dropdown-menu"
                : "d-none search-dropdown-menu"
            }
          >
            <EditCustomer
              rewriteSelectedWithResponse={true}
              functionCallbackNew={this.updateCustomerToSearch}
              defaultName={this.state.customerToSearch}
              title={"Add customer"}
              label={"+ Add customer"}
              style={{ color: "#10A0E3" }}
              action={"new"}
            />
            {this.props.vTerminal.customers.map((record, i) => (
              <a
                key={i}
                href="/"
                onClick={(e) => this.selectCustomer(i, e)}
                className="dropdown-item"
              >
                {record.Firstname + " " + record.Lastname} - {record.Email}{" "}
                <br />
                <div className="separatorBetween">
                  {record.customerNumber ? (
                    <span className="small-small grey">
                      Customer # {record.customerNumber}
                    </span>
                  ) : (
                    ""
                  )}
                  {record.Company ? (
                    <span className="small-small grey">{record.Company}</span>
                  ) : (
                    ""
                  )}
                  {record.Address ? (
                    <span className="small-small grey">
                      {record.Address.substring(0, 20) + " ..."}
                    </span>
                  ) : (
                    ""
                  )}
                  {record.Phone ? (
                    <span className="small-small grey">{record.Phone}</span>
                  ) : (
                    ""
                  )}
                </div>
              </a>
            ))}
            {this.props.vTerminal.customers.length === 0 &&
              this.state.customerToSearch.length > 2 && (
                <div className="small-grey ml-2">No results found</div>
              )}
          </div>
        </div>
      </>
    );
  }
}

export { SectionCustomer };
