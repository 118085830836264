import { action, computed, makeObservable, observable, toJS } from "mobx";
import virtualTerminalStore from "./VirtualTerminalStore";
import globalStore from "./GlobalStore";

import axios from "axios";
import payabli from "../assets/images/payabli.png";
import { getBrightnessColor } from "../utils/getBrightnessColor";
import {
  PayabliCookieManager,
  PayabliStorageManager,
} from "../api/localStorageManager";

const FileDownload = require("js-file-download");

class InvoiceStore {
  globalStore = null;

  constructor(virtualTerminalStore, globalStore) {
    this.virtualTerminalStore = virtualTerminalStore;
    this.globalStore = globalStore;
    makeObservable(this);
  }

  invoiceId = null;
  paylinkId = null;

  @observable
  status = "new"; // '0:draft' | 1:'pending'

  @observable
  virtualTerminalStore;

  @observable
  defaultSettings = {};

  @observable
  paypointData = {};

  @observable
  customerSelected = {};

  @observable
  invoiceDetails = {
    invoiceNumber: null,
    invoiceDate: new Date(),
    dueDate: null,
    term: null,
    scheduleCheck: false,
    startDate: null,
    frequency: "onetime",
    endDate: null,
  };

  @observable
  items = [];

  @observable
  pay = {
    subtotal: 0,
    fee: 0,
    total: 0,
  };

  @observable
  notes = "";

  @observable
  advancedOptions = {
    paymentMethods: {},
  };

  @observable
  invoiceActionSelected = {
    action: "sendInvoice",
    data: {
      id: "withPayLink",
    },
  };

  @observable
  btnActionEnabled = false;

  @action updateStatusInvoice(status) {
    this.status = status;
  }

  @action
  reset() {
    this.invoiceId = null;
    this.paylinkId = null;
    this.status = "new";
    this.customerSelected = {};
    this.items = [];
    this.pay = {
      subtotal: 0,
      fee: 0,
      total: 0,
    };
    this.invoiceActionSelected = {
      action: "sendInvoice",
      data: {
        id: "withPayLink",
      },
    };
    this.btnActionEnabled = false;
    this.invoiceDetails = {
      invoiceNumber: null,
      invoiceDate: new Date(),
      dueDate: null,
      term: null,
      scheduleCheck: false,
      startDate: null,
      frequency: "onetime",
      endDate: null,
    };

    this.virtualTerminalStore.cleanCategories();
    this.virtualTerminalStore.clearCustomer();
    this.virtualTerminalStore.clearCustomerSelected();
  }

  @action
  saveDefaultSettings(settings) {
    this.defaultSettings = settings;
  }

  @action
  setPaypointData(data) {
    this.paypointData = data;
  }

  @action
  setDefaultSettings(forInvoicesSettings) {
    const valueLoaded = {};

    if (forInvoicesSettings !== null) {
      Object.values(forInvoicesSettings).forEach(({ key, value }) => {
        let valueParsed = value;
        if (value === "true") valueParsed = true;
        if (value === "false") valueParsed = false;
        if (value === "null") valueParsed = null;
        if (key === "dueDateCustom") valueParsed = new Date(value);

        valueLoaded[key] = valueParsed;
      });
    }

    this.defaultSettings = valueLoaded;
  }

  @action
  exportInvoiceFile(invoiceId, invoiceNumber) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(process.env.REACT_APP_URL_API + "Export/invoicePdf/" + invoiceId, {
        responseType: "blob",
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      })
      .then((res) => {
        FileDownload(
          res.data,
          "Invoice-" + (invoiceNumber ? invoiceNumber : "") + ".pdf"
        );
      })
      .catch((error) => {
        throw error;
      });
  }

  getTermText() {
    let array = this.getOptionsDueDate();
    let thisObj = this;
    let returnText = "";
    array.forEach(function (item) {
      if (item.value === thisObj.invoiceDetails.term) {
        returnText = item.text;
      }
    });
    return returnText;
  }

  getOptionsDueDate() {
    return [
      { value: "DOR", label: "Due on receipt", text: "Due on receipt" },
      { value: "NET10", label: "10 days after invoice date", text: "Net 10" },
      { value: "NET15", label: "15 days after invoice date", text: "Net 15" },
      { value: "NET20", label: "20 days after invoice date", text: "Net 20" },
      { value: "NET30", label: "30 days after invoice date", text: "Net 30" },
      { value: "NET45", label: "45 days after invoice date", text: "Net 45" },
      { value: "NET60", label: "60 days after invoice date", text: "Net 60" },
      { value: "NET90", label: "90 days after invoice date", text: "Net 90" },
      {
        value: "EOM",
        label: "Due end of this month",
        text: "End of this month",
      },
      {
        value: "1MFI",
        label: "1st of the month following the invoice date",
        text: "1st of the month",
      },
      {
        value: "5MFI",
        label: "5th of the month following the invoice date",
        text: "5th of the month",
      },
      {
        value: "10MFI",
        label: "10th of the month following the invoice date",
        text: "10th of the month",
      },
      {
        value: "15MFI",
        label: "15th of the month following the invoice date",
        text: "15th of the month",
      },
      {
        value: "20MFI",
        label: "20th of the month following the invoice date",
        text: "20th of the month",
      },
      { value: "custom", label: "Custom", text: "Custom date" },
    ];
  }

  getCalculateDueDateFromStartDateTerms() {
    let term = this.invoiceDetails.term;
    let startDate = new Date(this.invoiceDetails.startDate);
    if (term) {
      switch (term) {
        case "DOR":
          return startDate;
        case "NET10":
          startDate.setDate(startDate.getDate() + 10);
          return startDate;
        case "NET15":
          startDate.setDate(startDate.getDate() + 15);
          return startDate;
        case "NET20":
          startDate.setDate(startDate.getDate() + 20);
          return startDate;
        case "NET30":
          startDate.setDate(startDate.getDate() + 30);
          return startDate;
        case "NET45":
          startDate.setDate(startDate.getDate() + 45);
          return startDate;
        case "NET60":
          startDate.setDate(startDate.getDate() + 60);
          return startDate;
        case "NET90":
          startDate.setDate(startDate.getDate() + 90);
          return startDate;
        case "EOM":
          return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        case "1MFI":
          return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        case "5MFI":
          return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 5);
        case "10MFI":
          return new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            10
          );
        case "15MFI":
          return new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            15
          );
        case "20MFI":
          return new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            20
          );
        default:
          startDate.setDate(startDate.getDate() + 1);
          return startDate;
      }
    } else {
      return startDate;
    }
  }

  @action
  updateAdvancedOptions(data) {
    this.advancedOptions = {
      ...this.advancedOptions,
      ...data,
    };
  }

  @action
  updateInvoiceActionSelected(action, data) {
    this.invoiceActionSelected = {
      ...this.invoiceActionSelected,
      action,
    };
  }

  @action
  async getNextInvoice() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .get(`${process.env.REACT_APP_URL_API}Invoice/getNumber/${entryPoint}`, {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  getBrightnessColor(color, brightness) {
    return getBrightnessColor(color, brightness);
  }

  @action
  setCustomer(customer) {
    this.customerSelected = customer;
  }

  @action
  setInvoiceDetails(data) {
    this.invoiceDetails = {
      ...this.invoiceDetails,
      ...data,
    };
  }

  @action
  cleanItems() {
    this.items = [];
  }

  @action
  addItem(item) {
    this.items.push(item);
    this.calculatePay();
  }

  @action
  updateItem(item, position) {
    this.items[position] = {
      ...this.items[position],
      ...item,
    };
    this.calculatePay();
  }

  @action
  updateQuantity(action, position) {
    if (action === "add") {
      this.items[position].quantity = this.items[position].quantity + 1;
    }

    if (action === "subtract" && this.items[position].quantity > 1) {
      this.items[position].quantity = this.items[position].quantity - 1;
    }

    this.calculatePay();
  }

  @action
  updateQuantityInput(event) {
    var position = event.target.id.replace("amountItemQtyInput", "");
    event.target.value > 1
      ? (this.items[position].quantity = Number(event.target.value))
      : (this.items[position].quantity = 1);
    this.calculatePay();
  }

  @action
  updateUpValueInput(event, position) {
    if (event.value > 0) {
      this.items[position].value = event.value;
      this.calculatePay();
    }
  }

  @action
  removeItem(position) {
    this.items.splice(position, 1);
    this.calculatePay();
  }

  calculatePay() {
    const items = toJS(this.items);
    let subTotal = 0;
    items.forEach((item) => {
      subTotal += item.quantity * item.value;
    });

    this.pay = {
      ...this.pay,
      subtotal: subTotal,
      total: subTotal + this.pay.fee,
    };
  }

  @computed
  get isBtnActionEnabled() {
    // validate field minimum
    if (!this.customerSelected.customerId) return false;
    if (!this.invoiceDetails.dueDate || !this.invoiceDetails.invoiceNumber)
      return false;
    if (this.items.length === 0) return false;

    if (this.pay.total === 0) return false;

    // scheduled, has to have startDate
    if (this.invoiceDetails.scheduleCheck && !this.invoiceDetails.startDate)
      return false;

    // schedule, frequency different to One time has to have endData
    if (
      this.invoiceDetails.scheduleCheck &&
      this.invoiceDetails.frequency !== "onetime" &&
      !this.invoiceDetails.endDate
    )
      return false;

    // chargeCustomer
    if (this.invoiceActionSelected.action === "chargeCustomer") {
      const paymentMethod = this.virtualTerminalStore.paymentMethod;
      const paymentMethodData =
        this.virtualTerminalStore.paymentPage.paymentMethods;

      switch (paymentMethod) {
        case "savedcard":
        case "savedach":
          if (!paymentMethodData.paymentMethodSaved) return false;
          break;
        case "card":
          return this.validateCardData(paymentMethodData);
        case "ach":
          return this.validateAch(paymentMethodData);
        case "check":
          return this.validateCheck(paymentMethodData);
        case "cash":
          return true;
        case "device":
          return paymentMethodData?.device && paymentMethodData.device !== "";
        default:
          return false;
      }
    }

    return true;
  }

  validateCardData(paymentMethodData) {
    const validators = this.globalStore.validators;
    const {
      cardCvv,
      cardExpirationDate,
      cardHolderName,
      cardNumber,
      cardZipcode,
    } = paymentMethodData;

    // validate cardNumber
    if (
      validators.isEmpty(cardNumber) ||
      validators.isMaxLength(16, cardNumber) ||
      validators.stringValidator("card", cardNumber)
    ) {
      return false;
    }

    // validate cardExpirationDate
    if (
      validators.isEmpty(cardExpirationDate) ||
      validators.stringValidator("exp", cardExpirationDate)
    )
      return false;
    const expDateYear = cardExpirationDate.substr(-2);
    const expDateMonth = cardExpirationDate.substr(0, 2);
    const currentYear = new Date().getFullYear().toString().substr(-2);
    const currentMonth = new Date().getMonth().toString();

    if (parseInt(expDateYear) < parseInt(currentYear)) return false;

    if (
      (parseInt(expDateYear) === parseInt(currentYear) &&
        parseInt(expDateMonth) < parseInt(currentMonth) + 1) ||
      parseInt(expDateMonth) > 12
    ) {
      return false;
    }

    // validate CVV
    const ccType = this.globalStore.creditCardType(cardNumber);
    if (
      validators.isEmpty(cardCvv) ||
      (ccType === "american-express"
        ? validators.stringValidator("cvvamex", cardCvv)
        : validators.stringValidator("cvv", cardCvv))
    ) {
      return false;
    }

    // validate ZipCode
    if (
      validators.isEmpty(cardZipcode) ||
      validators.isMaxLength(7, cardZipcode) ||
      validators.stringValidator("zipcode", cardZipcode)
    ) {
      return false;
    }

    // validate Cardholder Name
    if (
      validators.isEmpty(cardHolderName) ||
      validators.stringValidator("alpha", cardHolderName)
    )
      return false;

    return true;
  }

  validateAch(paymentMethodData) {
    const validators = this.globalStore.validators;
    const { achAccountHolderName, achAccountType, achRouting, achAccount } =
      paymentMethodData;

    if (
      validators.isEmpty(achAccountHolderName) ||
      validators.isMaxLength(250, achAccountHolderName)
    )
      return false;

    if (
      validators.isEmpty(achAccountType) ||
      validators.isMaxLength(250, achAccountType)
    )
      return false;

    if (
      validators.isEmpty(achRouting) ||
      validators.isMaxLength(9, achRouting) ||
      validators.stringValidator("routing", achRouting)
    ) {
      return false;
    }

    if (
      validators.isEmpty(achAccount) ||
      validators.stringValidator("numbers", achAccount) ||
      validators.isMinLength(4, achAccount) ||
      validators.isMaxLength(17, achAccount)
    ) {
      return false;
    }

    return true;
  }

  validateCheck(paymentMethodData) {
    const validators = this.globalStore.validators;
    const { checkAccountHolderName, checkNumber } = paymentMethodData;

    if (
      validators.isEmpty(checkAccountHolderName) ||
      validators.isMaxLength(250, checkAccountHolderName)
    )
      return false;

    if (
      validators.isEmpty(checkNumber) ||
      validators.isMaxLength(250, checkNumber)
    )
      return false;

    return true;
  }

  @action
  async loadInvoice(invoiceId) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();

    const res = await axios.get(
      `${process.env.REACT_APP_URL_API}Invoice/${invoiceId}`,
      {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      }
    );
    const {
      invoiceNumber,
      invoiceStatus,
      paylinkId,
      paymentTerms,
      Customer,
      invoiceDate,
      invoiceDueDate,
      frequency,
      lastPaymentDate,
    } = res.data;

    this.invoiceId = invoiceId;
    this.status = invoiceStatus;
    const customerParsed = this.parseLoadCustomer(Customer);
    this.customerSelected = customerParsed;
    this.virtualTerminalStore.selectCustomerObject(customerParsed);
    this.invoiceDetails.invoiceNumber = invoiceNumber;
    this.invoiceDetails.term = paymentTerms;
    this.paylinkId = paylinkId;
    this.invoiceDetails["invoiceNumber"] = invoiceNumber;
    this.invoiceDetails["invoiceDate"] = new Date(invoiceDate);
    this.invoiceDetails["dueDate"] = new Date(invoiceDueDate);
    this.invoiceDetails["term"] = paymentTerms;
    this.invoiceDetails["frequency"] = frequency;
    this.invoiceDetails["endDate"] = new Date(lastPaymentDate);

    return res.data;
  }

  parseLoadCustomer(customer) {
    return {
      Firstname: customer.FirstName,
      Lastname: customer.LastName,
      email: customer.BillingEmail,
      customerId: customer.customerId,
      Company: customer.CompanyName,
      customerNumber: customer.CustomerNumber,
      // billing
      Address: customer.BillingAddress1,
      Address1: customer.BillingAddress2,
      City: customer.BillingCity,
      State: customer.BillingState,
      Zip: customer.BillingZip,
      Country: customer.BillingCountry,
      Phone: customer.BillingPhone,
      Email: customer.BillingEmail,
      // shipping
      ShippingAddress: customer.ShippingAddress1,
      ShippingAddress1: customer.ShippingAddress2,
      ShippingCity: customer.ShippingCity,
      ShippingState: customer.ShippingState,
      ShippingZip: customer.ShippingZip,
      ShippingCountry: customer.ShippingCountry,
    };
  }

  @action
  async saveInvoice(status) {
    const { customerData, invoiceData } = this.collectData(status);

    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    )
      ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
          .pEntry
      : "";
    return axios
      .post(
        `${process.env.REACT_APP_URL_API}Invoice/${entryPoint}`,
        {
          customerData,
          invoiceData,
        },
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        this.invoiceId = res.data.responseData;
        return res.data.responseData;
      })
      .catch((error) => {
        throw error;
      });
  }

  async updateInvoice(status) {
    const { customerData, invoiceData } = this.collectData(status);

    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .put(
        `${process.env.REACT_APP_URL_API}Invoice/${this.invoiceId}`,
        {
          customerData,
          invoiceData,
        },
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        return res?.data?.responseData;
      })
      .catch((error) => {
        throw error;
      });
  }

  async storeInvoice(state) {
    let invoiceId = null;
    if (this.status === "new") {
      invoiceId = await this.saveInvoice(state);
    } else {
      invoiceId = await this.updateInvoice(state);
    }

    return invoiceId;
  }

  @action
  async sendInvoiceWithPaylink(emails, state = 1) {
    // store invoice with status 1: Pending
    const invoiceId = await this.storeInvoice(state);

    // generate paylink
    let paylinkId;
    if (this.paylinkId) {
      paylinkId = this.paylinkId;
      await this.updatePaylink(paylinkId);
    } else {
      const paylinkData = await this.generatePaylink(invoiceId);
      paylinkId = paylinkData.responseData;
    }

    // send email - paylink
    let listEmails = `${this.customerSelected.Email};${emails}`;
    if (emails.trim() === "") {
      listEmails = listEmails.substring(0, listEmails.length - 1);
    }

    //refreshing PayLink
    await this.refreshPaylink(paylinkId);

    // no send paylink if invoice is recurring
    if (
      this.invoiceDetails.scheduleCheck === false ||
      (this.invoiceDetails.scheduleCheck === true &&
        this.invoiceDetails.frequency &&
        this.invoiceDetails.frequency.toLowerCase() === "onetime")
    ) {
      await this.sendPaylinkEmail(paylinkId, listEmails);
    }

    return true;
  }

  @action
  async sendInvoiceWithPaylinkV2(type, additionalEmails = []) {
    // store invoice with status 1: Pending
    const invoiceId = await this.storeInvoice(1);

    // generate paylink
    let paylinkId;
    if (this.paylinkId) {
      paylinkId = this.paylinkId;
      try {
        await this.updatePaylink(paylinkId);
      } catch (error) {
        console.error(error);
      }
    } else {
      const paylinkData = await this.generatePaylink(invoiceId);
      paylinkId = paylinkData.responseData;
    }

    //refreshing PayLink
    await this.refreshPaylink(paylinkId);

    // no send paylink if invoice is recurring
    const { scheduleCheck, frequency } = this.invoiceDetails;
    const isFrequencyOnetime = frequency?.toLowerCase() === "onetime";
    if (
      scheduleCheck === false ||
      (scheduleCheck === true && isFrequencyOnetime)
    ) {
      return await this.sendPaylink(paylinkId, type, additionalEmails);
    }

    return false;
  }

  @action
  async sendInvoiceWithoutPaylink(emails, state = 1) {
    const invoiceId = await this.storeInvoice(state);

    let listEmails = `${this.customerSelected.Email};${emails}`;
    if (emails.trim() === "") {
      listEmails = listEmails.substring(0, listEmails.length - 1);
    }
    await this.sendInvoiceEmail(invoiceId, listEmails);

    return true;
  }

  @action
  async saveInvoiceWithoutActions(emails, state = 1) {
    this.storeInvoice(state);
    return true;
  }

  @action
  async sendInvoiceWithoutStatus(emails) {
    if (this.invoiceId) {
      let listEmails = `${this.customerSelected.Email};${emails}`;
      if (emails.trim() === "") {
        listEmails = listEmails.substring(0, listEmails.length - 1);
      }
      await this.sendInvoiceEmail(this.invoiceId, listEmails);
      return true;
    }
    return false;
  }

  @action
  async generatePaylink(invoiceId) {
    const paylinkData = this.collectPaylinkData();

    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .post(
        `${process.env.REACT_APP_URL_API}PaymentLink/${invoiceId}`,
        {
          ...paylinkData,
        },
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  async updatePaylink(paylinkId) {
    const paylinkData = this.collectPaylinkData();

    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .put(
        `${process.env.REACT_APP_URL_API}PaymentLink/update/${paylinkId}`,
        {
          ...paylinkData,
        },
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  async sendPaylinkEmail(paylinkId, emails) {
    emails = emails.replace(/\s/g, "");
    emails = emails.replace("null;", "");
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(
        `${process.env.REACT_APP_URL_API}PaymentLink/send/${paylinkId}?mail2=${emails}`,
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  async sendPaylink(paylinkId, type, additionalEmails) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    const params = {
      channel: type,
      attachFile: true,
      additionalEmails: additionalEmails,
    };
    return axios
      .post(
        `${process.env.REACT_APP_URL_API}PaymentLink/push/${paylinkId}`,
        params,
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        const { status, data } = res;
        return { data, status };
      })
      .catch((error) => {
        if (error.response) {
          const { status, data } = error.response;
          return { data, status };
        } else {
          console.error(error);
          return error;
        }
      });
  }

  @action
  async sendInvoiceEmail(invoiceId, emails) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(
        `${process.env.REACT_APP_URL_API}Invoice/send/${invoiceId}?mail2=${emails}`,
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  async refreshPaylink(paylinkId) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(`${process.env.REACT_APP_URL_API}PaymentLink/refresh/${paylinkId}`, {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  async getPaymentLinkData(paylinkId) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(`${process.env.REACT_APP_URL_API}PaymentLInk/load/${paylinkId}`, {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getInvoiceType() {
    if (this.invoiceDetails.scheduleCheck === false) {
      return 0;
    } else if (
      this.invoiceDetails.frequency &&
      this.invoiceDetails.frequency.toLowerCase() === "onetime"
    ) {
      return 0;
    } else {
      return 1;
    }
  }

  collectData(status) {
    const customerData = {
      customerId: this.customerSelected.customerId,
      firstName: this.customerSelected.Firstname,
      lastName: this.customerSelected.Lastname,
      company: this.customerSelected.Company,
      customerNumber: this.customerSelected.customerNumber,
      // billing
      billingAddress1: this.customerSelected.Address,
      billingAddress2: this.customerSelected.Address1,
      billingCity: this.customerSelected.City,
      billingState: this.customerSelected.State,
      billingZip: this.customerSelected.Zip,
      billingCountry: this.customerSelected.Country,
      billingPhone: this.customerSelected.Phone,
      billingEmail: this.customerSelected.Email,
      // shipping
      shippingAddress1: this.customerSelected.ShippingAddress,
      shippingAddress2: this.customerSelected.ShippingAddress1,
      shippingCity: this.customerSelected.ShippingCity,
      shippingState: this.customerSelected.ShippingState,
      shippingZip: this.customerSelected.ShippingZip,
      shippingCountry: this.customerSelected.ShippingCountry,
      // additionalData
      additionalData: {
        ...toJS(this.customerSelected.AdditionalFields),
        // fields not included in API
        invoiceScheduled: this.invoiceDetails.scheduleCheck?.toString(),
        invoiceStartDate: this.invoiceDetails.startDate?.toString(),
        invoiceFrequency: this.invoiceDetails.frequency,
      },
      // identifierFields
      identifierFields: toJS(this.customerSelected.IdentifierFields),
    };
    const invoiceStartDate = this.invoiceDetails.startDate;
    const invoiceData = {
      invoiceNumber: this.invoiceDetails.invoiceNumber,
      invoiceDate: this.invoiceDetails.invoiceDate?.toISOString(),
      invoiceDueDate: this.invoiceDetails.dueDate?.toISOString(),
      invoiceEndDate:
        this.invoiceDetails.endDate === "untilcancelled"
          ? new Date(
              invoiceStartDate.getFullYear() + 20,
              invoiceStartDate.getMonth(),
              invoiceStartDate.getDate()
            )
          : this.invoiceDetails.endDate?.toISOString(),
      invoiceStatus: this.status === 2 ? 2 : status,
      invoiceType: this.getInvoiceType(),
      frequency: this.invoiceDetails.frequency,
      paymentTerms: this.invoiceDetails.term,
      // termsConditions: 'string',
      notes: this.advancedOptions.memoNote,
      tax: 0,
      discount: 0,
      invoiceAmount: this.pay.subtotal,
      // purchaseOrder: 'string',
      firstName: this.customerSelected.Firstname,
      lastName: this.customerSelected.Lastname,
      company: this.customerSelected.Company,
      shippingAddress1: this.customerSelected.ShippingAddress,
      shippingAddress2: this.customerSelected.ShippingAddress1,
      shippingCity: this.customerSelected.ShippingCity,
      shippingState: this.customerSelected.ShippingState,
      shippingZip: this.customerSelected.ShippingZip,
      shippingCountry: this.customerSelected.ShippingCountry,
      // shippingEmail: 'string',
      // shippingPhone: 'string',
      // summaryCommodityCode: 'string',
      // items
      items: [
        ...this.items.map((item) => {
          return {
            // itemProductCode: 'string',
            itemProductName: item.label,
            itemDescription: item.description,
            // itemCommodityCode: 'string',
            itemUnitOfMeasure: "quantity",
            itemCost: item.value,
            itemQty: item.quantity,
            itemTotalAmount: item.value * item.quantity,
            itemTaxAmount: 0,
            itemTaxRate: 0,
          };
        }),
      ],
    };

    return { customerData, invoiceData };
  }

  getFileNameAndType(filePath) {
    let ftype, filename;
    const arrFileName = filePath.split("/");
    filename = arrFileName.pop();
    const arrType = filename.split(".");
    ftype = arrType.pop();
    return { ftype: ftype, filename: filename };
  }

  @action
  getPageLogo() {
    let vftype, vfilename, vfurl, vfContent;

    const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    const pEntry = `${PayabliStorageManager.getEntryName()}_pEntry`;
    const entryPoint = encryptStorage.getItem(pEntry)?.pEntry
      ? encryptStorage.getItem(pEntry).pEntry
      : "";
    const pImgValue = encryptStorage?.getItem(`${entryPoint}_pImg`);

    if (typeof pImgValue === "string") {
      vfurl = new URL(pImgValue);
      const { ftype, filename } = this.getFileNameAndType(pImgValue);
      vftype = ftype ? ftype : "";
      vfilename = filename ? filename : "";
    } else if (payabli) {
      let base64string = payabli.split(",");
      if (base64string[1]) {
        vfContent = vfurl ? "" : base64string[1];
      }
    }

    return {
      ftype: vftype,
      filename: vfilename,
      furl: vfurl?.href,
      fContent: vfContent,
    };
  }

  collectPaylinkData() {
    const { paylinkHeader, paylinkDescription, paymentMethods, applePaybutton } =
      this.advancedOptions;
    const { visa, mastercard, discover, amex, eCheck, applePay } =
      paymentMethods;
    const { brandColor, contactUsEmail, contactUsPhone, contactUsText } =
      this.defaultSettings;
    const { ftype, filename, furl, fContent } = this.getPageLogo();

    return {
      logo: {
        enabled: true,
        order: 1,
      },
      page: {
        enabled: true,
        order: 2,
        header: paylinkHeader,
        description: paylinkDescription,
      },
      paymentMethods: {
        enabled: true,
        order: 3,
        header: "Payment Methods",
        allMethodsChecked: false,
        methods: {
          visa,
          mastercard,
          discover,
          amex,
          eCheck,
          applePay,
          googlePay: false,
          payPal: false,
          bitPay: false,
        },
      },
      messageBeforePaying: {
        enabled: true,
        order: 5,
        label: this.advancedOptions.memoNote,
      },
      paymentButton: {
        enabled: true,
        order: 6,
        label: "Pay",
      },
      review: {
        enabled: true,
        order: 4,
        header: "Review & Confirm Payment",
      },
      bills: {
        enabled: true,
        order: 0,
        billLink: {
          enabled: true,
          order: 0,
          label: "string",
        },
        viewBillDetails: {
          enabled: true,
          order: 0,
          label: "string",
        },
      },
      invoices: {
        enabled: true,
        order: 7,
        invoiceLink: {
          enabled: true,
          order: 8,
          label: "View invoice",
        },
        viewInvoiceDetails: {
          enabled: true,
          order: 9,
          label: "View invoice details",
        },
      },
      notes: {
        enabled: true,
        header: "none",
        placeholder: "none",
        value: this.advancedOptions.footerNote,
        order: 10,
      },
      contactUs: {
        enabled: true,
        order: 11,
        header: contactUsText,
        emailLabel: contactUsEmail,
        phoneLabel: contactUsPhone,
        paymentIcons: true,
      },
      payor: {
        enabled: false,
        order: 12,
        header: "none",
        fields: [
          {
            name: "none",
            label: "none",
            validation: "none",
            value: "none",
            required: false,
            display: false,
            fixed: false,
            identifier: false,
            order: 0,
            width: 0,
          },
        ],
      },
      settings: {
        color: brandColor ? brandColor : "#10A0E3",
        customCssUrl: "none",
        language: "none",
        redirectAfterApproveUrl: "none",
        redirectAfterApprove: true,
        pageLogo: {
          ftype: ftype || "",
          filename: filename || "",
          furl: furl?.toString() || "",
          fContent: fContent || "",
        },
      },
    };
  }

  @computed
  get getInvoiceLogo() {
    if (!this.globalStore.isLoading) {
      return (
        PayabliCookieManager.readCookie(
          `${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${
            process.env.REACT_APP_ENVIRONMENT
          }`
        ) +
          "?nocache=" +
          new Date().getTime() || payabli
      );
    }
  }
}

const invoiceStore = new InvoiceStore(virtualTerminalStore, globalStore);
export default invoiceStore;
