import React from 'react';
import { inject, observer } from 'mobx-react';
import { PayabliStorageManager } from '../api/localStorageManager';
import { BiDownload, BiMailSend, BiSpreadsheet, BiUserCheck } from 'react-icons/bi';

@inject('global','reports', 'invoice')
@observer
class QuickViewInvoice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
        this.exportInvoiceFile = this.exportInvoiceFile.bind(this);
    }

    exportInvoiceFile(invoiceId, invoiceNumber){
        if(invoiceId && invoiceNumber ){
            this.props.invoice.exportInvoiceFile(invoiceId, invoiceNumber);
        }
       
      }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions =  encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        const recordDetail = this.props.reports.getRecordDetails;
        return (
            <>
                <div className="small mb-5">
                <div className="row">
                    <div className="col-6">
                        <label className="header">Invoice #</label>
                    </div>
                    <div className="col-6">
                        {recordDetail && recordDetail.invoiceNumber ? recordDetail.invoiceNumber : '-'}
                    </div>
                </div>
                <div className='row'>
                    <div className="col-6">
                        <label className="header">Status</label>
                    </div>
                    <div className="col-6">
                        {this.props.global.getInvoicesStatus(recordDetail?.invoiceStatus)}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <label className="header">Created Date {" ("+this.props.global.getTimeZone('v1')+")"}</label>
                    </div>
                    <div className="col-6">
                        {recordDetail && recordDetail.createdAt ? this.props.global.stringDateFormat(recordDetail.createdAt) : '-'}
                        -
                        {recordDetail && recordDetail.createdAt ? this.props.global.stringTimeFormat(recordDetail.createdAt) : '-'}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <label className="header">Due Date {" ("+this.props.global.getTimeZone('v1')+")"}</label>
                    </div>
                    <div className="col-6">
                        {recordDetail && recordDetail.invoiceDueDate ? this.props.global.stringDateFormat(recordDetail.invoiceDueDate) : '-'}
                        -
                        {recordDetail && recordDetail.invoiceDueDate ? this.props.global.stringTimeFormat(recordDetail.invoiceDueDate) : '-'}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <label className="header">Payment Term</label>
                    </div>
                    <div className="col-6">
                        {recordDetail && recordDetail.paymentTerms ? recordDetail.paymentTerms : '-'}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="header">Frequency</label>
                    </div>
                    <div className="col-6">
                        {recordDetail && recordDetail.frequency ? this.props.global.capitalizeFirstLetterOfEachWord(recordDetail.frequency) : '-'}
                    </div>
                </div>
                {(recordDetail && recordDetail.notes) &&
                <div className="mb-3 mt-3 notes-horizontal-block">
                    <b>NOTES: </b>  {recordDetail.notes}
                </div>
                } 
                
                <div className='row mt-3 mb-2'>
                    <div className='col-12'>
                        <b>Amount</b>
                    </div>
                </div>
                <div className='row small mb-2'>
                    {(recordDetail && recordDetail.items) &&
                    <div className="col-12">
                        {recordDetail.items.map((record, i) => (
                            <div className="row mb-1" key={i}>
                                <div className="col-6">{record.itemProductName}</div>
                                <div className="col-2 text-right">${this.props.global.numberWithCommas((record.itemCost).toFixed(2))}</div>
                                <div className="col-2 text-center">x {record.itemQty ? record.itemQty : 1}</div>
                                <div className="col-2 text-right">${this.props.global.numberWithCommas(((record.itemQty ? record.itemQty : 1) * record.itemCost).toFixed(2))}</div>
                            </div>
                        ))
                        }
                    </div>
                    }
                </div>
             
                <div className="row mb-4">

                    <div className="col-12">
                        <div className="row mb-1">
                            <div className="col-6">
                                <b>Subtotal</b>
                            </div>
                            <div className="col-6 text-right">
                                {(recordDetail && recordDetail.invoiceAmount) ? this.props.global.numberWithCommasNegative(recordDetail.invoiceAmount.toFixed(2)) : '$0.00'}
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-4">
                                <b>Fee</b>
                            </div>
                            <div className="col-8 text-right">
                                {(recordDetail && recordDetail.feeAmount) ? this.props.global.numberWithCommasNegative(recordDetail.feeAmount.toFixed(2)) : '$0.00'}
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-4">
                                <b>Total</b>
                            </div>
                            <div className="col-8 text-right">
                            <b>{(recordDetail && recordDetail.invoiceAmount) ? this.props.global.numberWithCommasNegative(recordDetail.invoiceAmount.toFixed(2)) : '$0.00'}</b>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<h5 className="header mb-3">Payment Information</h5>
                
                <div className="card-v2 mb-3" style={{padding: "3em"}}>
                    




                </div>*/}                 
    
                <div className="text-center mb-4">
                <div className="btn-group" role="group">
                {userPermissions.indexOf("Invoices") !== -1 && 
                    <>
                    <a href={"/"+PayabliStorageManager.getEntryName()+"/invoices/" + recordDetail?.invoiceId} className="btn btn-outline-primary text-transform-normal">
                        <BiSpreadsheet/>
                        {(recordDetail && recordDetail.invoiceStatus !== 4 && recordDetail.invoiceStatus !== 2) ?
                        <> View/Edit Invoice</>
                        :
                        <> View Invoice</>
                        }
                    </a>
                    </>
                }
                
                {userPermissions.indexOf("Invoices") !== -1 && 
                    <>
                    {(recordDetail && recordDetail.invoiceStatus !== 4 && recordDetail.invoiceStatus !== 0 && recordDetail.frequency && recordDetail.frequency.toLowerCase() === "onetime") && 
                    <button onClick={() => this.props.handleSendInvoice(recordDetail)} type="button" className="btn btn-outline-primary"><BiMailSend/> Send Invoice</button>
                    }
                    </>
                }

                {userPermissions.indexOf("Invoices") !== -1 && 
                    <>
                    {(recordDetail && recordDetail.invoiceStatus !== 0) &&
                    <button onClick={() => this.exportInvoiceFile(recordDetail?.invoiceId, recordDetail?.invoiceNumber)} type="button" className="btn btn-outline-primary"><BiDownload/> Download Invoice</button>
                    }
                    </>
                }   
                
                </div>
                </div>
                <h5 className="header mb-3">Customer Information &nbsp;
                {(recordDetail && recordDetail.Customer) &&
                    <a className='float-end no-underline fw-normal' style={{fontSize: '12px'}} href={ '/'+this.props.global.getURLEntry() +"/customer/"+ recordDetail.Customer.customerId}> <BiUserCheck className='mr-1' style={{fontSize: '16px'}}/> Customer Details</a>
                }
                </h5>
                <div className="row mb-4">
                    <div className="col-6">
                        <label className="header">Name</label>
                    </div>
                    <div className="col-6">
                        {(recordDetail && recordDetail.Customer ) ? (recordDetail.Customer.FirstName?recordDetail.Customer.FirstName:"") + ' ' + (recordDetail.Customer.LastName?recordDetail.Customer.LastName:"") : '-'}
                    </div>
                    <div className="col-6">
                        <label className="header">Customer #</label>
                    </div>
                    <div className="col-6">
                        {(recordDetail && recordDetail.Customer && recordDetail.Customer.CustomerNumber) ? recordDetail.Customer.CustomerNumber : "-" }
                    </div>
                   
                    <div className="col-6">
                        <label className="header">Email</label>
                    </div>
                    <div className="col-6">
                        {(recordDetail && recordDetail.Customer && recordDetail.Customer.BillingEmail) ? recordDetail.Customer.BillingEmail : "-" }
                    </div>
                    <div className="col-6">
                        <label className="header">Phone #</label>
                    </div>
                    <div className="col-6">
                        {(recordDetail && recordDetail.Customer && recordDetail.Customer.BillingPhone) ? this.props.global.phoneNumberFormat(recordDetail.Customer.BillingPhone) : "-" }
                    </div>
                    <div className="col-6">
                        <label className="header">Billing Address</label>
                    </div>
                    <div className="col-6">
                        {recordDetail?.Customer?.BillingAddress1} {recordDetail?.Customer?.BillingAddress2} {recordDetail?.Customer?.BillingCity} {recordDetail?.Customer?.BillingState} {recordDetail?.Customer?.BillingZip}
                    </div>
                    <div className="col-6 mt-2">
                        <label className="header">Shipping Address</label>
                    </div>
                    <div className="col-6 mt-2">
                        {recordDetail?.Customer?.ShippingAddress1} {recordDetail?.Customer?.ShippingAddress2} {recordDetail?.Customer?.ShippingCity} {recordDetail?.Customer?.ShippingState} {recordDetail?.Customer?.ShippingZip}
                    </div>
                </div>

                <h5 className="header mb-3">Processing Information</h5>
                <div className="row mb-4">                   
                    <div className="col-3">
                        <label className="header">Source</label>
                    </div>
                    <div className="col-9">
                    {(recordDetail && recordDetail.paylinkId ) ? recordDetail.paylinkId : '-'}
                    </div>
                </div>
                {(recordDetail && recordDetail.billEvents && recordDetail.billEvents.length > 0) &&
                <>
                    <h5 className="header mb-3">Invoice History</h5>
                    <div className='timeLineRecordContainer'>
                    {recordDetail.billEvents.map((record, i) => (                            
                        <div className='timeLineRecordItem' key={i}>
                            <div className="timeLineRecord">&nbsp;</div>
                            <div className="timeLineRecordText"><b>{record.description}</b><br/>{this.props.global.stringDateFormat(record.eventTime)} <span className="grey">{this.props.global.stringTimeFormat(record.eventTime)} {"("+this.props.global.getTimeZone('v1')+")"}</span></div>
                            <br/>
                        </div>                            
                    ))}
                    </div>
                </>
                }                       
                </div>
            </>
        );
    }
}

export { QuickViewInvoice };