import React from "react";
import { inject, observer } from "mobx-react";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { PayabliStorageManager } from "../api/localStorageManager";

@inject("store", "global")
@observer
class PaymenPagePublishActions extends React.Component {
  constructor(props) {
    super(props);
    this.savePage = this.savePage.bind(this);
  }

  savePage() {
    if (
      this.props.store.getPageName === "" ||
      this.props.store.getPageName === null
    ) {
      this.props.store.setShowPaymentPageNameError(true);
      return;
    }
    if (
      this.props.store.getPaymentPageSubdomain === "" ||
      this.props.store.getPaymentPageSubdomain === null
    ) {
      this.props.store.setShowPaymentPageSubdomainError(true);
      return;
    }
    this.props.store.setShowPaymentPageNameError(false);
    this.props.global.setLoading(true);
    this.props.store
      .savePaymentPage()
      .then((result) => {
        this.props.global.setLoading(false);
        toast.success("Data saved successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-success-container",
        });

        this.props.store.setSaveAction("edit");
        //this.props.history.push("/builder/"+this.props.store.getPaymentPageSubdomain);
      })
      .catch((error) => {
        this.props.global.setLoading(false);
        let errorMessage =
          error.response && error.response.data.responseText
            ? error.response.data.responseText
            : "Something is Wrong!";
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-error-container",
        });
      });
  }

  render() {
    const PopoverTooltipPublish = (
      <Popover style={{ minWidth: "200px", maxWidth: "250px" }}>
        <Popover.Content>
          <p className="small m0">
            Must save before publish or preview a <br /> Payment Page
          </p>
        </Popover.Content>
      </Popover>
    );

    const subdomain = this.props.store.getPaymentPageSubdomain;
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entry =
      encryptStorage &&
      encryptStorage.getItem(
        `${PayabliStorageManager.getEntryName()}_pEntry`
      ) &&
      encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
        .pEntry
        ? encryptStorage.getItem(
            `${PayabliStorageManager.getEntryName()}_pEntry`
          ).pEntry
        : 0;
    return (
      <div className="row top-bar-sub">
        <div className="col"></div>
        <div className="col-8 text-right">
          <button
            className="btn btn-light"
            onClick={this.savePage}
            type="button"
          >
            Save
          </button>

          {this.props.store.getSaveAction === "new" ? (
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom"
              overlay={PopoverTooltipPublish}
            >
              <button className="btn btn-light" type="button">
                Preview
              </button>
            </OverlayTrigger>
          ) : (
            <a
              rel="noreferrer"
              href={
                "/" + this.props.global.getURLEntry() + "/preview/" + subdomain
              }
              target="_blank"
              className="btn btn-light"
              type="button"
            >
              Preview
            </a>
          )}

          {this.props.store.getSaveAction === "new" ? (
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom"
              overlay={PopoverTooltipPublish}
            >
              <button className="btn btn-success" type="button">
                Publish
              </button>
            </OverlayTrigger>
          ) : (
            <button
              onClick={() =>
                this.props.global.newTabRedirection(
                  process.env.REACT_APP_URL_PAYMENT_PAGE +
                    entry +
                    "/" +
                    subdomain
                )
              }
              className="btn btn-success"
            >
              Publish
            </button>
          )}
        </div>
        <ToastContainer transition={Bounce} />
      </div>
    );
  }
}

export { PaymenPagePublishActions };
