import { makeObservable, action } from "mobx";
import axios from "axios";
import { PayabliStorageManager } from "../api/localStorageManager";
import * as Sentry from "@sentry/react";

class UserStore {
  constructor() {
    makeObservable(this);
  }

  @action
  deleteUser(userId) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .delete(process.env.REACT_APP_URL_API + "User/" + userId, {
        headers: { requestToken: encryptStorage.getItem("pToken") },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateUserCredentials(user) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .put(
        process.env.REACT_APP_URL_API + "User/" + user.id,
        {
          email: user.email,
          pwd: user.pwd,
        },
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  updatePermissions(user, permissions, userPermissions) {
    let access = [];

    if (permissions) {
      permissions.forEach(function (item) {
        if (userPermissions.indexOf(item.permission) !== -1) {
          access.push({
            roleLabel: item.permission,
            roleValue: item.checked ? true : false,
          });
        }

        if (item.childs) {
          item.childs.forEach(function (item2) {
            if (userPermissions.indexOf(item2.permission) !== -1) {
              access.push({
                roleLabel: item2.permission,
                roleValue: item2.checked ? true : false,
              });
            }
            if (item2.childs) {
              item2.childs.forEach(function (item3) {
                if (userPermissions.indexOf(item3.permission) !== -1) {
                  access.push({
                    roleLabel: item3.permission,
                    roleValue: item3.checked ? true : false,
                  });
                }
              });
            }
          });
        }
      });
    }

    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .put(
        process.env.REACT_APP_URL_API + "User/" + user.id,
        {
          access: access,
        },
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  updateUserInfo(user) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .put(
        process.env.REACT_APP_URL_API + "User/" + user.id,
        {
          name: user.name,
          email: user.email,
          phone: user.phone,
          usrStatus: parseInt(user.usrStatus),
          timeZone: user.timeZone,
          additionalData: user.additionalData,
        },
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  updateUserMFA(id, enableMFA, enableMFAType) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .put(
        process.env.REACT_APP_URL_API + "User/mfa/" + id,
        {
          mfa: enableMFA,
          mfaMode: enableMFAType !== "" ? enableMFAType : "0",
        },
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  newUserInfo(user) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .post(
        process.env.REACT_APP_URL_API + "User",
        {
          name: user.name,
          email: user.email,
          phone: user.phone,
          usrStatus: parseInt(user.usrStatus),
          pwd: user.pwd,
          scope: user.scope,
          access: user.access,
          timeZone: user.timeZone,
          mfaData: {
            mfa: user.mfa ? user.mfa : false,
            mfaMode: user.mfaMode ? user.mfaMode : 0,
          },
        },
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  getUserFromApi(id, pEntry) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint =
      encryptStorage &&
      encryptStorage.getItem(
        `${PayabliStorageManager.getEntryName()}_pEntry`
      ) &&
      encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
        .pEntry
        ? encryptStorage.getItem(
            `${PayabliStorageManager.getEntryName()}_pEntry`
          ).pEntry
        : null;
    return axios
      .get(
        process.env.REACT_APP_URL_API +
          "User/" +
          id +
          "?entry=" +
          (pEntry ? pEntry : entryPoint) +
          "&level=2",
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  getPermissionsFromApi() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(process.env.REACT_APP_URL_API + "Tools/values/userRoles", {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  sendEmailToResetPassword(user) {
    return axios
      .post(
        process.env.REACT_APP_URL_API + "User/authreset",
        {
          email: user.email,
          entry: user.entry,
          entryType: 2,
        },
        {
          headers: { requestToken: process.env.REACT_APP_TOKEN },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  resetPassword(user, token) {
    return axios
      .put(
        process.env.REACT_APP_URL_API + "User/authpsw",
        {
          psw: user.psw,
        },
        {
          headers: { requestToken: token },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  validateToken(token) {
    return axios
      .post(
        process.env.REACT_APP_URL_API + "Tools/validate",
        {},
        {
          headers: { requestToken: token },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  newNotification(notification) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .post(process.env.REACT_APP_URL_API + "Notification", notification, {
        headers: { requestToken: encryptStorage.getItem("pToken") },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  bulkNewNotification(notifications) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .post(
        process.env.REACT_APP_URL_API + "Notification/addbulk",
        notifications,
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  deleteNotification(notificationId) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .delete(
        process.env.REACT_APP_URL_API + "Notification/" + notificationId,
        {
          headers: { requestToken: encryptStorage.getItem("pToken") },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  bulkDeleteNotification(notificationsIds) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .delete(process.env.REACT_APP_URL_API + "Notification/removebulk", {
        headers: { requestToken: encryptStorage.getItem("pToken") },
        data: notificationsIds,
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  getNotificationsFromApi() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(process.env.REACT_APP_URL_API + "Tools/values/notificationEvents", {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getReportsFromApi() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(process.env.REACT_APP_URL_API + "Tools/values/notificationReports", {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateNotification(notification){
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(
				process.env.REACT_APP_URL_API + 'Notification/' + notification.notificationId,
				notification,
				{
					headers: { requestToken: encryptStorage.getItem('pToken') }
				}
			)
			.then((res) => {
				return res
			})
			.catch((error) => {
				Sentry.captureException(error);
				throw error
			})
	}

  getUsers(filter) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    ).pEntry;
    return axios
      .get(
        process.env.REACT_APP_URL_API +
          "Query/users/point/" +
          entryPoint +
          (filter ? filter : ""),
        {
          headers: {
            requestToken: encryptStorage.getItem("pToken"),
          },
        }
      )
      .then((res) => {
        return res.data.Records;
      })
      .catch((error) => {
        throw error;
      });
  }

  getUsersByStatus(status) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(
      `${PayabliStorageManager.getEntryName()}_pEntry`
    ).pEntry;
    return axios
      .get(process.env.REACT_APP_URL_API + "Query/users/point/" + entryPoint, {
        headers: {
          requestToken: encryptStorage.getItem("pToken"),
        },
        params: {
          "status(eq)": status,
        },
      })
      .then((res) => {
        return res.data.Records;
      })
      .catch((error) => {
        throw error;
      });
  }

  hasAccess(path, permissionsArray) {
    if (permissionsArray) {
      switch (path) {
        case "/:entryUrl/paymentpages":
          return permissionsArray.indexOf("Payment Pages") !== -1
            ? true
            : false;
        case "/:entryUrl/builder/:subdomain?":
          return permissionsArray.indexOf("Payment Pages") !== -1
            ? true
            : false;
        case "/:entryUrl/settings/:subdomain?":
          return permissionsArray.indexOf("Payment Pages") !== -1
            ? true
            : false;
        case "/:entryUrl/receipt/:subdomain?":
          return permissionsArray.indexOf("Payment Pages") !== -1
            ? true
            : false;
        case "/:entryUrl/preview/:subdomain?":
          return permissionsArray.indexOf("Payment Pages") !== -1
            ? true
            : false;
        case "/:entryUrl/previewreceipt/:subdomain?":
          return permissionsArray.indexOf("Payment Pages") !== -1
            ? true
            : false;
        case "/:entryUrl/report/bills/:id?":
        case "/:entryUrl/report/bills/bill/:billNumber?":
        case "/:entryUrl/report/bills/vendor/:vendorNumber?":
        case "/:entryUrl/bills/new":
        case "/:entryUrl/bills/edit/:idBill":
        case "/:entryUrl/bills/new/:vendorNumber":
        case "/:entryUrl/bills/view/:idBill":
          return permissionsArray.indexOf("Bills") !== -1 ? true : false;
        case "/:entryUrl/report/approvals":
          return permissionsArray.indexOf("Approvals") !== -1 ? true : false;
        case "/:entryUrl/report/vendors":
        case "/:entryUrl/vendor/:id":
          return permissionsArray.indexOf("Vendors") !== -1 ? true : false;
        case "/:entryUrl/chargeback/:id/:command?":
          return permissionsArray.indexOf("Chargeback") !== -1 ? true : false;
        case "/:entryUrl/report/transactions/out/:id?":
          return permissionsArray.indexOf("Transaction") !== -1
            ? true
            : false;
        case "/:entryUrl/report/transaction/out/:billNumber":
          return permissionsArray.indexOf("MoneyOut Actions") !== -1
            ? true
            : false;
        case "/:entryUrl/report/batches/out":
        case "/:entryUrl/report/batches/transactions/out/:idBatch":
          return permissionsArray.indexOf("Transaction") !== -1
            ? true
            : false;
        case "/:entryUrl/report/transactions/:id/:gobackData?":
        case "/:entryUrl/report/transactions":
        case "/:entryUrl/report/transactions/id/:paymentTransId":
        case "/:entryUrl/report/transactions/idtransrefund/:idtransRefund":
          return permissionsArray.indexOf("Transaction") !== -1 ? true : false;
        case "/:entryUrl/report/batches":
        case "/:entryUrl/report/batches/:idBatch":
          return permissionsArray.indexOf("Batch") !== -1 ? true : false;
        case "/:entryUrl/report/autopays":
        case "/:entryUrl/report/autopays/:id/:command?":
          return permissionsArray.indexOf("Subscriptions") !== -1
            ? true
            : false;
        case "/:entryUrl/report/settlements/:batchNumber?":
          return permissionsArray.indexOf("Settlement") !== -1 ? true : false;
        case "/:entryUrl/report/chargebacks/:paymentTransId?":
          return permissionsArray.indexOf("Chargeback") !== -1 ? true : false;
        case "/:entryUrl/report/users":
        case "/:entryUrl/users/new":
          return permissionsArray.indexOf("Manage Users") !== -1 ? true : false;
        case "/:entryUrl/report/customers/:command?":
          return permissionsArray.indexOf("Customers") !== -1 ? true : false;
        case "/:entryUrl/customer/paymethods/:id/:command?":
          return permissionsArray.indexOf("Customers") !== -1 ? true : false;
        case "/:entryUrl/customer/:id":
          return permissionsArray.indexOf("Customers") !== -1 ? true : false;
        case "/:entryUrl/report/invoices":
          return permissionsArray.indexOf("Invoices") !== -1 ? true : false;
        case "/:entryUrl/invoicing":
          return permissionsArray.indexOf("Invoices") !== -1 ? true : false;
        case "/:entryUrl/invoices/:id?":
          return permissionsArray.indexOf("Invoices") !== -1 ? true : false;
        case "/:entryUrl/vterminal/:paymentType?":
          return permissionsArray.indexOf("Virtual Terminal") !== -1
            ? true
            : false;
        case "/:entryUrl/:entry/login":
          console.log('did it enter here');
          return true;
        case "/:entryUrl/:entry/sso":
          return true;
        case "/:entryUrl/:entry/forgotpassword":
          return true;
        case "/:entryUrl/:entry/resetpassword/:token":
          return true;
        case "/:entryUrl/riskcontrol/reports":
          return true;
        case "/:entryUrl/:entry/Logout":
          return true;
        case "/:entryUrl/dashboard":
          return permissionsArray.indexOf("Dashboard") !== -1 ? true : false;
        case "/:entryUrl/profile/:id":
          return permissionsArray.indexOf("Profile") !== -1 ? true : false;
        case "/:entryUrl/developers":
        case "/:entryUrl/developers/apitokens":
        case "/:entryUrl/developers/creator":
          return permissionsArray.indexOf("Developers") !== -1 ? true : false;
        case "/:entryUrl/developers/sftpaccounts":
        case "/:entryUrl/developers/reports":
          return permissionsArray.indexOf("Xchange") !== -1 ? true : false;
        case "/:entryUrl/fields":
          return permissionsArray.indexOf("Custom Fields") !== -1
            ? true
            : false;
        case "/:entryUrl/notifications":
          return permissionsArray.indexOf("Notifications") !== -1
            ? true
            : false;
        case "/:entryUrl/paypoint":
          return permissionsArray.indexOf("Paypoints") !== -1 ? true : false;
        case "/:entryUrl/report/devices":
        case "/:entryUrl/report/catalog":
          return permissionsArray.indexOf("Virtual Terminal") !== -1
            ? true
            : false;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
}

const userStore = new UserStore();
export default userStore;
