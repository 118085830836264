import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ActionsLinks } from '../../components/ActionsLinks';
import { VirtualTerminal } from '../../components/VirtualTerminal';
import { Nav, Tab} from 'react-bootstrap';
import { Message } from '../../components/Message';
import { ToastContainer , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { Layout } from '../../components/Layout'
import { PayabliStorageManager } from '../../api/localStorageManager';

@inject('global','vTerminal')
@observer
class ViewVirtualTerminal extends React.Component {

    
    componentDidMount() {
        let paymentType = this.props.match.params.paymentType;
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        
        if(paymentType === "autopay" && userPermissions.indexOf("Autopay") !== -1 ){
            this.props.vTerminal.disableAutopay(true);
        }
         
    }

    reloadPage(){
        window.location.reload();
    }

    render() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];

        return (
            <Layout {...this.props}>
            <div>

                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <ActionsLinks selected="vterminal"/>
                </div>
                </TopBar>
                

                <div className="mt-body2">

                <div className="d-flex flex-column justify-content-center align-items-center">
                <div id="vterminal-container" className="card" >
                    <div className="card-body main-cont-3 main-card-body">

                    <Tab.Container defaultActiveKey="requestPayment">
                        <Nav fill variant="tabs" defaultActiveKey="requestPayment" className="vterminal-tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="requestPayment" data-qaid="requestPaymentVirtualTerminal">Request a Payment</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="payBill">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pay a Bill&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="requestPayment">
                            {(userPermissions.indexOf("Accept Payments") !== -1 || userPermissions.indexOf("Autopay") !== -1 ) ?
                                <VirtualTerminal paymentSuccessFunctionCallBack={this.reloadPage} autopay={userPermissions.indexOf("Accept Payments") === -1 || userPermissions.indexOf("Autopay") === -1 ? 0 : ''}/>
                            :
                                <Message message="Sorry. You can't accept payments."  />
                            }
                            </Tab.Pane>
                            <Tab.Pane eventKey="payBill">
                                <div style={{marginTop: "30px"}}>
                                    <Message message="Coming Soon."  />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                      

                    </div>
                </div>
                </div>
           
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { ViewVirtualTerminal };