import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiPaperPlane } from 'react-icons/bi';
import { GiReceiveMoney } from 'react-icons/gi';

import HowVendorGetPaid from './HowVendorGetPaid';

@inject('store', 'global', 'bill', 'vTerminal')
@observer
class SectionPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentSelected: 'askVendor',
    };

    this.handleChangePayment = this.handleChangePayment.bind(this);
  }

  handleChangePayment(e) {
    const { value } = e.target;
    this.setState({ paymentSelected: value });

    if (value === 'askVendor') {
      this.props.bill.updateBillActionSelected('askVendor', {});
    }

    if (value === 'howVendorGetsPaid') {
      this.props.bill.updateBillActionSelected('howVendorGetsPaid', {});
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="section-form">
        <h6 className="mb-3">Payment Delivery</h6>

        <div className="payment-option with-separator">
          <div className="radio-selection">
            <div className="icheck-primary">
              <input
                name="payment"
                type="radio"
                id="askVendor"
                value="askVendor"
                onChange={(e) => this.handleChangePayment(e)}
                defaultChecked={this.state.paymentSelected === 'askVendor'}
                disabled={this.props.isView}
              />
              <label htmlFor="askVendor">&nbsp;</label>
            </div>
          </div>

          <div className="radio-description">
            <div className="icon">
              <BiPaperPlane />
            </div>
            <div className="info">
              <div className="headline">Send vendor payment link</div>
              <div className="subtitle">
                <span className="text">Vendor decides how to get paid</span>
              </div>
            </div>
          </div>
        </div>

        <div className="payment-option">
          <div className="radio-selection">
            <div className="icheck-primary">
              <input
                name="payment"
                type="radio"
                id="howVendorGetsPaid"
                value="howVendorGetsPaid"
                onChange={(e) => this.handleChangePayment(e)}
                defaultChecked={this.state.paymentSelected === 'howVendorGetsPaid'}
                disabled={this.props.isView}
              />
              <label htmlFor="howVendorGetsPaid">&nbsp;</label>
            </div>
          </div>

          <div className="radio-description">
            <div className="icon">
              <GiReceiveMoney />
            </div>
            <div className="info">
              <div className="headline">Select how vendor gets paid</div>
              <div className="subtitle">
                <span className="text">You decide how vendor gets paid</span>
              </div>
            </div>
          </div>
        </div>

        {this.state.paymentSelected === 'howVendorGetsPaid' && <HowVendorGetPaid />}
      </div>
    );
  }
}

export default SectionPayment;
