import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiEdit, BiUpload, BiChevronLeft } from "react-icons/bi";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import 'jquery-ui-bundle';

import ppThumb from '../../assets/images/paymentPageThumbnail.png';
import { TopBar } from '../../components/TopBar';
import { PaymenPagePublishActions } from '../../components/PaymenPagePublishActions';
import { PaymenPageSections } from '../../components/PaymenPageSections';
import { PaymentPageName } from '../../components/PaymentPageName';
import { Layout } from '../../components/Layout'
import InputColor from 'react-input-color';
import { PaymentPageLogo } from '../../components/PaymentPageLogo';
import payabli from '../../assets/images/payabli.png';

import {Helmet} from "react-helmet";
import { PayabliStorageManager } from '../../api/localStorageManager'


@inject('store','global')
@observer
class Settings extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handlePaymentButtonTextChange = this.handlePaymentButtonTextChange.bind(this);
        this.handlePaymentButtonSize = this.handlePaymentButtonSize.bind(this);
        this.setColor = this.setColor.bind(this);
        this.handleFileRead = this.handleFileRead.bind(this);
        this.convertBase64 = this.convertBase64.bind(this);
        this.selectFileLogo = this.selectFileLogo.bind(this);
        this.handleRedirectAfterApprove = this.handleRedirectAfterApprove.bind(this);
        this.inputFileLogo = React.createRef();
        this.state = {
            fileError: false
        }
    }

    selectFileLogo(){
        this.inputFileLogo.current.click();
    }

    componentDidMount() {
        this.props.global.protect(this.props.history);
        var subdomain = this.props.match.params.subdomain;
        if(subdomain){
            if(this.props.store.paymentPage.name === ''){
                this.props.history.push(`/${this.props.global.getURLEntry()}/paymentpages`);
            }
        }
    }

    prevent(e) {
        e.preventDefault();
        this.handleTextChange = this.handleTextChange.bind(this);
    }    

    
    handleTextChange(event) {
        this.props.store.handlePPSettingsTextChange(event.target.id ,event.target.value);
    }

    handlePaymentButtonTextChange(event) {
        this.props.store.handlePPSettingsPaymentButtonTextChange(event);
    }

    handlePaymentButtonSize(event){
        this.props.store.handlePPSettingsPaymentButtonSize(event);
    }

    handleRedirectAfterApprove(event){
        this.props.store.handleRedirectAfterApprove(event);
    }
    
    setColor(e){
        this.props.store.setPPSettingsColor(e.hex);
    }

    handleFileRead(e){
        let file = e.target.files[0];
        if(file){
            let fileExtension = this.getFileExtension(file.type);

            if( (fileExtension === "jpeg" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "gif") && file.size <= 2000000){
                this.convertBase64(file).then(base64 => {
                    let base64string = base64.split(',');
                    if(base64string[0] &&  base64string[1]){
                        this.props.store.setLogo(base64string[1],fileExtension);
                    }
                    this.props.store.setPreviewLogo(base64);
                    this.setState({fileError: false});
                })
                .catch(error => {
                    console.log(error);
                });
            }
            else{
                this.setState({fileError: true});
            }
            
        }
        
    }

    getFileExtension(type){
        switch(type.toLowerCase()){
            case 'image/jpeg':
                return 'jpeg';
            case 'image/png':
                return 'png';
            case 'image/jpg':
                return 'jpg';
            default:
                return '';
        }
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
      }
      

    render() {
        const linkTheme = {
            color: this.props.store.paymentPageSettings.color,
        };
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ? 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0;
        const PopoverEditTitleDescription = (
            <Popover>
              <Popover.Content>
                    <div className="row mb-2">
                        <div className="col"><h6>Payment Button</h6></div>
                    </div>
                    <input name="header" value={this.props.store.paymentPageSettings.paymentButton.label} onChange={(e) => this.handlePaymentButtonTextChange(e)} type="text" className="form-control mb-3" placeholder="Button Label" />
                    

                    <label className="header">Choose button size</label>
                    <div className="row">
                        <div className="col-sm">
                            <div className="icheck-primary">
                            <input name="paymentButtonSize" value="sm" onChange={this.handlePaymentButtonSize} defaultChecked={this.props.store.paymentPageSettings.paymentButton.size === 'sm'} type="radio"  id="paymentButtonSmall" />
                            <label htmlFor="paymentButtonSmall">Small</label>
                            </div>
                        </div>

                        <div className="col-sm">
                            <div className="icheck-primary">
                            <input name="paymentButtonSize" value="md" onChange={this.handlePaymentButtonSize} defaultChecked={this.props.store.paymentPageSettings.paymentButton.size === 'md'} type="radio"  id="paymentButtonMedium" />
                            <label htmlFor="paymentButtonMedium">Medium</label>
                            </div>
                        </div>

                        <div className="col-sm">
                            <div className="icheck-primary">
                            <input name="paymentButtonSize" value="lg" onChange={this.handlePaymentButtonSize} defaultChecked={this.props.store.paymentPageSettings.paymentButton.size === 'lg'} type="radio"  id="paymentButtonLarge" />
                            <label htmlFor="paymentButtonLarge">Large</label>
                            </div>
                        </div>
                    </div>

                    <label className="header">Preview</label>
                    <div className="card card-in mb-3 payment-button-preview">
                        <div className="card-body text-center">
                            <button className={ "btn btn-primary text-transform-normal btn-"+this.props.store.paymentPageSettings.paymentButton.size }>{this.props.store.paymentPageSettings.paymentButton.label ? this.props.store.paymentPageSettings.paymentButton.label : 'Pay Now' }</button>
                            <br/>
                            <div className="powered-by-pp-button-preview">Powered by <img alt="" src={payabli} /></div>
                        </div>
                    </div>

                    <label className="header">HTML Code</label>
                    <p className="small small-grey">Copy & Paste this HTML in your site</p>
                   
                    <div className="html-code-example">
                    {'<div id="payabliComponentsPaymentPageButton"><a href="'+process.env.REACT_APP_URL_PAYMENT_PAGE+ entry +"/"+this.props.match.params.subdomain+'" class="btn btn-primary text-transform-normal btn-'+this.props.store.paymentPageSettings.paymentButton.size+'">'+ this.props.store.embeddedButtonLabel +'</a> <div class="powered-by-pp-button-preview">Powered by <img src="'+window.location.origin+'/payabli.png" /></div><link rel="stylesheet" href="'+window.location.origin+'/componentsStyle.css"></div>'}
                    </div>

                    <div className="popover-footer text-right">
                        <button className="btn btn-light btn-sm" type="button" onClick={() => document.body.click()}>Done</button>
                    </div>
              </Popover.Content>
            </Popover>
          );
        

        return (
            <Layout {...this.props}>
           
            <div>
            <TopBar>
                <PaymenPagePublishActions history={this.props.history} />
                <div className="top-bar-sub">
                    <div className="row">
                        <div className="col-5">
                        <a href={"/" + this.props.global.getURLEntry() + "/paymentpages"} className="btn btn-light text-transform-normal"><BiChevronLeft/> Payment Pages</a>
                        </div>
                       
                        <div className="col-6 text-center hide">
                            {/*
                            <a href="/"><BiDollarCircle/> Charge Amounts</a>
                            <a href="/"><BiIdCard/> Payer Details</a>
                            <a href="/"><BiWallet/> Pay Methods</a>
                            <a href="/"><BiListCheck/> Confirm</a>
                            */}
                        </div>
                        <div className="col-7 text-right">
                            <PaymenPageSections  selected="settings" />
                        </div>
                    </div>
                </div>
            </TopBar>
            <div className="mt-body2">

            <div className="d-flex flex-column justify-content-center align-items-center">
                 <div className="card" style={{width: '50em', padding: "1rem 2rem", marginBottom: "40px"}}>
                 <PaymentPageName/>
                </div>
            </div>

            <div className="d-flex flex-column sjustify-content-center align-items-center">
                <div className="card" style={{width: '50em'}}>
                    <div className="card-body  main-cont-2">
                        <h4 className="card-title mb-4">Payment Page Settings</h4>
                        
                        <input className="hide" ref={this.inputFileLogo} type="file" onChange={(e) => this.handleFileRead(e)} />

                        <div className="row">
                        <div className="col-sm-7">
                            <h6 className="sub-header mb-3">Branding</h6>
                            {this.state.fileError && 
                                <div className="small-small mb-3 text-danger">File size or extension not allowed</div>
                            }

                            
                            <div className="row">
                                <div className="col">
                                    <PaymentPageLogo/>
                                </div>
                                <div className="col text-right">
                                    <button onClick={(e)=>this.selectFileLogo()} className="btn btn-default bordered" type="button"><BiUpload/> Upload</button>
                                </div>
                            </div>
                            <p className="small-grey">
                            Max size- 2 MB | Format type – JPEG, JPG, PNG, GIF
                            </p>

                            <InputColor
                                initialValue={this.props.store.paymentPageColor}
                                onChange={(e) => this.setColor(e)}
                                placement="top"
                                className="color-picker"
                            />
  
                            <input type="text" className="form-control color-picker-input" placeholder={this.props.store.paymentPageColor} readOnly/>

                            <p className="small-grey">Select the color you would like your background, text, and highlights to appear</p>

                            <div className="form-floating mb-3">
                                <input value={this.props.store.paymentPageSettings.customCssUrl} type="text" id="customCssUrl" onChange={(e) => this.handleTextChange(e)} className="form-control" placeholder="Add Custom CSS Url" />
                                <label htmlFor="customCssUrl">Add Custom CSS URL</label>
                            </div>

                            <div className="form-floating mb-4">
                                <select disabled={true} className="form-select form-control" aria-label="Language">
                                    <option value="en" defaultValue>English</option>
                                </select>
                                <label htmlFor="floatingSelectGrid">Language</label>
                            </div>

                        </div>
                        <div className="col-sm-5 text-center">
                            <p className="small-grey" style={{marginBottom: '4px', marginTop: '8px'}}>Preview</p>
                            <div id="pp-color-preview" style={{backgroundColor: this.props.store.paymentPageColor}}>
                                <div className="button-cont">
                                    <img alt="" src={ppThumb} />
                                    <div className="button" style={{backgroundColor: this.props.store.paymentPageColor}}>Make a Payment</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                            <h6 className="sub-header mb-3">URLs and Payment Button</h6>
                            

                            <div className="row mb-4">
                                <div className="col-sm">
                                    
                            
                                    <div className="icheck-primary">
                                    <input id="customUrlRedirectCheck" checked={this.props.store.paymentPageSettings.redirectAfterApprove} type="checkbox" onChange={(e) => this.handleRedirectAfterApprove(e)}/>
                                    <label htmlFor="customUrlRedirectCheck">Redirect after approved payment?</label>
                                    </div>

                                    <div className="form-floating">
                                        <input disabled={!this.props.store.paymentPageSettings.redirectAfterApprove} id="redirectAfterApproveUrl" type="text" value={this.props.store.paymentPageSettings.redirectAfterApproveUrl} className="form-control" onChange={(e)=> this.handleTextChange(e)} placeholder="Redirect URL" />
                                        <label htmlFor="redirectAfterApproveUrl">Redirect URL</label>
                                    </div>
                                </div>
                                {/*<div className="col-sm">
                                    <div className="icheck-primary" style={{display: "inline-block"}}>
                                    <input defaultChecked={true} name="expire" type="radio"  id="neverExpire" />
                                    <label htmlFor="neverExpire">Never Expire</label>
                                    </div>

                                    <div className="icheck-primary" style={{display: "inline-block", marginLeft: "10px"}}>
                                    <input name="expire" type="radio"  id="expireDate" />
                                    <label htmlFor="expireDate">Set Expire Date</label>
                                    </div>

                                    <div className="form-floating">
                                        <input type="text" className="form-control input-calendar" placeholder="Expired Date" />
                                        <label htmlFor="expiredDate">Expired Date</label>
                                    </div>
                                </div>*/}
                            </div>

                                <b>Create Payment Button</b>
                                <OverlayTrigger rootClose key={1} trigger="click" placement="right" overlay={PopoverEditTitleDescription}>
                                    <BiEdit className="icon-edit" style={{marginLeft: '15px'}} />
                                </OverlayTrigger>
                                <p className="small-grey">Embed a payment button in your website</p>
                                



                            {/*<h6 className="sub-header mb-3">Notifications</h6>
                            Email Notifications
                            <p className="small-grey">Enter email address for contact that will receive payment receipts, batch reports, refund notifications, and other payment related noticed (enter multiple emails separated by commas)</p>
                            
                            
                            <div className="form-floating mb-4">
                                <input type="text" className="form-control" placeholder="Email Address" />
                                <label htmlFor="customUrlRedirect">Email Address</label>
                            </div>
                            
                            Select which emails you’d like to receive

                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="icheck-primary">
                                    <input id="notificationAllApprovePayments" type="checkbox" defaultChecked={true} />
                                    <label htmlFor="notificationAllApprovePayments">All approved payment emails</label>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="icheck-primary">
                                    <input id="notificationDailyBatchReport" type="checkbox" defaultChecked={true} />
                                    <label htmlFor="notificationDailyBatchReport">Daily Batch Reports</label>
                                    </div>
                                </div>
 
                                <div className="col-sm-3">
                                    <div className="icheck-primary">
                                    <input id="notificationOther" type="checkbox" defaultChecked={true} />
                                    <label htmlFor="notificationOther">Others...</label>
                                    </div>
                                </div>
                            </div>   
                    
                            */}
                    </div>
                    
                </div>
            
                <p className="small-small mt-5">Powered by  <a rel="noreferrer" style={linkTheme} target="_blank" href="https://www.payabli.com/" className="small no-underline">Payabli</a></p>
            </div>
            </div>
            </div>
            {(this.props.store && this.props.store.paymentPageSettings.customCssUrl) &&
            <Helmet>
            <link rel="stylesheet" href={this.props.store.paymentPageSettings.customCssUrl}/>
            </Helmet>
            }
            </Layout>
        );
    }
}

export { Settings };