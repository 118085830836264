import axios from "axios";
import { PayabliCookieManager, PayabliStorageManager } from "./localStorageManager";
import * as Sentry from "@sentry/react";

export class SecurityManager {
  // RefreshToken should use an actual refresh token instead of the access token
  static refreshToken = (accessToken) => {
    return axios
      .post(
        process.env.REACT_APP_URL_API + "user/authrefresh",
        {},
        {
          headers: { requestToken: accessToken },
        }
      )
      .then((res) => {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let pUser = encryptStorage.getItem("pUser");
        let remaining =
          new Date().getTime() + parseInt(res.data.remaining) * 60000;
        pUser.remaining = remaining;
        encryptStorage.setItem("pUser", pUser);

        PayabliStorageManager.setEncryptedLocalStorageKey(
          res.data.responseData
        );

        if(res.data.responseData){
          try{
              PayabliCookieManager.eraseCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`);
              PayabliCookieManager.createCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`, res.data.responseData, 1);
          }catch(e){
              Sentry.captureException(e);
              console.log(e);
          }
      }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log("setEncryptedLocalStorageKey", error);
        SecurityManager.logout();
      });
  };

  static logout = (pEntry) => {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    Sentry.setUser(null);

    if (Object.keys(pEntry).length === 0) {
      window.location = `/login`;
      return;
    }

    let accessToken = encryptStorage.getItem("pToken");
    PayabliCookieManager.eraseCookie(`${PayabliStorageManager.getEntryName()}_payabliLastTimeCookie_${process.env.REACT_APP_ENVIRONMENT}`);
    PayabliCookieManager.eraseCookie(`${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`);
    PayabliCookieManager.eraseCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`);
    PayabliCookieManager.eraseCookie(`creatorContainer_${process.env.REACT_APP_ENVIRONMENT}`);

    if (accessToken) {
      axios
        .get(process.env.REACT_APP_URL_API + "user/authlogout", {
          headers: { requestToken: accessToken },
        })
        .catch((error) => {
          Sentry.captureException(error);
        })
        .finally(() => {
          try {
            PayabliStorageManager.clearStorage(
              PayabliStorageManager.getEntryName(),
            );
          } catch (error) {}

          if (pEntry) {
            window.location = `/${pEntry}/login`;
          }
        });
    } else {
      if (pEntry) {
        window.location = `/${pEntry}/login`;
      }
    }
  };
}
