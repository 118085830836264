import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiCopy, BiReceipt, BiCog } from 'react-icons/bi';
import {Link} from "react-router-dom";

@inject('store', 'global')
@observer
class PaymenPageSections extends React.Component {

    render() {
        const subdomain = this.props.store.getPaymentPageSubdomain;
        return (
            <>
            <Link className={this.props.selected === 'builder' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/builder/"+ subdomain}><BiCopy/> Page</Link>
            <Link className={this.props.selected === 'receipt' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/receipt/"+ subdomain}><BiReceipt/> Receipt</Link>
            <Link className={this.props.selected === 'settings' ? 'selected' : ''} to={"/" + this.props.global.getURLEntry() +"/settings/"+ subdomain}><BiCog/> Settings</Link>
            </>
        )
    }
}

export { PaymenPageSections };