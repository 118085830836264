import React from 'react';
import { inject, observer } from 'mobx-react';
import { CardPaymentInformation } from './CardPaymentInformation';
import { PayabliStorageManager } from '../api/localStorageManager';
import { BiLinkExternal, BiMailSend, BiShare, BiXCircle } from 'react-icons/bi';
import { Modal, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IMaskInput } from 'react-imask';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import QuickViewSplitFundingInformation from './QuickViewSplitFundingInformation'
import { CgUnavailable } from 'react-icons/cg';

@inject('global', 'reports')
@observer
class QuickViewTransaction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            transIdToVoid: null,
            voidModalIsOpen: true,
            refundModalIsOpen: true,
            sendReceiptModalIsOpen: true,
            refundAmountMax: 0,
            refundAmountError: false,
        }
        this.voidTransaction = this.voidTransaction.bind(this);
        this.voidTransactionAction = this.voidTransactionAction.bind(this);
        this.refundTransaction = this.refundTransaction.bind(this);
        this.refundTransactionAction = this.refundTransactionAction.bind(this);
        this.openRefundModal = this.openRefundModal.bind(this);
        this.closeRefundModal = this.closeRefundModal.bind(this);
        this.openVoidModal = this.openVoidModal.bind(this);
        this.closeVoidModal = this.closeVoidModal.bind(this);
        this.openSendReceiptModal = this.openSendReceiptModal.bind(this);
        this.closeSendReceiptModal = this.closeSendReceiptModal.bind(this);
        this.sendReceiptTransactionAction = this.sendReceiptTransactionAction.bind(this);
        this.sendTransactionReceipt = this.sendTransactionReceipt.bind(this);
        this.handleRefundAmount = this.handleRefundAmount.bind(this);
        this.handleSendReceiptAddress = this.handleSendReceiptAddress.bind(this);
        this.renderViewFundingStatus = this.renderViewFundingStatus.bind(this);
        this.focus = this.focus.bind(this);
    }

    focus(e) {
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }

    handleSendReceiptAddress(value) {
        this.setState({ sendReceiptAddress: value });
        if (!this.props.global.validators.isEmpty(value) && !this.props.global.validators.stringValidator('email', value)) {
            this.setState({ sendReceiptError: false });
        }
        else {
            this.setState({ sendReceiptError: true });
        }
    }

    handleRefundAmount(value) {
        this.setState({ refundAmount: value });
        if (value < 0.01) {
            this.setState({ refundAmountError: true });
        }
        else {
            if (value > this.state.refundAmountMax) {
                this.setState({ refundAmountError: true });
            } else {
                this.setState({ refundAmountError: false });
            }
        }
    }

    sendTransactionReceipt(transaction, email) {
        this.setState({ sendReceiptAddress: email, transIdToSendReceipt: transaction, sendReceiptError: email ? false : true });
        this.openSendReceiptModal();
    }

    sendReceiptTransactionAction() {
        if (this.state.sendReceiptError) {
            return;
        }

        let transIdToSendReceipt = this.state.transIdToSendReceipt;
        let sendReceiptAddress = this.state.sendReceiptAddress;

        if (transIdToSendReceipt && sendReceiptAddress) {
            this.props.global.setLoading(true);
            this.props.reports.sendReceiptTransaction(transIdToSendReceipt, sendReceiptAddress)
                .then(result => {
                    this.setState({ sendReceiptModalIsOpen: true });
                    this.props.global.setLoading(false);
                    toast.success("Transaction Receipt Emailed Successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 7000,
                        className: 'toast-success-container data-qaid-transactionEmailedSuccessfullyAlert'
                    });
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
        }
    }

    openSendReceiptModal() {
        this.setState({ sendReceiptModalIsOpen: false });
    }

    closeSendReceiptModal() {
        this.setState({ sendReceiptModalIsOpen: true });
    }

    openVoidModal() {
        this.setState({ voidModalIsOpen: false });
    }

    closeVoidModal() {
        this.setState({ voidModalIsOpen: true });
    }

    openRefundModal() {
        this.setState({ refundModalIsOpen: false });
    }

    closeRefundModal() {
        this.setState({ refundModalIsOpen: true });
    }

    refundTransaction(transaction, totalAmount) {
        this.setState({
            transIdToRefund: transaction,
            refundAmount: String(totalAmount),
            refundAmountMax: totalAmount,
            refundAmountError: false
        });
        this.openRefundModal();
    }

    refundTransactionAction() {
        if (this.state.refundAmountError) {
            return;
        }

        let transIdToRefund = this.state.transIdToRefund;

        if (transIdToRefund) {
            this.props.global.setLoading(true);
            this.props.reports.refundTransaction(transIdToRefund, this.state.refundAmount)
                .then(result => {
                    this.setState({ refundModalIsOpen: true });
                    this.props.global.setLoading(false);
                    if (result.data.responseData.resultCode && result.data.responseData.resultCode !== 1) {
                        let message = result.data.responseText ? "Sorry, the payment was " + result.data.responseText : 'Sorry, we have a problem refunding your payment.';
                        toast.error(message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    }
                    else {
                        toast.success("Refund successful!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                    }
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
        }
    }

    voidTransaction(transaction) {
        this.setState({ transIdToVoid: transaction });
        this.openVoidModal();
    }

    voidTransactionAction() {
        let transIdToVoid = this.state.transIdToVoid;
        if (transIdToVoid) {
            this.props.global.setLoading(true);
            this.props.reports.voidTransaction(transIdToVoid)
                .then(result => {
                    this.setState({ voidModalIsOpen: true });
                    this.props.global.setLoading(false);
                    if (result.data.responseData.resultCode && result.data.responseData.resultCode !== 1) {
                        let message = result.data.responseText ? "Sorry, the operation was " + result.data.responseText : 'Sorry, we have a problem voiding the payment.';
                        toast.error(message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    }
                    else {
                        let records = this.props.reports.records;
                        records.forEach(function (item, index) {
                            if (item.PaymentTransId === transIdToVoid) {
                                records[index].TransStatus = 5;
                            }
                        });
                        toast.success("Voided successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                    }
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
        }
    }

    renderViewFundingStatus(recordDetail) {
        const settlementStatus = recordDetail?.SettlementStatus !== undefined && recordDetail?.SettlementStatus !== null ? recordDetail.SettlementStatus : recordDetail?.Status;
        if (!recordDetail || recordDetail.TransStatus === -1 || recordDetail.TransStatus === 2 || recordDetail.TransStatus === 3 || recordDetail.TransStatus === 5 ||
            !(recordDetail.Method && recordDetail.Method.toLowerCase() !== "check" && recordDetail.Method.toLowerCase() !== "cash")) {
            return <span className="badge bg-light"><CgUnavailable /> N/A</span>;
        }
        if (settlementStatus !== null) {
            if (settlementStatus < 0) {
                return (
                    <a href={"/" + this.props.global.getURLEntry() + "/report/chargebacks/" + recordDetail.PaymentTransId}>
                        {this.props.global.getSettlementTransStatus(settlementStatus, recordDetail)}
                    </a>
                );
            } else {
                return this.props.global.getSettlementTransStatus(settlementStatus, recordDetail);
            }
        }
        return <span className="badge bg-light"><CgUnavailable /> N/A</span>;
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        const recordDetail = this.props.recordDetail ? this.props.recordDetail : ( this.props.reports.getRecordDetails ? this.props.reports.getRecordDetails : null );
        return (
            <>
                {userPermissions.indexOf("Void") !== -1 &&
                    <Modal style={{ textAlign: "center" }} show={!this.state.voidModalIsOpen} onHide={this.closeVoidModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            <BiXCircle className="icon-modal" />
                            <h5 data-qaid="transactionMoneyInVoidPopUp">Void</h5>
                            <p className="small">Are you sure you want to void this transaction?</p>
                            <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeVoidModal()} data-qaid="cancelTransactionMoneyInButton">
                                Cancel
                            </Button>
                            &nbsp;&nbsp;
                            <Button className="btn" variant="primary" onClick={this.voidTransactionAction} data-qaid="voidTransactionMoneyInButton">
                                Void
                            </Button>
                        </Modal.Body>
                    </Modal>
                }

                <Modal style={{ textAlign: "center" }} show={!this.state.sendReceiptModalIsOpen} onHide={this.closeSendReceiptModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <BiMailSend className="icon-modal" />
                        <h5 data-qaid="transactionMoneyInSendReceiptPopUp">Send Receipt</h5>
                        <p className="small">Transaction Id:</p>
                        <p className="small-grey">{this.state.transIdToSendReceipt}</p>

                        <IMaskInput
                            mask={this.props.global.maskValidator('email')}
                            value={this.state.sendReceiptAddress}
                            unmask={true}
                            placeholder="Email"
                            className={this.state.sendReceiptError ? "form-control mb-3 input-error" : "form-control mb-3"}
                            onAccept={
                                (value, mask) => this.handleSendReceiptAddress(value)
                            }
                        />


                        <div className="row">
                            <div className="col">
                                <Button className="btn full-w  cancel-btn" variant="default" onClick={this.closeSendReceiptModal}>
                                    Cancel
                                </Button>
                            </div>
                            <div className="col">
                                <Button className="btn full-w" variant="primary" onClick={this.sendReceiptTransactionAction} data-qaid="sendTransactionMoneyInButton">
                                    Send
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {userPermissions.indexOf("Refund") !== -1 && (
                    <Modal style={{ textAlign: "center" }} show={!this.state.refundModalIsOpen} onHide={this.closeRefundModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            <BiShare className="icon-modal" />
                            <h5>Refund Payment</h5>
                            <p className="small">Transaction Id:</p>
                            <p className="small-grey">{this.state.transIdToRefund}</p>

                            <NumberFormat
                                onFocus={(e) => this.focus(e)}
                                thousandsGroupStyle="thousand"
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                fixedDecimalScale={true}

                                value={this.state.refundAmount}
                                placeholder={"Max amount $" + this.props.global.numberWithCommas(this.state.refundAmountMax.toFixed(2))}
                                className={this.state.refundAmountError ? "form-control input-money-v2 mb-3 input-error" : "form-control input-money-v2 mb-3"}
                                onValueChange={(values) => this.handleRefundAmount(values.value)}
                            />

                            <Button className="btn full-w" variant="primary" onClick={this.refundTransactionAction}>
                                Refund
                            </Button>
                        </Modal.Body>
                    </Modal>
                )}

                <div className="small mb-5">
                    <div className="row">
                        <div className="col-4">
                            <label className="header">Date & Time {" (" + this.props.global.getTimeZone('v1') + ")"}</label>
                        </div>
                        <div className="col-8">
                            {recordDetail && recordDetail.TransactionTime ? this.props.global.stringDateFormat(recordDetail.TransactionTime) : '-'}
                            -
                            {recordDetail && recordDetail.TransactionTime ? this.props.global.stringTimeFormat(recordDetail.TransactionTime) : '-'}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <label className="header">Type</label>
                        </div>
                        <div className="col-8">
                            {recordDetail && recordDetail.Operation ? recordDetail.Operation : '-'}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <label className="header">Payment status</label>
                        </div>
                        <div className="col-8">
                            { this.props.global.getTransStatus(recordDetail?.TransStatus) }
                        </div>
                        <div className="col-4">
                            <label className="header">Batch Status</label>
                        </div>
                        <div className="col-8">
                            {this.renderViewFundingStatus(recordDetail)}
                        </div>
                        {(recordDetail && recordDetail.Operation && recordDetail.Operation.toLowerCase() === "refund") &&
                            <>
                                <div className="col-4">
                                    <label className="header">Original Payment ID</label>
                                </div>
                                <div className="col-8">
                                    <a href={'/' + this.props.global.getURLEntry() + '/report/transactions/idtransrefund/' + recordDetail.RefundId}>{recordDetail.RefundId}</a>
                                </div>
                            </>
                        }

                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label className="header">Response</label>
                        </div>
                        <div className="col-8">
                            {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.responsetext) ? this.props.global.capitalizeFirstLetter(recordDetail.ResponseData.responsetext) : '-'}
                            {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.response) ? this.props.global.getIconTransactionResponse(recordDetail.ResponseData.response) : ''}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-4">

                        </div>
                        <div className="col-8">
                            {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.response_code_text) ? this.props.global.capitalizeFirstLetter(recordDetail.ResponseData.response_code_text) : ''}
                        </div>
                    </div>
                    <div className="row mb-4">
                        {(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.orderDescription) &&
                            <div className="col-12">
                                <label className="header">Notes:</label>
                                <p>
                                    {recordDetail.PaymentData.orderDescription}
                                </p>
                            </div>
                        }
                        {(recordDetail?.PaymentData?.paymentDetails?.categories) &&
                            <div className="col-12 mb-3">
                                {recordDetail.PaymentData.paymentDetails.categories.map((record, i) => (
                                    <React.Fragment key={i}>
                                    <div className="row mb-1" key={i}>
                                        <div className="col-4">{record.label}</div>
                                        <div className="col-3">${this.props.global.numberWithCommas((record.amount).toFixed(2))}</div>
                                        <div className="col-2">x {record.qty ? record.qty : 1}</div>
                                        <div className="col-3 text-right">${this.props.global.numberWithCommas(((record.qty ? record.qty : 1) * record.amount).toFixed(2))}</div>
                                    </div>
                                    {record.description &&
                                    <div className='row mt-1 mb-3'>
                                        <div className="col-4">
                                        Description
                                        </div>
                                        <div className="col-8">
                                        {record.description}
                                        </div>
                                    </div>
                                    }
                                    </React.Fragment>
                                ))}
                            </div>
                        }
                        <div className="col-12">
                            <div className="row mb-1">
                                <div className="col-4">
                                    <b>Amount</b>
                                </div>
                                <div className="col-8 text-right">
                                    {(recordDetail?.NetAmount) ? this.props.global.numberWithCommasNegative(recordDetail.NetAmount.toFixed(2)) : '$0.00'}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-4">
                                    <b>Fee</b>
                                </div>
                                <div className="col-8 text-right">
                                    {(recordDetail?.PaymentData?.paymentDetails?.serviceFee) ? this.props.global.numberWithCommasNegative(recordDetail.PaymentData.paymentDetails.serviceFee.toFixed(2)) : '$0.00'}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-4">
                                    <b>Total</b>
                                </div>
                                <div className="col-8 text-right">
                                    <b>{(recordDetail?.PaymentData?.paymentDetails?.totalAmount) ? this.props.global.numberWithCommasNegative(recordDetail.PaymentData.paymentDetails.totalAmount.toFixed(2)) : '$0.00'}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
						(Array.isArray(recordDetail?.splitFundingInstructions) && recordDetail?.splitFundingInstructions?.length > 0) ? 
						<QuickViewSplitFundingInformation splitFundingInstructions={recordDetail.splitFundingInstructions} /> : null
					}
                    <h5 className="header mb-3">Payment Information</h5>
                    {(recordDetail?.PaymentData?.MaskedAccount?.toLowerCase() === "poi") ?
                        <div className="card-v2 mb-3" style={{ padding: "3em" }}>
                            <div className="row">
                                <div className="col-7">
                                    <label className="grey">Source</label>
                                    <h6 style={{ fontWeight: 500 }}>
                                        POI Device
                                    </h6>
                                </div>
                                <div className="col-5 text-right">
                                    {this.props.global.getGlobalImg('pointofsale', '30px')}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="card-v2 mb-3" style={{ padding: "3em" }}>
                            <div className="right-panel-card mb-4">
                                {this.props.global.getPaymethodImgPaymentData(recordDetail, '45px')}
                            </div>
                            <CardPaymentInformation recordDetail={recordDetail} />
                        </div>
                    }
                    <div className="text-center mb-4">
                        <div className="btn-group" role="group">
                            {userPermissions.indexOf("Void") !== -1 &&
                                <>
                                    {(recordDetail && (recordDetail.SettlementStatus === 0 && (recordDetail.TransStatus === 1 || recordDetail.TransStatus === 11))) &&
                                        <button onClick={() => this.voidTransaction(recordDetail.PaymentTransId)} type="button" className="btn btn-outline-primary"><BiXCircle /> Void</button>
                                    }
                                </>
                            }
                            {userPermissions.indexOf("Refund") !== -1 &&
                                <>
                                    {(recordDetail && recordDetail.SettlementStatus === 1) &&
                                        <button onClick={() => this.refundTransaction(recordDetail.PaymentTransId, recordDetail.NetAmount)} type="button" className="btn btn-outline-primary"><BiShare /> Refund</button>
                                    }
                                </>
                            }
                            <button onClick={() => this.sendTransactionReceipt(recordDetail.PaymentTransId, recordDetail.Customer.BillingEmail)} type="button" className="btn btn-outline-primary"><BiMailSend /> Send Receipt</button>
                        </div>
                    </div>
                    <h5 className="header mb-3">Customer Information &nbsp;
                        <OverlayTrigger placement="top" overlay={<Tooltip>View Customer</Tooltip>}>
                            <Link style={{ color: '#333333' }} to={recordDetail?.Customer ? '/' + this.props.global.getURLEntry() + "/customer/" + recordDetail.Customer.customerId : ''}><BiLinkExternal /></Link>
                        </OverlayTrigger>
                    </h5>
                    <div className="row mb-4">
                        <div className="col-3">
                            <label className="header">Customer</label>
                        </div>
                        <div className="col-9">
                            {(recordDetail && recordDetail.Customer) ? (recordDetail.Customer.FirstName ? recordDetail.Customer.FirstName : "") + ' ' + (recordDetail.Customer.LastName ? recordDetail.Customer.LastName : "") : '-'}
                        </div>
                    </div>

                    <h5 className="header mb-3">Processing Information</h5>
                    <div className="row mb-4">
                        <div className="col-3">
                            <label className="header">Transaction #</label>
                        </div>
                        <div className="col-9">
                            {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.transactionid) ? recordDetail.ResponseData.transactionid : '-'}
                        </div>

                        <div className="col-3">
                            <label className="header">Payment ID</label>
                        </div>
                        <div className="col-9">
                            {(recordDetail && recordDetail.PaymentTransId) ? recordDetail.PaymentTransId : '-'}
                        </div>

                        <div className="col-3">
                            <label className="header">CVV Response</label>
                        </div>
                        <div className="col-9">
                            {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.cvvresponse_text) ? recordDetail.ResponseData.cvvresponse_text : '-'}
                        </div>

                        <div className="col-3">
                            <label className="header">AVS Response</label>
                        </div>
                        <div className="col-9">
                            {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.avsresponse_text) ? recordDetail.ResponseData.avsresponse_text : '-'}
                        </div>
                        <div className="col-3">
                            <label className="header">Auth Code</label>
                        </div>
                        <div className="col-9">
                            {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.authcode) ? recordDetail.ResponseData.authcode : '-'}
                        </div>
                        <div className="col-3">
                            <label className="header">Source</label>
                        </div>
                        <div className="col-9">
                            {recordDetail?.Source ? recordDetail.Source.toUpperCase() : '-'}
                        </div>
                    </div>
                    {(recordDetail && recordDetail.TransactionEvents && recordDetail.TransactionEvents.length > 0) &&
                        <>
                            <h5 className="header mb-3">Transaction Event History</h5>
                            <div className='timeLineRecordContainer'>
                                {recordDetail.TransactionEvents.map((record, i) => (
                                    <div className='timeLineRecordItem' key={i}>
                                        <div className="timeLineRecord">&nbsp;</div>
                                        <div className="timeLineRecordText"><b>{record.TransEvent}</b><br />{this.props.global.stringDateFormat(record.EventTime)} <span className="grey">{this.props.global.stringTimeFormat(record.EventTime)} {"(" + this.props.global.getTimeZone('v1') + ")"}</span></div>
                                        <br />
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>
            </>
        );
    }
}

export { QuickViewTransaction };