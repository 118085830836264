import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import "./utils/axiosInterceptor";

const tracesSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE
);
const replaysSessionSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_REPLAY_SAMPLE_RATE
);
const replaysOnErrorSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_ONERROR_SAMPLE_RATE
);
const profilesSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_PROFILE_SAMPLE_RATE
);

let masking = {};
if (Boolean(process.env.REACT_APP_SENTRY_MASKING)) {
  masking = {
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
  };
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
    new Sentry.Replay(masking),
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/payabli\.com\/api/],
  // Performance Monitoring
  tracesSampleRate, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate,
});

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <App />
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
