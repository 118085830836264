import React from 'react';

class TopFixedMessage extends React.Component {
    render() {
        return (
            <div className="top-msg-container">{this.props.message}</div>
        );
    }
}

export { TopFixedMessage };