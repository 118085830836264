import React from 'react';
import { inject, observer } from 'mobx-react';
import {IMaskInput} from 'react-imask';

@inject('store')
@observer
class PaymentPageName extends React.Component {

    constructor(props) {
        super(props);
        this.handlePageNameChange = this.handlePageNameChange.bind(this);
    }

    handlePageNameChange(value) {
        this.props.store.setPageName(value);

        if(value){
            this.props.store.setShowPaymentPageNameError(false);
        }
        else{
            this.props.store.setShowPaymentPageNameError(true);
        }
    }
    
    handlePageSubdomainChange(value) {
    
        this.props.store.setPageSubdomain(value);
        
        if(value){
            this.props.store.setShowPaymentPageSubdomainError(false);
        }
        else{
            this.props.store.setShowPaymentPageSubdomainError(true);
        }
    }

    render() {
        return (
            <>
             <div className="row payment-page-name">
                        <div className="col-6">
                            <div className="form-floating">
                            <input  onChange={(e)=>this.handlePageNameChange(e.target.value)} value={this.props.store.paymentPage.name} placeholder="Enter page title here" type="text" id="paymentPageName" style={{fontSize:"12px"}} className={ this.props.store.getShowPaymentPageNameError ? "form-control input-error": "form-control"} />
                            <label>Page Name</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating">
                            <IMaskInput
                                mask={/^[a-zA-Z0-9_-]*$/}
                                id="paymentPageSubdomain"
                                style={{fontSize:"12px"}}
                                className={ this.props.store.getShowPaymentPageSubdomainError ? "form-control input-error": "form-control"} 
                                placeholder="Page Subdomain"
                                value={this.props.store.paymentPage.subdomain}
                                unmask={true}
                                disabled={ this.props.store.saveAction === "edit" ? true : false }
                                onAccept={
                                    (value, mask) => this.handlePageSubdomainChange(value)
                                }
                            />
                            <label>Page Subdomain</label>
                            </div>
                        </div>
                     </div>
            </>
        )
    }
}

export { PaymentPageName };