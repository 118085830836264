import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiXCircle, BiDisc, BiLoader, BiLogInCircle, BiDollarCircle } from 'react-icons/bi';
import { RenderTransactionDate } from '../../components/filters/RenderTransactionDate';
import { RenderFeeAmount } from '../../components/filters/RenderFeeAmount';
import { RenderSource } from '../../components/filters/RenderSource';
import { RenderTotalAmount } from '../../components/filters/RenderTotalAmount';
import { RenderPaymentInformationMo } from '../../components/filters/RenderPaymentInformationMo';
import { RenderVendorInformation } from '../../components/filters/RenderVendorInformation';
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'global', 'filter')
@observer
class ViewPartialTransactionsMo extends React.Component {

    render() {
        return (<>
                <RenderTransactionDate />
                <div>
                    <p><small>Payment Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))} data-qaid="cancelledStatusMoneyOut"><BiXCircle /> Cancelled</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-secondary" : "badge text-secondary"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))} data-qaid="capturedStatusMoneyOut"><BiDisc /> Captured</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 2, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[2]))} data-qaid="processingStatusMoneyOut"><BiLoader /> Processing</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]) ? "badge bg-info" : "badge text-info"} onClick={() => this.props.filter.handleStatus('status(in)', 3, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]))} data-qaid="processedStatusMoneyOut">{this.props.global.getGlobalImg((Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[3]) ?  'intransitinfodark' : 'intransitinfo', '12px')}  Processed</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 5, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[5]))} data-qaid="paidStatusMoneyOut"><BiDollarCircle /> Paid</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[11]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 11, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[11]))} data-qaid="authorizedStatusMoneyOut"><BiLogInCircle /> Authorized</span>
                    </div>
                </div>
                <div>
                    <ContainerShowMoreLess>
                        <div>
                            <hr className='mt-1 mb-3'></hr>
                            <RenderFeeAmount />
                            <RenderTotalAmount />
                            <div className='row'>
                                <div className="col-sm-6 col-xs-12 mb-4">
                                    <div className="form-floating">
                                        <input name="billNumber" id="billNumber" className={"form-control"} placeholder="Bill #" onChange={(e) => this.props.filter.handleCaptureTextFilter('billNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('billNumber(ct)', e)} value={this.props.filter.filterText.billNumber ? this.props.filter.filterText.billNumber : ''} data-qaid="billNumberTransactionMoneyOutInput"/>
                                        <label htmlFor="billNumber">Bill #</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ContainerShowMoreLess>
                </div>
                <div>
                    <RenderPaymentInformationMo />
                </div>
                <RenderVendorInformation />
                <div>
                    <h5 className='title'>Processing Information</h5>
                    <div className='row'>  
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="paymentId" id="paymentId" className={"form-control"} placeholder="Payment ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('paymentId(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('paymentId(ct)', e)} value={this.props.filter.filterText.paymentId ? this.props.filter.filterText.paymentId : ''} />
                                <label htmlFor="paymentId">Payment ID</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <input name="paymentgroup" id="paymentgroup" className={"form-control"} placeholder="Batch Number" onChange={(e) => this.props.filter.handleCaptureTextFilter('batchNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('batchNumber(ct)', e)} value={this.props.filter.filterText.paymentgroup ? this.props.filter.filterText.paymentgroup : ''} />
                                <label htmlFor="paymentgroup">Batch Number</label>
                            </div>
                        </div>
                    </div>
                </div>
                <ContainerShowMoreLess>
                    <div>
                        <hr className='mb-3 mt-1'></hr>
                        <div>
                            <RenderSource />
                        </div>
                    </div>
                </ContainerShowMoreLess>
        </>);
    }
}

export { ViewPartialTransactionsMo };