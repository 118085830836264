import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { SettingsLinks } from '../../components/SettingsLinks';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { BiTrash, BiDotsVerticalRounded } from 'react-icons/bi';
import { Modal, Button} from 'react-bootstrap';
import {IMaskInput} from 'react-imask';
import pointOfSale from '../../assets/images/pointOfService.svg';
import { PayabliStorageManager } from '../../api/localStorageManager';

@inject('reports', 'global', 'device')
@observer
class DevicesReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            responseList: [],
            newDeviceModalIsOpen: false,
            deleteModalIsOpen: false,
            deviceErrors: {},
            deviceDescription: '',
            deviceId: '',
            deviceToDelete: null,
            hasCredentials: false,
            hasCredentialsErrorMessage: '',
        };
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.closeNewDeviceModal = this.closeNewDeviceModal.bind(this);
        this.openNewDeviceModal = this.openNewDeviceModal.bind(this);
        this.saveDevice = this.saveDevice.bind(this);
        this.validateDeviceFields = this.validateDeviceFields.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.deleteDevice = this.deleteDevice.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteDeviceAction = this.deleteDeviceAction.bind(this);
        this.getStringDateFormatV3 = this.getStringDateFormatV3.bind(this);
        this.getStringTimeFormat = this.getStringTimeFormat.bind(this);
    }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.getReportFromApi();
        this.haveDevicePermission();
    }

    closeNewDeviceModal(){
        this.setState({ newDeviceModalIsOpen: false });
    }
    
    openNewDeviceModal(){
        this.setState({ newDeviceModalIsOpen: true,  deviceDescription: '', deviceId: '', deviceErrors: {}});
    }

    openDeleteModal(){
        this.setState({ deleteModalIsOpen: true });
    }

    closeDeleteModal(){
        this.setState({ deleteModalIsOpen: false });
    }

    deleteDeviceAction(){
        let deviceToDelete = this.state.deviceToDelete;
        if(deviceToDelete){
            this.props.global.setLoading(true);
            this.props.device.deleteDevice(deviceToDelete)
            .then(response => {
                this.closeDeleteModal();
                this.getReportFromApi();
                toast.success("Device deleted successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
                
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    haveDevicePermission(){
        this.props.global.setLoading(true);
        this.props.device.haveDevicePermission().then(res => {
            if (res) this.setState({hasCredentials: true})
            this.props.global.setLoading(false)
        })
        .catch(error => {
            this.props.global.setLoading(false)
        });
    }

    getReportFromApi(){
        this.props.global.setLoading(true);
        this.props.device.getDevicesFromApi().then(res => {
            if(res && res.responseList){
                this.setState({responseList: res.responseList}, function(){
                    this.props.global.setLoading(false);
                })
            }
            else{
                this.setState({responseList: []}, function(){
                    this.props.global.setLoading(false);
                })
            }     
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            this.setState({hasCredentials: false, hasCredentialsErrorMessage: errorMessage});
            this.props.global.setLoading(false);
        });
    }

    saveDevice(){
        let errors = this.validateDeviceFields();
        this.setState({ deviceErrors: errors }, function(){
            
            if(Object.keys(errors).length === 0){
                let device ={
                    description: this.state.deviceDescription,
                    id: this.state.deviceId
                };

                this.props.global.setLoading(true); 
                this.props.device.newDevice(device)
                .then(result => {
                    this.closeNewDeviceModal();
                    this.getReportFromApi();
                    toast.success("Device created successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Failed to add a device!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
        });
    }

    validateDeviceFields(){
        let validators = this.props.global.validators;
        let errors = {};
        if(validators.isEmpty(this.state.deviceDescription) || validators.isMaxLength(250, this.state.deviceDescription))
        {
            errors['deviceDescription']= true;
        }
        
        if(validators.isEmpty(this.state.deviceId) || validators.isMaxLength(250, this.state.deviceId))
        {
            errors['deviceId']= true;
        }
        return errors;
    }

    handleTextChange(key, value){
        this.setState({ [key]: value });
    }

    deleteDevice(id){
        this.setState({ deviceToDelete: id });
        this.openDeleteModal();
    }

    getStringDateFormatV3(date){
        return date ? this.props.global.stringDateFormatV3(date) : '-';
    }
    
    getStringTimeFormat(time){
        return time ? this.props.global.stringTimeFormat(time) : '';
    }

    
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];;
        return (
            <Layout {...this.props}>
      
            <div>

            {userPermissions.indexOf("Device") !== -1 &&           
                <Modal show={this.state.newDeviceModalIsOpen} onHide={this.closeNewDeviceModal}  size="md" centered >
                    <Modal.Body className="popover-body">
                        <div className="row mb-3">
                            <div className="col"><h6>Add Device</h6></div>
                        </div>
                        
                        <div className="row">
                            <div className="col-12 mb-3">
                            <div className="form-floating">
                                <IMaskInput
                                    mask={this.props.global.maskValidator('alphanumericspaces')}
                                    name="deviceDescription"
                                    value={this.state.deviceDescription}
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChange('deviceDescription', value)
                                    }
                                    placeholder="Device Description" 
                                    maxLength="250" 
                                    className={this.state.deviceErrors.deviceDescription ? "form-control input-error" : "form-control"}
                                    id="deviceDescription"
                                />                                
                                <label htmlFor="deviceDescription">Device Description</label>
                            </div>
                            </div>

                            <div className="col-12 mb-3">
                            <div className="form-floating">
                                <input  value={this.state.deviceId} id="deviceId" onChange={(e) => this.handleTextChange('deviceId', e.target.value)} className={this.state.deviceErrors.deviceId ? "form-control input-error" : "form-control"} placeholder="Device ID" maxLength="250"/>
                                <label htmlFor="deviceId">Device ID</label>
                            </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col">
                                <button className="btn full-w btn-light" type="button" onClick={() => this.closeNewDeviceModal() }>Cancel</button>
                            </div>
                            <div className="col">
                                <button className="btn full-w btn-primary" type="button" onClick={() => this.saveDevice()}>Save</button>   
                            </div>
                        </div>  
                    </Modal.Body>
                </Modal>
            }


            {encryptStorage && userPermissions.indexOf("Device") !== -1 && 
            <Modal style={{textAlign: "center"}} show={this.state.deleteModalIsOpen} onHide={this.closeDeleteModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiTrash className="icon-modal"/>
                    <h5>Delete</h5>

                    {
                        (encryptStorage && this.state.userToDelete === encryptStorage.getItem('pUser').id) ? 
                        <>
                        <p className="small">Sorry! You can not delete yourself.</p>
                        <Button className="btn" variant="primary" onClick={(e) => this.closeDeleteModal()}>
                            Close
                        </Button>
                        </>
                        :
                        <>
                         <p className="small">Are you sure you want to delete this device?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeDeleteModal()}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="danger" onClick={(e) => this.deleteDeviceAction()}>
                            Delete
                        </Button>
                        </>
                    }

                   
                </Modal.Body>
            </Modal>
            }
            <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <SettingsLinks history={this.props.history} selected="managedevices"/>
                </div>
            </TopBar>                
                <div className="mt-body4">
                <div className="row mb-4 datatable-actions">
                    <div className="col-6">
                        <div className="mr-3 inline">
                            <h6 style={{display: "inline-block"}}>Devices Management</h6>
                        </div>
                        <div className="small-small inline-block">
                            {this.state.hasCredentials === true &&
                                <>Total Count:  {this.state.responseList.length}</>
                            }
                        </div>
                    </div>
                    <div className="col-6 text-right">
                        {this.state.hasCredentials === true &&
                            <button onClick={(e) => this.openNewDeviceModal(e)} className="btn btn-default btn-bordered-success ml-2">+ Add Device</button>
                        }
                    </div>
                </div>
                <div className="report-container">
                    {this.state.hasCredentials === true &&
                    <>
                    <table className="table table-hover table-striped">
                        <thead>
                        <tr>
                            <th scope="col" >Device Model</th>
                            <th scope="col" >Description</th>
                            <th scope="col" >Device ID</th>
                            <th scope="col" >Serial #</th>
                            <th scope="col" >Created Date</th>
                            <th scope="col" >Last Connected Date</th>
                            <th scope="col" >Last Transaction Date</th>
                            <th scope="col" className="text-center sticky-row">Actions</th>
                        </tr>
                    </thead>
                    <tbody>                    
                    { this.state.responseList.map((record, i) => (
                            <tr key={i} className="cursorPointer">
                                <td><img alt="" style={{width: '26px', opacity: 0.2}} className="mr-1" src={pointOfSale}/> {record.make} {record.model}</td>
                                <td>{record.deviceNickName}</td>      
                                <td>{record.deviceId}</td>
                                <td>{record.serialNumber}</td>
                                <td>{this.getStringDateFormatV3(record.dateRegistered)}<br/>{this.getStringTimeFormat(record.dateRegistered)}</td>
                                <td>{this.getStringDateFormatV3(record.lastConnectedDate)}<br/>{this.getStringTimeFormat(record.lastConnectedDate)}</td>
                                <td>{this.getStringDateFormatV3(record.lastTransactionDate)}<br/>{this.getStringTimeFormat(record.lastTransactionDate)}</td>
                                <td className="text-center sticky-row">
                                    <DropdownButton
                                        menuAlign="right"
                                        title={<BiDotsVerticalRounded/>}
                                        id="actionsMenuButton"
                                        size="sm"
                                        variant="default"
                                    >
                                    {userPermissions.indexOf("Device") !== -1 &&     
                                    <>
                                    <Dropdown.Item style={{color:"rgb(192, 0, 0)"}} onClick={(e)=>this.deleteDevice(record.deviceId)} >Delete</Dropdown.Item>
                                    </>
                                    }
                                    
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))
                    }
                    
                    </tbody>
                    </table>
               
                    {(this.state.responseList.length < 1 && this.state.hasCredentials === true) &&
                        <RecordsNotFound btnFunction={() => this.openNewDeviceModal()} btnText="+ Add Device" message="No devices added " description={<span>Add a device that lets customers tap, swipe, or dip <br/>devices will appear on this report.</span>}/>
                    }
                    </>
                }
                {this.state.hasCredentials === false &&
                    <RecordsNotFound  message={this.state.hasCredentialsErrorMessage || "User permission disabled"} description={<span>Please contact your administrator to gain access to this report.</span>}/>
                }
                </div>
                </div>
            </div>

            <ToastContainer transition={Bounce} />
            </Layout>
        )
    }
}

export { DevicesReport };