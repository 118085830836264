import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { PayabliCookieManager, PayabliStorageManager } from '../api/localStorageManager'
import {GiSandsOfTime} from '@react-icons/all-files/gi/GiSandsOfTime';

class IdleTimeOutModal extends React.Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
        PayabliCookieManager.createCookie(`${PayabliStorageManager.getEntryName()}_payabliLastTimeCookie_${process.env.REACT_APP_ENVIRONMENT}`, new Date(), 1, true);
        this.props.handleClose();
    }

    render() {

        return (
        <Modal style={{textAlign: "center"}} show={this.props.showModal} onHide={this.handleClose} size="sm" centered>
            <Modal.Body>
                <GiSandsOfTime className="icon-modal"/>
                <h5>Your session is about to expire!</h5>
                <p className="small mb-3">Do you want to continue?</p>
                {this.props.countDown &&
                    <>
                    <div className='mb-3 small'>{`${this.props.countDown} minutes remaining.`}</div>
                    </>
                }
                <Button variant="light" onClick={this.props.handleLogout}>
                    Logout
                </Button>
                &nbsp;&nbsp;
                <Button variant="primary" onClick={this.handleClose}>
                    Continue
                </Button>
               
               
                
            </Modal.Body>
        </Modal>
        )
    }
}

export { IdleTimeOutModal };