import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiDollarCircle, BiGroup, BiIdCard } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { PayabliStorageManager } from '../api/localStorageManager'
import { BiReceipt, BiShoppingBag } from 'react-icons/bi';

@inject('reports', 'global')
@observer
class ActionsLinks extends React.Component {
  render() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
    return (
      <div className="row">
        <div className="col-12 text-center">
          <div className="hide-sm">
            {userPermissions.indexOf('Virtual Terminal') !== -1 && (
              <Link
                data-qaid="topBarMenuVirtualTerminalLink"
                className={
                  this.props.selected === 'vterminal' ? 'selected' : ''
                }
                to={'/' + this.props.global.getURLEntry() + '/vterminal'}
              >
                <BiDollarCircle /> Virtual Terminal
              </Link>
            )}
            {userPermissions.indexOf('Payment Pages') !== -1 && (
              <Link
                data-qaid="topBarMenuPaymentPagesLink"
                className={
                  this.props.selected === 'paymentPages' ? 'selected' : ''
                }
                to={'/' + this.props.global.getURLEntry() + '/paymentpages'}
              >
                <BiIdCard /> Payment Pages
              </Link>
            )}
            {userPermissions.indexOf('Customers') !== -1 && (
              <Link
                data-qaid="topBarMenuCustomersLink"
                className={
                  this.props.selected === 'customers' ? 'selected' : ''
                }
                to={'/' + this.props.global.getURLEntry() + '/report/customers'}
              >
                <BiGroup /> Customers
              </Link>
            )}
            {userPermissions.indexOf('Invoices') !== -1 && (
              <Link
                data-qaid="topBarMenuInvoicesLink"
                className={this.props.selected === 'invoices' ? 'selected' : ''}
                to={'/' + this.props.global.getURLEntry() + '/report/invoices'}
              >
                <BiReceipt /> Invoices
              </Link>
            )}
            {userPermissions.indexOf('Virtual Terminal') !== -1 && (
              <Link
                data-qaid="topBarMenuCatalogLink"
                className={this.props.selected === 'catalog' ? 'selected' : ''}
                to={'/' + this.props.global.getURLEntry() + '/report/catalog'}
              >
                <BiShoppingBag /> Catalog
              </Link>
            )}
            {/*<Link className={this.props.selected === 'invoices'? 'selected' : ''} to="/invoices"><BiWallet/> Invoices</Link>
                    <Link className={this.props.selected === 'bills'? 'selected' : ''} to={"/bills"}><BiListCheck/> Bills</Link>*/}
          </div>
          <div className="hide show-sm sm-screen-topbar-menu">
            {userPermissions.indexOf('Virtual Terminal') !== -1 && (
              <Link
                className={
                  this.props.selected === 'vterminal' ? 'selected' : ''
                }
                to={'/' + this.props.global.getURLEntry() + '/vterminal'}
              >
                <BiDollarCircle />
              </Link>
            )}
            {userPermissions.indexOf('Payment Pages') !== -1 && (
              <Link
                className={
                  this.props.selected === 'paymentPages' ? 'selected' : ''
                }
                to={'/' + this.props.global.getURLEntry() + '/paymentpages'}
              >
                <BiIdCard />
              </Link>
            )}
            {userPermissions.indexOf('Customers') !== -1 && (
              <Link
                className={
                  this.props.selected === 'customers' ? 'selected' : ''
                }
                to={'/' + this.props.global.getURLEntry() + '/report/customers'}
              >
                <BiGroup />
              </Link>
            )}
            {userPermissions.indexOf('Invoices') !== -1 && (
              <Link
                className={this.props.selected === 'invoices' ? 'selected' : ''}
                to={'/' + this.props.global.getURLEntry() + '/report/invoices'}
              >
                <BiReceipt />
              </Link>
            )}
            {userPermissions.indexOf('Virtual Terminal') !== -1 && (
              <Link
                className={this.props.selected === 'catalog' ? 'selected' : ''}
                to={'/' + this.props.global.getURLEntry() + '/report/catalog'}
              >
                <BiShoppingBag />
              </Link>
            )}            
            {/*<Link className={this.props.selected === 'invoices'? 'selected' : ''} to="/invoices"><BiWallet/> Invoices</Link>
                    <Link className={this.props.selected === 'bills'? 'selected' : ''} to={"/bills"}><BiListCheck/> Bills</Link>*/}
          </div>
        </div>
      </div>
    );
  }
}

export { ActionsLinks };
