import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { SettingsLinks } from '../../components/SettingsLinks';
import { TopBar } from '../../components/TopBar';
import { PayabliStorageManager } from '../../api/localStorageManager';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiChevronDown, BiChevronRight, BiInfoCircle, BiPlusCircle, BiTrashAlt } from 'react-icons/bi';

@inject('global', 'entry', 'user')
@observer
class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            notificationsMoneyOut: [],
            reports: [],
            savedNotifications: [],
            errors: [],
            reportsTimezone : 0,
            notificationsTimezone : 0,
            notificationsTimezoneMoneyOut : 0,
            moneyInSummaryReportsOpen : false,
            moneyInNotificationsOpen : false,
            moneyOutSummaryReportsOpen : false,
            moneyOutNotificationsOpen : false,
            countErrors: 0,
            notificationsToDelete: [],
            moneyInNotificationsOpenDeclined : false,
            paypointIsActive : true,
            paypointSettingsGeneral: null,
            sendApprovedReceipt: {
                key: "sendApprovedReceipt",
                readOnly: false,
                value: "true"
            },
            sendDeclinedReceipt: {
                key: "sendDeclinedReceipt",
                readOnly: false,
                value: "true"
            },
        };
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.getTooltipText = this.getTooltipText.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.saveReportsNotifications = this.saveReportsNotifications.bind(this);
        this.handleTextChangeNotifications = this.handleTextChangeNotifications.bind(this);
        this.handleCheckboxNotifications = this.handleCheckboxNotifications.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.showError = this.showError.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.checkByKey = this.checkByKey.bind(this);
        this.init = this.init.bind(this);
        this.selectReportsTimezone = this.selectReportsTimezone.bind(this);
        this.selectNotificationsTimezone = this.selectNotificationsTimezone.bind(this);
        this.selectNotificationsTimezoneMoneyOut = this.selectNotificationsTimezoneMoneyOut.bind(this);
        this.handleAccordionState = this.handleAccordionState.bind(this);
        this.handleCheckboxPaymentReceipt = this.handleCheckboxPaymentReceipt.bind(this);
        this.renderInputsNotification = this.renderInputsNotification.bind(this);
        this.addInputNotification = this.addInputNotification.bind(this);
    }

    handlePaypointSettingsGeneral(){
        let paypointSettingsGeneral = this.state.paypointSettingsGeneral;
        let addConfigSendApprovedReceipt = true;
        let addConfigSendDeclinedReceipt = true;
        let thisObj = this;

        paypointSettingsGeneral.forEach(function (item) {
            if(item.key === "sendApprovedReceipt"){
                item.value = String(thisObj.state.sendApprovedReceipt.value);
                addConfigSendApprovedReceipt = false;
            }

            if(item.key === "sendDeclinedReceipt"){
                item.value = String(thisObj.state.sendDeclinedReceipt.value);
                addConfigSendDeclinedReceipt = false;
            }
        });

        if(addConfigSendApprovedReceipt){
            paypointSettingsGeneral.push(this.state.sendApprovedReceipt);
        }
        
        if(addConfigSendDeclinedReceipt){
            paypointSettingsGeneral.push(this.state.sendDeclinedReceipt);
        }
    }

    handleCheckboxPaymentReceipt(e){
        let stateItem = this.state[e.target.id];
        stateItem.value = String(e.target.checked);
        this.setState({[e.target.id] : stateItem})
    }

    handleAccordionState(accordion){
        this.setState({[accordion]:!this.state[accordion]});
    }

    selectReportsTimezone(e){
        if(e){
            this.setState({reportsTimezone:e.target.value})
            let reports = this.state.reports;
            reports.forEach((item)=>{
                if(item.checked === true){
                    item.toUpdate = true;
                }
            });
            this.setState({reports:reports})
        }
    }
    
    selectNotificationsTimezone(e){
        if(e){
            this.setState({notificationsTimezone:e.target.value})
            let notifications = this.state.notifications;
            notifications.forEach((item)=>{
                if(item.type.toLowerCase() !== "boarding" && item.type.toLowerCase() !== "moneyout" && item.checked === true){
                    item.toUpdate = true;
                }
            });
            this.setState({notifications:notifications})
        }
       
    }

    selectNotificationsTimezoneMoneyOut(e){
        if(e){
            this.setState({notificationsTimezoneMoneyOut:e.target.value}, () => {
                let notifications = this.state.notifications;
                notifications.forEach((item)=>{
                    if(item.type.toLowerCase() === "moneyout" && item.checked === true){
                        item.toUpdate = true;
                    }
                });
                this.setState({notifications:notifications})
            })
            
        }
       
    }

    showError(error){
        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
        toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
        });
    }

    showSuccess(message){
        toast.success("Data saved successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-success-container'
        });
    }

    componentDidMount() {
        this.init();
    }

    init(showSuccess){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ? 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0;
        this.props.entry.getEntryFromApi(entry).then(res => {
            if(res && res.responseData && res.responseData.Paypoint && res.responseData.Paypoint.PaypointStatus !== 1){
                this.setState({paypointIsActive: false})
            }
         })
         .catch(error => {
             this.showError(error);
             this.props.global.setLoading(false);
         });
        
        this.props.entry.getPaypointSettings(entry).then(res => {
            if(res && res.general){
                let thisObj = this;
                let general = res.general;
                general.forEach(function (item) {
                    if(item.key === "sendApprovedReceipt"){
                        thisObj.setState({
                            sendApprovedReceipt: item
                        })
                        
                    }
        
                    if(item.key === "sendDeclinedReceipt"){
                        thisObj.setState({
                            sendDeclinedReceipt: item
                        })
                    }
                });

                this.setState({paypointSettingsGeneral: general})
            }
         })
         .catch(error => {
             this.showError(error);
             this.props.global.setLoading(false);
         });

        this.setState({
            notifications: [],
            reports: [],
            savedNotifications: [],
            notificationsToDelete: []
        },function(){
            this.props.global.setLoading(true);
            this.props.user.getReportsFromApi().then(res => {
                let reports = [];
                if(res){
                    res.forEach(function (item) {
                        if(item.type.toLowerCase() === 'boarding'){
                            return
                        }
                        reports.push({
                            type: item.type,
                            description: item.description,
                            name: item.name,
                            frequency: "",
                            emails: "",
                            checked: false,
                            notificationId: "",
                            toUpdate: false
                        })
                    });
                    this.setState({reports: reports}, function(){
                        this.props.user.getNotificationsFromApi().then(res => {
                            let notifications = [];
                            if(res){
                                res.forEach(function (item) {
                                    if(item.type.toLowerCase() !== "boarding"){
                                        notifications.push({
                                            type: item.type,
                                            description: item.description,
                                            name: item.name,
                                            frequency: "untilcancelled",
                                            emails: [{value:"", notificationId: ""}],
                                            phones: [{value:"", notificationId: ""}],
                                            webhooks: [{value:"", notificationId: ""}],
                                            checked: false,
                                            notificationId: "",
                                            checkedWebHeader: false,
                                            webHeaderParameters: [{key: '', value:''}],
                                            toUpdate: false
                                        })
                                    }
                                        
                                });
                                this.setState({notifications: notifications}, function(){
                                    let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0;
                                    this.props.entry.getNotificationsPaypoint(entry, -1, "untilcancelled").then(res => {
                                        if(res.Records){
                                            let reactObj = this;
                                            this.setState({
                                                savedNotifications : res.Records
                                            });
                                            res.Records.forEach(function (item) {
                                                if(item.content.eventType === null || 
                                                    item.content.eventType?.toLowerCase() === 'boarding'){
                                                    return
                                                }

                                                if(item.content.eventType){
                                                    reactObj.checkByKey(item);
                                                }
                                                if(item.method === "report-email"){
                                                    if(item.content.timeZone !== null){
                                                        reactObj.setState({reportsTimezone:String(item.content.timeZone)});
                                                    }
                                                }
                                                if(item.method === "email"){
                                                    if(item.content.timeZone !== null){
                                                        reactObj.setState({notificationsTimezone:String(item.content.timeZone)});
                                                    }
                                                    
                                                }
                                            });
    
                                        }
    
                                        this.props.global.setLoading(false);
                                        if(showSuccess === true){
                                            this.showSuccess("Data saved successfully!");
                                        }
                                    })
                                    .catch(error => {
                                        this.showError(error);
                                        this.props.global.setLoading(false);
                                    });
                                });
                            }
                        })
                        .catch(error => {
                            this.showError(error);
                            this.props.global.setLoading(false);
                        });
                    });
                }
            })
            .catch(error => {
                this.showError(error);
                this.props.global.setLoading(false);
            });
        });

        
    }

    checkByKey(obj){
        let notifications = this.state.notifications;
        let reports = this.state.reports;
        let reactObj = this;

        notifications.forEach(function (item) {
            if(item.name === obj.content.eventType){
                item.checked = true;
                item.notificationId = obj.notificationId;

                let values = {
                    value: (obj.target).replace(/ /g, ''),
                    notificationId: obj.notificationId
                };

                if(obj.method === "sms" && !item.phones.includes(obj.target)){
                    if(item.phones[0].value === ""){
                        item.phones[0] = values;
                    }
                    else{
                        item.phones.push(values);
                    }
                }
                if(obj.method === "web" && !item.webhooks.includes(obj.target)){
                    if(item.webhooks[0].value === ""){
                        item.webhooks[0] = values;
                    }
                    else{
                        item.webhooks.push(values);
                    }

                    item.checkedWebHeader = obj.content.webHeaderParameters !== null ? true : false;
                    item.webHeaderParameters = obj.content.webHeaderParameters !== null ? obj.content.webHeaderParameters : [{key: '', value:''}]
                }
                if(obj.method === "email" && !item.emails.includes(obj.target)){
                    if(item.emails[0].value === ""){
                        item.emails[0] = values;
                    }
                    else{
                        item.emails.push(values);
                    }
                }

                if(item.type.toLowerCase() === "moneyout"){
                    reactObj.setState({notificationsTimezoneMoneyOut:String(obj.content.timeZone)});
                }
            }
        });

        reports.forEach(function (item) {
            
            if(item.type.toLowerCase() === "boarding"){
                return;
            }

            if(item.name === obj.content.eventType){
                item.checked = true;
                item.notificationId = obj.notificationId;
                item.frequency = obj.frequency;
                item.emails =  obj.target.replaceAll(";", "; ");
            }
        });
        this.setState({notifications:notifications, reports:reports});
    }

    handleCheckbox(option, i){
        option.checked = !option.checked;
        option.toUpdate = true;
        let reports = this.state.reports;
        reports[i] = option;
        this.setState({reports : reports})
    }
    
    handleCheckboxNotifications(option, i){
        option.checked = !option.checked;
        option.toUpdate = true;
        let notifications = this.state.notifications;
        notifications[i] = option;
        this.setState({notifications : notifications})
    }

    handleCheckboxNotificationsWebHeader(option, i){
        option.checkedWebHeader = !option.checkedWebHeader;
        option.webHeaderParameters =[{key: '', value:''}]
        let notifications = this.state.notifications;
        option.toUpdate = true;
        notifications[i] = option;

        this.setState({notifications : notifications})
    }

    handleTextChange(emails, i){
        let reports = this.state.reports;
        let option = reports[i];
        option.toUpdate = true;
        option.emails = emails;
        reports[i] = option;
        this.setState({reports : reports})
    }

    handleSelect(frequency, i){
        let reports = this.state.reports;
        let option = reports[i];
        option.toUpdate = true;
        option.frequency = frequency;
        reports[i] = option;
        this.setState({reports : reports})
    }
    
    handleTextChangeNotifications(value, i, target, targetPosition, notificationName){
        let notifications = this.state.notifications;
        let errors = this.state.errors;
        let option = notifications[i];
        option.toUpdate = true;
        option[target][targetPosition]['value']= value;
        notifications[i] = option;
        
        this.setState({notifications : notifications, errors: errors})
    }
    handleTextChangeNotificationsWebHeader(value, i,index, target, notificationName){
        let notifications = this.state.notifications;
        let errors = this.state.errors;
        let option = notifications[i];
        option.toUpdate = true;
        option.webHeaderParameters[index][target] = value;
        notifications[i] = option;

        if(errors[notificationName] && errors[notificationName].webHeaderParameters && errors[notificationName].webHeaderParameters[index][target]){
            errors[notificationName].webHeaderParameters[index][target] = false;
        }
        
        this.setState({notifications : notifications, errors: errors})
    }
    
    addWebHeaders(index){
        let notifications = this.state.notifications;
        let option = notifications[index];
        option.webHeaderParameters.push({key: '', value:''})
        option.toUpdate = true;
        notifications[index] = option;
        this.setState({notifications : notifications})
    }

    removeWebHeaders(i,index){
        let notifications = this.state.notifications;
        let option = notifications[i];
        let webHeaderParameters = option.webHeaderParameters.filter((data, indexData) => indexData !== index)
        option.toUpdate = true;
        option.webHeaderParameters = webHeaderParameters
        notifications[i] = option;
        this.setState({notifications : notifications})
    }

    getTooltipText(key){
        switch(key){
            case "Transaction":
                return "Real-time report of all authorized, approved, refunded, and voided transactions.";
            case "Settlement":
                return "Reconciliation report that helps you match batches of transactions to actual settlement deposits in your bank account.";
            case "Returned":
                return "Credit card chargebacks and ACH returns will appear on this report.";
            case "Boarding":
                return "A status report of new PayPoints being boarded are compiled in this report.";
            case "ReceivedChargeBack":
                return "The payor has disputed the transaction with their bank.";
            case "ReceivedAchReturn":
                return "The ACH transaction was returned by the bank.";
            case "OriginatedPayment":
                return "The payment process has started and the payment is being verified by the bank.";
            case "SettledPayment":
                return "Funding has been initiated and settled with deposit bank.";
            case "FundedPayment":
                return "The payment has been sent to the processor to initiate funding.";
            case "CreatedApplication":
                return "A new application has been created.";
            case "SubmittedApplication":
                return "Application has been reviewed and submitted for underwriting.";
            case "ActivatedMerchant":
                return "PayPoint is ready to start accepting payments.";
            case "UnderWritingApplication":
                return "Application is in underwriting and risk review.";
            case "HoldingApplication":
                return "Application is on hold; this likely means more information is needed to complete review.";
            case "DeclinedApplication":
                return "Application has been declined for payment processing.";
            case "BoardingApplication":
                return "PayPoint is almost ready to accept payment.";
            case "ApprovedPayment":
                return "A transaction successfully approved by bank.";
            case "AuthorizedPayment":
                return "Payment has been verified but not captured.";
            case "VoidedPayment":
                return "The payment has been voided prior to being batched out.";
            case "RefundedPayment":
                return "The payment has been refunded.";
            case "FraudAlert":
                return "Get notified when transactions trigger fraud controls (ex: excessively high dollar amount, suspicious activity, etc.)";
            case "BillPaid":
                return "Payment of a Bill has been made.";
            case "BillApproved":
                return "Notification of when a Bill was approved for payment.";
            case "BillDisApproved":
                return "Notification of when a Bill as not approved for payment.";
            case "BillCanceled":
                return "An active Bill was canceled for consideration for approval and/or payment.";
            case "BillProcessing":
                return "A payment has been made and is in transit to a Vendor.";
            case "PayOutFunded":
                return "Funds for payment was received.";
            case "PayOutProcessed":
                return "Payment is in transit and was sent to recipient.";
            case "PayOutCanceled":
                return "Payment was canceled.";
            case "InvoiceSent":
                return "An Invoice was Sent.";
            case "InvoicePaid":
                return "A payment was received for invoice.";
            case "importFileReceived":
                return "A file was received by xchange account.";
            case "importFileProcessed":
                return "A file was processed by xchange account.";
            case "importFileError":
                return "Xchange aborted processing of file.";
            case "exportFileSent":
                return "A file was sent by xchange account.";
            case "exportFileError":
                return "Xchange aborted file transmission.";
            default: 
                return "Sorry, we don't have description for this item.";
        }
    }

    validateFields(){
        let validators = this.props.global.validators;
        let errors = [];
        let countErrors = 0;
        let notifications = this.state.notifications;
        let reports = this.state.reports;

        notifications.forEach(function (item) {
            if(item.type.toLowerCase() === "boarding"){
                return;
            }

            if(item.checked){
                errors[item.name]= {};
                errors[item.name].emails = {};
                errors[item.name].phones = {};
                errors[item.name].webhooks = {};
                errors[item.name].webHeaderParameters = [];

                if (item.checkedWebHeader){
                    const newWebHeaderParameters = item.webHeaderParameters.filter((webHeader) => !webHeader.key || webHeader.key === "" || !webHeader.value || webHeader.value === "")
                    if (newWebHeaderParameters.length > 0){
                        item.webHeaderParameters.forEach(function(webHeader, index){
                            let emptyWebhooks = true;
                            item.webhooks.forEach((webhook)=>{
                                if(webhook.value !== ""){
                                    emptyWebhooks = false;
                                }
                            });
                            
                            if(emptyWebhooks === true){
                                errors[item.name].webhooks[0] = true;
                                countErrors=countErrors + 1;
                            }

                            
                            if ((!webHeader.key || webHeader.key === "" || !webHeader.value || webHeader.value === "" || item.webHeaderParameters.length > 1)){
                                
                                errors[item.name].webHeaderParameters.push({key : false,value : false});
                            
                                if(!webHeader.key || webHeader.key === ""){
                                    errors[item.name].webHeaderParameters[index].key = true
                                }
                                if(!webHeader.value || webHeader.value === ""){
                                    errors[item.name].webHeaderParameters[index].value = true
                                }
                            }
                        })
                    } else {
                        let emptyWebhooks = true;
                        item.webhooks.forEach((webhook)=>{
                            if(webhook.value !== ""){
                                emptyWebhooks = false;
                            }
                        });

                        if(emptyWebhooks === true){
                            errors[item.name].webhooks[0] = true;
                            countErrors=countErrors + 1;
                        }
                    }
                }
                if((item.emails.length === 1 && validators.isEmpty(item.emails[0].value)) && 
                   (item.phones.length === 1 && validators.isEmpty(item.phones[0].value)) && 
                   (item.webhooks.length === 1 && validators.isEmpty(item.webhooks[0].value))){
                    errors[item.name].emails[0] = true;
                    errors[item.name].phones[0] = true;
                    errors[item.name].webhooks[0] = true;
                    countErrors=countErrors + 3;
                }
                else{
                    if(item.emails.length <= 1){
                        item.emails.forEach(function (email, c) {
                            if(validators.stringValidator('email',email.value)){
                                errors[item.name].emails[c] = true;
                                countErrors++;
                            }
                        });
                    }else if(item.emails.length > 1){
                        item.emails.forEach(function (email, c) {
                            if(validators.isEmpty(email.value) || validators.stringValidator('email',email.value)){
                                errors[item.name].emails[c] = true;
                                countErrors++;
                            }
                        });
                    }

                    if(item.phones.length <= 1){
                        item.phones.forEach(function (phone, p) {
                            if((validators.stringValidator('phone',phone.value))){
                                errors[item.name].phones[p] = true;
                                countErrors++;
                            }
                        });
                    }else if(item.phones.length > 1){
                        item.phones.forEach(function (phone, p) {
                            if(validators.isEmpty(phone.value) || validators.stringValidator('phone',phone.value)){
                                errors[item.name].phones[p] = true;
                                countErrors++;
                            }
                        });
                    }

                    if(item.webhooks.length <= 1){
                        item.webhooks.forEach(function (webhook, w) {
                            if((validators.stringValidator('urlprotocol',webhook.value))){
                                errors[item.name].webhooks[w] = true;
                                countErrors++;
                            }
                        });
                        
                    }else if(item.webhooks.length > 1){
                        item.webhooks.forEach(function (webhook, w) {
                            if(validators.isEmpty(webhook.value) || validators.stringValidator('urlprotocol',webhook.value)){
                                errors[item.name].webhooks[w] = true;
                                countErrors++;
                            }
                        });
                    }
                }
            }
        });
        reports.forEach(function (item) {
            if(item.type.toLowerCase() === "boarding"){
                return;
            }

            if(item.checked){
                if(!item.frequency || item.frequency === ""){
                    if(!errors[item.name]){
                        errors[item.name] = {};
                    }
                    errors[item.name].frequency = true;
                    countErrors++;
                }

                if(!item.emails || item.emails === "" || !validators.stringValidator('emailSemicolon',item.emails)){
                    if(!errors[item.name]){
                        errors[item.name] = {};
                    }
                    errors[item.name].emails = true;
                    countErrors++;
                }
            }
        });
        return {errors : errors, countErrors:countErrors};
    }

    saveReportsNotifications(){
        let {errors,countErrors} = this.validateFields();
        
        this.setState({
            errors: errors
        }, function(){ 
            if(countErrors === 0){
                let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
                let idPaypoint = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').paypointId;
                let entry = encryptStorage.getItem(this.props.global.getURLEntry()+'_pEntry').pEntry;
                let notifications = this.state.notifications;
                let reactObj =  this;
               
                let notoficationsToSubmit = [];
                let notoficationsToSubmitArray = [];
                let notificationsToDelete = this.state.notificationsToDelete;
                let reportsToSubmit = this.state.reports;

                this.props.global.setLoading(true);
                notifications.forEach(function (item) {
                    if(item.toUpdate){
                        if(item.emails.length > 0){
                            item.emails.forEach(function (email) {
                                let newItem = { ...item };
                                newItem.target = email.value;
                                newItem.eventType = "email";
                                newItem.notificationId = email.notificationId;
                                notoficationsToSubmit.push(newItem);
                            });
                           
                        }

                        if(item.phones.length > 0){
                            item.phones.forEach(function (phone) {
                                let newItem = { ...item };
                                newItem.target = phone.value;
                                newItem.eventType = "sms";
                                newItem.notificationId = phone.notificationId;
                                notoficationsToSubmit.push(newItem);
                            });
                            
                        }

                        if(item.webhooks.length > 0){
                            item.webhooks.forEach(function (webhook) {
                                let newItem = { ...item };
                                newItem.notificationId = webhook.notificationId;
                                newItem.target = webhook.value;
                                newItem.eventType = "web";
                                notoficationsToSubmit.push(newItem);
                            });
                            
                        }
                        
                    }
                });

                notoficationsToSubmit.forEach(function (item) {
                    if(item.type.toLowerCase() === "boarding"){
                        return;
                    }
                    if(item.toUpdate){
                        const newWebHeaderParameters = item.webHeaderParameters.filter((webHeader) => (webHeader.key || webHeader.key !== "") && (webHeader.value || webHeader.value !== "")  )
                        let parameters = {
                            "checked": item.checked,
                            "notificationId": item.notificationId,
                            "ownerId": idPaypoint,
                            "ownerType": 2, // paypoint
                            "method": item.eventType,
                            "frequency": item.frequency,
                            "target": item.target,
                            "source": "Webapp Paypoint",
                            "status": 1,
                            "content": {
                                "eventType": item.name,
                                "timeZone": item.type.toLowerCase() !== "moneyout" ? reactObj.state.notificationsTimezone : reactObj.state.notificationsTimezoneMoneyOut,
                                "webHeaderParameters": newWebHeaderParameters.length > 0 && item.eventType === 'web' ? newWebHeaderParameters: null
                            }
                        };
                        
                        notoficationsToSubmitArray.push(parameters);
                    }
                    
                });

                reportsToSubmit.forEach(function (item) {
                    if(item.type.toLowerCase() === "boarding"){
                        return;
                    }
                    if(item.toUpdate){
                        let parameters = {
                            "checked": item.checked,
                            "notificationId": item.notificationId,
                            "ownerId": idPaypoint,
                            "ownerType": 2, // paypoint
                            "method": "report-email",
                            "frequency": item.frequency,
                            "target": item.emails.replaceAll(" ", ""),
                            "source": "Webapp Paypoint",
                            "status": 1,
                            "content": {
                                "eventType": item.name,
                                "reportName": item.name,
                                "fileFormat": "csv",
                                "timeZone": reactObj.state.reportsTimezone
                            }
                        };

                        notoficationsToSubmitArray.push(parameters);
                    }
                });

                this.handlePaypointSettingsGeneral();
                this.props.entry.updatePaypointSettingsPaymentReceipt(entry, this.state.paypointSettingsGeneral)
                .catch(error => {
                    this.showError(error);
                });
                
                notoficationsToSubmitArray.forEach(function (item) {
                    if(item.checked && item.notificationId === "" && item.target !== ""){
                        // new
                        reactObj.props.user.newNotification(item).then(res => {
                        })
                        .catch(error => {
                            reactObj.showError(error);
                        });    
                    }
                    if(item.checked && item.notificationId !== "" && item.target !== ""){
                        // update
                        reactObj.props.user.updateNotification(item).then(res => {
                        })
                        .catch(error => {
                            reactObj.showError(error);
                        });    
                    }
                    if(item.checked && item.notificationId !== "" && item.target === ""){
                        // update
                        reactObj.props.user.deleteNotification(item.notificationId).then(res => {
                        })
                        .catch(error => {
                            reactObj.showError(error);
                        });    
                    }
                    if(!item.checked && item.notificationId !== ""){
                        // delete
                        reactObj.props.user.deleteNotification(item.notificationId).then(res => {
                        })
                        .catch(error => {
                            reactObj.showError(error);
                        });      
                    }
                });

                // removing notifications deleted from array
                notificationsToDelete.forEach((notificationId)=>{
                    reactObj.props.user.deleteNotification(notificationId).then(res => {
                    })
                    .catch(error => {
                        reactObj.showError(error);
                        reactObj.props.global.setLoading(false);
                    });   
                });

                this.init(true);      

               
            }
            else{

                if(document.getElementById("collapseSummaryMoneyIn").querySelectorAll(".input-error").length){
                    if(this.state.moneyInSummaryReportsOpen === false){
                        document.getElementById('accordionHeaderSummaryMoneyIn').click();
                    }
                }

                if(document.getElementById("collapseNotificationsMoneyIn").querySelectorAll(".input-error").length){
                    if(this.state.moneyInNotificationsOpen === false){
                        document.getElementById('accordionHeaderNotificationsMoneyIn').click();
                    }
                }

                if(document.getElementById("collapseNotificationsMoneyOut").querySelectorAll(".input-error").length){
                    if(this.state.moneyOutNotificationsOpen === false){
                        document.getElementById('accordionHeaderNotificationsMoneyOut').click();
                    }
                }
            }

        });
    }

    tooltipText(){
        return (
            <Tooltip>
            You can select one or more notifications methods. With each method, you can also add multiple emails, SMS, and webhook links by adding a " ; ".<br/>
            Ex: <br/>jane@mail.com; mark@mail.com<br/>
            Ex: 2132828787; 3051001000<br/>
            Ex: https://www.webhooklink1.com; https://www.webhooklink2.com
            </Tooltip>
        )
    }

    renderInputsNotification(items, option, errors, i, target,placeholder){
        return (
            items.map((item, e) => (
                <div key={`${target}${e}`} className='d-flex flex-row'>
                    <div key={e} className="form-floating mb-2">
                    <input disabled={option.checked ? false : true} className={ errors[option.name] && errors[option.name][target] && errors[option.name][target][e] === true ? "form-control input-error" : "form-control" } onChange={(obj) => this.handleTextChangeNotifications(obj.target.value, i, target,e, option.name)} placeholder={placeholder} id={`${target}InputNotification`} value={item.value} />
                    <label htmlFor={`${target}InputNotification`} style={{overflow:'hidden'}}>{placeholder}</label>
                    </div>
                    <div className='d-flex flex-row'>
                        {(items.length -1 === e) &&
                        <button onClick={() => this.addInputNotification(i,target)} disabled={option.checked ? false : true} className='notificationActionPlus text-primary'>
                            <BiPlusCircle/>
                        </button>
                        }
                        {(items.length > 1) &&
                        <button onClick={() => this.removeInputNotification(i,target, e)} disabled={option.checked ? false : true} className='notificationActionRemove text-danger'>
                            <BiTrashAlt/>
                        </button>
                        }
                    </div>
                </div>
            ))
        )
    }

    addInputNotification(i, target){
        let notifications = this.state.notifications;
        let targetArray = notifications[i][target];
        targetArray.push({value:'',notificationId:''});
        notifications[i][target] = targetArray;
        notifications[i].toUpdate = true;
        this.setState({notifications: notifications});
    }

    removeInputNotification(i, target, e){
        let notifications = this.state.notifications;
        let targetArray = notifications[i][target];
        let notificationsToDelete = this.state.notificationsToDelete;
        if(targetArray[e].notificationId !== ''){
            notificationsToDelete.push(targetArray[e].notificationId);
        }
        targetArray.splice(e, 1);
        notifications[i][target] = targetArray; 
        notifications[i].toUpdate = true;
        this.setState({notifications: notifications, notificationsToDelete: notificationsToDelete});
    }
    
    render() {
        let errors = this.state.errors;
        
        return (
            <Layout {...this.props}>
                <div>
                    <TopBar>
                        <MainTopBarMenu/>
                        <div className="top-bar-sub">
                            <SettingsLinks selected="notifications"/>
                        </div>
                    </TopBar>
                    
                    <div className="mt-body4 form-input-disabled">
                        <div className="mb-3">
                            <h5>Reports & Notifications</h5>
                            <p className="small-grey">Select & configure reports you wish to receive</p>
                        </div>
                        {!this.state.paypointIsActive &&
                            <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Paypoint is not active, you can't edit Reports and Notifications</div>
                        }
                        
                        <h6>Money In</h6>
                        <div className="row">
                            <div className="col-sm-12 popover-body ml-2" style={{maxWidth: "1000px"}}>
                                <Accordion>
                                    <Accordion.Toggle className='accordionHeader' id="accordionHeaderSummaryMoneyIn" eventKey="0" onClick={(e)=> this.handleAccordionState('moneyInSummaryReportsOpen')}>
                                        <h6 className="sub-header mb-1">
                                            <>
                                            {this.state.moneyInSummaryReportsOpen ? <BiChevronDown className='accordionArrow'/> : <BiChevronRight className='accordionArrow'/>}
                                            Summary Reports
                                            <p className="small-grey mb-4">
                                                Select summary reports wished to be received, alongside frequency and email address.
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip >Enter ; to add multiple email addresses</Tooltip>}
                                                >
                                                <BiInfoCircle className="ml-1 cursor-pointer" style={{fontSize: "20px"}}/>
                                                </OverlayTrigger>
                                            </p>
                                            </>
                                        </h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0" id="collapseSummaryMoneyIn">
                                        <>
                                        <div className="row">
                                            <div className="col-sm-5 mb-1">
                                                    <div className="form-floating mb-3">
                                                        <select onChange={(e)=> this.selectReportsTimezone(e)} value={this.state.reportsTimezone} id="timezone-reports" className="form-control form-select" aria-label="timezone-reports">
                                                            <option value="0">UTC</option>
                                                            <option value="-4">Atlantic Time UTC−04:00</option>
                                                            <option value="-5">Eastern Time UTC−05:00</option>
                                                            <option value="-6">Central Time UTC−06:00</option>
                                                            <option value="-7">Mountain Time UTC−07:00</option>
                                                            <option value="-8">Pacific Time UTC−08:00</option>
                                                            <option value="-9">Alaskan Time UTC−09:00</option>
                                                            <option value="-10">Hawaii-Aleutian Time UTC−10:00</option>
                                                        </select>
                                                        <label htmlFor="timezone-reports">Time Zone</label>
                                                    </div>
                                            </div>
                                        </div>
                                        {this.state.reports.map((option, i) => (
                                            ((option.type.toLowerCase() !== "boarding") &&
                                            <div key={i} className="row">
                                                <div className="col-sm-5 mb-1">
                                                    <div className="icheck-primary">
                                                        <input checked={option.checked} id={"summaryReport"+i} type="checkbox"  onChange={(e) => this.handleCheckbox(option, i)}  />
                                                        <label htmlFor={"summaryReport"+i}>{option.description} </label>
                                                    
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip>{this.getTooltipText(option.name)}</Tooltip>}
                                                        >
                                                        <BiInfoCircle className="float-right cursor-pointer" style={{fontSize: "20px"}}/>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mb-1">
                                                    <div className={option.checked ? "form-floating mb-3" : "form-floating mb-3 disabled-transparent"}>
                                                        <select className={ errors[option.name] && errors[option.name].frequency === true ? "form-control form-select input-error" : "form-control form-select" } value={option.frequency} disabled={option.checked ? false : true} id="frequency" onChange={(e) => this.handleSelect(e.target.value, i)} aria-label="frequency">
                                                            <option value="">Select...</option>
                                                            <option value="daily">Daily</option>
                                                            <option value="weekly">Weekly</option>
                                                            <option value="biweekly">Bi-weekly</option>
                                                            <option value="monthly">Monthly</option>
                                                            <option value="quarterly">Quarterly</option>
                                                            <option value="semiannually">Semi-annually</option>
                                                            <option value="annually">Annually</option>
                                                        </select>
                                                        <label htmlFor="frequency">Frequency</label>
                                                    </div>
                                                </div>
                                                <div className={option.checked ? "col-sm-4 mb-1" : "col-sm-4 mb-1 disabled-transparent"}>
                                                    <div className="form-floating">
                                                        <input disabled={option.checked ? false : true} className={ errors[option.name] && errors[option.name].emails === true ? "form-control input-error" : "form-control" } onChange={(e) => this.handleTextChange(e.target.value, i)} placeholder="Email" id="emailReports" value={option.emails} />
                                                        <label htmlFor="emailReports">Email</label>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        ))}
                                        </>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>
                            <div className="col-sm-12 popover-body ml-2" style={{"paddingTop":0}}>
                                <Accordion>
                                    <Accordion.Toggle className='accordionHeader' id="accordionHeaderNotificationsMoneyIn" eventKey="1" onClick={(e)=> this.handleAccordionState('moneyInNotificationsOpen')}>
                                        <h6 className="sub-header mb-1">
                                            <>
                                            {this.state.moneyInNotificationsOpen ? <BiChevronDown className='accordionArrow'/> : <BiChevronRight className='accordionArrow'/>}
                                            Notifications
                                            <p className="small-grey mb-4">
                                                Select real time notifications wished to be received alongside desired email address.
                                            </p>
                                            </>
                                        </h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1" id="collapseNotificationsMoneyIn">
                                        <>
                                        <div className="row">
                                            <div className="col-sm-5 mb-1">
                                                <div className="form-floating mb-3">
                                                    <select onChange={(e)=> this.selectNotificationsTimezone(e)} value={this.state.notificationsTimezone} id="timezone-reports" className="form-control form-select" aria-label="timezone-reports">
                                                        <option value="0">UTC</option>
                                                        <option value="-4">Atlantic Time UTC−04:00</option>
                                                        <option value="-5">Eastern Time UTC−05:00</option>
                                                        <option value="-6">Central Time UTC−06:00</option>
                                                        <option value="-7">Mountain Time UTC−07:00</option>
                                                        <option value="-8">Pacific Time UTC−08:00</option>
                                                        <option value="-9">Alaskan Time UTC−09:00</option>
                                                        <option value="-10">Hawaii-Aleutian Time UTC−10:00</option>
                                                    </select>
                                                    <label htmlFor="timezone-reports">Time Zone</label>
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label className="mb-3"><b>Transactions</b></label>
                                                    </div>
                                                    <div className="col-8">
                                                        <label className="mb-3"><b>Notification Method</b></label>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={this.tooltipText()}
                                                        >
                                                        <BiInfoCircle className="ml-1 cursor-pointer" style={{fontSize: "20px"}}/>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                {this.state.notifications.map((option, i) => (
                                                    ((option.type.toLowerCase() !== "boarding" && option.type.toLowerCase() !== "moneyout") &&
                                                        <div key={i} className="row">
                                                            <div className="col-md-12 col-lg-3 mb-3">
                                                                <div className="icheck-primary d-flex flex-row justify-content-lg-between justify-content-md-start">
                                                                    <input checked={option.checked} id={"transactionsNotification"+i} type="checkbox"  onChange={(e) => this.handleCheckboxNotifications(option, i)}  />
                                                                    <label htmlFor={"transactionsNotification"+i}>{option.description} </label>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip >{this.getTooltipText(option.name)}</Tooltip>}
                                                                    >
                                                                    <BiInfoCircle className="float-right cursor-pointer ms-2" style={{fontSize: "20px"}}/>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                            <div className={`col-md-12 col-lg-5 mb-3 ${!option.checked ? 'disabled-transparent':''}`}>
                                                                <div className='row'>
                                                                    <div className='col-md-6 col-lg-4'>
                                                                        {option.emails.length > 0 &&
                                                                            this.renderInputsNotification(option.emails, option, errors, i, "emails", "Email")
                                                                        }
                                                                    </div>
                                                                    <div className='col-md-6 col-lg-4'>
                                                                        {option.phones.length > 0 &&
                                                                            this.renderInputsNotification(option.phones, option, errors, i, "phones", "Phone #")
                                                                        }
                                                                    </div>
                                                                    <div className='col-md-6 col-lg-4'>
                                                                        {option.webhooks &&
                                                                            this.renderInputsNotification(option.webhooks, option, errors, i, "webhooks", "Webhook Link")
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`col-md-12 col-lg-4 mb-lg-3 ${!option.checked ? 'disabled-transparent':''}`} style={{position:'relative', top:'-33px'}}>
                                                                <div className="icheck-success" style={{position:'relative', zIndex:'1', maxWidth:'200px', background:'#ffffff', left:'10px'}}>
                                                                    <input disabled={option.checked ? false : true} checked={option.checkedWebHeader} id={"webHeaderParameter"+i} type="checkbox"  onChange={(e) => this.handleCheckboxNotificationsWebHeader(option, i)}  />
                                                                    <label className='d-flex align-items-center' htmlFor={"webHeaderParameter"+i}>Web Header Parameters &nbsp;
                                                                        <span style={{color:'#03A9F4'}}>
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip ><b>For increased Webhook security</b> you can add an API header to ensure you only receive webhooks that includes additional parameters.</Tooltip>}
                                                                            >
                                                                            <BiInfoCircle className="float-right cursor-pointer" style={{fontSize: "15px"}}/>
                                                                            </OverlayTrigger>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className={`border rounded p-2 ${(option.checked && !option.checkedWebHeader) ? 'disabled-transparent' : ''}`} style={{position:'relative', top:'-15px'}}>
                                                                    {option.webHeaderParameters.map((webHeader, index) => (
                                                                        <div key={'webHeader'+index} className='row'>
                                                                            <div className='col-sm-6 py-1'>
                                                                                <div className="form-floating">
                                                                                    <input disabled={option.checkedWebHeader ? false : true} className={errors[option.name] && errors[option.name].webHeaderParameters && errors[option.name].webHeaderParameters[index]?.key === true ? "form-control input-error" : "form-control" } onChange={(e) => this.handleTextChangeNotificationsWebHeader(e.target.value, i,index, "key", option.name)} placeholder="Key" id={`Key${i}-${index}`} value={webHeader.key} />
                                                                                    <label htmlFor="webHeaderKeyReports">Key</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-sm-6 py-1'>
                                                                                <div className='d-flex flex-row'>
                                                                                    <div className="form-floating w-100">
                                                                                        <input disabled={option.checkedWebHeader ? false : true} className={errors[option.name] && errors[option.name].webHeaderParameters && errors[option.name].webHeaderParameters[index]?.value === true ? "form-control input-error" : "form-control" } onChange={(e) => this.handleTextChangeNotificationsWebHeader(e.target.value, i,index, "value", option.name)} placeholder="Value" id={`keyValue${i}-${index}`} value={webHeader.value} />
                                                                                        <label htmlFor="webHeaderValueReports">Value</label>
                                                                                    </div>
                                                                                    <div className="d-flex flex-row">
                                                                                        {(option.webHeaderParameters.length-1) === index && (
                                                                                            <button onClick={() => this.addWebHeaders(i)} disabled={option.checkedWebHeader ? false : true} style={{color: '#03A9F4', fontWeight: 'bold', border:'none', backgroundColor: 'transparent'}}>
                                                                                                <BiPlusCircle style={{width:'15px', height:'15px'}} />
                                                                                            </button>
                                                                                        )}
                                                                                        {option.webHeaderParameters.length > 1 && (
                                                                                            <button onClick={() => this.removeWebHeaders(i, index)} disabled={option.checkedWebHeader ? false : true} style={{color: '#FF867C', fontWeight: 'bold', border:'none', backgroundColor: 'transparent', paddingRight: (option.webHeaderParameters.length-1) !== index && '33px'}}>
                                                                                                <BiTrashAlt style={{width:'15px', height:'15px'}} />
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                        </>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>
                            <div className="col-sm-12 popover-body ml-2" style={{maxWidth: "1000px","paddingTop":0}}>  
                                <Accordion>
                                    <Accordion.Toggle className='accordionHeader' id="accordionHeaderNotificationsMoneyInDeclined" eventKey="4" onClick={(e)=> this.handleAccordionState('moneyInNotificationsOpenDeclined')}>
                                        <h6 className="sub-header mb-1">
                                            <>
                                            {this.state.moneyInNotificationsOpenDeclined ? <BiChevronDown className='accordionArrow'/> : <BiChevronRight className='accordionArrow'/>}
                                            Payment Receipts
                                            <p className="small-grey mb-4">
                                                Select if you wish to receive a payment receipt when a payment is approved or declined.
                                            </p>
                                            </>
                                        </h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4" id="collapseNotificationsMoneyInApprovedDeclined">
                                    <>
                                    <div className="row">
                                            <div className="col-sm-12 mb-3">
                                                <div className="icheck-primary">
                                                    <input checked={(this.state.sendApprovedReceipt && this.state.sendApprovedReceipt.value === "true" ) ? true : false } id={"sendApprovedReceipt"} type="checkbox"  onChange={(e) => this.handleCheckboxPaymentReceipt(e)}  />
                                                    <label htmlFor={"sendApprovedReceipt"}>I wish to receive a payment receipt when a payment is approved </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 mb-3">
                                                <div className="icheck-primary">
                                                    <input checked={(this.state.sendDeclinedReceipt && this.state.sendDeclinedReceipt.value === "true" ) ? true : false } id={"sendDeclinedReceipt"} type="checkbox"  onChange={(e) => this.handleCheckboxPaymentReceipt(e)}  />
                                                    <label htmlFor={"sendDeclinedReceipt"}>I wish to receive a payment receipt when a payment is declined </label>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>
                        </div>
                        
                        <h6>Money Out</h6>
                        <div className="row">
                            <div className="col-sm-12 popover-body ml-2" style={{maxWidth: "1000px"}}>
                                <Accordion>
                                    <Accordion.Toggle className='accordionHeader' id="accordionHeaderSummaryMoneyIn" eventKey="2" onClick={(e)=> this.handleAccordionState('moneyOutSummaryReportsOpen')}>
                                    <h6 className="sub-header mb-1">
                                        <>
                                        {this.state.moneyInSummaryReportsOpen ? <BiChevronDown className='accordionArrow'/> : <BiChevronRight className='accordionArrow'/>}
                                        Summary Reports
                                        <p className="small-grey mb-4">
                                            Select summary reports wished to be received, alongside frequency and email address.
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip >Enter ; to add multiple email addresses</Tooltip>}
                                            >
                                            <BiInfoCircle className="ml-1 cursor-pointer" style={{fontSize: "20px"}}/>
                                            </OverlayTrigger>
                                        </p>
                                        </>
                                    </h6>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey="2" id="collapseSummaryMoneyOut">
                                    <>
                                    <div className='row'>
                                        <div className='col-6'>
                                        <p className='small'>Coming Soon.</p>
                                        </div>
                                    </div>
                                    </>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>

                            <div className="col-sm-12 popover-body ml-2">
                                <Accordion>
                                    <Accordion.Toggle className='accordionHeader' id="accordionHeaderNotificationsMoneyOut" eventKey="3" onClick={(e)=> this.handleAccordionState('moneyOutNotificationsOpen')}>
                                        <h6 className="sub-header mb-1">
                                            <>
                                            {this.state.moneyOutNotificationsOpen ? <BiChevronDown className='accordionArrow'/> : <BiChevronRight className='accordionArrow'/>}
                                            Notifications
                                            <p className="small-grey mb-4">
                                                Select real time notifications wished to be received alongside desired email address.
                                            </p>
                                            </>
                                        </h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3" id="collapseNotificationsMoneyOut">
                                    <>
                                        <div className="row">
                                            <div className="col-sm-5 mb-1">
                                                    <div className="form-floating mb-3">
                                                        <select onChange={(e)=> this.selectNotificationsTimezoneMoneyOut(e)} value={this.state.notificationsTimezoneMoneyOut} id="timezone-reports-moneyout" className="form-control form-select" aria-label="timezone-reports-moneyout">
                                                            <option value="0">UTC</option>
                                                            <option value="-4">Atlantic Time UTC−04:00</option>
                                                            <option value="-5">Eastern Time UTC−05:00</option>
                                                            <option value="-6">Central Time UTC−06:00</option>
                                                            <option value="-7">Mountain Time UTC−07:00</option>
                                                            <option value="-8">Pacific Time UTC−08:00</option>
                                                            <option value="-9">Alaskan Time UTC−09:00</option>
                                                            <option value="-10">Hawaii-Aleutian Time UTC−10:00</option>
                                                        </select>
                                                        <label htmlFor="timezone-reports-moneyout">Time Zone</label>
                                                    </div>
                                            </div>
                                        </div>  
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label className="mb-3"><b>Transactions</b></label>
                                                    </div>
                                                    <div className="col-8">
                                                        <label className="mb-3"><b>Notification Method</b></label>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={this.tooltipText()}
                                                        >
                                                        <BiInfoCircle className="ml-1 cursor-pointer" style={{fontSize: "20px"}}/>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                {this.state.notifications.map((option, i) => (
                                                    ((option.type.toLowerCase() === "moneyout") &&
                                                    <div key={i} className="row">
                                                        <div className="col-md-12 col-lg-3 mb-3">
                                                            <div className="icheck-primary d-flex flex-row justify-content-lg-between justify-content-md-start">
                                                                <input checked={option.checked} id={"transactionsNotificationMoneyout"+i} type="checkbox"  onChange={(e) => this.handleCheckboxNotifications(option, i)}  />
                                                                <label htmlFor={"transactionsNotificationMoneyout"+i}>{option.description} </label>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip >{this.getTooltipText(option.name)}</Tooltip>}
                                                                >
                                                                <BiInfoCircle className="float-right cursor-pointer ms-2" style={{fontSize: "20px"}}/>
                                                                </OverlayTrigger>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className={`col-md-12 col-lg-5 mb-3 ${!option.checked ? 'disabled-transparent':''}`}>
                                                            <div className='row'>
                                                                <div className='col-md-6 col-lg-4'>
                                                                    {option.emails.length > 0 &&
                                                                        this.renderInputsNotification(option.emails, option, errors, i, "emails", "Email")
                                                                    }
                                                                </div>
                                                                <div className='col-md-6 col-lg-4'>
                                                                    {option.phones.length > 0 &&
                                                                        this.renderInputsNotification(option.phones, option, errors, i, "phones", "Phone #")
                                                                    }
                                                                </div>
                                                                <div className='col-md-6 col-lg-4'>
                                                                    {option.webhooks &&
                                                                        this.renderInputsNotification(option.webhooks, option, errors, i, "webhooks", "Webhook Link")
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`col-md-12 col-lg-4 mb-lg-3 ${!option.checked ? 'disabled-transparent':''}`} style={{position:'relative', top:'-33px'}}>
                                                            <div className="icheck-success" style={{position:'relative', zIndex:'1', maxWidth:'200px', background:'#ffffff', left:'10px'}}>
                                                                <input disabled={option.checked ? false : true} checked={option.checkedWebHeader} id={"webHeaderParameter"+i} type="checkbox"  onChange={(e) => this.handleCheckboxNotificationsWebHeader(option, i)}  />
                                                                <label className='d-flex align-items-center' htmlFor={"webHeaderParameter"+i}>Web Header Parameters &nbsp;
                                                                    <span style={{color:'#03A9F4'}}>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip ><b>For increased Webhook security</b> you can add an API header to ensure you only receive webhooks that includes additional parameters.</Tooltip>}
                                                                        >
                                                                        <BiInfoCircle className="float-right cursor-pointer" style={{fontSize: "15px"}}/>
                                                                        </OverlayTrigger>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className={`border rounded p-2 ${(option.checked && !option.checkedWebHeader) ? 'disabled-transparent' : ''}`} style={{position:'relative', top:'-15px'}}>
                                                                {option.webHeaderParameters.map((webHeader, index) => (
                                                                <div key={'webHeader'+index} className='row'>
                                                                    <div className='col-sm-6 py-1'>
                                                                        <div className="form-floating">
                                                                            <input disabled={option.checkedWebHeader ? false : true} className={errors[option.name] && errors[option.name].webHeaderParameters && errors[option.name].webHeaderParameters[index]?.key === true ? "form-control input-error" : "form-control" } onChange={(e) => this.handleTextChangeNotificationsWebHeader(e.target.value, i,index, "key", option.name)} placeholder="Key" id={`Key${i}-${index}`} value={webHeader.key} />
                                                                            <label htmlFor="webHeaderKeyReports">Key</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-sm-6 py-1'>
                                                                        <div className='d-flex flex-row'>
                                                                            <div className="form-floating w-100">
                                                                                <input disabled={option.checkedWebHeader ? false : true} className={errors[option.name] && errors[option.name].webHeaderParameters && errors[option.name].webHeaderParameters[index]?.value === true ? "form-control input-error" : "form-control" } onChange={(e) => this.handleTextChangeNotificationsWebHeader(e.target.value, i,index, "value", option.name)} placeholder="Value" id={`keyValue${i}-${index}`} value={webHeader.value} />
                                                                                <label htmlFor="webHeaderValueReports">Value</label>
                                                                            </div>
                                                                            <div className="d-flex flex-row">
                                                                                {(option.webHeaderParameters.length-1) === index && (
                                                                                    <button onClick={() => this.addWebHeaders(i)} disabled={option.checkedWebHeader ? false : true} style={{color: '#03A9F4', fontWeight: 'bold', border:'none', backgroundColor: 'transparent'}}>
                                                                                        <BiPlusCircle style={{width:'15px', height:'15px'}} />
                                                                                    </button>
                                                                                )}
                                                                                {option.webHeaderParameters.length > 1 && (
                                                                                    <button onClick={() => this.removeWebHeaders(i, index)} disabled={option.checkedWebHeader ? false : true} style={{color: '#FF867C', fontWeight: 'bold', border:'none', backgroundColor: 'transparent', paddingRight: (option.webHeaderParameters.length-1) !== index && '33px'}}>
                                                                                        <BiTrashAlt style={{width:'15px', height:'15px'}} />
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>
                        </div>
                        {this.state.paypointIsActive &&
                            <div className="row">
                                <div className="col-md-4">
                                <button className="btn btn-primary mt-4 mb-5 full-w" type="button" onClick={() => this.saveReportsNotifications()}>Save</button>   
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <ToastContainer transition={Bounce} />
            </Layout>
        )
    }
}

export { Notifications };