import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiBroadcast} from 'react-icons/bi';

@inject('global')
@observer
class NewOpenResource extends React.Component {

    componentDidMount() {
        // to disable the back button
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
        };
    }

    render() {
    
        return (
        <>
            <div className="root-page">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{height: "100%", position: "absolute", width: "100%"}}>
                <div className="text-center" style={{width: '20em', backgroundColor: "#ffffff", padding: "30px 15px"}}>
                    <BiBroadcast className='icon-modal'/>
                    <h6>Resource Detected</h6>
                    <p className="small">
                        The system has detected <br/>a new resource in another tab.
                        <br/>
                    </p>
                </div>
            </div>
            </div>
        </>
        )
    }
}

export { NewOpenResource };