import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportsLinks } from '../../components/ReportsLinks';
import { ReportPaginator } from '../../components/ReportPaginator';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import ParentView from '../../components/slideView/ParentView';
import { DropdownButton, Dropdown, Modal, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { Layout } from '../../components/Layout';
import { PayabliStorageManager } from '../../api/localStorageManager';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MainBar } from '../../components/MainBar';
import { BiSortAlt2, BiDotsVerticalRounded } from 'react-icons/bi';
import { AiOutlineAlert } from 'react-icons/ai';

@inject('reports', 'global', 'management')
@observer
class BatchesReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            flagItemAllColumn: true,
            sortDirection: "desc",
        };
        
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.exportSettlements = this.exportSettlements.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.renderModalRisks = this.renderModalRisks.bind(this);
        this.showModalConfirmationRisks = this.showModalConfirmationRisks.bind(this);
        this.renderActionHoldReleasedBatch = this.renderActionHoldReleasedBatch.bind(this);
        this.savedActionsRisks = this.savedActionsRisks.bind(this);
    }

    clearFilters(){
        this.setState({ head: ""}, () => {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                /* Batch */
                BatchNumber         : { label:'Batch #', class: '', display: true }, 
                ExternalPaypointID  : { label:'External Paypoint ID', class: '', display: false },
                BatchDate           : { label:'Batch Close Date', class: '', display: true },
                ExpectedDepositDate : { label:'Expected Deposit Date', class: '', display: false}, 
                Method              : { label:'Method', class: 'text-center', display: true }, 
                BatchRecords        : { label:'# of Payments', class: 'text-center', display: true}, 
                BatchStatus         : { label:'Batch Status', class: '', display: true }, 
                BatchAmount         : { label:'Gross Batch', class: 'text-right', display: false},
                BatchFeesAmount     : { label:'Service Fee', class: 'text-right', display: false},
                BatchAuthAmount     : { label:'Net Batch', class: 'text-right', display: true},
                /* Transfers */
                TransferId           : { label:'Transfer ID', class: '', display: false},
                TransferDate         : { label:'Transfer Date', class: '', display: false},
                GrossAmount          : { label:'Gross Amount', class: 'text-right', display: false},
                TransferStatus       : { label:'Transfer Status', class: '', display: true},
                ChargeBackAmount     : { label:'Chargebacks', class: 'text-right', display: false},
                ReturnedAmount       : { label:'ACH Returns', class: 'text-right', display: false},
                BillingFeesAmount    : { label:'Billing & Fees', class: 'text-right', display: false},
                AdjustmentsAmount    : { label:'Adjustments', class: 'text-right', display: false},
                ThirdPartyPaidAmount : { label:'Third Party Paid', class: 'text-right', display: false},
                HoldAmount           : { label:'Held Amount', class: 'text-right', display: false},
                ReleasedAmount       : { label:'Release Amount', class: 'text-right', display: false},
                BatchSplitAmount     : { label:'Split Amount', class: 'text-right', display: false},
                NetFundedAmount      : { label:'Transfer Total', class: 'text-right', display: true},
                /* BatchNumber          : { label:'Processor Transfer ID', class: '', display: false}, */
                Processor            : { label:'Processor', class: '', display: false},
            }
        )
        this.props.reports.setRecords([]);
        this.getReportFromApi();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    exportFile(format){
        this.props.reports.exportFile('batches',format);
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    exportSettlements(format, IdBatch){
        this.props.global.setLoading(true);
        this.props.reports.exportSettlements(format, IdBatch)
        .then(result => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    getMappingFields(){
        return {
            'BatchNumber': 'batchnumber',
            'ParentOrgName': 'orgname',
            'PaypointLegalname': 'paypointlegal',
            'ExternalPaypointID': 'externalpaypointid',
            'BatchDate': 'batchdate',
            'ExpectedDepositDate': 'batchdate',
            'Method': 'method',
            // 'BatchRecords': 'batchrecords',
            'BatchStatus': 'status',
            'BatchAmount': 'batchamount',
        }
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'batches').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }

    sortBy(e, record){
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }

    getReportFromApi(){
        let tempFilterValue = {};
        if(this.state.sortBy){
            tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
        }
        this.filter(tempFilterValue);
    }

    viewRecordDetails(record, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || (object.target.tagName.toLowerCase() === "a" && !object.target.className.includes('view-record-details')) || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            this.props.global.setSlideParentViewOpen(true);
            this.props.reports.setObjectBatchRecord(record);
        }
    }

    renderModalRisks(){
        if (this.state.actionsRisks) {
            const {action, idBatch} = this.state.actionsRisks
            return (
                <Modal style={{ textAlign: 'center' }} show={this.state.modalConfirmationRisks} onHide={()=> this.deleteModalStatus(false)} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body>
                        <AiOutlineAlert className="icon-modal" />
                        <h5 data-qaid={action === 'hold' ? 'holdModalMoneyIn' : 'releaseModalMoneyIn'}>{action === 'hold' ? 'Hold' : 'Release'}</h5>
                        <p className="small mb-4">This action will <strong>{action === 'hold' ? 'Hold' : 'Release'}</strong> the Batch selected, do you want to continue?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={()=> this.showModalConfirmationRisks()} data-qaid="cancelHoldModalMoneyIn">Cancel</Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="primary" onClick={()=> this.savedActionsRisks(action, idBatch)}>Save</Button>
                    </Modal.Body>
                </Modal>
            )
        }
        return null
    }
    
    showModalConfirmationRisks(action, idBatch){
        let modalConfirmationRisks = !this.state.modalConfirmationRisks
        let actionsRisks = {
            'action': action,
            'idBatch': idBatch
        }
        this.setState({modalConfirmationRisks: modalConfirmationRisks, actionsRisks: actionsRisks})
    }

    savedActionsRisks(action, idBatch){
        if (action && idBatch) {
            this.props.global.setLoading(true);
            if (action === 'hold') {
                this.props.management.actionHoldBatch(idBatch)
                .then(result => {
                    this.props.global.setLoading(false);
                    toast.success("Batch has been successfully held!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.getReportFromApi();
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
            if (action === 'release') {
                this.props.management.actionReleaseBatch(idBatch)
                .then(result => {
                    this.props.global.setLoading(false);
                    toast.success("Batch has been successfully released!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-success-container'
                    });
                    this.getReportFromApi();
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
            this.showModalConfirmationRisks()
            this.props.global.setLoading(false);
        }
        return
    }

    renderActionHoldReleasedBatch(record) {
        const { BatchStatus, Transfer, ConnectorName } = record;        
        if (ConnectorName?.toLowerCase() === 'gp' && !Transfer) {
            const renderDivRisk = <>
                <Dropdown.Divider />
                <span className='small' style={{ fontSize: '10px', marginLeft: '15px' }}>RISK</span>
            </>
            if (BatchStatus === 0 || BatchStatus === 1) {
                return <>
                    {renderDivRisk}
                    <Dropdown.Item onClick={() => this.showModalConfirmationRisks('hold', record.IdBatch)} data-qaid="holdBatchMoneyIn">Hold Batch</Dropdown.Item>                    
                </>
            }
            if (BatchStatus === -5) {
                return <>
                    {renderDivRisk}
                    <Dropdown.Item onClick={() => this.showModalConfirmationRisks('release', record.IdBatch)} data-qaid="releaseBatchMoneyIn">Release Batch</Dropdown.Item>
                </>
            }
        }
    }

    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];;
        return (
            <Layout {...this.props}>
                <div>
                    <TopBar>
                        <MainTopBarMenu/>
                        <div className="top-bar-sub">
                            <ReportsLinks selected="batches" history={this.props.history}/>
                        </div>
                    </TopBar>
                    <ParentFilterPanel report={'batches'} />
                    <div className="mt-body4">
                    <ParentView />
                        <MainBar
                            reportName={"batches"}
                            dataQAName="BatchesMoneyIn"
                            reportTitle={this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "Batches & Funding"}
                            showHideFilters={this.showHideFilters}
                            selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                            handleShowColumn={(e) => this.handleShowColumn(e)}
                            totalRecords={this.props.reports.totalRecords}
                            getHeaders={this.props.reports.getHeaders}
                            headers={this.props.reports.headers}
                            refreshView={this.clearFilters}
                            buttonExport={true}
                            searchBar={false}
                            exportFile={this.exportFile}
                            withTotal={true}
                            totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                            flagItemAllColumn={this.state.flagItemAllColumn}
                            balanceLine={true}
                        /> 
                        <div className="report-container">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                    {
                                        this.props.reports.getHeaders.map((record, i) => (
                                            (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                                                record[1].display && 
                                                (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                                    <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                                                </th> ))
                                                ||
                                                (record[1].display && 
                                                (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                                                </th> ))
                                        ))
                                    }
                                    <th className="text-center sticky-row" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>                
                                { this.props.reports.records.map((record, i) => (
                                    <tr key={i} className="cursorPointer" onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} onClick={(e) => this.viewRecordDetails(record, e)} data-qaid={"batchesMoneyInRow-"+(i)} >
                                        { (this.props.reports.headers.BatchNumber && this.props.reports.headers.BatchNumber.display) &&
                                            <td>{record.BatchNumber}</td>
                                        }
                                        { (this.props.reports.headers.ExternalPaypointID && this.props.reports.headers.ExternalPaypointID.display) &&
                                            <td>{record && record.externalPaypointID ? record.externalPaypointID : '-'}</td>
                                        }
                                        { (this.props.reports.headers.BatchDate && this.props.reports.headers.BatchDate.display) &&
                                            <td>{this.props.global.stringDateFormatV3(record.BatchDate)}</td>
                                        }
                                        { (this.props.reports.headers.ExpectedDepositDate && this.props.reports.headers.ExpectedDepositDate.display) &&
                                            <td>{ record.ExpectedDepositDate ? this.props.global.stringDateFormatV3(record.ExpectedDepositDate) : "-"}</td>
                                        }
                                        { (this.props.reports.headers.Method && this.props.reports.headers.Method.display) &&
                                            <td className='text-center'>{this.props.global.getPaymethodImg(record.Method.toLowerCase())}</td>
                                        }
                                        { (this.props.reports.headers.BatchRecords && this.props.reports.headers.BatchRecords.display) &&
                                            <td className='text-center'>{record.BatchRecords}</td>
                                        }                            
                                        { (this.props.reports.headers.BatchStatus && this.props.reports.headers.BatchStatus.display) && 
                                            <td>{this.props.global.getBatchStatus(record.BatchStatus)}</td>
                                        }
                                        { (this.props.reports.headers.BatchAmount && this.props.reports.headers.BatchAmount.display) && 
                                            <td className={'text-right'}>{record.BatchAmount ? this.props.global.numberWithCommasNegative(record.BatchAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.BatchFeesAmount && this.props.reports.headers.BatchFeesAmount.display) && 
                                            <td className={'text-right'}>{record.BatchFeesAmount ? this.props.global.numberWithCommasNegative(record.BatchFeesAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.BatchAuthAmount && this.props.reports.headers.BatchAuthAmount.display) && 
                                            <td className={'text-right'}>{record.BatchAuthAmount ? this.props.global.numberWithCommasNegative(record.BatchAuthAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.TransferId && this.props.reports.headers.TransferId.display) && 
                                            <td>{record.Transfer?.TransferId ? record.Transfer.TransferId : '-'}</td>
                                        }
                                        { (this.props.reports.headers.TransferDate && this.props.reports.headers.TransferDate.display) && 
                                            <td>{record.Transfer?.TransferDate ? this.props.global.stringDateFormatV3(record.Transfer.TransferDate) : '-'}</td>
                                        }
                                        { (this.props.reports.headers.GrossAmount && this.props.reports.headers.GrossAmount.display) && 
                                            <td className={'text-right'}>{record.Transfer?.GrossAmount ? this.props.global.numberWithCommasNegative(record.Transfer.GrossAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.TransferStatus && this.props.reports.headers.TransferStatus.display) && 
                                            <td>{this.props.global.getTransferStatus(record?.Transfer?.TransferStatus)}</td>
                                        }
                                        { (this.props.reports.headers.ChargeBackAmount && this.props.reports.headers.ChargeBackAmount.display) && 
                                            <td className={'text-right'}>{record.Transfer?.ChargeBackAmount ? this.props.global.numberWithCommasNegative(record.Transfer.ChargeBackAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.ReturnedAmount && this.props.reports.headers.ReturnedAmount.display) && 
                                            <td className={'text-right'}>{record.Transfer?.ReturnedAmount ? this.props.global.numberWithCommasNegative(record.Transfer.ReturnedAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.BillingFeesAmount && this.props.reports.headers.BillingFeesAmount.display) && 
                                            <td className={'text-right'}>{record.Transfer?.BillingFeesAmount ? this.props.global.numberWithCommasNegative(record.Transfer.BillingFeesAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.AdjustmentsAmount && this.props.reports.headers.AdjustmentsAmount.display) && 
                                            <td className={'text-right'}>{record.Transfer?.AdjustmentsAmount ? this.props.global.numberWithCommasNegative(record.Transfer.AdjustmentsAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.ThirdPartyPaidAmount && this.props.reports.headers.ThirdPartyPaidAmount.display) && 
                                            <td className={'text-right'}>{record.Transfer?.ThirdPartyPaidAmount ? this.props.global.numberWithCommasNegative(record.Transfer.ThirdPartyPaidAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.HoldAmount && this.props.reports.headers.HoldAmount.display) && 
                                            <td className={'text-right'}>{record.Transfer?.HoldAmount ? this.props.global.numberWithCommasNegative(record.Transfer.HoldAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.ReleasedAmount && this.props.reports.headers.ReleasedAmount.display) && 
                                            <td className={'text-right'}>{record.Transfer?.ReleasedAmount ? this.props.global.numberWithCommasNegative(record.Transfer.ReleasedAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.BatchSplitAmount && this.props.reports.headers.BatchSplitAmount.display) && 
                                            <td className={'text-right'}>{record?.BatchSplitAmount ? this.props.global.numberWithCommasNegative(record?.BatchSplitAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.NetFundedAmount && this.props.reports.headers.NetFundedAmount.display) && 
                                            <td className={'text-right'}>{record.Transfer?.NetFundedAmount ? this.props.global.numberWithCommasNegative(record.Transfer.NetFundedAmount.toFixed(2)) : '$0.00'}</td>
                                        }
                                        { (this.props.reports.headers.Processor && this.props.reports.headers.Processor.display) && 
                                            <td>{record?.ConnectorName ? record.ConnectorName.toUpperCase() : '-'}</td>
                                        }
                                        <td className="text-center sticky-row">
                                            <DropdownButton
                                                menuAlign="right"
                                                title={<BiDotsVerticalRounded/>}
                                                data-qaid="actionBatchesMoneyIn"
                                                id="actionsMenuButton"
                                                size="sm"
                                                variant="default"
                                            >
                                            {userPermissions.indexOf("Settlement") !== -1 && 
                                            <Dropdown.Item className='view-record-details' onClick={(e) => this.viewRecordDetails(record, e)} data-qaid="viewTransactionsBatchesMoneyInLink"><div>View Transactions</div></Dropdown.Item>
                                            }
                                            <Dropdown.Item onClick={(e) => this.exportSettlements('csv', record.IdBatch)} data-qaid="downloadBatchesMoneyInLink">Download</Dropdown.Item>
                                            { userPermissions.includes("Management") && this.renderActionHoldReleasedBatch(record) }
                                            </DropdownButton>
                                        </td>
                                    </tr>
                                ))
                                }                
                                </tbody>
                            </table>                
                            {this.props.reports.records.length < 1 &&
                                <RecordsNotFound message="No batches yet" description={<span>When your payments are grouped together for funding,<br/> they will show up here.</span>}/>
                            }
                        </div>                
                        <ReportPaginator report="batches"/>
                    </div>
                    <ToastContainer transition={Bounce} />
                </div>
                {this.renderModalRisks()}
            </Layout>
        )
    }
}

export { BatchesReport };