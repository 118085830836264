import React from 'react';
import { inject, observer } from 'mobx-react';
import {  Modal, Accordion, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { BiChevronDown, BiChevronRight, BiInfoCircle} from 'react-icons/bi';
import { toJS } from "mobx";
import {IMaskInput} from 'react-imask';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocationSearchInput } from './LocationSearchInput';
import SelectSearch from 'react-select-search';
import '../assets/css/react-select-search.css';
import Fuse from 'fuse.js';
import { PayabliStorageManager } from '../api/localStorageManager';
import PhoneInput from './PhoneInput';

export default function fuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name', 'groupName', 'items.name'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }

        return fuse.search(value);
    };
}

@inject('global','bill', 'vTerminal', 'reports', 'vendor')
@observer
class EditVendor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            phoneError: false,
            id: "",
            name1: "",
            contacts: '',
            customerVendorAccount: "",
            vendorNumber: "",
            ein: "",
            email: "",
            phone: "",
            mcc: "",
            customFields: [],
            identifiers: [],
            identifiersSelected: [],
            
            billingAddress1: "",
            billingAddress2: "",
            billingCity: "",
            billingState: "",
            billingZip: "",
            billingCountry: "",

            remitAddress1: "",
            remitAddress2: "",
            remitCity: "",
            remitState: "",
            remitZip: "",
            remitCountry: "",

            billingSameRemit: true,

            formErrors: {},

            accordionBillingDetailsOpen : false,
            accordionCustomFieldsOpen : false,
            accordionIdentifiersOpen : false,

            clickedRemitCheckbox: false,

            customField1: "",
            customField2: ""
        };
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.addVendor = this.addVendor.bind(this);
        this.customFieldsKeyHandleTextChange = this.customFieldsKeyHandleTextChange.bind(this);
        this.customFieldsValueHandleTextChange = this.customFieldsValueHandleTextChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextChangeSelectSearch = this.handleTextChangeSelectSearch.bind(this);
        this.removeCustomField = this.removeCustomField.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.identifierHandleTextChange = this.identifierHandleTextChange.bind(this);
        this.changeBillingSameRemit = this.changeBillingSameRemit.bind(this);
        this.handleGoogleAutocompleteBillingAddress = this.handleGoogleAutocompleteBillingAddress.bind(this);
        this.handleGoogleAutocompleteRemitAddress = this.handleGoogleAutocompleteRemitAddress.bind(this);
        this.collapseAccordion = this.collapseAccordion.bind(this);
        this.getCustomFieldsFromApi = this.getCustomFieldsFromApi.bind(this);
        this.isIdentifier = this.isIdentifier.bind(this);
        this.hasCustomFieldsErrors = this.hasCustomFieldsErrors.bind(this);
        this.handleTextChangeContact = this.handleTextChangeContact.bind(this);
        this.setPhoneError = this.setPhoneError.bind(this);
        this.accordionCustomFields = React.createRef();
        this.accordionBillingDetails = React.createRef();
        this.baseState = this.state;
        this.componentPhone = React.createRef();
    }

    resetState = () => {
        this.setState(this.baseState);
        this.getCustomFieldsFromApi();
    }

    componentDidUpdate(){
        let vendorOCR = this.props.bill.vendorOCR;
        if(vendorOCR && vendorOCR.name1 && (this.state.name1 !== vendorOCR.name1)){
            this.setState({
                name1: vendorOCR.name1 ? vendorOCR.name1 : this.state.name1,
                contacts: vendorOCR.contacts ? vendorOCR.contacts : this.state.contacts,
                customerVendorAccount: vendorOCR.customerVendorAccount ? vendorOCR.customerVendorAccount : this.state.customerVendorAccount,
                vendorNumber: vendorOCR.vendorNumber ? vendorOCR.vendorNumber : this.state.vendorNumber,
                ein: vendorOCR.ein ? vendorOCR.ein : this.state.vendorNumber,
                email: vendorOCR.email ? vendorOCR.email : this.state.email,
                phone: vendorOCR.phone ? vendorOCR.phone : this.state.phone,
            },
            function(){
                let thisObj = this;
                setTimeout(function(){
                    if(vendorOCR){
                        thisObj.setState({modalIsOpen: true});
                        thisObj.props.bill.setVendorOCR(null);
                    }
                    
                }, 1000);
            });
        }
    }

    componentDidMount(){
        let command = this.props.command;
       
        if(command === "add"){
            this.openModal();
        }
        
        this.getCustomFieldsFromApi();

    }

    getCustomFieldsFromApi(){
        let isEmbedded = this.props.isEmbedded;
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ? 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0;

        if(isEmbedded !== true){ 
            this.props.global.setLoading(true);
        }
        this.props.reports.getEntrySettings(entry).then(res => {
            if(res.data.customFields){
                let identifiersSelected = [];
                if(res.data.identifiers){
                    res.data.identifiers.forEach(function (item) {
                        identifiersSelected.push(item.key);
                    });
                }

                this.setState({customFields: res.data.customFields ? res.data.customFields : [], identifiers: res.data.identifiers, identifiersSelected:identifiersSelected });
            }
            if(isEmbedded !== true){ 
                this.props.global.setLoading(false);
            }
        })
        .catch(error => {
            if(isEmbedded !== true){ 
                this.props.global.setLoading(false);
            }
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    collapseAccordion(accordion){
        this.setState({[accordion]:!this.state[accordion]});
    }

    handleGoogleAutocompleteBillingAddress(billingAddress1, billingAddress2, billingCity, billingState, billingZip, billingCountry){
        this.setState({
            billingAddress1 : billingAddress1 ?  billingAddress1 : '',
            billingAddress2 : billingAddress2 ?  billingAddress2 : '',
            billingCity : billingCity ?  billingCity : '',
            billingState : billingState ?  billingState : '',
            billingZip : billingZip ?  billingZip : '',
            billingCountry : billingCountry ?  billingCountry : ''
         });
    }

    handleGoogleAutocompleteRemitAddress(remitAddress1, remitAddress2, remitCity, remitState, remitZip, remitCountry){
        this.setState({
            remitAddress1 : remitAddress1 ?  remitAddress1 : '',
            remitAddress2 : remitAddress2 ?  remitAddress2 : '',
            remitCity : remitCity ?  remitCity : '',
            remitState : remitState ?  remitState : '',
            remitZip : remitZip ?  remitZip : '',
            remitCountry : remitCountry ?  remitCountry : ''
        });      
    }

    handleTextChange(e){
        this.setState({ [e.target.id]: e.target.value });
    }

    handleTextChangeContact(e){
        let contacts = Array.isArray(this.state.contacts) ? this.state.contacts : [];
        contacts[0] = {ContactName: e.target.value};
        this.setState({ contacts: contacts });
    }

    changeBillingSameRemit(e){
        if(this.props.viewAction === "edit"){
            if(this.state.clickedRemitCheckbox === false){
                this.setState({ billingSameRemit: !this.state.billingSameRemit, clickedRemitCheckbox: true });
            }else{
                this.setState({ billingSameRemit: !this.state.billingSameRemit, clickedRemitCheckbox: false });
            }
        }else if(this.props.viewAction === "new"){
            if(this.state.clickedRemitCheckbox === false){
                this.setState({ billingSameRemit: !this.state.billingSameRemit, clickedRemitCheckbox: true });
            }else{
                this.setState({ clickedRemitCheckbox: false });
            }
        }
        
    }
    
    handleTextChangeSelectSearch(value, name){
        this.setState({ [name]: value });
    }
   
    customFieldsKeyHandleTextChange(e, i){
        let customFields = this.state.customFields;
        customFields[i].key = e.target.value;
        this.setState({ customFields: customFields });
    }

    customFieldsValueHandleTextChange(e, i){
        let customFields = this.state.customFields;
        customFields[i].value = e.target.value;
        this.setState({ customFields: customFields });
    }
   
    identifierHandleTextChange(e){
        let identifiers = this.state.identifiers;
        identifiers[e.target.id] = e.target.value;
        this.setState({ identifiers: identifiers });
    }
    
    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }

    closeModal(){
        this.setState({ 
            modalIsOpen: false, 
            accordionBillingDetailsOpen: false, 
            formErrors : {}
        });
        this.props.bill.setVendorOCR(null);
    }

    openModal(e){
        if(e){
            e.preventDefault();
        }
        let customFields = [];
        let customFieldsApi = this.props.vendorToUpdate && this.props.vendorToUpdate.AdditionalFields ? toJS(this.props.vendorToUpdate.AdditionalFields) : this.state.customFields;

        if(this.props.vendorToUpdate && this.props.vendorToUpdate.AdditionalFields){
            for (var [key, value] of Object.entries(customFieldsApi)) {
                customFields.push({key:key, value: value});
            }
        }
        else{
            // eslint-disable-next-line no-unused-vars
            for (var [key1, value1] of Object.entries(customFieldsApi)) {
                customFields.push(value1);
            }
        }

        let billingSameRemit = true;
        if(this.props.vendorToUpdate && (this.props.vendorToUpdate.Address1 !== this.props.vendorToUpdate.remitAddress1 || this.props.vendorToUpdate.Address2 !== this.props.vendorToUpdate.remitAddress2)){
            billingSameRemit = false;
        }


        //if(this.props.vendorToUpdate && 

        this.setState({
            id: this.props.vendorToUpdate && this.props.vendorToUpdate.VendorId ? this.props.vendorToUpdate.VendorId : this.props.vendorToUpdate && this.props.vendorToUpdate.vendorId ? this.props.vendorToUpdate.vendorId : "",
            name1: this.props.vendorToUpdate && this.props.vendorToUpdate.Name1 ? this.props.vendorToUpdate.Name1 :  this.props.vendorToUpdate && this.props.vendorToUpdate.name1 ? this.props.vendorToUpdate.name1 : "",
            contacts: this.props.vendorToUpdate && this.props.vendorToUpdate.Contacts ? this.props.vendorToUpdate.Contacts : this.props.vendorToUpdate && this.props.vendorToUpdate.contacts ? this.props.vendorToUpdate.contacts :  [],
            customerVendorAccount: this.props.vendorToUpdate && this.props.vendorToUpdate.CustomerVendorAccount ? this.props.vendorToUpdate.CustomerVendorAccount : this.props.vendorToUpdate && this.props.vendorToUpdate.customerVendorAccount ? this.props.vendorToUpdate.customerVendorAccount : "",
            vendorNumber: this.props.vendorToUpdate && this.props.vendorToUpdate.VendorNumber ? this.props.vendorToUpdate.VendorNumber : this.props.vendorToUpdate && this.props.vendorToUpdate.vendorNumber ? this.props.vendorToUpdate.vendorNumber : "",
            ein: this.props.vendorToUpdate && this.props.vendorToUpdate.EIN ? this.props.vendorToUpdate.EIN : this.props.vendorToUpdate && this.props.vendorToUpdate.ein ? this.props.vendorToUpdate.ein : "",
            email: this.props.vendorToUpdate && this.props.vendorToUpdate.Email ? this.props.vendorToUpdate.Email : this.props.vendorToUpdate && this.props.vendorToUpdate.email ? this.props.vendorToUpdate.email : "",
            phone: this.props.vendorToUpdate && this.props.vendorToUpdate.Phone ? this.props.vendorToUpdate.Phone : this.props.vendorToUpdate && this.props.vendorToUpdate.phone ? this.props.vendorToUpdate.phone : "",
            mcc: this.props.vendorToUpdate && this.props.vendorToUpdate.Mcc ? this.props.vendorToUpdate.Mcc : this.props.vendorToUpdate && this.props.vendorToUpdate.mcc ? this.props.vendorToUpdate.mcc : "",
            customFields: customFields,
            //identifiersSelected: this.props.vendorToUpdate && this.props.vendorToUpdate.IdentifierFields ? toJS(this.props.vendorToUpdate.IdentifierFields) : [],

            billingAddress1: this.props.vendorToUpdate && this.props.vendorToUpdate.Address1 ? this.props.vendorToUpdate.Address1 : this.props.vendorToUpdate && this.props.vendorToUpdate.address1 ? this.props.vendorToUpdate.address1 : "",
            billingAddress2: this.props.vendorToUpdate && this.props.vendorToUpdate.Address2 ? this.props.vendorToUpdate.Address2 : this.props.vendorToUpdate && this.props.vendorToUpdate.address2 ? this.props.vendorToUpdate.address2 : "",
            billingCity: this.props.vendorToUpdate && this.props.vendorToUpdate.City ? this.props.vendorToUpdate.City : this.props.vendorToUpdate && this.props.vendorToUpdate.city ? this.props.vendorToUpdate.city : "",
            billingState: this.props.vendorToUpdate && this.props.vendorToUpdate.State ? this.props.vendorToUpdate.State :this.props.vendorToUpdate && this.props.vendorToUpdate.state ? this.props.vendorToUpdate.state : "",
            billingZip: this.props.vendorToUpdate && this.props.vendorToUpdate.Zip ? this.props.vendorToUpdate.Zip : this.props.vendorToUpdate && this.props.vendorToUpdate.zip ? this.props.vendorToUpdate.zip : "",
            billingCountry: this.props.vendorToUpdate && this.props.vendorToUpdate.Country ? this.props.vendorToUpdate.Country : this.props.vendorToUpdate && this.props.vendorToUpdate.country ? this.props.vendorToUpdate.country : "",

            remitAddress1: this.props.vendorToUpdate && this.props.vendorToUpdate.remitAddress1 ? this.props.vendorToUpdate.remitAddress1 : "",
            remitAddress2: this.props.vendorToUpdate && this.props.vendorToUpdate.remitAddress2 ? this.props.vendorToUpdate.remitAddress2 : "",
            remitCity: this.props.vendorToUpdate && this.props.vendorToUpdate.remitCity ? this.props.vendorToUpdate.remitCity : "",
            remitState: this.props.vendorToUpdate && this.props.vendorToUpdate.remitState ? this.props.vendorToUpdate.remitState : "",
            remitZip: this.props.vendorToUpdate && this.props.vendorToUpdate.remitZip ? this.props.vendorToUpdate.remitZip : "",
            remitCountry: this.props.vendorToUpdate && this.props.vendorToUpdate.remitCountry ? this.props.vendorToUpdate.remitCountry : "",

            customField1: this.props.vendorToUpdate && this.props.vendorToUpdate.customField1 ? this.props.vendorToUpdate.customField1 : "",
            customField2: this.props.vendorToUpdate && this.props.vendorToUpdate.customField2 ? this.props.vendorToUpdate.customField2 : "",

            billingSameRemit: billingSameRemit,
            modalIsOpen: true
        });
    }

    addVendor(){
        let errors = this.validateFields();
        this.setState({ formErrors: errors }, function(){

            if(!this.state.accordionCustomFieldsOpen && this.hasCustomFieldsErrors()){
                this.accordionCustomFields.current.click();
            }

            if(Object.keys(errors).length === 0){

                this.props.bill.setVendorOCR(null);

                let vendor ={
                    id: this.state.id,
                    name1: this.state.name1,
                    contacts: this.state.contacts && this.state.contacts !== "" ? this.state.contacts : [],
                    customerVendorAccount: this.state.customerVendorAccount,
                    vendorNumber: this.state.vendorNumber,
                    ein: this.state.ein,
                    email: this.state.email,
                    phone: this.state.phone,
                    mcc: this.state.mcc,
                    additionalFields: this.state.customFields,
                    identifierFields: this.state.identifiersSelected,

                    address1: this.state.billingAddress1,
                    address2: this.state.billingAddress2,
                    city: this.state.billingCity,
                    state: this.state.billingState,
                    zip: this.state.billingZip,
                    country: this.state.billingCountry,
                    
                    remitAddress1: this.state.billingSameRemit ? this.state.billingAddress1 : this.state.remitAddress1,
                    remitAddress2: this.state.billingSameRemit ? this.state.billingAddress2 : this.state.remitAddress2,
                    remitCity: this.state.billingSameRemit ? this.state.billingCity : this.state.remitCity,
                    remitState: this.state.billingSameRemit ? this.state.billingState : this.state.remitState,
                    remitZip: this.state.billingSameRemit ? this.state.billingZip : this.state.remitZip,
                    remitCountry: this.state.billingSameRemit ? this.state.billingCountry : this.state.remitCountry,

                    customField1: this.state.customField1,
                    customField2: this.state.customField2
                }

                if(this.props.action.toLowerCase() === "edit"){
                    this.props.global.setLoading(true);
                    this.props.vendor.updateVendor(vendor)
                    .then(result => {

                        // calling parent functionCallback after saving
                        if(this.props.functionCallback){
                            this.props.functionCallback();
                        }

                        //this.closeModal();
                        this.props.global.setLoading(false);
                        toast.success("Vendor updated successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });

                        this.resetState();
                    })
                    .catch(error => {
                        this.props.global.setLoading(false);
                        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                        toast.error(errorMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    });
                }else if(this.props.action.toLowerCase() === "new"){
                    this.props.global.setLoading(true);
                    this.props.vendor.createVendor(vendor)
                    .then(result => {

                        if(this.props.functionCallbackNew){
                            this.props.functionCallbackNew(vendor, result);
                        }

                        // calling parent functionCallback after saving
                        if(this.props.functionCallback){
                            this.props.functionCallback();
                        }

                        //this.closeModal();
                        this.props.global.setLoading(false);
                        toast.success("Vendor created successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                        this.resetState();
                    })
                    .catch(error => {
                        this.props.global.setLoading(false);
                        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                        toast.error(errorMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    });
                }

               
                
            }
        });
        
    }

    removeCustomField(i){
        let customFields = this.state.customFields;
        customFields.splice(i, 1);
        this.setState({ customFields: customFields });
    }

    isIdentifier(key){
        let identifiers =  this.state.identifiers;
        if(identifiers){
            for(let i=0; i < identifiers.length; i++){
                if(identifiers[i].key.toLowerCase() === key.toLowerCase()){
                    return true;
                }
            }
        }
        
        return false;
    }

    hasCustomFieldsErrors(){
        let customFields =  this.state.customFields;
       
        for(let i=0; i < customFields.length; i++){
            if(this.state.formErrors['customFieldsValue'+i]){
                return true;
            }
        }
        return false;
    }

    validateFields(){
        let validators = this.props.global.validators;
        let errors = {};

        if(validators.isMaxLength(100, this.state.name1) || validators.isEmpty(this.state.name1))
        {
            errors['name1']= true;
        }
        if(validators.isMaxLength(9, this.state.ein) || (this.state.ein !== "" && validators.stringValidator("ein", this.state.ein)) || (this.isIdentifier("ein") ? validators.isEmpty(this.state.ein) : false))
        {
            errors['ein']= true;
        }
        if(validators.isMaxLength(40, this.state.vendorNumber) || validators.isEmpty(this.state.vendorNumber))
        {
            errors['vendorNumber']= true;
        }
        if(!validators.isEmpty(this.state.email) &&validators.stringValidator('email',this.state.email))
        {
            errors['email']= true;
        }
        if((this.componentPhone.current.hasError()) || (this.isIdentifier("phone") ? validators.isEmpty(this.state.phone) : false))
        {
            errors['phone']= true;
        }
        if(validators.isMaxLength(250, this.state.mcc) || (this.isIdentifier("mcc") ? validators.isEmpty(this.state.mcc) : false))
        {
            errors['mcc']= true;
        }
        if(validators.isEmpty(this.state.billingAddress1))
        {
            errors['billingAddress1']= true;
        }
        if(validators.isEmpty(this.state.billingCity))
        {
            errors['billingCity']= true;
        }
        if(validators.isEmpty(this.state.billingState))
        {
            errors['billingState']= true;
        }
        if(validators.isEmpty(this.state.billingZip))
        {
            errors['billingZip']= true;
        }        
        return errors;
    }

    setPhoneError(error){
        this.setState({phoneError: error});
    }
    render() {
        const hasVendorOCR = this.props.bill.hasVendorOCR;
        return (
            <>                
                <Modal show={this.state.modalIsOpen || hasVendorOCR} onHide={(e) => this.closeModal()}  size="md"  aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="popover-body" id="editVendorModal">

                <div className="row mb-3">
                    <div className="col"><h6 data-qaid="add/editModalVendorMoneyOut">{this.props.title ? this.props.title : "Edit Vendor"}</h6></div>
                </div>

                <div className="row">                    
                   <div className="col-12 mb-3">
                       <span className="mb-2" style={{display: "block"}}>Account Information</span> 
                       <div className="form-floating">
                            <input maxLength={200} className={this.state.formErrors.name1 ? "form-control input-error" : "form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="Business Name" id="name1" value={ this.state.name1 } />
                            <label htmlFor="name1">Business Name</label>
                        </div>
                   </div>
                   <div className="col-sm-6 mb-3">
                       <div className="form-floating">
                            <input maxLength={100} className={"form-control"} onChange={(e) => this.handleTextChangeContact(e)} placeholder="Contact Name" id="contactName" value={ Array.isArray(this.state.contacts) && this.state.contacts[0] && (this.state.contacts[0].ContactName || this.state.contacts[0].contactName) ? (this.state.contacts[0].contactName ? this.state.contacts[0].contactName : this.state.contacts[0].ContactName) : '' } />
                            <label htmlFor="contactName">Contact Name</label>
                        </div>
                   </div>
                   <div className="col-sm-6 mb-3">
                       <div className="form-floating right-addon">
                            <input maxLength={200} className={"form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="Account #" id="customerVendorAccount" value={ this.state.customerVendorAccount } style={{paddingRight: '25px'}} />
                            <label htmlFor="customerVendorAccount">Account #</label>
                            <div className='inner-addon'>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Account # is how your vendor identifies you as a company. Appears often in memo field on your vendor's invoices.</Tooltip>}
                                    >
                                    <BiInfoCircle/>
                                </OverlayTrigger>
                            </div>
                        </div>
                   </div>
                   <div className="col-sm-6 mb-3">
                        <div className="form-floating right-addon">
                            <IMaskInput
                                mask={this.props.global.maskValidator('text')}
                                name="vendorNumber"
                                value={ this.state.vendorNumber  }
                                unmask={true}
                                maxLength="40"                                
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('vendorNumber', value)
                                }
                                placeholder="Vendor # (for internal use)" 
                                className={this.state.formErrors.vendorNumber ? "form-control input-error" : "form-control"}
                                id="vendorNumber"
                                style={{paddingRight: '25px'}}
                            />
                            <label htmlFor="vendorNumber">Vendor # (for internal use)</label>
                            <div className='inner-addon'>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Vendor # is how you internally identify your vendor. This is often used for payment tracking or internal tracking.</Tooltip>}
                                    >
                                    <BiInfoCircle/>
                                </OverlayTrigger>
                            </div>
                        </div>
                   </div>
                   <div className="col-sm-6 mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('ein')}
                                name="ein"
                                value={ this.state.ein  }
                                unmask={true}
                                maxLength="10"
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('ein', value)
                                }
                                placeholder="Tax ID / EIN" 
                                className={this.state.formErrors.ein ? "form-control input-error" : "form-control"}
                                id="ein"
                            />
                            <label htmlFor="ein">Tax ID / EIN</label>
                        </div>
                   </div>
                   <div className="col-sm-6 mb-3">
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator('email')}
                                name="email"
                                value={ this.state.email }
                                unmask={true}
                                maxLength="200"
                                onAccept={
                                    (value, mask) => this.handleTextChangeMask('email', value)
                                }
                                placeholder="Email"
                                className={this.state.formErrors.email ? "form-control input-error" : "form-control"}
                                id="email"
                            />
                            <label htmlFor="email">Email</label>
                        </div>
                   </div>
                   <div className="col-sm-6 mb-3">
                    <PhoneInput
                        ref={this.componentPhone}
                        iValue={ this.state.phone || '' } 
                        iSetError={this.setPhoneError} 
                        iCustomClass="inner-addon" 
                        iTitle="Primary Phone #" 
                        iMask="phone" 
                        iOnChange={this.handleTextChangeMask}
                        iOnChangeForce={this.handleTextChangeMask}
                        iName="phone" 
                        full
                    />
                   </div>
                   <div className="col-12 mb-3">
                        <div className={this.state.formErrors.mcc ? "input-error form-floating" : "form-floating"}>
                            <SelectSearch
                                id="mcc"
                                options={this.props.global.getMccOptions()}
                                autoComplete={"selectSearch"}
                                search
                                filterOptions={fuzzySearch}
                                emptyMessage="Not found"
                                placeholder="Find and Select"
                                value={this.state.mcc}
                                onChange={(e) =>
                                    this.handleTextChangeSelectSearch(e,"mcc")
                                }
                                disabled={false}
                            />
                            <label htmlFor="mcc" className="forceFloatLabel">MCC</label>
                        </div>
                   </div>
                </div>
                <Accordion activeKey="0">
                <Card className="card-no-border mb-2">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2" ref={this.accordionBillingDetails}>
                        Billing Details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    <div className="col-12 mb-3">
                        <LocationSearchInput 
                            placeholder="Billing Address" 
                            onChange={this.handleGoogleAutocompleteBillingAddress} 
                            handleTextChange={this.handleTextChangeMask} 
                            address={this.state.billingAddress1}
                            address2={this.state.billingAddress2}
                            city={this.state.billingCity}
                            state={this.state.billingState}
                            zipcode={this.state.billingZip}
                            country={this.state.billingCountry}
                            handleTextChangeKeys={['billingAddress1','billingAddress2', 'billingCity', 'billingState', 'billingZip', 'billingCountry']}
                            formErrors={this.state.formErrors}
                        />
                    </div>

                    <div className="col-12 mb-3">
                        <div className="icheck-primary" onClick={(e)=>this.changeBillingSameRemit(e)} style={{display: "inline"}}>
                            <input onChange={function(){}} checked={this.state.billingSameRemit} type="checkbox" id="billingAddreSameRemit" />
                            <label htmlFor="billingAddreSameRemit">Remit address is same as billing</label>
                        </div>
                    </div>

                    {!this.state.billingSameRemit &&
                        <>
                        <div className="col-12 mb-3">
                            <LocationSearchInput  
                                placeholder="Remit Address"
                                className={"form-control"}
                                onChange={this.handleGoogleAutocompleteRemitAddress} 
                                handleTextChange={this.handleTextChangeMask} 
                                address={this.state.remitAddress1}
                                address2={this.state.remitAddress2}
                                city={this.state.remitCity}
                                state={this.state.remitState}
                                zipcode={this.state.remitZip}
                                country={this.state.remitCountry}
                                handleTextChangeKeys={['remitAddress1','remitAddress2', 'remitCity', 'remitState', 'remitZip', 'remitCountry']}
                            />
                        </div>
                        </>
                    }
                    
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>

                <Accordion >
                    <Card className="card-no-border mb-2">
                        <Accordion.Toggle as={Card.Header} eventKey="0" className="mb-2" ref={this.accordionCustomFields} onClick={(e)=>this.collapseAccordion("accordionCustomFieldsOpen")}>
                            {this.state.accordionCustomFieldsOpen ? <BiChevronDown/> : <BiChevronRight/>} 
                            Custom Fields &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>You can add up to 2 custom fields to your vendor profile. Custom fields will display on your reports.</Tooltip>}
                            >
                                <BiInfoCircle/>
                            </OverlayTrigger>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div className='mb-3'>
                                    <div className="form-floating">
                                        <IMaskInput
                                            mask={this.props.global.maskValidator('alphanumericspaces')}
                                            name="customField1"
                                            value={this.state.customField1}
                                            unmask={true}
                                            maxLength="250"                                            
                                            onAccept={
                                                (value) => this.handleTextChangeMask('customField1', value)
                                            }
                                            placeholder="Custom Field 1" 
                                            className={this.state.formErrors.customField1 ? "form-control input-error" : "form-control"}
                                            id="customField1"
                                        />
                                        <label htmlFor="customField1">Custom Field 1</label>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="form-floating">                            
                                        <IMaskInput
                                            mask={this.props.global.maskValidator('alphanumericspaces')}
                                            name="customField2"
                                            value={ this.state.customField2}
                                            unmask={true}
                                            maxLength="250"                                            
                                            onAccept={
                                                (value, mask) => this.handleTextChangeMask('customField2', value)
                                            }
                                            placeholder="Custom Field 2" 
                                            className={this.state.formErrors.customField2 ? "form-control input-error" : "form-control"}
                                            id="customField2"
                                        />
                                        <label htmlFor="customField2">Custom Field 2</label>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <button className="btn full-w btn-light" type="button" onClick={() => this.closeModal() } data-qaid="cancelModalVendorMoneyOutButton">Cancel</button>
                    </div>
                    <div className="col-sm-12 col-md-6">
                    <button className="btn full-w btn-primary" data-qaid="saveModalVendorMoneyOutButton" type="button" onClick={() => this.addVendor()}>{"Save Vendor"}</button>   
                    </div>
                </div>
                </Modal.Body>
                </Modal>
                <a data-qaid={this.props.viewAction !== "edit" ? "addVendorMoneyOutButton" : "editVendorMoneyOutLink"} style={ this.props.style ? this.props.style : {}} className={this.props.className ? this.props.className : "dropdown-item editVendor"} onClick={(e) => this.openModal(e)}>{this.props.label ? this.props.label : "Edit Vendor"}</a>
            </>
        )
    }
}

export { EditVendor };