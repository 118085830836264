import axios from 'axios';
import { SecurityManager } from './securityManager';
import { PayabliStorageManager } from '../api/localStorageManager'

const unauthorizedMessages = ['Unauthorized Api client.'];

axios.interceptors.response.use(
  (response) => {
    if (response.config.headers.requestToken && response.config.headers.requestToken !== process.env.REACT_APP_TOKEN) {
      PayabliStorageManager.setEncryptedLocalStorageKey(response.config.headers.requestToken);
    }
    return response;
  },
  async (error) => {
    if (error.response) {
      var url = new URL(error.response.config?.url);
      if (url.origin === process.env.CLM_API_URL) {
        return;
      }
      let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
      const pEntry = (encryptStorage && encryptStorage.getItem(PayabliStorageManager.getEntryName() + '_pEntry')) ?  
        encryptStorage.getItem(PayabliStorageManager.getEntryName() + '_pEntry') : null;
      const { data, status } = error.response;

      if (pEntry && status === 403 && unauthorizedMessages.indexOf(data) !== -1) {
        SecurityManager.logout(pEntry);
      }
    }
    return Promise.reject(error);
  }
);
