import React from 'react';
import { inject, observer } from 'mobx-react';

import { Accordion, Card } from 'react-bootstrap';
import { PaymentMethodECheck } from '../../../../../components/PaymentMethodECheck';
import achIcon from '../../../../../assets/images/ach.svg';
import cardIcon from '../../../../../assets/images/card.svg';
import paperCheck from '../../../../../assets/images/paper-check.svg';

import visa from '../../../../../assets/images/visa.svg';
import mastercard from '../../../../../assets/images/mastercard.svg';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiTimer } from 'react-icons/bi';

@inject('store', 'global', 'vTerminal', 'bill')
@observer
class HowVendorGetPaid extends React.Component {
  constructor(props) {
    super(props);

    this.setPaymentMethod = this.setPaymentMethod.bind(this);
  }

  componentDidMount() {
    this.props.global.setLoading(true);
    this.props.vTerminal
      .getPaymentPageFromApi()
      .then((res) => {
        this.props.global.setLoading(false);
      })
      .catch((error) => {
        this.props.global.setLoading(false);
        let errorMessage =
          error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      });
  }

  componentDidUpdate() {
    let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;

    if (
      this.props.vTerminal.getCustomerSelected.StoredMethods &&
      this.props.vTerminal.getCustomerSelected.StoredMethods.length === 0
    ) {
      if (
        this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' &&
        this.props.vTerminal.hasCards &&
        ((credentialsPermissions.card.onetime && !this.props.vTerminal.isAutopay) ||
          (credentialsPermissions.card.recurring && this.props.vTerminal.isAutopay))
      ) {
        this.props.vTerminal.setPaymentMethod('card');
        this.props.vTerminal.setDefaultPaymentMethodActiveKey('0');
      } else if (
        this.props.vTerminal.getPaymentMethod.toLowerCase() === 'ach' &&
        this.props.vTerminal.hasECheck &&
        ((credentialsPermissions.ach.onetime && !this.props.vTerminal.isAutopay) ||
          (credentialsPermissions.ach.recurring && this.props.vTerminal.isAutopay))
      ) {
        this.props.vTerminal.setPaymentMethod('ach');
        this.props.vTerminal.setDefaultPaymentMethodActiveKey('1');
      }
    }
  }

  setPaymentMethod(method, activeKey) {
    this.props.vTerminal.setPaymentMethod(method);
    if (activeKey !== null) {
      this.props.vTerminal.setDefaultPaymentMethodActiveKey(activeKey);
    }
    this.props.vTerminal.setSavedPaymentMethodSelected('');
  }

  render() {    
    let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
    return (
      <div className="charge-payment-options">
        <h6 className="mb-3">Payment Method</h6>

        <Accordion activeKey={this.props.vTerminal.defaultPaymentMethodActiveKey}>

        {credentialsPermissions.managed.moneyout &&
        <Card className={ (this.props.vTerminal.defaultPaymentMethodActiveKey === "4") ? "card card-in mb-3 card-hover card-selected" : "card card-in mb-3 card-hover"}>
            <Accordion.Toggle
              as={Card.Header}
              className="card-body"
              eventKey="4"
              onClick={(e) => this.setPaymentMethod('managed', '4')}
            >
              <div className="row">
                <div className="col-2 text-center">
                <BiTimer style={{fontSize: '39px', marginTop: "4px",  opacity: "0.25"}}/>
                </div>
                <div className="col-10">
                  Managed
                  <p className="small small-grey-m0"> The vendor will be contacted to obtain payment</p>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body style={{padding: 0}}>
                
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          }

          {credentialsPermissions.vcard.moneyout &&
          
          <Card className={ (this.props.vTerminal.defaultPaymentMethodActiveKey === "2") ? "card card-in mb-3 card-hover card-selected" : "card card-in mb-3 card-hover"}>
            <Accordion.Toggle
              as={Card.Header}
              className="card-body"
              eventKey="2"
              onClick={(e) => this.setPaymentMethod('vcard', '2')}
            >
              <div className="row">
                <div className="col-2 text-center">
                <img alt="" style={{width: '34px', marginTop: "8px"}} className="grey-icon-v2" src={cardIcon}/>
                </div>
                <div className="col-10">
                  Virtual Card
                  <p className="small small-grey-m0"> Recipient is emailed a digital card</p>
                  <div className="card-brands accordion-right-corner" style={{position: 'absolute'}}>
                    <img alt="" src={visa} />
                    <img alt="" className="mastercard" src={mastercard} />
                  </div>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body className='pt-1'>
                  <div className="row">
                      <div className="col-2">
                      
                      </div>
                      <div className="col-10" >
                          <div>
                          <div className="form-floating">
                              <input readOnly autoComplete="off" value={this.props.bill.getVendorSelected?.Email} className={"form-control"} placeholder="emailedCheck" />
                              <label htmlFor="emailedCheck">Remittance email on file</label>
                          </div>
                          </div>
                      </div>
                  </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          }

            {credentialsPermissions.ach.moneyout &&
            <Card className={ (this.props.vTerminal.defaultPaymentMethodActiveKey === "1") ? "card card-in mb-3 card-hover card-selected" : "card card-in mb-3 card-hover"}>
              <Accordion.Toggle
                as={Card.Header}
                className="card-body"
                eventKey="1"
                onClick={(e) => this.setPaymentMethod('ach', '1')}
              >
                <div className="row">
                  <div className="col-2 text-center">
                  <img alt="" style={{width: '36px', marginTop: "10px"}} className="grey-icon-v2" src={achIcon}/>
                  </div>
                  <div className="col-10">
                    Bank Account
                    <p className="small small-grey-m0">Pay from your Bank Account</p>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <PaymentMethodECheck history={this.props.history} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            }

            {credentialsPermissions.check.moneyout &&
            <Card className={ (this.props.vTerminal.defaultPaymentMethodActiveKey === "3") ? "card card-in mb-3 card-hover card-selected" : "card card-in mb-3 card-hover"}>
            <Accordion.Toggle
              as={Card.Header}
              className="card-body"
              eventKey="3"
              onClick={(e) => this.props.bill.getVendorSelected?.Address1 ? this.setPaymentMethod('check', '3') : null}
            >
              <div className="row">
                <div className="col-2 text-center">
                <img alt="" style={{width: '36px', marginTop: "7px"}} className="grey-icon-v2" src={paperCheck}/>
                </div>
                <div className="col-10">
                  Paper Check 
                  <p className="small small-grey-m0">Recipient is mailed a check to their address on file </p>
                  {this.props.bill.getVendorSelected?.Address1 ? "" : <p className="small-small small-grey-m0 text-danger mt-1">The selected vendor does'nt have an address to send a check </p>}
                  
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
                <Card.Body className='pt-1'>
                    <div className="row">
                        <div className="col-2">
                        
                        </div>
                        <div className="col-10" >
                            <div>
                            <div className="form-floating">
                                <input readOnly autoComplete="off" value={this.props.bill.getVendorSelected?.Address1 + " " + this.props.bill.getVendorSelected?.City + " " + this.props.bill.getVendorSelected?.State + " " + this.props.bill.getVendorSelected?.Zip + " " + this.props.bill.getVendorSelected?.Country} className={"form-control"} placeholder="emailedCheck" />
                                <label htmlFor="emailedCheck">Remittance address on file</label>
                            </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
          </Card>
          }
         
        </Accordion>

        <div className="review-total mt-5">
          <h6 className="sub-header-line mb-3">Review & Send Payment</h6>
          {/*<div className="row">
            <div className="col">Net Amount:</div>
            <div className="col">
              $
              {this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['netAmount']).toFixed(2))}
            </div>
          </div>
          <div className="row">
            <div className="col">Fee:</div>
            <div className="col">
              ${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['fee']).toFixed(2))}
            </div>
        </div>*/}

          <div className="row total-amount">
            <div className="col">Total Amount:</div>
            <div className="col">
              $
              {this.props.global.numberWithCommas(
                //(parseFloat(this.props.vTerminal.totalAmount['totalAmount']) + parseFloat(this.props.bill.billDetails.totalAmount)).toFixed(2)
                (parseFloat(this.props.bill.billDetails?.totalAmount ? this.props.bill.billDetails.totalAmount : 0)).toFixed(2)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowVendorGetPaid;
