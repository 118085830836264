import React from 'react';
import { inject, observer } from 'mobx-react';
import { Accordion, Card } from 'react-bootstrap';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { Message } from '../components/Message';

@inject('reports')
@observer
class PayBillAdvancedOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsOpen: false
    };

    this.handleAccordionState = this.handleAccordionState.bind(this);
  }

  handleAccordionState() {
    this.setState({ optionsOpen: !this.state.optionsOpen });
  }

  render() {

    return (
      <div>
          <div className="section-form" id="advanced-options">
            <Accordion>
              <Card className="card card-in">
                <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={this.handleAccordionState}>
                  <h6>
                    Advanced options <small>(optional)</small>
                    {this.state.optionsOpen && <BiChevronUp />}
                    {!this.state.optionsOpen && <BiChevronDown />}
                  </h6>
                </Accordion.Toggle>
              </Card>

              <Accordion.Collapse eventKey="0">
                <div>
                  <Message message="Coming Soon."  />
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>

          
      </div>
    );
  }
}

export { PayBillAdvancedOptions };
