import React from "react";
import { BiLogoApple } from "react-icons/bi";
import { inject, observer } from "mobx-react";

@inject("store", "applepay")
@observer
class ApplePayButton extends React.Component {
  constructor(props) {
    super(props);
    const { buttonStyle, buttonType } = this.props.store.applePayButton;
    this.buttonStyle = buttonStyle;
    this.buttonType = buttonType;
  }
  render() {
    const isWhiteOutline = this.buttonStyle === "white-outline";
    const buttonClasses = `btn ${
      this.buttonStyle === "black"
        ? "btn-dark"
        : ""
    } btn-block d-flex align-items-center justify-content-center w-100 ${
      isWhiteOutline ? "border border-dark" : ""
    }`;

    return (
      <button
        className={buttonClasses}
        style={{
          borderRadius: "6px",
          height: "50px",
          textTransform: "none",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
          userSelect: "none",
          WebkitTouchCallout: "none",
          WebkitTapHighlightColor: "transparent",
        }}
        onClick={() => {
          this.props.onClick();
        }}
      >
        <span
          className="fs-5"
          style={{ fontWeight: "600", marginRight: "3px" }}
        >
          {this.props.applepay.getApplePayButtonType(this.buttonType).displayText}
        </span>
        <span className="fs-5 d-flex align-items-center my-auto">
          <BiLogoApple />
        </span>
        <span className="fs-5" style={{ fontWeight: "600", marginLeft: "2px" }}>
          Pay
        </span>
      </button>
    );
  }
}

export { ApplePayButton };
