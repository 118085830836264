import React from 'react'
import { inject, observer } from 'mobx-react'
import { Layout } from '../../components/Layout'
import { MainTopBarMenu } from '../../components/MainTopBarMenu'
import { DevelopersLinks } from '../../components/DevelopersLinks'
import { ReportPaginatorApiKeys } from '../../components/ReportPaginatorApiKeys'
import { TopBar } from '../../components/TopBar'
import {
	Button,
	Dropdown,
	DropdownButton,
	Modal,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap'
import {
	BiCopy,
	BiGridAlt,
	BiHide,
	BiInfoCircle,
	BiKey,
	BiListUl,
	BiDotsVerticalRounded,
	BiPlusCircle,
	BiShow,
	BiSlider,
	BiTrash
} from 'react-icons/bi'
import { ToastContainer, toast, Bounce } from 'react-toastify'
import { PayabliStorageManager } from '../../api/localStorageManager'
import { RecordsNotFound } from '../../components/RecordsNotFound'
import { RightPanel } from '../../components/RightPanel'

@inject('global', 'developers')
@observer
class ApiKeys extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			deleteModalIsOpen: false,
			newModalIsOpen: false,
			tokenToDelete: null,
			recordDetails: null,
			records: [],
			localFilter: 0,
			forCreatorToken: true,
			tokenType: 'o',
			tokenMode: 1,
			lifetime: 1,
			lifetimeArray: [
				1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
				19, 20, 21, 22, 23, 24
			],
			description: '',
			ipRestrictions: [''],
			domainRestrictions: [''],
			formErrors: {}
		}
		this.validateFields = this.validateFields.bind(this)
		this.getReportFromApi = this.getReportFromApi.bind(this)
		this.addIp = this.addIp.bind(this)
		this.removeIp = this.removeIp.bind(this)
		this.addRestrictions = this.addRestrictions.bind(this)
		this.removeRestrictions = this.removeRestrictions.bind(this)
		this.handleTextChangeMask = this.handleTextChangeMask.bind(this)
		this.handleTextChangeRestrictions =
			this.handleTextChangeRestrictions.bind(this)
		this.handleTextChange = this.handleTextChange.bind(this)
		this.handleRadioOption = this.handleRadioOption.bind(this)
		this.handleLifetime = this.handleLifetime.bind(this)
		this.setTokenMode = this.setTokenMode.bind(this)
		this.localFilter = this.localFilter.bind(this)
		this.showRecord = this.showRecord.bind(this)
		this.viewRecordDetails = this.viewRecordDetails.bind(this)
		this.getRecordType = this.getRecordType.bind(this)
		this.getIpRestrictions = this.getIpRestrictions.bind(this)
		this.copyApiToken = this.copyApiToken.bind(this)
		this.deleteToken = this.deleteToken.bind(this)
		this.getDomainRestrictions = this.getDomainRestrictions.bind(this)
		this.createTokenAction = this.createTokenAction.bind(this)
		this.deleteTokenAction = this.deleteTokenAction.bind(this)
		this.deleteModalStatus = this.deleteModalStatus.bind(this)
		this.newModalStatus = this.newModalStatus.bind(this)
		this.getExpirationDate = this.getExpirationDate.bind(this)
		this.getLimitedToIpAsString = this.getLimitedToIpAsString.bind(this)
		this.getLimitedToIpAsColumn = this.getLimitedToIpAsColumn.bind(this)
		this.getFilterButtons = this.getFilterButtons.bind(this)
		this.handleRadioOptionType = this.handleRadioOptionType.bind(this)
	}

	componentDidMount() {
		this.getReportFromApi()
	}

	validateFields() {
		let validators = this.props.global.validators
		let errors = {}

		if (validators.isMaxLength(255, this.state.description)) {
			errors['description'] = true
		}

		if (
			validators.isEmpty(this.state.tokenMode) ||
			!Number.isInteger(this.state.tokenMode)
		) {
			errors['tokenMode'] = true
		}

		if (
			validators.isEmpty(this.state.lifetime) ||
			!Number.isInteger(this.state.lifetime) ||
			this.state.lifetime < 1
		) {
			errors['lifetime'] = true
		}

		if (Array.isArray(this.state.ipRestrictions)) {
			this.state.ipRestrictions.forEach(function (itemI, indexI) {
				if (
					validators.isMaxLength(15, itemI) ||
					validators.stringValidator('ipv4', itemI)
				) {
					if (!errors.ipRestrictions) {
						errors.ipRestrictions = []
					}
					errors.ipRestrictions[indexI] = true
				}
			})
		}

		if (
			this.state.forCreatorToken &&
			Array.isArray(this.state.domainRestrictions)
		) {
			this.state.domainRestrictions.forEach((itemI, indexI) => {
				if (validators.isEmpty(itemI)) {
					if (!errors.domainRestrictions) {
						errors.domainRestrictions = []
					}
					errors.domainRestrictions[indexI] = true
				}
			})
		}

		return errors
	}

	addIp(position) {
		let ipRestrictions = this.state.ipRestrictions
		ipRestrictions[position + 1] = ''

		this.setState({
			ipRestrictions: ipRestrictions
		})
	}

	removeIp(position) {
		let ipRestrictions = this.state.ipRestrictions
		delete ipRestrictions[position]
		this.setState({
			ipRestrictions: ipRestrictions
		})
	}

	addRestrictions(position, typeRestrictions) {
		let tempRestrictions = this.state[typeRestrictions]
		tempRestrictions[position + 1] = ''
		this.setState({ [typeRestrictions]: tempRestrictions })
	}

	removeRestrictions(position, typeRestrictions) {
		let tempRestrictions = this.state[typeRestrictions]
		delete tempRestrictions[position]
		this.setState({ [typeRestrictions]: tempRestrictions })
	}

	handleTextChangeMask(i, value) {
		let ipRestrictions = this.state.ipRestrictions
		ipRestrictions[i] = value
		this.setState({ ipRestrictions: ipRestrictions })
	}

	handleTextChangeRestrictions(i, event) {
		const { id, value } = event.target
		let tempRestrictions = this.state[id]
		tempRestrictions[i] = value
		this.setState({ [id]: tempRestrictions })
	}

	handleTextChange(e) {
		if (e) {
			this.setState({ [e.target.name]: e.target.value })
		}
	}

	handleRadioOption() {
		let radioOption = !this.state.forCreatorToken
		this.setState({ forCreatorToken: radioOption })
	}

	handleRadioOptionType(e) {
		const value = e.target.value,
			forCreatorToken = value === 'o' ? true : false
		this.setState({ tokenType: value, forCreatorToken: forCreatorToken })
	}

	handleLifetime(e) {
		if (e) {
			this.setState({ [e.target.name]: parseInt(e.target.value) })
		}
	}

	setTokenMode(mode) {
		this.setState({ tokenMode: mode })
	}

	localFilter(e, filter) {
		if (e) {
			e.preventDefault()
		}
		this.setState({
			localFilter: filter
		})
	}

	showRecord(record) {
		//all
		if (this.state.localFilter === 0) {
			return true
		}
		//public
		if (
			this.state.localFilter === 1 &&
			record.type.toLowerCase() === 'o' &&
			record.mode === 1
		) {
			return true
		}
		//private
		if (
			this.state.localFilter === 2 &&
			(record.type.toLowerCase() === 'a' ||
				(record.type.toLowerCase() === 'o' && record.mode === 0))
		) {
			return true
		}
		return false
	}

	viewRecordDetails(record, object) {
		if (
			object &&
			(this.props.global.isDrag() ||
				object.target.rel === 'noreferrer' ||
				object.target.tagName.toLowerCase() === 'svg' ||
				object.target.tagName.toLowerCase() === 'a' ||
				object.target.tagName.toLowerCase() === 'path')
		) {
			return
		}
		if (object?.target?.id !== 'actionsMenuButton') {
			let thisObj = this
			if (
				thisObj.props.global.rightPanelOpen &&
				thisObj.state.recordDetails &&
				thisObj.state.recordDetails.ApiToken !== record.ApiToken
			) {
				thisObj.props.global.setRightPanelOpen(false)
				this.setState(
					{
						recordDetails: record
					},
					setTimeout(function () {
						thisObj.props.global.setRightPanelOpen(true)
					}, 500)
				)
			} else {
				this.setState(
					{
						recordDetails: record
					},
					thisObj.props.global.setRightPanelOpen(true)
				)
			}
		}
	}

	getRecordType(record, text) {
		if (text) {
			let returnValue = null
			if (record.type.toLowerCase() === 'a') {
				returnValue = 'Private'
			}

			if (record.type.toLowerCase() === 'o') {
				switch (record.mode) {
					case 0:
						returnValue = 'Private'
						break
					case 1:
						returnValue = 'Public'
						break
					default:
						returnValue = 'Unknown'
				}
			}

			return returnValue
		} else {
			let returnValue = null
			if (record.type.toLowerCase() === 'a') {
				returnValue = (
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>Private</Tooltip>}>
						<BiHide style={{ fontSize: '20px' }} />
					</OverlayTrigger>
				)
			}

			if (record.type.toLowerCase() === 'o') {
				switch (record.mode) {
					case 0:
						returnValue = (
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip>Private</Tooltip>}>
								<BiHide style={{ fontSize: '20px' }} />
							</OverlayTrigger>
						)
						break
					case 1:
						returnValue = (
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip>Public</Tooltip>}>
								<BiShow style={{ fontSize: '20px' }} />
							</OverlayTrigger>
						)
						break
					default:
						returnValue = 'Unknown'
				}
			}

			return returnValue
		}
	}

	copyApiToken(token) {
		if (token) {
			navigator.clipboard.writeText(token)
			toast.success('API Token copied!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-success-container'
			})
		}
	}

	deleteToken(token) {
		this.setState({
			deleteModalIsOpen: true,
			tokenToDelete: token
		})
	}

	getIpRestrictions() {
		let validators = this.props.global.validators
		let ipRestrictions = this.state.ipRestrictions
		let returnIpRestrictions = []
		ipRestrictions.forEach(function (itemI) {
			if (!validators.isEmpty(itemI)) {
				returnIpRestrictions.push(itemI)
			}
		})
		return returnIpRestrictions
	}

	getDomainRestrictions() {
		let validators = this.props.global.validators
		let domainRestrictions = this.state.domainRestrictions
		let returnDomainRestrictions = []
		domainRestrictions.forEach((itemI) => {
			if (!validators.isEmpty(itemI)) {
				returnDomainRestrictions.push(itemI)
			}
		})
		return returnDomainRestrictions
	}

	createTokenAction() {
		let errors = this.validateFields()
		this.setState({ formErrors: errors }, async function () {
			if (Object.keys(errors).length === 0) {
				let encryptStorage =
					PayabliStorageManager.getEncryptedLocalStorage()
				let paypointId = encryptStorage.getItem(
					`${PayabliStorageManager.getEntryName()}_pEntry`
				).paypointId
				let data = {
					level: 2,
					orgId: paypointId,
					lifeTime: this.state.lifetime,
					mode: this.state.tokenMode,
					type: this.state.tokenType,
					description: this.state.description,
					limitedToIp: this.getIpRestrictions(),
					limitedToDomains: this.getDomainRestrictions()
				}
				this.props.global.setLoading(true)
				try {
					let dataSigning = await this.props.developers.getSigning()
					if (dataSigning.data && dataSigning.data.responseData) {
						this.props.developers
							.createApiKey(data, dataSigning.data.responseData)
							.then((res) => {
								if (res?.data?.isSuccess) {
									toast.success('Token created!', {
										position: toast.POSITION.BOTTOM_RIGHT,
										className: 'toast-success-container'
									})
									this.getReportFromApi()
								}
								this.newModalStatus(false)
								this.props.global.setLoading(false)
							})
							.catch((error) => {
								this.props.global.setLoading(false)
								let errorMessage =
									error.response &&
									error.response.data.responseText
										? error.response.data.responseText
										: 'Something is wrong!'
								toast.error(errorMessage, {
									position: toast.POSITION.BOTTOM_RIGHT,
									className: 'toast-error-container'
								})
							})
					} else {
						throw new Error('Failed Authentication')
					}
				} catch (error) {
					this.props.global.setLoading(false)
					toast.error(error, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				}
			}
		})
	}

	async deleteTokenAction() {
		if (this.state.tokenToDelete) {
			this.props.global.setLoading(true)
			try {
				let dataSigning = await this.props.developers.getSigning()
				if (dataSigning.data && dataSigning.data.responseData) {
					this.props.developers
						.deleteAPIKey(
							this.state.tokenToDelete,
							dataSigning.data.responseData
						)
						.then((res) => {
							this.props.global.setLoading(false)
							this.deleteModalStatus(false)
							toast.success('API Token deleted!', {
								position: toast.POSITION.BOTTOM_RIGHT,
								className: 'toast-success-container'
							})
							this.getReportFromApi()
						})
						.catch((error) => {
							this.props.global.setLoading(false)
							let errorMessage =
								error.response &&
								error.response.data.responseText
									? error.response.data.responseText
									: 'Something is wrong!'
							toast.error(errorMessage, {
								position: toast.POSITION.BOTTOM_RIGHT,
								className: 'toast-error-container'
							})
						})
				} else {
					throw new Error('Failed Authentication')
				}
			} catch (error) {
				this.props.global.setLoading(false)
				toast.error(error, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			}
		}
	}

	deleteModalStatus(status) {
		this.setState({ deleteModalIsOpen: status })
	}

	newModalStatus(status, clear) {
		if (clear) {
			this.setState({
				newModalIsOpen: status
			})
		} else {
			this.setState({
				newModalIsOpen: status,
				tokenMode: 1,
				lifetime: 1,
				description: '',
				ipRestrictions: [''],
				formErrors: {}
			})
		}
	}

	getExpirationDate(date) {
		let exp = new Date(date)
		let now = new Date()
		let diffInDays = Math.floor((exp - now) / (1000 * 60 * 60 * 24))

		let style = {
			fontSize: '9px',
			fontWeight: 'bold'
		}

		if (now >= exp) {
			return (
				<>
					<span
						style={style}
						className="small text-uppercase text-danger">
						Token Expired!
					</span>
					<br />
					<span className="badge bg-danger">
						{'0 Days | ' +
							this.props.global.stringDateFormatV3(date)}
					</span>
				</>
			)
		} else if (diffInDays < 30) {
			return (
				<>
					<span
						style={style}
						className="small text-uppercase text-warning">
						Expires Soon!
					</span>
					<br />
					<span className="badge bg-warning">
						{diffInDays +
							' Days | ' +
							this.props.global.stringDateFormatV3(date)}
					</span>
				</>
			)
		} else {
			return (
				<>
					<span className="badge bg-info">
						{diffInDays +
							' Days | ' +
							this.props.global.stringDateFormatV3(date)}
					</span>
				</>
			)
		}
	}

	getLimitedToIpAsString(record) {
		let returnString = ''
		if (record.limitedToIp && Array.isArray(record.limitedToIp)) {
			returnString = record.limitedToIp.join(' | ')
		}
		return returnString
	}

	getLimitedToIpAsColumn(record) {
		if (record.limitedToIp && Array.isArray(record.limitedToIp)) {
			return record.limitedToIp.map((i) => (
				<React.Fragment key={i}>
					<span>{i}</span>
					<br />
				</React.Fragment>
			))
		}
		return null
	}

	getFilterButtons() {
		return (
			<>
				<a
					href="/"
					onClick={(e) => this.localFilter(e, 0)}
					className={this.state.localFilter === 0 ? 'active' : null}>
					<BiGridAlt /> All
				</a>
				<a
					href="/"
					onClick={(e) => this.localFilter(e, 1)}
					className={this.state.localFilter === 1 ? 'active' : null}>
					<BiShow /> Public
				</a>
				<a
					href="/"
					onClick={(e) => this.localFilter(e, 2)}
					className={this.state.localFilter === 2 ? 'active' : null}>
					<BiHide /> Private
				</a>
			</>
		)
	}

	async getReportFromApi() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let entry =
			encryptStorage &&
			encryptStorage.getItem(
				`${PayabliStorageManager.getEntryName()}_pEntry`
			) &&
			encryptStorage.getItem(
				`${PayabliStorageManager.getEntryName()}_pEntry`
			).pEntry
				? encryptStorage.getItem(
						`${PayabliStorageManager.getEntryName()}_pEntry`
				  ).pEntry
				: 0
		this.props.global.setLoading(true)
		try {
			let dataSigning = await this.props.developers.getSigning()
			if (dataSigning.data && dataSigning.data.responseData) {
				this.props.developers
					.getAPIKeysFromApi(entry, 2, dataSigning.data.responseData)
					.then((res) => {
						this.props.global.setLoading(false)
					})
					.catch((error) => {
						this.props.global.setLoading(false)
						let errorMessage =
							error.response && error.response.data.responseText
								? error.response.data.responseText
								: 'Something is wrong!'
						toast.error(errorMessage, {
							position: toast.POSITION.BOTTOM_RIGHT,
							className: 'toast-error-container'
						})
					})
			} else {
				throw new Error('Failed Authentication')
			}
		} catch (error) {
			this.props.global.setLoading(false)
			toast.error(error, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
		}
	}

	render() {
		let recordDetails = this.state.recordDetails
		return (
			<Layout {...this.props}>
				{/* Modal for Remove Token */}
				<Modal
					style={{ textAlign: 'center' }}
					show={this.state.deleteModalIsOpen}
					onHide={() => this.deleteModalStatus(false)}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered>
					<Modal.Body>
						<BiTrash className="icon-modal" />
						<h5>Delete</h5>
						<p className="small">
							This action will delete the Token selected, do you
							want to continue?
						</p>
						<Button
							className="btn cancel-btn"
							variant="default"
							onClick={() => this.deleteModalStatus(false)}>
							Cancel
						</Button>
						&nbsp;&nbsp;
						<Button
							className="btn"
							variant="danger"
							onClick={() => this.deleteTokenAction()}>
							Delete Token
						</Button>
					</Modal.Body>
				</Modal>
				{/* Modal for Create Token */}
				<Modal
					show={this.state.newModalIsOpen}
					onHide={() => this.newModalStatus(false)}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="modal-api-key">
					<Modal.Body className="small popover-body">
						<h6>Create API Token</h6>
						<label className="mb-3 mt-3 blue-text">
							<b>Select Token Mode</b>
							<OverlayTrigger
								placement="right"
								overlay={
									<Tooltip>
										<b>Public:</b> Used with Embedded
										Components.
										<br />
										<b>Private:</b> Used with payment APIs,
										cloud devices, Payment Device SDK for
										Windows/Linux, and query APIs.
									</Tooltip>
								}>
								<BiInfoCircle
									style={{
										marginLeft: '5px',
										fontSize: '14px'
									}}
								/>
							</OverlayTrigger>
						</label>
						<div className="row select-buttons">
							<div className="col text-center mb-3">
								<div
									onClick={() => this.setTokenMode(1)}
									className={
										this.state.tokenMode === 1
											? 'item active'
											: 'item'
									}>
									<BiShow />
									<br />
									Public
								</div>
							</div>
							<div className="col text-center mb-3">
								<div
									onClick={() => this.setTokenMode(0)}
									className={
										this.state.tokenMode === 0
											? 'item active'
											: 'item'
									}>
									<BiHide style={{ color: '#64D8CB' }} />
									<br />
									Private
								</div>
							</div>
						</div>
						{this.state.formErrors.tokenMode && (
							<p className="small text-danger">
								Select a Token Mode.
							</p>
						)}
						<label className="mb-2 blue-text">
							<b>Configuration</b>
						</label>
						<div className="row">
							<div className="col-4 pt-2 mb-2">
								<b>Token Type</b>
								<OverlayTrigger
									placement="right"
									overlay={
										<Tooltip>
											<b>Organization: </b>
											The organization token is the most
											common API token used in the
											platform. Anyone with an
											organization token has access to
											most API functions and endpoints.
											<br />
											<b>Application: </b>
											The application token is used with
											API endpoints for authentication and
											managing entities. For example, you
											need this kind of token if you are
											building your own portals for
											paypoints, or any solution that
											involves users authenticating to
											Payabli outside of PartnerHub or a
											PayHub.
										</Tooltip>
									}>
									<BiInfoCircle
										style={{
											color: '#03A9F4',
											marginLeft: '5px',
											fontSize: '14px'
										}}
									/>
								</OverlayTrigger>
							</div>
							<div className="col-8 mb-2">
								<div
									className="form-group"
									style={{ display: 'inline-flex' }}>
									<div className="icheck-primary mr-3">
										<input
											type="radio"
											name="inlineRadioForTypeOrganization"
											id="inlineRadioForTypeOrganization"
											value="o"
											checked={
												this.state.tokenType === 'o'
											}
											onChange={(e) =>
												this.handleRadioOptionType(e)
											}
										/>
										<label htmlFor="inlineRadioForTypeOrganization">
											Organization
										</label>
									</div>
									<div className="icheck-primary">
										<input
											type="radio"
											name="inlineRadioForTypeApplication"
											id="inlineRadioForTypeApplication"
											value="a"
											checked={
												this.state.tokenType === 'a'
											}
											onChange={(e) =>
												this.handleRadioOptionType(e)
											}
										/>
										<label htmlFor="inlineRadioForTypeApplication">
											Application
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4 pt-1 mb-2">
								<b>Token Lifetime</b>
								<OverlayTrigger
									placement="right"
									overlay={
										<Tooltip>
											You can set tokens to expire
											anywhere between 1 and 24 months.
											You can delete unused or compromised
											tokens as needed from the main API
											Tokens screen.
										</Tooltip>
									}>
									<BiInfoCircle
										style={{
											color: '#03A9F4',
											marginLeft: '5px',
											fontSize: '14px'
										}}
									/>
								</OverlayTrigger>
							</div>
							<div className="col-sm-8 mb-3">
								<div className="row">
									<div className="col-4">
										<select
											name="lifetime"
											onChange={(e) =>
												this.handleLifetime(e)
											}
											className={
												this.state.formErrors.lifetime
													? 'form-control form-select input-error'
													: 'form-control form-select'
											}>
											{this.state.lifetimeArray.map(
												(i) => (
													<option key={i}>{i}</option>
												)
											)}
										</select>
									</div>
									<div className="col-8 small pt-2">
										Month(s)
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4 pt-1 mb-2">
								<b>Allowed IP Addresses</b>
								<OverlayTrigger
									placement="top"
									overlay={
										<Tooltip>
											This security feature lets you
											create an allowlist of IP addresses
											that can make requests with this
											token. If you leave it blank, all IP
											addresses are allowed.
										</Tooltip>
									}>
									<BiInfoCircle
										style={{
											color: '#03A9F4',
											marginLeft: '5px',
											fontSize: '14px'
										}}
									/>
								</OverlayTrigger>
							</div>
							<div className="col-sm-8">
								{this.state.ipRestrictions.map((record, i) => (
									<div
										key={i}
										className="row mb-3">
										<div className="col-10">
											<input
												type="text"
												onChange={(e) =>
													this.handleTextChangeMask(
														i,
														e.target.value
													)
												}
												value={
													this.state.ipRestrictions[i]
												}
												placeholder="Add an allowed IP Address"
												className={
													this.state.formErrors
														.ipRestrictions &&
													this.state.formErrors
														.ipRestrictions[i]
														? 'form-control input-error'
														: 'form-control'
												}
											/>
										</div>
										<div className="col-2 pt-1">
											{this.state.ipRestrictions.length -
												1 ===
											i ? (
												<BiPlusCircle
													className="icon-trash-modal"
													onClick={() =>
														this.addIp(i)
													}
												/>
											) : (
												<BiTrash
													className="icon-trash-modal"
													onClick={() =>
														this.removeIp(i)
													}
												/>
											)}
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-sm-4">
								<b>Creator Token?</b>
								<OverlayTrigger
									placement="top"
									overlay={
										<Tooltip>
											Choose <b>Yes</b> if you plan on
											using this token with Creator,
											Payabli's no-code embedded component
											builder. With Creator, you can
											quickly customize and embed UI
											reports, pay in and pay out checkout
											experiences, and more.
										</Tooltip>
									}>
									<BiInfoCircle
										style={{
											color: '#03A9F4',
											marginLeft: '5px',
											fontSize: '14px'
										}}
									/>
								</OverlayTrigger>
							</div>
							<div className="col-sm-8">
								<div
									className="form-group mt-1"
									style={{ display: 'inline-flex' }}>
									<div className="icheck-primary mr-3">
										<input
											type="radio"
											name="inlineRadioForCreatorTokenYes"
											id="inlineRadioForCreatorTokenYes"
											value="1"
											checked={this.state.forCreatorToken}
											onChange={() =>
												this.handleRadioOption()
											}
											disabled={
												this.state.tokenType === 'a'
											}
										/>
										<label htmlFor="inlineRadioForCreatorTokenYes">
											Yes
										</label>
									</div>
									<div className="icheck-primary">
										<input
											type="radio"
											name="inlineRadioForCreatorTokenNo"
											id="inlineRadioForCreatorTokenNo"
											value="0"
											checked={
												!this.state.forCreatorToken
											}
											onChange={() =>
												this.handleRadioOption()
											}
										/>
										<label htmlFor="inlineRadioForCreatorTokenNo">
											No
										</label>
									</div>
								</div>
							</div>
						</div>
						{this.state.forCreatorToken && (
							<div className="row">
								<div className="col-sm-4 mb-2">
									<b>Domain restrictions</b>
									<br />
									(Required)
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip>
												For security, you must add the
												domain your Creator components
												will be used on. For example, if
												you're embedding a report on
												https://app.myproduct.com, add{' '}
												<code>
													https://app.myproduct
												</code>{' '}
												here.
											</Tooltip>
										}>
										<BiInfoCircle
											style={{
												color: '#03A9F4',
												marginLeft: '5px',
												fontSize: '14px'
											}}
										/>
									</OverlayTrigger>
								</div>
								<div className="col-sm-8">
									{this.state.domainRestrictions.map(
										(record, i) => (
											<div
												key={i}
												className="row mb-3">
												<div className="col-10">
													<input
														id="domainRestrictions"
														type="text"
														onChange={(e) =>
															this.handleTextChangeRestrictions(
																i,
																e
															)
														}
														value={
															this.state
																.domainRestrictions[
																i
															]
														}
														placeholder="Add an allowed URL"
														className={
															this.state
																.formErrors
																.domainRestrictions &&
															this.state
																.formErrors
																.domainRestrictions[
																i
															]
																? 'form-control input-error'
																: 'form-control'
														}
													/>
												</div>
												<div className="col-2 pt-1">
													{this.state
														.domainRestrictions
														.length -
														1 ===
													i ? (
														<BiPlusCircle
															className="icon-trash-modal"
															onClick={() =>
																this.addRestrictions(
																	i,
																	'domainRestrictions'
																)
															}
														/>
													) : (
														<BiTrash
															className="icon-trash-modal"
															onClick={() =>
																this.removeRestrictions(
																	i,
																	'domainRestrictions'
																)
															}
														/>
													)}
												</div>
											</div>
										)
									)}
								</div>
							</div>
						)}
						<div className="row">
							<div className="col-sm-4 pt-1 mb-2">
								<b>Description</b>
							</div>
							<div className="col-sm-8 mb-3">
								<input
									name="description"
									placeholder="Description"
									onChange={(e) => this.handleTextChange(e)}
									maxLength="250"
									className={
										this.state.formErrors.description
											? 'form-control input-error'
											: 'form-control'
									}
								/>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-sm-12 col-md-6">
								<button
									onClick={() => this.newModalStatus(false)}
									className="btn full-w btn-light"
									type="button">
									Cancel
								</button>
							</div>
							<div className="col-sm-12 col-md-6">
								<button
									onClick={() => this.createTokenAction()}
									value={this.state.description}
									className="btn full-w btn-primary"
									type="button">
									Create API Token
								</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<RightPanel>
					<h5 className="header mb-3">API Token Details</h5>
					<div className="small">
						<div className="row mb-3">
							<div className="col-5">
								<b>Description</b>
							</div>
							<div className="col-7">
								{recordDetails && recordDetails.description
									? recordDetails.description
									: '-'}
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-5">
								<b>Type </b>
							</div>
							<div className="col-7">
								{recordDetails && recordDetails.type
									? this.getRecordType(recordDetails, true)
									: '-'}
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-5">
								<b>Token </b>
							</div>
							<div
								className="col-7"
								style={{ wordWrap: 'break-word' }}>
								{recordDetails && recordDetails.ApiToken
									? recordDetails.ApiToken
									: '-'}
								<br />
								<br />
								<span
									onClick={() =>
										this.copyApiToken(
											recordDetails &&
												recordDetails.ApiToken
												? recordDetails.ApiToken
												: null
										)
									}
									className="blue-text"
									style={{
										float: 'right',
										cursor: 'pointer'
									}}>
									Copy Token
								</span>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-5">
								<b>Created Date</b>
							</div>
							<div className="col-7">
								{recordDetails && recordDetails.createdAt
									? this.props.global.stringDateFormatV3(
											recordDetails.createdAt
									  )
									: '-'}
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-5">
								<b>Lifetime | Expiration Date</b>
							</div>
							<div className="col-7">
								{recordDetails && recordDetails.expiringOn
									? this.getExpirationDate(
											recordDetails.expiringOn
									  )
									: '-'}
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-5">
								<b>Allowed IP Addresses</b>
							</div>
							<div className="col-7">
								{' '}
								{recordDetails && recordDetails.limitedToIp
									? this.getLimitedToIpAsColumn(recordDetails)
									: '-'}{' '}
							</div>
						</div>
					</div>
				</RightPanel>
				<div>
					<TopBar>
						<MainTopBarMenu />
						<div className="top-bar-sub">
							<DevelopersLinks selected="apikeys" />
						</div>
					</TopBar>
					<div className="mt-body4">
						<div className="row mb-4 datatable-actions">
							<div className="col-6 col-md-6 col-lg-3 head-filter">
								<h6 data-qaid="apiTokenDevelopersPage">
									API Tokens
								</h6>
								<div className="small-small inline-block">
									Manage your API Tokens
								</div>
							</div>
							<div className="col-1 col-md-1 col-lg-5 a-tabs-style text-center">
								<div className="hide-md">
									{this.getFilterButtons()}
								</div>
							</div>
							<div className="col-5 col-md-5 col-lg-4 text-right">
								<div className="d-none show-md">
									<DropdownButton
										menuAlign="right"
										title={
											<div>
												<BiListUl /> Actions
											</div>
										}
										size="sm"
										variant="default">
										<Dropdown.Item
											disabled={true}
											onClick={(e) => function () {}}>
											Show Filters
										</Dropdown.Item>
										<Dropdown.Item
											onClick={(e) =>
												this.newModalStatus(true, true)
											}>
											Create API Token
										</Dropdown.Item>
									</DropdownButton>
								</div>
								<div className="hide-md">
									<button
										disabled={true}
										className="btn btn-default bordered"
										type="button"
										onClick={(e) => (e) => function () {}}>
										<BiSlider />
										Show Filters
									</button>
									<button
										onClick={(e) =>
											this.newModalStatus(true)
										}
										className="btn btn-default btn-bordered-success ml-2">
										<BiKey style={{ fontSize: '19px' }} />{' '}
										Create API Token
									</button>
								</div>
							</div>
						</div>
						<div className="hide show-md a-tabs-style text-center mb-3 small-screen">
							{this.getFilterButtons()}
						</div>
						<div className="report-container">
							<table className="table table-hover table-striped">
								<thead>
									<tr>
										<th>Description</th>
										<th className="text-center">Mode</th>
										<th className="text-center">Type</th>
										<th>Token</th>
										<th>Created Date</th>
										<th>Lifetime | Expiration Date</th>
										<th>Allowed IP Addresses</th>
										<th className="text-center sticky-row">Actions</th>
									</tr>
								</thead>
								<tbody>
									{this.props.developers.records
										?.slice()
										?.reverse()
										?.map((record, i) => (
											<React.Fragment key={i}>
												{this.showRecord(record) && (
													<tr
														className="cursorPointer"
														onClick={(e) =>
															this.viewRecordDetails(
																record,
																e
															)
														}
														onMouseUp={(e) =>
															this.props.global.setCoordClickUp(
																e
															)
														}
														onMouseDown={(e) =>
															this.props.global.setCoordClickDown(
																e
															)
														}>
														<td>
															{record.description
																? record.description
																: '-'}
														</td>
														<td className="text-center">
															{record.type
																? this.getRecordType(
																		record
																  )
																: '-'}
														</td>
														<td className="text-center">
															{record.type
																? record.type ===
																  'o'
																	? 'Organization'
																	: 'Application'
																: '-'}
														</td>
														<td
															style={{
																maxWidth: 'none'
															}}>
															{record.ApiToken ? (
																<div
																	style={{
																		width: '312px'
																	}}>
																	{record.ApiToken.substring(
																		0,
																		15
																	) +
																		'..........' +
																		record.ApiToken.substring(
																			record
																				.ApiToken
																				.length -
																				15,
																			record
																				.ApiToken
																				.length
																		)}
																	<OverlayTrigger
																		placement="top"
																		overlay={
																			<Tooltip>
																				Copy
																				Token
																			</Tooltip>
																		}>
																		<BiCopy
																			className="copy-icon"
																			onClick={() =>
																				this.copyApiToken(
																					record.ApiToken
																				)
																			}
																		/>
																	</OverlayTrigger>
																</div>
															) : (
																'-'
															)}
														</td>
														<td>
															{record.createdAt
																? this.props.global.stringDateFormatV3(
																		record.createdAt
																  )
																: '-'}
														</td>
														<td>
															{record.expiringOn
																? this.getExpirationDate(
																		record.expiringOn
																  )
																: '-'}
														</td>
														<td>
															{record.limitedToIp ? (
																<div
																	style={{
																		width: '268px'
																	}}>
																	{record.limitedToIp
																		? this.getLimitedToIpAsString(
																				record
																		  ).substring(
																				0,
																				33
																		  )
																		: '-'}

																	{this.getLimitedToIpAsString(
																		record
																	).length >
																		32 && (
																		<>
																			...
																			&nbsp;
																			<OverlayTrigger
																				placement="top"
																				overlay={
																					<Tooltip>
																						{this.getLimitedToIpAsColumn(
																							record
																						)}
																					</Tooltip>
																				}>
																				<span className="blue-text text-underline">
																					see
																					all
																				</span>
																			</OverlayTrigger>
																		</>
																	)}
																</div>
															) : (
																'-'
															)}
														</td>
														<td className="text-center sticky-row">
															<DropdownButton
																menuAlign="right"
																title={
																	<BiDotsVerticalRounded />
																}
																id="actionsMenuButton"
																size="sm"
																variant="default">
																<Dropdown.Item
																	onClick={(
																		e
																	) =>
																		this.viewRecordDetails(
																			record
																		)
																	}>
																	Quick View
																</Dropdown.Item>
																<Dropdown.Item
																	rel="noreferrer"
																	onClick={() =>
																		this.copyApiToken(
																			record.ApiToken
																		)
																	}>
																	Copy API
																	Token
																</Dropdown.Item>
																<Dropdown.Divider />
																<Dropdown.Item
																	rel="noreferrer"
																	style={{
																		color: '#c00000'
																	}}
																	onClick={() =>
																		this.deleteToken(
																			record.ApiToken
																		)
																	}>
																	Delete Token
																</Dropdown.Item>
															</DropdownButton>
														</td>
													</tr>
												)}
											</React.Fragment>
										))}
								</tbody>
							</table>
							{this.props.developers.records.length < 1 && (
								<RecordsNotFound
									message="No API Tokens yet."
									description={
										<span>
											API Tokens that are created will
											display here.
										</span>
									}
								/>
							)}
						</div>
						<ReportPaginatorApiKeys />
					</div>
					<ToastContainer transition={Bounce} />
				</div>
			</Layout>
		)
	}
}

export { ApiKeys }
