import React from 'react'
import { inject, observer } from 'mobx-react'
import { TopBar } from '../../components/TopBar'
import { MainTopBarMenu } from '../../components/MainTopBarMenu'
import { ReportPaginator } from '../../components/ReportPaginator'
import { Modal, Button } from 'react-bootstrap'
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel'
import 'react-datepicker/dist/react-datepicker.css'
import { ToastContainer, toast, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { BiNotepad } from '@react-icons/all-files/bi/BiNotepad'
import { HiCheckCircle } from '@react-icons/all-files/hi/HiCheckCircle'
import { HiQuestionMarkCircle } from '@react-icons/all-files/hi/HiQuestionMarkCircle'
import { RecordsNotFound } from '../../components/RecordsNotFound'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiCheck, BiDollar, BiMoney, BiPaperPlane, BiTimeFive, BiDotsVerticalRounded, BiTrash, BiPlus } from 'react-icons/bi'
import { NameAvatar } from '../../components/NameAvatar'
import { EditVendor } from '../../components/EditVendor'
import { ModalComingSoon } from '../../components/ModalComingSoon'
import { MoneyOutLinks } from '../../components/MoneyOutLinks'
import { RightPanel } from '../../components/RightPanel'
import { VendorDataChartColumn } from '../../components/VendorDataChartColumn'
import { ModalImport } from '../Common/ModalImport'
import { MainBar } from '../../components/MainBar'
import { BiSortAlt2 } from 'react-icons/bi'

@inject('reports', 'global', 'vendor')
@observer
class VendorsReport extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			head: '',
			vendorIdToDelete: null,
			deleteModalIsOpen: false,
			commingSoonModal: false,
			changeVendorStatusModalIsOpen: false,
			statusVendorToEdit: 0,
			nameVendorToEdit: null,
			idVendorToEdit: null,
			modalImportIsOpen: false,
			flagItemAllColumn: true,
			sortDirection: 'desc'
		}
		this.getReportFromApi = this.getReportFromApi.bind(this)
		this.clearFilters = this.clearFilters.bind(this)
		this.viewRecordDetails = this.viewRecordDetails.bind(this)
		this.deleteVendor = this.deleteVendor.bind(this)
		this.closeDeleteModal = this.closeDeleteModal.bind(this)
		this.openDeleteModal = this.openDeleteModal.bind(this)
		this.deleteVendorAction = this.deleteVendorAction.bind(this)
		this.showHideFilters = this.showHideFilters.bind(this)
		this.exportFile = this.exportFile.bind(this)
		this.filter = this.filter.bind(this)
		this.selectAllColumns = this.selectAllColumns.bind(this)
		this.handleShowColumn = this.handleShowColumn.bind(this)
		this.openCommingSoonModal = this.openCommingSoonModal.bind(this)
		this.closeCommingSoonModal = this.closeCommingSoonModal.bind(this)
		this.changeVendorStatus = this.changeVendorStatus.bind(this)
		this.closeChangeVendorStatusModal = this.closeChangeVendorStatusModal.bind(this)
		this.openChangeVendorStatusModal = this.openChangeVendorStatusModal.bind(this)
		this.selectChangeVendorStatus = this.selectChangeVendorStatus.bind(this)
		this.saveVendorStatus = this.saveVendorStatus.bind(this)
		this.toggleModalImport = this.toggleModalImport.bind(this)
		this.handleImportAction = this.handleImportAction.bind(this)
		this.handlerImportClick = this.handlerImportClick.bind(this)
		this.buttonAddComponent = this.buttonAddComponent.bind(this)
		this.sortBy = this.sortBy.bind(this)
	}
	buttonAddComponent(withoutStyle = false) {
		return (
			<EditVendor
				isEmbedded={true}
				viewAction={'new'}
				functionCallback={this.getReportFromApi}
				command={this.props.match.params.command ? this.props.match.params.command : null}
				className={!withoutStyle ? 'btn btn-default btn-bordered-success ml-2' : 'button-add-a dropdown-item'}
				style={withoutStyle ? { marginLeft: '1rem !important' } : {}}
				title={'Add a vendor'}
				label={
					withoutStyle ? (
						'Add Vendor'
					) : (
						<>
							<BiPlus />
							<span className="main-span-button">Add a vendor</span>
						</>
					)
				}
				action={'new'}
			/>
		)
	}
	toggleModalImport() {
		this.setState({ modalImportIsOpen: false })
	}
	handleImportAction() {}
	toggleModalMarkPaid(recordIdSelected) {
		this.setState({
			recordIdSelected: recordIdSelected,
			markPaidModalIsOpen: !this.state.markPaidModalIsOpen
		})
	}
	handlerImportClick(ev) {
		this.setState({ modalImportIsOpen: true })
	}

	componentDidMount() {
		this.props.global.protect(this.props.history)
		this.props.reports.setFrom(0)
		this.props.global.setLoading(true)
		this.props.reports.setHeaders({
			Vendor: { label: 'Vendor or recipient name', class: '', display: true },
			ExternalPaypointID: { label: 'External Paypoint ID', class: '', display: false },
			CustomerVendorAccount: { label: 'Account #', class: '', display: true },
			VendorNumber: { label: 'Vendor #', class: '', display: false },
			Ein: { label: 'Tax ID', class: '', display: false },
			Email: { label: 'Email', class: '', display: false },
			Phone: { label: 'Phone', class: '', display: false },
			Address: { label: 'Billing Address', class: '', display: false },
			RemitAddress: { label: 'Remit Address', class: '', display: false },
			ActiveBillsAutoPay: { label: 'Active Bills', class: 'text-right', display: true },
			BillsTransit: { label: 'Payment in Transit', class: 'text-right', display: true },
			BillsPaid: { label: 'Bills Paid', class: 'text-right', display: true },
			BillsPastDue: { label: 'Bills Past Due', class: 'text-right', display: true },
			TotalBills: { label: 'Total Bills', class: 'text-right', display: true },
			Status: { label: 'Status', class: '', display: false },
			DateCreated: { label: 'Date Created ', class: '', display: true },
			CustomField1: { label: 'Custom Field 1', class: '', display: false },
			CustomField2: { label: 'Custom Field 2', class: '', display: false }
		})
		this.props.reports.setRecords([])
		this.clearFilters()
	}

	selectAllColumns(e, containerId) {
		let menuContainer = document.querySelector('[aria-labelledby="' + containerId + '"]')
		let checkBoxes = menuContainer.getElementsByTagName('input')
		for (var i = 0; i < checkBoxes.length; i++) {
			if (checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
				checkBoxes[i].click()
			}
		}
		this.setState({ flagItemAllColumn: false })
	}

	filter(type, value) {
		this.props.global.setLoading(true)
		this.props.reports
			.filter(type, value, 'vendors')
			.then((res) => {
				this.props.global.setLoading(false)
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}

	viewRecordDetails(index, object, id) {
		if (object && (this.props.global.isDrag() || object.target.rel === 'noreferrer' || object.target.tagName.toLowerCase() === 'svg' || object.target.tagName.toLowerCase() === 'a' || object.target.tagName.toLowerCase() === 'path' || object.target.className === 'dropdown-item editVendor')) {
			return
		}
		let editVendorModal = document.getElementById('editVendorModal')
		if (editVendorModal) {
			if (editVendorModal.contains(object.target) || object.target.className === 'fade modal show') {
				return
			}
		}
		if (object?.target?.id !== 'actionsMenuButton') {
			let thisObj = this
			if (thisObj.props.global.rightPanelOpen && this.props.vendor.vendor !== null && this.props.vendor.vendor.VendorId === id) {
				this.props.global.setRightPanelOpen(false)
				return
			}
			this.props.global.setRightPanelOpen(false)
			this.props.global.setLoading(true)
			this.props.reports.setRecordDetails(index)
			if (id) {
				this.props.vendor
					.getStatistics(id)
					.then((res) => {
						thisObj.props.global.setLoading(false)
						setTimeout(function () {
							thisObj.props.global.setRightPanelOpen(true)
						}, 500)
					})
					.catch((error) => {
						thisObj.props.global.setLoading(false)
					})
			}
		}
	}

	showHideFilters() {
		this.props.global.setFilterRightPanelOpen(true)
	}

	deleteVendor(vendorIdToDelete) {
		this.setState({ vendorIdToDelete: vendorIdToDelete })
		this.openDeleteModal()
	}

	openDeleteModal() {
		this.setState({ deleteModalIsOpen: true })
	}

	closeDeleteModal() {
		this.setState({ deleteModalIsOpen: false })
	}

	selectChangeVendorStatus(e) {
		if (e) {
			this.setState({
				statusVendorToEdit: e.target.value
			})
		}
	}

	saveVendorStatus() {
		let vendorIdToSave = this.state.idVendorToEdit
		let vendorStatusToSave = this.state.statusVendorToEdit

		if (vendorIdToSave) {
			this.props.global.setLoading(true)
			this.props.vendor
				.updateVendorStatus(vendorIdToSave, vendorStatusToSave)
				.then((result) => {
					this.closeChangeVendorStatusModal()
					this.getReportFromApi()
					toast.success('Data saved successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 7000,
						className: 'toast-success-container data-qaid-dataSavedSuccessfull'
					})
				})
				.catch((error) => {
					this.props.global.setLoading(false)
					let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				})
		}
	}

	changeVendorStatus(vendor) {
		this.setState({
			nameVendorToEdit: vendor ? vendor.Name1 + ' ' + (vendor.Name2 ? vendor.Name2 : '') : null,
			statusVendorToEdit: vendor ? vendor.VendorStatus : 0,
			idVendorToEdit: vendor ? vendor.VendorId : null
		})
		this.openChangeVendorStatusModal()
	}

	openChangeVendorStatusModal() {
		this.setState({ changeVendorStatusModalIsOpen: true })
	}

	closeChangeVendorStatusModal() {
		this.setState({ changeVendorStatusModalIsOpen: false })
	}

	deleteVendorAction() {
		let vendorIdToDelete = this.state.vendorIdToDelete

		if (vendorIdToDelete) {
			this.props.global.setLoading(true)
			this.props.reports
				.deleteVendor(vendorIdToDelete)
				.then((result) => {
					this.setState({ deleteModalIsOpen: false })
					this.props.global.setLoading(false)
					toast.success('Data deleted successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
				})
				.catch((error) => {
					this.props.global.setLoading(false)
					let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				})
		}
	}

	handleShowColumn(event) {
		this.props.reports.handleShowColumn(event)
		if (!this.state.flagItemAllColumn) {
			this.setState({ flagItemAllColumn: true })
		}
	}

	clearFilters() {
		this.setState({ head: '' }, function () {
			this.props.reports.clearFilters()
			this.getReportFromApi()
		})
	}

	exportFile(format) {
		this.props.reports.exportFile('vendors', format, this.state.orgId)
	}

	getMappingFields() {
		return {
			Vendor: 'vendor',
			ParentOrgName: 'orgname',
			PaypointLegalname: 'paypointlegal',
			ExternalPaypointID: 'externalpaypointid',
			CustomerVendorAccount: 'vendoraccountnumber',
			VendorNumber: 'vendornumber',
			Ein: 'ein',
			Email: 'email',
			Phone: 'phone',
			Address: 'address',
			RemitAddress: 'remitaddress',
			Status: 'status',
			DateCreated: 'createdat'
		}
	}
	middlwareFields(field) {
		const apiFields = this.getMappingFields()
		return apiFields[field]
	}
	sortBy(e, record) {
		this.props.global.setLoading(true)
		this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
			this.getReportFromApi()
		})
	}
	getReportFromApi() {
		let tempFilterValue = {}
		if (this.state.sortBy) {
			tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`
		}
		this.filter(tempFilterValue)
	}

	openCommingSoonModal() {
		this.setState({ commingSoonModal: true })
	}

	closeCommingSoonModal() {
		this.setState({ commingSoonModal: false })
	}

	render() {
		const recordDetail = this.props.reports.getRecordDetails

		return (
			<Layout {...this.props}>
				<div>
					<ModalComingSoon
						isOpen={this.state.commingSoonModal}
						closeAction={this.closeCommingSoonModal}
					/>
					<Modal
						style={{ textAlign: 'center' }}
						show={this.state.deleteModalIsOpen}
						onHide={this.closeDeleteModal}
						size="sm"
						aria-labelledby="contained-modal-title-vcenter"
						centered>
						<Modal.Body>
							<BiTrash className="icon-modal" />
							<h5 data-qaid="deleteVendorModal">Delete</h5>
							<p className="small">Are you sure you want to delete this Vendor?</p>
							<Button
								className="btn cancel-btn"
								variant="default"
								onClick={(e) => this.closeDeleteModal()}
								data-qaid="cancelDeleteModalButton">
								Cancel
							</Button>
							&nbsp;&nbsp;
							<Button
								className="btn"
								variant="danger"
								onClick={this.deleteVendorAction}>
								Delete
							</Button>
						</Modal.Body>
					</Modal>
					<ModalImport
						isOpen={this.state.modalImportIsOpen}
						closeModal={this.toggleModalImport}
						action={this.handleImportAction}
						modalType="Vendor"
						textHeader="Import Vendor Data"
						initialState={{ uploadedFiles: [], correctFiles: [] }}
					/>
					<RightPanel>
						<h5
							className="header mb-3"
							data-qaid="vendorDetailsMoneyOut">
							Vendor Details
						</h5>
						<div className="row mb-3 small mt-5 color-circles">
							<div className="col-sm-4 mb-4">
								<div className="dashboard-color-circle orange">
									<BiCheck />
								</div>
								<label>Active Volume</label>
								<br />
								<b>${recordDetail && recordDetail.Summary ? this.props.global.numberWithCommas(recordDetail.Summary.PendingBillsAmount.toFixed(2)) : '0.00'}</b>
							</div>

							<div className="col-sm-4 mb-4">
								<div className="dashboard-color-circle blue">
									<BiPaperPlane />
								</div>
								<label>In Transit Volume</label>
								<br />
								<b>${recordDetail && recordDetail.Summary ? this.props.global.numberWithCommas(recordDetail.Summary.InTransitBillsAmount.toFixed(2)) : '0.00'}</b>
							</div>
							<div className="col-sm-4 mb-4">
								<div className="dashboard-color-circle purple">
									<BiMoney />
								</div>
								<label>Paid Volume</label>
								<br />
								<b>${recordDetail && recordDetail.Summary ? this.props.global.numberWithCommas(recordDetail.Summary.PaidBillsAmount.toFixed(2)) : '0.00'}</b>
							</div>

							<div className="col-sm-4 mb-4">
								<div className="dashboard-color-circle red">
									<BiTimeFive />
								</div>
								<label>Past Due Volume</label>
								<br />
								<b>${recordDetail && recordDetail.Summary ? this.props.global.numberWithCommas(recordDetail.Summary.OverdueBillsAmount.toFixed(2)) : '0.00'}</b>
							</div>

							<div className="col-sm-4 mb-4">
								<div
									className="dashboard-color-circle green"
									style={{ paddingTop: '2px' }}>
									<BiDollar />
								</div>
								<label>Total Volume</label>
								<br />
								<b>${recordDetail && recordDetail.Summary ? this.props.global.numberWithCommas(recordDetail.Summary.TotalBillsAmount.toFixed(2)) : '0.00'}</b>
							</div>
							<div className="col-sm-4 mb-4">
								{recordDetail && recordDetail.VendorStatus != null && recordDetail.VendorStatus === 1 ? (
									<div className="dashboard-color-circle greenoutline">
										<HiCheckCircle />
									</div>
								) : (
									<div className="dashboard-color-circle yellowoutline">
										<HiQuestionMarkCircle />
									</div>
								)}

								<label>Vendor Status</label>
								<br />
								<b>{recordDetail && recordDetail.VendorStatus != null ? this.props.reports.getVendorStatus(recordDetail.VendorStatus, true) : '-'}</b>
							</div>
						</div>
						<VendorDataChartColumn
							isEmbedded={true}
							callBackFunction={this.getReportFromApi}
							routeParams={this.props.match.params}
							vendorObj={recordDetail}
						/>
					</RightPanel>

					<Modal
						style={{ textAlign: 'left' }}
						show={this.state.changeVendorStatusModalIsOpen}
						onHide={this.closeChangeVendorStatusModal}
						size="md"
						aria-labelledby="contained-modal-title-vcenter"
						centered>
						<Modal.Body
							className="popover-body"
							style={{ minWidth: 'auto' }}>
							<h6
								className="mb-3"
								data-qaid="changeStatusModalVendorMoneyOut">
								Change Status
							</h6>
							<p className="small">
								Change the status of vendor <b>{this.state.nameVendorToEdit}</b>
							</p>

							<div className="form-floating mb-4">
								<select
									onChange={(e) => this.selectChangeVendorStatus(e)}
									value={this.state.statusVendorToEdit}
									className="form-select form-control"
									id="vendorStatus"
									aria-label="vendorStatus">
									<option value="1">Active</option>
									<option value="0">Inactive</option>
								</select>
								<label htmlFor="vendorStatus">Status</label>
							</div>

							<div className="row">
								<div className="col-sm-12 col-md-6">
									<Button
										className="btn full-w btn-light"
										variant="default"
										onClick={(e) => this.closeChangeVendorStatusModal()}>
										Cancel
									</Button>
								</div>
								<div className="col-sm-12 col-md-6">
									<Button
										className="btn full-w btn-primary"
										onClick={this.saveVendorStatus}
										data-qaid="saveChangeStatusModalButton">
										Save
									</Button>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<ParentFilterPanel report={'vendors'} />
					<TopBar>
						<MainTopBarMenu />
						<div className="top-bar-sub">
							<MoneyOutLinks
								gobackText="Go back"
								history={this.props.history}
								goback={false}
								selected="vendors"
							/>
						</div>
					</TopBar>
					<div className="mt-body4">
						<MainBar
							reportTitle={this.state.head ? this.props.reports.getVendorStatus(this.state.head, true) : 'All Vendors & Recipients'}
							reportName="vendorsRecipients"
							dataQAName="VendorsMoneyOut"
							showHideFilters={this.showHideFilters}
							selectAllColumns={(e) => this.selectAllColumns(e, 'columnReport')}
							handleShowColumn={(e) => this.handleShowColumn(e)}
							totalRecords={this.props.reports.totalRecords}
							getHeaders={this.props.reports.getHeaders}
							headers={this.props.reports.headers}
							refreshView={this.clearFilters}
							buttonExport={true}
							exportFile={this.exportFile}
							searchBar={false}
							buttonImport={true}
							skipTitle={true}
							importFile={this.handlerImportClick}
							buttonAddComponent={this.buttonAddComponent}
							flagItemAllColumn={this.state.flagItemAllColumn}
						/>
						<div className="report-container">
							<table className="table table-hover table-striped">
								<thead>
									<tr>
										{this.props.reports.getHeaders.map(
											(record, i) =>
												(Object.entries(this.getMappingFields()).flat(1).includes(record[0]) && record[1].display && (
													<th
														key={i}
														scope="col"
														className={`${record[1].class} sortby-parent`}>
														{record[1].label}
														<BiSortAlt2
															className="cursorPointer sort-icon"
															onClick={(e) => this.sortBy(e, record)}
														/>
													</th>
												)) ||
												(record[1].display && (
													<th
														key={i}
														scope="col"
														className={record[1].class}>
														{record[1].label}
													</th>
												))
										)}
										<th
											className="text-center sticky-row"
											scope="col">
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									{this.props.reports.records.map((record, i) => (
										<tr
											onMouseUp={(e) => this.props.global.setCoordClickUp(e)}
											onMouseDown={(e) => this.props.global.setCoordClickDown(e)}
											key={i}
											className="cursorPointer"
											onClick={(e) => this.viewRecordDetails(i, e, record.VendorId)}
											data-qaid={'vendorMoneyOutRow-' + i}>
											{/*<td>
                                <div className="icheck-primary">
                                    <input
                                        type="checkbox"
                                        id={"checkRow"+i}
                                    />
                                    <label htmlFor={"checkRow"+i}></label>
                                </div>
                            </td>*/}
											{this.props.reports.headers.Vendor && this.props.reports.headers.Vendor.display && (
												<td className="avatarTd">
													{' '}
													<NameAvatar text={record && record.Name1 ? record.Name1 + ' ' + record.Name2 : '-'} /> {(record && record.Name1 ? this.props.global.capitalizeFirstLetterOfEachWord(record.Name1) : '-') + ' ' + (record && record.Name2 ? this.props.global.capitalizeFirstLetterOfEachWord(record.Name2) : '')}
												</td>
											)}
											{this.props.reports.headers.ExternalPaypointID && this.props.reports.headers.ExternalPaypointID.display && <td>{record && record.externalPaypointID ? record.externalPaypointID : '-'}</td>}
											{this.props.reports.headers.CustomerVendorAccount && this.props.reports.headers.CustomerVendorAccount.display && <td>{record && record.customerVendorAccount ? record.customerVendorAccount : '-'}</td>}
											{this.props.reports.headers.VendorNumber && this.props.reports.headers.VendorNumber.display && <td>{record && record.VendorNumber !== null ? record.VendorNumber : '-'}</td>}
											{this.props.reports.headers.Ein && this.props.reports.headers.Ein.display && <td>{record && record.EIN ? record.EIN : '-'}</td>}
											{this.props.reports.headers.Email && this.props.reports.headers.Email.display && <td>{record && record.Email !== null ? record.Email : '-'}</td>}
											{this.props.reports.headers.Phone && this.props.reports.headers.Phone.display && <td>{record && record.Phone ? record.Phone : '-'}</td>}
											{this.props.reports.headers.Address && this.props.reports.headers.Address.display && <td>{record && (record.Address1 || record.Address2) ? (record.Address1 ? record.Address1 : '') + ' ' + (record.Address2 ? record.Address2 : '') : '-'}</td>}
											{this.props.reports.headers.RemitAddress && this.props.reports.headers.RemitAddress.display && <td>{record && (record.remitAddress1 || record.remitAddress2) ? (record.remitAddress1 ? record.remitAddress1 : '') + ' ' + (record.remitAddress2 ? record.remitAddress2 : '') : '-'}</td>}
											{this.props.reports.headers.ActiveBillsAutoPay && this.props.reports.headers.ActiveBillsAutoPay.display && (
												<td className="text-right">
													{record.Summary && record.Summary.PendingBills !== null ? record.Summary.PendingBills : '0'}
													<br />
													{record.Summary && record.Summary.PendingBillsAmount !== null ? this.props.global.numberWithCommasNegative(record.Summary.PendingBillsAmount.toFixed(2)) : '0.00'}
												</td>
											)}
											{this.props.reports.headers.BillsTransit && this.props.reports.headers.BillsTransit.display && (
												<td className="text-right">
													{record.Summary && record.Summary.InTransitBills !== null ? record.Summary.InTransitBills : '0'}
													<br />
													{record.Summary && record.Summary.InTransitBillsAmount !== null ? this.props.global.numberWithCommasNegative(record.Summary.InTransitBillsAmount.toFixed(2)) : '0.00'}
												</td>
											)}
											{this.props.reports.headers.BillsPaid && this.props.reports.headers.BillsPaid.display && (
												<td className="text-right">
													{record.Summary && record.Summary.PaidBills !== null ? record.Summary.PaidBills : '0'}
													<br />
													{record.Summary && record.Summary.PaidBillsAmount !== null ? this.props.global.numberWithCommasNegative(record.Summary.PaidBillsAmount.toFixed(2)) : '0.00'}
												</td>
											)}
											{this.props.reports.headers.BillsPastDue && this.props.reports.headers.BillsPastDue.display && (
												<td className="text-right">
													{record.Summary && record.Summary.OverdueBills !== null ? record.Summary.OverdueBills : '0'}
													<br />
													{record.Summary && record.Summary.OverdueBillsAmount !== null ? this.props.global.numberWithCommasNegative(record.Summary.OverdueBillsAmount.toFixed(2)) : '0.00'}
												</td>
											)}
											{this.props.reports.headers.TotalBills && this.props.reports.headers.TotalBills.display && (
												<td className="text-right">
													{record.Summary && record.Summary.TotalBills !== null ? record.Summary.TotalBills : '0'}
													<br />
													{record.Summary && record.Summary.TotalBillsAmount !== null ? this.props.global.numberWithCommasNegative(record.Summary.TotalBillsAmount.toFixed(2)) : '0.00'}
												</td>
											)}
											{this.props.reports.headers.Status && this.props.reports.headers.Status.display && <td>{this.props.reports.getVendorStatus(record.VendorStatus)}</td>}
											{this.props.reports.headers.DateCreated && this.props.reports.headers.DateCreated.display && <td>{record.CreatedDate ? this.props.global.stringDateFormatV3(record.CreatedDate) : '-'}</td>}
											{this.props.reports.headers.notes && this.props.reports.headers.notes.display && (
												<td className="text-center">
													{record.PaymentData && record.PaymentData.orderDescription ? (
														<OverlayTrigger
															placement="top"
															overlay={<Tooltip>{record.PaymentData.orderDescription}</Tooltip>}>
															<BiNotepad className="label-info-icon in-input" />
														</OverlayTrigger>
													) : (
														'-'
													)}
												</td>
											)}
											{this.props.reports.headers.CustomField1 && this.props.reports.headers.CustomField1.display && <td>{record.customField1 ? record.customField1 : '-'}</td>}
											{this.props.reports.headers.CustomField2 && this.props.reports.headers.CustomField2.display && <td>{record.customField2 ? record.customField2 : '-'}</td>}
											<td className="text-center sticky-row">
												<DropdownButton
													menuAlign="right"
													title={<BiDotsVerticalRounded />}
													data-qaid="actionVendorMoneyOut"
													id="actionsMenuButton"
													size="sm"
													variant="default">
													<Dropdown.Item
														as={Link}
														to={'/' + this.props.global.getURLEntry() + '/report/bills/vendor/' + record.VendorNumber}>
														Pay Bills
													</Dropdown.Item>
													<Dropdown.Item onClick={(e) => this.openCommingSoonModal()}>Schedule Bill Pay</Dropdown.Item>
													{record.VendorStatus === 1 && (
														<Dropdown.Item
															as={Link}
															to={'/' + this.props.global.getURLEntry() + '/bills/new/' + record.VendorNumber}
															data-qaid="addBillToPayVendorMoneyOutLink">
															Add Bill to Pay
														</Dropdown.Item>
													)}
													<Dropdown.Item onClick={(e) => this.openCommingSoonModal()}>Import Bills</Dropdown.Item>
													<Dropdown.Item
														as={Link}
														to={'/' + this.props.global.getURLEntry() + '/report/bills/' + record.VendorId}
														data-qaid="viewBillVendorMoneyOutLink">
														View Bills
													</Dropdown.Item>
													<Dropdown.Divider />
													<Dropdown.Item
														onClick={(e) => this.viewRecordDetails(i, e)}
														data-qaid="quickViewVendorMoneyOutLink">
														<div>Quick View</div>
													</Dropdown.Item>
													<Dropdown.Item
														as={Link}
														to={'/' + this.props.global.getURLEntry() + '/vendor/' + record.VendorId}
														data-qaid="viewVendorMoneyOutLink">
														View Vendor
													</Dropdown.Item>
													<Dropdown.Item as="div">
														<EditVendor
															isEmbedded={true}
															viewAction={'edit'}
															functionCallback={this.getReportFromApi}
															vendorToUpdate={record}
															action={'edit'}
														/>
													</Dropdown.Item>
													<Dropdown.Item
														onClick={(e) => this.changeVendorStatus(record)}
														data-qaid="changeStatusVendorMoneyOutLink">
														Change Status
													</Dropdown.Item>
													<Dropdown.Divider />
													<Dropdown.Item
														style={{ color: '#c00000' }}
														onClick={(e) => this.deleteVendor(record.VendorId)}
														data-qaid="deleteVendorMoneyOutLink">
														Delete Vendor
													</Dropdown.Item>
												</DropdownButton>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{this.props.reports.records.length < 1 && (
								<RecordsNotFound
									message="No vendors yet"
									description={<span>You can track vendors details here.</span>}
								/>
							)}
						</div>
						<ReportPaginator report="vendors" />
					</div>
					<ToastContainer transition={Bounce} />
				</div>
			</Layout>
		)
	}
}

export { VendorsReport }
