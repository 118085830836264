import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiX  } from "react-icons/bi";

@inject('reports','global')
@observer
class ReportFilters extends React.Component {

    constructor(props) {
        super(props);
        this.removeFilter = this.removeFilter.bind(this);
    }

    removeFilter(filter){
        this.props.reports.removeFilter(filter)
        this.props.global.setLoading(true);
        this.props.reports.getReportFromApi(this.props.report).then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });
    }

    render() {
        return (
            <div className="row mb-3">
                <div className="col filter-badges">
                    {
                        this.props.reports.getFilters.map((record, i) => (
                            <>
                            {(!this.props.skipt || (this.props.skipt && this.props.skipt !== record[0])) && 
                                <>
                                {
                                    (Array.isArray(this.props.skipt) && this.props.skipt.includes(record[0])) ?
                                    <></>
                                    :
                                    <span key={i} className="badge rounded-pill" onClick={(e) => this.removeFilter(record[0])}>{record[0]} <BiX/></span>

                                }
                                   
                                </>
                            }
                            </>
                        ))
                    }
                    <span className="badge rounded-pill" onClick={(e) => this.props.clearFilters()}>Clear All<BiX/></span>
                </div>
            </div>
        )
    }
}

export { ReportFilters };