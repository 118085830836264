import React from 'react'
import { inject, observer } from 'mobx-react'
import { TopBar } from '../../components/TopBar'
import { MainTopBarMenu } from '../../components/MainTopBarMenu'
import { ActionsLinks } from '../../components/ActionsLinks'
import { ReportPaginator } from '../../components/ReportPaginator'
import { RightPanel } from '../../components/RightPanel'
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel'
import { ReportFilters } from '../../components/ReportFilters'
import { CustomerDataChartColumn } from '../../components/CustomerDataChartColumn'
import { EditCustomer } from '../../components/EditCustomer'
import { Layout } from '../../components/Layout'
import { VirtualTerminal } from '../../components/VirtualTerminal'
import { PayabliStorageManager } from '../../api/localStorageManager'
import { Modal, Button } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { ToastContainer, toast, Bounce } from 'react-toastify'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { BiPlus, BiTrash, BiX } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { RecordsNotFound } from '../../components/RecordsNotFound'
import { NameAvatar } from '../../components/NameAvatar'
import { ModalImport } from '../Common/ModalImport'
import { ModalChangeStatus } from '../Common/ModalChangeStatus'
import { MainBar } from '../../components/MainBar'
import { BiSortAlt2, BiDotsVerticalRounded } from 'react-icons/bi'
import { InfoConsentCustomer } from '../../components/InfoConsentCustomer'

@inject('reports', 'global', 'vTerminal', 'customer')
@observer
class CustomerReport extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			head: '',
			sortDirection: 'desc',
			filtersEnabled: false,
			filterStatus: {},
			deleteModalIsOpen: false,
			customerIdToDelete: null,
			vterminalRightPanelOpen: false,
			vTerminalAutopay: 1,
			customFields: [],
			modalImportIsOpen: false,
			modalStatusIsOpen: false,
			flagItemAllColumn: true,
			activeRecord: null,
			activeRecordStatus: ''
		}
		this.getReportFromApi = this.getReportFromApi.bind(this)
		this.exportFile = this.exportFile.bind(this)
		this.clearFilters = this.clearFilters.bind(this)
		this.showHideFilters = this.showHideFilters.bind(this)
		this.handleQuickFilter = this.handleQuickFilter.bind(this)
		this.filter = this.filter.bind(this)
		this.handleShowColumn = this.handleShowColumn.bind(this)
		this.openDeleteModal = this.openDeleteModal.bind(this)
		this.closeDeleteModal = this.closeDeleteModal.bind(this)
		this.deleteCustomer = this.deleteCustomer.bind(this)
		this.deleteCustomerAction = this.deleteCustomerAction.bind(this)
		this.viewRecordDetails = this.viewRecordDetails.bind(this)
		this.closeVterminalPanel = this.closeVterminalPanel.bind(this)
		this.openVterminalPanel = this.openVterminalPanel.bind(this)
		this.getCustomFieldsFromApi = this.getCustomFieldsFromApi.bind(this)
		this.toggleModalImport = this.toggleModalImport.bind(this)
		this.handleImportAction = this.handleImportAction.bind(this)
		this.handlerImportClick = this.handlerImportClick.bind(this)
		this.selectAllColumns = this.selectAllColumns.bind(this)
		this.getNameReport = this.getNameReport.bind(this)
		this.buttonAddComponent = this.buttonAddComponent.bind(this)
		this.sortBy = this.sortBy.bind(this)
		this.toggleModalStatus = this.toggleModalStatus.bind(this)
		this.handlerChangeStatusClick = this.handlerChangeStatusClick.bind(this)
	}

	buttonAddComponent() {
		return (
			<EditCustomer
				isEmbedded={true}
				functionCallback={this.getReportFromApi}
				command={this.props.match.params.command ? this.props.match.params.command : null}
				className="btn btn-default btn-bordered-success ml-2"
				title={'Add a customer'}
				label={
					<>
						<BiPlus />
						<span className="main-span-button">Add a customer</span>
					</>
				}
				action={'new'}
			/>
		)
	}

	getNameReport() {
		return (
			<>
				<div className="mr-3 inline">
					<h6 data-qaid="allCustomersMoneyInReportsPage">{this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : 'All Customers'}</h6>
				</div>
				<div className="small-small inline-block">Total Customer Count: {this.props.reports.totalRecords}</div>
			</>
		)
	}

	toggleModalImport() {
		this.setState({ modalImportIsOpen: false })
	}
	toggleModalStatus() {
		this.setState({ modalStatusIsOpen: false })
	}
	handlerChangeStatusClick(ev, record) {
		this.setState({ modalStatusIsOpen: true, activeRecord: record, activeRecordStatus: this.props.reports.getCustomerTextStatus(this.state.record?.customerStatus) })
	}

	handleImportAction() {}

	toggleModalMarkPaid(recordIdSelected) {
		this.setState({
			recordIdSelected: recordIdSelected,
			markPaidModalIsOpen: !this.state.markPaidModalIsOpen
		})
	}

	handlerImportClick(ev) {
		this.setState({ modalImportIsOpen: true })
	}

	componentDidMount() {
		this.props.global.protect(this.props.history)
		this.props.reports.setFrom(0)
		this.props.global.setRightPanelOpen(false)
		this.props.global.setLoading(true)
		this.props.reports.setHeaders({
			customerId: { label: 'Customer ID', class: '', display: false },
			customer: { label: 'Customer', class: '', display: true },
			company: { label: 'Company', class: '', display: true },
			ExternalPaypointID: { label: 'External Paypoint ID', class: '', display: false },
			customerNumber: { label: 'Customer #', class: '', display: false },
			payorPhone: { label: 'Phone #', class: '', display: false },
			billingAddress: { label: 'Billing Address', class: '', display: false },
			shippingAddress: { label: 'Shipping Address', class: '', display: false },
			email: { label: 'Email', class: '', display: true },
			subscriptions: { label: 'Active Autopay', class: '', display: true },
			customerStatus: { label: 'Status', class: '', display: false },
			balance: { label: 'Balance', class: 'text-right', display: true },
			created: { label: 'Date Created', class: '', display: true }
		})
		this.getCustomFieldsFromApi()
		this.props.reports.setRecords([])
		this.clearFilters()
	}

	selectAllColumns(e, containerId) {
		let menuContainer = document.querySelector('[aria-labelledby="' + containerId + '"]')
		let checkBoxes = menuContainer.getElementsByTagName('input')
		for (var i = 0; i < checkBoxes.length; i++) {
			if (checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
				checkBoxes[i].click()
			}
		}
		this.setState({ flagItemAllColumn: false })
	}

	getCustomFieldsFromApi() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0
		let reactObj = this

		this.props.reports
			.getEntrySettings(entry)
			.then((res) => {
				if (res.data.customFields) {
					let fields = res.data.customFields
					fields.forEach(function (item, index) {
						reactObj.props.reports.addHeader(item.key, { label: item.key, class: '', display: true, filter: item.key, custom: true })
					})
					this.setState({ customFields: fields })
				}
			})
			.catch((error) => {
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}

	closeVterminalPanel() {
		this.setState({ vterminalRightPanelOpen: false })
	}

	clearAmountsCategories(vTerminal) {
		let emptyCategory = {
			name: 'amountDefaultPayabliApp',
			type: 'customer',
			label: 'Amount you wish to charge',
			value: '0.00',
			description: '',
			quantity: 1,
			showDescription: true
		}
		vTerminal.setAmountCategories([emptyCategory])
	}

	openVterminalPanel(customer, autopay) {
		if (autopay === 1) {
			this.props.vTerminal.disableAutopay(true)
		} else {
			this.props.vTerminal.disableAutopay(false)
		}
		if (customer) {
			this.props.vTerminal.selectCustomerObject(customer)
		}
		this.props.vTerminal.updateCustomerPaymentsOptions()
		this.clearAmountsCategories(this.props.vTerminal)
		this.setState({ vterminalRightPanelOpen: true, vTerminalAutopay: autopay }, function () {
			this.props.vTerminal.setPaymentMethod('')
			this.props.vTerminal.setDefaultPaymentMethodActiveKey('2')
			this.props.vTerminal.setPaymentMethodSavedPosition('2')
			this.props.vTerminal.setPaymentMethodSaved(null)
		})
	}

	deleteCustomer(customerIdToDelete) {
		this.setState({ customerIdToDelete: customerIdToDelete })
		this.openDeleteModal()
	}

	deleteCustomerAction() {
		let customerIdToDelete = this.state.customerIdToDelete

		if (customerIdToDelete) {
			this.props.global.setLoading(true)
			this.props.reports
				.deleteCustomer(customerIdToDelete)
				.then((result) => {
					this.setState({ deleteModalIsOpen: false })
					this.props.global.setLoading(false)
					toast.success('Data deleted successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
				})
				.catch((error) => {
					this.props.global.setLoading(false)
					let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				})
		}
	}

	openDeleteModal() {
		this.setState({ deleteModalIsOpen: true })
	}

	closeDeleteModal() {
		this.setState({ deleteModalIsOpen: false })
	}

	getMappingFields() {
		return {
			customerId: 'customerid',
			customer: 'fullname',
			company: 'company',
			ParentOrgName: 'orgname',
			PaypointLegalname: 'paypointlegal',
			ExternalPaypointID: 'externalpaypointid',
			customerNumber: 'customernumber',
			payorPhone: 'phone',
			billingAddress: 'address',
			shippingAddress: 'createddate',
			email: 'email',
			// 'subscriptions': 'subscriptions',
			customerStatus: 'status',
			balance: 'balance',
			created: 'createddate'
		}
	}
	middlwareFields(field) {
		const apiFields = this.getMappingFields()
		return apiFields[field]
	}
	sortBy(e, record) {
		this.props.global.setLoading(true)
		this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
			this.getReportFromApi()
		})
	}
	getReportFromApi() {
		let tempFilterValue = {}
		if (this.state.sortBy) {
			tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`
		}
		this.filter(tempFilterValue)
	}

	clearFilters() {
		this.setState({ head: '', filterStatus: {}, dateStartDate: null, dateEndtDate: null }, function () {
			this.props.reports.clearFilters()
			this.getReportFromApi()
		})
	}

	showHideFilters() {
		this.props.global.setFilterRightPanelOpen(true)
	}

	handleShowColumn(event) {
		this.props.reports.handleShowColumn(event)
		if (!this.state.flagItemAllColumn) {
			this.setState({ flagItemAllColumn: true })
		}
	}

	exportFile(format) {
		this.props.reports.exportFile('customers', format)
	}

	handleQuickFilter(value, e) {
		this.filter('status(eq)', value)
		this.setState({ head: e.target.id })
	}

	filter(type, value) {
		this.props.global.setLoading(true)
		this.props.reports
			.filter(type, value, 'customers')
			.then((res) => {
				this.props.global.setLoading(false)
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}

	viewRecordDetails(id, object) {
		if (object && (this.props.global.isDrag() || object.target.rel === 'noreferrer' || object.target.tagName.toLowerCase() === 'svg' || object.target.tagName.toLowerCase() === 'a' || object.target.tagName.toLowerCase() === 'path')) {
			return
		}
		let editCustomerModal = document.getElementById('editCustomerModal')
		if (editCustomerModal) {
			if (editCustomerModal.contains(object.target) || object.target.className === 'fade modal show') {
				return
			}
		}
		if (object && object.target.id !== 'actionsMenuButton' && object.target.nodeName.toLowerCase() !== 'a') {
			let thisObj = this
			if (thisObj.props.global.rightPanelOpen && this.props.customer.customer !== null && this.props.customer.customer.customerId === id) {
				this.props.global.setRightPanelOpen(false)
				return
			}
			this.props.global.setRightPanelOpen(false)
			this.props.global.setLoading(true)
			this.props.customer.getStatistics(id)
			this.props.customer
				.getCustomerFromApi(id)
				.then((res) => {
					thisObj.props.global.setLoading(false)
					setTimeout(function () {
						thisObj.props.global.setRightPanelOpen(true)
					}, 500)
				})
				.catch((error) => {
					thisObj.props.global.setLoading(false)
				})
		}
	}

	render() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : []

		return (
			<Layout {...this.props}>
				<div>
					{userPermissions.indexOf('Customers') !== -1 && (
						<>
							<Modal
								style={{ textAlign: 'center' }}
								show={this.state.deleteModalIsOpen}
								onHide={this.closeDeleteModal}
								size="sm"
								aria-labelledby="contained-modal-title-vcenter"
								centered>
								<Modal.Body>
									<BiTrash className="icon-modal" />
									<h5 data-qaid="deleteCustomerModal">Delete</h5>
									<p className="small">Are you sure you want to delete this customer?</p>
									<Button
										className="btn cancel-btn"
										variant="default"
										onClick={(e) => this.closeDeleteModal()}
										data-qaid="cancelButtonDeleteCustomerModal">
										Cancel
									</Button>
									&nbsp;&nbsp;
									<Button
										className="btn"
										variant="danger"
										onClick={this.deleteCustomerAction}>
										Delete
									</Button>
								</Modal.Body>
							</Modal>
							<ModalImport
								isOpen={this.state.modalImportIsOpen}
								closeModal={this.toggleModalImport}
								action={this.handleImportAction}
								modalType="Customer"
								textHeader="Import Customer Data"
								initialState={{ uploadedFiles: [], correctFiles: [] }}
							/>
							<ModalChangeStatus
								isOpen={this.state.modalStatusIsOpen}
								closeModal={this.toggleModalStatus}
								action={this.handlerChangeStatusClick}
								activeRecord={this.state.activeRecord}
								functionCallback={this.clearFilters}
							/>
							<RightPanel>
								<CustomerDataChartColumn
									isEmbedded={true}
									header={'Customer Quick View'}
									routeParams={this.props.match.params}
									customerObj={this.props.customer.customer}
								/>
							</RightPanel>

							<ParentFilterPanel
								report={'customers'}
								customFields={this.state.customFields}
							/>
						</>
					)}

					{(userPermissions.indexOf('Accept Payments') !== -1 || userPermissions.indexOf('Autopay') !== -1) && (
						<div
							id="vterminal-rigth-panel"
							className={this.state.vterminalRightPanelOpen ? 'open' : ''}>
							<div className="popover-body popover-body-2">
								<BiX
									id="right-panel-close"
									onClick={(e) => this.closeVterminalPanel()}
								/>
								{this.state.vTerminalAutopay ? (
									<h5
										data-qaid="createAutopayCustomerMoneyIn"
										className="header mb-3">
										Create Autopay
									</h5>
								) : (
									<h5
										data-qaid="createPaymentCustomerMoneyIn"
										className="header mb-3">
										Create Payment
									</h5>
								)}
								<VirtualTerminal
									isEmbedded={true}
									paymentSuccessFunctionCallBack={this.closeVterminalPanel}
									autopay={this.state.vTerminalAutopay}
								/>
							</div>
						</div>
					)}
					{this.state.vterminalRightPanelOpen && (
						<div
							onClick={(e) => this.closeVterminalPanel()}
							id="right-panel-layer"></div>
					)}

					<TopBar>
						<MainTopBarMenu />
						<div className="top-bar-sub">
							<ActionsLinks selected="customers" />
						</div>
					</TopBar>

					<div className="mt-body4">
						<MainBar
							reportNameComponent={this.getNameReport}
							dataQAName="CustomersMoneyIn"
							showHideFilters={this.showHideFilters}
							selectAllColumns={(e) => this.selectAllColumns(e, 'columnReport')}
							handleShowColumn={(e) => this.handleShowColumn(e)}
							totalRecords={this.props.reports.totalRecords}
							getHeaders={this.props.reports.getHeaders}
							headers={this.props.reports.headers}
							refreshView={this.clearFilters}
							buttonExport={true}
							searchBar={false}
							skipTotal={true}
							exportFile={this.exportFile}
							buttonImport={true}
							importFile={this.handlerImportClick}
							buttonAddComponent={this.buttonAddComponent}
							flagItemAllColumn={this.state.flagItemAllColumn}
						/>

						{this.state.filtersEnabled && (
							<ReportFilters
								report="customers"
								clearFilters={this.clearFilters}
							/>
						)}

						<div className="report-container">
							<table className="table table-hover table-striped">
								<thead>
									<tr>
										{this.props.reports.getHeaders.map(
											(record, i) =>
												(Object.entries(this.getMappingFields()).flat(1).includes(record[0]) && record[1].display && (
													<th
														key={i}
														scope="col"
														className={`${record[1].class} sortby-parent`}>
														{record[1].label}
														<BiSortAlt2
															className="cursorPointer sort-icon"
															onClick={(e) => this.sortBy(e, record)}
														/>
													</th>
												)) ||
												(record[1].display && (
													<th
														key={i}
														scope="col"
														className={record[1].class}>
														{record[1].label}
													</th>
												))
										)}
										<th
											className="text-center sticky-row"
											scope="col">
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									{this.props.reports.records.map((record, i) => (
										<tr
											key={i}
											className="cursorPointer"
											onMouseUp={(e) => this.props.global.setCoordClickUp(e)}
											onMouseDown={(e) => this.props.global.setCoordClickDown(e)}
											onClick={(e) => this.viewRecordDetails(record.customerId, e)}
											data-qaid={'customerMoneyInRow-' + i}>
											{this.props.reports.headers.customerId && this.props.reports.headers.customerId.display && <td>{record.customerId}</td>}
											{this.props.reports.headers.customer && this.props.reports.headers.customer.display && (
												<td className="avatarTd">
													{record ? <NameAvatar text={record ? (record.Firstname ? record.Firstname : '') + ' ' + (record.Lastname ? record.Lastname : '') : ''} /> : ''}
													{record ? this.props.global.capitalizeFirstLetterOfEachWord((record.Firstname ? record.Firstname : '') + ' ' + (record.Lastname ? record.Lastname : '')) : '-'}
												</td>
											)}
											{this.props.reports.headers.company && this.props.reports.headers.company.display && <td>{record.Company ? this.props.global.capitalizeFirstLetterOfEachWord(record.Company) : '-'}</td>}
											{this.props.reports.headers.ExternalPaypointID && this.props.reports.headers.ExternalPaypointID.display && <td>{record && record.externalPaypointID ? record.externalPaypointID : '-'}</td>}
											{this.props.reports.headers.customerNumber && this.props.reports.headers.customerNumber.display && <td>{record.customerNumber ? (record.customerNumber.length > 15 ? record.customerNumber.substring(0, 15) + '...' : record.customerNumber) : '-'} </td>}
											{
												this.props.reports.headers.payorPhone && this.props.reports.headers.payorPhone.display && 
												<td>
													{ record.Phone ? 
														<>
															{this.props.global.phoneNumberFormat(record.Phone)}
															<InfoConsentCustomer type={1} data={record.customerConsent?.sms} />
														</>
														: '-'
													}
												</td>
											}
											{this.props.reports.headers.billingAddress && this.props.reports.headers.billingAddress.display && <td>{record.Address ? record.Address + ' ' + record.Address1 + ' ' + record.City + ' ' + record.State + ' ' + record.Zip + ' ' + record.Country : '-'} </td>}
											{this.props.reports.headers.shippingAddress && this.props.reports.headers.shippingAddress.display && <td>{record.ShippingAddress ? record.ShippingAddress + ' ' + record.ShippingAddress1 + ' ' + record.ShippingCity + ' ' + record.ShippingState + ' ' + record.ShippingZip + ' ' + record.ShippingCountry : '-'} </td>}
											{
												this.props.reports.headers.email && this.props.reports.headers.email.display && 
													<td>
														{ record.Email ? 
															<>
																{ record.Email }
																<InfoConsentCustomer type={0} data={record.customerConsent?.eCommunication} />
															</>
															: '-'
														}
													</td>
											}
											{this.props.reports.headers.subscriptions && this.props.reports.headers.subscriptions.display && <td>{record.Subscriptions ? record.Subscriptions.length : ''}</td>}
											{this.props.reports.headers.customerStatus && this.props.reports.headers.customerStatus.display && <td>{this.props.reports.getCustomerStatus(record.customerStatus)}</td>}
											{this.props.reports.headers.balance && this.props.reports.headers.balance.display && <td className="text-right">${this.props.global.numberWithCommas(record.Balance.toFixed(2))}</td>}
											{this.props.reports.headers.created && this.props.reports.headers.created.display && <td>{this.props.global.stringDateFormatV3(record.Created)}</td>}
											{this.state.customFields.map((field, i) => this.props.reports.headers[field.key] && this.props.reports.headers[field.key].display && <td key={i}>{record.AdditionalFields && record.AdditionalFields[field.key] ? record.AdditionalFields[field.key] : '-'}</td>)}
											<td className="text-center sticky-row">
												<DropdownButton
													menuAlign="right"
													title={<BiDotsVerticalRounded />}
													data-qaid="customerMoneyInActions"
													id="actionsMenuButton"
													size="sm"
													variant="default">
													{userPermissions.indexOf('Accept Payments') !== -1 && (
														<Dropdown.Item
															onClick={(e) => this.openVterminalPanel(record, 0)}
															data-qaid="createPaymentCustomerMoneyInLink">
															Create Payment
														</Dropdown.Item>
													)}
													{userPermissions.indexOf('Autopay') !== -1 && (
														<Dropdown.Item
															onClick={(e) => this.openVterminalPanel(record, 1)}
															data-qaid="createAutopayCustomerMoneyInLink">
															Create Autopay
														</Dropdown.Item>
													)}
													{/*<Dropdown.Item>Create Invoice</Dropdown.Item>*/}
													<Dropdown.Divider />
													{userPermissions.indexOf('Customers') !== -1 && (
														<>
															<Dropdown.Item
																onClick={(e) => this.viewRecordDetails(record.customerId)}
																data-qaid="quickViewCustomerMoneyInLink">
																<div>Quick View</div>
															</Dropdown.Item>
															<Dropdown.Item
																as={Link}
																to={'/' + this.props.global.getURLEntry() + '/customer/' + record.customerId}
																data-qaid="viewCustomerMoneyInLink">
																{' '}
																View Customer
															</Dropdown.Item>
															<Dropdown.Item
																as="div"
																data-qaid="editCustomerMoneyInLink">
																<EditCustomer
																	isEmbedded={true}
																	functionCallback={this.getReportFromApi}
																	customerToUpdate={record}
																	action={'edit'}
																/>
															</Dropdown.Item>
															<Dropdown.Item
																onClick={(e) => this.handlerChangeStatusClick(e, record)}
																data-qaid="changeStatusCustomerMoneyInLink">
																Change Status
															</Dropdown.Item>
															<Dropdown.Item
																as={Link}
																to={'/' + this.props.global.getURLEntry() + '/customer/paymethods/' + record.customerId + '/add'}
																data-qaid="addPaymentMethodCustomerMoneyInLink">
																Add Payment Method
															</Dropdown.Item>
															<Dropdown.Divider />
															<Dropdown.Item
																style={{ color: '#c00000' }}
																onClick={(e) => this.deleteCustomer(record.customerId)}
																data-qaid="deleteCustomerMoneyInLink">
																Delete Customer
															</Dropdown.Item>
														</>
													)}
												</DropdownButton>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{this.props.reports.records.length < 1 && (
								<RecordsNotFound
									btnLink={'/' + PayabliStorageManager.getEntryName() + '/report/customers/add'}
									btnText="+ Add a customer"
									message="No customers yet"
									description={
										<span>
											When you add customers, they will show up here.
											<br /> You can view their payment history, charge them, and more.
										</span>
									}
								/>
							)}
						</div>
						<ReportPaginator report="customers" />
					</div>
					<ToastContainer transition={Bounce} />
				</div>
			</Layout>
		)
	}
}

export { CustomerReport }
