import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiMinusCircle, BiCheckCircle, BiLockAlt, BiTrash } from 'react-icons/bi';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'filter')
@observer
class ViewPartialCustomers extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerNo" id="customerNo" className={"form-control"} placeholder="Customer #" onChange={(e) => this.props.filter.handleCaptureTextFilter('customernumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customernumber(ct)', e)} value={this.props.filter.filterText.customerNo ? this.props.filter.filterText.customerNo : ''} />
                            <label htmlFor="customerNo">Customer #</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerName" id="customerName" className={"form-control"} placeholder="Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('name(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('name(ct)', e)} value={this.props.filter.filterText.customerName ? this.props.filter.filterText.customerName : ''} data-qaid="nameCustomerMoneyInInput"/>
                            <label htmlFor="customerName">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerCompany" id="customerCompany" className={"form-control"} placeholder="Company" onChange={(e) => this.props.filter.handleCaptureTextFilter('company(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('company(ct)', e)} value={this.props.filter.filterText.customerCompany ? this.props.filter.filterText.customerCompany : ''} data-qaid="companyCustomerMoneyInInput"/>
                            <label htmlFor="customerCompany">Company</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="externalPaypointID" id="externalPaypointID" className={"form-control"} placeholder="External Paypoint ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('externalPaypointID(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('externalPaypointID(ct)', e)} value={this.props.filter.filterText.externalPaypointID ? this.props.filter.filterText.externalPaypointID : ''} />
                            <label htmlFor="externalPaypointID">External Paypoint ID</label>
                        </div>
                    </div>
                </div>
                <div>
                    <p><small>Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}><BiCheckCircle /> Active</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-light" : "badge text-light"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiMinusCircle /> Inactive</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[85]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 85, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[85]))}><BiLockAlt /> Locked</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-99]) ? "badge bg-danger" : "badge text-danger"} onClick={() => this.props.filter.handleStatus('status(in)', -99, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[-99]))}><BiTrash /> Deleted</span>
                    </div>
                </div>
                <ContainerShowMoreLess>
                    <hr className='mb-4' style={{width: '95%'}}></hr>
                    <div className='col-sm-6 col-xs-12'>
                        <p><small>Date Created</small></p>
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="dateCreatedCalendar" 
                                        name="dateCreatedCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateCreated ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateCreated) + ' → ' : '') + (this.props.filter.stateDate.endDateCreated ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateCreated) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="dateCreatedCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateCreated ? this.props.filter.stateDate.startDateCreated : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateCreated', 'endDateCreated', 'createdDate')}
                            startDate={this.props.filter.stateDate.startDateCreated}
                            endDate={this.props.filter.stateDate.endDateCreated}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateCreated !== null ? false : true}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                },
                                }}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4" style={{ marginTop: '20px' }}>
                        <div className="form-floating">
                            <input name="customerEmail" id="customerEmail" className={"form-control"} placeholder="Email" onChange={(e) => this.props.filter.handleCaptureTextFilter('email(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('email(ct)', e)} value={this.props.filter.filterText.customerEmail ? this.props.filter.filterText.customerEmail : ''} />
                            <label htmlFor="customerEmail">Email</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating input-number">
                            <input name="customerPhone" id="customerPhone" type='number' className={"form-control"} placeholder="Phone" onChange={(e) => this.props.filter.handleCaptureTextFilter('phone(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('phone(ct)', e)} value={this.props.filter.filterText.customerPhone ? this.props.filter.filterText.customerPhone : ''} />
                            <label htmlFor="customerPhone">Phone</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerBillingaddress" id="customerBillingaddress" className={"form-control"} placeholder="Billing Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('address(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('address(ct)', e)} value={this.props.filter.filterText.customerBillingaddress ? this.props.filter.filterText.customerBillingaddress : ''} />
                            <label htmlFor="customerBilling-address">Billing Address</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerShippingAddress" id="customerShippingAddress" className={"form-control"} placeholder="Shipping Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('shippingaddress(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('shippingaddress(ct)', e)} value={this.props.filter.filterText.customerShippingAddress ? this.props.filter.filterText.customerShippingAddress : ''} />
                            <label htmlFor="customerShippingAddress">Shipping Address</label>
                        </div>
                    </div>
                </ContainerShowMoreLess>
                <div>
                    <h5 className='title'>Payment Information</h5>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="form-floating">
                                <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    decimalSeparator="."
                                    displayType="input"
                                    type="text"
                                    thousandSeparator={true}
                                    allowNegative={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={this.props.filter.filterText.balance ? this.props.filter.filterText.balance : ''}
                                    placeholder="Balance"
                                    className="form-control"
                                    onValueChange={(values) => this.props.filter.handleCaptureTextFilterObject('balance(eq)', values, 'balance')}
                                    onKeyDown={(e) => this.props.filter.handleKeyDownObject('balance(eq)', e, 'balance')}
                                />
                                <label>{"Balance"}</label>
                            </div>
                        </div>                        
                    </div>
                </div>
            </>);
    }
}

export { ViewPartialCustomers };