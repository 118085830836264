import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from "mobx";
import {IMaskInput} from 'react-imask';
import { BiMessageSquareError, BiCheckCircle, BiRefresh } from "react-icons/bi";
import { Accordion , Card, Modal, Button} from 'react-bootstrap';
import { PaymentMethodECheck } from './PaymentMethodECheck';
import { PaymentMethodsAccepted } from './PaymentMethodsAccepted';
import { SelectPaymentMethodSaved } from './SelectPaymentMethodSaved';
import { PaymentMethodCCForm } from './PaymentMethodCCForm';
import { PaymentMethodCheck } from './PaymentMethodCheck';
import { AutopayForm } from './AutopayForm';
import { Message } from './Message';
import { EditCustomer } from './EditCustomer';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberFormat from 'react-number-format';
import pointOfSale from '../assets/images/pointOfService.svg';

@inject('global' , 'vTerminal', 'device')
@observer
class VirtualTerminal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            infoModalIsOpen: true,
            searchLineItemPanelIsOpen: false,
            searchCustomerPanelIsOpen: false,
            newLineItemModalIsOpen: false,
            receiptModalIsOpen: false,
            confirmModalIsOpen: false,
            infoMessageInModal: '',
            customerToSearch: '',
            lineItemToSearch: '',
            searchingCustomer: false,
            searchingLineItem: false,
            lineItemTemplate: {
                name: '',
                type: '',
                label: '',
                value: '',
                description: '',
                quantity: 1,
                showDescription: true
            },
            lineItemTemplateLabelErrors: false,
            lineItemTemplateAmountErrors: false,
            paymentApproved: false,
            paymentResponseMessage: "",
            paymentResponseMessageAvs: "",
            paymentResponseMessageCvv: "",
            saveToCatalog: false,
            responseList: [],
            swipeModalIsOpen: false,
            deviceTimeout: false,
            deviceDescription: '',
            inputQtyError: false,
            sendApprovedReceipt: true,
            responseListEmpty: true,
            sendDeclinedReceipt: true
        };
        this.renderAmountCategories = this.renderAmountCategories.bind(this);
        this.disableAutopay = this.disableAutopay.bind(this);
        this.handleCategoryTextChangeMask = this.handleCategoryTextChangeMask.bind(this);
        this.handleCategoryAmount = this.handleCategoryAmount.bind(this);
        this.setPaymentMethod = this.setPaymentMethod.bind(this);
        this.openInfoModal = this.openInfoModal.bind(this);
        this.closeNewLineItemModal = this.closeNewLineItemModal.bind(this);
        this.openNewLineItemModal = this.openNewLineItemModal.bind(this);
        this.closeInfoModal = this.closeInfoModal.bind(this);
        this.searchingCustomer = this.searchingCustomer.bind(this);
        this.searchingLineItem = this.searchingLineItem.bind(this);
        this.selectCustomer = this.selectCustomer.bind(this);
        this.removeAmountCategory = this.removeAmountCategory.bind(this);
        this.handleAmountCategoryType = this.handleAmountCategoryType.bind(this);
        this.handleCategoryTextChange = this.handleCategoryTextChange.bind(this);
        this.handleCategoryShowDescription = this.handleCategoryShowDescription.bind(this);
        this.addLineItem = this.addLineItem.bind(this);
        this.resetLineItemTemplate = this.resetLineItemTemplate.bind(this);
        this.makePayment = this.makePayment.bind(this);
        this.openSearchLineItemPanel = this.openSearchLineItemPanel.bind(this);
        this.closeSearchLineItemPanel = this.closeSearchLineItemPanel.bind(this);
        this.handleCategoryQtySubstract = this.handleCategoryQtySubstract.bind(this);
        this.handleCategoryQtyAdd = this.handleCategoryQtyAdd.bind(this);
        this.handleCategoryQtyInput = this.handleCategoryQtyInput.bind(this);
        this.handleCategoryQtyInputWithoutZero = this.handleCategoryQtyInputWithoutZero.bind(this);
        this.selectLineItem = this.selectLineItem.bind(this);
        this.clearCustomer = this.clearCustomer.bind(this);
        this.clearTextCustomer = this.clearTextCustomer.bind(this);
        this.reviewPayment = this.reviewPayment.bind(this);
        this.updateCustomerToSearch = this.updateCustomerToSearch.bind(this);
        this.savePaymentMethodForFuture = this.savePaymentMethodForFuture.bind(this);
        this.openReceiptModal = this.openReceiptModal.bind(this);
        this.closeReceiptModal = this.closeReceiptModal.bind(this);
        this.handleFirstCategoryTextChange = this.handleFirstCategoryTextChange.bind(this);
        this.handlePayorTextChange = this.handlePayorTextChange.bind(this);
        this.handleOperation = this.handleOperation.bind(this);
        this.handleChangeDeviceSelect = this.handleChangeDeviceSelect.bind(this);
        this.openSwipeModal = this.openSwipeModal.bind(this);
        this.closeSwipeModal = this.closeSwipeModal.bind(this);
        this.closeSwipeModalReset = this.closeSwipeModalReset.bind(this);
        this.focus = this.focus.bind(this);
        this.renderPaymentMethodReceipt = this.renderPaymentMethodReceipt.bind(this);
        this.renderPaymentInformation = this.renderPaymentInformation.bind(this);
        this.handlerSendReceiptByConfig = this.handlerSendReceiptByConfig.bind(this);
    }
    
    componentDidMount() {
        let isEmbedded = this.props.isEmbedded;
    
        this.props.vTerminal.setOperation("sale");

        if(isEmbedded !== true){
            this.props.global.setLoading(true);
        }
        
        
        try{
            this.props.vTerminal.getPaymentPageFromApi().then(res => {
                if(isEmbedded !== true){
                    this.props.global.setLoading(false);
                }
            })
            .catch(error => {
                if(isEmbedded !== true){
                    this.props.global.setLoading(false);
                }
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
    
            //devices
            this.props.device.getDevicesFromApi().then(res => {
                if(res?.responseList && res?.responseList?.length > 0){
                    this.setState({responseList: res.responseList}, () => {
                        if(isEmbedded !== true){
                            this.props.global.setLoading(false);
                        }
                    })
                }
                else{
                    setTimeout(() => {
                        this.setState({responseListEmpty: false}, () => {
                            if(isEmbedded !== true){
                                this.props.global.setLoading(false);
                            }
                        })
                    }, 1000);
                }
            })
            .catch(error => {
                if(isEmbedded !== true){
                    this.props.global.setLoading(false);
                }
            });
        }catch(e){
            if(isEmbedded !== true){
                this.props.global.setLoading(false);
            }
            let errorMessage = e.message ? e.message : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
        
    }

    handlerSendReceiptByConfig(general){
        if(general){
            let thisObj = this;
            general.forEach(function (item) {
                if(item.key === "sendApprovedReceipt" && item.value !== "true"){
                    thisObj.setState({sendApprovedReceipt : false});
                }
    
                if(item.key === "sendDeclinedReceipt" && item.value !== "true"){
                    thisObj.setState({sendDeclinedReceipt : false});
                }
            });
        }
    }

    handleChangeDeviceSelect(e){
        let value = e.target.value;
        let valueArray = value.split("|||");
        if(valueArray[0]){
            this.setState({deviceDescription:valueArray[1]});
            this.props.vTerminal.handleTextChangeACH(e.target.name, valueArray[0]);
        }
    }

    componentDidUpdate(){
        let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;

        if(this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods.length === 0){
            if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' && this.props.vTerminal.hasCards && ((credentialsPermissions.card.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.card.recurring && this.props.vTerminal.isAutopay))){
                this.props.vTerminal.setPaymentMethod('card');
                this.props.vTerminal.setDefaultPaymentMethodActiveKey("0");
            }else if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'ach' && this.props.vTerminal.hasECheck && ((credentialsPermissions.ach.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.ach.recurring && this.props.vTerminal.isAutopay))){
                this.props.vTerminal.setPaymentMethod('ach');
                this.props.vTerminal.setDefaultPaymentMethodActiveKey("1");
            }
        }
    }

    focus(e){
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }

    handleOperation(e){
        this.props.vTerminal.setOperation(e.target.value);
    }

    updateCustomerToSearch(){
        this.setState({ customerToSearch: this.props.vTerminal.getSelectedCustomerString});
    }

    savePaymentMethodForFuture(e){
        this.props.vTerminal.savePaymentMethodForFuture(e.target.checked);
    }

    handleCategoryQtySubstract(event){
        this.props.vTerminal.handleCategoryQtySubstract(event);
    }
    
    handlePayorTextChange(field, value){
        this.props.vTerminal.handlePayorTextChange(field, value);
    }
    
    handleNotesTextChange(e){
        this.props.vTerminal.handleNotesTextChange(e.target.value);
    }

    handleCategoryQtyAdd(event){
        this.props.vTerminal.handleCategoryQtyAdd(event);
    }

    handleCategoryQtyInput(event){
        if(!this.state.inputQtyError){
            this.props.vTerminal.handleCategoryQtyInput(event);
        }
    }

    handleCategoryQtyInputWithoutZero(e) {
        if (e.target.value.length === 0) {
            this.setState({ inputQtyError: true });
        }else{
            this.setState({ inputQtyError: false });    
        }
    }

    searchingCustomer(e){
        this.setState({ customerToSearch: e.target.value, searchCustomerPanelIsOpen: true});
        if(e.target.value.length > 1){
            this.setState({ searchingCustomer: true });
            this.props.vTerminal.searchingCustomer(e.target.value).then(res => {
                this.setState({ searchingCustomer: false });
            })
            .catch(error => {
                this.setState({ searchingCustomer: false });
            });
        }    
        else{
            this.props.vTerminal.clearCustomer();
        }
    }
    
    clearTextCustomer(){
        this.setState({ customerToSearch: ''});
        this.props.vTerminal.clearCustomer();
        this.props.vTerminal.clearCustomerSelected();
        this.props.vTerminal.handleTextChangeACH('paymentMethodSaved', null);
    }
    
    searchingLineItem(e){
        this.setState({ lineItemToSearch: e.target.value, searchLineItemPanelIsOpen: true});
        if(e.target.value.length > 2){
            this.setState({ searchingLineItem: true });
            this.props.vTerminal.searchingLineItems(e.target.value).then(res => {
                this.setState({ searchingLineItem: false });
            })
            .catch(error => {
                this.setState({ searchingLineItem: false });
            });
        }    
        else{
            this.props.vTerminal.clearLineItems();
        }
    }

    prevent(e) {
        e.preventDefault();
    }

    removeAmountCategory(event) {
        this.props.vTerminal.removeAmountCategory(event);
        if(this.props.vTerminal.amountCategories.length === 0){
            let emptyCategory = {
                name: "amountDefaultPayabliApp",
                type: 'customer',
                label: 'Amount you wish to charge',
                value: '0.00',
                description: '',
                quantity: 1,
                showDescription: true
            };
            this.props.vTerminal.setAmountCategories([emptyCategory]);
        }
        
    }

    selectCustomer(position,e){
        e.preventDefault();
        this.props.vTerminal.selectCustomer(position);
        this.setState({ customerToSearch: this.props.vTerminal.getSelectedCustomerString, searchCustomerPanelIsOpen: false }, function(){
            this.props.vTerminal.clearCustomer();            
            if(this.props.vTerminal.getCustomerSelected){
                this.props.vTerminal.updateCustomerPaymentsOptions();
            }
            else{
                this.props.vTerminal.setPaymentMethod('card');
                this.props.vTerminal.setDefaultPaymentMethodActiveKey("0");
            }
            if(this.props.vTerminal.getCustomerSelected?.StoredMethods?.length !== 0){
                this.props.vTerminal.setPaymentMethod('');
                this.props.vTerminal.setPaymentMethodSaved(null);
            }
        });
    }

    selectLineItem(position,e){
        e.preventDefault();
        this.props.vTerminal.selectLineItem(position);
        this.setState({ searchLineItemPanelIsOpen: false , lineItemToSearch: ''}, function(){
            this.props.vTerminal.clearLineItems();
        });
    }

    openSearchLineItemPanel(){
        this.setState({ searchLineItemPanelIsOpen: true });
    }

    closeSearchLineItemPanel(){
        this.setState({ searchLineItemPanelIsOpen: false });
    }
    
    closeSearchLineItemPanelDelay(){
        let reactObj = this;
        setTimeout(function(){ 
            reactObj.setState({ searchLineItemPanelIsOpen: false, lineItemToSearch: '' });
        }, 500); 
    }
   
    closeSearchCustomerPanelDelay(){
        let reactObj = this;
        setTimeout(function(){ 
            reactObj.setState({ searchCustomerPanelIsOpen: false });

            if(Object.keys(reactObj.props.vTerminal.getCustomerSelected).length === 0){
                reactObj.clearTextCustomer();
            }

        }, 600);
    }

    openInfoModal(info){
        this.setState({ infoModalIsOpen: false, infoMessageInModal: info });
    }

    clearCustomer(){
        this.props.vTerminal.clearCustomer();
    }

    closeInfoModal(){
        this.setState({ infoModalIsOpen: true });
    }
    
    closeReceiptModal(){
        if(this.state.paymentApproved){
            this.props.vTerminal.resetPaymentPage();
        }
        this.setState({ receiptModalIsOpen: false });

        if(this.props.paymentSuccessFunctionCallBack){
            this.props.paymentSuccessFunctionCallBack();
        }
    }
    
    openReceiptModal(){
        this.setState({ receiptModalIsOpen: true });
    }
    
    closeConfirmModal(){
        this.setState({ confirmModalIsOpen: false });
    }
    
    closeSwipeModal(){
        this.setState({ swipeModalIsOpen: false });
    }

    closeSwipeModalReset(){
        this.props.vTerminal.resetPaymentPage();
        this.closeSwipeModal();
        window.location.reload();
    }
    
    openSwipeModal(){
        this.setState({ swipeModalIsOpen: true });
    }

    openConfirmModal(){
        this.setState({ confirmModalIsOpen: true });
    }    
    
    openNewLineItemModal(e, saveToCatalog){
        if(e){
            e.preventDefault();
        }
        this.resetLineItemTemplate();
        this.setState({ newLineItemModalIsOpen: true, saveToCatalog: saveToCatalog ? saveToCatalog : false});
        if(this.state.lineItemToSearch){
            let lineItemTemplate = this.state.lineItemTemplate;
            lineItemTemplate.label = this.state.lineItemToSearch;
            this.setState({ lineItemTemplate : lineItemTemplate});
        }
        this.closeSearchLineItemPanel();
    }

    closeNewLineItemModal(){
        this.setState({ newLineItemModalIsOpen: false });
    }

    handleCategoryTextChangeMask(field, key ,value) {
        if(!isNaN(value))
        {
            value = value.toString();
        }
        this.props.vTerminal.handleCategoryTextChangeMask(field, key ,value);
    }
    
    
    resetLineItemTemplate() {
        this.setState({ lineItemTemplate: {
            name: '',
            type: '',
            label: '',
            value: '',
            description: '',
            quantity: 1,
            showDescription: true
        }});
    }

    reviewPayment(){
        this.validateFields();

        if(this.props.vTerminal.getPaymentPageErrors.credentialsMaxMinTicketError){
            this.openInfoModal('Sorry, the amount to be paid is greater or less than what is allowed.');
            return;
        }

        if(this.props.vTerminal.totalAmount.netAmount === 0){
            this.openInfoModal('Sorry, the amount must be greater than $0.00');
            return;
        }
        else{
            if(!this.props.vTerminal.hasPaymentPageErrors()){
                if(this.props.vTerminal.getPaymentMethod === "device"){
                    this.setState({deviceTimeout: false});
                    this.openSwipeModal();
                    this.props.vTerminal.makePayment(this.props.vTerminal.operation ? this.props.vTerminal.operation : "sale").then(response => {
                        this.closeSwipeModal();
                        if(response.data.responseData.resultCode && response.data.responseData.resultCode !== 1){
                            let message = "";
                            if(response.data.responseData.resultText){
                                message = response.data.responseData.resultText;
                            }else{
                                message = response.data.responseText ? "Sorry, the payment was " + response.data.responseText : 'Sorry, we have a problem sending your payment.';
                            }
                            this.setState({
                                paymentApproved:false, 
                                receiptModalIsOpen: true, 
                                paymentResponseMessage: message, 
                                referenceId: response.data.responseData.referenceId
                            });            
                        } else {
                            if (response.data.responseData.referenceId && response.data.responseData.resultCode === 1 && this.state.sendApprovedReceipt === true) {
                                this.props.vTerminal.sendReceipt(response.data.responseData.referenceId);
                            }
                            
                            if (response.data.responseData.referenceId && response.data.responseData.resultCode !== 1 && this.state.sendDeclinedReceipt === true) {
                                this.props.vTerminal.sendReceipt(response.data.responseData.referenceId);
                            }
                            this.setState({
                                paymentApproved:true, 
                                receiptModalIsOpen: true, 
                                paymentAuthCode: response.data.responseData.authCode ? response.data.responseData.authCode : '-',  
                                referenceId: response.data.responseData.referenceId ? response.data.responseData.referenceId : '-'
                            });                            
                        }
                    })
                    .catch(error => {
                        if (error.code === 'ECONNABORTED'){
                            this.setState({deviceTimeout: true});
                        }
                        else{
                            this.closeSwipeModal();
                        }
                        
                    });
                }
                else{
                    this.openConfirmModal();
                }                
            }
        }
    }

    makePayment(){
        this.props.global.setLoading(true);
        this.props.vTerminal.makePayment(this.props.vTerminal.operation ? this.props.vTerminal.operation : "sale")
        .then((response) => {
            this.props.global.setLoading(false);
            if(response.data.responseData.resultCode && response.data.responseData.resultCode !== 1){
                let message = "", messageAvs = "", messageCvv = "";
                if(response.data.responseData.resultText){
                    message = response.data.responseData.resultText;
                }else{
                    message = response.data.responseText ? "Sorry, the payment was " + response.data.responseText : 'Sorry, we have a problem sending your payment.';
                }
                if (response.data.responseData.cvvResponseText){
                    messageCvv = response.data.responseData.cvvResponseText;
                }
                if (response.data.responseData.avsResponseText){
                    messageAvs = response.data.responseData.avsResponseText;
                }

                if (response.data.responseData.referenceId && this.state.sendDeclinedReceipt === true) {
                    this.props.vTerminal.sendReceipt(response.data.responseData.referenceId);
                }
                this.setState({
                    paymentApproved:false, 
                    receiptModalIsOpen: true, 
                    paymentResponseMessage: message, 
                    paymentResponseMessageCvv: messageCvv,
                    paymentResponseMessageAvs: messageAvs,
                    referenceId: response.data.responseData.referenceId
                });
            } else {
                if (response.data.responseData.referenceId && response.data.responseData.resultCode === 1 && this.state.sendApprovedReceipt === true) {
                    this.props.vTerminal.sendReceipt(response.data.responseData.referenceId);
                }
                this.setState({
                    paymentApproved:true, 
                    receiptModalIsOpen: true, 
                    paymentAuthCode: response.data.responseData.authCode ? response.data.responseData.authCode : '-',  
                    referenceId: response.data.responseData.referenceId ? response.data.responseData.referenceId : '-'
                });                
            }            
        })
        .catch((reason) => {
            this.props.global.setLoading(false);
            let message = "";
            if(reason.data){
                message = reason.data.responseText ? reason.data.responseText : 'Sorry, we have a problem sending your payment.';
            }else if(reason.request.response){
                if (typeof reason.request.response === 'string') {
                    let messageJson = JSON.parse(reason.request.response);
                    if(messageJson.responseText){
                        message = messageJson.responseText;
                    }else{
                        message = reason.request.response;
                    }                   
                }
                else {                    
                    message = JSON.parse(reason.request.response).responseText;
                }
            }            
            this.setState({
                paymentApproved:false, 
                receiptModalIsOpen: true, 
                paymentResponseMessage: message, 
                referenceId: (reason.data && reason.data.responseData && reason.data.responseData.referenceId ) ? reason.data.responseData.referenceId : null 
            });            
        });
        this.closeConfirmModal();
    }

    setPaymentMethod(method, activeKey){
        if(method === "device" && this.state.responseList && this.state.responseList.length === 1){
            let defaultValue = this.state.responseList[0];
            if(defaultValue){
                this.setState({deviceDescription:defaultValue.deviceNickName});
                this.props.vTerminal.handleTextChangeACH('device', defaultValue.deviceId);
            }
        }
        this.props.vTerminal.setPaymentMethod(method);
        if(activeKey!==null){
            this.props.vTerminal.setDefaultPaymentMethodActiveKey(activeKey);
        }
        this.props.vTerminal.setSavedPaymentMethodSelected('');
    }

    validateFields(){        
        var paymentPage = this.props.vTerminal.paymentPage;
        var validators = this.props.global.validators;
        var reactObj = this;
        this.props.vTerminal.clearPaymentPageError();

        // validating autopay fields
        if(this.props.vTerminal.isAutopay){
            if(validators.isEmpty(paymentPage.autopay.startDate) || validators.isMaxLength(250, paymentPage.autopay.startDate))
            {
                this.props.vTerminal.setPaymentPageError('autopayStartDateError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('autopayStartDateError',false);
            }

            if(validators.isEmpty(paymentPage.autopay.frequencySelected) || validators.isMaxLength(250, paymentPage.autopay.frequencySelected))
            {
                this.props.vTerminal.setPaymentPageError('autopayFrequencyError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('autopayFrequencyError',false);
            }

            if(paymentPage.autopay.frequencySelected !== 'onetime'){
                if(validators.isEmpty(paymentPage.autopay.finishSelected) || validators.isMaxLength(250, paymentPage.autopay.finishSelected))
                {
                    this.props.vTerminal.setPaymentPageError('autopayFinishError',true);
                }
                else{
                    this.props.vTerminal.setPaymentPageError('autopayFinishError',false);
                }
            }
            else{
                this.props.vTerminal.setPaymentPageError('autopayFinishError',false);
            }
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayStartDateError',false);
            this.props.vTerminal.setPaymentPageError('autopayFrequencyError',false);
            this.props.vTerminal.setPaymentPageError('autopayFinishError',false);
        }
        
        // validating ach form
        if(this.props.vTerminal.getPaymentMethod === "ach"){
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsDeviceError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountHolderName) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountHolderName))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountType) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountType))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achRouting) || !this.props.global.isValidRouting(paymentPage.paymentMethods.achRouting) || validators.isMaxLength(9, paymentPage.paymentMethods.achRouting) || validators.stringValidator('routing', paymentPage.paymentMethods.achRouting))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccount) ||  validators.stringValidator('numbers', paymentPage.paymentMethods.achAccount) || validators.isMinLength(4, paymentPage.paymentMethods.achAccount) || validators.isMaxLength(17, paymentPage.paymentMethods.achAccount))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
            }
        }else if(this.props.vTerminal.getPaymentMethod === "card"){

            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsDeviceError',false);
            
            if(validators.isEmpty(paymentPage.paymentMethods.cardNumber) || validators.isMaxLength(16, paymentPage.paymentMethods.cardNumber) || validators.stringValidator('card', paymentPage.paymentMethods.cardNumber) || !this.props.global.luhnCheck(paymentPage.paymentMethods.cardNumber))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardExpirationDate) || validators.stringValidator('exp', paymentPage.paymentMethods.cardExpirationDate))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
            }
            else{

                let expDateYear = paymentPage.paymentMethods.cardExpirationDate.substr(-2);
                let expDateMonth = paymentPage.paymentMethods.cardExpirationDate.substr(0,2);
                let currentYear =new Date().getFullYear().toString().substr(-2);
                let currentMonth =new Date().getMonth().toString();

                if(parseInt(expDateYear) < parseInt(currentYear)){
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
                }


                if(((parseInt(expDateYear) === parseInt(currentYear)) && (parseInt(expDateMonth) < parseInt(currentMonth)+1)) || (parseInt(expDateMonth) > 12)){
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                }
            }

            let ccType = this.props.global.creditCardType(paymentPage.paymentMethods.cardNumber);
            if(validators.isEmpty(paymentPage.paymentMethods.cardCvv) || (ccType === "american-express" ? validators.stringValidator('cvvamex', paymentPage.paymentMethods.cardCvv) : validators.stringValidator('cvv', paymentPage.paymentMethods.cardCvv)))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardZipcode) || validators.isMaxLength(7, paymentPage.paymentMethods.cardZipcode) || validators.stringValidator('zipcode', paymentPage.paymentMethods.cardZipcode))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardHolderName) || validators.stringValidator('alphanumericspaces', paymentPage.paymentMethods.cardHolderName))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
            }

        }else if(this.props.vTerminal.getPaymentMethod === '' || this.props.vTerminal.getPaymentMethod === 'savedcard' || this.props.vTerminal.getPaymentMethod === 'savedach'){

            this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
            
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);

            this.props.vTerminal.setPaymentPageError('paymentMethodsDeviceError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.paymentMethodSaved)){
                this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);
            }
        }else if(this.props.vTerminal.getPaymentMethod === 'device'){
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
            
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
            
            this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.device) || validators.isMaxLength(250, paymentPage.paymentMethods.device))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsDeviceError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsDeviceError',false);
            }
        }else if(this.props.vTerminal.getPaymentMethod === 'check'){
            this.props.vTerminal.setPaymentPageError('paymentMethodsCheckAccountHolderNameError',false);
            
            if(validators.isEmpty(paymentPage.paymentMethods.checkAccountHolderName) || validators.isMaxLength(250, paymentPage.paymentMethods.checkAccountHolderName))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCheckAccountHolderNameError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCheckAccountHolderNameError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.checkNumber))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCheckNumber',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCheckNumber',false);
            }
        }

        // validating customer
        if(!this.props.vTerminal.hasCustomerSelected){
            this.props.vTerminal.setPaymentPageError('customerError',true);
        }else{
            this.props.vTerminal.setPaymentPageError('customerError',false);
        }

         // validating payment categories
        var categoriesConfig = Object.entries(toJS(this.props.vTerminal.amountCategories));
        categoriesConfig.forEach(function (item, index) {
            if(!item[1].optionalPay && (validators.isEmpty(item[1].value) || parseFloat(item[1].value) === 0 || isNaN(item[1].value)) ){
                reactObj.props.vTerminal.setPaymentPageError([item[1].name],true)
            }
            else{
                reactObj.props.vTerminal.setPaymentPageError([item[1].name],false)
            }
        });

        // validating payor fields
        this.props.vTerminal.setPaymentPageError('payorInvoiceNumberError',false);
        if(validators.isMaxLength(250, paymentPage.payor.invoiceNumber.value) || validators.stringValidator('text', paymentPage.payor.invoiceNumber.value))
        {
            this.props.vTerminal.setPaymentPageError('payorInvoiceNumberError',true);
        }

        // validating notes field
        this.props.vTerminal.setPaymentPageError('notesError',false);
        if(validators.isMaxLength(250, paymentPage.notes.value))
        {
            this.props.vTerminal.setPaymentPageError('notesError',true);
        }

        this.props.vTerminal.validateCredentialsMaxMinTicketError();

        setTimeout(function(){ 
            try{
            let inputs =  document.getElementsByClassName('input-error');
            if(inputs[0]){
                let objRect = inputs[0].getBoundingClientRect();
                window.scrollTo({
                    top: parseInt(objRect.top + window.scrollY - 130),
                    behavior: 'smooth'
                });
                inputs[0].focus();
            }}
            catch(e){
                console.log(e);
            }
         }, 300);
    }

    renderAmountCategories(){
        var loopData = [];
        var categoriesConfig = Object.entries(toJS(this.props.vTerminal.amountCategories));
        var categoriesCount = categoriesConfig.length;
       
        var reactObject = this;
        categoriesConfig.forEach(function (item, index) { 
            if(categoriesCount > 1 || categoriesConfig[0][1].name !== "amountDefaultPayabliApp") {            
                loopData.push(
                    <li key={"keyAmountCategory"+item[0]} className="ui-state-default dragable-panel" id={"blockcategory"+item[0]}>
                        <div className="row mb-3 line-item">
                            <div className="col-8">
                                <div className="form-floating form-floating-money">

                                    <NumberFormat
                                        onFocus={(e)=>reactObject.focus(e)} 
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator="."
                                        displayType="input"
                                        type="text"
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        decimalScale={2}
                                        fixedDecimalScale={true}

                                        value={item[1].value}
                                        placeholder={item[1].label}
                                        className={reactObject.props.vTerminal.getPaymentPageErrors[item[1].name] ? "form-control input-error input-money" : "form-control input-money" }
                                        onValueChange={(values) => reactObject.handleCategoryTextChangeMask(item[0],"value", values.value)}
                                        readOnly={ (item[1].type === 'fixed' || item[1].type === 'quantity') ? true : false }
                                    />

                                    <label>{item[1].label}</label>
                                    { item[1].type === 'quantity'  &&
                                    <div className='input-group input-group-qty' style={{ width: item[1].quantity.toString().length + 3 + "em" }}>
                                        <button id={"amountCategoryQtySubstract"+item[0]} className="btn btn-outline-light btn-sm" type="button" onClick={(e) => reactObject.handleCategoryQtySubstract(e)}>-</button>
                                        <IMaskInput
                                            id={"amountCategoryQtyInput"+item[0]}
                                            mask={"00000000"}
                                            className={"form-control"}
                                            value={String(item[1].quantity)}
                                            onChange={(e) => reactObject.handleCategoryQtyInput(e)}
                                            onBlur={(e) => reactObject.handleCategoryQtyInputWithoutZero(e)}
                                        />
                                        <button id={"amountCategoryQtyAdd"+item[0]} className="btn btn-outline-light btn-sm" type="button" onClick={(e) => reactObject.handleCategoryQtyAdd(e)}>+</button>
                                    </div>
                                    }
                                </div>
                                
                                { (item[1].description && item[1].showDescription)  ? <p className="small-grey-m0 mt-1">{item[1].description}</p> : ''}
                            </div>
                            <div className="col-3 text-right mt-2">
                                <b>${reactObject.props.global.numberWithCommas(parseFloat(item[1].value * item[1].quantity).toFixed(2))}</b>
                            </div>
                            <div className="col-1 text-right">
                                <div className="category-icon mt-2">
                                <button className="remove-icon-btn" type="button" onClick={(e) => reactObject.removeAmountCategory(e)} id={"categoryRemove"+item[0]}>&nbsp;</button>
                                </div>
                            </div>
                        </div>

                    </li>
                )
            }else{
                loopData.push(
                    <li key={"keyAmountCategory"+item[0]} className="text-center" id={"blockcategory"+item[0]}>
                        <label className="small mb-3">{categoriesConfig[0][1].label}</label>                    
                        <NumberFormat
                            onFocus={(e)=>reactObject.focus(e)} 
                            thousandsGroupStyle="thousand"
                            prefix="$"
                            decimalSeparator="."
                            displayType="input"
                            type="text"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}

                            data-qaid={"amountVirtualTerminal"}
                            value={item[1].value}
                            placeholder={"$0.00"}
                            className={"big-amount"}
                            onValueChange={(values) => reactObject.handleCategoryTextChangeMask(item[0],"value", values.value)}
                            readOnly={(item[1].type === 'fixed' || item[1].type === 'quantity') ? true : false }
                        />                                  
                        <div className="row mb-4">                
                            <div className="col-12">
                                <div className="form-floating">
                                    <input style={{fontSize: "12px"}} value={categoriesConfig[0][1].description} className="form-control" placeholder="Description" onChange={(e)=>reactObject.handleFirstCategoryTextChange(e)} />
                                    <label htmlFor="customer">Description</label>
                                </div>
                            </div>                        
                        </div>
                    </li>
                )
            }
        });        
        return loopData;
    }

    disableAutopay(checked){
        this.props.vTerminal.setDefaultPaymentMethodActiveKey(2);
        this.props.vTerminal.setPaymentMethod('');
        this.props.vTerminal.handleTextChangeACH('paymentMethodSaved', null);
        this.props.vTerminal.setSavedPaymentMethodSelected("");
        this.props.vTerminal.disableAutopay(checked);
    }

    handleAmountCategoryType(e){
        let lineItemTemplate = this.state.lineItemTemplate;
        lineItemTemplate.type = e.target.value;
        this.setState({ lineItemTemplate: lineItemTemplate });
    }

    handleCategoryTextChange(key ,e) {
        let lineItemTemplate = this.state.lineItemTemplate;
        lineItemTemplate[key] = e.target.value;
        this.setState({ lineItemTemplate: lineItemTemplate });
    }

    handleCategoryPreviewQty(value) {
        let lineItemTemplate = this.state.lineItemTemplate;
        if (((lineItemTemplate.quantity + value) > 0)) {
            lineItemTemplate.quantity =  lineItemTemplate.quantity + value;
            this.setState({ lineItemTemplate: lineItemTemplate });
        }
    }

    handleFirstCategoryTextChange(e) {
        this.props.vTerminal.handleFirstCategoryTextChange(e.target.value);
    }

    addLineItem(){
        let lineItemTemplateLabelErrors = false;
        let lineItemTemplateAmountErrors = false;
        let validators = this.props.global.validators;

        lineItemTemplateLabelErrors = validators.isEmpty(this.state.lineItemTemplate.label) || validators.isMaxLength(250, this.state.lineItemTemplate.label) ? true : false;
        lineItemTemplateAmountErrors = validators.isEmpty(this.state.lineItemTemplate.value) || validators.isMaxLength(250, this.state.lineItemTemplate.value) ? true : false;
        this.setState({ 
            lineItemTemplateLabelErrors : lineItemTemplateLabelErrors,
            lineItemTemplateAmountErrors : lineItemTemplateAmountErrors
        }, function(){
            if(!this.state.lineItemTemplateLabelErrors && !this.state.lineItemTemplateAmountErrors){
                if(this.state.saveToCatalog){
                    this.props.global.setLoading(true);
                    this.props.vTerminal.addNewLineItemField(this.state.lineItemTemplate).then(res => {
                        this.props.global.setLoading(false);
                        this.setState({lineItemToSearch: ''});
                    })
                    .catch(error => {
                        this.props.global.setLoading(false);
                        let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong creating the item!";
                        toast.error(errorMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    });
                }
                else{
                    if(this.props.vTerminal.paymentPage.amount.categories.length === 1 && this.props.vTerminal.paymentPage.amount.categories[0].value === '0.00'){
                        this.props.vTerminal.cleanCategories();
                    }
                    let lineItemTemplate = this.state.lineItemTemplate;
                    lineItemTemplate.type="quantity";
                    this.setState({lineItemToSearch: '', lineItemTemplate: lineItemTemplate}, () => {
                        this.props.vTerminal.paymentPage.amount.categories.push(this.state.lineItemTemplate);
                    });
                }
                this.closeNewLineItemModal();
            }            
        });
    }
    
    handleCategoryAmount(key ,value) {
        let lineItemTemplate = this.state.lineItemTemplate;
        lineItemTemplate[key] = value;
        this.setState({ lineItemTemplate: lineItemTemplate });
    }

    handleCategoryShowDescription(){
        let lineItemTemplate = this.state.lineItemTemplate;
        lineItemTemplate.showDescription = !lineItemTemplate.showDescription;
        this.setState({ lineItemTemplate: lineItemTemplate });
    }

    renderPaymentMethodReceipt(){
        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'device' ){
            return (
                <>
                    {this.props.global.getPaymethodImg("device")} &nbsp;&nbsp; {this.state.deviceDescription}
                </>
            )
        }

        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' || this.props.vTerminal.getPaymentMethod.toLowerCase() === 'savedcard' ){
            return (
                <>
                    {
                        this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' ?
                        <>
                        {this.props.global.getPaymethodImg(this.props.global.creditCardType(this.props.vTerminal.paymentPage.paymentMethods.cardNumber))}
                        &nbsp;&nbsp;&nbsp;{this.props.vTerminal.paymentPage.paymentMethods.cardNumber ? this.props.global.maskedCardNumber(this.props.vTerminal.paymentPage.paymentMethods.cardNumber, "v3"): ''}
                        </>
                        :
                        <>
                        {this.props.vTerminal.getCustomerSelected && Number.isInteger(this.props.vTerminal.paymentMethodSavedPosition) && this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition] ? this.props.global.getPaymethodImg(this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition].Descriptor) : ''}
                        &nbsp;&nbsp;&nbsp;
                        {this.props.vTerminal.getCustomerSelected && Number.isInteger(this.props.vTerminal.paymentMethodSavedPosition) && this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition] ? this.props.global.maskedCardNumber(this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition].MaskedAccount, "v3") : ''}
                        </>
                    }
                </>
            )
        }
        
        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'ach' || this.props.vTerminal.getPaymentMethod.toLowerCase() === 'savedach' ){
            return (
                <>
                {this.props.global.getPaymethodImg('ach')} &nbsp;&nbsp;&nbsp; Bank account / E-Check
                </>
            )
        }

        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'check'){
            return (
                <>
                    {this.props.global.getPaymethodImg('check')} &nbsp;&nbsp;&nbsp; Check Payment
                </>
            )
        }
        
        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'cash'){
            return (
                <>
                    {this.props.global.getPaymethodImg('cash')} &nbsp;&nbsp;&nbsp; Cash
                </>
            )
        }
        
    }

    renderPaymentInformation(){
        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' || this.props.vTerminal.getPaymentMethod.toLowerCase() === 'savedcard'){
            return (
                this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' 
                    ?
                    <>
                        {this.props.global.getPaymethodImg(this.props.global.creditCardType(this.props.vTerminal.paymentPage.paymentMethods.cardNumber))}
                        &nbsp;&nbsp;&nbsp;{this.props.vTerminal.paymentPage.paymentMethods.cardNumber ? this.props.global.maskedCardNumber(this.props.vTerminal.paymentPage.paymentMethods.cardNumber, "v3"): ''}
                    </>
                    :
                    <>
                         {this.props.vTerminal.getCustomerSelected && Number.isInteger(this.props.vTerminal.paymentMethodSavedPosition) && this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition] ? this.props.global.getPaymethodImg(this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition].Descriptor) : ''}
                        &nbsp;&nbsp;&nbsp;
                        {this.props.vTerminal.getCustomerSelected && Number.isInteger(this.props.vTerminal.paymentMethodSavedPosition) && this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition] ? this.props.global.maskedCardNumber(this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition].MaskedAccount, "v3") : ''}
                    </>
            )            
        }else if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'ach' || this.props.vTerminal.getPaymentMethod.toLowerCase() === 'savedach' ){
            return (
                <>
                    {this.props.global.getPaymethodImg('ach')} &nbsp;&nbsp;&nbsp; Bank account / E-Check
                </>
            )
        }else if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'check'){
            return (
                <>
                    {this.props.global.getPaymethodImg('check')} &nbsp;&nbsp;&nbsp; Check Payment
                </>
            )
        }else if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'cash'){
            return (
                <>
                    {this.props.global.getPaymethodImg('cash')} &nbsp;&nbsp;&nbsp; Cash
                </>
            )
        }
    }

    render() {
        let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
        return (
            <>
            { this.props.vTerminal.hasCredentials && this.props.vTerminal.isActivePaypoint ?
            <>
            <div>
                <Modal style={{textAlign: "center"}} show={!this.state.infoModalIsOpen} onHide={this.closeInfoModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiMessageSquareError className="icon-modal"/>
                    <h5>Info</h5>
                    <p className="small">{this.state.infoMessageInModal}</p>
                    <Button className="btn" onClick={this.closeInfoModal}>
                    Close
                    </Button>
                </Modal.Body>
                </Modal>
                <Modal show={this.state.receiptModalIsOpen} onHide={this.closeReceiptModal}  size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="text-center mt-4">
                        <b>Payment Receipt</b>
                        <h2 className="mb-4">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</h2>
                        { this.state.paymentApproved ?
                        <p data-qaid="paymentReceiptApproved"><BiCheckCircle style={{fontSize: '28px', color: "rgb(136,201,82)"}}/> Approved</p>
                        :
                        <>
                        <p className="mb-2"><BiCheckCircle style={{fontSize: '28px', color: "rgb(255, 100, 82)"}}/> Declined</p>
                        <p className="small-grey mt-0 mb-2">{this.state.paymentResponseMessage}</p>
                        {this.state.paymentResponseMessageCvv !== "" ? <p className="small-grey mt-0 mb-2">{this.state.paymentResponseMessageCvv}</p> : ""}
                        {this.state.paymentResponseMessageAvs !== "" ? <p className="small-grey mt-0 mb-2">{this.state.paymentResponseMessageAvs}</p> : ""}
                        </>
                        }                   
                    </div>
                    <div className="mt-4 small">
                        {
                            this.props.vTerminal.amountCategories.map((record, i) => (
                                <>
                                <div key={i} className="row">
                                    <div className="col-6 mb-1">{record.label} x {record.quantity}</div>
                                    <div className="col-6 text-right mb-1">${this.props.global.numberWithCommas(parseFloat(record.value).toFixed(2))}</div>
                                </div>
                                {record.description &&
                                <div className='row mt-1 mb-1'>
                                    <div className="col-4">
                                    Description
                                    </div>
                                    <div className="col-8 text-right">
                                    {record.description}
                                    </div>
                                </div>
                                }
                                </>
                            ))
                           
                        }
                    </div>
                    <div className="review-total mb-3" style={{borderBottom: "none"}}>
                        <h6 className="sub-header-line mb-3"> </h6>
                        <div className="row mb-3 mt-3">
                            <div className="col-6">
                               {this.renderPaymentMethodReceipt()}
                            </div>
                            <div className="col-6 text-right">
                                {!this.props.vTerminal.isAutopay ?
                                <small>
                                Paid on {this.props.global.stringDateFormat(new Date().toString())}, {this.props.global.stringTimeFormat(new Date().toString())}
                                </small>
                                :
                                <small>
                                Next date {this.props.global.stringDateFormat(this.props.vTerminal.paymentPage.autopay.startDate)}
                                </small>
                                }
                            </div>
                        </div>  
                        <div className="row">
                        <div className="col">Payment:</div>
                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['netAmount']).toFixed(2))}</div>
                        </div>
                        <div className="row">
                        <div className="col">Fee:</div>
                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['fee']).toFixed(2))}</div>
                        </div>
                        <div className="top-border-grey mt-2 bottom-border-grey">
                            <div className="row total-amount">
                            <div className="col">Total Amount:</div>
                            <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <div className="row total-amount">
                                <div className="col-4">Reference Id: </div>
                                <div className="col-8">{ this.state.referenceId ? this.state.referenceId : ""}</div>
                                { this.state.paymentApproved &&
                                    <>
                                    <div className="col-4">AuthCode: </div>
                                    <div className="col-8">{ this.state.paymentAuthCode ? this.state.paymentAuthCode : ""}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <Button id="btnCloseReceipt" className="btn full-w btn-lg" onClick={this.closeReceiptModal} data-qaid="paymentReceiptCloseButton">
                        Close
                    </Button>                   
                </Modal.Body>
                </Modal>
                <Modal show={this.state.newLineItemModalIsOpen} onHide={this.closeNewLineItemModal}  size="md" centered >
                <Modal.Body className="popover-body">
                    <div className="row mb-2">
                        <div className="col"><h6>Price Field</h6></div>
                    </div>
                    <div className="row">
                    <div className="col-7">
                        <input value={this.state.lineItemTemplate.label} onChange={(e) => this.handleCategoryTextChange("label", e)} type="text" className={ this.state.lineItemTemplateLabelErrors ? "form-control mb-3 input-error" : "form-control mb-3" } placeholder="Item Name"/>
                    </div>
                    <div className="col-5">
                        <NumberFormat
                            onFocus={(e)=>this.focus(e)} 
                            thousandsGroupStyle="thousand"
                        
                            decimalSeparator="."
                            displayType="input"
                            type="text"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}

                            placeholder='Amount'
                            className={ this.state.lineItemTemplateAmountErrors ? "form-control mb-3 input-error input-money" : "form-control mb-3 input-money" }
                            onValueChange={(values) => this.handleCategoryAmount("value", values.value)}
                        />
                    </div>                      
                    <div className="col-12">
                        <input onChange={(e) => this.handleCategoryTextChange("description", e)} type="text" className="form-control mb-1" placeholder="Item description (optional)" />
                    </div>                   
                    </div>
                    <div className="card-v2 mb-4 info-message-card" style={{position: "relative"}}>
                        <span className="badge bg-dark card-info-badge">Preview</span>
                        <div className="row line-item">
                        <div className="col-7">
                            <div className="form-floating form-floating-money">
                                <NumberFormat
                                    onFocus={(e)=>this.focus(e)} 
                                    thousandsGroupStyle="thousand"
                                    decimalSeparator="."
                                    displayType="input"
                                    type="text"
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    decimalScale={2}
                                    fixedDecimalScale={true}

                                    value={this.state.lineItemTemplate.value}
                                    placeholder={this.state.lineItemTemplate.label ? this.state.lineItemTemplate.label : "Item Name"}
                                    className={ "form-control input-money" }
                                    readOnly={ true }
                                />
                                <label>{this.state.lineItemTemplate.label ? this.state.lineItemTemplate.label : "Item Name"}</label>                                    
                                <div className='input-group input-group-qty' style={{ top: "6px", right: "8px"}}>
                                    <button id={"amountCategoryQtySubstractPreview"} className="btn btn-outline-light btn-sm" type="button" onClick={() => this.handleCategoryPreviewQty(-1)}>-</button>
                                    <input type="text" className="form-control" readOnly value={this.state.lineItemTemplate.quantity} />
                                    <button id={"amountCategoryQtyAddPreview"} className="btn btn-outline-light btn-sm" type="button" onClick={() => this.handleCategoryPreviewQty(1)}>+</button>
                                </div>                                
                            </div>                                
                            { this.state.lineItemTemplate.description  ? <p className="small-grey-m0 mt-1 text-left">{this.state.lineItemTemplate.description}</p> : ''}
                        </div>
                        <div className="col-3 text-right mt-1">
                        <b>${this.props.global.numberWithCommas(parseFloat(this.state.lineItemTemplate.value ? this.state.lineItemTemplate.quantity * this.state.lineItemTemplate.value : 0).toFixed(2))}</b>
                        </div>
                        <div className="col-2 text-right">
                            <div className="category-icon mt-1">
                            <button className="remove-icon-btn" type="button" >&nbsp;</button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn full-w btn-light" type="button" onClick={() => this.closeNewLineItemModal() }>Cancel</button>
                        </div>
                        <div className="col">
                        <button className="btn full-w btn-primary" type="button" onClick={() => this.addLineItem()}>Add</button>   
                        </div>
                    </div>  
                </Modal.Body>
                </Modal>
                <Modal show={this.state.confirmModalIsOpen} onHide={() => this.closeConfirmModal()}  size="md" centered >
                <Modal.Body className="popover-body">
                    <h6 className="sub-header-line mb-3">Payment Information</h6>                        
                    <div className="row mb-3">
                        <div className="col-7">
                            {this.renderPaymentInformation()}
                        </div>
                        <div className="col-5 text-right">
                            {!this.props.vTerminal.isAutopay ?
                            <small>
                            Paid on {this.props.global.stringDateFormat(new Date().toString())}
                            </small>
                            :
                            <small>
                            Next date {this.props.global.stringDateFormat(this.props.vTerminal.paymentPage.autopay.startDate)}
                            </small>
                            }
                        </div>
                    </div>                        
                    <div className="review-total">
                        <h6 className="sub-header-line mb-3">Review & Send Payment</h6>
                        <div className="row">
                        <div className="col">Net Amount:</div>
                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['netAmount']).toFixed(2))}</div>
                        </div>
                        <div className="row">
                        <div className="col">Fee:</div>
                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['fee']).toFixed(2))}</div>
                        </div>

                        <div className="row total-amount">
                        <div className="col">Total Amount:</div>
                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</div>
                        </div>
                    </div>
                    <button type="button" onClick={(e) => this.makePayment()} className="btn btn-success btn-lg full-w" data-qaid="buttonPayPopUp">Pay ${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</button>
                    <div className="text-center mt-3">
                    <button className="btn btn-default" onClick={() => this.closeConfirmModal()}>Cancel</button>
                    </div>
                </Modal.Body>
                </Modal>
                <Modal show={this.state.swipeModalIsOpen} onHide={() => function(){}}  size="sm" centered >
                <Modal.Body>                        
                    <div className="row mb-3 mt-4 text-center">
                        <div className="text-center mb-3">
                            <img alt="" style={{width: '60px', opacity: 0.8}} src={pointOfSale}/>
                        </div>
                        <h6>Continue on device</h6>
                        <p className="small-grey mt-0">{this.state.deviceDescription}</p>
                        <br/>
                        {this.state.deviceTimeout === true ? 
                            <>
                                <p className='small mb-3'>Connection time has elapsed. Please continue on the device.</p>
                                <Button className="btn btn-success" onClick={this.closeSwipeModalReset}>
                                    Continue
                                </Button>
                            </>
                        :
                            <BiRefresh className="refreshIcon"/>
                        }
                        
                    </div>
                </Modal.Body>
                </Modal>

                      
                <ul className="ul-nomargin">                               
                    { this.props.autopay !== 1 && this.props.autopay !== 0 &&
                    <li className="mb-4" style={{marginTop: "22px"}}>
                        <div className="row">
                            
                            {(credentialsPermissions.ach.onetime || credentialsPermissions.card.onetime || credentialsPermissions.wallet.onetime || credentialsPermissions.cloud.onetime || credentialsPermissions.cash.onetime || credentialsPermissions.check.onetime) &&
                            <div className="col"><button onClick={() => this.disableAutopay(false)} type="button" className={this.props.vTerminal.isAutopay? "btn full-w btn-outline-primary":"btn full-w btn-primary"}>One Time</button></div>
                            }

                            {(credentialsPermissions.ach.recurring || credentialsPermissions.card.recurring || credentialsPermissions.wallet.recurring || credentialsPermissions.cloud.recurring || credentialsPermissions.cash.recurring || credentialsPermissions.check.recurring) &&
                            <div className="col"><button onClick={() => this.disableAutopay(true)} type="button" className={this.props.vTerminal.isAutopay? "btn full-w btn-primary":"btn full-w btn-outline-primary"}>Schedule</button></div>
                            }
                        </div>
                    </li>
                    }
                    <li className="mb-2" id="transactiontype">
                        <div className="form-floating">
                            <select value={this.props.vTerminal.operation} onChange={(e) => this.handleOperation(e)} className="form-select form-control" id="operation" name="operation">
                                <option value="sale">Sale</option>
                                <option value="authorize">Authorize or "Capture Later"</option>
                            </select>
                            <label htmlFor="operation">Operation</label>
                        </div>
                    </li>
                    <li className="mb-4" id="blockamount">
                        <div>
                            <ul style={{paddingLeft: 0}} className="mb-3" ref={this.sortableCategories}>
                                {this.renderAmountCategories()}
                            </ul>
                            
                            <div className="form-floating">
                                <input autoComplete="off" onFocus={(e) => this.searchingLineItem(e)} onBlur={(e) => this.closeSearchLineItemPanelDelay(e)} onChange={(e) => this.searchingLineItem(e)} value={this.state.lineItemToSearch} id="line-item" className={ !this.state.searchingLineItem ? "form-control search-input": "form-control search-input loading"} placeholder="Find or add line item…" />
                                <label htmlFor="line-item">Find or add line item…</label>
                    
                                <div className={ this.state.searchLineItemPanelIsOpen ? "search-dropdown-menu" : "d-none search-dropdown-menu" }>
                                    <a style={{color: "#10A0E3"}} href="/" onClick={(e) => this.openNewLineItemModal(e)} className="dropdown-item" >+ Add { this.state.lineItemToSearch && this.state.lineItemToSearch !== '' ? <><span className="text-dark">"{this.state.lineItemToSearch}"</span>  as a One Time Item</>: 'a One Time Item'}</a>
                                    <a style={{color: "#10A0E3"}} href="/" onClick={(e) => this.openNewLineItemModal(e, true)} className="dropdown-item" >+ Add { this.state.lineItemToSearch && this.state.lineItemToSearch !== '' ? <><span className="text-dark">"{this.state.lineItemToSearch}"</span> to Items Catalog</>: ' Item to Catalog'}</a>
                                    {
                                        this.props.vTerminal.lineItems.map((record, i) => (
                                            <a key={i} href="/" onClick={(e) => this.selectLineItem(i,e)} className="dropdown-item" >{record.itemProductName} <span style={{float:"right"}}>${this.props.global.numberWithCommas(parseFloat(record.itemCost).toFixed(2))}</span></a>
                                        ))                                                    
                                    }
                                    { (this.props.vTerminal.lineItems.length === 0 && this.state.lineItemToSearch.length > 2) &&
                                        <div className="small-grey ml-2">No results found</div>
                                    }

                                </div>
                                
                                
                            </div>
                        </div>
                    </li>
                    <li id="blockpayer" className="mb-4" style={{position: "relative"}}>
                        <label className="optionalLabelInfo" style={{fontSize: "11px"}}>(Optional)</label>
                        <div className="form-floating">
                            <IMaskInput
                                mask={this.props.global.maskValidator(this.props.vTerminal.paymentPage.payor.invoiceNumber.text)}
                                name="invoiceNumber"
                                defaultValue={ this.props.vTerminal.paymentPage.payor.invoiceNumber.value  }
                                unmask={true}
                                maxLength="250"
                                onAccept={
                                    (value, mask) => this.handlePayorTextChange('invoiceNumber', value)
                                }
                                placeholder={this.props.vTerminal.paymentPage.payor.invoiceNumber.label}
                                className={this.props.vTerminal.getPaymentPageErrors.payorInvoiceNumberError ? "form-control input-error" : "form-control"}
                                id="invoiceNumber"
                            />
                            <label htmlFor="invoiceNumber">{this.props.vTerminal.paymentPage.payor.invoiceNumber.label}</label>
                        </div>                                    
                    </li>
                    { this.props.vTerminal.paymentPage.autopay.enabled &&
                    <li className="ui-state-default dragable-panel" id="blockautopay">
                        <h6 className="sub-header mb-3">Autopay</h6>                        
                        <AutopayForm/>
                    </li>
                    }
                    <li className="mb-4" id="blockCustomer">
                        <h6 className="mb-3">Customer</h6>
                        { !this.props.vTerminal.disableSearchCustomer ?
                        <div className="form-floating">
                            <input className={ !this.state.searchingCustomer ? this.props.vTerminal.getPaymentPageErrors.customerError ? "form-control search-input input-error" : "form-control search-input" : "form-control search-input loading"} autoComplete="off" onFocus={(e) => this.clearTextCustomer(e)} onBlur={(e) => this.closeSearchCustomerPanelDelay(e)} onChange={(e) => this.searchingCustomer(e)} value={this.state.customerToSearch} id="customer" placeholder="Search for a customer" data-qaid="inputCustomerVirtualTerminal"/>
                            <label htmlFor="customer">Search for a customer</label>                            
                            <div className={ this.state.searchCustomerPanelIsOpen ? "search-dropdown-menu" : "d-none search-dropdown-menu" }>
                                <EditCustomer isEmbedded={this.props.isEmbedded} functionCallBackPaypointConfig={this.handlerSendReceiptByConfig} rewriteSelectedWithResponse={true} functionCallbackNew={this.updateCustomerToSearch} defaultName={this.state.customerToSearch} title={"Add customer"} label={"+ Add customer"} style={{color: "#10A0E3"}} action={"new"}/>
                                {
                                    this.props.vTerminal.customers.map((record, i) => (
                                        <a data-qaid={"customer-"+(record.Email)} key={i} href="/" onClick={(e) => this.selectCustomer(i,e)} className="dropdown-item" >
                                            {record.Firstname+" "+record.Lastname} - {record.Email}
                                            <br/>
                                            <div className='separatorBetween'>
                                            {record.customerNumber ? <span className='small-small grey'>Customer #: {record.customerNumber}</span> : ""}
                                            {record.Company ? <span className='small-small grey'>{record.Company}</span> : ""}
                                            {record.Address ? <span className='small-small grey'>{record.Address.substring(0,20)+ " ..."}</span> : ""}
                                            {record.Phone ? <span className='small-small grey'>{record.Phone}</span> : ""}
                                            </div>
                                        </a>
                                    ))
                                }
                                { (this.props.vTerminal.customers.length === 0 && this.state.customerToSearch.length > 2) &&
                                        <div className="small-grey ml-2">No results found</div>
                                }
                            </div>                                        
                        </div>
                        :
                        <div className="form-floating">
                            <input className="form-control" disabled id="customer" placeholder={this.props.vTerminal.getSelectedCustomerString} />
                            <label htmlFor="customer">{this.props.vTerminal.getSelectedCustomerString}</label>
                        </div>
                        }                                   
                    </li>
                    <li className="mb-4" id="blockpaymentMethods">
                        <h6 className="mb-3">Payment Method</h6>                                
                        {
                            (!credentialsPermissions.ach.onetime && !credentialsPermissions.ach.recurring && !credentialsPermissions.card.onetime && !credentialsPermissions.card.recurring) && (
                                <div style={{margin: '50px 0 50px 0'}}>
                                    <div className="alert alert-warning small">There are no services activated for this Paypoint. Contact your administrator to enable it.</div>
                                </div>
                            )                        
                        }
                        <div className="mb-3">
                            <SelectPaymentMethodSaved/>
                        </div>   
                        <Accordion activeKey={this.props.vTerminal.defaultPaymentMethodActiveKey}>
                        {   
                            (this.state.responseListEmpty && ((credentialsPermissions.cloud.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.cloud.recurring && this.props.vTerminal.isAutopay))) &&
                            (this.state.responseList.length < 1 ?
                            (<Card className="card card-in mb-3 card-hover">
                                <Accordion.Toggle as={Card.Header}  className="card-body">
                                    <div className="row">
                                        <div className="col-2 text-center">
                                        <div id="div-loading-layer" className="d-flex justify-content-center align-items-center" style={{margin: '3px'}}>
                                        <div className="spinner-border" role="status"></div>
                                        </div>
                                        </div>
                                        <div className="col-10">
                                            Device / Terminal
                                            <p className="small small-grey-m0">
                                            Loading devices for selection...
                                            </p>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                            </Card>) :
                            (<Card className="card card-in mb-3 card-hover">
                                <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="3" onClick={(e) => this.setPaymentMethod('device', "3")}>
                                    <div className="row">
                                        <div className="col-2 text-center pt-1">
                                            {this.props.global.getGlobalImg('pointofsale', '30px')}
                                        </div>
                                        <div className="col-10">
                                            Device / Terminal
                                            <p className="small small-grey-m0">
                                            Tap, dip, or swipe a debit or credit card
                                            </p>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <div className="form-floating">
                                            <select className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsDeviceError ? "form-select form-control input-error" : "form-select form-control" } id="device" name="device" onChange={(e) => this.handleChangeDeviceSelect(e)}>
                                                {(this.state.responseList.length !== 1) &&
                                                <option value="">Select...</option>
                                                }                                     
                                                { this.state.responseList.map((record, i) => (
                                                        <option key={i} className={record.deviceNickName} value={record.deviceId+"|||"+record.deviceNickName}>{record.deviceNickName} - {record.make} {record.model}</option>
                                                    ))
                                                }

                                            </select>
                                            <label htmlFor="device">Device Description & Model</label>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>))
                        }
                        { (this.props.vTerminal.hasECheck && ((credentialsPermissions.ach.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.ach.recurring && this.props.vTerminal.isAutopay))) &&
                        <Card className="card card-in mb-3 card-hover">
                        <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="1" onClick={(e) => this.setPaymentMethod('ach', "1")} data-qaid="achPaymentMethodVirtualTerminal">
                            <span className="badge bg-dark card-info-badge">Most popular method</span>
                            <div className="row">
                                <div className="col-2 text-center pt-1">
                                    {this.props.global.getGlobalImg('ach', '30px')}
                                </div>
                                <div className="col-10">
                                    Bank account / E-Check
                                    <p className="small small-grey-m0">
                                    Pay from your Bank Account
                                    </p>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <PaymentMethodECheck history={this.props.history} />
                        </Card.Body>
                        </Accordion.Collapse>
                        </Card>
                        }
                        { (this.props.vTerminal.hasCards && ((credentialsPermissions.card.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.card.recurring && this.props.vTerminal.isAutopay))) &&
                        <Card className="card card-in mb-3 card-hover">
                        <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('card', "0")} data-qaid="cardPaymentMethodVirtualTerminal">
                            <div className="row">
                                <div className="col-2 text-center pt-1">
                                    {this.props.global.getGlobalImg('card', '30px')}
                                </div>
                                <div className="col-10">
                                Credit or Debit Card
                                <p className="small small-grey-m0">
                                    Use your Credit or Debit Card
                                </p>
                                <div className="card-brands accordion-right-corner" style={{position: 'absolute'}}>
                                <PaymentMethodsAccepted/>
                                </div>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <PaymentMethodCCForm history={this.props.history} />
                        </Card.Body>
                        </Accordion.Collapse>
                        </Card>
                        }
                        { (this.props.vTerminal.hasCheck && (this.props.vTerminal.operation !== 'authorize') && ((credentialsPermissions.check.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.check.recurring && this.props.vTerminal.isAutopay))) &&
                            <Card className="card card-in mb-3 card-hover">
                                <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('check', "4")}>
                                    <div className="row">
                                        <div className="col-2 text-center pt-1">
                                            {this.props.global.getGlobalImg('check', '30px')}
                                        </div>
                                        <div className="col-10">
                                            Check Payment
                                            <p className="small small-grey-m0">Record a Physical Check payment</p>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <PaymentMethodCheck history={this.props.history} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        }
                        { (this.props.vTerminal.hasCash && (this.props.vTerminal.operation !== 'authorize') && ((credentialsPermissions.cash.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.cash.recurring && this.props.vTerminal.isAutopay))) &&
                            <Card className="card card-in mb-3 card-hover" style={this.props.vTerminal.paymentMethod && this.props.vTerminal.paymentMethod === 'cash' ? {boxShadow: '0px 0px 2px 0.5px #2196f3'} : {}}>
                                <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('cash', "5")}>
                                    <div className="row">
                                        <div className="col-2 text-center pt-1">
                                            {this.props.global.getGlobalImg('cash', '30px')}
                                        </div>
                                        <div className="col-10">
                                            Cash
                                            <p className="small small-grey-m0">Record a cash payment</p>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                            </Card>
                        }
                        </Accordion>
                        { (!this.props.vTerminal.hasCards && !this.props.vTerminal.hasECheck ) &&  
                            <Message message="Warning: Select at least a Payment Method." />
                        }
                        { (!this.props.vTerminal.isAutopay && (this.props.vTerminal.getPaymentMethod === "card" || this.props.vTerminal.getPaymentMethod === "ach")) &&
                        <div className="icheck-primary">
                            <input type="checkbox" defaultChecked={this.props.vTerminal.paymentPage.paymentMethods.savePaymentMethodForFuture} id="savePaymentMethod" onClick={(e) => this.savePaymentMethodForFuture(e)} />
                            <label htmlFor="savePaymentMethod">Save payment details for future use</label>
                        </div>
                        }
                    </li>
                    <li id="blocknotes" className="mb-4" style={{position: "relative"}}>
                        <label className="optionalLabelInfo" style={{top: "46px", fontSize: "11px"}}>(Optional)</label>
                        <h6 className="mb-3">Notes</h6>
                        <textarea onChange={(e)=>this.handleNotesTextChange(e)} placeholder={this.props.vTerminal.paymentPage.notes.placeholder} style={{height: '75px'}} className={this.props.vTerminal.getPaymentPageErrors.notesError ? "form-control input-error" : "form-control"}></textarea>
                    </li>
                    <li className="ui-state-default dragable-panel" id="blockreview">
                        <div className="review-total">
                            <h6 className="sub-header-line mb-3">Review & Send Payment</h6>
                            <div className="row">
                                <div className="col">Net Amount:</div>
                                <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['netAmount']).toFixed(2))}</div>
                            </div>
                            <div className="row">
                                <div className="col">Fee:</div>
                                <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['fee']).toFixed(2))}</div>
                            </div>
                            <div className="row total-amount">
                                <div className="col">Total Amount:</div>
                                <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</div>
                            </div>
                        </div>
                    </li>
                    <li className="ui-state-default dragable-panel" id="blockpaymentButton">
                        <div className="btn-group full-w">
                            <button type="button" onClick={(e) => this.reviewPayment()} className="btn btn-success btn-lg" data-qaid="buttonReview&Pay">Review & Pay ${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</button>
                        </div>
                    </li>
                </ul>
            </div>
            </>
           :
            <>
            {!this.props.global.isLoading &&
            <div style={{marginTop: "50px"}}>
                <Message message="Your account is not ready to take payments. If you have any questions please contact customer support."  />
            </div>
            }
            </>
            }
            </>
            )
    }
}

export { VirtualTerminal };