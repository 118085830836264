import React from 'react';
import { inject, observer } from 'mobx-react';

import { SectionCustomer } from './customer/SectionCustomer';
import SectionInvoiceDetails from './invoiceDetails/SectionInvoiceDetails';
import SectionItemsDetails from './itemsDetails/SectionItemsDetails';
import SectionAdvancedOptions from './advancedOptions/SectionAdvancedOptions';
import SectionPayment from './payment/SectionPayment';

@inject('store', 'global', 'invoice')
@observer
class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedDefault: false,
      memoNote: '',
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handlerApplyNote = this.handlerApplyNote.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    const invoiceStatus = this.props.invoice.status;
    const invoiceDataLoaded = this.props.invoiceDataLoaded;

    if (invoiceStatus === 'new' && !this.state.loadedDefault && Object.keys(this.props.settings).length !== 0) {
      const { memoNote } = this.props.settings;
      this.setState({ memoNote, loadedDefault: true });
      this.props.invoice.updateAdvancedOptions({ memoNote });
    }

    if (invoiceStatus !== 'new' && !this.state.loadedDefault && invoiceDataLoaded) {
      const note = invoiceDataLoaded.notes;
      this.props.invoice.updateAdvancedOptions({ memoNote: note });
      this.setState({ memoNote: note, loadedDefault: true });
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  handleChangeInput(e) {
    const { value } = e.target;
    this.setState({ memoNote: value });
  }

  async handlerApplyNote(e) {
    if(e && e.target){
      this.handleChangeInput(e);
      this.props.invoice.updateAdvancedOptions({ memoNote: e.target.value });
    }
  }

  render() {
    return (
      <>
        <h5 data-qaid="createEditInvoiceMoneyIn">{this.props.invoice.status !== 'new'? "Edit":"Create"} Invoice</h5>

        <div className="wrapper-form">
          <SectionCustomer />

          <SectionInvoiceDetails invoiceDataLoaded={this.props.invoiceDataLoaded} settings={this.props.settings} />

          <SectionItemsDetails invoiceDataLoaded={this.props.invoiceDataLoaded} />

          {/* Notes */}
          <div className="section-form">
            <h6 className="mb-3">Notes</h6>
            <div className="form-floating">
              <input
                className="form-control"
                onChange={(e)=>this.handlerApplyNote(e)}
                placeholder="Memo note"
                id="memoNote"
                value={this.state.memoNote}
              />
               <label htmlFor={"memoNote"}>Memo note</label>
            </div>
            
            </div>
          
          <SectionAdvancedOptions settings={this.props.settings} />

          <SectionPayment />
        </div>
      </>
    );
  }
}

export { InvoiceForm };
