import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import NumberFormat from 'react-number-format';

@inject('store', 'global', 'vTerminal', 'invoice')
@observer
class ItemModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lineItemTemplate: {
        name: '',
        type: '',
        label: '',
        value: '',
        description: '',
        quantity: 1,
        showDescription: true,
      },
      lineItemTemplateLabelErrors: false,
      lineItemTemplateAmountErrors: false,
      lineItemTemplateQuantityErrors: false,
    };

    this.handleCategoryTextChange = this.handleCategoryTextChange.bind(this);
    this.handleCategoryAmount = this.handleCategoryAmount.bind(this);
    this.addLineItem = this.addLineItem.bind(this);
    this.editLineItem = this.editLineItem.bind(this);
    this.onlyPositiveNumbers = this.onlyPositiveNumbers.bind(this);
    this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
  }

  handleCategoryTextChange(key, e) {
    let lineItemTemplate = this.state.lineItemTemplate;
    lineItemTemplate[key] = e.target.value;
    this.setState({ lineItemTemplate: lineItemTemplate });
  }

  handleCategoryAmount(key, value) {
    let lineItemTemplate = this.state.lineItemTemplate;
    lineItemTemplate[key] = value;
    this.setState({ lineItemTemplate: lineItemTemplate });
  }

  handleChangeQuantity(e) {
    let lineItemTemplate = this.state.lineItemTemplate;
    let value = e.target.value;
    if (value.toString().length > 0) {
      value = parseInt(value, 10);
      lineItemTemplate['quantity'] = (isNaN(value) || value === 0) ? 1 : value;
      this.setState({ lineItemTemplate: lineItemTemplate, lineItemTemplateQuantityErrors: false });      
    }else{
      lineItemTemplate['quantity'] = '';
      this.setState({
        lineItemTemplateQuantityErrors: true
      });
    }
  }

  onlyPositiveNumbers(event) {
    if (event.charCode === 8 || event.charCode === 0 || (event.charCode >= 48 && event.charCode <= 57)) {
      return;
    } else event.preventDefault();
  }

  editLineItem() {
    if (!this.state.lineItemTemplateQuantityErrors){
      const { position } = this.state.lineItemTemplate;
      this.props.vTerminal.editItemCategory(this.state.lineItemTemplate, position);
      this.props.invoice.updateItem(this.state.lineItemTemplate, position);
      this.props.close();
    } 
  }

  addLineItem() {
    let lineItemTemplateLabelErrors = false;
    let lineItemTemplateAmountErrors = false;
    let validators = this.props.global.validators;

    lineItemTemplateLabelErrors =
      validators.isEmpty(this.state.lineItemTemplate.label) ||
      validators.isMaxLength(250, this.state.lineItemTemplate.label)
        ? true
        : false;

    lineItemTemplateAmountErrors =
      validators.isEmpty(this.state.lineItemTemplate.value) ||
      validators.isMaxLength(250, this.state.lineItemTemplate.value)
        ? true
        : false;

    this.setState(
      {
        lineItemTemplateLabelErrors: lineItemTemplateLabelErrors,
        lineItemTemplateAmountErrors: lineItemTemplateAmountErrors,
      },
      function () {
        if (!this.state.lineItemTemplateLabelErrors && !this.state.lineItemTemplateAmountErrors && !this.state.lineItemTemplateQuantityErrors) {
          if (this.props.saveToCatalog) {
            this.props.global.setLoading(true);
            this.props.vTerminal
              .addNewLineItemField(this.state.lineItemTemplate)
              .then((res) => {
                this.props.invoice.addItem(this.state.lineItemTemplate);
                this.props.global.setLoading(false);
              })
              .catch((error) => {
                this.props.global.setLoading(false);
                let errorMessage =
                  error.response && error.response.data.responseText
                    ? error.response.data.responseText
                    : 'Something is Wrong creating the item!';
                toast.error(errorMessage, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'toast-error-container',
                });
              });
          } else {
            if (
              this.props.vTerminal.paymentPage.amount.categories.length === 1 &&
              this.props.vTerminal.paymentPage.amount.categories[0].value === '0.00'
            ) {
              this.props.vTerminal.cleanCategories();
            }

            //this.state.lineItemTemplate.type = 'quantity';
            let lineItemTemplate = this.state.lineItemTemplate;
            lineItemTemplate['type'] = 'quantity';
            this.setState({ lineItemTemplate: lineItemTemplate });
            
            this.props.vTerminal.paymentPage.amount.categories.push(this.state.lineItemTemplate);
            this.props.invoice.addItem(this.state.lineItemTemplate);
          }

          this.props.close();
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    // when is open the modal
    if (!prevProps.open && this.props.open) {
      if (this.props.action === 'add') {
        this.setState({
          lineItemTemplate: {
            name: '',
            type: '',
            label: this.props.lineItemToSearch,
            value: '',
            description: '',
            quantity: 1,
            showDescription: true,
          },
        });
      }

      if (this.props.action === 'edit') {
        const itemModify = this.props.lineItemToModify;
        this.setState({
          lineItemTemplate: { ...itemModify, showDescription: true },
        });
      }
    }
  }

  render() {
    let titleModal = '';
    switch (this.props.action) {
      case 'add':
        titleModal = this.props.saveToCatalog ? 'Add product or service to items catalog' : 'Add one time item';
        break;
      case 'edit':
        titleModal = 'Edit item';
        break;
      default:
        titleModal = '';
        break;
    }

    return (
      <Modal show={this.props.open} onHide={this.props.close} size="md" centered>
        <Modal.Body className="popover-body">
          <div className="row mb-2">
            <div className="col">
              <h6>{titleModal}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <input
                value={this.state.lineItemTemplate.label}
                onChange={(e) => this.handleCategoryTextChange('label', e)}
                type="text"
                className={
                  this.state.lineItemTemplateLabelErrors ? 'form-control mb-3 input-error' : 'form-control mb-3'
                }
                placeholder="Product or Service Name"
              />
            </div>
            
          </div>
          <div className="row">
            <div className="col-sm-6">
              <NumberFormat
                thousandsGroupStyle="thousand"
                prefix="$"
                decimalSeparator="."
                displayType="input"
                type="text"
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={4}
                fixedDecimalScale={false}
                value={this.state.lineItemTemplate.value}
                className={this.state.lineItemTemplateAmountErrors ? 'form-control mb-3 input-error' : 'form-control mb-3'}
                placeholder="$0.00"
                onValueChange={(values) => this.handleCategoryAmount('value', values.value)}
                readOnly={false}
              />
            </div>
            <div className="col-sm-6">
              <input
                value={this.state.lineItemTemplate.quantity}
                onKeyPress={this.onlyPositiveNumbers}
                onChange={(e) => this.handleChangeQuantity(e)}
                type="text"
                className={
                  this.state.lineItemTemplateQuantityErrors ? 'form-control mb-3 input-error' : 'form-control mb-3'
                }
                placeholder="Quantity"
                maxLength={8}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <textarea
                rows={2}
                value={this.state.lineItemTemplate.description}
                onChange={(e) => this.handleCategoryTextChange('description', e)}
                type="text"
                className="form-control mb-1"
                placeholder="Description (optional)"
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col">
              <button className="btn full-w btn-light" type="button" onClick={() => this.props.close()}>
                Cancel
              </button>
            </div>
            {this.props.action === 'add' && (
              <div className="col">
                <button className="btn full-w btn-primary" type="button" onClick={() => this.addLineItem()}>
                  Add
                </button>
              </div>
            )}
            {this.props.action === 'edit' && (
              <div className="col">
                <button className="btn full-w btn-primary" type="button" onClick={() => this.editLineItem()}>
                  Edit
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ItemModal;
