import React from 'react';
import { inject, observer } from 'mobx-react';
import { BiPauseCircle, BiCheckCircle, BiCheckDouble } from 'react-icons/bi';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { RenderCurrency } from '../../components/filters/RenderCurrency';
import { RenderCustomerInformation } from '../../components/filters/RenderCustomerInformation';
import { RenderCustomFields } from '../../components/filters/RenderCustomFields';
import { RenderFeeAmount } from '../../components/filters/RenderFeeAmount';
import { RenderFrequency } from '../../components/filters/RenderFrequency';
import { RenderNetAmount } from '../../components/filters/RenderNetAmount';
import { RenderPaymentInformation } from '../../components/filters/RenderPaymentInformation';
import { RenderTotalAmount } from '../../components/filters/RenderTotalAmount';
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'filter')
@observer
class ViewPartialSubscriptions extends React.Component {

    render() {
        return (<>
                <div className='row'>
                    <p><small>Next Draft On</small></p>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <DatePicker
                            customInput={
                                <div className="form-floating">
                                    <input 
                                        id="nextDraftOnCalendar" 
                                        name="nextDraftOnCalendar" 
                                        readOnly 
                                        autoComplete="off" 
                                        className={"form-control input-calendar"} 
                                        value={(this.props.filter.stateDate.startDateNextDraftOn ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateNextDraftOn) + ' → ' : '') + (this.props.filter.stateDate.endDateNextDraftOn ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateNextDraftOn) : '')} 
                                        placeholder="Start Date to End Date" 
                                    />
                                    <label htmlFor="nextDraftOnCalendar">Start Date to End Date</label>
                                </div>
                            }
                            selected={ this.props.filter.stateDate.startDateDate ? this.props.filter.stateDate.startDateDate : new Date()}
                            onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateNextDraftOn', 'endDateNextDraftOn', 'nextDate')}
                            startDate={this.props.filter.stateDate.startDateNextDraftOn}
                            endDate={this.props.filter.stateDate.endDateNextDraftOn}
                            selectsRange={true}
                            monthsShown={2}
                            shouldCloseOnSelect={this.props.filter.stateDate.endDateNextDraftOn !== null ? false : true}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <NumberFormat
                                format={'####'}
                                thousandsGroupStyle="thousand"
                                displayType="input"
                                type="text"
                                allowNegative={false}
                                value={this.props.filter.filterText.cycles ? this.props.filter.filterText.cycles : ''}
                                className="form-control"
                                placeholder={"# Remaining"}
                                onValueChange={(values) => this.props.filter.handleCaptureTextFilterObject('cycles(eq)', values, 'cycles')}
                                onKeyDown={(e) => this.props.filter.handleKeyDownObject('cycles(eq)', e, 'cycles')}
                                readOnly={this.props.filter.filterCheck.remainingCheckCanceled ? true : false}
                            />
                            <label>{"# Remaining"}</label>
                        </div>
                    </div>                                        
                </div>
                <div className='mb-4'>
                    <RenderFrequency />                                                             
                </div>
                <div>
                    <p><small>Status</small></p>
                    <div className='section-status mb-3'>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]) ? "badge bg-primary" : "badge text-primary"} onClick={() => this.props.filter.handleStatus('status(in)', 1, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[1]))}><BiCheckCircle /> Active</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]) ? "badge bg-warning" : "badge text-warning"} onClick={() => this.props.filter.handleStatus('status(in)', 0, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[0]))}><BiPauseCircle /> Paused</span>
                        <span className={(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[99]) ? "badge bg-success" : "badge text-success"} onClick={() => this.props.filter.handleStatus('status(in)', 99, !(Object.keys(this.props.filter.filterStatus).length > 0 && this.props.filter.filterStatus[99]))}><BiCheckDouble /> Completed</span>
                    </div>
                </div>
                <div className='mb-2'>
                    <RenderNetAmount />
                </div>
                <ContainerShowMoreLess>
                    <div>
                        <hr className='mt-1 mb-4'></hr>
                        <RenderFeeAmount />
                        <RenderTotalAmount />
                        <div className='row'>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <p><small>Start Date</small></p>
                                <DatePicker
                                    customInput={
                                        <div className="form-floating">
                                            <input 
                                                id="startDateCalendar" 
                                                name="startDateCalendar" 
                                                readOnly 
                                                autoComplete="off" 
                                                className={"form-control input-calendar"} 
                                                value={(this.props.filter.stateDate.startDateStartDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateStartDate) + ' → ' : '') + (this.props.filter.stateDate.endDateStartDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateStartDate) : '')} 
                                                placeholder="Start Date to End Date" 
                                            />
                                            <label htmlFor="startDateCalendar">Start Date to End Date</label>
                                        </div>
                                    }
                                    selected={ this.props.filter.stateDate.startDateStartDate ? this.props.filter.stateDate.startDateStartDate : new Date()}
                                    onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateStartDate', 'endDateStartDate', 'startDate')}
                                    startDate={this.props.filter.stateDate.startDateStartDate}
                                    endDate={this.props.filter.stateDate.endDateStartDate}
                                    selectsRange={true}
                                    monthsShown={2}
                                    shouldCloseOnSelect={this.props.filter.stateDate.endDateStartDate !== null ? false : true}
                                />
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <p><small>End Date</small></p>
                                <DatePicker
                                    customInput={
                                        <div className="form-floating">
                                            <input 
                                                id="endDateCalendar" 
                                                name="endDateCalendar" 
                                                readOnly 
                                                autoComplete="off" 
                                                className={"form-control input-calendar"} 
                                                value={(this.props.filter.stateDate.startDateEndDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateEndDate) + ' → ' : '') + (this.props.filter.stateDate.endDateEndDate ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateEndDate) : '')} 
                                                placeholder="Start Date to End Date" 
                                            />
                                            <label htmlFor="endDateCalendar">Start Date to End Date</label>
                                        </div>
                                    }
                                    selected={ this.props.filter.stateDate.startDateEndDate ? this.props.filter.stateDate.startDateEndDate : new Date()}
                                    onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateEndDate', 'endDateEndDate', 'endDate')}
                                    startDate={this.props.filter.stateDate.startDateEndDate}
                                    endDate={this.props.filter.stateDate.endDateEndDate}
                                    selectsRange={true}
                                    monthsShown={2}
                                    shouldCloseOnSelect={this.props.filter.stateDate.endDateEndDate !== null ? false : true}
                                    popperModifiers={{
                                        preventOverflow: {
                                            enabled: true,
                                        },
                                        }}
                                >
                                <div className="linkCalendar">
                                    <div className="icheck-primary">
                                        <input type="checkbox" value="true" id="checkUntilCancelled" checked={this.props.filter.filterSource.checkUntilCancelled} onChange={(e) => this.props.filter.handleCheckUntilCancelled(e, 'untilcancelled(eq)')} />
                                        <label htmlFor="checkUntilCancelled">Until Cancelled</label>
                                    </div>
                                </div>
                                </DatePicker>
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <p><small>Create On</small></p>
                                <DatePicker
                                    customInput={
                                        <div className="form-floating">
                                            <input 
                                                id="createOnCalendar" 
                                                name="createOnCalendar" 
                                                readOnly 
                                                autoComplete="off" 
                                                className={"form-control input-calendar"} 
                                                value={(this.props.filter.stateDate.startDateCreateOn ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateCreateOn) + ' → ' : '') + (this.props.filter.stateDate.endDateCreateOn ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateCreateOn) : '')} 
                                                placeholder="Start Date to End Date" 
                                            />
                                            <label htmlFor="createOnCalendar">Start Date to End Date</label>
                                        </div>
                                    }
                                    selected={ this.props.filter.stateDate.startDate ? this.props.filter.stateDate.startDate : new Date()}
                                    onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateCreateOn', 'endDateCreateOn', 'createdAt')}
                                    startDate={this.props.filter.stateDate.startDateCreateOn}
                                    endDate={this.props.filter.endDateCreateOn}
                                    selectsRange={true}
                                    monthsShown={2}
                                    shouldCloseOnSelect={this.props.filter.stateDate.endDateCreateOn !== null ? false : true}
                                />
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <p><small>Last Update On</small></p>
                                <DatePicker
                                    customInput={
                                        <div className="form-floating">
                                            <input 
                                                id="lastUpdateOnCalendar" 
                                                name="lastUpdateOnCalendar" 
                                                readOnly 
                                                autoComplete="off" 
                                                className={"form-control input-calendar"} 
                                                value={(this.props.filter.stateDate.startDateLastUpdateOn ? this.props.reports.dateStringFormat(this.props.filter.stateDate.startDateLastUpdateOn) + ' → ' : '') + (this.props.filter.stateDate.endDateLastUpdateOn ? this.props.reports.dateStringFormat(this.props.filter.stateDate.endDateLastUpdateOn) : '')} 
                                                placeholder="Start Date to End Date" 
                                            />
                                            <label htmlFor="lastUpdateOnCalendar">Start Date to End Date</label>
                                        </div>
                                    }
                                    selected={ this.props.filter.stateDate.startDateLastUpdateOn ? this.props.filter.stateDate.startDateLastUpdateOn : new Date()}
                                    onChange={date => this.props.filter.handleChangeCalendarState(date, 'startDateLastUpdateOn', 'endDateLastUpdateOn', 'updatedOn')}
                                    startDate={this.props.filter.stateDate.startDateLastUpdateOn}
                                    endDate={this.props.filter.endDateLastUpdateOn}
                                    selectsRange={true}
                                    monthsShown={2}
                                    shouldCloseOnSelect={this.props.filter.stateDate.endDateLastUpdateOn !== null ? false : true}
                                    popperModifiers={{
                                        preventOverflow: {
                                            enabled: true,
                                        },
                                        }}
                                />
                            </div>                                
                        </div>
                    </div>
                </ContainerShowMoreLess>
                <div>
                    <RenderPaymentInformation />
                </div>
                <div>
                    <RenderCustomerInformation />
                </div>
                <div>
                    <h5 className='title'>Processing Information</h5>
                    <div className='row'>                            
                        <div className="col-sm-6 col-xs-12 mb-4 mt-4">
                            <div className="form-floating">
                                <input name="autopayId" id="autopayId" className={"form-control"} placeholder="Autopay ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('subId(eq)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('subId(eq)', e)} value={this.props.filter.filterText.autopayId ? this.props.filter.filterText.autopayId : ''} />
                                <label htmlFor="autopayId">Autopay ID</label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <RenderCurrency />
                        </div>                            
                    </div>
                </div>             
                { Array.isArray(this.props.filter.customFields) && <RenderCustomFields /> }
            </>);
    }
}

export { ViewPartialSubscriptions };