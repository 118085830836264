import React from "react";
import { inject, observer } from "mobx-react";
import {
  BiEdit,
  BiDollarCircle,
  BiWallet,
  BiListCheck,
  BiIdCard,
  BiChevronLeft,
} from "react-icons/bi";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import $ from "jquery";
import "jquery-ui-bundle";
import { Accordion, Card, Tooltip, Alert } from "react-bootstrap";

import { PaymentMethodCCForm } from "../../components/PaymentMethodCCForm";
import { PaymentMethodECheck } from "../../components/PaymentMethodECheck";
import { ApplePayButtonType } from "../../components/ApplePayButtonType";
import { ApplePayButtonStyle } from "../../components/ApplePayButtonStyle";
import { ApplePayButtonLanguage } from "../../components/ApplePayButtonLanguage";
import { PaymentMethodsAccepted } from "../../components/PaymentMethodsAccepted";
import { Message } from "../../components/Message";
import { PayorFields } from "../../components/PayorFields";
import { TopBar } from "../../components/TopBar";
import { PaymenPagePublishActions } from "../../components/PaymenPagePublishActions";
import { PaymentPageName } from "../../components/PaymentPageName";
import { PaymenPageSections } from "../../components/PaymenPageSections";
import { Layout } from "../../components/Layout";

import { toJS } from "mobx";
import ReactDOM from "react-dom";
import Switch from "react-switch";
import { IMaskInput } from "react-imask";

import { Link } from "react-router-dom";
import { PaymentPageLogo } from "../../components/PaymentPageLogo";
import { PayabliStorageManager } from "../../api/localStorageManager";

import { Helmet } from "react-helmet";
import NumberFormat from "react-number-format";
import { LocationSearchInput } from "../../components/LocationSearchInput";

@inject("store", "global", "entry", "vTerminal")
@observer
class Builder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputQtyError: false,
      hasCards: false,
      hasEChecks: false,
      hasWallets: false,
      mode: "new",
      payorFields: [],
    };

    this.sortablePayer = React.createRef();
    this.sortableCategories = React.createRef();

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleAutopayFrequency = this.handleAutopayFrequency.bind(this);
    this.disableAutopay = this.disableAutopay.bind(this);
    this.disableTitle = this.disableTitle.bind(this);
    this.disableMessageBeforePaying =
      this.disableMessageBeforePaying.bind(this);
    this.disableNotes = this.disableNotes.bind(this);
    this.handleAutopayFinish = this.handleAutopayFinish.bind(this);
    this.handlePaymentMethods = this.handlePaymentMethods.bind(this);
    this.handleAllPaymentMethods = this.handleAllPaymentMethods.bind(this);
    this.disablePayer = this.disablePayer.bind(this);
    this.addNewPayorField = this.addNewPayorField.bind(this);
    this.disableContactUs = this.disableContactUs.bind(this);
    this.handleContactUsPaymentIcons =
      this.handleContactUsPaymentIcons.bind(this);
    this.testDataJson = this.testDataJson.bind(this);
    this.disableReview = this.disableReview.bind(this);
    this.disableLogo = this.disableLogo.bind(this);
    this.addNewAmountCategoryField = this.addNewAmountCategoryField.bind(this);
    this.removeAmountCategory = this.removeAmountCategory.bind(this);
    this.handleCategoryTextChange = this.handleCategoryTextChange.bind(this);
    this.handleAmountCategoryType = this.handleAmountCategoryType.bind(this);
    this.handleCategoryShowDescription =
      this.handleCategoryShowDescription.bind(this);
    this.handleCategoryOptionalPay = this.handleCategoryOptionalPay.bind(this);
    this.handleCategoryQtySubstract =
      this.handleCategoryQtySubstract.bind(this);
    this.handleCategoryQtyAdd = this.handleCategoryQtyAdd.bind(this);
    this.handleCategoryQtyInput = this.handleCategoryQtyInput.bind(this);
    this.handleCategoryQtyInputWithoutZero =
      this.handleCategoryQtyInputWithoutZero.bind(this);
    this.addCustomFields = this.addCustomFields.bind(this);
    this.prevent = this.prevent.bind(this);
    this.focus = this.focus.bind(this);
    this.handleGoogleAutocompleteBillingAddress =
      this.handleGoogleAutocompleteBillingAddress.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleTextChangeAutopayStartDate =
      this.handleTextChangeAutopayStartDate.bind(this);
    this.validateDaysRanges = this.validateDaysRanges.bind(this);
    this.disableDigitalWallets = this.disableDigitalWallets.bind(this);
  }

  prevent(e) {
    e.preventDefault();
  }
  handleGoogleAutocompleteBillingAddress(
    billingAddress1,
    billingAddress2,
    billingCity,
    billingState,
    billingZip,
    billingCountry
  ) {
    this.setState({
      billingAddress1: billingAddress1 ? billingAddress1 : "",
      billingAddress2: billingAddress2 ? billingAddress2 : "",
      billingCity: billingCity ? billingCity : "",
      billingState: billingState ? billingState : "",
      billingZip: billingZip ? billingZip : "",
      billingCountry: billingCountry ? billingCountry : "",
    });
  }
  disableDigitalWallets() {
    return (
      this.props.store.paymentPage.autopay.enabled ||
      !this.state.hasWallets ||
      !this.props.store.paymentPage.paymentMethods.methods.applePay
    );
  }
  disableAutopay(checked) {
    this.props.store.disableAutopay(checked);
  }

  disablePayer(checked) {
    this.props.store.disablePayer(checked);
  }

  disableNotes(checked) {
    this.props.store.disableNotes(checked);
  }

  disableTitle(checked) {
    this.props.store.disableTitle(checked);
  }

  disableLogo(checked) {
    this.props.store.disableLogo(checked);
  }

  removeAmountCategory(event) {
    this.props.store.removeAmountCategory(event);
  }

  disableContactUs(checked) {
    this.props.store.disableContactUs(checked);
  }

  disableReview(checked) {
    this.props.store.disableReview(checked);
  }

  disableMessageBeforePaying(checked) {
    this.props.store.disableMessageBeforePaying(checked);
  }

  handleAutopayFrequency(event) {
    this.props.store.handleAutopayFrequency(event);
  }

  handleAmountCategoryType(event) {
    this.props.store.handleAmountCategoryType(event);
  }

  handleCategoryQtySubstract(event) {
    this.props.store.handleCategoryQtySubstract(event);
  }

  handleCategoryQtyAdd(event) {
    this.props.store.handleCategoryQtyAdd(event);
  }

  handleCategoryQtyInput(event) {
    if (!this.state.inputQtyError) {
      this.props.store.handleCategoryQtyInput(event);
    }
  }

  handleCategoryQtyInputWithoutZero(e) {
    if (e.target.value.length === 0) {
      this.setState({ inputQtyError: true });
    } else {
      this.setState({ inputQtyError: false });
    }
  }

  handleCategoryShowDescription(event) {
    this.props.store.handleCategoryShowDescription(event);
  }

  handleCategoryOptionalPay(event) {
    this.props.store.handleCategoryOptionalPay(event);
  }

  handleAutopayFinish(event) {
    this.props.store.handleAutopayFinish(event);
  }

  handleTextChange(block, key, event) {
    this.props.store.handleTextChange(block, key, event);
  }

  handleKeyPress(event) {
    const keyPressed = event.key;
    const validCharacters = "0123456789-,";
    if (!validCharacters.includes(keyPressed)) {
      event.preventDefault();
    }
  }

  handleTextChangeAutopayStartDate(event) {
    const daysRange = event.target.value,
      validRanges = this.validateDaysRanges(daysRange);
    let autopayStartDateErrorTemp = false;
    if (daysRange !== "") {
      autopayStartDateErrorTemp = !validRanges;
    }
    this.setState({ autopayStartDateError: autopayStartDateErrorTemp });
    this.props.store.handleTextChange("autopay", "startDate", event);
  }

  validateDaysRanges = (daysRange) => {
    const ranges = daysRange.split(",");
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i].trim().split("-");
      const start = parseInt(range[0]);
      const end = parseInt(range[1]);
      if (isNaN(start) || isNaN(end)) {
        return null;
      }
      if (start < 1 || start > 30 || end < 1 || end > 30) {
        return null;
      }
      for (let j = i + 1; j < ranges.length; j++) {
        const otherRange = ranges[j].trim().split("-");
        const otherStart = parseInt(otherRange[0]);
        const otherEnd = parseInt(otherRange[1]);
        if (
          (start >= otherStart && start <= otherEnd) ||
          (end >= otherStart && end <= otherEnd)
        ) {
          return null;
        }
      }
    }
    return ranges;
  };

  handleCategoryTextChange(field, key, event) {
    this.props.store.handleCategoryTextChange(field, key, event);
  }

  handleCategoryTextChangeMask(field, key, value) {
    this.props.store.handleCategoryTextChangeMask(field, key, value);
  }

  handlePaymentMethods(event) {
    this.props.store.handlePaymentMethods(event);
  }

  handleAllPaymentMethods() {
    this.props.store.handleAllPaymentMethods();
  }

  handleContactUsPaymentIcons() {
    this.props.store.handleContactUsPaymentIcons();
  }

  addNewPayorField() {
    return;
    //this.props.store.addNewPayorField();
  }

  addNewAmountCategoryField() {
    this.props.store.addNewAmountCategoryField();
  }

  testDataJson() {
    console.log(this.props.store.testDataJson());
  }

  focus(e) {
    e.target.focus();
    e.target.setSelectionRange(0, 1000000000);
  }

  sortFields(inputArr) {
    let len = inputArr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len - 1; j++) {
        if (inputArr[j][1].order > inputArr[j + 1][1].order) {
          let tmp = inputArr[j];
          inputArr[j] = inputArr[j + 1];
          inputArr[j + 1] = tmp;
        }
      }
    }
    return inputArr;
  }

  renderFields() {
    var loopData = [];
    var objThis = this;
    var payerFieldsSort = this.sortFields(
      Object.entries(toJS(this.state.payorFields))
    );

    payerFieldsSort.forEach(function (item, index) {
      if (item[1].display === true || item[1].identifier === true) {
        loopData.push(
          (item[1].validation !== "address" && (
            <div
              key={"keyPayer" + item[0]}
              className={"col-sm-" + item[1].width}
            >
              <div className="form-floating mb-3">
                <input
                  name={"payorFieldInput" + item[0]}
                  type="text"
                  className="form-control"
                  placeholder={item[1].label}
                />
                <label htmlFor={"payorFieldInput" + item[0]}>
                  {item[1].label}
                </label>
              </div>
            </div>
          )) || (
            <div
              key={"keyPayer" + item[0]}
              className={"col-sm-" + item[1].width}
            >
              <LocationSearchInput
                placeholder={item[1].label}
                onChange={objThis.handleGoogleAutocompleteBillingAddress}
                onlyAddress={true}
                // handleTextChange={this.handleTextChangeMask}
                // address={this.state.billingAddress1}
                // address2={this.state.billingAddress2}
                // city={this.state.billingCity}
                // state={this.state.billingState}
                // zipcode={this.state.billingZip}
                // country={this.state.billingCountry}
                handleTextChangeKeys={[item[0]]}
              />
            </div>
          )
        );
      }
    });
    return loopData;
  }

  renderAmountCategories() {
    var loopData = [];
    var categoriesConfigSort = this.sortFields(
      Object.entries(toJS(this.props.store.amountCategories))
    );
    var categoriesCount = categoriesConfigSort.length;
    var reactObject = this;
    categoriesConfigSort.forEach(function (item, index) {
      const PopoverEditCategory = (
        <Popover>
          <Popover.Content>
            <div className="row mb-2">
              <div className="col">
                <h6>Price Field</h6>
              </div>
            </div>
            <label className="header">Select amount type</label>

            <div className="mb-3">
              <div className="icheck-primary">
                <input
                  name={"amountCategoryType" + item[0]}
                  type="radio"
                  id={"amountCategoryTypeCustomer" + item[0]}
                  value="customer"
                  onChange={(e) => reactObject.handleAmountCategoryType(e)}
                  defaultChecked={item[1].type === "customer"}
                />
                <label htmlFor={"amountCategoryTypeCustomer" + item[0]}>
                  Customer decides amount to pay
                </label>
                {item[1].type === "customer" && (
                  <label className="small small-grey">
                    This will create an empty field that customers can enter any
                    amount they wish to pay.
                  </label>
                )}
              </div>

              <div className="icheck-primary">
                <input
                  name={"amountCategoryType" + item[0]}
                  type="radio"
                  id={"amountCategoryTypeFixed" + item[0]}
                  value="fixed"
                  onChange={(e) => reactObject.handleAmountCategoryType(e)}
                  defaultChecked={item[1].type === "fixed"}
                />
                <label htmlFor={"amountCategoryTypeFixed" + item[0]}>
                  Fixed amount
                </label>
                {item[1].type === "fixed" && (
                  <label className="small small-grey">
                    Customers must pay the specific amount for this price field.
                  </label>
                )}
              </div>

              <div className="icheck-primary">
                <input
                  name={"amountCategoryType" + item[0]}
                  type="radio"
                  id={"amountCategoryTypeQuantity" + item[0]}
                  value="quantity"
                  onChange={(e) => reactObject.handleAmountCategoryType(e)}
                  defaultChecked={item[1].type === "quantity"}
                />
                <label htmlFor={"amountCategoryTypeQuantity" + item[0]}>
                  Fixed item amount with quantity
                </label>
                {item[1].type === "quantity" && (
                  <label className="small small-grey">
                    Facilitate your payers with quantity selection of an item
                    with a fixed cost.
                  </label>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                <input
                  onChange={(e) =>
                    reactObject.handleCategoryTextChange(item[0], "label", e)
                  }
                  value={item[1].label}
                  type="text"
                  className="form-control mb-3"
                  placeholder="Item Name"
                />
              </div>
              <div className="col-5">
                <NumberFormat
                  onFocus={(e) => reactObject.focus(e)}
                  thousandsGroupStyle="thousand"
                  decimalSeparator="."
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={item[1].value}
                  placeholder="Amount"
                  className="form-control mb-3 input-money"
                  onValueChange={(values) =>
                    reactObject.handleCategoryTextChangeMask(
                      item[0],
                      "value",
                      values.value
                    )
                  }
                />
              </div>
              {item[1].showDescription && (
                <div className="col-12">
                  <input
                    onChange={(e) =>
                      reactObject.handleCategoryTextChange(
                        item[0],
                        "description",
                        e
                      )
                    }
                    value={item[1].description}
                    type="text"
                    className="form-control mb-3"
                    placeholder="Item description (optional)"
                  />
                </div>
              )}
            </div>

            <div className="icheck-primary">
              <input
                type="checkbox"
                id={"amountCategoryCheckDescription" + item[0]}
                defaultChecked={item[1].showDescription}
                onChange={(e) => reactObject.handleCategoryShowDescription(e)}
              />
              <label htmlFor={"amountCategoryCheckDescription" + item[0]}>
                Add Description
              </label>
            </div>
            {Array.isArray(
              reactObject.props.store?.paymentPage?.amount?.categories
            ) &&
              reactObject.props.store.paymentPage.amount.categories.length >
                1 && (
                <div className="icheck-primary">
                  <input
                    type="checkbox"
                    id={"amountCategoryCheckOptional" + item[0]}
                    defaultChecked={item[1].optionalPay}
                    onChange={(e) => reactObject.handleCategoryOptionalPay(e)}
                  />
                  <label htmlFor={"amountCategoryCheckOptional" + item[0]}>
                    Optional Item to Pay
                  </label>
                  {item[1].optionalPay && (
                    <label className="small small-grey">
                      Customer can select or unselect this amount field when
                      deciding what to pay.
                    </label>
                  )}
                </div>
              )}
            <div className="popover-footer text-right">
              <button
                className="btn btn-light btn-sm"
                type="button"
                onClick={() => document.body.click()}
              >
                Done
              </button>
            </div>
          </Popover.Content>
        </Popover>
      );

      if (categoriesCount > 1) {
        loopData.push(
          <li
            key={"keyAmountCategory" + item[0]}
            className="ui-state-default dragable-panel"
            id={"blockcategory" + item[0]}
          >
            <div className="category-icon">
              <OverlayTrigger
                rootClose
                key={"keyPopoverAmountCategory" + item[0]}
                trigger="click"
                placement="right"
                overlay={PopoverEditCategory}
              >
                <BiEdit className="icon-edit" />
              </OverlayTrigger>
              <button
                className="remove-icon-btn"
                type="button"
                onClick={(e) => reactObject.removeAmountCategory(e)}
                id={"categoryRemove" + item[0]}
              >
                &nbsp;
              </button>
            </div>

            <div className="form-floating form-floating-money">
              <NumberFormat
                onFocus={(e) => reactObject.focus(e)}
                thousandsGroupStyle="thousand"
                decimalSeparator="."
                displayType="input"
                type="text"
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                value={item[1].value}
                placeholder={item[1].label}
                className="form-control input-money"
                onValueChange={(values) =>
                  reactObject.handleCategoryTextChangeMask(
                    item[0],
                    "value",
                    values.value
                  )
                }
                readOnly={
                  item[1].type === "fixed" || item[1].type === "quantity"
                    ? true
                    : false
                }
              />

              <label>{item[1].label}</label>
            </div>
            {item[1].optionalPay && (
              <div className="icheck-primary optional-pay">
                <input
                  type="checkbox"
                  id={"amountCategoryOptionalPay" + item[0]}
                />
                <label htmlFor={"amountCategoryOptionalPay" + item[0]}></label>
              </div>
            )}

            {item[1].type === "quantity" && (
              <div
                className={
                  item[1].optionalPay
                    ? "input-group input-group-qty input-group-qty-mr"
                    : "input-group input-group-qty"
                }
                style={{
                  width: item[1].quantity.toString().length + 3 + "em",
                }}
              >
                <button
                  id={"amountCategoryQtySubstract" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtySubstract(e)}
                >
                  -
                </button>
                <IMaskInput
                  id={"amountCategoryQtyInput" + item[0]}
                  mask={"00000000"}
                  className={"form-control"}
                  value={String(item[1].quantity)}
                  onChange={(e) => reactObject.handleCategoryQtyInput(e)}
                  onBlur={(e) =>
                    reactObject.handleCategoryQtyInputWithoutZero(e)
                  }
                />
                <button
                  id={"amountCategoryQtyAdd" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtyAdd(e)}
                >
                  +
                </button>
              </div>
            )}

            {item[1].description && item[1].showDescription ? (
              <p className="small-grey-m0 mt-1">{item[1].description}</p>
            ) : (
              ""
            )}
          </li>
        );
      } else {
        loopData.push(
          <li
            key={"keyAmountCategory" + item[0]}
            className="ui-state-default dragable-panel"
            id={"blockcategory" + item[0]}
          >
            <div className="category-icon">
              <OverlayTrigger
                rootClose
                key={"keyPopoverAmountCategory" + item[0]}
                trigger="click"
                placement="right"
                overlay={PopoverEditCategory}
              >
                <BiEdit className="icon-edit" />
              </OverlayTrigger>
              {/*<button className="remove-icon-btn" type="button" onClick={(e) => reactObject.removeAmountCategory(e)} id={"categoryRemove"+item[0]}>&nbsp;</button>*/}
            </div>

            <NumberFormat
              onFocus={(e) => reactObject.focus(e)}
              thousandsGroupStyle="thousand"
              prefix="$"
              decimalSeparator="."
              displayType="input"
              type="text"
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              value={item[1].value}
              placeholder={"$"}
              className="big-amount"
              onValueChange={(values) =>
                reactObject.handleCategoryTextChangeMask(
                  item[0],
                  "value",
                  values.value
                )
              }
              readOnly={
                item[1].type === "fixed" || item[1].type === "quantity"
                  ? true
                  : false
              }
            />

            {item[1].type === "quantity" && (
              <div
                className={
                  item[1].optionalPay
                    ? "input-group input-group-qty-big input-group-qty-mr"
                    : "input-group input-group-qty-big"
                }
                style={{
                  width: item[1].quantity.toString().length + 3 + "em",
                }}
              >
                <button
                  id={"amountCategoryQtySubstract" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtySubstract(e)}
                >
                  -
                </button>
                <IMaskInput
                  id={"amountCategoryQtyInput" + item[0]}
                  mask={"00000000"}
                  className={"form-control"}
                  value={String(item[1].quantity)}
                  onChange={(e) => reactObject.handleCategoryQtyInput(e)}
                  onKeyPress={(e) =>
                    reactObject.handleCategoryQtyInputWithoutZero(e)
                  }
                  onBlur={(e) =>
                    reactObject.handleCategoryQtyInputWithoutZero(e)
                  }
                />
                <button
                  id={"amountCategoryQtyAdd" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtyAdd(e)}
                >
                  +
                </button>
              </div>
            )}
          </li>
        );
      }
    });

    return loopData;
  }

  async componentDidMount() {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();

    this.props.global.protect(this.props.history);

    this.props.store.normalizePayorFields();

    this.props.global.setLoading(true);

    let entry =
      encryptStorage &&
      encryptStorage.getItem(
        `${PayabliStorageManager.getEntryName()}_pEntry`
      ) &&
      encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)
        .pEntry
        ? encryptStorage.getItem(
            `${PayabliStorageManager.getEntryName()}_pEntry`
          ).pEntry
        : 0;
    this.props.vTerminal
      .getPaymentPageFromApi()
      .then((res) => {
        this.handleCredrencialEntry();
        this.props.global.setLoading(false);
      })
      .catch((error) => {
        this.props.global.setLoading(false);
      });
    var MainStoreObj = this.props.store;
    var sortableObj = $(ReactDOM.findDOMNode(this.sortablePayer.current));
    sortableObj.sortable({
      stop: function (event, ui) {
        var idsInOrder = sortableObj.sortable("toArray");
        MainStoreObj.updateBlocksOrder(idsInOrder);
      },
    });

    var sortableObjCategories = $(
      ReactDOM.findDOMNode(this.sortableCategories.current)
    );
    sortableObjCategories.sortable({
      stop: function (event, ui) {
        var idsInOrder = sortableObjCategories.sortable("toArray");
        MainStoreObj.updateCategoryBlocksOrder(idsInOrder);
      },
    });

    var subdomain = this.props.match.params.subdomain;
    if (subdomain && this.props.store.getSaveAction === "edit") {
      // this.props.store.setSaveAction('edit');
      if (this.props.store.paymentPage.name === "") {
        this.props.history.push("/paymentpages");
      }
    } else {
      this.props.store.setSaveAction("new");
      this.props.store.setPaymentPageSubdomain(Date.now().toString());
      this.props.store.setPageName("Payment Page");
    }
    if (subdomain) {
      this.props.store
        .getPaymentPageFromApi(subdomain)
        .then((res) => {
          this.props.store.setSaveAction("edit");
        })
        .catch((error) => {});
    }
    try {
      const res = await this.props.entry.getPaypointSettings(entry);
      if (res.customFields) {
        await this.addCustomFields(res.customFields, res.identifiers);
      }
      this.props.global.setLoading(false);
    } catch (error) {
      this.props.global.setLoading(false);
    }
    this.setState({
      payorFields: this.props.store.payerFields,
    });

    this.props.store.setShowPaymentPageNameError(false);
  }

  addCustomFields(fields, identifiers) {
    let store = this.props.store;
    let customFields = Object.entries(
      toJS(this.props.store.paymentPage.payor.fields)
    );

    if (identifiers) {
      for (let i = 0; i < identifiers.length; i++) {
        //normalizing identifier name
        switch (identifiers[i].key) {
          case "customernumber":
            identifiers[i].key = "customerNumber";
            break;
          case "firstname":
            identifiers[i].key = "firstName";
            break;
          case "lastname":
            identifiers[i].key = "lastName";
            break;
          case "email":
            identifiers[i].key = "billingEmail";
            break;
          case "phone":
            identifiers[i].key = "billingPhone";
            break;
          default:
            break;
        }
      }
    }

    for (let i = 0; i < identifiers.length; i++) {
      store.setPayorIdentifier(identifiers[i].key, true);
    }

    fields.forEach(function (item, i) {
      let found = false;
      let identifier = false;

      if (identifiers) {
        for (let i = 0; i < identifiers.length; i++) {
          if (identifiers[i].key === item.key) {
            identifier = true;
            break;
          }
        }
      }

      for (let i = 0; i < customFields.length; i++) {
        if (
          customFields[i][1].name ===
          "additional_" + item.key.split(" ").join("_")
        ) {
          found = true;
          break;
        }
      }
      if (!found) {
        if (store.getSaveAction !== "edit") {
          store.addAdditionalField(item, identifier);
        } else {
          store.addAdditionalField(item, identifier, false);
        }
      }
    });
  }

  handleCredrencialEntry() {
    let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions,
      tempHasCards = this.state.hasCards,
      tempHasEChecks = this.state.hasEChecks,
      tempHasWallets = this.state.hasWallets;
    if (
      credentialsPermissions.card.onetime ||
      credentialsPermissions.card.recurring
    ) {
      tempHasCards = true;
    }
    if (credentialsPermissions.ach.recurring) {
      tempHasEChecks = true;
    }
    if (credentialsPermissions.wallet.onetime) {
      tempHasWallets = true;
    }
    this.setState({
      hasCards: tempHasCards,
      hasEChecks: tempHasEChecks,
      hasWallets: tempHasWallets,
    });
  }

  render() {
    const btnTheme = {
      backgroundColor: this.props.store.paymentPageSettings.color,
    };

    const linkTheme = {
      color: this.props.store.paymentPageSettings.color,
    };

    const bgTheme = {
      backgroundColor: this.props.store.paymentPageSettings.color,
    };

    const PopoverEditTitleDescription = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col">
              <h6>Page Header</h6>
            </div>
            <div className="col text-right">
              <Switch
                onChange={this.disableTitle}
                checked={this.props.store.paymentPage.page.enabled}
                onColor="#10A0E3"
                onHandleColor="#ffffff"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={38}
                className="react-switch"
              />
            </div>
          </div>
          <input
            name="header"
            value={this.props.store.paymentPage.page.header}
            onChange={(e) => this.handleTextChange("page", "header", e)}
            type="text"
            className="form-control mb-3"
            placeholder="Header"
          />
          <textarea
            rows="6"
            name="description"
            value={this.props.store.paymentPage.page.description}
            onChange={(e) => this.handleTextChange("page", "description", e)}
            className="form-control"
            placeholder="Description"
          ></textarea>

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const PopoverEditNotes = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col">
              <h6>Notes</h6>
            </div>
            <div className="col text-right">
              <Switch
                onChange={this.disableNotes}
                checked={this.props.store.paymentPage.notes.enabled}
                onColor="#10A0E3"
                onHandleColor="#ffffff"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={38}
                className="react-switch"
              />
            </div>
          </div>
          <input
            name="notesHeader"
            value={this.props.store.paymentPage.notes.header}
            onChange={(e) => this.handleTextChange("notes", "header", e)}
            type="text"
            className="form-control mb-3"
            placeholder="Header"
          />

          <textarea
            rows="5"
            name="notesPlaceholder"
            value={this.props.store.paymentPage.notes.placeholder}
            onChange={(e) => this.handleTextChange("notes", "placeholder", e)}
            className="form-control"
            placeholder="Placeholder"
          ></textarea>

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const PopoverEditPayButton = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col">
              <h6>Payment Button</h6>
            </div>
            <div className="col text-right"></div>
          </div>
          <label className="header">Edit Pay Button Label</label>
          <input
            name="payButton"
            value={
              this.props.store.paymentPage.paymentButton.label
                ? this.props.store.paymentPage.paymentButton.label
                : ""
            }
            onChange={(e) => this.handleTextChange("paymentButton", "label", e)}
            type="text"
            className="form-control mb-3"
            placeholder="Pay"
          />

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const PopoverEditLogo = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col">
              <h6>Branding</h6>
            </div>
            <div className="col text-right">
              <Switch
                onChange={this.disableLogo}
                checked={this.props.store.paymentPage.logo.enabled}
                onColor="#10A0E3"
                onHandleColor="#ffffff"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={38}
                className="react-switch"
              />
            </div>
          </div>

          <p className="small">
            Add your logo through the{" "}
            <Link to={"/" + PayabliStorageManager.getEntryName() + "/settings"}>
              Page Settings
            </Link>{" "}
            page to brand your organization.{" "}
          </p>

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const PopoverEditContact = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col">
              <h6>Contact Us info</h6>
            </div>
            <div className="col text-right">
              <Switch
                onChange={this.disableContactUs}
                checked={this.props.store.paymentPage.contactUs.enabled}
                onColor="#10A0E3"
                onHandleColor="#ffffff"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={38}
                className="react-switch"
              />
            </div>
          </div>

          <input
            name="contactUsHeader"
            value={this.props.store.paymentPage.contactUs.header}
            onChange={(e) => this.handleTextChange("contactUs", "header", e)}
            type="text"
            className="form-control mb-3"
            placeholder="Header"
          />
          <input
            name="contactUsEmail"
            value={this.props.store.paymentPage.contactUs.emailLabel}
            onChange={(e) =>
              this.handleTextChange("contactUs", "emailLabel", e)
            }
            type="text"
            className="form-control mb-3"
            placeholder="Support email"
          />
          <input
            name="contactUsPhone"
            value={this.props.store.paymentPage.contactUs.phoneLabel}
            onChange={(e) =>
              this.handleTextChange("contactUs", "phoneLabel", e)
            }
            type="text"
            className="form-control mb-3"
            placeholder="Support phone"
          />

          <div className="icheck-primary">
            <input
              type="checkbox"
              defaultChecked={
                this.props.store.paymentPage.contactUs.paymentIcons
              }
              onChange={this.handleContactUsPaymentIcons}
              id="paymentIcons"
            />
            <label htmlFor="paymentIcons">Show payment icons</label>
          </div>
          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const PopoverEditMessageBeforePaying = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col">
              <h6>Message Before Paying</h6>
            </div>
            <div className="col text-right">
              <Switch
                onChange={this.disableMessageBeforePaying}
                checked={
                  this.props.store.paymentPage.messageBeforePaying.enabled
                }
                onColor="#10A0E3"
                onHandleColor="#ffffff"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={38}
                className="react-switch"
              />
            </div>
          </div>

          <label className="header">Add message before paying</label>
          <p className="small small-grey">
            Ideas: Term & Conditions, settlement disclaimers, thank you note,
            promotional message.{" "}
          </p>

          <textarea
            rows="5"
            name="messageBeforePaying"
            value={this.props.store.paymentPage.messageBeforePaying.label}
            onChange={(e) =>
              this.handleTextChange("messageBeforePaying", "label", e)
            }
            className="form-control"
            placeholder="Enter messaging..."
          ></textarea>

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const PopoverEditReview = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col-9">
              <h6>Review & Send Payment</h6>
            </div>
            <div className="col text-right">
              <Switch
                onChange={this.disableReview}
                checked={this.props.store.paymentPage.review.enabled}
                onColor="#10A0E3"
                onHandleColor="#ffffff"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={38}
                className="react-switch"
              />
            </div>
          </div>

          <input
            name="reviewHeader"
            value={this.props.store.paymentPage.review.header}
            onChange={(e) => this.handleTextChange("review", "header", e)}
            type="text"
            className="form-control mb-3"
            placeholder="Header"
          />

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const PopoverEditAutopay = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col">
              <h6>Autopay</h6>
            </div>
            <div className="col text-right">
              <Switch
                onChange={this.disableAutopay}
                checked={this.props.store.paymentPage.autopay.enabled}
                onColor="#10A0E3"
                onHandleColor="#ffffff"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={38}
                className="react-switch"
              />
            </div>
          </div>
          <input
            name="autopayHeader"
            value={this.props.store.paymentPage.autopay.header}
            onChange={(e) => this.handleTextChange("autopay", "header", e)}
            type="text"
            className="form-control mb-3"
            placeholder="Header"
          />
          <label className="header">Start Date</label>
          <p className="small small-grey">
            Select date range(s) you want to offer
          </p>
          <input
            name="autopayStartDate"
            type="text"
            value={
              this.props.store.paymentPage.autopay.startDate
                ? this.props.store.paymentPage.autopay.startDate
                : ""
            }
            onChange={(e) => this.handleTextChangeAutopayStartDate(e)}
            onKeyPress={(e) => this.handleKeyPress(e)}
            className={
              this.state.autopayStartDateError
                ? "form-control mb-3 is-invalid"
                : "form-control mb-3"
            }
            placeholder="Example: 1-5,10-15,25-28"
          />
          <label className="header">Payment Frequency</label>
          <p className="small small-grey">
            Select frequency options you want to offer
          </p>

          <div className="row">
            <div className="col-6 mb-2">
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  defaultChecked={
                    this.props.store.paymentPage.autopay.frequency.onetime
                  }
                  onChange={this.handleAutopayFrequency}
                  id="onetime"
                />
                <label htmlFor="onetime">One Time</label>
              </div>
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  defaultChecked={
                    this.props.store.paymentPage.autopay.frequency.weekly
                  }
                  onChange={this.handleAutopayFrequency}
                  id="weekly"
                />
                <label htmlFor="weekly">Weekly</label>
              </div>
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  defaultChecked={
                    this.props.store.paymentPage.autopay.frequency.every2Weeks
                  }
                  onChange={this.handleAutopayFrequency}
                  id="every2Weeks"
                />
                <label htmlFor="every2Weeks">Every 2 weeks</label>
              </div>
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  defaultChecked={
                    this.props.store.paymentPage.autopay.frequency.monthly
                  }
                  onChange={this.handleAutopayFrequency}
                  id="monthly"
                />
                <label htmlFor="monthly">Monthly</label>
              </div>
            </div>
            <div className="col-6 mb-2">
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  defaultChecked={
                    this.props.store.paymentPage.autopay.frequency.every3Months
                  }
                  onChange={this.handleAutopayFrequency}
                  id="every3Months"
                />
                <label htmlFor="every3Months">Every 3 months</label>
              </div>
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  defaultChecked={
                    this.props.store.paymentPage.autopay.frequency.every6Months
                  }
                  onChange={this.handleAutopayFrequency}
                  id="every6Months"
                />
                <label htmlFor="every6Months">Every 6 months</label>
              </div>
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  defaultChecked={
                    this.props.store.paymentPage.autopay.frequency.annually
                  }
                  onChange={this.handleAutopayFrequency}
                  id="annually"
                />
                <label htmlFor="annually">Annually</label>
              </div>
            </div>
          </div>

          <label className="header">Finish Pay Date</label>
          <p className="small small-grey">Select options you want to offer</p>

          <div className="row">
            <div className="col-6 mb-2">
              <div className="icheck-primary">
                <input
                  name="autopayFinish"
                  onChange={this.handleAutopayFinish}
                  defaultChecked={
                    this.props.store.paymentPage.autopay.finish.calendar
                  }
                  type="radio"
                  id="calendar"
                />
                <label htmlFor="calendar">Calendar & Until Cancelled</label>
              </div>
            </div>
            <div className="col-6 mb-2">
              <div className="icheck-primary">
                <input
                  name="autopayFinish"
                  onChange={this.handleAutopayFinish}
                  defaultChecked={
                    this.props.store.paymentPage.autopay.finish.untilCancelled
                  }
                  type="radio"
                  id="untilCancelled"
                />
                <label htmlFor="untilCancelled">Until Cancelled Only</label>
              </div>
            </div>
          </div>

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              disabled={this.state.autopayStartDateError}
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const PopoverEditPaymentMethods = (
      <Popover>
        <Popover.Content>
          <div className="row mb-2">
            <div className="col">
              <h6>Payment Methods</h6>
            </div>
          </div>
          <input
            name="paymentMethodsHeader"
            value={this.props.store.paymentPage.paymentMethods.header}
            onChange={(e) =>
              this.handleTextChange("paymentMethods", "header", e)
            }
            type="text"
            className="form-control mb-3"
            placeholder="Header"
          />

          <div className="mb-2">
            <label className="header">
              Select Pay Methods you want to Offer
            </label>
            <div className="row">
              <div className="col">
                <div className="icheck-primary">
                  <input
                    defaultChecked={
                      this.props.store.paymentPage.paymentMethods
                        .allMethodsChecked
                    }
                    onChange={this.handleAllPaymentMethods}
                    type="checkbox"
                    id="paymentMethodsSelectAll"
                  />
                  <label htmlFor="paymentMethodsSelectAll">
                    {this.props.store.paymentPage.paymentMethods
                      .allMethodsChecked
                      ? "Unselect"
                      : "Select"}{" "}
                    All
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2">
            <label className="header">Debit/Credit Cards</label>
            {!this.state.hasCards ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip>
                    {
                      "This service is not activated for this Paypoint. Please contact your administrator to enable."
                    }
                  </Tooltip>
                }
              >
                <div className="row">
                  <div className="col">
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasCards}
                        checked={
                          this.props.store.paymentPage.paymentMethods.methods
                            .visa
                        }
                        type="checkbox"
                        id="visa"
                      />
                      <label htmlFor="visa">Visa</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasCards}
                        checked={
                          this.props.store.paymentPage.paymentMethods.methods
                            .mastercard
                        }
                        type="checkbox"
                        id="mastercard"
                      />
                      <label htmlFor="mastercard">Mastercard</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasCards}
                        checked={
                          this.props.store.paymentPage.paymentMethods.methods
                            .discover
                        }
                        onChange={this.handlePaymentMethods}
                        type="checkbox"
                        id="discover"
                      />
                      <label htmlFor="discover">Discover</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasCards}
                        checked={
                          this.props.store.paymentPage.paymentMethods.methods
                            .amex
                        }
                        type="checkbox"
                        id="amex"
                      />
                      <label htmlFor="amex">Amex</label>
                    </div>
                  </div>
                </div>
              </OverlayTrigger>
            ) : (
              <div className="row">
                <div className="col">
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods.visa
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="visa"
                    />
                    <label htmlFor="visa">Visa</label>
                  </div>
                </div>
                <div className="col">
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods
                          .mastercard
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="mastercard"
                    />
                    <label htmlFor="mastercard">Mastercard</label>
                  </div>
                </div>
                <div className="col">
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods
                          .discover
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="discover"
                    />
                    <label htmlFor="discover">Discover</label>
                  </div>
                </div>
                <div className="col">
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods.amex
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="amex"
                    />
                    <label htmlFor="amex">Amex</label>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mb-2">
            <label className="header">Bank</label>
            <div className="row">
              <div className="col">
                {!this.state.hasEChecks ? (
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip>
                        {
                          "This service is not activated for this Paypoint. Please contact your administrator to enable."
                        }
                      </Tooltip>
                    }
                  >
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasEChecks}
                        checked={
                          this.props.store.paymentPage.paymentMethods.methods
                            .eCheck
                        }
                        type="checkbox"
                        id="eCheck"
                      />
                      <label htmlFor="eCheck">E-Check / ACH</label>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div className="icheck-primary">
                    <input
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods
                          .eCheck
                      }
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="eCheck"
                    />
                    <label htmlFor="eCheck">E-Check / ACH</label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mb-2">
            <label className="header">Wallet</label>
            <div className="row">
              <div className="col-sm-3">
                {!this.state.hasWallets ? (
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip>
                        {
                          "This service is not activated for this Paypoint. Please contact your administrator to enable."
                        }
                      </Tooltip>
                    }
                  >
                    <div className="icheck-primary">
                      <input
                        disabled={!this.state.hasWallets}
                        checked={false}
                        type="checkbox"
                        id="applePay"
                      />
                      <label htmlFor="applePay">Apple Pay</label>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div className="icheck-primary">
                    <input
                      disabled={
                        this.props.store.paymentPage.autopay.enabled
                      }
                      checked={
                        this.props.store.paymentPage.paymentMethods.methods
                          .applePay &&
                        !this.props.store.paymentPage.autopay.enabled}
                      onChange={this.handlePaymentMethods}
                      type="checkbox"
                      id="applePay"
                    />
                    <label htmlFor="applePay">Apple Pay</label>
                  </div>
                )}
              </div>
              <div className="col-sm-3">
                <ApplePayButtonType />
              </div>
              <div className="col-sm-3">
                <ApplePayButtonStyle />
              </div>
              <div className="col-sm-3">
                <ApplePayButtonLanguage />
              </div>
            </div>
          </div>
          <div className="mb-2">
            <label className="header">Coming Soon!</label>
            <div className="row">
              <div className="col-sm-3">
                <div className="icheck-primary">
                  <input
                    disabled={true}
                    checked={
                      this.props.store.paymentPage.paymentMethods.methods
                        .googlePay
                    }
                    onChange={this.handlePaymentMethods}
                    type="checkbox"
                    id="googlePay"
                  />
                  <label htmlFor="googlePay">Google Pay</label>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="icheck-primary">
                  <input
                    disabled={true}
                    checked={
                      this.props.store.paymentPage.paymentMethods.methods.payPal
                    }
                    onChange={this.handlePaymentMethods}
                    type="checkbox"
                    id="payPal"
                  />
                  <label htmlFor="payPal">PayPal</label>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2">
            <label className="header">Crypto</label>
            <div className="row">
              <div className="col">
                <div className="icheck-primary">
                  <input
                    disabled={true}
                    checked={
                      this.props.store.paymentPage.paymentMethods.methods.bitPay
                    }
                    onChange={this.handlePaymentMethods}
                    type="checkbox"
                    id="bitPay"
                  />
                  <label htmlFor="bitPay">BitPay</label>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2">
            <label className="header">Convenience Fees</label>
            <small>
              Please go to{" "}
              <Link
                to={"/" + PayabliStorageManager.getEntryName() + "/settings"}
              >
                Page Settings
              </Link>{" "}
              to manager
            </small>
          </div>

          <div className="popover-footer text-right">
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    const PopoverEditPayer = (
      <Popover>
        <Popover.Content style={{ paddingLeft: "30px", paddingRight: "20px" }}>
          <div className="row mb-2">
            <div className="col-9">
              <h6>Customer Information Fields</h6>
            </div>
            <div className="col text-right">
              <Switch
                onChange={this.disablePayer}
                checked={this.props.store.paymentPage.payor.enabled}
                onColor="#10A0E3"
                onHandleColor="#ffffff"
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={38}
                className="react-switch"
                disabled={this.props.store.requiredCustomFields}
              />
            </div>
          </div>

          <input
            name="payerHeader"
            value={this.props.store.paymentPage.payor.header}
            onChange={(e) => this.handleTextChange("payor", "header", e)}
            type="text"
            className="form-control mb-3"
            placeholder="Header"
          />

          <label className="header">Form Builder</label>
          <p className="small small-grey">
            Configure Customer Information Fields
          </p>
          {<PayorFields />}

          <div className="row">
            <div className="col-12">
              <Alert key="primary" variant="primary">
                The custom fields should be added in{" "}
                <Alert.Link
                  href={"/" + PayabliStorageManager.getEntryName() + "/fields"}
                >
                  settings
                </Alert.Link>{" "}
                to apply to all customers in your entity
              </Alert>
            </div>
          </div>

          <div
            className="popover-footer text-right"
            style={{ marginLeft: "-30px", marginRight: "-20px" }}
          >
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => document.body.click()}
            >
              Done
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    return (
      <Layout {...this.props}>
        <div>
          <TopBar>
            <PaymenPagePublishActions history={this.props.history} />

            <div className="top-bar-sub">
              <div className="row">
                <div className="col-5">
                  <a
                    href={
                      "/" + this.props.global.getURLEntry() + "/paymentpages"
                    }
                    className="btn btn-light text-transform-normal"
                  >
                    <BiChevronLeft /> Payment Pages
                  </a>
                </div>

                <div className="col-6 text-center hide">
                  <a href="/">
                    <BiDollarCircle /> Amounts
                  </a>
                  <a href="/">
                    <BiIdCard /> Payer Details
                  </a>
                  <a href="/">
                    <BiWallet /> Pay Methods
                  </a>
                  <a href="/">
                    <BiListCheck /> Confirm
                  </a>
                </div>
                <div className="col-7 text-right">
                  <PaymenPageSections selected="builder" />
                </div>
              </div>
            </div>
          </TopBar>
          <div className="mt-body2">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div
                className="card"
                style={{
                  width: "50em",
                  padding: "1rem 2rem",
                  marginBottom: "40px",
                }}
              >
                <PaymentPageName />
              </div>
            </div>

            <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
              <div className="card" style={{ width: "50em" }}>
                <div className="card-body  main-cont">
                  <ul className="sortable" ref={this.sortablePayer}>
                    <li
                      className="ui-state-default dragable-panel"
                      id="blocklogo"
                      style={{ paddingBottom: 0 }}
                    >
                      <OverlayTrigger
                        rootClose
                        key={0}
                        trigger="click"
                        placement="right"
                        overlay={PopoverEditLogo}
                      >
                        <BiEdit className="icon-edit right" />
                      </OverlayTrigger>
                      <div
                        className={
                          !this.props.store.paymentPage.logo.enabled
                            ? "opacity-panel"
                            : ""
                        }
                      >
                        <div className="text-center">
                          <PaymentPageLogo />
                        </div>
                      </div>
                    </li>
                    <li
                      className="ui-state-default dragable-panel"
                      id="blockpage"
                      style={{ paddingTop: 0 }}
                    >
                      <OverlayTrigger
                        rootClose
                        key={1}
                        trigger="click"
                        placement="right"
                        overlay={PopoverEditTitleDescription}
                      >
                        <BiEdit className="icon-edit right" />
                      </OverlayTrigger>
                      <div
                        className={
                          !this.props.store.paymentPage.page.enabled
                            ? "opacity-panel"
                            : ""
                        }
                      >
                        <div className="row">
                          <div className="col-md-8 offset-md-2 progress-steps mb-3">
                            <div className="row">
                              <div style={bgTheme} className="col active">
                                &nbsp;
                              </div>
                              <div className="col">&nbsp;</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <h3 className="card-title text-center">
                              {this.props.store.paymentPage.page.header
                                ? this.props.store.paymentPage.page.header
                                : "[Page Header]"}
                            </h3>
                            <p
                              className="small small-grey text-center m0"
                              dangerouslySetInnerHTML={{
                                __html: this.props.store.paymentPage.page
                                  .description
                                  ? this.props.store.paymentPage.page
                                      .description
                                  : "[Page Description]",
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li
                      className="ui-state-default dragable-panel"
                      id="blockamount"
                    >
                      <div className="payment-categories">
                        <ul className="sortable" ref={this.sortableCategories}>
                          {this.renderAmountCategories()}
                        </ul>

                        <button
                          type="button"
                          style={btnTheme}
                          onClick={this.addNewAmountCategoryField}
                          className="btn btn-success mt-2 btn-sm full-w mb-2"
                        >
                          + Add Price Field
                        </button>
                      </div>
                      {this.props.store.isAutopay && (
                        <p className="text-center">
                          <a
                            href="/"
                            onClick={(e) => this.prevent(e)}
                            style={linkTheme}
                          >
                            Click here
                          </a>{" "}
                          to make One-Time Payment
                        </p>
                      )}
                    </li>

                    <li
                      className="ui-state-default dragable-panel"
                      id="blockautopay"
                    >
                      <OverlayTrigger
                        rootClose={
                          this.state.autopayStartDateError ? false : true
                        }
                        key={2}
                        trigger="click"
                        placement="right"
                        overlay={PopoverEditAutopay}
                      >
                        <BiEdit className="icon-edit right" />
                      </OverlayTrigger>
                      <div
                        className={
                          !this.props.store.paymentPage.autopay.enabled
                            ? "opacity-panel"
                            : ""
                        }
                      >
                        <h6 className="sub-header mb-3">
                          {this.props.store.paymentPage.autopay.header
                            ? this.props.store.paymentPage.autopay.header
                            : "[AutoPay Header]"}
                        </h6>
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control input-calendar"
                                placeholder="Start Date"
                              />
                              <label htmlFor="floatingInput">Start Date</label>
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select form-control"
                                id="floatingSelectGrid"
                                aria-label="Frequency"
                              >
                                <option defaultValue="select">
                                  {" "}
                                  Select...{" "}
                                </option>
                                {this.props.store.paymentPage.autopay.frequency
                                  .onetime && <option>One Time</option>}
                                {this.props.store.paymentPage.autopay.frequency
                                  .weekly && <option>Weekly</option>}
                                {this.props.store.paymentPage.autopay.frequency
                                  .every2Weeks && (
                                  <option>Every 2 weeks</option>
                                )}
                                {this.props.store.paymentPage.autopay.frequency
                                  .monthly && <option>Monthly</option>}
                                {this.props.store.paymentPage.autopay.frequency
                                  .every3Months && (
                                  <option>Every 3 months</option>
                                )}
                                {this.props.store.paymentPage.autopay.frequency
                                  .every6Months && (
                                  <option>Every 6 months</option>
                                )}
                                {this.props.store.paymentPage.autopay.frequency
                                  .annually && <option>Annually</option>}
                              </select>
                              <label htmlFor="floatingSelectGrid">
                                Frequency
                              </label>
                            </div>
                          </div>
                          <div className="col-sm">
                            {this.props.store.paymentPage.autopay.finish
                              .untilCancelled && (
                              <div className="form-floating mb-3">
                                <select
                                  className="form-select form-control"
                                  id="floatingSelectGrid"
                                  aria-label="End Date"
                                >
                                  <option defaultValue="select">
                                    {" "}
                                    Select...{" "}
                                  </option>
                                  <option value="untilcancelled">
                                    Until Cancelled
                                  </option>
                                </select>
                                <label htmlFor="floatingSelectGrid">
                                  End Date
                                </label>
                              </div>
                            )}

                            {this.props.store.paymentPage.autopay.finish
                              .calendar && (
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control input-calendar"
                                  placeholder="End Date"
                                />
                                <label htmlFor="floatingInput">End Date</label>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>

                    <li
                      className="ui-state-default dragable-panel"
                      id="blockpayer"
                    >
                      <OverlayTrigger
                        rootClose
                        key={8}
                        trigger="click"
                        placement="bottom"
                        overlay={PopoverEditPayer}
                      >
                        <BiEdit className="icon-edit right" />
                      </OverlayTrigger>
                      <div
                        className={
                          !this.props.store.paymentPage.payor.enabled
                            ? "opacity-panel"
                            : ""
                        }
                      >
                        <h6 className="sub-header mb-3">
                          {this.props.store.paymentPage.payor.header
                            ? this.props.store.paymentPage.payor.header
                            : "[Customer Information Header]"}
                        </h6>
                        <div className="row">{this.renderFields()}</div>
                      </div>
                    </li>

                    <li
                      className="ui-state-default dragable-panel"
                      id="blockpaymentMethods"
                    >
                      <h6 className="sub-header mb-3">
                        {this.props.store.paymentPage.paymentMethods.header
                          ? this.props.store.paymentPage.paymentMethods.header
                          : "[Payment Methods Header]"}
                      </h6>
                      <Accordion defaultActiveKey="1">
                        <OverlayTrigger
                          rootClose
                          key={6}
                          trigger="click"
                          placement="bottom"
                          overlay={PopoverEditPaymentMethods}
                        >
                          <BiEdit className="icon-edit right" />
                        </OverlayTrigger>

                        {this.props.store.hasECheck && (
                          <Card className="card card-in mb-3 card-hover">
                            <Accordion.Toggle
                              as={Card.Header}
                              className="card-body"
                              eventKey="1"
                            >
                              <span className="badge bg-dark card-info-badge">
                                Most popular method
                              </span>
                              <div className="row">
                                <div className="col-2 text-center pt-1">
                                  {this.props.global.getGlobalImg(
                                    "ach",
                                    "30px"
                                  )}
                                </div>
                                <div className="col-10">
                                  Bank account / E-Check
                                  <p className="small small-grey-m0">
                                    Pay from your Bank Account
                                  </p>
                                </div>
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <PaymentMethodECheck
                                  history={this.props.history}
                                />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        )}

                        {this.props.store.hasCards && (
                          <Card className="card card-in mb-3 card-hover">
                            <Accordion.Toggle
                              as={Card.Header}
                              className="card-body"
                              eventKey="0"
                            >
                              <div className="row">
                                <div className="col-2 text-center">
                                  {this.props.global.getGlobalImg(
                                    "card",
                                    "30px"
                                  )}
                                </div>
                                <div className="col-10">
                                  Credit or Debit Card
                                  <p className="small small-grey-m0">
                                    Use your Credit or Debit Card
                                  </p>
                                  <div
                                    className="card-brands accordion-right-corner"
                                    style={{
                                      position: "absolute",
                                    }}
                                  >
                                    <PaymentMethodsAccepted />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <PaymentMethodCCForm
                                  history={this.props.history}
                                />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        )}

                        {!this.disableDigitalWallets() && (
                          <Card className="card card-in mb-3 card-hover">
                            <Accordion.Toggle
                              as={Card.Header}
                              className="card-body"
                              eventKey="2"
                            >
                              <div className="row">
                                <div className="col-2 text-center my-auto">
                                  {this.props.global.getGlobalImg(
                                    "applepay",
                                    "30px"
                                  )}
                                </div>
                                <div className="col-10">
                                  Apple Pay
                                  <p className="small small-grey-m0">
                                    Apple Pay is an easy and secure way to pay
                                  </p>
                                </div>
                              </div>
                            </Accordion.Toggle>
                          </Card>
                        )}
                      </Accordion>
                      {!this.props.store.hasCards &&
                        !this.props.store.hasECheck &&
                        !this.props.store.hasWallets && (
                          <Message message="Warning: Select at least a Payment Method." />
                        )}
                    </li>

                    <li
                      className="ui-state-default dragable-panel"
                      id="blocknotes"
                    >
                      <OverlayTrigger
                        rootClose
                        key={3}
                        trigger="click"
                        placement="right"
                        overlay={PopoverEditNotes}
                      >
                        <BiEdit className="icon-edit right" />
                      </OverlayTrigger>
                      <div
                        className={
                          !this.props.store.paymentPage.notes.enabled
                            ? "opacity-panel"
                            : ""
                        }
                      >
                        <h6 className="sub-header mb-3">
                          {this.props.store.paymentPage.notes.header
                            ? this.props.store.paymentPage.notes.header
                            : "[Notes Header]"}
                        </h6>
                        <div className="mb-3">
                          <textarea
                            style={{
                              height: "75px",
                            }}
                            className="form-control"
                            id="notesTextarea"
                            placeholder={
                              this.props.store.paymentPage.notes.placeholder
                                ? this.props.store.paymentPage.notes.placeholder
                                : "[Notes Placeholder]"
                            }
                          ></textarea>
                        </div>
                      </div>
                    </li>

                    <li
                      className="ui-state-default dragable-panel"
                      id="blockreview"
                    >
                      <OverlayTrigger
                        rootClose
                        key={3}
                        trigger="click"
                        placement="right"
                        overlay={PopoverEditReview}
                      >
                        <BiEdit className="icon-edit right" />
                      </OverlayTrigger>
                      <div
                        className={
                          !this.props.store.paymentPage.review.enabled
                            ? "opacity-panel"
                            : ""
                        }
                      >
                        <div className="review-total">
                          <h6 className="sub-header-line mb-3">
                            {this.props.store.paymentPage.review.header
                              ? this.props.store.paymentPage.review.header
                              : "[Review & Send Payment Header]"}
                          </h6>
                          <div className="row">
                            <div className="col">Net Amount:</div>
                            <div className="col">$0.00</div>
                          </div>
                          <div className="row">
                            <div className="col">Fee:</div>
                            <div className="col">$0.00</div>
                          </div>

                          <div className="row total-amount">
                            <div className="col">Total Amount:</div>
                            <div className="col">$0.00</div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li
                      className="ui-state-default dragable-panel"
                      id="blockmessageBeforePaying"
                    >
                      <OverlayTrigger
                        rootClose
                        key={4}
                        trigger="click"
                        placement="right"
                        overlay={PopoverEditMessageBeforePaying}
                      >
                        <BiEdit className="icon-edit right" />
                      </OverlayTrigger>
                      <div
                        className={
                          !this.props.store.paymentPage.messageBeforePaying
                            .enabled
                            ? "opacity-panel"
                            : ""
                        }
                      >
                        <p
                          className="small small-grey"
                          style={{ margin: 0 }}
                          dangerouslySetInnerHTML={{
                            __html: this.props.store.paymentPage
                              .messageBeforePaying.label
                              ? this.props.store.paymentPage.messageBeforePaying
                                  .label
                              : "[Message Before Paying]",
                          }}
                        ></p>
                      </div>
                    </li>

                    <li
                      className="ui-state-default dragable-panel"
                      id="blockpaymentButton"
                    >
                      <OverlayTrigger
                        rootClose
                        key={5}
                        trigger="click"
                        placement="right"
                        overlay={PopoverEditPayButton}
                      >
                        <BiEdit className="icon-edit right" />
                      </OverlayTrigger>
                      <div className="btn-group full-w">
                        <button
                          type="button"
                          style={btnTheme}
                          onClick={this.testDataJson}
                          className="btn btn-success btn-lg"
                        >
                          {this.props.store.paymentPage.paymentButton.label}{" "}
                          $0.00
                        </button>
                      </div>
                    </li>

                    <li
                      className="ui-state-default dragable-panel"
                      id="blockcontactUs"
                    >
                      <OverlayTrigger
                        rootClose
                        key={7}
                        trigger="click"
                        placement="right"
                        overlay={PopoverEditContact}
                      >
                        <BiEdit className="icon-edit right" />
                      </OverlayTrigger>
                      <div
                        className={
                          !this.props.store.paymentPage.contactUs.enabled
                            ? "opacity-panel"
                            : ""
                        }
                      >
                        <p className="small-grey-m0 text-center ">
                          <b>
                            {this.props.store.paymentPage.contactUs.header
                              ? this.props.store.paymentPage.contactUs.header
                              : "[Contact Us Header]"}
                          </b>
                          <br />
                          {this.props.store.paymentPage.contactUs.emailLabel
                            ? this.props.store.paymentPage.contactUs.emailLabel
                            : "[email]"}{" "}
                          |{" "}
                          {this.props.store.paymentPage.contactUs.phoneLabel
                            ? this.props.store.paymentPage.contactUs.phoneLabel
                            : "[Phone Number]"}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                {this.props.store.paymentPage.contactUs.paymentIcons && (
                  <div className="card-brands text-center bottom-grey mt-3">
                    <PaymentMethodsAccepted />
                  </div>
                )}
              </div>
              <p className="small-small mt-5">
                Powered by{" "}
                <a
                  rel="noreferrer"
                  style={linkTheme}
                  target="_blank"
                  href="https://www.payabli.com/"
                  className="small no-underline"
                >
                  Payabli
                </a>
              </p>
            </div>
          </div>
        </div>
        {this.props.store &&
          this.props.store.paymentPageSettings.customCssUrl && (
            <Helmet>
              <link
                rel="stylesheet"
                href={this.props.store.paymentPageSettings.customCssUrl}
              />
            </Helmet>
          )}
      </Layout>
    );
  }
}

export { Builder };
