import React from 'react';
import { inject, observer } from 'mobx-react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AiFillSetting } from 'react-icons/ai';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import BillSchedule from './BillSchedule';

@inject('store', 'global', 'bill', 'reports')
@observer
class SectionBillDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedDefault: false,
      dueDate: '',
      schedule: false,
      formErrors: {
        textNumber: null,
      },
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
    this.handleSchedule = this.handleSchedule.bind(this);
    this.handleChangePaymentTerm = this.handleChangePaymentTerm.bind(this);
  }

  handleChangeInput(e) {
    const { value } = e.target;
    this.props.bill.setBillDetails({
      billNumber: value,
    });
  }

  handleChangeDueDate(date) {
    this.props.bill.setBillDetails({
      dueDate: new Date(date),
      term: null,
    });
    this.setState({ dueDate: date, paymentTermsText: '', paymentTerms: 'none' });
  }

  handleChangePaymentTerm(item) {
    const dueDateCalculated = this.calculateDueDate(item.value);

    let paymentTermsText = item.text;
    let paymentTermsValue = item.value;

    if (item.value === 'custom') {
      paymentTermsText = '';
      paymentTermsValue = 'none';
    }

    this.setState({ paymentTermsText: paymentTermsText, paymentTerms: paymentTermsValue, dueDate: dueDateCalculated });
    this.props.bill.setBillDetails({
      dueDate: dueDateCalculated,
      term: paymentTermsValue
    });
  }

  calculateDueDate(term) {
    let billDetails = this.props.bill.billDetails;
    let day = new Date();
    if(billDetails.scheduleCheck === true && billDetails.startDate){
      day = new Date(billDetails.startDate);
    }

    switch (term) {
      case 'DOR':
        return day;
      case 'NET10':
        day.setDate(day.getDate() + 10);
        return day;
      case 'NET15':
        day.setDate(day.getDate() + 15);
        return day;
      case 'NET20':
        day.setDate(day.getDate() + 20);
        return day;
      case 'NET30':
        day.setDate(day.getDate() + 30);
        return day;
      case 'NET45':
        day.setDate(day.getDate() + 45);
        return day;
      case 'NET60':
        day.setDate(day.getDate() + 60);
        return day;
      case 'NET90':
        day.setDate(day.getDate() + 90);
        return day;
      case 'EOM':
        return new Date(day.getFullYear(), day.getMonth() + 1, 0);
      case '1MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 1);
      case '5MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 5);
      case '10MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 10);
      case '15MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 15);
      case '20MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 20);
      case 'custom':
        // open datepicker
        document.getElementById('dueDate').click();
        return;
      default:
        day.setDate(day.getDate() + 1);
        return day;
    }
  }

  handleSchedule(e) {
    const { checked, id } = e.target;
    this.setState({ [id]: checked });
    this.props.bill.setBillDetails({
      scheduleCheck: checked,
    });
  }

  componentDidMount() {
    const dueDateCalculated = this.calculateDueDate();
    this.setState({ dueDate: dueDateCalculated });
    this.props.bill.setBillDetails({
      dueDate: dueDateCalculated
    });
  }

  componentDidUpdate() {
    const billStatus = this.props.bill.status;

    if (billStatus === 'new' && !this.state.loadedDefault) {
      const dueDateCalculated = this.calculateDueDate();
      this.setState({ loadedDefault: true, dueDate: dueDateCalculated });
      this.props.bill.setBillDetails({
        dueDate: dueDateCalculated,
      });
    }

    const billDataLoaded = this.props.billDataLoaded;

    if (billStatus !== 'new' && !this.state.loadedDefault && billDataLoaded) {
      let isSchedule = billDataLoaded.Frequency ? (billDataLoaded.Frequency.toLowerCase() !== "onetime" ? true : false) : false;

      this.setState({
        loadedDefault: true,
        dueDate: new Date(billDataLoaded.DueDate),
        schedule: isSchedule,
      });
      
      this.props.bill.setBillDetails({
        billNumber: billDataLoaded.BillNumber,
        billDate: new Date(billDataLoaded.BillDate),
        dueDate: new Date(billDataLoaded.DueDate),
        term: billDataLoaded.Terms,
        scheduleCheck: isSchedule,
        frequency: billDataLoaded.Frequency,
        endDate: billDataLoaded.EndDate ? new Date(billDataLoaded.EndDate) : null,
      });
    }
  }

  render() {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const { billDetails } = this.props.bill;

    const paymentTermsToggle = React.forwardRef(({ children, onClick }, ref) => <>{children}</>);

    return (
      <div className="section-form">
        <h6 className="mb-3">Bill Details</h6>
        <div className="row">
          <div className="col-6 mb-2">
            <div className="form-floating">
              <input
                className={this.state.formErrors.billNumber ? 'form-control input-error' : 'form-control'}
                onChange={this.handleChangeInput}
                placeholder="Bill #"
                id="billNumber"
                value={this.props.bill.billDetails.billNumber ? this.props.bill.billDetails.billNumber : ''}
                disabled={this.props.bill.status !== 'new'}
              />
              <label htmlFor="billNumber">Bill #</label>
            </div>
          </div>
          
          <div className="col-6 mb-2">
            <DatePicker
              customInput={
                <div className="form-floating">
                  <input
                    readOnly
                    autoComplete="off"
                    onChange={function () {}}
                    value={billDetails.dueDate ? this.props.reports.dateStringFormat(billDetails.dueDate) : ''}
                    name="dueDate"
                    id="dueDate"
                    className={
                      this.state.formErrors.dueDate
                        ? 'form-control input-calendar input-error input-lg'
                        : 'form-control input-calendar input-lg'
                    }
                    placeholder="Select Date"
                    disabled={this.props.isView}
                  />
                  <label htmlFor="dueDate">Due Date</label>
                </div>
              }
              selected={billDetails.dueDate}
              onChange={(date) => this.handleChangeDueDate(date)}
              minDate={ billDetails.scheduleCheck === true ? billDetails.startDate ? billDetails.startDate : today : today}
              dayClassName={(date) => 'calendar-day'}
              popperPlacement="bottom-start"
              disabled={this.props.isView}
            />
          </div>
        </div>

        {/*<div className="row-schedule">
          <div className="icheck-primary">
            <input
              checked={this.state.schedule}
              type="checkbox"
              name="schedule"
              id="schedule"
              onChange={this.handleSchedule}
              disabled={this.props.isView}
            />
            <label htmlFor="schedule">Schedule</label>
          </div>
          
          {(!this.props.isView && !this.props.noTerms ) &&
          <span id="payment-terms">
            <span className="text">
              {billDetails.term === null  ? '+ payment terms' : `Term: ${this.props.bill.getTermText()}`}
            </span>
            <div className="icon-settings">
              <DropdownButton
                as={paymentTermsToggle}
                menuAlign="right"
                title={<AiFillSetting />}
                size="sm"
                variant="default"
              >
                {this.props.bill.getOptionsDueDate().map((item) => (
                  <Dropdown.Item
                    key={item.value}
                    as="span"
                    id={item.value}
                    onClick={() => this.handleChangePaymentTerm(item)}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </span>
          }
        </div>*/}

        {this.state.schedule && (
          <div className="row-fields-schedule">
            <BillSchedule isView={this.props.isView} billDataLoaded={this.props.billDataLoaded} />
          </div>
        )}
      </div>
    );
  }
}

export default SectionBillDetails;
