import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { SettingsLinks } from '../../components/SettingsLinks';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { BiUserCircle, BiTrash, BiDotsVerticalRounded, BiMailSend, BiCheckCircle, BiMinusCircle, BiLockAlt } from 'react-icons/bi';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { Modal, Button} from 'react-bootstrap';
import { ReportPaginator } from '../../components/ReportPaginator';
import { Link } from 'react-router-dom';
import { PayabliStorageManager } from '../../api/localStorageManager';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { MainBar } from '../../components/MainBar';

@inject('reports', 'global', 'user')
@observer
class UserReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            status: '',
            username: '',
            password: '',
            repeatPassword: '',
            showPassword: false,
            timeZone: 0,
            score: 0,
            flagItemAllColumn: true,
        };
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);        
        this.deleteUser = this.deleteUser.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteUserAction = this.deleteUserAction.bind(this);
        this.closeResetPasswordEmailModal = this.closeResetPasswordEmailModal.bind(this);
        this.openResetPasswordEmailModal = this.openResetPasswordEmailModal.bind(this);
        this.sendEmailToResetPassword = this.sendEmailToResetPassword.bind(this);
        this.renderStatus = this.renderStatus.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.showRepeatPassword = this.showRepeatPassword.bind(this);
        this.selectTimezone = this.selectTimezone.bind(this);
        this.goToNewUserForm = this.goToNewUserForm.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                Profile    : { label:'Profile', class: 'text-center', display: true}, 
                Name       : { label:'Name', class: '', display: true}, 
                Email      : { label:'Email', class: '', display: true}, 
                Phone      : { label:'Phone', class: '', display: true}, 
                UsrStatus  : { label:'Status', class: '', display: true}, 
                LastAccess : { label:'Last Log in', class: '', display: true}, 
                CreatedAt  : { label:'Created on', class: '', display: true}, 
            }
        )        
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    closeResetPasswordEmailModal(){
        this.setState({resetPasswordEmailModalIsOpen:false})
    }
    
    openResetPasswordEmailModal(email){
        this.setState({resetPasswordEmailModalIsOpen:true, resetPasswordEmail: email})
    }

    sendEmailToResetPassword(){
        if(this.state.resetPasswordEmail){
            let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            let entry = encryptStorage.getItem(this.props.global.getURLEntry()+"_pEntry");

            var user ={
                email: this.state.resetPasswordEmail,
                entry: entry.pEntry,
            }
            
            this.props.global.setLoading(true);
            this.props.user.sendEmailToResetPassword(user)
            .then(result => {
                this.props.global.setLoading(false);
                toast.success("Email sent successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
                this.closeResetPasswordEmailModal();
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    renderStatus(status){
        switch(status){
            case 1:
                return <span className="badge bg-primary"><BiCheckCircle /> Active</span>;
            case 0:
                return <span className="badge bg-light"><BiMinusCircle /> Inactive</span>;
            case 85:
                return <span className="badge bg-warning"><BiLockAlt /> Locked</span>;
            default:
                return '-';
        }
    }
    
    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }

    deleteUser(id){
        this.setState({ userToDelete: id });
        this.openDeleteModal();
    }

    clearFilters(){
        this.setState({ head: "" }, () => {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    openDeleteModal(){
        this.setState({ deleteModalIsOpen: true });
    }
    
    closeDeleteModal(){
        this.setState({ deleteModalIsOpen: false });
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'users/point').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
        });
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    getReportFromApi(){
        this.props.global.setLoading(true);
        this.props.reports.getReportFromApi('users/point').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    viewRecordDetails(url, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            this.props.history.push(url);
        }
    }

    selectTimezone(e){
        if(e){
            this.setState({timeZone:e.target.value})
        }
    }

    deleteUserAction(){
        let userToDelete = this.state.userToDelete;
        
        if(userToDelete){
            this.props.global.setLoading(true);
            this.props.user.deleteUser(userToDelete)
            .then(response => {
                
                this.closeDeleteModal();
                this.getReportFromApi();
                this.setState({ deleteModalIsOpen: false });
                toast.success("Data deleted successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
                
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    validateUserFields(){
        let validators = this.props.global.validators;
        let errors = {};
        if(validators.isEmpty(this.state.name) || validators.isMaxLength(250, this.state.name))
        {
            errors['name']= true;
        }
        
        if(validators.isEmpty(this.state.username) || validators.isMaxLength(250, this.state.username) || validators.stringValidator('email',this.state.username))
        {
            errors['username']= true;
        }

        if(validators.isEmpty(this.state.phone) || validators.stringValidator('phone', this.state.phone))
        {
            errors['phone']= true;
        }

        if(validators.isEmpty(this.state.status))
        {
            errors['status']= true;
        }

        if(validators.isEmpty(this.state.password) || validators.isMaxLength(250, this.state.password) || (this.state.score < 4))
        {
            errors['password']= true;
        }

        if(this.state.password !== this.state.repeatPassword || validators.isEmpty(this.state.repeatPassword) || validators.isMaxLength(250, this.state.repeatPassword))
        {
            errors['repeatPassword']= true;
        }

        return errors;
    }

    showPassword(){
        this.setState({ showPassword:!this.state.showPassword });
    }
    
    showRepeatPassword(){
        this.setState({ showRepeatPassword:!this.state.showRepeatPassword });
    }

    goToNewUserForm(){
        window.location.href = ("/" + this.props.global.getURLEntry() + "/users/new");
    }
    
    render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];;
        return (
            <Layout {...this.props}>
            {userPermissions.indexOf("Manage Users") !== -1 && 
            <Modal style={{textAlign: "center"}} show={this.state.deleteModalIsOpen} onHide={this.closeDeleteModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiTrash className="icon-modal"/>
                    <h5>Delete</h5>

                    {
                        (encryptStorage && this.state.userToDelete === encryptStorage.getItem('pUser').id) ? 
                        <>
                        <p className="small">Sorry! You can not delete yourself.</p>
                        <Button className="btn" variant="primary" onClick={(e) => this.closeDeleteModal()}>
                            Close
                        </Button>
                        </>
                        :
                        <>
                         <p className="small">Are you sure you want to delete this user?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeDeleteModal()}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="danger" onClick={(e) => this.deleteUserAction()}>
                            Delete
                        </Button>
                        </>
                    }

                   
                </Modal.Body>
            </Modal>
            }

            {userPermissions.indexOf("Manage Users") !== -1 && 
            <Modal dialogClassName="modal-autowidth" style={{textAlign: "center"}} show={this.state.resetPasswordEmailModalIsOpen} onHide={this.closeResetPasswordEmailModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiMailSend className="icon-modal"/>
                    <h5>Reset Password</h5>
                    <p className="small">An email will be sent to change user's password.</p>
                    <p className="small"> <b>Email: {this.state.resetPasswordEmail}</b> </p>
                    
                    <Button className="btn full-w" variant="primary" onClick={(e)=>this.sendEmailToResetPassword()}>
                        Send Email
                    </Button>
                </Modal.Body>
            </Modal>
            }
            <div>
            <ParentFilterPanel report={'users/point'} />
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <SettingsLinks history={this.props.history} selected="manageusers"/>
                </div>
                </TopBar>

                <div className="mt-body4">
                    <MainBar
                        btnNewName="Add User" 
                        newOnClick={this.goToNewUserForm}
                        newButton={userPermissions.indexOf("Manage Users") !== -1}
                        reportName="Users"
                        reportTitle={this.state.head ? this.props.global.capitalizeFirstLetter(this.state.head) : "Users"}
                        dataQAName="UsersReports"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        rootTemplate={this.state.rootTemplate}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={false}
                        searchBar={false}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    /> 
                <div className="report-container">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            ( record[1].display && <th key={i} scope="col" className={record[1].class}>{record[1].label}</th> )
                        ))
                    }
                    <th className='text-center sticky-row' scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i} className="cursorPointer" onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} onClick={(e) => this.viewRecordDetails("../profile/"+record.userId,e)}>
                            { (this.props.reports.headers.Profile && this.props.reports.headers.Profile.display) &&
                                <td className="text-center table-icon"><BiUserCircle/></td>
                            }
                            { (this.props.reports.headers.Name && this.props.reports.headers.Name.display) &&
                                <td>{record.Name}</td>
                            }
                            { (this.props.reports.headers.Email && this.props.reports.headers.Email.display) &&
                                <td>{record.Email}</td>
                            }
                            { (this.props.reports.headers.Phone && this.props.reports.headers.Phone.display) &&
                                <td>{record.Phone ? this.props.global.phoneNumberFormat(record.Phone) : '-'}</td>
                            }                            
                            { (this.props.reports.headers.UsrStatus && this.props.reports.headers.UsrStatus.display) && 
                                <td>{this.renderStatus(record.UsrStatus)}</td>
                            }                            
                            { (this.props.reports.headers.LastAccess && this.props.reports.headers.LastAccess.display) && 
                                <td>{this.props.global.stringDateFormatV3(record.LastAccess)}</td>
                            }                            
                            { (this.props.reports.headers.CreatedAt && this.props.reports.headers.CreatedAt.display) && 
                                <td>{this.props.global.stringDateFormatV3(record.CreatedAt)}</td>
                            }
                            <td className='text-center sticky-row'>
                                <DropdownButton
                                    menuAlign="right"
                                    title={<BiDotsVerticalRounded/>}
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                {userPermissions.indexOf("Manage Users") !== -1 && 
                                <>
                                <Dropdown.Item as={Link} to={"../profile/"+record.userId}>View Details</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => this.openResetPasswordEmailModal(record.Email) }>Reset Password</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item style={{color:"rgb(192, 0, 0)"}} onClick={(e)=>this.deleteUser(record.userId)} >Delete</Dropdown.Item>
                                </>
                                }
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }
                
                </tbody>
                </table>
               
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound/>
                }
                </div>
                <ReportPaginator report="users/point"/>

                </div>
            </div>
         

            <ToastContainer transition={Bounce} />
            </Layout>
        )
    }
}

export { UserReport };