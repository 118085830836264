import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ActionsLinks } from '../../components/ActionsLinks';
import { EditCatalog } from '../../components/EditCatalog';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { Dropdown, DropdownButton, Modal, Button } from 'react-bootstrap';
import { ReportPaginator } from '../../components/ReportPaginator';
import { NameAvatar } from '../../components/NameAvatar';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { ReportFilters } from '../../components/ReportFilters';
import { MainBar } from '../../components/MainBar';
import { BiSortAlt2, BiDotsVerticalRounded } from 'react-icons/bi';

@inject('reports', 'global')
@observer
class CatalogReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filtersEnabled: false,
            filtersName: {},
            filtersDescription: {},
            deleteModalIsOpen: false,
            catalogToIdDelete: null,
            flagItemAllColumn: true,
            sortDirection: "desc",
        };
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.deleteCatalogAction = this.deleteCatalogAction.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.filter = this.filter.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.getReportNameComponent = this.getReportNameComponent.bind(this);
        this.buttonAddComponent = this.buttonAddComponent.bind(this);
        this.sortBy = this.sortBy.bind(this);
    }

    buttonAddComponent(withoutStyle=false){
        return <EditCatalog functionCallback={this.getReportFromApi} className={!withoutStyle ? "btn btn-default btn-bordered-success ml-2 data-qaid-productOrServiceCatalogMoneyIn" : "button-add-a dropdown-item"} style={withoutStyle ? {marginLeft: '1rem !important'}: {}} title={"Add product or service to items catalog"} label={withoutStyle ? "Product or Service" : <><BiPlus/><span className="main-span-button">Product or Service</span></>}  action={"add"}/>;
    }

    getReportNameComponent(){
        return (
            <>
                <div className="mr-3 inline">
                    <h6 style={{display: "inline-block"}} data-qaid="allCatalogMoneyInReportsPage">Products & Services Catalog</h6>
                </div>
                <div className="small-small">Add & edit products or services to your catalog</div>
            </>
        )
    }

    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.reports.setHeaders(
            {
                itemProductName : {label:'Item Name', class: '', display: true},
                itemDescription : {label:'Description', class: '', display: true},
                itemProductCode : {label:'Item Code', class: '', display: true},
                itemCost        : {label:'Item Cost', class: 'text-right', display: true},  
                createdAt       : {label:'Created Date', class: '', display: true},
                lastUpdated     : {label:'Updated Date', class: '', display: true},
            }
        )        
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    getMappingFields(){
        return {
            'itemProductName': 'name',
            'parentOrgName': 'orgname',
            'paypointLegalName': 'paypointlegal',
            'itemDescription': 'description',
            'itemProductCode': 'code',
            'itemCost': 'cost',
            'createdAt': 'createddate',
            'lastUpdated': 'updateddate',
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.props.global.setLoading(true);
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }
    getReportFromApi(){
        let tempFilterValue = {};
        if(this.state.sortBy){
            tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
        }
        this.filter(tempFilterValue);
    }

    deleteCatalog(idCatalog){
        this.setState({ catalogToIdDelete: idCatalog });
        this.openDeleteModal();
    }

    deleteCatalogAction(){
        let catalogToIdDelete = this.state.catalogToIdDelete;
        if(catalogToIdDelete){
            this.props.global.setLoading(true);
            this.props.reports.deleteLineItem(catalogToIdDelete)
            .then(result => {
                this.setState({ deleteModalIsOpen: false });
                this.props.global.setLoading(false);
                toast.success("Products or Services deleted successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container'
                });
            })
            .catch(error => {
                this.props.global.setLoading(false);
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    openDeleteModal(){
        this.setState({ deleteModalIsOpen: true });
    }

    closeDeleteModal(){
        this.setState({ deleteModalIsOpen: false });
    }

    clearFilters(){
        this.setState({filtersName: {}, filtersDescription: {}}, function(){
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'lineitems').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
    
    handleKeyDown(type, e){
        if (e.key === 'Enter') {
            this.filter(type,e.target.value);
        }
    }
    
    render() {
        return (
            <Layout {...this.props}>      
            <div>                
                <Modal style={{textAlign: "center"}} show={this.state.deleteModalIsOpen} onHide={this.closeDeleteModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <BiTrash className="icon-modal"/>
                        <h5 data-qaid="deleteCatalogModalMoneyIn">Delete</h5>
                        <p className="small">Are you sure you want to delete this product or service?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeDeleteModal()} data-qaid="cancelDeleteCatalogMoneyIn">
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="btn" variant="danger" onClick={this.deleteCatalogAction}>
                            Delete
                        </Button>
                    </Modal.Body>
                </Modal>
                
                <TopBar>
                    <MainTopBarMenu/>
                    <div className="top-bar-sub">
                        <ActionsLinks selected="catalog"/>
                    </div>
                </TopBar>

                <ParentFilterPanel report={'lineitems'} />

                <div className="mt-body4">
                    <MainBar
                        reportNameComponent={this.getReportNameComponent}
                        dataQAName="CatalogMoneyIn"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={false}
                        searchBar={false}
                        skipTotal={true}
                        buttonAddComponent={this.buttonAddComponent}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    /> 
                    
                    {this.state.filtersEnabled &&
                        <ReportFilters report="lineitems" clearFilters = {this.clearFilters}/>
                    }
                    <div className="report-container">
                    <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                            record[1].display && 
                            (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                            </th> ))
                            ||
                            (record[1].display && 
                            (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                            </th> ))
                        ))
                    }
                    <th scope="col" className="text-center sticky-row">Actions</th>
                    </tr>
                    </thead>
                    <tbody>                
                        {this.props.reports.records.map((record, i) => (
                            <tr key={i} className="cursorPointer" data-qaid={"catalogMoneyInRow-"+(i)}>
                                { (this.props.reports.headers.itemProductName && this.props.reports.headers.itemProductName.display) &&
                                    <td className='avatarTd'><NameAvatar text={record.itemProductName}/>{this.props.global.capitalizeFirstLetterOfEachWord(record.itemProductName)}</td>                                    
                                }
                                { (this.props.reports.headers.itemDescription && this.props.reports.headers.itemDescription.display) &&
                                    <td>{record.itemDescription ? record.itemDescription : ' - '}</td>
                                }                                
                                { (this.props.reports.headers.itemProductCode && this.props.reports.headers.itemProductCode.display) &&
                                    <td>{record.itemProductCode ? record.itemProductCode : ' - '}</td>
                                }                                
                                { (this.props.reports.headers.itemCost && this.props.reports.headers.itemCost.display) &&
                                    <td className='text-right'>${this.props.global.numberWithCommas(record.itemCost ? record.itemCost.toFixed(2): 0.00)}</td>
                                }                                
                                { (this.props.reports.headers.createdAt && this.props.reports.headers.createdAt.display) &&
                                    <td>{this.props.global.stringDateFormatV3(record.createdAt)}</td>
                                }                                
                                { (this.props.reports.headers.lastUpdated && this.props.reports.headers.lastUpdated.display) &&
                                    <td>{this.props.global.stringDateFormatV3(record.lastUpdated)}</td>
                                }                                
                                <td className="text-center sticky-row">
                                    <DropdownButton
                                        menuAlign="right"
                                        title={<BiDotsVerticalRounded/>}
                                        id="actionsMenuButton"
                                        data-qaid="catalogMoneyInActions"
                                        size="sm"
                                        variant="default"
                                    >
                                    <Dropdown.Item as="div"><EditCatalog functionCallback={this.getReportFromApi} title={"Edit product or service"} label={"Edit Item"}  action={"edit"} lineItemRecord={record} /></Dropdown.Item>
                                    <Dropdown.Item style={{color: '#c00000'}} onClick={() => this.deleteCatalog(record.Id)} data-qaid="deleteItemLinkMoneyIn">Delete Item</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>))                        
                        }
                </tbody>
                </table>                
                    {this.props.reports.records.length < 1 &&
                        <RecordsNotFound btnLink={'#'} btnText="Add product or service to items catalog" message="No products or services yet" description={<span>When you add products or services, they will show up here.</span>}/>
                    }
                </div>
                <ReportPaginator report="lineitems"/>
                </div>
            </div>
            <ToastContainer transition={Bounce} />
            </Layout>
        )
    }
}

export { CatalogReport };