import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../../components/TopBar';
import { MainTopBarMenu } from '../../../components/MainTopBarMenu';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { ActionsLinks } from '../../../components/ActionsLinks';
import { Layout } from '../../../components/Layout';

import 'react-toastify/dist/ReactToastify.css';
import '../../../assets/css/invoices.css';

import { ReportFilters } from '../../../components/ReportFilters';
import { ParentFilterPanel } from '../../../components/filters/ParentFilterPanel';
import { QuickFilters } from './QuickFilters';
import { TableTools } from './TableTools';
import { Table } from './Table';
import { ModalMarkPaid } from './ModalMarkPaid';
import { ModalDelete } from './ModalDelete';
import { ReportPaginator } from '../../../components/ReportPaginator';
import { QuickViewInvoice } from '../../../components/QuickViewInvoice';
import { RightPanel } from '../../../components/RightPanel';

@inject('store', 'global', 'reports', 'invoice')
@observer
class InvoiceReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      head: '',
      something: null,
      filtersEnabled: false,
      recordIdSelected: false,
      markPaidModalIsOpen: false,
      modalDeleteIsOpen: false,
    };

    this.clearFilters = this.clearFilters.bind(this);
    this.showHideFilters = this.showHideFilters.bind(this);
    this.filter = this.filter.bind(this);
    this.setHead = this.setHead.bind(this);
    this.toggleModalMarkPaid = this.toggleModalMarkPaid.bind(this);
    this.handleMarkPaidAction = this.handleMarkPaidAction.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.handleDeleteAction = this.handleDeleteAction.bind(this);
    this.handleSendInvoice = this.handleSendInvoice.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.getMappingFields = this.getMappingFields.bind(this);
  }

  componentDidMount() {
    this.props.global.protect(this.props.history);
    this.props.reports.setFrom(0);

    this.props.reports.setHeaders({
      Customer: {
        label: 'Customer',
        class: '',
        display: true
      },
      Company: {
        label: 'Company',
        class: '',
        display: true
      },
      ExternalPaypointID  : { 
        label:'External Paypoint ID', 
        class: '', 
        display: false 
      },
      CustomerNumber : { 
        label:'Customer #', 
        class: '', 
        display: false
      },
      BillingEmail : { 
        label:'Email', 
        class: '', 
        display: false
      },
      PayorPhone : { 
        label:'Phone #', 
        class: '', 
        display: false
      },
      BillingAddress : { 
        label:'Billing Address', 
        class: '', 
        display: false
      },
      ShippingAddress : { 
        label:'Shipping Address', 
        class: '', 
        display: false
      },
      InvoiceNumber: {
        label: 'Invoice #',
        class: '',
        display: true
      },
      Frequency: {
        label: 'Frequency',
        class: '',
        display: true
      },
      PaymentTerms: {
        label: 'Payment Terms',
        class: '',
        display: false
      },
      CreatedDate: {
        label: 'Created Date',
        class: '',
        display: true
      },
      DueDate: {
        label: 'Due Date',
        class: '',
        display: false
      },
      LastPaymentDate: {
        label: 'Last Payment Date',
        class: '',
        display: true
      },
      Status: {
        label: 'Status',
        class: '',
        display: true
      },
      PaidAmount: {
        label: 'Paid Amount',
        class: 'text-right',
        display: true
      },
      Amount: {
        label: 'Invoice Amount',
        class: 'text-right',
        display: true
      },
      Notes: {
        label: 'Notes',
        class: 'text-center',
        display: true,
      }
    });

    this.props.reports.setRecords([]);
    this.clearFilters();
  }

  getMappingFields(){
    return {
        'Customer': 'customerfullname',
        'Company': 'customercompanyname',
        'ParentOrgName': 'orgname',
        'PaypointLegalname': 'paypointlegal',
        'ExternalPaypointID': 'externalpaypointid',
        'CustomerNumber': 'customernumber',
        'BillingEmail': 'customeremail',
        'PayorPhone': 'customerphone',
        'BillingAddress': 'customeraddress',
        'ShippingAddress': 'customershippingaddress',
        'InvoiceNumber': 'invoicenumber',
        'Frequency': 'frequency',
        'PaymentTerms': 'payterms',
        'CreatedDate': 'createat',
        'DueDate': 'duedate',
        'LastPaymentDate': 'lastpaydate',
        'Status': 'status',
        'PaidAmount': 'paidamount',
        'Amount': 'totalamount',
    }
}
middlwareFields(field) {
    const apiFields = this.getMappingFields();
    return apiFields[field];
}
sortBy(e, record){
    this.props.global.setLoading(true);
    this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
        this.getReportFromApi();
    });
}
getReportFromApi(){
    let tempFilterValue = {};
    if(this.state.sortBy){
        tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
    }
    this.filter(tempFilterValue);
  }

  showHideFilters() {
    this.props.global.setFilterRightPanelOpen(true);
  }

  clearFilters() {
    this.setState({ head: null }, function () {
      this.props.reports.clearFilters();
      this.getReportFromApi();
    });
  }

  filter(type, value) {
    this.props.global.setLoading(true);
    this.props.reports.filter(type, value, 'invoices').finally(() => {
      this.props.global.setLoading(false);
    });
  }

  setHead(head) {
    this.setState({ head });
  }

  toggleModalMarkPaid(recordIdSelected) {
    this.setState({
      recordIdSelected: recordIdSelected,
      markPaidModalIsOpen: !this.state.markPaidModalIsOpen,
    });
  }

  async handleMarkPaidAction() {
    const { recordIdSelected } = this.state;
    this.toggleModalMarkPaid(null);

    try {
      await this.props.reports.markInvoiceAsPaid(recordIdSelected);
      toast.success('The invoice was marked as Paid', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-success-container',
      });
      this.getReportFromApi();
    } catch (error) {
      this.props.global.setLoading(false);
      let errorMessage =
        error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!';
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
      });
    }
  }

  toggleModalDelete(recordIdSelected, e) {
    if(e){
      e.preventDefault();
  }
    this.setState({
      recordIdSelected: recordIdSelected,
      modalDeleteIsOpen: !this.state.modalDeleteIsOpen,
    });
  }

  async handleDeleteAction() {
    const { recordIdSelected } = this.state;
    this.toggleModalDelete(null);

    try {
      await this.props.reports.deleteInvoice(recordIdSelected);
      toast.success('The invoice was deleted', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-success-container',
      });
      this.getReportFromApi();
    } catch (error) {
      this.props.global.setLoading(false);
      let errorMessage =
        error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!';
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
      });
    }
  }

  async handleSendInvoice(record) {
    this.props.global.setLoading(true);
    try {
      const {paylinkId} = record;
      const customerEmail = record.Customer.BillingEmail;
      if(paylinkId === null){
        toast.error('The invoice was not sent because it does not have a paylink.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      }else{
        await this.props.invoice.sendPaylinkEmail(paylinkId, customerEmail);
        toast.success('The invoice was sent', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success-container data-qaid-alertSendInvoicesMoneyIn',
          autoClose: 9000
        });
      }
    } catch (error) {
      let errorMessage =
        error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!';
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
      });
    } finally {
      this.props.global.setLoading(false);
    }
  }

  render() {
    return (
      <Layout {...this.props}>
        <RightPanel>
          <h5 className="header mb-3" data-qaid="invoiceDetailsMoneyIn">Invoice Details</h5>
          <QuickViewInvoice ref={this.quickViewInvoice} handleSendInvoice={this.handleSendInvoice}/>
        </RightPanel>     
        <div>
          {/* MODAL ACTIONS */}
          <ModalMarkPaid
            isOpen={this.state.markPaidModalIsOpen}
            closeModal={this.toggleModalMarkPaid}
            action={this.handleMarkPaidAction}
          />

          <ModalDelete
            isOpen={this.state.modalDeleteIsOpen}
            closeModal={this.toggleModalDelete}
            action={this.handleDeleteAction}
          />

          <ParentFilterPanel report={'invoices'} />

          {/* TOPBAR */}
          <TopBar>
            <MainTopBarMenu />
            <div className="top-bar-sub">
              <ActionsLinks selected="invoices" />
            </div>
          </TopBar>

          <div className="mt-body4">
            <div className="row mb-4 datatable-actions">
              {/* Filters and description headline */}
              <QuickFilters head={this.state.head} setHead={this.setHead} handleFilter={this.filter} />

              {/* Table tools */}
              <TableTools refreshView={this.clearFilters} filtersEnabled={this.state.filtersEnabled} showHideFilters={this.showHideFilters} />
            </div>

            {this.state.filtersEnabled && <ReportFilters report="invoices" clearFilters={this.clearFilters} />}

            <div className="report-container">
              <Table
                filtersEnabled={this.state.filtersEnabled}
                handleFilter={this.filter}
                toggleModalMarkPaid={this.toggleModalMarkPaid}
                toggleModalDelete={this.toggleModalDelete}
                handleSendInvoice={this.handleSendInvoice}
                history={this.props.history}
                sortBy={this.sortBy}
                getMappingFields={this.getMappingFields}
              />
            </div>

            <ReportPaginator report="invoices" />
          </div>
          <ToastContainer transition={Bounce} />
        </div>
      </Layout>
    );
  }
}

export { InvoiceReport };
