import React from 'react';
import { inject, observer } from 'mobx-react';
import ContainerShowMoreLess from './ContainerShowMoreLess'

@inject('reports', 'filter')
@observer
class RenderCustomerInformation extends React.Component {

    render() {
        return (<>
                {(this.props.filter.propsReport !== 'customers') && (<h5 className='title'>Customer Information</h5>)}
                <div className='row'>
                    {
                        (this.props.filter.propsReport === 'customers') &&
                        (<>
                            <h5 className='title'>Customer Information</h5>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating">
                                    <input name="paypointDba" id="paypointDba" className={"form-control"} placeholder="Paypoint DBA" onChange={(e) => this.props.filter.handleCaptureTextFilter('paypointDba(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('paypointDba(ct)', e)} value={this.props.filter.filterText.paypointDba ? this.props.filter.filterText.paypointDba : ''} />
                                    <label htmlFor="paypointDba">Paypoint DBA</label>
                                </div>
                            </div>
                        </>)
                    }                    
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerNo" id="customerNo" className={"form-control"} placeholder="Customer #" onChange={(e) => this.props.filter.handleCaptureTextFilter('customerNumber(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customerNumber(ct)', e)} value={this.props.filter.filterText.customerNo ? this.props.filter.filterText.customerNo : ''} />
                            <label htmlFor="customerNo">Customer #</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerName" id="customerName" className={"form-control"} placeholder="Name" onChange={(e) => this.props.filter.handleCaptureTextFilter('customerName(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customerName(ct)', e)} value={this.props.filter.filterText.customerName ? this.props.filter.filterText.customerName : ''} data-qaid="inputCustomerNameFilter"/>
                            <label htmlFor="customerName">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="customerCompany" id="customerCompany" className={"form-control"} placeholder="Company" onChange={(e) => this.props.filter.handleCaptureTextFilter('customerCompanyname(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customerCompanyname(ct)', e)} value={this.props.filter.filterText.customerCompany ? this.props.filter.filterText.customerCompany : ''} />
                            <label htmlFor="customerCompany">Company</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 mb-4">
                        <div className="form-floating">
                            <input name="externalPaypointID" id="externalPaypointID" className={"form-control"} placeholder="External Paypoint ID" onChange={(e) => this.props.filter.handleCaptureTextFilter('externalPaypointID(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('externalPaypointID(ct)', e)} value={this.props.filter.filterText.externalPaypointID ? this.props.filter.filterText.externalPaypointID : ''}/>
                            <label htmlFor="externalPaypointID">External Paypoint ID</label>
                        </div>
                    </div>
                    <div>
                        <ContainerShowMoreLess>
                            <hr className='mb-4' style={{width: '95%'}}></hr>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating">
                                    <input name="customerEmail" id="customerEmail" className={"form-control"} placeholder="Email" onChange={(e) => this.props.filter.handleCaptureTextFilter('customerEmail(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customerEmail(ct)', e)} value={this.props.filter.filterText.customerEmail ? this.props.filter.filterText.customerEmail : ''} />
                                    <label htmlFor="customerEmail">Email</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating input-number">
                                    <input name="customerPhone" id="customerPhone" type='number' className={"form-control"} placeholder="Phone" onChange={(e) => this.props.filter.handleCaptureTextFilter('customerPhone(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customerPhone(ct)', e)} value={this.props.filter.filterText.customerPhone ? this.props.filter.filterText.customerPhone : ''} />
                                    <label htmlFor="customerPhone">Phone</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating">
                                    <input name="customerBillingaddress" id="customerBillingaddress" className={"form-control"} placeholder="Billing Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('customerAddress(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customerAddress(ct)', e)} value={this.props.filter.filterText.customerBillingaddress ? this.props.filter.filterText.customerBillingaddress : ''} />
                                    <label htmlFor="customerBilling-address">Billing Address</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12 mb-4">
                                <div className="form-floating">
                                    <input name="customerShippingAddress" id="customerShippingAddress" className={"form-control"} placeholder="Shipping Address" onChange={(e) => this.props.filter.handleCaptureTextFilter('customerShippingAddress(ct)', e)} onKeyDown={(e) => this.props.filter.handleKeyDown('customerShippingAddress(ct)', e)} value={this.props.filter.filterText.customerShippingAddress ? this.props.filter.filterText.customerShippingAddress : ''} />
                                    <label htmlFor="customerShippingAddress">Shipping Address</label>
                                </div>
                            </div>
                        </ContainerShowMoreLess>
                    </div>
                </div>
            </>);
    }
}

export { RenderCustomerInformation };