import React from 'react';
import { inject, observer } from 'mobx-react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

@inject('store', 'global', 'reports', 'invoice')
@observer
class Schedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedDefault: false,
      startDate: null,
      endDate: null,
      frequency: 'onetime',
      formErrors: {},
      // options
      optionsFrequency: [
        { value: 'onetime', label: 'One Time' },
        { value: 'weekly', label: 'Weekly', days: 7 },
        { value: 'every2weeks', label: 'Every 2 weeks', days: 14 },
        { value: 'monthly', label: 'Monthly', days: 30 },
        { value: 'every3Months', label: 'Every 3 Months', days: 90 },
        { value: 'every6Months', label: 'Every 6 Months', days: 180 },
      ],
      colVisibleSchedule: 2,
    };

    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleFrequency = this.handleFrequency.bind(this);
    this.getCountDaysByFrequency = this.getCountDaysByFrequency.bind(this);
    this.endDateInvoiceCalendar = React.createRef();
  }

  getCountDaysByFrequency(){
    if(this.state.frequency){
        const arrFrequency = this.state.optionsFrequency.filter(option => option.value === this.state.frequency);
        if (arrFrequency?.length > 0) {
            return arrFrequency[0].days || 1;
        }
    }
    return 1;
  }

  handleStartDate(date) {
    this.setState({
      startDate: date,
      endDate: null,
    });
    this.props.invoice.setInvoiceDetails({
      startDate: date,
      endDate: null,
    });

    const { invoiceDetails } = this.props.invoice;

   if(invoiceDetails.startDate > invoiceDetails.dueDate){
      this.props.invoice.setInvoiceDetails({
        dueDate: invoiceDetails.startDate
      });
    }

    if(invoiceDetails.term){
      this.props.invoice.setInvoiceDetails({
        dueDate: this.props.invoice.getCalculateDueDateFromStartDateTerms(),
      });
    }
  }

  handleEndDate(date) {
    this.setState({ endDate: date });
    this.props.invoice.setInvoiceDetails({
      endDate: date,
    });
    this.endDateInvoiceCalendar.current.setOpen(false)
  }

  handleFrequency(e) {
    const { value } = e.target;
    let { colVisibleSchedule } = this.state;

    if (value === 'onetime') {
      colVisibleSchedule = 2;
    } else {
      colVisibleSchedule = 3;
    }

    this.setState({ frequency: value, endDate: null, colVisibleSchedule: colVisibleSchedule });
    this.props.invoice.setInvoiceDetails({
      frequency: value,
      endDate: null,
    });
  }

  componentDidMount() {
    const invoiceStatus = this.props.invoice.status;
    const invoiceDataLoaded = this.props.invoiceDataLoaded;

    if (invoiceStatus !== 'new' && !this.state.loadedDefault && invoiceDataLoaded) {
      let { invoiceStartDate, invoiceFrequency } = invoiceDataLoaded.Customer.AdditionalData;
      invoiceStartDate = invoiceStartDate ? new Date(invoiceStartDate) : null;

      const frequency = invoiceFrequency ? invoiceFrequency : 'onetime';
      let colVisibleSchedule = 2;
      if (frequency && frequency !== 'onetime') {
        colVisibleSchedule = 3;
      }

      let invoiceEndDate = invoiceDataLoaded.invoiceEndDate ? new Date(invoiceDataLoaded.invoiceEndDate) : null;
      invoiceEndDate = invoiceEndDate && invoiceEndDate?.getFullYear() === (invoiceStartDate?.getFullYear() + 20) && invoiceEndDate?.getMonth() === invoiceStartDate?.getMonth() && invoiceEndDate?.getDate() === invoiceStartDate.getDate() ? "untilcancelled" : invoiceEndDate;
      this.setState({
        loadedDefault: true,
        startDate: invoiceStartDate,
        endDate: invoiceEndDate,
        frequency: frequency,
        colVisibleSchedule,
      });

      this.props.invoice.setInvoiceDetails({
        startDate: invoiceStartDate,
        frequency: frequency,
        endDate: invoiceEndDate,
      });
    }
  }

  render() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const { invoiceDetails } = this.props.invoice;

    let minEndDate = tomorrow;
    if (this.state.startDate) {
      const days = this.getCountDaysByFrequency();
      minEndDate = new Date(this.state.startDate);
      minEndDate.setDate(minEndDate.getDate() + days);
    }

    return (
      <div className="row">
        <div className={`col-${12 / this.state.colVisibleSchedule}`}>
          <DatePicker
            customInput={
              <div className="form-floating">
                <input
                  readOnly
                  autoComplete="off"
                  onChange={function () {}}
                  value={invoiceDetails.startDate ? this.props.reports.dateStringFormat(invoiceDetails.startDate) : ''}
                  name="startDate"
                  id="startDate"
                  className={
                    this.state.formErrors.startDate
                      ? 'form-control input-calendar input-error input-lg'
                      : 'form-control input-calendar input-lg'
                  }
                  placeholder="Select Date"
                />
                <label htmlFor="startDate">{this.state.frequency === "onetime" ? "Invoice Date":"Start Date"}</label>
              </div>
            }
            selected={invoiceDetails.startDate}
            onChange={(date) => this.handleStartDate(date)}
            minDate={tomorrow}
            dayClassName={(date) => 'calendar-day'}
            popperPlacement="bottom-start"
          />
        </div>

        <div className={`col-${12 / this.state.colVisibleSchedule}`}>
          <div className="form-floating">
            <select
              className="form-control form-select"
              name="frequency"
              id="frequency"
              value={this.state.frequency}
              onChange={this.handleFrequency}
            >
              {this.state.optionsFrequency.map((record, i) => (
                <option value={record.value} key={i}>
                  {record.label}
                </option>
              ))}
            </select>
            <label htmlFor="frequency" className="font-weight-bold">
              Frequency
            </label>
          </div>
        </div>

        {this.state.frequency !== 'onetime' && (
          <div className={`col-${12 / this.state.colVisibleSchedule}`}>
            <DatePicker
              customInput={
                <div className="form-floating">
                  <input
                    readOnly
                    autoComplete="off"
                    onChange={function () {}}
                    value={this.state.endDate && this.state.endDate instanceof Date ? this.props.reports.dateStringFormat(this.state.endDate) : this.state.endDate === 'untilcancelled' ? "Until Cancelled" : '' }
                    name="dueDateCustom"
                    id="dueDateCustom"
                    className={
                      this.state.formErrors.endDate
                        ? 'form-control input-calendar input-error input-lg'
                        : 'form-control input-calendar input-lg'
                    }
                    placeholder="Select Date"
                  />
                  <label htmlFor="endDate">End Date</label>
                </div>
              }
              selected={this.state.endDate && this.state.endDate instanceof Date ? this.state.endDate : ''}
              onChange={(date) => this.handleEndDate(date)}
              minDate={minEndDate}
              dayClassName={(date) => 'calendar-day'}
              popperPlacement="bottom-start"
              ref={this.endDateInvoiceCalendar}
              >
                 <div className="linkCalendar" onClick={(e)=>this.handleEndDate('untilcancelled')}>Until Cancelled</div>
              </DatePicker>
          
          </div>
        )}
      </div>
    );
  }
}

export default Schedule;
