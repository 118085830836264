import React from 'react';

class HeaderNameSubtitle extends React.Component {

    render() {
        return (
            <div className="header-paypoint">
            <h5 className="name">{this.props.paylinkHeader}</h5>
            <span className="subtitle">{this.props.paylinkDescription}</span>
            </div>
        )
    }
}

export { HeaderNameSubtitle };