import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Modal, Row, Col, Form, FormControl, InputGroup, Tooltip, OverlayTrigger, Alert } from 'react-bootstrap';
import { BiShow, BiSitemap, BiHide, BiCalendar, BiX, BiInfoCircle, BiUndo } from 'react-icons/bi';
import { PayabliStorageManager } from '../api/localStorageManager';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import ModalFileLimit from './ModalFileLimit'

@inject('global', 'reports')
@observer
class EditSftpAccounts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actionModalIsOpen: false,
            inputUsername: '',
            inputPassword: '',
            inputConfirmPassword: '',
            isInputPassword: true,
            inputInbox: '',
            inputOutbox: '',
            inputServerUrl: '',
            inputLabelFile: '',
            inputFileUpload: null,
            inputFileUploadBase64: '',
            inlineRadioMode: '0',
            inlineRadioChannel: '2',
            inlineSelectedFrequency: 'hourly',
            inlineRadioSchedule: 'am',
            hourSchedule: '6',
            minutesSchedule: '00',
            timeFullSchedule: '06:00',
            inlineSelectedTimezone: 'T-5',
            listDays: [],
            errorInputForm: {},
            xchangeMapping: [],
            listFilePurpose: [{ "fileName": "", "header": true, "mapIdentifier": "", "description": "" }],
            idSftpConfig: null,
        };
        this.imputFileRef = React.createRef();
        this.getListDaysForUpdate = this.getListDaysForUpdate.bind(this);
        this.getSftpAccountById = this.getSftpAccountById.bind(this);
        this.getXchangeMapping = this.getXchangeMapping.bind(this);
        this.convertFileToBase64 = this.convertFileToBase64.bind(this);
        this.adjustTime = this.adjustTime.bind(this);
        this.adjustHour = this.adjustHour.bind(this);
        this.validatedFliedsForm = this.validatedFliedsForm.bind(this);
        this.sftpAccountSave = this.sftpAccountSave.bind(this);
        this.handleShowActionModal = this.handleShowActionModal.bind(this);
        this.handleHiddenActionModal = this.handleHiddenActionModal.bind(this);
        this.handleActionUploadFile = this.handleActionUploadFile.bind(this);
        this.handleActionRadioMode = this.handleActionRadioMode.bind(this);
        this.handleActionRadioChannel = this.handleActionRadioChannel.bind(this);
        this.handleActionSelectFrequency = this.handleActionSelectFrequency.bind(this);
        this.handleActionSelectTimezone = this.handleActionSelectTimezone.bind(this);
        this.handleActionRadioSchedule = this.handleActionRadioSchedule.bind(this);
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleActionPaste = this.handleActionPaste.bind(this);
        this.handleScheduleTime = this.handleScheduleTime.bind(this);
        this.handleActionFreqCalendar = this.handleActionFreqCalendar.bind(this);
        this.handleChangeCalendarState = this.handleChangeCalendarState.bind(this);
        this.handleChangeValueFilePurpose = this.handleChangeValueFilePurpose.bind(this);
        this.handleAddFilePurpose = this.handleAddFilePurpose.bind(this);
        this.handleRemoveFilePurpose = this.handleRemoveFilePurpose.bind(this);
        this.handleCancelRemoveFilePurpose = this.handleCancelRemoveFilePurpose.bind(this);
        this.renderCalendarWeek = this.renderCalendarWeek.bind(this);
        this.renderCalendarMonth = this.renderCalendarMonth.bind(this);
        this.renderFilePurpose = this.renderFilePurpose.bind(this);
    }

    getListDaysForUpdate(list) {
        let result = [];
        if (list['monday']) {
            result.push(1);
        }
        if (list['tuesday']) {
            result.push(2);
        }
        if (list['wednesday']) {
            result.push(3);
        }
        if (list['thursday']) {
            result.push(4);
        }
        if (list['friday']) {
            result.push(5);
        }
        if (list['saturday']) {
            result.push(6);
        }
        if (list['sunday']) {
            result.push(7);
        }
        return result;
    }

    getSftpAccountById(id, idSftpConfig) {
        this.props.global.setLoading(true);
        this.props.reports.getSftpAccountByIdFromApi(id, idSftpConfig).then(res => {
            if (Array.isArray(res)) {
                let objectData = res[0];
                this.setState({
                    inputUsername: objectData.userId ? objectData.userId : '',
                    inputPassword: objectData.password ? objectData.password : '',
                    inputInbox: objectData.remotePathIn ? objectData.remotePathIn : '',
                    inputOutbox: objectData.remotePathOut ? objectData.remotePathOut : '',
                    inputServerUrl: objectData.server ? objectData.server : '',
                    inputLabelFile: objectData.pkey ? 'Uploaded file' : '',
                    inputFileUploadBase64: objectData.pkey ? objectData.pkey : '',
                    inlineRadioMode: objectData.mode ? objectData.mode.toString() : '0',
                    inlineRadioChannel: objectData.channel ? objectData.channel.toString() : '1',
                    scheduleStartDate: Array.isArray(objectData.schedule) && objectData.schedule[0].startDate ? new Date(objectData.schedule[0].startDate) : '',
                    scheduleEndDate: Array.isArray(objectData.schedule) && objectData.schedule[0].endDate ? new Date(objectData.schedule[0].endDate) : '',
                    inlineSelectedFrequency: Array.isArray(objectData.schedule) && objectData.schedule[0].frequency ? objectData.schedule[0].frequency.toLowerCase() : 'hourly',
                    inlineRadioSchedule: Array.isArray(objectData.schedule) && objectData.schedule[0].timeOfDay ? (Number(objectData.schedule[0].timeOfDay.split(':')[0]) < 12 ? 'am' : 'pm') : 'am',
                    hourSchedule: Array.isArray(objectData.schedule) && objectData.schedule[0].timeOfDay ? this.adjustHour(objectData.schedule[0].timeOfDay.split('T')[0].split(':')[0]) : '6',
                    minutesSchedule: Array.isArray(objectData.schedule) && objectData.schedule[0].timeOfDay ? objectData.schedule[0].timeOfDay.split('T')[0].split(':')[1] : '00',
                    timeFullSchedule: Array.isArray(objectData.schedule) && objectData.schedule[0].timeOfDay ? objectData.schedule[0].timeOfDay.split('T')[0] : '06:00',
                    inlineSelectedTimezone: Array.isArray(objectData.schedule) && objectData.schedule[0].timeOfDay ? 'T' + objectData.schedule[0].timeOfDay.split('T')[1] : 'T-5',
                    listDays: Array.isArray(objectData.schedule) ? this.getListDaysForUpdate(objectData.schedule[0]) : [],
                    idSftpConfig: objectData.idSftpConfig ? objectData.idSftpConfig : null,
                    listFilePurpose: Array.isArray(objectData.listFilePurpose) && objectData.listFilePurpose.length > 0 ? objectData.listFilePurpose : [{ "fileName": "", "header": true, "mapIdentifier": "", "description": "" }],
                    actionModalIsOpen: true
                });
            }
            this.props.global.setLoading(false);
        })
            .catch(error => { this.props.global.setLoading(false) });
    }

    getXchangeMapping() {
        this.props.reports.getXchangeMappingFromApi().then(res => {
            this.setState({ xchangeMapping: res })
        })
            .catch(error => { });
    }

    async convertFileToBase64(file) {
        try {
            const base64 = await this.props.global.readFileBase64(file)
            const base64string = base64.split(',');
            return base64string[0] && base64string[1] ? base64string[1] : '';
        } catch (error) {
            throw error
        }
    }

    adjustTime() {
        let m = this.state.inlineRadioSchedule, hour = Number(this.state.hourSchedule), min = this.state.minutesSchedule, result = '06:00';
        if (m === 'pm') {
            if (0 < hour && hour < 12) {
                hour = hour + 12;
            }
        } else {
            if (hour === 12) {
                hour = '00';
            } else if (hour < 10) {
                hour = hour.toString().padStart(2, '0');
            }
        }
        result = hour.toString() + ':' + min.toString();
        this.setState({ timeFullSchedule: result });
    }

    adjustHour(value) {
        if (value > 12) {
            return (value - 12)
        } else if (value < 10) {
            return value.toString().replace('0', '')
        }
        return value
    }

    async validatedFliedsForm() {
        let errorList = {};
        if (this.state.inputUsername.length === 0) {
            errorList['inputUsername'] = true;
        }
        if (this.state.inputInbox.length === 0) {
            errorList['inputInbox'] = true;
        }
        if (this.state.inputOutbox.length === 0) {
            errorList['inputOutbox'] = true;
        }
        if (this.state.inputServerUrl.length === 0) {
            errorList['inputServerUrl'] = true;
        }
        if (this.state.inputPassword.length > 0 && (this.state.inputPassword !== this.state.inputConfirmPassword)) {
            errorList['inputConfirmPassword'] = true;
        }
        const listFilePurpose = this.state.listFilePurpose
        const mapFileNames = new Set();
        for (let i = 0; i < listFilePurpose.length; i++) {
            const object = listFilePurpose[i];
            let value = {}
            if (object.fileName === '' && object.updFlag !== 'remove') {
                value['fileName'] = true;
            }
            if (object.mapIdentifier === '' && object.updFlag !== 'remove') {
                value['mapIdentifier'] = true;
            }
            if (mapFileNames.has(object.fileName) && object.updFlag !== 'remove') {
                value['fileName'] = true;
            }
            if (Object.keys(value).length > 0) {
                if (!Array.isArray(errorList['listFilePurpose'])) {
                    errorList['listFilePurpose'] = [];
                    errorList['listFilePurpose'].length = listFilePurpose.length - 1;
                }
                errorList['listFilePurpose'].splice(i, 0, value);
            }
            mapFileNames.add(object.fileName);
        }
        this.setState({ errorInputForm: errorList });
    }

    async sftpAccountSave() {
        await this.validatedFliedsForm();
        if (Object.keys(this.state.errorInputForm).length > 0) {
            toast.error('There are some validation errors detected, please check!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        } else if (this.state.inputPassword.length === 0 && this.state.inputFileUploadBase64.length === 0 ){
            toast.error('To add a new SFTP account it is mandatory that it has a password or a pem file.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        } else {
            let tempLastCheck = new Date();
            tempLastCheck.setDate((tempLastCheck).getDate() + 1);
            let objectData = {
                "server": this.state.inputServerUrl,
                "userId": this.state.inputUsername,
                "password": this.state.inputPassword,
                "pKey": this.state.inputFileUploadBase64,
                "remotePathIn": this.state.inputInbox,
                "remotePathOut": this.state.inputOutbox,
                "mode": this.state.inlineRadioMode,
                "channel": this.state.inlineRadioChannel,
                "schedule": [
                    {
                        "startdate": this.state.scheduleStartDate ? new Date(this.state.scheduleStartDate) : new Date(),
                        "enddate": this.state.scheduleEndDate ? new Date(this.state.scheduleEndDate) : new Date(),
                        "frequency": this.state.inlineSelectedFrequency,
                        "monday": this.state.listDays.includes(1),
                        "tuesday": this.state.listDays.includes(2),
                        "wednesday": this.state.listDays.includes(3),
                        "thursday": this.state.listDays.includes(4),
                        "friday": this.state.listDays.includes(5),
                        "saturday": this.state.listDays.includes(6),
                        "sunday": this.state.listDays.includes(7),
                        "timeofday": this.state.timeFullSchedule + this.state.inlineSelectedTimezone
                    }
                ],
                "lastCheck": this.props.global.stringDateFormatFilters(tempLastCheck)
            };
            let listFilePurpose = this.state.listFilePurpose
            if (this.props.action === "edit" && this.props.id && this.state.idSftpConfig) {
                this.props.global.setLoading(true);
                this.props.reports
                    .updateSftpAccount(objectData, this.state.idSftpConfig, listFilePurpose)
                    .then((res) => {
                        if (this.props.functionCallback) {
                            this.props.functionCallback();
                        }
                        this.props.global.setLoading(false);
                        toast.success("SFTP account edited successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                    })
                    .catch((error) => {
                        this.props.global.setLoading(false);
                        let errorMessage =
                            error.response && error.response.data.responseText
                                ? error.response.data.responseText
                                : 'Something went wrong when creating the SFTP Account!';
                        toast.error(errorMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    });
                this.handleHiddenActionModal();
            } else {
                this.props.global.setLoading(true);
                this.props.reports
                    .addSftpAccount(objectData, listFilePurpose)
                    .then((res) => {
                        if (this.props.functionCallback) {
                            this.props.functionCallback();
                        }
                        this.props.global.setLoading(false);
                        toast.success("SFTP Account created successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                    })
                    .catch((error) => {
                        this.props.global.setLoading(false);
                        let errorMessage =
                            error.response && error.response.data.responseText
                                ? error.response.data.responseText
                                : 'Something went wrong when creating the SFTP Account!';
                        toast.error(errorMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    });
                this.handleHiddenActionModal();
            }
        }
    }

    handleShowActionModal(e) {
        if (e) {
            e.preventDefault();
        }
        this.getXchangeMapping();
        if (this.props.id && this.props.idSftpConfig) {
            this.getSftpAccountById(this.props.id, this.props.idSftpConfig);
        } else {
            this.setState({ actionModalIsOpen: true });
        }
    }

    handleHiddenActionModal() {
        this.setState({
            actionModalIsOpen: false,
            inputUsername: '',
            inputPassword: '',
            isInputPassword: true,
            inputInbox: '',
            inputOutbox: '',
            inputServerUrl: '',
            inputLabelFile: '',
            inputFileUpload: null,
            inputFileUploadBase64: '',
            inlineRadioMode: '0',
            inlineRadioChannel: '1',
            inlineSelectedFrequency: 'hourly',
            inlineRadioSchedule: 'am',
            hourSchedule: '6',
            minutesSchedule: '00',
            timeFullSchedule: '06:00',
            inlineSelectedTimezone: 'T-5',
            listDays: [],
            errorInputForm: {},
            itemTypeAccount: 'partner',
            objectAccountUser: '',
            listPaypoints: [],
            listPartners: [],
            xchangeMapping: [],
            listFilePurpose: [{ "fileName": "", "header": true, "mapIdentifier": "", "description": "" }],
            idSftpConfig: null,
        });
    }

    async handleActionUploadFile(e) {
        try {
            if (e.target.files.length > 0) {
                if (e.target.files[0] === null) {
                    return;
                }
                const file = e.target.files[0];
                const fileExtension = file.type !== '' ? this.props.global.getFileExtension(file.type) : file.name?.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
                 if (fileExtension !== "pem") {
                    toast.error('The file extension is not valid, the extension must be (.pem).', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                    e.target.value='';
                    return
                } else if (file.size >= 10000) {
                    this.setState({ showModalFileLimit: true })
                    e.target.value='';
                    return;
                }
    
                const reader = new FileReader();
                reader.onload = async (evt) => {
                    const textValid = evt.target.result
                    if ((textValid.includes('BEGIN RSA PRIVATE KEY') && textValid.includes('END RSA PRIVATE KEY')) || (textValid.includes('BEGIN OPENSSH PRIVATE KEY') && textValid.includes('END OPENSSH PRIVATE KEY'))){
                        const fileResult = await this.convertFileToBase64(file);
                        this.setState({ inputFileUploadBase64: fileResult, inputFileUpload: e.target.files[0], inputLabelFile: e.target.files[0].name })
                    } else {
                        toast.error('The file format is not valid.', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-error-container',
                        });
                    }
                };
                reader.readAsText(file);
            } else {
                this.setState({ inputFileUploadBase64: '', inputFileUpload: null, inputLabelFile: '' })
            }
        } catch (error) {
            toast.error(String(error), {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
    }

    handleActionRadioMode(e) {
        let value = e.target.value;
        this.setState({
            inlineRadioMode: value
        });
    }

    handleActionRadioChannel(e) {
        let value = e.target.value;
        this.setState({
            inlineRadioChannel: value
        });
    }

    handleActionSelectFrequency(e) {
        let value = e.target.value;
        this.setState({
            inlineSelectedFrequency: value,
            listDays: [],
        });
    }

    handleActionSelectTimezone(e) {
        let value = e.target.value;
        this.setState({
            inlineSelectedTimezone: value,
        });
    }

    handleActionRadioSchedule(e) {
        let value = e.target.value;
        this.setState({ inlineRadioSchedule: value }, () => {
            this.adjustTime();
        });
    }

    handleShowPassword() {
        let element = document.getElementById("inputPassword");
        let elementConfirmPassword = document.getElementById("inputConfirmPassword");
        if (element.type === "password") {
            element.type = "text";
            elementConfirmPassword.type = "text";
            this.setState({ isInputPassword: false });
        } else {
            element.type = "password";
            elementConfirmPassword.type = "password";
            this.setState({ isInputPassword: true });
        }
    }

    handleTextChange(e) {
        let tempErrorInputForm = this.state.errorInputForm, id = e.target.id;
        if (e.target.value.length > 0) {
            delete tempErrorInputForm[id];
        } else {
            tempErrorInputForm[id] = true;
        }
        if (id === 'inputPassword' || id === 'inputConfirmPassword') {
            delete tempErrorInputForm['inputConfirmPassword'];
        }
        this.setState({ [id]: e.target.value, errorInputForm: tempErrorInputForm });
    }

    async handleActionPaste(input) {
        const text = await navigator.clipboard.readText();
        this.setState({ [input]: text });
    }

    handleScheduleTime(e, type) {
        let value = e.target.value;
        if (type === 1) {
            this.setState({ hourSchedule: value }, () => {
                this.adjustTime();
            });
        } else {
            this.setState({ minutesSchedule: value }, () => {
                this.adjustTime();
            });
        }
    }

    handleActionFreqCalendar(value) {
        let tempListDays = this.state.listDays;
        const index = tempListDays.indexOf(value);
        if (index > -1) {
            tempListDays.splice(index, 1);
        } else {
            tempListDays.push(value);
        }
        this.setState({ listDays: tempListDays });
    }

    handleChangeCalendarState(date) {
        const [start, end] = date
        this.setState({ scheduleStartDate: start, scheduleEndDate: end })
    }

    handleChangeValueFilePurpose(event, pos) {
        const action = this.props.action
        let errorList = this.state.errorInputForm;
        if (Array.isArray(errorList?.listFilePurpose) && errorList?.listFilePurpose[pos]) {
            errorList.listFilePurpose.splice(pos, 0, {})
        }
        let list = this.state.listFilePurpose
        const { id, value } = event.target
        list[pos][id] = value
        if (action === 'edit' && list[pos]['purposeId']) {
            list[pos]['updFlag'] = 'edit'
        }
        this.setState({ listFilePurpose: list })
    }

    handleAddFilePurpose = () => {
        const action = this.props.action
        let list = this.state.listFilePurpose
        let object = { "fileName": "", "header": true, "mapIdentifier": "", "description": "" }
        if (action === 'edit') {
            object['updFlag'] = 'add'
        }
        list.push(object)
        this.setState({ listFilePurpose: list })
    }

    handleRemoveFilePurpose = (pos) => {
        const action = this.props.action
        if (pos >= 0) {
            let list = this.state.listFilePurpose
            if (action === 'edit') {
                list[pos]['updFlag'] = 'remove'
                if (list.every(obj => obj.updFlag === 'remove')) {
                    this.handleAddFilePurpose()
                }
            } else {
                list.splice(pos, 1)
            }
            this.setState({ listFilePurpose: list })
        }
    }

    handleCancelRemoveFilePurpose = (pos) => {
        if (pos >= 0) {
            let list = this.state.listFilePurpose
            list[pos]['updFlag'] = 'edit'
            this.setState({ listFilePurpose: list })
        }
    }

    renderCalendarWeek() {
        let elements = [], days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
        for (let i = 1; i < 8; i++) {
            elements.push(<span key={i} className={this.state.listDays.includes(i) ? 'm-modal-cal actived' : 'm-modal-cal'} onClick={() => this.handleActionFreqCalendar(i)}>{days[i - 1]}</span>)
        }
        return elements;
    }

    renderCalendarMonth() {
        let elements = [];
        for (let i = 1; i < 29; i++) {
            elements.push(<span className={this.state.listDays.includes(i) ? 'm-modal-cal actived' : 'm-modal-cal'} onClick={() => this.handleActionFreqCalendar(i)}>{i}</span>)
            if (i % 7 === 0) {
                elements.push(<br />)
            }
        }
        return elements
    }

    renderFilePurpose = () => {
        const constIndexList = this.state.listFilePurpose.length, listFilePurpose = this.state.listFilePurpose
        return listFilePurpose.map((item, i) => {
            if (item['updFlag'] !== 'remove') {
                return (
                    <div className='mt-3 mb-2' key={i}>
                        <Form.Group className='mb-3' as={Row}>
                            <Col sm={6} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <h6 style={{ marginBottom: '0' }}>File Purpose {constIndexList > 1 && `#${i + 1}`}</h6>
                            </Col>
                            <Col sm={6} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {constIndexList > 1 && <Button size="sm" variant="link" style={{ textDecoration: 'none', color: '#FF867C' }} onClick={() => this.handleRemoveFilePurpose(i)}>delete</Button>}
                                {(constIndexList - 1) === i && <Button size="sm" variant="primary" onClick={() => this.handleAddFilePurpose()}>add new file purpose</Button>}
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3}>
                                Mapping Purpose
                                <OverlayTrigger placement="right" overlay={<Tooltip>Please identify the mapping purpose of your file. Data from your file will add or update system records.<br />Ex: if you select "Customers", our system will fetch data from your file to add or update customer records.</Tooltip>}>
                                    <BiInfoCircle style={{ marginLeft: "5px", fontSize: "14px", color: '#03A9F4' }} />
                                </OverlayTrigger>
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control id='mapIdentifier' as="select" className='form-select' isInvalid={Array.isArray(this.state.errorInputForm?.listFilePurpose) && this.state.errorInputForm?.listFilePurpose[i]?.mapIdentifier} onChange={(e) => this.handleChangeValueFilePurpose(e, i)} value={this.state.listFilePurpose[i]?.mapIdentifier ? this.state.listFilePurpose[i]?.mapIdentifier : ''}>
                                    <option key={-1} value={''}>Select...</option>
                                    {this.state.xchangeMapping.map((element, index) => (
                                        <option key={index} value={element?.identifier}>{element?.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-3' as={Row}>
                            <Form.Label column sm={3}>
                                Key Words
                                <OverlayTrigger placement="right" overlay={<Tooltip>File name should include key words that our systems will always look for.</Tooltip>}>
                                    <BiInfoCircle style={{ marginLeft: "5px", fontSize: "14px", color: '#03A9F4' }} />
                                </OverlayTrigger>
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control id='fileName' type="text" isInvalid={Array.isArray(this.state.errorInputForm?.listFilePurpose) && this.state.errorInputForm?.listFilePurpose[i]?.fileName} maxLength={250} placeholder="NameMerchant_MMDDYYYY_HHMM_0001" onChange={(e) => this.handleChangeValueFilePurpose(e, i)} value={this.state.listFilePurpose[i]?.fileName ? this.state.listFilePurpose[i].fileName : ''} />
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-3' as={Row}>
                            <Form.Label column sm={3} style={{ paddingTop: 'inherit' }}>Description <br className='d-none d-sm-block' /><small className="text-muted">(Optional)</small></Form.Label>
                            <Col sm={9}>
                                <Form.Control id='description' type="text" isInvalid={false} maxLength={250} placeholder="Description" onChange={(e) => this.handleChangeValueFilePurpose(e, i)} value={this.state.listFilePurpose[i]?.description ? this.state.listFilePurpose[i].description : ''} />
                            </Col>
                        </Form.Group>
                    </div>
                )
            }
            else {
                return (
                    <Alert key={i} variant={'danger'}>DELETED <strong>File Purpose {constIndexList > 1 && `#${i + 1}`}</strong><p style={{ cursor: 'pointer', fontSize: '10px' }} className='float-end' onClick={() => this.handleCancelRemoveFilePurpose(i)}><BiUndo style={{ fontSize: '20px', marginTop: '-2px' }} /> UNDO</p></Alert>
                )
            }

        })
    }

    render() {
        const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        const orgId = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId ? encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId : "";
        return (<>
            <Modal id={'modalEditSftp'} show={this.state.actionModalIsOpen} onHide={this.handleHiddenActionModal} size="lg" aria-labelledby="contained-modal-title-vcenter modal-edit-sftp">
                <Modal.Header>
                    <h4 className='ml-2 mb-0 text-payabli-primary'>{this.props.title}</h4>
                </Modal.Header>
                <Modal.Body className="small popover-body" style={{ paddingBottom: 'inherit' }}>
                    <h6>Account Information</h6>
                    <Form>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3}>Server URL</Form.Label>
                            <Col sm={9}>
                                <Form.Control id='inputServerUrl' type="text" isInvalid={this.state.errorInputForm.inputServerUrl} maxLength={250} placeholder="Server URL" value={this.state.inputServerUrl} onChange={(e) => this.handleTextChange(e)} />
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3}>Inbox</Form.Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <FormControl id='inputInbox' type="text" maxLength={250} isInvalid={this.state.errorInputForm.inputInbox} className={'input-icon-append'} value={this.state.inputInbox} onChange={(e) => this.handleTextChange(e)} style={{ paddingRight: '90px' }} />
                                    <InputGroup.Append style={{ marginLeft: '-91px' }}>
                                        <Button variant="link" className='btn-input-append' onClick={() => this.handleActionPaste('inputInbox')}>Paste Path</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3}>Outbox</Form.Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <FormControl id='inputOutbox' type="text" maxLength={250} isInvalid={this.state.errorInputForm.inputOutbox} className={'input-icon-append'} onChange={(e) => this.handleTextChange(e)} value={this.state.inputOutbox} style={{ paddingRight: '90px' }} />
                                    <InputGroup.Append style={{ marginLeft: '-91px' }}>
                                        <Button variant="link" className='btn-input-append' onClick={() => this.handleActionPaste('inputOutbox')}>Paste Path</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3}>Username</Form.Label>
                            <Col sm={9}>
                                <Form.Control id='inputUsername' type="text" isInvalid={this.state.errorInputForm.inputUsername} maxLength={250} placeholder="Username" onChange={(e) => this.handleTextChange(e)} value={this.state.inputUsername} />
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3} style={{ paddingTop: 'inherit', paddingBottom: 'inherit' }}>Password <br className='d-none d-sm-block' /><small className="text-muted">(not required)</small></Form.Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <FormControl id='inputPassword' type="password" maxLength={50} placeholder="Password" aria-label="Password" className={'input-icon-append'} onChange={(e) => this.handleTextChange(e)} value={this.state.inputPassword} />
                                    <InputGroup.Append style={{ marginLeft: '-32px', zIndex: '1' }}>
                                        {
                                            this.state.isInputPassword ?
                                                <BiShow className='icon-show-append' onClick={() => this.handleShowPassword()} />
                                                :
                                                <BiHide className='icon-show-append' onClick={() => this.handleShowPassword()} />
                                        }
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3}>Confirm Password</Form.Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <FormControl id='inputConfirmPassword' type="password" maxLength={50} isInvalid={this.state.errorInputForm.inputConfirmPassword} placeholder="Confirm Password" aria-label="Password" className={'input-icon-append'} onChange={(e) => this.handleTextChange(e)} value={this.state.inputConfirmPassword} />
                                    <InputGroup.Append style={{ marginLeft: '-32px', zIndex: '1' }}>
                                        {
                                            this.state.isInputPassword ?
                                                <BiShow className='icon-show-append' onClick={() => this.handleShowPassword()} />
                                                :
                                                <BiHide className='icon-show-append' onClick={() => this.handleShowPassword()} />
                                        }
                                    </InputGroup.Append>
                                </InputGroup>
                                {this.state.errorInputForm.inputConfirmPassword && <span className="text-uppercase ml-1" style={{ fontSize: '10px', color: '#4A4A4A' }}><BiX style={{ color: '#FF867C', fontSize: '18px', marginBottom: '1px' }} />password did not match</span>}
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3} style={{ paddingTop: 'inherit', paddingBottom: 'inherit' }}>Upload pem file <br className='d-none d-sm-block' /><small className="text-muted">(not required)</small></Form.Label>
                            <Col sm={9} onClick={() => this.imputFileRef.current.click()}>
                                <div className="file-upload-r">
                                    <span>Search File</span>
                                    <input ref={this.imputFileRef} type="file" className={"form-control upload"} accept="application/x-x509-ca-cert" hidden={true}  id="fileImport" onChange={this.handleActionUploadFile} />
                                </div>
                                <input type="text" className='form-control' id="fileuploadurl" style={{ borderRadius: '4px 0px 0px 4px' }} readOnly placeholder={this.state.inputLabelFile} />
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3}>Mode</Form.Label><br />
                            <Col sm={9}>
                                <div className="icheck-primary form-check-inline mr-3">
                                    <input type="radio" name="inlineRadioModeDownload" id="inlineRadioModeDownload" value={'0'} checked={this.state.inlineRadioMode === '0'} onChange={(e) => this.handleActionRadioMode(e)} />
                                    <label htmlFor="inlineRadioModeDownload">Download</label>
                                </div>
                                <div className="icheck-primary form-check-inline mr-3">
                                    <input type="radio" name="inlineRadioModeUpload" id="inlineRadioModeUpload" value={'1'} checked={this.state.inlineRadioMode === '1'} onChange={(e) => this.handleActionRadioMode(e)} />
                                    <label htmlFor="inlineRadioModeUpload">Upload</label>
                                </div>
                                <div className="icheck-primary form-check-inline mr-3">
                                    <input type="radio" name="inlineRadioModeBoth" id="inlineRadioModeBoth" value={'2'} checked={this.state.inlineRadioMode === '2'} onChange={(e) => this.handleActionRadioMode(e)} />
                                    <label htmlFor="inlineRadioModeBoth">Both</label>
                                </div>
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-2' as={Row}>
                            <Form.Label column sm={3}>Channel</Form.Label><br />
                            <Col sm={9}>
                                <div className="icheck-primary form-check-inline mr-3">
                                    <input type="radio" name="inlineRadioChannelOpenfiles" id="inlineRadioChannelOpenfiles" value={'2'} checked={this.state.inlineRadioChannel === '2'} onChange={(e) => this.handleActionRadioChannel(e)} />
                                    <label htmlFor="inlineRadioChannelOpenfiles">Openfiles</label>
                                </div>
                                {orgId === 2 &&
                                    <><div className="icheck-primary form-check-inline mr-3">
                                        <input type="radio" name="inlineRadioChannelTsys" id="inlineRadioChannelTsys" value={'1'} checked={this.state.inlineRadioChannel === '1'} onChange={(e) => this.handleActionRadioChannel(e)} />
                                        <label htmlFor="inlineRadioChannelTsys">Tsys</label>
                                    </div>
                                        <div className="icheck-primary form-check-inline mr-3">
                                            <input type="radio" name="inlineRadioChannelGlobal" id="inlineRadioChannelGlobal" value={'3'} checked={this.state.inlineRadioChannel === '3'} onChange={(e) => this.handleActionRadioChannel(e)} />
                                            <label htmlFor="inlineRadioChannelGlobal">Global</label>
                                        </div></>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className='mb-2' column sm={3}>Frequency</Form.Label><br />
                            <Col className='mb-2' sm={3} xs={12}>
                                <div>
                                    <Form.Control className='form-select' as="select" value={this.state.inlineSelectedFrequency} onChange={(e) => this.handleActionSelectFrequency(e)}>
                                        <option value='hourly'>Hourly</option>
                                        <option value='daily'>Daily</option>
                                        <option value='weekly'>Weekly</option>
                                        <option value='monthly'>Monthly</option>
                                    </Form.Control>
                                </div>
                            </Col>
                            <Col className='mb-2 mt-2' sm={6} xs={12}>
                                {this.state.inlineSelectedFrequency === 'weekly' &&
                                    <>
                                        <span className='form-check-inline text-muted'>on</span>
                                        <div className='form-check-inline'>
                                            {this.renderCalendarWeek()}
                                        </div>
                                    </>
                                }
                                {/* this.state.inlineSelectedFrequency === 'monthly' &&
                                    <>
                                        <span className='form-check-inline text-muted' style={{ float: 'left', marginTop: '6px' }}>each</span>
                                        <div className='form-check-inline'>
                                            {this.renderCalendarMonth()}
                                        </div>
                                    </>
                                */}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3} style={{ paddingTop: 'inherit' }}>
                                Start Date to End Date
                                <OverlayTrigger placement="right" overlay={<Tooltip>Start Date to End Date</Tooltip>}>
                                    <BiInfoCircle style={{ marginLeft: "5px", fontSize: "14px", color: '#03A9F4' }} />
                                </OverlayTrigger>
                                <br className='d-none d-sm-block' /><small className="text-muted">(Optional)</small>
                            </Form.Label>
                            <Col sm={9}>
                                <Row>
                                    <Col xs={5} >
                                        <Form.Control placeholder="MM/DD/YYYY" disabled value={this.state.scheduleStartDate ? this.props.reports.dateStringFormat(this.state.scheduleStartDate) : ''} />
                                    </Col>
                                    <Col xs={1} className='label-center-align'>
                                        <Form.Label>to</Form.Label>
                                    </Col>
                                    <Col xs={5} >
                                        <Form.Control placeholder="MM/DD/YYYY" disabled value={this.state.scheduleEndDate ? this.props.reports.dateStringFormat(this.state.scheduleEndDate) : ''} />
                                    </Col>
                                    <Col xs={1} className='label-center-align'>
                                        <DatePicker
                                            customInput={
                                                <span><BiCalendar className='calendar-startdate-sftp' /><Form.Control hidden /></span>
                                            }
                                            selected={this.state.scheduleStartDate ? this.state.scheduleStartDate : new Date()}
                                            onChange={date => this.handleChangeCalendarState(date)}
                                            startDate={this.state.scheduleStartDate}
                                            endDate={this.state.scheduleEndDate}
                                            selectsRange={true}
                                            monthsShown={2}
                                            popperPlacement="top-left"
                                            popperModifiers={{ preventOverflow: { enabled: true } }}
                                            shouldCloseOnSelect={this.state.scheduleEndDate !== null ? false : true}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3}>Schedule</Form.Label><br />
                            <Col sm={9}>
                                <div className='form-check-inline mb-3'>
                                    <Form.Control className='form-select' as="select" value={this.state.hourSchedule} onChange={(e) => this.handleScheduleTime(e, 1)}>
                                        <option value='1'>01</option>
                                        <option value='2'>02</option>
                                        <option value='3'>03</option>
                                        <option value='4'>04</option>
                                        <option value='5'>05</option>
                                        <option value='6'>06</option>
                                        <option value='7'>07</option>
                                        <option value='8'>08</option>
                                        <option value='9'>09</option>
                                        <option value='10'>10</option>
                                        <option value='11'>11</option>
                                        <option value='12'>12</option>
                                    </Form.Control>
                                </div>
                                <div className='form-check-inline'>
                                    :
                                </div>
                                <div className='form-check-inline'>
                                    <Form.Control className='form-select' as="select" value={this.state.minutesSchedule} onChange={(e) => this.handleScheduleTime(e, 2)}>
                                        <option value='00'>00</option>
                                        <option value='05'>05</option>
                                        <option value='10'>10</option>
                                        <option value='15'>15</option>
                                        <option value='20'>20</option>
                                        <option value='25'>25</option>
                                        <option value='30'>30</option>
                                        <option value='35'>35</option>
                                        <option value='40'>40</option>
                                        <option value='45'>45</option>
                                        <option value='50'>50</option>
                                        <option value='55'>55</option>
                                    </Form.Control>
                                </div>
                                <div className="icheck-primary form-check-inline">
                                    <input type="radio" name="inlineRadioScheduleAm" id="inlineRadioScheduleAm" value={'am'} checked={this.state.inlineRadioSchedule === 'am'} onChange={(e) => this.handleActionRadioSchedule(e)} />
                                    <label htmlFor="inlineRadioScheduleAm">AM</label>
                                </div>
                                <div className="icheck-primary form-check-inline mr-3">
                                    <input type="radio" name="inlineRadioSchedulePm" id="inlineRadioSchedulePm" value={'pm'} checked={this.state.inlineRadioSchedule === 'pm'} onChange={(e) => this.handleActionRadioSchedule(e)} />
                                    <label htmlFor="inlineRadioSchedulePm">PM</label>
                                </div>
                                <div className='float-md-end' style={{ marginRight: '-17px' }}>
                                    <div className='form-check-inline'>
                                        <Form.Label>Timezone</Form.Label>
                                    </div>
                                    <div className='form-check-inline'>
                                        <Form.Control className='form-select' as="select" value={this.state.inlineSelectedTimezone} onChange={(e) => this.handleActionSelectTimezone(e)}>
                                            <option value='T-5'>Eastern</option>
                                            <option value='T-6'>Central</option>
                                            <option value='T-7'>Mountain</option>
                                            <option value='T-8'>Pacific</option>
                                        </Form.Control>
                                    </div>
                                </div>
                            </Col>
                        </Form.Group>
                        <>{this.renderFilePurpose()}</>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mr-2 mb-2' style={{ borderTop: '0' }}>
                    <Button variant="light" onClick={() => this.handleHiddenActionModal()}>cancel</Button>
                    <Button variant={"primary"} onClick={() => this.sftpAccountSave()}>{this.props.action === "add" ? "Add SFTP Account" : "save changes"}</Button>
                </Modal.Footer>
            </Modal>

            <ModalFileLimit
				show={this.state.showModalFileLimit}
				close={() => this.setState({ showModalFileLimit: false })}
                title='File size exceeded!'
				textLine1 = 'It seems that you have exceeded the maximum file size allowed. The maximum upload is 10kb of the format type (.pem). We apologize for any inconvenience.'
				textLine2 = 'If you need to upload the file, try a smaller file size.'
			/>
            <a href="/" className={this.props.className} onClick={(e) => this.handleShowActionModal(e)}>{(!this.props.withoutStyle && <BiSitemap style={{ fontSize: "19px" }} />)} {this.props.action === "add" ? "Add new SFTP Account" : "Edit"}</a>
        </>)
    }
}

export { EditSftpAccounts };